@if (shouldRenderNavigation) {
    <router-outlet></router-outlet>
}

@if (!shouldRenderNavigation) {
    <div cdkScrollableViewport class="app-wrapper">
        <app-network-status></app-network-status>
        <router-outlet></router-outlet>
    </div>
}
