// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="@angular/localize" />
import { registerLocaleData } from '@angular/common';
import localeEN_NZ from '@angular/common/locales/en-NZ';
import localeKO from '@angular/common/locales/ko';
import localeMI from '@angular/common/locales/mi';
import localeVI from '@angular/common/locales/vi';
import { enableProdMode } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';
import { loadTranslations } from '@angular/localize';
import { bootstrapApplication } from '@angular/platform-browser';
import { ConsoleTransport, FetchTransport, LogLevel, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { parseTranslationsForLocalize } from './parseTranslations';

declare const APP_BUILD_TYPE: string;
declare const APP_BUILD_GITHASH: string;

if (environment.production) {
    enableProdMode();
}

const getLocale = (): string => {
    const availableLocales = ['en-nz', 'ko', 'mi', 'vi'];

    const locale =
        // try to get it from the localStorage
        availableLocales.find((l) => (localStorage.getItem('app-locale') ?? '').startsWith(l)) ??
        // if nothing is stored get the best language as specified by the browser settings
        availableLocales.find((l) => navigator.language.startsWith(l)) ??
        // else just fallback to the default language (en-nz in this case)
        availableLocales[0];

    return locale;
};

const setLocale = (locale: string): void => {
    localStorage.setItem('app-locale', locale);
    window.document.documentElement.lang = locale;
};

// get current locale and store it in the local storage
// order of locale determination: url, local storage, browser languages, default language
const locale = getLocale();
setLocale(locale);

initializeFaro({
    app: {
        name: 'mymahi-app',
        version: APP_BUILD_GITHASH,
        environment: APP_BUILD_TYPE
    },
    instrumentations: [
        // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
        ...getWebInstrumentations(),

        // Initialization of the tracing package.
        // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
        new TracingInstrumentation({
            instrumentationOptions: {
                // Requests to these URLs will have tracing headers attached.
                propagateTraceHeaderCorsUrls: ['localhost', /^\//, /^https:\/\/(.+\.)?mymahi\.com\//, /^https:\/\/(.+\.)?mymahi\.co\.nz\//]
            }
        })
    ],
    sessionTracking: {
        samplingRate: 0.1
    },
    transports: [
        environment.production
            ? new FetchTransport({
                  url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/f3cb91e15ba80cdb7bf912070e5f5e4d'
              })
            : new ConsoleTransport({
                  level: LogLevel.DEBUG
              })
    ]
});

registerLocaleData(localeEN_NZ);
registerLocaleData(localeKO);
registerLocaleData(localeMI);
registerLocaleData(localeVI);

// FIXME: find a way to get rid of this hack
/* eslint-disable @typescript-eslint/no-explicit-any */
const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
    (this.form as any).nativeElement = (this.valueAccessor as any)._elementRef.nativeElement;
    // eslint-disable-next-line prefer-rest-params
    return originFormControlNgOnChanges.apply(this, arguments as any);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
    // eslint-disable-next-line prefer-rest-params
    const result = originFormControlNameNgOnChanges.apply(this, arguments as any);
    if ((this.valueAccessor as any)._elementRef) {
        (this.control as any).nativeElement = (this.valueAccessor as any)._elementRef.nativeElement;
    }
    return result;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

// Needed for PDF.js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).pdfWorkerSrc = 'pdf.worker.min.mjs';

// fetch resources for runtime translations. this could also point to an API endpoint
fetch(`assets/i18n/messages.${locale}.xlf`)
    .then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return response.text();
    })
    .then((text) => parseTranslationsForLocalize(text))
    .then((json) => loadTranslations(json))
    .then(() => bootstrapApplication(AppComponent, appConfig))
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
