import { OverlayContainer } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { HttpErrorResponse, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HammerModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { faro } from '@grafana/faro-web-sdk';
import { APIModule } from '@mymahi/api';
import { AuthenticationModule } from '@mymahi/authentication';
import { provideDeviceSize } from '@mymahi/device-size';
import { MARKED_OPTIONS, MarkedOptions, MarkedRenderer, provideMarkdown } from 'ngx-markdown';
import { filter } from 'rxjs';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { DisplayService } from './display.service';
import { InstallService } from './install.service';
import { UpdateService } from './update.service';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            HammerModule,
            ReactiveFormsModule,
            MatBottomSheetModule,
            MatLuxonDateModule,
            MatSnackBarModule,
            ScrollingModule,
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production,
                registrationStrategy: 'registerImmediately'
            }),
            AuthenticationModule.forRoot(environment.auth),
            APIModule.forRoot({
                ENDPOINT: environment.api.graphql,
                ENDPOINT_WS: environment.api.graphql_ws
            })
        ),
        // provide the LOCALE_ID so you can inject the current language in your components
        // and also get the correct formatting for dates, numbers, etc.
        {
            provide: LOCALE_ID,
            useFactory: () => localStorage.getItem('app-locale')
        },
        {
            provide: ErrorHandler,
            useClass: class FaroErrorHandler extends ErrorHandler {
                handleError(error: unknown) {
                    super.handleError(error);
                    if (error instanceof HttpErrorResponse) {
                        if (error.error instanceof Error) {
                            faro.api.pushError(error.error);
                        }
                        let errorMessage: string;
                        if (error.error instanceof ErrorEvent) {
                            errorMessage = error.error.message;
                        } else if (typeof error.error === 'string') {
                            errorMessage = error.error;
                        } else {
                            errorMessage = error.message;
                        }
                        faro.api.pushError(new Error(errorMessage));
                    }
                    if (error instanceof Error) {
                        faro.api.pushError(error);
                    }
                    if (typeof error === 'string') {
                        faro.api.pushError(new Error(error));
                    }
                }
            }
        },
        provideAnimations(),
        provideRouter(
            routes,
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withRouterConfig({ paramsInheritanceStrategy: 'always' })
        ),
        provideHttpClient(withInterceptorsFromDi()),
        provideMarkdown({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: (): MarkedOptions => {
                    const renderer = new MarkedRenderer();

                    const linkRenderer = renderer.link;
                    renderer.link = ({ href, title, text }) => {
                        const localLink = href.startsWith(`${location.protocol}//${location.hostname}`);
                        const html = linkRenderer.call(renderer, href, title, text);
                        return localLink ? html : html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `);
                    };

                    return {
                        renderer: renderer,
                        gfm: true,
                        breaks: false,
                        pedantic: false
                    };
                }
            }
        }),
        provideDeviceSize(),
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig): string => {
                const cdnImageUrl = `https://cdn.${environment.baseUrl}/images/`;

                // It's not a CDN url so just return src and let the browser handle it
                if (!config.src.startsWith(cdnImageUrl)) {
                    return config.src;
                }

                const cdnUrl = new URL(config.src);

                // Always default format to auto
                if (!cdnUrl.searchParams.has('f')) {
                    cdnUrl.searchParams.set('f', 'auto');
                }

                // Always default quality to 80
                if (!cdnUrl.searchParams.has('q')) {
                    cdnUrl.searchParams.set('q', '80');
                }

                if (config.width != null && config.width > 0) {
                    cdnUrl.searchParams.set('w', config.width.toString());
                }

                const loaderParams = config.loaderParams;
                if (loaderParams != null) {
                    const validParams = new Map<string, string>([
                        ['height', 'h'],
                        ['h', 'h'],
                        ['format', 'f'],
                        ['f', 'f'],
                        ['quality', 'q'],
                        ['q', 'q'],
                        ['align', 'a'],
                        ['a', 'a'],
                        ['fit', 'fit'],
                        ['withoutEnlargement', 'we']
                    ]);

                    for (const [option, param] of validParams) {
                        const value = loaderParams[option];
                        if (value != null) {
                            cdnUrl.searchParams.set(param, value.toString());
                        }
                    }

                    const square = loaderParams.square;
                    if (square === true && config.width != null) {
                        cdnUrl.searchParams.set('h', config.width.toString());
                    }

                    const ratio = loaderParams.ratio;
                    if (ratio != null && !Number.isNaN(+ratio) && config.width != null) {
                        cdnUrl.searchParams.set('h', (config.width / +ratio).toString());
                    }
                }

                return cdnUrl.toString();
            }
        },
        provideEnvironmentInitializer(() => inject(OverlayContainer).getContainerElement().classList.add('mat-typography')),
        provideEnvironmentInitializer(() =>
            inject(Router)
                .events.pipe(filter((x): x is NavigationEnd => x instanceof NavigationEnd))
                .subscribe((x) => faro.api.pushEvent('navigation', { url: x.url }))
        ),
        provideEnvironmentInitializer(() => {
            inject(InstallService);
            inject(DisplayService);
            inject(UpdateService);
        })
    ]
};
