import { Lazy } from '@mymahi/utility';
import { WalletCardType } from '../queries';

export enum WalletCardDisclosures {
    SUB = 'sub',
    ID_NUMBER = 'id_number',
    GIVEN_NAME = 'given_name',
    FAMILY_NAME = 'family_name',
    BIRTH_DATE = 'birth_date',
    PICTURE = 'picture',
    EMAIL = 'email',
    COUNTRY_CODE = 'country_code',
    ADDRESS = 'address',
    ORGANISATION_TITLE = 'organisation_title',
    ORGANISATION_LOGO = 'organisation_logo',
    BARCODE_NUMBER = 'barcode_number',
    NATIONAL_CURRICULUM_YEAR = 'national_curriculum_year',
    UNIQUE_STUDENT_NUMBER = 'unique_student_number',
    AGE = 'age',
    AGE_OVER_13 = 'age_over_13',
    AGE_OVER_16 = 'age_over_16',
    AGE_OVER_18 = 'age_over_18',
    AGE_OVER_21 = 'age_over_21',
    ISSUER_ID = 'issuer_id',
    ISSUER_TITLE = 'issuer_title',
    ISSUER_LOGO = 'issuer_logo',
    PROGRAM_ID = 'program_id',
    PROGRAM_TITLE = 'program_title',
    TERMS_AND_CONDITIONS_URL = 'terms_and_conditions_url',
    VERIFICATION_SOURCES = 'verification_sources'
}

export const WalletCardTypeDisplayStrings = new Lazy(() => {
    return {
        [WalletCardType.LEARNER_ID_VC]: $localize`MyMahi Learner ID`,
        [WalletCardType.LOYALTY_VC]: $localize`Loyalty Card`,
        [WalletCardType.VERIFIED_ID_VC]: $localize`MyMahi Verified ID`
    };
});

export const WalletCardClaimDisplayStrings = new Lazy(() => {
    return {
        [WalletCardDisclosures.SUB]: $localize`Subject`,
        [WalletCardDisclosures.ID_NUMBER]: $localize`ID Number`,
        [WalletCardDisclosures.GIVEN_NAME]: $localize`Given Name`,
        [WalletCardDisclosures.FAMILY_NAME]: $localize`Family Name`,
        [WalletCardDisclosures.BIRTH_DATE]: $localize`Birth Date`,
        [WalletCardDisclosures.PICTURE]: $localize`Picture`,
        [WalletCardDisclosures.EMAIL]: $localize`Email`,
        [WalletCardDisclosures.COUNTRY_CODE]: $localize`Country Code`,
        [WalletCardDisclosures.ADDRESS]: $localize`Address`,
        [WalletCardDisclosures.ORGANISATION_TITLE]: $localize`Organisation Title`,
        [WalletCardDisclosures.ORGANISATION_LOGO]: $localize`Organisation Logo`,
        [WalletCardDisclosures.BARCODE_NUMBER]: $localize`Barcode Number`,
        [WalletCardDisclosures.NATIONAL_CURRICULUM_YEAR]: $localize`National Curriculum Year`,
        [WalletCardDisclosures.UNIQUE_STUDENT_NUMBER]: $localize`Unique Student Number`,
        [WalletCardDisclosures.AGE]: $localize`Age`,
        [WalletCardDisclosures.AGE_OVER_13]: $localize`Age Over 13`,
        [WalletCardDisclosures.AGE_OVER_16]: $localize`Age Over 16`,
        [WalletCardDisclosures.AGE_OVER_18]: $localize`Age Over 18`,
        [WalletCardDisclosures.AGE_OVER_21]: $localize`Age Over 21`,
        [WalletCardDisclosures.ISSUER_ID]: $localize`Issuer ID`,
        [WalletCardDisclosures.ISSUER_TITLE]: $localize`Issuer Title`,
        [WalletCardDisclosures.ISSUER_LOGO]: $localize`Issuer Logo`,
        [WalletCardDisclosures.PROGRAM_ID]: $localize`Program ID`,
        [WalletCardDisclosures.PROGRAM_TITLE]: $localize`Program Title`,
        [WalletCardDisclosures.TERMS_AND_CONDITIONS_URL]: $localize`Terms and Conditions URL`,
        [WalletCardDisclosures.VERIFICATION_SOURCES]: $localize`Verification Sources`
    };
});
