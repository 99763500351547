import { Lazy } from '@mymahi/utility';
import { MentorStudentSharedActivityType } from '../queries';

export interface IMentorStudentShareableActivity {
    title: string;
    description: string;
    type: MentorStudentSharedActivityType;
}

export const MentorStudentShareableActivities = new Lazy((): IMentorStudentShareableActivity[] => {
    return [
        {
            title: $localize`Curriculum Vitae`,
            description: $localize`Record of work and personal achievements`,
            type: MentorStudentSharedActivityType.CURRICULUM_VITAE
        },
        {
            title: $localize`Goals`,
            description: $localize`Users goals`,
            type: MentorStudentSharedActivityType.GOALS
        },
        {
            title: $localize`Pathway Planners`,
            description: $localize`Mapping out the future`,
            type: MentorStudentSharedActivityType.PATHWAY_PLANNERS
        },
        {
            title: $localize`Reflections`,
            description: $localize`Self reflections`,
            type: MentorStudentSharedActivityType.REFLECTIONS
        },
        {
            title: $localize`Resource Hub`,
            description: $localize`Resource Hub items`,
            type: MentorStudentSharedActivityType.RESOURCE_HUB_ITEMS
        },
        {
            title: $localize`Saved Courses`,
            description: $localize`Courses of interest`,
            type: MentorStudentSharedActivityType.SAVED_COURSES
        }
    ];
});
