import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

/**
 * App root component
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [RouterOutlet]
})
export class AppComponent implements OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();

    /**
     * Component constructor
     */
    constructor(private router: Router) {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                console.log(this.router.lastSuccessfulNavigation);
            }
        });
    }

    public get shouldRenderNavigation(): boolean {
        return (
            !this.router.isActive('/auth', { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }) &&
            !this.router.isActive('/consent', { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }) &&
            !this.router.isActive('/offline', { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }) &&
            this.router.navigated
        );
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
