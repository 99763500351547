import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InMemoryCache } from '@apollo/client/cache';
import { from, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import { AuthService } from '@mymahi/authentication';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { createClient } from 'graphql-ws';
import { firstValueFrom } from 'rxjs';
import { APIConfig, API_CONFIG } from './apiConfig';
import introspectionResult from './introspection-result';

declare const APP_NAME: string;
declare const APP_BUILD_TYPE: string;
declare const APP_BUILD_GITHASH_SHORT: string;
const APP_VERSION_STRING = `${APP_NAME}/${APP_BUILD_TYPE}/${APP_BUILD_GITHASH_SHORT}`;

@Injectable()
export class QueryService {
    constructor(
        @Inject(API_CONFIG) apiConfig: APIConfig,
        private apollo: Apollo,
        private httpLinkFactory: HttpLink,
        private auth: AuthService
    ) {
        const httpLink = this.httpLinkFactory.create({ uri: apiConfig.ENDPOINT });

        const authenticationLink = setContext(async () => {
            // Grab token if there is one in storage
            const token = await firstValueFrom(this.auth.getAccessToken());
            return {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`).set('X-App-Version', APP_VERSION_STRING)
            };
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const retryLink = new RetryLink({
            delay: {
                initial: 300,
                max: Infinity,
                jitter: true
            },
            attempts: {
                max: 5,
                retryIf: (error, _operation) => !!error
            }
        });

        const wsLink = new GraphQLWsLink(
            createClient({
                url: apiConfig.ENDPOINT_WS,
                lazyCloseTimeout: 60_000,
                connectionParams: async () => {
                    // Grab token if there is one in storage
                    const token = await firstValueFrom(this.auth.getAccessToken());
                    return { Authorization: `Bearer ${token}`, 'X-App-Version': APP_VERSION_STRING };
                }
            })
        );

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            wsLink,
            from([authenticationLink, retryLink, httpLink])
        );

        const cache = new InMemoryCache({
            possibleTypes: introspectionResult.possibleTypes,
            typePolicies: {
                Query: {
                    fields: {
                        geoLocationSearch: relayStylePagination(['countryCode', 'type', 'query']),
                        geoLocationCountryRegions: relayStylePagination(['countryCode']),
                        joinableMentorGroups: relayStylePagination(['countryCode', 'privateOnly']),
                        lessons: relayStylePagination([
                            'locale',
                            'query',
                            'sort',
                            'lessonCategoryId',
                            'isFeatured',
                            'countryCodes',
                            'tags'
                        ]),
                        lessonCategories: relayStylePagination(['locale']),
                        newsfeed: relayStylePagination(),
                        opportunityListings: relayStylePagination(['country', 'geoLocationId', 'types', 'query', 'sort', 'includeExpired']),
                        projectsSimilarInMentorGroup: relayStylePagination(['projectId', 'mentorGroupId']),
                        providers: relayStylePagination(['types', 'query', 'sort']),
                        providersWithStudyProgrammeModule: relayStylePagination(),
                        sponsorAssets: relayStylePagination(['sponsorProviderId']),
                        sponsorCampaigns: relayStylePagination(['sponsorProviderId', 'sponsorCampaignStatus']),
                        sponsorProviders: relayStylePagination(),
                        studyProgrammes: relayStylePagination([
                            'studyCountryCodes',
                            'studyProviderId',
                            'studyCategoryId',
                            'search',
                            'isEnquiriesEnabled',
                            'forLandingList'
                        ]),
                        studyProviders: relayStylePagination(['studyCountryCodes']),
                        studyCategories: relayStylePagination(),
                        threeGoodThingsList: relayStylePagination()
                    }
                },
                ConnectedUser: {
                    fields: {
                        goals: relayStylePagination(['types', 'query']),
                        reflections: relayStylePagination(['types', 'query']),
                        savedStudyProgrammes: relayStylePagination(),
                        pathwayPlanners: relayStylePagination(['query']),
                        projects: relayStylePagination()
                    }
                },
                ConnectedUserProviderActivity: {
                    fields: {
                        reward: {
                            merge: true
                        }
                    }
                },
                ConnectedUserReward: {
                    keyFields: false,
                    fields: {
                        transactions: relayStylePagination()
                    }
                },
                JobProfile: {
                    fields: {
                        activities: relayStylePagination()
                    }
                },
                JoinableMentorGroup: {
                    fields: {
                        members: relayStylePagination()
                    }
                },
                Lesson: {
                    keyFields: ['id', 'locale'],
                    fields: {
                        localizations: {
                            merge: false
                        }
                    }
                },
                LessonLocalization: {
                    keyFields: false
                },
                LessonCategory: {
                    keyFields: ['id', 'locale'],
                    fields: {
                        localizations: {
                            merge: false
                        }
                    }
                },
                LessonCategoryLocalization: {
                    keyFields: false
                },
                MentorGroup: {
                    fields: {
                        discussionMessages: relayStylePagination(),
                        discussionUserState: {
                            merge: true
                        },
                        members: relayStylePagination(),
                        missingStudents: relayStylePagination(),
                        students: relayStylePagination(),
                        rewardStudents: relayStylePagination(),
                        studentHomeworkDiaryDailyMentorTasks: relayStylePagination(['date'])
                    }
                },
                MentorGroupDiscussionUserState: {
                    keyFields: false
                },
                MentorSessionsSummary: {
                    keyFields: false
                },
                MentorRewardAnalytics: {
                    keyFields: false,
                    fields: {
                        topMentors: {
                            keyArgs: ['start', 'end', 'mentorGroupIds']
                        },
                        topLearners: {
                            keyArgs: ['start', 'end', 'mentorGroupIds']
                        },
                        pointsSummaryByType: {
                            keyArgs: ['start', 'end', 'mentorGroupIds', 'mentorRewardEventIds']
                        },
                        pointsYearSummaryByType: {
                            keyArgs: ['year', 'mentorGroupIds', 'mentorRewardEventIds']
                        }
                    }
                },
                MentorRewardStudent: {
                    fields: {
                        transactions: relayStylePagination()
                    }
                },
                mentorBadgeStudent: {
                    fields: {
                        badges: relayStylePagination(['currentProviderOnly'])
                    }
                },
                MentorRewardTopLearner: {
                    keyFields: false
                },
                MentorRewardTopMentor: {
                    keyFields: false
                },
                MentorStudent: {
                    fields: {
                        availableMentorGroups: relayStylePagination(),
                        badges: relayStylePagination(['currentProviderOnly']),
                        goals: relayStylePagination(['types', 'query']),
                        mySchoolInformationItems: relayStylePagination(),
                        notes: relayStylePagination(['mentorGroupId']),
                        pathwayPlanners: relayStylePagination(['query']),
                        projects: relayStylePagination(),
                        reflections: relayStylePagination(['types', 'query']),
                        resourceHubItems: relayStylePagination(['mimeTypes']),
                        studentHomeworkDiaryDailyItems: relayStylePagination(['date']),
                        todos: relayStylePagination(['mentorGroupId'])
                    }
                },
                OpportunityListingPost: {
                    keyFields: ['itemId']
                },
                PathwayPlanner: {
                    fields: {
                        stagesList: {
                            merge: false
                        }
                    }
                },
                PointsSummaryByType: {
                    keyFields: false
                },
                PointsYearSummaryByType: {
                    keyFields: false
                },
                PostMetadata: {
                    keyFields: false
                },
                Provider: {
                    fields: {
                        integrations: relayStylePagination(),
                        learnerSmsGroups: relayStylePagination(),
                        mentorGroups: relayStylePagination(['query']),
                        mentorRewardAnalytics: {
                            merge: true
                        },
                        mentorRewardStudents: relayStylePagination(['query']),
                        mentorBadgeStudents: relayStylePagination(['query']),
                        mentorSessionsSummaryAnalytics: relayStylePagination(),
                        mentorSharedResources: relayStylePagination(),
                        mentorStudents: relayStylePagination(['query']),
                        mentorStudentsProjects: relayStylePagination(['filter']),
                        newsfeedProviderPosts: relayStylePagination(),
                        opportunityListings: relayStylePagination(['country', 'geoLocationId', 'types', 'query', 'sort', 'includeExpired']),
                        providerProjectTemplates: relayStylePagination(),
                        searchStudentJobProfiles: relayStylePagination([
                            'countryCode',
                            'geoLocationId',
                            'jobCategories',
                            'ageGroups',
                            'query',
                            'isFavourite',
                            'hasContacted'
                        ])
                    }
                },
                ProviderPost: {
                    keyFields: ['itemId']
                },
                ProviderPostReactionTotalCountByType: {
                    keyFields: false
                },
                SponsorCampaign: {
                    fields: {
                        sponsorCampaignAssets: relayStylePagination()
                    }
                },
                SponsorProvider: {
                    fields: {
                        sponsorAssets: relayStylePagination()
                    }
                },
                StudentHomeworkDiaryMentorTask: {
                    fields: {
                        studentsAssigned: relayStylePagination(),
                        studentsCompleted: relayStylePagination()
                    }
                },
                UserData: {
                    fields: {
                        connectedUsers: relayStylePagination(['query']),
                        goals: relayStylePagination(['types', 'query']),
                        learnerSmsProfiles: relayStylePagination(),
                        pathwayPlanners: relayStylePagination(['query']),
                        projects: relayStylePagination(),
                        reflections: relayStylePagination(['types', 'query']),
                        resourceHubItems: relayStylePagination(),
                        savedStudyProgrammes: relayStylePagination(),
                        walletCards: relayStylePagination(['types'])
                    }
                }
            }
        });

        this.apollo.create({
            link: splitLink,
            cache,
            defaultOptions: {
                watchQuery: {
                    errorPolicy: 'none',
                    fetchPolicy: 'cache-and-network',
                    nextFetchPolicy: 'cache-first'
                },
                query: {
                    errorPolicy: 'none',
                    fetchPolicy: 'network-only'
                },
                mutate: {
                    errorPolicy: 'none'
                }
            },
            connectToDevTools: true
        });
    }
}
