/* tslint:disable */
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A country code as defined by ISO 3166-1 alpha-2 */
    CountryCode: { input: string; output: string };
    /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Date: { input: string; output: string };
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: { input: string; output: string };
    /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
    EmailAddress: { input: string; output: string };
    /** A field whose value is an Emoji: https://en.wikipedia.org/wiki/Emoji. */
    Emoji: { input: string; output: string };
    /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
    HexColorCode: { input: string; output: string };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: { input: { [key: string]: any }; output: { [key: string]: any } };
    /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
    JWT: { input: string; output: string };
    /** The locale in the format of a BCP 47 (RFC 5646) standard string */
    Locale: { input: string; output: string };
    /** Integers that will have a value of 0 or more. */
    NonNegativeInt: { input: number; output: number };
    /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
    TimeZone: { input: string; output: string };
    /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
    URL: { input: string; output: string };
    /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
    UUID: { input: string; output: string };
}

export interface AcceptMentorStudentInviteCodeInput {
    readonly code: Scalars['String']['input'];
    readonly isSharingOtherProviderActivity?: InputMaybe<Scalars['Boolean']['input']>;
    readonly sharedActivity?: InputMaybe<ReadonlyArray<MentorStudentSharedActivityType>>;
}

export interface AcceptMentorStudentInviteCodePayload {
    readonly __typename: 'AcceptMentorStudentInviteCodePayload';
    readonly mentorStudent: MentorStudent;
}

export interface AcceptUserConnectionRequestInput {
    readonly requestCode: Scalars['String']['input'];
    readonly sharerSharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
}

export interface AcceptUserConnectionRequestPayload {
    readonly __typename: 'AcceptUserConnectionRequestPayload';
    readonly connectedUser: ConnectedUser;
    readonly userConnectionRequestId: Scalars['UUID']['output'];
}

export interface AcknowledgeMentorGroupDiscussionMessageInput {
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['input'];
}

export interface AcknowledgeMentorGroupDiscussionMessagePayload {
    readonly __typename: 'AcknowledgeMentorGroupDiscussionMessagePayload';
    readonly mentorGroupDiscussionMessage: MentorGroupDiscussionMessage;
    readonly mentorGroupDiscussionMessageAcknowledgementStatus: MentorGroupDiscussionMessageAcknowledgementStatus;
}

export interface AddMentorGroupMemberInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly providerMemberId: Scalars['UUID']['input'];
}

export interface AddMentorGroupMemberPayload {
    readonly __typename: 'AddMentorGroupMemberPayload';
    readonly mentorGroupMember: MentorGroupMember;
}

export interface AddMentorGroupStudentInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface AddMentorGroupStudentPayload {
    readonly __typename: 'AddMentorGroupStudentPayload';
    readonly mentorGroupStudent: MentorGroupStudent;
}

export enum AnalyticsDateType {
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    YEAR = 'YEAR'
}

export interface AssessmentResult {
    readonly __typename: 'AssessmentResult';
    readonly comment?: Maybe<Scalars['String']['output']>;
    readonly credits?: Maybe<Scalars['Int']['output']>;
    readonly date?: Maybe<Scalars['DateTime']['output']>;
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly level?: Maybe<Scalars['Int']['output']>;
    readonly number: Scalars['String']['output'];
    readonly points?: Maybe<Scalars['Int']['output']>;
    readonly provider: Provider;
    readonly purpose?: Maybe<Scalars['String']['output']>;
    readonly result?: Maybe<Scalars['String']['output']>;
    readonly subField?: Maybe<Scalars['String']['output']>;
    readonly subject?: Maybe<Scalars['String']['output']>;
    readonly title?: Maybe<Scalars['String']['output']>;
    readonly type: AssessmentResultType;
    readonly version: Scalars['String']['output'];
    readonly weighting?: Maybe<Scalars['Float']['output']>;
}

export enum AssessmentResultType {
    A = 'A',
    B = 'B',
    C = 'C',
    E = 'E',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    O = 'O',
    P = 'P',
    R = 'R',
    S = 'S',
    T = 'T',
    U = 'U',
    V = 'V',
    X = 'X',
    Y = 'Y',
    Z = 'Z'
}

export interface AttachProviderIntegrationLissToParentIntegrationInput {
    readonly id: Scalars['UUID']['input'];
    readonly parentIntegrationId: Scalars['UUID']['input'];
}

export interface AttachProviderIntegrationLissToParentIntegrationPayload {
    readonly __typename: 'AttachProviderIntegrationLissToParentIntegrationPayload';
    readonly providerIntegration: ProviderIntegrationLiss;
}

export interface AvailableMentorGroupConnection {
    readonly __typename: 'AvailableMentorGroupConnection';
    readonly edges: ReadonlyArray<MentorGroupEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroup>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface Badge extends Deletable {
    readonly __typename: 'Badge';
    readonly badgeImageUrl: Scalars['URL']['output'];
    readonly canDelete: Scalars['Boolean']['output'];
    readonly comment: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface BadgeConnection {
    readonly __typename: 'BadgeConnection';
    readonly edges: ReadonlyArray<BadgeEdge>;
    readonly lastCreatedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly nodes: ReadonlyArray<Maybe<Badge>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface BadgeEdge {
    readonly __typename: 'BadgeEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<Badge>;
}

export interface BannerAd {
    readonly __typename: 'BannerAd';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly imageUrl: Scalars['URL']['output'];
    readonly link: Scalars['URL']['output'];
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface BasicVerifiedIdCard extends WalletCard {
    readonly __typename: 'BasicVerifiedIdCard';
    readonly countryCode: Scalars['CountryCode']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly dateOfBirth: Scalars['Date']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly fullName: Scalars['String']['output'];
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly photoUrl: Scalars['URL']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly verificationSources: ReadonlyArray<Scalars['String']['output']>;
    readonly walletCardType: WalletCardType;
}

export enum BroadcastEmailTemplate {
    NEW_FEATURE = 'NEW_FEATURE'
}

export interface Calendar {
    readonly __typename: 'Calendar';
    readonly color: Scalars['String']['output'];
    readonly events: ReadonlyArray<CalendarEvent>;
    readonly id: Scalars['UUID']['output'];
    readonly priority: Scalars['Int']['output'];
    readonly title: Scalars['String']['output'];
}

export interface CalendarEventsArgs {
    calendarEventId?: InputMaybe<Scalars['UUID']['input']>;
    end?: InputMaybe<Scalars['DateTime']['input']>;
    start?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface CalendarEvent {
    readonly __typename: 'CalendarEvent';
    readonly dateType: CalendarEventDateType;
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly end?: Maybe<Scalars['DateTime']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly location?: Maybe<Scalars['String']['output']>;
    readonly start: Scalars['DateTime']['output'];
    readonly summary: Scalars['String']['output'];
}

export enum CalendarEventDateType {
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME'
}

export interface CareersRiasecResult {
    readonly __typename: 'CareersRiasecResult';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly interestArtistic: Scalars['Float']['output'];
    readonly interestCode: Scalars['String']['output'];
    readonly interestConventional: Scalars['Float']['output'];
    readonly interestEnterprising: Scalars['Float']['output'];
    readonly interestInvestigative: Scalars['Float']['output'];
    readonly interestRealistic: Scalars['Float']['output'];
    readonly interestSocial: Scalars['Float']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface CheckUserVerificationRequestResultInput {
    readonly userVerificationRequestId: Scalars['UUID']['input'];
}

export interface CheckUserVerificationRequestResultPayload {
    readonly __typename: 'CheckUserVerificationRequestResultPayload';
    readonly isComplete: Scalars['Boolean']['output'];
    readonly success: Scalars['Boolean']['output'];
}

export interface CheckVerifiedIdCardRequestInput {
    readonly userVerificationRequestId: Scalars['UUID']['input'];
}

export interface CheckVerifiedIdCardRequestPayload {
    readonly __typename: 'CheckVerifiedIdCardRequestPayload';
    /** @deprecated Use verifiedIdVerifiableCredential instead */
    readonly basicVerifiedIdCard?: Maybe<BasicVerifiedIdCard>;
    readonly isComplete: Scalars['Boolean']['output'];
    readonly success: Scalars['Boolean']['output'];
    readonly verifiedIdVerifiableCredential?: Maybe<VerifiedIdVerifiableCredential>;
}

export interface ChooseMentorGroupDiscussionPollResponseInput {
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['input'];
    readonly pollOptionId: Scalars['UUID']['input'];
}

export interface ChooseMentorGroupDiscussionPollResponsePayload {
    readonly __typename: 'ChooseMentorGroupDiscussionPollResponsePayload';
    readonly mentorGroupDiscussionMessage: MentorGroupDiscussionMessage;
    readonly mentorGroupDiscussionMessagePollResponse: MentorGroupDiscussionMessagePollResponse;
}

export interface ChooseProviderPostPollResponseInput {
    readonly pollOptionId: Scalars['UUID']['input'];
    readonly providerPostId: Scalars['UUID']['input'];
}

export interface ChooseProviderPostPollResponsePayload {
    readonly __typename: 'ChooseProviderPostPollResponsePayload';
    readonly providerPost: ProviderPost;
    readonly providerPostPollResponse: ProviderPostPollResponse;
}

export interface CompleteProjectSectionInput {
    readonly id: Scalars['UUID']['input'];
}

export interface CompleteProjectSectionPayload {
    readonly __typename: 'CompleteProjectSectionPayload';
    readonly projectSection: ProjectSection;
}

export interface CompleteProjectSectionReviewInput {
    readonly id: Scalars['UUID']['input'];
}

export interface CompleteProjectSectionReviewPayload {
    readonly __typename: 'CompleteProjectSectionReviewPayload';
    readonly projectSection: ProjectSection;
}

export interface ConnectedUser {
    readonly __typename: 'ConnectedUser';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly curriculumVitae?: Maybe<CurriculumVitae>;
    readonly goals?: Maybe<GoalConnection>;
    readonly hasPinned: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly isSharingAssessmentResults: Scalars['Boolean']['output'];
    readonly isSharingAttendance: Scalars['Boolean']['output'];
    readonly isSharingCurriculumVitae: Scalars['Boolean']['output'];
    readonly isSharingDailyNotices: Scalars['Boolean']['output'];
    readonly isSharingGoals: Scalars['Boolean']['output'];
    readonly isSharingPathwayPlanners: Scalars['Boolean']['output'];
    readonly isSharingProjects: Scalars['Boolean']['output'];
    readonly isSharingReflections: Scalars['Boolean']['output'];
    readonly isSharingReward: Scalars['Boolean']['output'];
    readonly isSharingSavedCourses: Scalars['Boolean']['output'];
    readonly isSharingStudentHomeworkDiary: Scalars['Boolean']['output'];
    readonly isSharingTimetable: Scalars['Boolean']['output'];
    readonly pathwayPlanners?: Maybe<PathwayPlannerConnection>;
    readonly pinnedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly projects?: Maybe<ProjectConnection>;
    readonly providerActivities: ReadonlyArray<ConnectedUserProviderActivity>;
    readonly providerActivity?: Maybe<ConnectedUserProviderActivity>;
    readonly reflections?: Maybe<ReflectionConnection>;
    readonly savedStudyProgrammes?: Maybe<SavedStudyProgrammeConnection>;
    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface ConnectedUserGoalsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<GoalType>>;
}

export interface ConnectedUserPathwayPlannersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface ConnectedUserProjectsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ConnectedUserProviderActivityArgs {
    providerId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface ConnectedUserReflectionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<ReflectionType>>;
}

export interface ConnectedUserSavedStudyProgrammesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ConnectedUserConnection {
    readonly __typename: 'ConnectedUserConnection';
    readonly edges: ReadonlyArray<ConnectedUserEdge>;
    readonly nodes: ReadonlyArray<Maybe<ConnectedUser>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ConnectedUserEdge {
    readonly __typename: 'ConnectedUserEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ConnectedUser>;
}

export interface ConnectedUserProviderActivity {
    readonly __typename: 'ConnectedUserProviderActivity';
    readonly assessmentResultsList?: Maybe<ReadonlyArray<AssessmentResult>>;
    readonly attendanceSummary?: Maybe<LearnerSmsAttendanceSummary>;
    readonly attendanceYearMonthSummary?: Maybe<LearnerSmsAttendanceSummary>;
    readonly dailyNoticesList?: Maybe<ReadonlyArray<DailyNotice>>;
    readonly id: Scalars['UUID']['output'];
    readonly providerTitle: Scalars['String']['output'];
    readonly reward?: Maybe<ConnectedUserReward>;
    readonly studentHomeworkDiaryDailyItems?: Maybe<StudentHomeworkDiaryConnection>;
    readonly studentHomeworkDiaryMonthSummary: StudentHomeworkDiaryMonthSummary;
    readonly timetableEventsList?: Maybe<ReadonlyArray<TimetableEvent>>;
}

export interface ConnectedUserProviderActivityAttendanceSummaryArgs {
    end: Scalars['Date']['input'];
    start: Scalars['Date']['input'];
}

export interface ConnectedUserProviderActivityAttendanceYearMonthSummaryArgs {
    month: Scalars['Int']['input'];
    year: Scalars['Int']['input'];
}

export interface ConnectedUserProviderActivityDailyNoticesListArgs {
    end: Scalars['DateTime']['input'];
    start: Scalars['DateTime']['input'];
}

export interface ConnectedUserProviderActivityStudentHomeworkDiaryDailyItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ConnectedUserProviderActivityStudentHomeworkDiaryMonthSummaryArgs {
    date: Scalars['Date']['input'];
}

export interface ConnectedUserProviderActivityTimetableEventsListArgs {
    end: Scalars['DateTime']['input'];
    start: Scalars['DateTime']['input'];
}

export interface ConnectedUserReward {
    readonly __typename: 'ConnectedUserReward';
    readonly availablePoints: Scalars['Int']['output'];
    readonly totalPoints: Scalars['Int']['output'];
    readonly transactions: ConnectedUserRewardTransactionConnection;
}

export interface ConnectedUserRewardTransactionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ConnectedUserRewardTransaction {
    readonly __typename: 'ConnectedUserRewardTransaction';
    readonly comment?: Maybe<Scalars['String']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly points: Scalars['Int']['output'];
    readonly rewardValue?: Maybe<ConnectedUserRewardValue>;
    readonly type: ConnectedUserRewardTransactionType;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ConnectedUserRewardTransactionConnection {
    readonly __typename: 'ConnectedUserRewardTransactionConnection';
    readonly edges: ReadonlyArray<ConnectedUserRewardTransactionEdge>;
    readonly nodes: ReadonlyArray<Maybe<ConnectedUserRewardTransaction>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ConnectedUserRewardTransactionEdge {
    readonly __typename: 'ConnectedUserRewardTransactionEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ConnectedUserRewardTransaction>;
}

export enum ConnectedUserRewardTransactionType {
    GET = 'GET',
    USE = 'USE'
}

export interface ConnectedUserRewardValue {
    readonly __typename: 'ConnectedUserRewardValue';
    readonly color: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export enum ConnectedUserSharedActivityType {
    ASSESSMENT_RESULTS = 'ASSESSMENT_RESULTS',
    ATTENDANCE = 'ATTENDANCE',
    CURRICULUM_VITAE = 'CURRICULUM_VITAE',
    DAILY_NOTICES = 'DAILY_NOTICES',
    GOALS = 'GOALS',
    HOMEWORK_DIARY = 'HOMEWORK_DIARY',
    PATHWAY_PLANNERS = 'PATHWAY_PLANNERS',
    PROJECTS = 'PROJECTS',
    REFLECTIONS = 'REFLECTIONS',
    REWARDS = 'REWARDS',
    SAVED_COURSES = 'SAVED_COURSES',
    TIMETABLE = 'TIMETABLE'
}

export interface ConsumeMentorRewardInput {
    readonly comment?: InputMaybe<Scalars['String']['input']>;
    readonly mentorStudentId: Scalars['UUID']['input'];
    readonly points: Scalars['Int']['input'];
}

export interface ConsumeMentorRewardPayload {
    readonly __typename: 'ConsumeMentorRewardPayload';
    readonly mentorRewardTransaction: MentorRewardTransaction;
}

export interface ContactStudentJobProfileInput {
    readonly jobProfileId: Scalars['UUID']['input'];
    readonly message: Scalars['String']['input'];
    readonly phoneNumber: Scalars['String']['input'];
    readonly position?: InputMaybe<Scalars['String']['input']>;
    readonly providerId: Scalars['UUID']['input'];
    readonly url?: InputMaybe<Scalars['URL']['input']>;
}

export interface ContactStudentJobProfilePayload {
    readonly __typename: 'ContactStudentJobProfilePayload';
    readonly contacted: Scalars['Boolean']['output'];
}

export interface CopyProviderProjectTemplateInput {
    readonly id: Scalars['UUID']['input'];
    readonly targetProviderId: Scalars['UUID']['input'];
    readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface CopyProviderProjectTemplatePayload {
    readonly __typename: 'CopyProviderProjectTemplatePayload';
    readonly providerProjectTemplate: ProviderProjectTemplate;
}

export interface CreateBadgeInput {
    readonly comment: Scalars['String']['input'];
    readonly mentorStudentId: Scalars['UUID']['input'];
    readonly providerBadgeTemplateId: Scalars['UUID']['input'];
}

export interface CreateBadgePayload {
    readonly __typename: 'CreateBadgePayload';
    readonly badge: Badge;
}

export interface CreateBadgesInput {
    readonly comment: Scalars['String']['input'];
    readonly mentorStudentIds: ReadonlyArray<Scalars['UUID']['input']>;
    readonly providerBadgeTemplateId: Scalars['UUID']['input'];
}

export interface CreateBadgesPayload {
    readonly __typename: 'CreateBadgesPayload';
    readonly badges: ReadonlyArray<Badge>;
}

export interface CreateGoalInput {
    readonly color?: InputMaybe<Scalars['HexColorCode']['input']>;
    readonly complete?: InputMaybe<Scalars['Boolean']['input']>;
    readonly dueAt?: InputMaybe<Scalars['DateTime']['input']>;
    readonly goalType: GoalType;
    readonly imageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly information: Scalars['String']['input'];
    readonly notes?: InputMaybe<ReadonlyArray<CreateGoalInputNoteInput>>;
    readonly tasks?: InputMaybe<ReadonlyArray<CreateGoalInputTaskInput>>;
    readonly title: Scalars['String']['input'];
}

export interface CreateGoalInputNoteInput {
    readonly information: Scalars['String']['input'];
}

export interface CreateGoalInputTaskInput {
    readonly complete?: InputMaybe<Scalars['Boolean']['input']>;
    readonly information: Scalars['String']['input'];
}

export interface CreateGoalNoteInput {
    readonly goalId: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
}

export interface CreateGoalNotePayload {
    readonly __typename: 'CreateGoalNotePayload';
    readonly goalNote: GoalNote;
}

export interface CreateGoalPayload {
    readonly __typename: 'CreateGoalPayload';
    readonly goal: Goal;
}

export interface CreateGoalTaskInput {
    readonly complete?: InputMaybe<Scalars['Boolean']['input']>;
    readonly goalId: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
}

export interface CreateGoalTaskPayload {
    readonly __typename: 'CreateGoalTaskPayload';
    readonly goalTask: GoalTask;
}

export interface CreateLessonInput {
    readonly countryCodes: ReadonlyArray<Scalars['String']['input']>;
    readonly coverImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly defaultLocale: Scalars['Locale']['input'];
    readonly description: Scalars['String']['input'];
    readonly documentId: Scalars['String']['input'];
    readonly lessonCategoryId: Scalars['UUID']['input'];
    readonly tags: ReadonlyArray<Scalars['String']['input']>;
    readonly title: Scalars['String']['input'];
}

export interface CreateLessonLocalizationInput {
    readonly coverImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly description: Scalars['String']['input'];
    readonly documentId: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly locale: Scalars['Locale']['input'];
    readonly tags: ReadonlyArray<Scalars['String']['input']>;
    readonly title: Scalars['String']['input'];
}

export interface CreateLessonLocalizationPayload {
    readonly __typename: 'CreateLessonLocalizationPayload';
    readonly lesson: Lesson;
}

export interface CreateLessonPayload {
    readonly __typename: 'CreateLessonPayload';
    readonly lesson: Lesson;
}

export interface CreateMentorGroupInput {
    readonly description?: InputMaybe<Scalars['String']['input']>;
    readonly providerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateMentorGroupPayload {
    readonly __typename: 'CreateMentorGroupPayload';
    readonly mentorGroup: MentorGroup;
}

export interface CreateMentorRewardItemInput {
    readonly description: Scalars['String']['input'];
    readonly imageId: Scalars['UUID']['input'];
    readonly points: Scalars['Int']['input'];
    readonly providerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateMentorRewardItemPayload {
    readonly __typename: 'CreateMentorRewardItemPayload';
    readonly mentorRewardItem: MentorRewardItem;
}

export interface CreateMentorRewardValueInput {
    readonly color: Scalars['String']['input'];
    readonly providerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
    readonly worth: Scalars['Int']['input'];
}

export interface CreateMentorRewardValuePayload {
    readonly __typename: 'CreateMentorRewardValuePayload';
    readonly mentorRewardValue: MentorRewardValue;
}

export interface CreateMentorSharedResourceInput {
    readonly information: Scalars['String']['input'];
    readonly providerId: Scalars['UUID']['input'];
    readonly sharedResourceType: MentorSharedResourceType;
}

export interface CreateMentorSharedResourcePayload {
    readonly __typename: 'CreateMentorSharedResourcePayload';
    readonly mentorSharedResource: MentorSharedResource;
}

export interface CreateMentorStudentNoteInput {
    readonly information: Scalars['String']['input'];
    readonly mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface CreateMentorStudentNotePayload {
    readonly __typename: 'CreateMentorStudentNotePayload';
    readonly mentorStudentNote: MentorStudentNote;
}

export interface CreateMentorStudentTodoInput {
    readonly complete?: InputMaybe<Scalars['Boolean']['input']>;
    readonly information: Scalars['String']['input'];
    readonly mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface CreateMentorStudentTodoPayload {
    readonly __typename: 'CreateMentorStudentTodoPayload';
    readonly mentorStudentTodo: MentorStudentTodo;
}

export interface CreateOpportunityListingInput {
    readonly company?: InputMaybe<Scalars['String']['input']>;
    readonly contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly contactPhone?: InputMaybe<Scalars['String']['input']>;
    readonly contactWebLink?: InputMaybe<Scalars['URL']['input']>;
    readonly country: Scalars['CountryCode']['input'];
    readonly coverImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly expiresAt: Scalars['DateTime']['input'];
    readonly geoLocationId: Scalars['UUID']['input'];
    readonly isPinned?: InputMaybe<Scalars['Boolean']['input']>;
    readonly jobDescription?: InputMaybe<Scalars['String']['input']>;
    readonly listingRange: OpportunityListingRange;
    readonly payRateDescription?: InputMaybe<Scalars['Float']['input']>;
    readonly providerId: Scalars['UUID']['input'];
    readonly referenceCode?: InputMaybe<Scalars['String']['input']>;
    readonly title: Scalars['String']['input'];
    readonly type: OpportunityListingType;
    readonly workingHoursDescription?: InputMaybe<Scalars['Float']['input']>;
}

export interface CreateOpportunityListingPayload {
    readonly __typename: 'CreateOpportunityListingPayload';
    readonly opportunityListing: OpportunityListing;
}

export interface CreatePathwayPlannerInput {
    readonly title: Scalars['String']['input'];
}

export interface CreatePathwayPlannerPayload {
    readonly __typename: 'CreatePathwayPlannerPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerPersonalRequirementPayload {
    readonly __typename: 'CreatePathwayPlannerPersonalRequirementPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerPersonalRequirementTodoInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreatePathwayPlannerQualificationPayload {
    readonly __typename: 'CreatePathwayPlannerQualificationPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerQualificationTodoInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreatePathwayPlannerSkillAndKnowledgePayload {
    readonly __typename: 'CreatePathwayPlannerSkillAndKnowledgePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerSkillAndKnowledgeTodoInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreatePathwayPlannerStageInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreatePathwayPlannerStagePayload {
    readonly __typename: 'CreatePathwayPlannerStagePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerStageSubjectPayload {
    readonly __typename: 'CreatePathwayPlannerStageSubjectPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerStageSubjectTodoInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly pathwayPlannerStageId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreatePathwayPlannerStageUsefulExperiencePayload {
    readonly __typename: 'CreatePathwayPlannerStageUsefulExperiencePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerStageUsefulExperienceTodoInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly pathwayPlannerStageId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreatePathwayPlannerUsefulExperiencePayload {
    readonly __typename: 'CreatePathwayPlannerUsefulExperiencePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface CreatePathwayPlannerUsefulExperienceTodoInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateProjectInput {
    readonly mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    readonly mentorStudentId: Scalars['UUID']['input'];
    readonly providerProjectTemplateId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateProjectPayload {
    readonly __typename: 'CreateProjectPayload';
    readonly project: Project;
}

export interface CreateProjectSectionCommentInput {
    readonly comment: Scalars['String']['input'];
    readonly projectSectionId: Scalars['UUID']['input'];
}

export interface CreateProjectSectionCommentPayload {
    readonly __typename: 'CreateProjectSectionCommentPayload';
    readonly projectSectionComment: ProjectSectionComment;
}

export interface CreateProjectSectionEvidenceInput {
    readonly description?: InputMaybe<Scalars['String']['input']>;
    readonly projectSectionId: Scalars['String']['input'];
    readonly resourceHubItemId: Scalars['String']['input'];
}

export interface CreateProjectSectionEvidencePayload {
    readonly __typename: 'CreateProjectSectionEvidencePayload';
    readonly projectSectionEvidence?: Maybe<ProjectSectionEvidence>;
}

export interface CreateProjectsForMentorGroupStudentsInput {
    readonly mentorGroupStudentIds: ReadonlyArray<Scalars['UUID']['input']>;
    readonly providerProjectTemplateId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateProjectsForMentorGroupStudentsPayload {
    readonly __typename: 'CreateProjectsForMentorGroupStudentsPayload';
    readonly projects: ReadonlyArray<Project>;
}

export interface CreateProviderBadgeTemplateInput {
    readonly badgeImageId: Scalars['UUID']['input'];
    readonly description: Scalars['String']['input'];
    readonly providerId: Scalars['String']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateProviderBadgeTemplatePayload {
    readonly __typename: 'CreateProviderBadgeTemplatePayload';
    readonly providerBadgeTemplate: ProviderBadgeTemplate;
}

export interface CreateProviderInput {
    readonly countryCode: Scalars['String']['input'];
    readonly internalContactDetails: CreateProviderInputInternalContactDetailsInput;
    readonly providerType: ProviderType;
    readonly technicalContactDetails: CreateProviderInputTechnicalContactDetailsInput;
    readonly title: Scalars['String']['input'];
}

export interface CreateProviderInputInternalContactDetailsInput {
    readonly email?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly fullName?: InputMaybe<Scalars['String']['input']>;
    readonly phone?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateProviderInputTechnicalContactDetailsInput {
    readonly email?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly fullName?: InputMaybe<Scalars['String']['input']>;
    readonly phone?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateProviderIntegrationInput {
    readonly integrationType: ProviderIntegrationType;
    readonly providerId: Scalars['UUID']['input'];
}

export interface CreateProviderIntegrationPayload {
    readonly __typename: 'CreateProviderIntegrationPayload';
    readonly providerIntegration: ProviderIntegration;
}

export interface CreateProviderMemberInviteInput {
    readonly email: Scalars['EmailAddress']['input'];
    readonly providerId: Scalars['UUID']['input'];
    readonly roleIds: ReadonlyArray<Scalars['UUID']['input']>;
}

export interface CreateProviderMemberInvitePayload {
    readonly __typename: 'CreateProviderMemberInvitePayload';
    readonly invite: ProviderMemberInvite;
}

export interface CreateProviderMemberInvitesEntryInput {
    readonly email: Scalars['EmailAddress']['input'];
    readonly roleIds: ReadonlyArray<Scalars['UUID']['input']>;
}

export interface CreateProviderMemberInvitesInput {
    readonly entries: ReadonlyArray<CreateProviderMemberInvitesEntryInput>;
    readonly providerId: Scalars['UUID']['input'];
}

export interface CreateProviderMemberInvitesPayload {
    readonly __typename: 'CreateProviderMemberInvitesPayload';
    readonly invites: ReadonlyArray<ProviderMemberInvite>;
}

export interface CreateProviderMySchoolInformationInput {
    readonly information: Scalars['String']['input'];
    readonly providerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateProviderMySchoolInformationPayload {
    readonly __typename: 'CreateProviderMySchoolInformationPayload';
    readonly providerMySchoolInformationItem: ProviderMySchoolInformationItem;
}

export interface CreateProviderPayload {
    readonly __typename: 'CreateProviderPayload';
    readonly provider: Provider;
}

export interface CreateProviderPostInput {
    readonly content: Scalars['String']['input'];
    readonly countryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly imageId?: InputMaybe<Scalars['String']['input']>;
    readonly isPinned?: InputMaybe<Scalars['Boolean']['input']>;
    readonly mentorOnly?: InputMaybe<Scalars['Boolean']['input']>;
    readonly mentorStudentOnly?: InputMaybe<Scalars['Boolean']['input']>;
    readonly pollExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
    readonly pollOptions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly providerId: Scalars['UUID']['input'];
}

export interface CreateProviderPostPayload {
    readonly __typename: 'CreateProviderPostPayload';
    readonly providerPost: ProviderPost;
}

export interface CreateProviderProjectTemplateInput {
    readonly providerId: Scalars['String']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateProviderProjectTemplateOverviewResourceInput {
    readonly providerProjectTemplateId: Scalars['UUID']['input'];
    readonly url: Scalars['URL']['input'];
}

export interface CreateProviderProjectTemplateOverviewResourcePayload {
    readonly __typename: 'CreateProviderProjectTemplateOverviewResourcePayload';
    readonly providerProjectTemplateOverviewResource: ProviderProjectTemplateOverviewResource;
}

export interface CreateProviderProjectTemplatePayload {
    readonly __typename: 'CreateProviderProjectTemplatePayload';
    readonly providerProjectTemplate: ProviderProjectTemplate;
}

export interface CreateProviderProjectTemplateSectionInput {
    readonly color: Scalars['HexColorCode']['input'];
    readonly description: Scalars['String']['input'];
    readonly providerProjectTemplateId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateProviderProjectTemplateSectionPayload {
    readonly __typename: 'CreateProviderProjectTemplateSectionPayload';
    readonly providerProjectTemplateSection: ProviderProjectTemplateSection;
}

export interface CreateProviderProjectTemplateSectionResourceInput {
    readonly providerProjectTemplateSectionId: Scalars['UUID']['input'];
    readonly url: Scalars['URL']['input'];
}

export interface CreateProviderProjectTemplateSectionResourcePayload {
    readonly __typename: 'CreateProviderProjectTemplateSectionResourcePayload';
    readonly providerProjectTemplateSectionResource?: Maybe<ProviderProjectTemplateSectionResource>;
}

export interface CreateReflectionInput {
    readonly information: Scalars['String']['input'];
    readonly reflectionType: ReflectionType;
}

export interface CreateReflectionPayload {
    readonly __typename: 'CreateReflectionPayload';
    readonly reflection: Reflection;
}

export interface CreateResourceHubItemInput {
    readonly category: ResourceHubItemCategory;
    readonly fileName: Scalars['String']['input'];
    readonly mimeType: Scalars['String']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateResourceHubItemPayload {
    readonly __typename: 'CreateResourceHubItemPayload';
    readonly fileId: Scalars['UUID']['output'];
    readonly resourceHubItem: ResourceHubItem;
    readonly uploadUrl: Scalars['URL']['output'];
}

export interface CreateSponsorAssetInput {
    readonly content?: InputMaybe<Scalars['String']['input']>;
    readonly fileName: Scalars['String']['input'];
    readonly label?: InputMaybe<Scalars['String']['input']>;
    readonly mimeType: Scalars['String']['input'];
    readonly sponsorProviderId: Scalars['UUID']['input'];
    readonly targetUrl: Scalars['URL']['input'];
    readonly title?: InputMaybe<Scalars['String']['input']>;
    readonly type: SponsorAssetType;
}

export interface CreateSponsorAssetPayload {
    readonly __typename: 'CreateSponsorAssetPayload';
    readonly fileId: Scalars['UUID']['output'];
    readonly sponsorAsset: SponsorAsset;
    readonly uploadUrl: Scalars['URL']['output'];
}

export interface CreateSponsorCampaignAssetInput {
    readonly endDate: Scalars['Date']['input'];
    readonly newsfeedPostIndex?: InputMaybe<Scalars['NonNegativeInt']['input']>;
    readonly sponsorAssetId: Scalars['UUID']['input'];
    readonly sponsorCampaignId: Scalars['UUID']['input'];
    readonly startDate: Scalars['Date']['input'];
    readonly targetUser: SponsorTargetUserRole;
}

export interface CreateSponsorCampaignAssetPayload {
    readonly __typename: 'CreateSponsorCampaignAssetPayload';
    readonly sponsorCampaignAsset: SponsorCampaignAsset;
}

export interface CreateSponsorCampaignInput {
    readonly endDate: Scalars['Date']['input'];
    readonly sponsorProviderId: Scalars['UUID']['input'];
    readonly startDate: Scalars['Date']['input'];
    readonly targetCountries: ReadonlyArray<Scalars['CountryCode']['input']>;
    readonly timezone: Scalars['TimeZone']['input'];
}

export interface CreateSponsorCampaignPayload {
    readonly __typename: 'CreateSponsorCampaignPayload';
    readonly sponsorCampaign: SponsorCampaign;
}

export interface CreateSponsorProviderInput {
    readonly logoImageId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateSponsorProviderPayload {
    readonly __typename: 'CreateSponsorProviderPayload';
    readonly sponsorProvider: SponsorProvider;
}

export interface CreateStudentHomeworkDiaryItemInput {
    readonly color?: InputMaybe<Scalars['HexColorCode']['input']>;
    readonly date: Scalars['Date']['input'];
    readonly imageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly information?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateStudentHomeworkDiaryItemPayload {
    readonly __typename: 'CreateStudentHomeworkDiaryItemPayload';
    readonly studentHomeworkDiaryItem: StudentHomeworkDiaryItem;
}

export interface CreateStudentHomeworkDiaryMentorTaskInput {
    readonly color?: InputMaybe<Scalars['HexColorCode']['input']>;
    readonly date: Scalars['Date']['input'];
    readonly imageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly information?: InputMaybe<Scalars['String']['input']>;
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly mentorGroupStudentIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    readonly title: Scalars['String']['input'];
}

export interface CreateStudentHomeworkDiaryMentorTaskPayload {
    readonly __typename: 'CreateStudentHomeworkDiaryMentorTaskPayload';
    readonly studentHomeworkDiaryMentorTask: StudentHomeworkDiaryMentorTask;
}

export interface CreateStudyCategoryInput {
    readonly title: Scalars['String']['input'];
}

export interface CreateStudyCategoryPayload {
    readonly __typename: 'CreateStudyCategoryPayload';
    readonly studyCategory: StudyCategory;
}

export interface CreateStudyProgrammeInput {
    readonly information: Scalars['String']['input'];
    readonly link: Scalars['URL']['input'];
    readonly studyCategoryId: Scalars['UUID']['input'];
    readonly studyCountryCodes: ReadonlyArray<Scalars['String']['input']>;
    readonly studyProgrammeType: StudyProgrammeType;
    readonly studyProviderId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateStudyProgrammePayload {
    readonly __typename: 'CreateStudyProgrammePayload';
    readonly studyProgramme: StudyProgramme;
}

export interface CreateStudyProviderInput {
    readonly countryCode: Scalars['String']['input'];
    readonly email?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
    readonly isEnquiriesEnabled: Scalars['Boolean']['input'];
    readonly isEnrolmentEnabled: Scalars['Boolean']['input'];
    readonly title: Scalars['String']['input'];
}

export interface CreateStudyProviderPayload {
    readonly __typename: 'CreateStudyProviderPayload';
    readonly studyProvider: StudyProvider;
}

export interface CreateThreeGoodThingsInput {
    readonly date: Scalars['Date']['input'];
    readonly firstItem: Scalars['String']['input'];
    readonly secondItem?: InputMaybe<Scalars['String']['input']>;
    readonly thirdItem?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateThreeGoodThingsPayload {
    readonly __typename: 'CreateThreeGoodThingsPayload';
    readonly threeGoodThings: ThreeGoodThings;
}

export interface CurriculumVitae {
    readonly __typename: 'CurriculumVitae';
    readonly achievementsAndAwards: CurriculumVitaeAchievementsAndAwards;
    readonly badges: CurriculumVitaeBadges;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly education: CurriculumVitaeEducation;
    readonly employment: CurriculumVitaeEmployment;
    readonly extracurricularActivities: CurriculumVitaeExtracurricularActivities;
    readonly generatedFiles: ReadonlyArray<CurriculumVitaeGeneratedFile>;
    readonly id: Scalars['UUID']['output'];
    readonly personalAttributes: CurriculumVitaePersonalAttributes;
    readonly personalDetails: CurriculumVitaePersonalDetails;
    readonly personalStatement: CurriculumVitaePersonalStatement;
    readonly profileImageId?: Maybe<Scalars['UUID']['output']>;
    readonly profileImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly references: CurriculumVitaeReferences;
    readonly resourceHubItems: CurriculumVitaeResourceHubItems;
    readonly skills: CurriculumVitaeSkills;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
    readonly volunteer: CurriculumVitaeVolunteer;
}

export interface CurriculumVitaeAchievementsAndAwards {
    readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
    readonly entries?: Maybe<ReadonlyArray<CurriculumVitaeAchievementsAndAwardsEntry>>;
}

export interface CurriculumVitaeAchievementsAndAwardsEntry {
    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
    readonly description: Scalars['String']['output'];
    readonly year: Scalars['Date']['output'];
}

export interface CurriculumVitaeBadges {
    readonly __typename: 'CurriculumVitaeBadges';
    readonly selectedBadgesList: ReadonlyArray<Badge>;
}

export interface CurriculumVitaeEducation {
    readonly __typename: 'CurriculumVitaeEducation';
    readonly entries?: Maybe<ReadonlyArray<CurriculumVitaeEducationEntry>>;
}

export interface CurriculumVitaeEducationEntry {
    readonly __typename: 'CurriculumVitaeEducationEntry';
    readonly school: Scalars['String']['output'];
    readonly subjects?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
    readonly title: Scalars['String']['output'];
    readonly year: Scalars['Date']['output'];
}

export interface CurriculumVitaeEmployment {
    readonly __typename: 'CurriculumVitaeEmployment';
    readonly entries?: Maybe<ReadonlyArray<CurriculumVitaeEmploymentEntry>>;
}

export interface CurriculumVitaeEmploymentEntry {
    readonly __typename: 'CurriculumVitaeEmploymentEntry';
    readonly company: Scalars['String']['output'];
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly end?: Maybe<Scalars['Date']['output']>;
    readonly start: Scalars['Date']['output'];
    readonly title: Scalars['String']['output'];
}

export interface CurriculumVitaeExtracurricularActivities {
    readonly __typename: 'CurriculumVitaeExtracurricularActivities';
    readonly entries?: Maybe<ReadonlyArray<CurriculumVitaeExtracurricularActivitiesEntry>>;
}

export interface CurriculumVitaeExtracurricularActivitiesEntry {
    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
    readonly description: Scalars['String']['output'];
    readonly toYear?: Maybe<Scalars['Date']['output']>;
    readonly year: Scalars['Date']['output'];
}

export interface CurriculumVitaeGeneratedFile {
    readonly __typename: 'CurriculumVitaeGeneratedFile';
    readonly fileId: Scalars['UUID']['output'];
    readonly fileUrl: Scalars['URL']['output'];
    readonly generatedAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
}

export enum CurriculumVitaePersonalAttribute {
    ACCURATE = 'ACCURATE',
    ADAPTABLE = 'ADAPTABLE',
    ALERT = 'ALERT',
    AMBITIOUS = 'AMBITIOUS',
    AMIABLE = 'AMIABLE',
    ANALYTICAL = 'ANALYTICAL',
    ARTICULATE = 'ARTICULATE',
    ASSERTIVE = 'ASSERTIVE',
    ATTENTIVE = 'ATTENTIVE',
    BROAD_MINDED = 'BROAD_MINDED',
    BUSINESSLIKE = 'BUSINESSLIKE',
    CALM = 'CALM',
    CAPABLE = 'CAPABLE',
    CAREFUL = 'CAREFUL',
    COMPETENT = 'COMPETENT',
    CONFIDENT = 'CONFIDENT',
    CONSCIENTIOUS = 'CONSCIENTIOUS',
    CONSISTENT = 'CONSISTENT',
    COOPERATIVE = 'COOPERATIVE',
    DEDICATED = 'DEDICATED',
    DEPENDABLE = 'DEPENDABLE',
    DETERMINED = 'DETERMINED',
    EFFICIENT = 'EFFICIENT',
    ENERGETIC = 'ENERGETIC',
    ENTERPRISING = 'ENTERPRISING',
    FLEXIBLE = 'FLEXIBLE',
    HARDWORKING = 'HARDWORKING',
    HONEST = 'HONEST',
    INDEPENDENT = 'INDEPENDENT',
    INDUSTRIOUS = 'INDUSTRIOUS',
    INNOVATIVE = 'INNOVATIVE',
    MOTIVATED = 'MOTIVATED',
    OPTIMISTIC = 'OPTIMISTIC',
    ORGANIZED = 'ORGANIZED',
    PATIENT = 'PATIENT',
    PEOPLE_ORIENTED = 'PEOPLE_ORIENTED',
    PERSEVERING = 'PERSEVERING',
    PRACTICAL = 'PRACTICAL',
    PRODUCTIVE = 'PRODUCTIVE',
    REALISTIC = 'REALISTIC',
    RELIABLE = 'RELIABLE',
    RESOURCEFUL = 'RESOURCEFUL',
    RESPONSIBLE = 'RESPONSIBLE',
    VERSATILE = 'VERSATILE'
}

export interface CurriculumVitaePersonalAttributes {
    readonly __typename: 'CurriculumVitaePersonalAttributes';
    readonly attributes?: Maybe<ReadonlyArray<CurriculumVitaePersonalAttribute>>;
}

export interface CurriculumVitaePersonalDetails {
    readonly __typename: 'CurriculumVitaePersonalDetails';
    readonly city?: Maybe<Scalars['String']['output']>;
    readonly countryCode?: Maybe<Scalars['String']['output']>;
    readonly dob?: Maybe<Scalars['Date']['output']>;
    readonly driversLicenceType?: Maybe<DriversLicenceType>;
    readonly email?: Maybe<Scalars['EmailAddress']['output']>;
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly givenName?: Maybe<Scalars['String']['output']>;
    readonly interests?: Maybe<Scalars['String']['output']>;
    readonly languageLocales?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
    readonly phoneNumber?: Maybe<Scalars['String']['output']>;
    readonly street?: Maybe<Scalars['String']['output']>;
    readonly suburb?: Maybe<Scalars['String']['output']>;
}

export interface CurriculumVitaePersonalStatement {
    readonly __typename: 'CurriculumVitaePersonalStatement';
    readonly description?: Maybe<Scalars['String']['output']>;
}

export interface CurriculumVitaeReferenceEntry {
    readonly __typename: 'CurriculumVitaeReferenceEntry';
    readonly company: Scalars['String']['output'];
    readonly email: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
    readonly phoneNumber: Scalars['String']['output'];
    readonly position: Scalars['String']['output'];
}

export interface CurriculumVitaeReferences {
    readonly __typename: 'CurriculumVitaeReferences';
    readonly entries?: Maybe<ReadonlyArray<CurriculumVitaeReferenceEntry>>;
}

export interface CurriculumVitaeResourceHubItems {
    readonly __typename: 'CurriculumVitaeResourceHubItems';
    readonly selectedResourceHubItemsList: ReadonlyArray<ResourceHubItem>;
}

export interface CurriculumVitaeSkills {
    readonly __typename: 'CurriculumVitaeSkills';
    readonly description?: Maybe<Scalars['String']['output']>;
}

export interface CurriculumVitaeVolunteer {
    readonly __typename: 'CurriculumVitaeVolunteer';
    readonly entries?: Maybe<ReadonlyArray<CurriculumVitaeVolunteerEntry>>;
}

export interface CurriculumVitaeVolunteerEntry {
    readonly __typename: 'CurriculumVitaeVolunteerEntry';
    readonly company: Scalars['String']['output'];
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly end?: Maybe<Scalars['Date']['output']>;
    readonly start: Scalars['Date']['output'];
    readonly title: Scalars['String']['output'];
}

export interface DailyNotice {
    readonly __typename: 'DailyNotice';
    readonly body?: Maybe<Scalars['String']['output']>;
    readonly color: Scalars['HexColorCode']['output'];
    readonly end: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly level: Scalars['String']['output'];
    readonly meetingDate?: Maybe<Scalars['DateTime']['output']>;
    readonly meetingPlace?: Maybe<Scalars['String']['output']>;
    readonly provider: Provider;
    readonly start: Scalars['DateTime']['output'];
    readonly subject: Scalars['String']['output'];
    readonly teacher?: Maybe<Scalars['String']['output']>;
}

export interface Deletable {
    readonly canDelete: Scalars['Boolean']['output'];
}

export interface DeleteBadgeInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteBadgePayload {
    readonly __typename: 'DeleteBadgePayload';
    readonly badgeId: Scalars['UUID']['output'];
}

export interface DeleteGoalInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteGoalNoteInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteGoalNotePayload {
    readonly __typename: 'DeleteGoalNotePayload';
    readonly goalNoteId: Scalars['UUID']['output'];
}

export interface DeleteGoalPayload {
    readonly __typename: 'DeleteGoalPayload';
    readonly goalId: Scalars['UUID']['output'];
}

export interface DeleteGoalTaskInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteGoalTaskPayload {
    readonly __typename: 'DeleteGoalTaskPayload';
    readonly goalTaskId: Scalars['UUID']['output'];
}

export interface DeleteLessonInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteLessonLocalizationInput {
    readonly id: Scalars['UUID']['input'];
    readonly locale: Scalars['Locale']['input'];
}

export interface DeleteLessonLocalizationPayload {
    readonly __typename: 'DeleteLessonLocalizationPayload';
    readonly lesson: Lesson;
}

export interface DeleteLessonPayload {
    readonly __typename: 'DeleteLessonPayload';
    readonly lessonId: Scalars['UUID']['output'];
}

export interface DeleteMentorGroupBannerImageInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface DeleteMentorGroupBannerImagePayload {
    readonly __typename: 'DeleteMentorGroupBannerImagePayload';
    readonly mentorGroup: MentorGroup;
}

export interface DeleteMentorGroupDiscussionMessageInput {
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['input'];
}

export interface DeleteMentorGroupDiscussionMessagePayload {
    readonly __typename: 'DeleteMentorGroupDiscussionMessagePayload';
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['output'];
}

export interface DeleteMentorGroupInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteMentorGroupPayload {
    readonly __typename: 'DeleteMentorGroupPayload';
    readonly mentorGroupId: Scalars['UUID']['output'];
}

export interface DeleteMentorRewardItemInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteMentorRewardItemPayload {
    readonly __typename: 'DeleteMentorRewardItemPayload';
    readonly mentorRewardItem: MentorRewardItem;
}

export interface DeleteMentorRewardValueInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteMentorRewardValuePayload {
    readonly __typename: 'DeleteMentorRewardValuePayload';
    readonly mentorRewardValueId: Scalars['UUID']['output'];
}

export interface DeleteMentorSharedResourceInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteMentorSharedResourcePayload {
    readonly __typename: 'DeleteMentorSharedResourcePayload';
    readonly mentorSharedResourceId: Scalars['UUID']['output'];
}

export interface DeleteMentorStudentNoteInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteMentorStudentNotePayload {
    readonly __typename: 'DeleteMentorStudentNotePayload';
    readonly mentorStudentNoteId: Scalars['UUID']['output'];
}

export interface DeleteMentorStudentTodoInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteMentorStudentTodoPayload {
    readonly __typename: 'DeleteMentorStudentTodoPayload';
    readonly mentorStudentTodoId: Scalars['UUID']['output'];
}

export interface DeletePathwayPlannerInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeletePathwayPlannerPayload {
    readonly __typename: 'DeletePathwayPlannerPayload';
    readonly pathwayPlannerId: Scalars['UUID']['output'];
}

export interface DeletePathwayPlannerPersonalRequirementPayload {
    readonly __typename: 'DeletePathwayPlannerPersonalRequirementPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface DeletePathwayPlannerPersonalRequirementTodoInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
}

export interface DeletePathwayPlannerQualificationPayload {
    readonly __typename: 'DeletePathwayPlannerQualificationPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface DeletePathwayPlannerQualificationTodoInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
}

export interface DeletePathwayPlannerSkillAndKnowledgePayload {
    readonly __typename: 'DeletePathwayPlannerSkillAndKnowledgePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface DeletePathwayPlannerSkillAndKnowledgeTodoInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
}

export interface DeletePathwayPlannerStageInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
}

export interface DeletePathwayPlannerStagePayload {
    readonly __typename: 'DeletePathwayPlannerStagePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface DeletePathwayPlannerStageSubjectPayload {
    readonly __typename: 'DeletePathwayPlannerStageSubjectPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface DeletePathwayPlannerStageSubjectTodoInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly pathwayPlannerStageId: Scalars['UUID']['input'];
}

export interface DeletePathwayPlannerStageUsefulExperiencePayload {
    readonly __typename: 'DeletePathwayPlannerStageUsefulExperiencePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface DeletePathwayPlannerStageUsefulExperienceTodoInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly pathwayPlannerStageId: Scalars['UUID']['input'];
}

export interface DeletePathwayPlannerUsefulExperiencePayload {
    readonly __typename: 'DeletePathwayPlannerUsefulExperiencePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface DeletePathwayPlannerUsefulExperienceTodoInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
}

export interface DeleteProjectInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProjectPayload {
    readonly __typename: 'DeleteProjectPayload';
    readonly projectId: Scalars['UUID']['output'];
}

export interface DeleteProjectSectionCommentInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProjectSectionCommentPayload {
    readonly __typename: 'DeleteProjectSectionCommentPayload';
    readonly projectSectionCommentId: Scalars['UUID']['output'];
}

export interface DeleteProjectSectionEvidenceInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProjectSectionEvidencePayload {
    readonly __typename: 'DeleteProjectSectionEvidencePayload';
    readonly projectSectionEvidenceId: Scalars['UUID']['output'];
}

export interface DeleteProviderBadgeTemplateInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderBadgeTemplatePayload {
    readonly __typename: 'DeleteProviderBadgeTemplatePayload';
    readonly providerBadgeTemplateId: Scalars['UUID']['output'];
}

export interface DeleteProviderInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderJoinCodeInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderJoinCodePayload {
    readonly __typename: 'DeleteProviderJoinCodePayload';
    readonly providerJoinCodeId: Scalars['UUID']['output'];
}

export interface DeleteProviderMemberInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderMemberInviteInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderMemberInvitePayload {
    readonly __typename: 'DeleteProviderMemberInvitePayload';
    readonly providerMemberInviteId: Scalars['UUID']['output'];
}

export interface DeleteProviderMemberPayload {
    readonly __typename: 'DeleteProviderMemberPayload';
    readonly providerMemberId: Scalars['UUID']['output'];
}

export interface DeleteProviderMySchoolInformationInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderMySchoolInformationPayload {
    readonly __typename: 'DeleteProviderMySchoolInformationPayload';
    readonly providerMySchoolInformationId: Scalars['UUID']['output'];
}

export interface DeleteProviderPayload {
    readonly __typename: 'DeleteProviderPayload';
    readonly providerId: Scalars['UUID']['output'];
}

export interface DeleteProviderPostInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderPostPayload {
    readonly __typename: 'DeleteProviderPostPayload';
    readonly providerPostId: Scalars['UUID']['output'];
}

export interface DeleteProviderProjectTemplateInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderProjectTemplateOverviewResourceInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderProjectTemplateOverviewResourcePayload {
    readonly __typename: 'DeleteProviderProjectTemplateOverviewResourcePayload';
    readonly providerProjectTemplateOverviewResourceId: Scalars['UUID']['output'];
}

export interface DeleteProviderProjectTemplatePayload {
    readonly __typename: 'DeleteProviderProjectTemplatePayload';
    readonly providerProjectTemplateId: Scalars['UUID']['output'];
}

export interface DeleteProviderProjectTemplateSectionInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderProjectTemplateSectionPayload {
    readonly __typename: 'DeleteProviderProjectTemplateSectionPayload';
    readonly providerProjectTemplateSectionId: Scalars['UUID']['output'];
}

export interface DeleteProviderProjectTemplateSectionResourceInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteProviderProjectTemplateSectionResourcePayload {
    readonly __typename: 'DeleteProviderProjectTemplateSectionResourcePayload';
    readonly providerProjectTemplateSectionResourceId: Scalars['UUID']['output'];
}

export interface DeleteReflectionInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteReflectionPayload {
    readonly __typename: 'DeleteReflectionPayload';
    readonly reflectionId: Scalars['UUID']['output'];
}

export interface DeleteResourceHubItemInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteResourceHubItemPayload {
    readonly __typename: 'DeleteResourceHubItemPayload';
    readonly resourceHubItemId: Scalars['UUID']['output'];
}

export interface DeleteSponsorAssetInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteSponsorAssetPayload {
    readonly __typename: 'DeleteSponsorAssetPayload';
    readonly sponsorAssetId: Scalars['UUID']['output'];
}

export interface DeleteSponsorCampaignAssetInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteSponsorCampaignAssetPayload {
    readonly __typename: 'DeleteSponsorCampaignAssetPayload';
    readonly sponsorCampaignAssetId: Scalars['UUID']['output'];
}

export interface DeleteSponsorCampaignInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteSponsorCampaignPayload {
    readonly __typename: 'DeleteSponsorCampaignPayload';
    readonly sponsorCampaignId: Scalars['UUID']['output'];
}

export interface DeleteSponsorProviderInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteSponsorProviderPayload {
    readonly __typename: 'DeleteSponsorProviderPayload';
    readonly sponsorProviderId: Scalars['UUID']['output'];
}

export interface DeleteStudentHomeworkDiaryItemInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteStudentHomeworkDiaryItemPayload {
    readonly __typename: 'DeleteStudentHomeworkDiaryItemPayload';
    readonly studentHomeworkDiaryItemId: Scalars['UUID']['output'];
}

export interface DeleteStudentHomeworkDiaryMentorTaskInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteStudentHomeworkDiaryMentorTaskPayload {
    readonly __typename: 'DeleteStudentHomeworkDiaryMentorTaskPayload';
    readonly studentHomeworkDiaryMentorTaskId: Scalars['UUID']['output'];
}

export interface DeleteStudyCategoryInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteStudyCategoryPayload {
    readonly __typename: 'DeleteStudyCategoryPayload';
    readonly studyCategoryId: Scalars['UUID']['output'];
}

export interface DeleteStudyProgrammeInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteStudyProgrammePayload {
    readonly __typename: 'DeleteStudyProgrammePayload';
    readonly studyProgrammeId: Scalars['UUID']['output'];
}

export interface DeleteStudyProviderInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteStudyProviderPayload {
    readonly __typename: 'DeleteStudyProviderPayload';
    readonly studyProviderId: Scalars['UUID']['output'];
}

export interface DeleteThreeGoodThingsInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DeleteThreeGoodThingsPayload {
    readonly __typename: 'DeleteThreeGoodThingsPayload';
    readonly threeGoodThingsId: Scalars['UUID']['output'];
}

export interface DisableJobProfileInput {
    readonly jobProfileId: Scalars['UUID']['input'];
}

export interface DisableJobProfilePayload {
    readonly __typename: 'DisableJobProfilePayload';
    readonly jobProfile: JobProfile;
}

export interface DisableProviderJoinCodeInput {
    readonly id: Scalars['UUID']['input'];
}

export interface DisableProviderJoinCodePayload {
    readonly __typename: 'DisableProviderJoinCodePayload';
    readonly providerJoinCode: ProviderJoinCode;
}

export interface DisableProviderModuleInput {
    readonly providerId: Scalars['UUID']['input'];
    readonly providerModuleId: Scalars['UUID']['input'];
}

export interface DisableProviderModulePayload {
    readonly __typename: 'DisableProviderModulePayload';
    readonly provider: Provider;
}

export interface DisconnectMentorGroupInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface DisconnectMentorGroupPayload {
    readonly __typename: 'DisconnectMentorGroupPayload';
    readonly mentorGroupId: Scalars['UUID']['output'];
}

export interface DisconnectMentorProviderInput {
    readonly providerId: Scalars['UUID']['input'];
}

export interface DisconnectMentorProviderPayload {
    readonly __typename: 'DisconnectMentorProviderPayload';
    readonly providerId: Scalars['UUID']['output'];
}

export enum DriversLicenceType {
    FULL = 'FULL',
    LEARNER = 'LEARNER',
    NONE = 'NONE',
    RESTRICTED = 'RESTRICTED'
}

export interface EnableJobProfileInput {
    readonly jobProfileId: Scalars['UUID']['input'];
}

export interface EnableJobProfilePayload {
    readonly __typename: 'EnableJobProfilePayload';
    readonly jobProfile: JobProfile;
}

export interface EnableProviderJoinCodeInput {
    readonly id: Scalars['UUID']['input'];
}

export interface EnableProviderJoinCodePayload {
    readonly __typename: 'EnableProviderJoinCodePayload';
    readonly providerJoinCode: ProviderJoinCode;
}

export interface EnableProviderModuleInput {
    readonly providerId: Scalars['UUID']['input'];
    readonly providerModuleId: Scalars['UUID']['input'];
}

export interface EnableProviderModulePayload {
    readonly __typename: 'EnableProviderModulePayload';
    readonly provider: Provider;
}

export interface EnquireStudyProgrammeInput {
    readonly email: Scalars['EmailAddress']['input'];
    readonly message: Scalars['String']['input'];
    readonly name: Scalars['String']['input'];
    readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
    readonly studyProgrammeId: Scalars['UUID']['input'];
}

export interface EnquireStudyProgrammePayload {
    readonly __typename: 'EnquireStudyProgrammePayload';
    readonly enquirySent: Scalars['Boolean']['output'];
}

export interface EnrolInStudyProgrammeInput {
    readonly addressCity: Scalars['String']['input'];
    readonly addressCountryCode: Scalars['CountryCode']['input'];
    readonly addressPostCode: Scalars['String']['input'];
    readonly addressStreet: Scalars['String']['input'];
    readonly addressSuburb?: InputMaybe<Scalars['String']['input']>;
    readonly countryCodeOfCitizenship: Scalars['CountryCode']['input'];
    readonly email: Scalars['EmailAddress']['input'];
    readonly ethnicityCodes: ReadonlyArray<Scalars['String']['input']>;
    readonly gender: EnrolInStudyProgrammeInputGender;
    readonly hasDisability: Scalars['Boolean']['input'];
    readonly isAustralianPermanentResident?: InputMaybe<Scalars['Boolean']['input']>;
    readonly isFullTime: Scalars['Boolean']['input'];
    readonly isNewZealandPermanentResident?: InputMaybe<Scalars['Boolean']['input']>;
    readonly isResidentInNewZealandDuringStudy: Scalars['Boolean']['input'];
    readonly iwiCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly phoneNumber: Scalars['String']['input'];
    readonly studyProgrammeId: Scalars['UUID']['input'];
    readonly verifiedIdCardId: Scalars['UUID']['input'];
}

export enum EnrolInStudyProgrammeInputGender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    OTHER = 'OTHER'
}

export interface EnrolInStudyProgrammePayload {
    readonly __typename: 'EnrolInStudyProgrammePayload';
    readonly enrolmentSent: Scalars['Boolean']['output'];
}

export interface ExpireOpportunityListingInput {
    readonly id: Scalars['UUID']['input'];
}

export interface ExpireOpportunityListingPayload {
    readonly __typename: 'ExpireOpportunityListingPayload';
    readonly opportunityListing: OpportunityListing;
}

export interface FollowProviderInput {
    readonly providerId: Scalars['UUID']['input'];
}

export interface FollowProviderPayload {
    readonly __typename: 'FollowProviderPayload';
    readonly providerId: Scalars['UUID']['output'];
}

export interface GenerateCoverLetterInput {
    readonly jobCompany?: InputMaybe<Scalars['String']['input']>;
    readonly jobDescription?: InputMaybe<Scalars['String']['input']>;
    readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
}

export interface GenerateCoverLetterPayload {
    readonly __typename: 'GenerateCoverLetterPayload';
    readonly coverLetterText: Scalars['String']['output'];
}

export interface GenerateCurriculumVitaeFileInput {
    readonly curriculumVitaeId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface GenerateCurriculumVitaeFilePayload {
    readonly __typename: 'GenerateCurriculumVitaeFilePayload';
    readonly generatedFile: CurriculumVitaeGeneratedFile;
}

export interface GeneratePathwayPlannerInformationSuggestionsInput {
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly pathwayPlannerInformation: PathwayPlannerInformationType;
    readonly pathwayPlannerStageId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface GeneratePathwayPlannerInformationSuggestionsPayload {
    readonly __typename: 'GeneratePathwayPlannerInformationSuggestionsPayload';
    readonly pathwaySuggestions: ReadonlyArray<Scalars['String']['output']>;
}

export interface GenerateProviderJoinCodeInput {
    readonly providerId: Scalars['UUID']['input'];
}

export interface GenerateProviderJoinCodePayload {
    readonly __typename: 'GenerateProviderJoinCodePayload';
    readonly providerJoinCode: ProviderJoinCode;
}

export interface GeoLocation {
    readonly __typename: 'GeoLocation';
    readonly city?: Maybe<Scalars['String']['output']>;
    readonly country?: Maybe<Scalars['String']['output']>;
    readonly countryCode?: Maybe<Scalars['CountryCode']['output']>;
    readonly county?: Maybe<Scalars['String']['output']>;
    readonly displayName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly latitude?: Maybe<Scalars['Float']['output']>;
    readonly longitude?: Maybe<Scalars['Float']['output']>;
    readonly name: Scalars['String']['output'];
    readonly state?: Maybe<Scalars['String']['output']>;
    readonly street?: Maybe<Scalars['String']['output']>;
}

export interface GeoLocationConnection {
    readonly __typename: 'GeoLocationConnection';
    readonly edges: ReadonlyArray<GeoLocationEdge>;
    readonly nodes: ReadonlyArray<Maybe<GeoLocation>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface GeoLocationEdge {
    readonly __typename: 'GeoLocationEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<GeoLocation>;
}

export enum GeoLocationSearchType {
    CITY = 'CITY',
    CITY_OR_SUBURB = 'CITY_OR_SUBURB',
    STATE_OR_REGION_OR_CITY = 'STATE_OR_REGION_OR_CITY',
    STATE_OR_REGION_OR_CITY_OR_SUBURB = 'STATE_OR_REGION_OR_CITY_OR_SUBURB',
    SUBURB = 'SUBURB'
}

export interface GiveMentorRewardsInput {
    readonly comment?: InputMaybe<Scalars['String']['input']>;
    readonly mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    readonly mentorRewardValueIds: ReadonlyArray<Scalars['UUID']['input']>;
    readonly mentorStudentIds: ReadonlyArray<Scalars['UUID']['input']>;
}

export interface GiveMentorRewardsPayload {
    readonly __typename: 'GiveMentorRewardsPayload';
    readonly mentorRewardTransactions: ReadonlyArray<MentorRewardTransaction>;
}

export interface Goal extends Deletable, Updatable {
    readonly __typename: 'Goal';
    readonly canCreateNote: Scalars['Boolean']['output'];
    readonly canCreateTask: Scalars['Boolean']['output'];
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly color?: Maybe<Scalars['HexColorCode']['output']>;
    readonly complete: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly dueAt?: Maybe<Scalars['DateTime']['output']>;
    readonly goalType: GoalType;
    readonly id: Scalars['UUID']['output'];
    readonly imageId?: Maybe<Scalars['UUID']['output']>;
    readonly imageUrl?: Maybe<Scalars['URL']['output']>;
    readonly information: Scalars['String']['output'];
    readonly notes: ReadonlyArray<GoalNote>;
    readonly tasks: ReadonlyArray<GoalTask>;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface GoalConnection {
    readonly __typename: 'GoalConnection';
    readonly edges: ReadonlyArray<GoalEdge>;
    readonly lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly nodes: ReadonlyArray<Maybe<Goal>>;
    readonly pageInfo: PageInfo;
    readonly totalCompleteCount: Scalars['Int']['output'];
    readonly totalCount: Scalars['Int']['output'];
}

export interface GoalEdge {
    readonly __typename: 'GoalEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<Goal>;
}

export interface GoalNote extends Deletable, Updatable {
    readonly __typename: 'GoalNote';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface GoalTask extends Deletable, Updatable {
    readonly __typename: 'GoalTask';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly complete: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export enum GoalType {
    ACADEMIC = 'ACADEMIC',
    CAREER = 'CAREER',
    DEVELOPING_ME = 'DEVELOPING_ME',
    ENVIRONMENT = 'ENVIRONMENT',
    EXTRA_CURRICULAR = 'EXTRA_CURRICULAR',
    HEALTH_AND_WELLBEING = 'HEALTH_AND_WELLBEING'
}

export interface GoalsDueReminder extends NewsfeedItem {
    readonly __typename: 'GoalsDueReminder';
    readonly goals: ReadonlyArray<Goal>;
    readonly itemCreated: Scalars['DateTime']['output'];
    readonly itemId: Scalars['UUID']['output'];
    readonly itemType: NewsfeedItemType;
    readonly itemUpdated?: Maybe<Scalars['DateTime']['output']>;
    readonly totalDue: Scalars['Int']['output'];
}

export interface ImportMentorRewardEventPointsInput {
    readonly description?: InputMaybe<Scalars['String']['input']>;
    readonly mentorRewardEventStudents: ReadonlyArray<ImportMentorRewardEventPointsInputMentorRewardEventStudent>;
    readonly title: Scalars['String']['input'];
}

export interface ImportMentorRewardEventPointsInputMentorRewardEventStudent {
    readonly mentorRewardEventComment?: InputMaybe<Scalars['String']['input']>;
    readonly mentorRewardEventPoints: Scalars['Int']['input'];
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface ImportMentorRewardEventPointsPayload {
    readonly __typename: 'ImportMentorRewardEventPointsPayload';
    readonly mentorRewardEventTransactions: ReadonlyArray<MentorRewardTransaction>;
}

export interface ImportMentorRewardPointsInput {
    readonly mentorRewardStudents: ReadonlyArray<ImportMentorRewardPointsInputMentorRewardStudent>;
}

export interface ImportMentorRewardPointsInputMentorRewardPoints {
    readonly mentorRewardValueId: Scalars['UUID']['input'];
    readonly numberOfPoints: Scalars['Int']['input'];
}

export interface ImportMentorRewardPointsInputMentorRewardStudent {
    readonly mentorRewardPoints: ReadonlyArray<ImportMentorRewardPointsInputMentorRewardPoints>;
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface ImportMentorRewardPointsPayload {
    readonly __typename: 'ImportMentorRewardPointsPayload';
    readonly mentorRewardTransactions: ReadonlyArray<MentorRewardTransaction>;
}

export enum JobCategory {
    CREATIVE_ARTISTIC_WORK = 'CREATIVE_ARTISTIC_WORK',
    DESIGNING_THINGS = 'DESIGNING_THINGS',
    HANDS_ON_WORK_WITH_TOOLS_OR_EQUIPMENT = 'HANDS_ON_WORK_WITH_TOOLS_OR_EQUIPMENT',
    OPERATING_VEHICLES = 'OPERATING_VEHICLES',
    PHYSICALLY_ACTIVE = 'PHYSICALLY_ACTIVE',
    PLANNING_OR_ORGANISING = 'PLANNING_OR_ORGANISING',
    RESEARCH_OR_ANALYSIS = 'RESEARCH_OR_ANALYSIS',
    SALES_OR_CUSTOMER_SERVICE = 'SALES_OR_CUSTOMER_SERVICE',
    TEAMWORK = 'TEAMWORK',
    WORKING_OUTDOORS = 'WORKING_OUTDOORS',
    WORKING_WITH_COMPUTERS = 'WORKING_WITH_COMPUTERS',
    WORKING_WITH_FOOD_OR_DRINKS = 'WORKING_WITH_FOOD_OR_DRINKS',
    WORKING_WITH_NUMBERS_OR_MONEY = 'WORKING_WITH_NUMBERS_OR_MONEY',
    WORKING_WITH_PEOPLE = 'WORKING_WITH_PEOPLE',
    WORKING_WITH_PLANTS_OR_ANIMALS_OR_NATURE = 'WORKING_WITH_PLANTS_OR_ANIMALS_OR_NATURE',
    WRITING_AND_COMMUNICATING = 'WRITING_AND_COMMUNICATING'
}

export interface JobProfile {
    readonly __typename: 'JobProfile';
    readonly activities: JobProfileActivitiesConnection;
    readonly canEnable: Scalars['Boolean']['output'];
    readonly curriculumVitaeCompletedSections: ReadonlyArray<JobProfileRequiredSection>;
    readonly enabledAt?: Maybe<Scalars['DateTime']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly preferredJobAvailability: Scalars['String']['output'];
    readonly preferredJobCategories: ReadonlyArray<JobCategory>;
    readonly preferredJobGeoLocations: ReadonlyArray<GeoLocation>;
    readonly preferredJobTypes: ReadonlyArray<JobType>;
}

export interface JobProfileActivitiesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface JobProfileActivitiesConnection {
    readonly __typename: 'JobProfileActivitiesConnection';
    readonly edges: ReadonlyArray<JobProfileActivityEdge>;
    readonly nodes: ReadonlyArray<Maybe<JobProfileActivity>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface JobProfileActivity {
    readonly __typename: 'JobProfileActivity';
    readonly activityType: JobProfileActivityType;
    readonly actor?: Maybe<User>;
    readonly actorFullName?: Maybe<Scalars['String']['output']>;
    readonly actorProvider?: Maybe<Provider>;
    readonly actorProviderTitle?: Maybe<Scalars['String']['output']>;
    readonly canReport: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface JobProfileActivityEdge {
    readonly __typename: 'JobProfileActivityEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<JobProfileActivity>;
}

export enum JobProfileActivityType {
    CONTACTED = 'CONTACTED',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
    VIEWED = 'VIEWED'
}

export enum JobProfileRequiredSection {
    EDUCATION = 'EDUCATION',
    PERSONAL_ATTRIBUTES = 'PERSONAL_ATTRIBUTES',
    PERSONAL_DETAIL = 'PERSONAL_DETAIL',
    PERSONAL_STATEMENT = 'PERSONAL_STATEMENT',
    REFERENCES = 'REFERENCES',
    SKILLS = 'SKILLS'
}

export enum JobType {
    APPRENTICE = 'APPRENTICE',
    CASUAL = 'CASUAL',
    FULL_TIME = 'FULL_TIME',
    PART_TIME = 'PART_TIME'
}

export interface JoinAvailableMentorGroupInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface JoinAvailableMentorGroupPayload {
    readonly __typename: 'JoinAvailableMentorGroupPayload';
    readonly mentorGroup: MentorGroup;
}

export interface JoinAvailableMentorProviderInput {
    readonly isSharingOtherProviderActivity?: InputMaybe<Scalars['Boolean']['input']>;
    readonly providerId: Scalars['UUID']['input'];
    readonly sharedActivity?: InputMaybe<ReadonlyArray<MentorStudentSharedActivityType>>;
}

export interface JoinAvailableMentorProviderPayload {
    readonly __typename: 'JoinAvailableMentorProviderPayload';
    readonly mentorStudent: MentorStudent;
}

export interface JoinJoinableMentorGroupInput {
    readonly isSharingOtherProviderActivity?: InputMaybe<Scalars['Boolean']['input']>;
    readonly joinableMentorGroupId: Scalars['UUID']['input'];
    readonly sharedActivity?: InputMaybe<ReadonlyArray<MentorStudentSharedActivityType>>;
}

export interface JoinJoinableMentorGroupPayload {
    readonly __typename: 'JoinJoinableMentorGroupPayload';
    readonly joinableMentorGroup: JoinableMentorGroup;
    readonly mentorGroup: MentorGroup;
}

export interface JoinableMentorGroup {
    readonly __typename: 'JoinableMentorGroup';
    readonly bannerImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly hasConsent: Scalars['Boolean']['output'];
    readonly hasJoined: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly members: JoinableMentorGroupMemberConnection;
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly totalStudentCount: Scalars['Int']['output'];
}

export interface JoinableMentorGroupMembersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface JoinableMentorGroupConnection {
    readonly __typename: 'JoinableMentorGroupConnection';
    readonly edges: ReadonlyArray<JoinableMentorGroupEdge>;
    readonly nodes: ReadonlyArray<Maybe<JoinableMentorGroup>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface JoinableMentorGroupEdge {
    readonly __typename: 'JoinableMentorGroupEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<JoinableMentorGroup>;
}

export interface JoinableMentorGroupMemberConnection {
    readonly __typename: 'JoinableMentorGroupMemberConnection';
    readonly edges: ReadonlyArray<JoinableMentorGroupMemberEdge>;
    readonly nodes: ReadonlyArray<Maybe<User>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface JoinableMentorGroupMemberEdge {
    readonly __typename: 'JoinableMentorGroupMemberEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<User>;
}

export interface LearnerIdVerifiableCredential extends WalletCard {
    readonly __typename: 'LearnerIdVerifiableCredential';
    readonly address?: Maybe<LearnerIdVerifiableCredentialAddress>;
    readonly age: Scalars['Int']['output'];
    readonly ageOver13: Scalars['Boolean']['output'];
    readonly ageOver16: Scalars['Boolean']['output'];
    readonly ageOver18: Scalars['Boolean']['output'];
    readonly ageOver21: Scalars['Boolean']['output'];
    readonly barcodeNumber: Scalars['String']['output'];
    readonly birthDate: Scalars['Date']['output'];
    readonly countryCode: Scalars['CountryCode']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly email: Scalars['EmailAddress']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly idNumber: Scalars['String']['output'];
    readonly nationalCurriculumYear: Scalars['String']['output'];
    readonly organisationLogoUrl: Scalars['URL']['output'];
    readonly organisationTitle: Scalars['String']['output'];
    readonly pictureUrl: Scalars['URL']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly walletCardType: WalletCardType;
}

export interface LearnerIdVerifiableCredentialAddress {
    readonly __typename: 'LearnerIdVerifiableCredentialAddress';
    readonly country: Scalars['String']['output'];
    readonly locality?: Maybe<Scalars['String']['output']>;
    readonly postalCode?: Maybe<Scalars['String']['output']>;
    readonly region?: Maybe<Scalars['String']['output']>;
    readonly streetAddress?: Maybe<Scalars['String']['output']>;
}

export interface LearnerSmsAttendanceSummary {
    readonly __typename: 'LearnerSmsAttendanceSummary';
    readonly halfDaysJustified: Scalars['Int']['output'];
    readonly halfDaysPresent: Scalars['Int']['output'];
    readonly halfDaysTotal: Scalars['Int']['output'];
    readonly halfDaysUnjustified: Scalars['Int']['output'];
    readonly standing: LearnerSmsAttendanceSummaryStanding;
}

export enum LearnerSmsAttendanceSummaryStanding {
    EXCELLENT = 'EXCELLENT',
    GOOD = 'GOOD',
    NEEDS_WORK = 'NEEDS_WORK'
}

export interface LearnerSmsEmployee {
    readonly __typename: 'LearnerSmsEmployee';
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly fullName: Scalars['String']['output'];
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly pictureUrl: Scalars['URL']['output'];
    readonly preferredFamilyName?: Maybe<Scalars['String']['output']>;
    readonly preferredFullName: Scalars['String']['output'];
    readonly preferredGivenName: Scalars['String']['output'];
    readonly provider: Provider;
}

export interface LearnerSmsGroup {
    readonly __typename: 'LearnerSmsGroup';
    readonly code: Scalars['String']['output'];
    readonly employeesList: ReadonlyArray<LearnerSmsEmployee>;
    readonly groupType: LearnerSmsGroupType;
    readonly id: Scalars['UUID']['output'];
    readonly mentorGroup?: Maybe<MentorGroup>;
    readonly name: Scalars['String']['output'];
    readonly numberOfStudents: Scalars['Int']['output'];
    readonly provider: Provider;
}

export interface LearnerSmsGroupConnection {
    readonly __typename: 'LearnerSmsGroupConnection';
    readonly edges: ReadonlyArray<LearnerSmsGroupEdge>;
    readonly nodes: ReadonlyArray<Maybe<LearnerSmsGroup>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface LearnerSmsGroupEdge {
    readonly __typename: 'LearnerSmsGroupEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<LearnerSmsGroup>;
}

export enum LearnerSmsGroupType {
    BOARDING = 'BOARDING',
    CAMPUS = 'CAMPUS',
    COURSE = 'COURSE',
    DEPARTMENT = 'DEPARTMENT',
    DIVISION = 'DIVISION',
    HOUSE = 'HOUSE',
    MISC = 'MISC',
    REGISTRATION = 'REGISTRATION',
    USER = 'USER',
    YEAR = 'YEAR'
}

export interface LearnerSmsProfile {
    readonly __typename: 'LearnerSmsProfile';
    readonly admissionDate?: Maybe<Scalars['Date']['output']>;
    readonly assessmentResultsList: ReadonlyArray<AssessmentResult>;
    readonly attendanceSummary?: Maybe<LearnerSmsAttendanceSummary>;
    readonly attendanceYearMonthSummary?: Maybe<LearnerSmsAttendanceSummary>;
    readonly barcodeNumber: Scalars['String']['output'];
    readonly dailyNotice?: Maybe<DailyNotice>;
    readonly dailyNoticesList: ReadonlyArray<DailyNotice>;
    readonly email: Scalars['EmailAddress']['output'];
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly fullName: Scalars['String']['output'];
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly idNumber: Scalars['String']['output'];
    readonly isAssessmentResultsAvailable: Scalars['Boolean']['output'];
    readonly isAttendanceAvailable: Scalars['Boolean']['output'];
    readonly isDailyNoticesAvailable: Scalars['Boolean']['output'];
    readonly isTimetableAvailable: Scalars['Boolean']['output'];
    readonly isTimetableDisabled: Scalars['Boolean']['output'];
    readonly leavingDate?: Maybe<Scalars['Date']['output']>;
    readonly mobile?: Maybe<Scalars['String']['output']>;
    readonly mySchoolInformationItems: ProviderMySchoolInformationConnection;
    readonly nationalCurriculumYear: Scalars['String']['output'];
    readonly pictureUrl: Scalars['URL']['output'];
    readonly preferredFamilyName?: Maybe<Scalars['String']['output']>;
    readonly preferredFullName: Scalars['String']['output'];
    readonly preferredGivenName: Scalars['String']['output'];
    readonly provider: Provider;
    readonly uniqueStudentNumber?: Maybe<Scalars['String']['output']>;
}

export interface LearnerSmsProfileAttendanceSummaryArgs {
    end: Scalars['Date']['input'];
    start: Scalars['Date']['input'];
}

export interface LearnerSmsProfileAttendanceYearMonthSummaryArgs {
    month: Scalars['Int']['input'];
    year: Scalars['Int']['input'];
}

export interface LearnerSmsProfileDailyNoticeArgs {
    id: Scalars['UUID']['input'];
}

export interface LearnerSmsProfileDailyNoticesListArgs {
    end: Scalars['DateTime']['input'];
    start: Scalars['DateTime']['input'];
}

export interface LearnerSmsProfileMySchoolInformationItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface LearnerSmsProfileConnection {
    readonly __typename: 'LearnerSmsProfileConnection';
    readonly edges: ReadonlyArray<LearnerSmsProfileEdge>;
    readonly nodes: ReadonlyArray<Maybe<LearnerSmsProfile>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface LearnerSmsProfileEdge {
    readonly __typename: 'LearnerSmsProfileEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<LearnerSmsProfile>;
}

export interface LeaveProviderInput {
    readonly providerId: Scalars['UUID']['input'];
}

export interface LeaveProviderPayload {
    readonly __typename: 'LeaveProviderPayload';
    readonly providerId: Scalars['UUID']['output'];
}

export interface Lesson extends Deletable, Updatable {
    readonly __typename: 'Lesson';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly countryCodes: ReadonlyArray<Scalars['String']['output']>;
    readonly coverImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly defaultLocale: Scalars['Locale']['output'];
    readonly description: Scalars['String']['output'];
    readonly documentId: Scalars['String']['output'];
    readonly documentIframeUrl?: Maybe<Scalars['URL']['output']>;
    readonly favoriteCount: Scalars['Int']['output'];
    readonly hasFavorited: Scalars['Boolean']['output'];
    readonly hasRead: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly isFeatured: Scalars['Boolean']['output'];
    readonly lessonCategory: LessonCategory;
    readonly locale: Scalars['Locale']['output'];
    readonly localizations: ReadonlyArray<LessonLocalization>;
    readonly tags: ReadonlyArray<Scalars['String']['output']>;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface LessonCategory extends Deletable, Updatable {
    readonly __typename: 'LessonCategory';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly defaultLocale: Scalars['Locale']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly locale: Scalars['Locale']['output'];
    readonly localizations: ReadonlyArray<LessonCategoryLocalization>;
    readonly parent?: Maybe<LessonCategory>;
    readonly title: Scalars['String']['output'];
}

export interface LessonCategoryConnection {
    readonly __typename: 'LessonCategoryConnection';
    readonly edges: ReadonlyArray<LessonCategoryEdge>;
    readonly nodes: ReadonlyArray<Maybe<LessonCategory>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface LessonCategoryEdge {
    readonly __typename: 'LessonCategoryEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<LessonCategory>;
}

export interface LessonCategoryLocalization extends Deletable, Updatable {
    readonly __typename: 'LessonCategoryLocalization';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly locale: Scalars['Locale']['output'];
    readonly title: Scalars['String']['output'];
}

export interface LessonConnection {
    readonly __typename: 'LessonConnection';
    readonly edges: ReadonlyArray<LessonEdge>;
    readonly nodes: ReadonlyArray<Maybe<Lesson>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface LessonEdge {
    readonly __typename: 'LessonEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<Lesson>;
}

export interface LessonLocalization extends Deletable, Updatable {
    readonly __typename: 'LessonLocalization';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly coverImageId?: Maybe<Scalars['UUID']['output']>;
    readonly coverImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly description: Scalars['String']['output'];
    readonly documentId: Scalars['String']['output'];
    readonly locale: Scalars['Locale']['output'];
    readonly tags: ReadonlyArray<Scalars['String']['output']>;
    readonly title: Scalars['String']['output'];
}

export enum LessonSortType {
    ALPHABETICAL_A_Z = 'ALPHABETICAL_A_Z',
    ALPHABETICAL_Z_A = 'ALPHABETICAL_Z_A'
}

export interface LockMentorGroupDiscussionInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface LockMentorGroupDiscussionPayload {
    readonly __typename: 'LockMentorGroupDiscussionPayload';
    readonly mentorGroup: MentorGroup;
}

export interface LogEventAttributeInput {
    readonly attributeName: Scalars['String']['input'];
    readonly attributeValue: Scalars['String']['input'];
}

export interface LogEventInput {
    readonly attributes?: InputMaybe<ReadonlyArray<LogEventAttributeInput>>;
    readonly eventId: Scalars['UUID']['input'];
    readonly eventName: Scalars['String']['input'];
    readonly eventTime: Scalars['DateTime']['input'];
    readonly sessionId: Scalars['UUID']['input'];
    readonly userAgent?: InputMaybe<Scalars['String']['input']>;
}

export interface LogEventsInput {
    readonly events: ReadonlyArray<LogEventInput>;
}

export interface LogEventsPayload {
    readonly __typename: 'LogEventsPayload';
    readonly eventsLogged: Scalars['Int']['output'];
}

export interface LoyaltyVerifiableCredential extends WalletCard {
    readonly __typename: 'LoyaltyVerifiableCredential';
    readonly barcodeNumber?: Maybe<Scalars['String']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly expiresAt?: Maybe<Scalars['DateTime']['output']>;
    readonly hexBackgroundColor: Scalars['HexColorCode']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly issuerId: Scalars['String']['output'];
    readonly issuerLogoUrl: Scalars['URL']['output'];
    readonly issuerTitle: Scalars['String']['output'];
    readonly programId: Scalars['String']['output'];
    readonly programTitle: Scalars['String']['output'];
    readonly termsAndConditionsUrl?: Maybe<Scalars['URL']['output']>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly walletCardType: WalletCardType;
}

export interface MentorBadgeStudent {
    readonly __typename: 'MentorBadgeStudent';
    readonly badges: BadgeConnection;
    readonly canCreateBadge: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly learnerSmsProfile?: Maybe<LearnerSmsProfile>;
    readonly provider: Provider;
    readonly user: User;
}

export interface MentorBadgeStudentBadgesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    currentProviderOnly?: InputMaybe<Scalars['Boolean']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorBadgeStudentConnection {
    readonly __typename: 'MentorBadgeStudentConnection';
    readonly edges: ReadonlyArray<MentorBadgeStudentEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorBadgeStudent>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorBadgeStudentEdge {
    readonly __typename: 'MentorBadgeStudentEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorBadgeStudent>;
}

export interface MentorGroup extends Deletable, Updatable {
    readonly __typename: 'MentorGroup';
    readonly bannerImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly canAddMember: Scalars['Boolean']['output'];
    readonly canAddMentorStudent: Scalars['Boolean']['output'];
    readonly canCreateDiscussionPoll: Scalars['Boolean']['output'];
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canLockDiscussion: Scalars['Boolean']['output'];
    readonly canMuteDiscussionUser: Scalars['Boolean']['output'];
    readonly canRegenerateInviteCode: Scalars['Boolean']['output'];
    readonly canRequireDiscussionAcknowledgement: Scalars['Boolean']['output'];
    readonly canSendDiscussionMessage: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly discussionMessages: MentorGroupDiscussionMessageConnection;
    readonly discussionMessagesPinned?: Maybe<ReadonlyArray<Maybe<MentorGroupDiscussionMessage>>>;
    readonly discussionUserState: MentorGroupDiscussionUserState;
    readonly hasJoinedAsStudent: Scalars['Boolean']['output'];
    readonly hasPinned: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly information?: Maybe<Scalars['String']['output']>;
    readonly inviteCode: Scalars['String']['output'];
    readonly isDiscussionLocked: Scalars['Boolean']['output'];
    readonly isJoinable: Scalars['Boolean']['output'];
    readonly isPublic: Scalars['Boolean']['output'];
    readonly members: MentorGroupMemberConnection;
    readonly mentorGroupDiscussionMutedUsers: MentorGroupDiscussionMutedUserConnection;
    readonly missingStudent?: Maybe<MentorGroupMissingStudent>;
    readonly missingStudents: MentorGroupMissingStudentConnection;
    readonly pinnedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly provider: Provider;
    readonly rewardStudents: MentorRewardStudentConnection;
    readonly student?: Maybe<MentorGroupStudent>;
    readonly studentHomeworkDiaryDailyMentorTasks: StudentHomeworkDiaryMentorTaskConnection;
    readonly studentHomeworkDiaryMentorTaskMonthSummary: StudentHomeworkDiaryMentorTaskMonthSummary;
    readonly studentHomeworkDiaryMentorTasks: StudentHomeworkDiaryMentorTaskConnection;
    readonly students: MentorGroupStudentConnection;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorGroupDiscussionMessagesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupMembersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupMentorGroupDiscussionMutedUsersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupMissingStudentArgs {
    id: Scalars['UUID']['input'];
}

export interface MentorGroupMissingStudentsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupRewardStudentsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupStudentArgs {
    id: Scalars['UUID']['input'];
}

export interface MentorGroupStudentHomeworkDiaryDailyMentorTasksArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupStudentHomeworkDiaryMentorTaskMonthSummaryArgs {
    date: Scalars['Date']['input'];
}

export interface MentorGroupStudentHomeworkDiaryMentorTasksArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupStudentsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupConnection {
    readonly __typename: 'MentorGroupConnection';
    readonly edges: ReadonlyArray<MentorGroupEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroup>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
    readonly unreadMessageCount: Scalars['Int']['output'];
}

export interface MentorGroupDiscussionMessage extends Deletable {
    readonly __typename: 'MentorGroupDiscussionMessage';
    readonly acknowledgementStatuses?: Maybe<MentorGroupDiscussionMessageAcknowledgementStatusConnection>;
    readonly canAcknowledge: Scalars['Boolean']['output'];
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canPin: Scalars['Boolean']['output'];
    readonly canReact: Scalars['Boolean']['output'];
    readonly canViewPollResults: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly imageAvailable: Scalars['Boolean']['output'];
    readonly imageId?: Maybe<Scalars['UUID']['output']>;
    readonly imageUrl?: Maybe<Scalars['URL']['output']>;
    readonly isPinned: Scalars['Boolean']['output'];
    readonly lesson?: Maybe<Lesson>;
    readonly message: Scalars['String']['output'];
    readonly messageLinks?: Maybe<ReadonlyArray<Scalars['URL']['output']>>;
    readonly myAcknowledgementStatus?: Maybe<MentorGroupDiscussionMessageAcknowledgementStatus>;
    readonly myReaction?: Maybe<MentorGroupDiscussionMessageReaction>;
    readonly pollExpiresAt?: Maybe<Scalars['DateTime']['output']>;
    readonly pollOptions?: Maybe<ReadonlyArray<MentorGroupDiscussionMessagePollOption>>;
    readonly reactions: MentorGroupDiscussionMessageReactionConnection;
    readonly requiresAcknowledgement: Scalars['Boolean']['output'];
    readonly selectedPollOption?: Maybe<MentorGroupDiscussionMessagePollResponse>;
    readonly totalPollResponsesCount: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorGroupDiscussionMessageAcknowledgementStatusesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupDiscussionMessageReactionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorGroupDiscussionMessageAcknowledgementStatus {
    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
    readonly acknowledged: Scalars['Boolean']['output'];
    readonly acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface MentorGroupDiscussionMessageAcknowledgementStatusConnection {
    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
    readonly edges: ReadonlyArray<MentorGroupDiscussionMessageAcknowledgementStatusEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroupDiscussionMessageAcknowledgementStatus>>;
    readonly pageInfo: PageInfo;
    readonly totalAcknowledgedCount: Scalars['Int']['output'];
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorGroupDiscussionMessageAcknowledgementStatusEdge {
    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorGroupDiscussionMessageAcknowledgementStatus>;
}

export interface MentorGroupDiscussionMessageConnection {
    readonly __typename: 'MentorGroupDiscussionMessageConnection';
    readonly edges: ReadonlyArray<MentorGroupDiscussionMessageEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroupDiscussionMessage>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorGroupDiscussionMessageEdge {
    readonly __typename: 'MentorGroupDiscussionMessageEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorGroupDiscussionMessage>;
}

export interface MentorGroupDiscussionMessagePollOption {
    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
    readonly id: Scalars['UUID']['output'];
    readonly option: Scalars['String']['output'];
    readonly totalResponsesCount: Scalars['Int']['output'];
    readonly totalResponsesCountPercentage: Scalars['Float']['output'];
}

export interface MentorGroupDiscussionMessagePollResponse {
    readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly mentorGroupDiscussionMessagePollOption: MentorGroupDiscussionMessagePollOption;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorGroupDiscussionMessageReaction {
    readonly __typename: 'MentorGroupDiscussionMessageReaction';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly discussionMessage: MentorGroupDiscussionMessage;
    readonly id: Scalars['UUID']['output'];
    readonly type: Scalars['Emoji']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface MentorGroupDiscussionMessageReactionConnection {
    readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
    readonly edges: ReadonlyArray<MentorGroupDiscussionMessageReactionEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroupDiscussionMessageReaction>>;
    readonly pageInfo: PageInfo;
    readonly topEmojis: ReadonlyArray<Scalars['Emoji']['output']>;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorGroupDiscussionMessageReactionEdge {
    readonly __typename: 'MentorGroupDiscussionMessageReactionEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorGroupDiscussionMessageReaction>;
}

export interface MentorGroupDiscussionMutedUserConnection {
    readonly __typename: 'MentorGroupDiscussionMutedUserConnection';
    readonly edges: ReadonlyArray<MentorGroupDiscussionMutedUserEdge>;
    readonly nodes: ReadonlyArray<Maybe<User>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorGroupDiscussionMutedUserEdge {
    readonly __typename: 'MentorGroupDiscussionMutedUserEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<User>;
}

export interface MentorGroupDiscussionUserState {
    readonly __typename: 'MentorGroupDiscussionUserState';
    readonly lastRead?: Maybe<Scalars['DateTime']['output']>;
    readonly lastTyping?: Maybe<Scalars['DateTime']['output']>;
    readonly mutedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly unreadMessageCount: Scalars['Int']['output'];
}

export interface MentorGroupEdge {
    readonly __typename: 'MentorGroupEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorGroup>;
}

export interface MentorGroupMember {
    readonly __typename: 'MentorGroupMember';
    readonly canRemove: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly providerMember: ProviderMember;
}

export interface MentorGroupMemberConnection {
    readonly __typename: 'MentorGroupMemberConnection';
    readonly edges: ReadonlyArray<MentorGroupMemberEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroupMember>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorGroupMemberEdge {
    readonly __typename: 'MentorGroupMemberEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorGroupMember>;
}

export interface MentorGroupMissingStudent {
    readonly __typename: 'MentorGroupMissingStudent';
    readonly id: Scalars['UUID']['output'];
    readonly idNumber: Scalars['String']['output'];
    readonly pictureUrl?: Maybe<Scalars['URL']['output']>;
    readonly preferredFamilyName?: Maybe<Scalars['String']['output']>;
    readonly preferredFullName: Scalars['String']['output'];
    readonly preferredGivenName: Scalars['String']['output'];
}

export interface MentorGroupMissingStudentConnection {
    readonly __typename: 'MentorGroupMissingStudentConnection';
    readonly edges: ReadonlyArray<MentorGroupMissingStudentEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroupMissingStudent>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorGroupMissingStudentEdge {
    readonly __typename: 'MentorGroupMissingStudentEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorGroupMissingStudent>;
}

export interface MentorGroupStudent {
    readonly __typename: 'MentorGroupStudent';
    readonly canRemove: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly mentorGroup: MentorGroup;
    readonly mentorStudent: MentorStudent;
    readonly user: User;
}

export interface MentorGroupStudentConnection {
    readonly __typename: 'MentorGroupStudentConnection';
    readonly edges: ReadonlyArray<MentorGroupStudentEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorGroupStudent>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorGroupStudentEdge {
    readonly __typename: 'MentorGroupStudentEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorGroupStudent>;
}

export interface MentorRewardAnalytics {
    readonly __typename: 'MentorRewardAnalytics';
    readonly pointsSummaryByType: PointsSummaryByType;
    readonly pointsYearSummaryByType: ReadonlyArray<PointsYearSummaryByType>;
    readonly topLearners: ReadonlyArray<MentorRewardTopLearner>;
    readonly topMentorGroups: ReadonlyArray<MentorRewardTopGroup>;
    readonly topMentors: ReadonlyArray<MentorRewardTopMentor>;
}

export interface MentorRewardAnalyticsPointsSummaryByTypeArgs {
    end: Scalars['DateTime']['input'];
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    mentorRewardEventIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    start: Scalars['DateTime']['input'];
}

export interface MentorRewardAnalyticsPointsYearSummaryByTypeArgs {
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    mentorRewardEventIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    year: Scalars['Int']['input'];
}

export interface MentorRewardAnalyticsTopLearnersArgs {
    end: Scalars['DateTime']['input'];
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    mentorRewardEventIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    start: Scalars['DateTime']['input'];
}

export interface MentorRewardAnalyticsTopMentorGroupsArgs {
    end: Scalars['DateTime']['input'];
    start: Scalars['DateTime']['input'];
}

export interface MentorRewardAnalyticsTopMentorsArgs {
    end: Scalars['DateTime']['input'];
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    start: Scalars['DateTime']['input'];
}

export interface MentorRewardEvent {
    readonly __typename: 'MentorRewardEvent';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorRewardEventConnection {
    readonly __typename: 'MentorRewardEventConnection';
    readonly edges: ReadonlyArray<MentorRewardEventEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorRewardEvent>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorRewardEventEdge {
    readonly __typename: 'MentorRewardEventEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorRewardEvent>;
}

export interface MentorRewardItem {
    readonly __typename: 'MentorRewardItem';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly imageId: Scalars['UUID']['output'];
    readonly imageUrl: Scalars['URL']['output'];
    readonly points: Scalars['Int']['output'];
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorRewardStudent {
    readonly __typename: 'MentorRewardStudent';
    readonly availablePoints: Scalars['Int']['output'];
    readonly canConsumeReward: Scalars['Boolean']['output'];
    readonly canGiveReward: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly learnerSmsProfile?: Maybe<LearnerSmsProfile>;
    readonly pointsSummaryRange: MentorRewardStudentPointsSummaryRange;
    readonly provider: Provider;
    readonly totalPoints: Scalars['Int']['output'];
    readonly totalPointsByEvent: ReadonlyArray<MentorRewardStudentEventPoints>;
    readonly totalPointsByValue: ReadonlyArray<MentorRewardStudentValuePoints>;
    readonly transactions: MentorRewardTransactionConnection;
    readonly user: User;
}

export interface MentorRewardStudentPointsSummaryRangeArgs {
    end: Scalars['DateTime']['input'];
    start: Scalars['DateTime']['input'];
}

export interface MentorRewardStudentTransactionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorRewardStudentConnection {
    readonly __typename: 'MentorRewardStudentConnection';
    readonly edges: ReadonlyArray<MentorRewardStudentEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorRewardStudent>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorRewardStudentEdge {
    readonly __typename: 'MentorRewardStudentEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorRewardStudent>;
}

export interface MentorRewardStudentEventPoints {
    readonly __typename: 'MentorRewardStudentEventPoints';
    readonly mentorRewardEvent: MentorRewardEvent;
    readonly totalPoints: Scalars['Int']['output'];
}

export interface MentorRewardStudentPointsSummaryRange {
    readonly __typename: 'MentorRewardStudentPointsSummaryRange';
    readonly totalPoints: Scalars['Int']['output'];
    readonly totalPointsByEvent: ReadonlyArray<MentorRewardStudentEventPoints>;
    readonly totalPointsByValue: ReadonlyArray<MentorRewardStudentValuePoints>;
}

export interface MentorRewardStudentValuePoints {
    readonly __typename: 'MentorRewardStudentValuePoints';
    readonly mentorRewardValue: MentorRewardValue;
    readonly totalPoints: Scalars['Int']['output'];
}

export interface MentorRewardTopGroup {
    readonly __typename: 'MentorRewardTopGroup';
    readonly group: MentorGroup;
    readonly points: Scalars['Int']['output'];
    readonly pointsByOther: Scalars['Int']['output'];
    readonly pointsByValue: ReadonlyArray<MentorRewardStudentValuePoints>;
    readonly rank: Scalars['Int']['output'];
}

export interface MentorRewardTopLearner {
    readonly __typename: 'MentorRewardTopLearner';
    readonly points: Scalars['Int']['output'];
    readonly pointsByOther: Scalars['Int']['output'];
    readonly pointsByValue: ReadonlyArray<MentorRewardStudentValuePoints>;
    readonly rank: Scalars['Int']['output'];
    readonly rewardStudent: MentorRewardStudent;
}

export interface MentorRewardTopMentor {
    readonly __typename: 'MentorRewardTopMentor';
    readonly points: Scalars['Int']['output'];
    readonly rank: Scalars['Int']['output'];
    readonly user: User;
}

export interface MentorRewardTransaction {
    readonly __typename: 'MentorRewardTransaction';
    readonly comment?: Maybe<Scalars['String']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly mentorGroupTitle?: Maybe<Scalars['String']['output']>;
    readonly mentorRewardEvent?: Maybe<MentorRewardEvent>;
    readonly mentorRewardStudent: MentorRewardStudent;
    readonly mentorRewardValue?: Maybe<MentorRewardValue>;
    readonly points: Scalars['Int']['output'];
    readonly type: MentorRewardTransactionType;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorRewardTransactionConnection {
    readonly __typename: 'MentorRewardTransactionConnection';
    readonly edges: ReadonlyArray<MentorRewardTransactionEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorRewardTransaction>>;
    readonly notViewedCount: Scalars['Int']['output'];
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorRewardTransactionEdge {
    readonly __typename: 'MentorRewardTransactionEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorRewardTransaction>;
}

export enum MentorRewardTransactionType {
    GET = 'GET',
    USE = 'USE'
}

export interface MentorRewardValue {
    readonly __typename: 'MentorRewardValue';
    readonly color: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly worth: Scalars['Int']['output'];
}

export interface MentorSessionsSummary {
    readonly __typename: 'MentorSessionsSummary';
    readonly sessionCount: Scalars['Int']['output'];
    readonly sessionDuration: Scalars['String']['output'];
    readonly user: User;
}

export interface MentorSessionsSummaryConnection {
    readonly __typename: 'MentorSessionsSummaryConnection';
    readonly edges: ReadonlyArray<MentorSessionsSummaryEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorSessionsSummary>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorSessionsSummaryEdge {
    readonly __typename: 'MentorSessionsSummaryEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorSessionsSummary>;
}

export interface MentorSharedResource extends Deletable, Updatable {
    readonly __typename: 'MentorSharedResource';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly provider: Provider;
    readonly sharedResourceType: MentorSharedResourceType;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorSharedResourceConnection {
    readonly __typename: 'MentorSharedResourceConnection';
    readonly edges: ReadonlyArray<MentorSharedResourceEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorSharedResource>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorSharedResourceEdge {
    readonly __typename: 'MentorSharedResourceEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorSharedResource>;
}

export enum MentorSharedResourceType {
    GETTING_ORGANISED = 'GETTING_ORGANISED',
    GOALS = 'GOALS',
    HEALTH_AND_WELLBEING = 'HEALTH_AND_WELLBEING',
    LEARNING = 'LEARNING',
    MONEY = 'MONEY',
    REFLECTIONS = 'REFLECTIONS',
    VALUES_AND_BELIEFS = 'VALUES_AND_BELIEFS',
    WORK_READY = 'WORK_READY'
}

export interface MentorStudent {
    readonly __typename: 'MentorStudent';
    readonly assessmentResultsList: ReadonlyArray<AssessmentResult>;
    readonly attendanceSummary?: Maybe<LearnerSmsAttendanceSummary>;
    readonly attendanceYearMonthSummary?: Maybe<LearnerSmsAttendanceSummary>;
    readonly availableMentorGroups: AvailableMentorGroupConnection;
    readonly badges: BadgeConnection;
    readonly canCreateBadge: Scalars['Boolean']['output'];
    readonly canRemove: Scalars['Boolean']['output'];
    readonly canSetAsMain: Scalars['Boolean']['output'];
    readonly curriculumVitae?: Maybe<CurriculumVitae>;
    readonly dailyNoticesList?: Maybe<ReadonlyArray<DailyNotice>>;
    readonly goals?: Maybe<GoalConnection>;
    readonly groups: ReadonlyArray<MentorGroupStudent>;
    readonly id: Scalars['UUID']['output'];
    readonly isAssessmentResultsAvailable: Scalars['Boolean']['output'];
    readonly isAttendanceAvailable: Scalars['Boolean']['output'];
    readonly isDailyNoticesAvailable: Scalars['Boolean']['output'];
    readonly isMain: Scalars['Boolean']['output'];
    readonly isSharingCurriculumVitae: Scalars['Boolean']['output'];
    readonly isSharingGoals: Scalars['Boolean']['output'];
    readonly isSharingOtherProviderActivity: Scalars['Boolean']['output'];
    readonly isSharingPathwayPlanners: Scalars['Boolean']['output'];
    readonly isSharingReflections: Scalars['Boolean']['output'];
    readonly isSharingResourceHubItems: Scalars['Boolean']['output'];
    readonly isSharingSavedCourses: Scalars['Boolean']['output'];
    readonly isTimetableAvailable: Scalars['Boolean']['output'];
    readonly isTimetableDisabled: Scalars['Boolean']['output'];
    readonly jobProfile?: Maybe<JobProfile>;
    readonly learnerSmsProfile?: Maybe<LearnerSmsProfile>;
    readonly mentorGroups: ReadonlyArray<MentorGroup>;
    readonly mySchoolInformationItems: ProviderMySchoolInformationConnection;
    readonly notes: MentorStudentNoteConnection;
    readonly pathwayPlanners?: Maybe<PathwayPlannerConnection>;
    readonly projects: ProjectConnection;
    readonly provider: Provider;
    readonly reflections?: Maybe<ReflectionConnection>;
    readonly resourceHubItems?: Maybe<ResourceHubItemConnection>;
    readonly rewards: MentorRewardStudent;
    readonly savedStudyProgrammes?: Maybe<SavedStudyProgrammeConnection>;
    readonly sharedActivity?: Maybe<ReadonlyArray<MentorStudentSharedActivityType>>;
    readonly studentHomeworkDiaryDailyItems: StudentHomeworkDiaryConnection;
    readonly studentHomeworkDiaryMonthSummary: StudentHomeworkDiaryMonthSummary;
    readonly timetableEventsList?: Maybe<ReadonlyArray<TimetableEvent>>;
    readonly todos: MentorStudentTodoConnection;
    readonly user: User;
}

export interface MentorStudentAttendanceSummaryArgs {
    end: Scalars['Date']['input'];
    start: Scalars['Date']['input'];
}

export interface MentorStudentAttendanceYearMonthSummaryArgs {
    month: Scalars['Int']['input'];
    year: Scalars['Int']['input'];
}

export interface MentorStudentAvailableMentorGroupsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorStudentBadgesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    currentProviderOnly?: InputMaybe<Scalars['Boolean']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorStudentDailyNoticesListArgs {
    end: Scalars['DateTime']['input'];
    start: Scalars['DateTime']['input'];
}

export interface MentorStudentGoalsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<GoalType>>;
}

export interface MentorStudentMySchoolInformationItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorStudentNotesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface MentorStudentPathwayPlannersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface MentorStudentProjectsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorStudentReflectionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<ReflectionType>>;
}

export interface MentorStudentResourceHubItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    mimeTypes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface MentorStudentSavedStudyProgrammesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorStudentStudentHomeworkDiaryDailyItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface MentorStudentStudentHomeworkDiaryMonthSummaryArgs {
    date: Scalars['Date']['input'];
}

export interface MentorStudentTimetableEventsListArgs {
    end: Scalars['DateTime']['input'];
    start: Scalars['DateTime']['input'];
}

export interface MentorStudentTodosArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface MentorStudentConnection {
    readonly __typename: 'MentorStudentConnection';
    readonly edges: ReadonlyArray<MentorStudentEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorStudent>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorStudentEdge {
    readonly __typename: 'MentorStudentEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorStudent>;
}

export interface MentorStudentInvite {
    readonly __typename: 'MentorStudentInvite';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly email: Scalars['EmailAddress']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly mentorGroups: ReadonlyArray<MentorGroup>;
    readonly provider: Provider;
    readonly redeemedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface MentorStudentNote extends Deletable, Updatable {
    readonly __typename: 'MentorStudentNote';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly isShared: Scalars['Boolean']['output'];
    readonly mentorGroup?: Maybe<MentorGroup>;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorStudentNoteConnection {
    readonly __typename: 'MentorStudentNoteConnection';
    readonly edges: ReadonlyArray<MentorStudentNoteEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorStudentNote>>;
    readonly notViewedCount: Scalars['Int']['output'];
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorStudentNoteEdge {
    readonly __typename: 'MentorStudentNoteEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorStudentNote>;
}

export enum MentorStudentSharedActivityType {
    CURRICULUM_VITAE = 'CURRICULUM_VITAE',
    GOALS = 'GOALS',
    PATHWAY_PLANNERS = 'PATHWAY_PLANNERS',
    REFLECTIONS = 'REFLECTIONS',
    RESOURCE_HUB_ITEMS = 'RESOURCE_HUB_ITEMS',
    SAVED_COURSES = 'SAVED_COURSES'
}

export interface MentorStudentTodo extends Deletable, Updatable {
    readonly __typename: 'MentorStudentTodo';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly complete: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly isShared: Scalars['Boolean']['output'];
    readonly mentorGroup?: Maybe<MentorGroup>;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MentorStudentTodoConnection {
    readonly __typename: 'MentorStudentTodoConnection';
    readonly edges: ReadonlyArray<MentorStudentTodoEdge>;
    readonly nodes: ReadonlyArray<Maybe<MentorStudentTodo>>;
    readonly notViewedCount: Scalars['Int']['output'];
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface MentorStudentTodoEdge {
    readonly __typename: 'MentorStudentTodoEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<MentorStudentTodo>;
}

export interface Mutation {
    readonly __typename: 'Mutation';
    readonly acceptMentorStudentInviteCode: AcceptMentorStudentInviteCodePayload;
    readonly acceptUserConnectionRequest: AcceptUserConnectionRequestPayload;
    readonly acknowledgeMentorGroupDiscussionMessage: AcknowledgeMentorGroupDiscussionMessagePayload;
    readonly addMentorGroupMember: AddMentorGroupMemberPayload;
    readonly addMentorGroupStudent: AddMentorGroupStudentPayload;
    readonly attachProviderIntegrationLissToParentIntegration: AttachProviderIntegrationLissToParentIntegrationPayload;
    readonly checkUserVerificationRequestResult: CheckUserVerificationRequestResultPayload;
    readonly checkVerifiedIdCardRequest: CheckVerifiedIdCardRequestPayload;
    readonly chooseMentorGroupDiscussionPollResponse: ChooseMentorGroupDiscussionPollResponsePayload;
    readonly chooseProviderPostPollResponse: ChooseProviderPostPollResponsePayload;
    readonly completeProjectSection: CompleteProjectSectionPayload;
    readonly completeProjectSectionReview: CompleteProjectSectionReviewPayload;
    readonly consumeMentorReward: ConsumeMentorRewardPayload;
    readonly contactStudentJobProfile: ContactStudentJobProfilePayload;
    readonly copyProviderProjectTemplate: CopyProviderProjectTemplatePayload;
    readonly createBadge: CreateBadgePayload;
    readonly createBadges: CreateBadgesPayload;
    readonly createGoal: CreateGoalPayload;
    readonly createGoalNote: CreateGoalNotePayload;
    readonly createGoalTask: CreateGoalTaskPayload;
    readonly createLesson: CreateLessonPayload;
    readonly createLessonLocalization: CreateLessonLocalizationPayload;
    readonly createMentorGroup: CreateMentorGroupPayload;
    readonly createMentorRewardItem: CreateMentorRewardItemPayload;
    readonly createMentorRewardValue: CreateMentorRewardValuePayload;
    readonly createMentorSharedResource: CreateMentorSharedResourcePayload;
    readonly createMentorStudentNote: CreateMentorStudentNotePayload;
    readonly createMentorStudentTodo: CreateMentorStudentTodoPayload;
    readonly createOpportunityListing: CreateOpportunityListingPayload;
    readonly createPathwayPlanner: CreatePathwayPlannerPayload;
    readonly createPathwayPlannerPersonalRequirement: CreatePathwayPlannerPersonalRequirementPayload;
    readonly createPathwayPlannerQualification: CreatePathwayPlannerQualificationPayload;
    readonly createPathwayPlannerSkillAndKnowledge: CreatePathwayPlannerSkillAndKnowledgePayload;
    readonly createPathwayPlannerStage: CreatePathwayPlannerStagePayload;
    readonly createPathwayPlannerStageSubject: CreatePathwayPlannerStageSubjectPayload;
    readonly createPathwayPlannerStageUsefulExperience: CreatePathwayPlannerStageUsefulExperiencePayload;
    readonly createPathwayPlannerUsefulExperience: CreatePathwayPlannerUsefulExperiencePayload;
    readonly createProject: CreateProjectPayload;
    readonly createProjectSectionComment: CreateProjectSectionCommentPayload;
    readonly createProjectSectionEvidence: CreateProjectSectionEvidencePayload;
    readonly createProjectsForMentorGroupStudents: CreateProjectsForMentorGroupStudentsPayload;
    readonly createProvider: CreateProviderPayload;
    readonly createProviderBadgeTemplate: CreateProviderBadgeTemplatePayload;
    readonly createProviderIntegration: CreateProviderIntegrationPayload;
    readonly createProviderMemberInvite: CreateProviderMemberInvitePayload;
    readonly createProviderMemberInvites: CreateProviderMemberInvitesPayload;
    readonly createProviderMySchoolInformation: CreateProviderMySchoolInformationPayload;
    readonly createProviderPost: CreateProviderPostPayload;
    readonly createProviderProjectTemplate: CreateProviderProjectTemplatePayload;
    readonly createProviderProjectTemplateOverviewResource: CreateProviderProjectTemplateOverviewResourcePayload;
    readonly createProviderProjectTemplateSection: CreateProviderProjectTemplateSectionPayload;
    readonly createProviderProjectTemplateSectionResource: CreateProviderProjectTemplateSectionResourcePayload;
    readonly createReflection: CreateReflectionPayload;
    readonly createResourceHubItem: CreateResourceHubItemPayload;
    readonly createSponsorAsset: CreateSponsorAssetPayload;
    readonly createSponsorCampaign: CreateSponsorCampaignPayload;
    readonly createSponsorCampaignAsset: CreateSponsorCampaignAssetPayload;
    readonly createSponsorProvider: CreateSponsorProviderPayload;
    readonly createStudentHomeworkDiaryItem: CreateStudentHomeworkDiaryItemPayload;
    readonly createStudentHomeworkDiaryMentorTask: CreateStudentHomeworkDiaryMentorTaskPayload;
    readonly createStudyCategory: CreateStudyCategoryPayload;
    readonly createStudyProgramme: CreateStudyProgrammePayload;
    readonly createStudyProvider: CreateStudyProviderPayload;
    readonly createThreeGoodThings: CreateThreeGoodThingsPayload;
    readonly deleteBadge: DeleteBadgePayload;
    readonly deleteGoal: DeleteGoalPayload;
    readonly deleteGoalNote: DeleteGoalNotePayload;
    readonly deleteGoalTask: DeleteGoalTaskPayload;
    readonly deleteLesson: DeleteLessonPayload;
    readonly deleteLessonLocalization: DeleteLessonLocalizationPayload;
    readonly deleteMentorGroup: DeleteMentorGroupPayload;
    readonly deleteMentorGroupBannerImage: DeleteMentorGroupBannerImagePayload;
    readonly deleteMentorGroupDiscussionMessage: DeleteMentorGroupDiscussionMessagePayload;
    readonly deleteMentorRewardItem: DeleteMentorRewardItemPayload;
    readonly deleteMentorRewardValue: DeleteMentorRewardValuePayload;
    readonly deleteMentorSharedResource: DeleteMentorSharedResourcePayload;
    readonly deleteMentorStudentNote: DeleteMentorStudentNotePayload;
    readonly deleteMentorStudentTodo: DeleteMentorStudentTodoPayload;
    readonly deletePathwayPlanner: DeletePathwayPlannerPayload;
    readonly deletePathwayPlannerPersonalRequirement: DeletePathwayPlannerPersonalRequirementPayload;
    readonly deletePathwayPlannerQualification: DeletePathwayPlannerQualificationPayload;
    readonly deletePathwayPlannerSkillAndKnowledge: DeletePathwayPlannerSkillAndKnowledgePayload;
    readonly deletePathwayPlannerStage: DeletePathwayPlannerStagePayload;
    readonly deletePathwayPlannerStageSubject: DeletePathwayPlannerStageSubjectPayload;
    readonly deletePathwayPlannerStageUsefulExperience: DeletePathwayPlannerStageUsefulExperiencePayload;
    readonly deletePathwayPlannerUsefulExperience: DeletePathwayPlannerUsefulExperiencePayload;
    readonly deleteProject: DeleteProjectPayload;
    readonly deleteProjectSectionComment: DeleteProjectSectionCommentPayload;
    readonly deleteProjectSectionEvidence: DeleteProjectSectionEvidencePayload;
    readonly deleteProvider: DeleteProviderPayload;
    readonly deleteProviderBadgeTemplate: DeleteProviderBadgeTemplatePayload;
    readonly deleteProviderJoinCode: DeleteProviderJoinCodePayload;
    readonly deleteProviderMember: DeleteProviderMemberPayload;
    readonly deleteProviderMemberInvite: DeleteProviderMemberInvitePayload;
    readonly deleteProviderMySchoolInformation: DeleteProviderMySchoolInformationPayload;
    readonly deleteProviderPost: DeleteProviderPostPayload;
    readonly deleteProviderProjectTemplate: DeleteProviderProjectTemplatePayload;
    readonly deleteProviderProjectTemplateOverviewResource: DeleteProviderProjectTemplateOverviewResourcePayload;
    readonly deleteProviderProjectTemplateSection: DeleteProviderProjectTemplateSectionPayload;
    readonly deleteProviderProjectTemplateSectionResource: DeleteProviderProjectTemplateSectionResourcePayload;
    readonly deleteReflection: DeleteReflectionPayload;
    readonly deleteResourceHubItem: DeleteResourceHubItemPayload;
    readonly deleteSponsorAsset: DeleteSponsorAssetPayload;
    readonly deleteSponsorCampaign: DeleteSponsorCampaignPayload;
    readonly deleteSponsorCampaignAsset: DeleteSponsorCampaignAssetPayload;
    readonly deleteSponsorProvider: DeleteSponsorProviderPayload;
    readonly deleteStudentHomeworkDiaryItem: DeleteStudentHomeworkDiaryItemPayload;
    readonly deleteStudentHomeworkDiaryMentorTask: DeleteStudentHomeworkDiaryMentorTaskPayload;
    readonly deleteStudyCategory: DeleteStudyCategoryPayload;
    readonly deleteStudyProgramme: DeleteStudyProgrammePayload;
    readonly deleteStudyProvider: DeleteStudyProviderPayload;
    readonly deleteThreeGoodThings: DeleteThreeGoodThingsPayload;
    readonly disableJobProfile: DisableJobProfilePayload;
    readonly disableProviderJoinCode: DisableProviderJoinCodePayload;
    readonly disableProviderModule: DisableProviderModulePayload;
    readonly disconnectMentorGroup: DisconnectMentorGroupPayload;
    readonly disconnectMentorProvider: DisconnectMentorProviderPayload;
    readonly enableJobProfile: EnableJobProfilePayload;
    readonly enableProviderJoinCode: EnableProviderJoinCodePayload;
    readonly enableProviderModule: EnableProviderModulePayload;
    readonly enquireStudyProgramme: EnquireStudyProgrammePayload;
    readonly enrolInStudyProgramme: EnrolInStudyProgrammePayload;
    readonly expireOpportunityListing: ExpireOpportunityListingPayload;
    readonly followProvider: FollowProviderPayload;
    readonly generateCoverLetter: GenerateCoverLetterPayload;
    readonly generateCurriculumVitaeFile: GenerateCurriculumVitaeFilePayload;
    readonly generatePathwayPlannerInformationSuggestions: GeneratePathwayPlannerInformationSuggestionsPayload;
    readonly generateProviderJoinCode: GenerateProviderJoinCodePayload;
    readonly giveMentorRewards: GiveMentorRewardsPayload;
    readonly importMentorRewardEventPoints: ImportMentorRewardEventPointsPayload;
    readonly importMentorRewardPoints: ImportMentorRewardPointsPayload;
    readonly joinAvailableMentorGroup: JoinAvailableMentorGroupPayload;
    readonly joinAvailableMentorProvider: JoinAvailableMentorProviderPayload;
    readonly joinJoinableMentorGroup: JoinJoinableMentorGroupPayload;
    readonly leaveProvider: LeaveProviderPayload;
    readonly lockMentorGroupDiscussion: LockMentorGroupDiscussionPayload;
    readonly logEvents: LogEventsPayload;
    readonly muteMentorGroupDiscussionUser: MuteMentorGroupDiscussionUserPayload;
    readonly pinMentorGroupDiscussionMessage: PinMentorGroupDiscussionMessagePayload;
    readonly publishSponsorCampaign: PublishSponsorCampaignPayload;
    readonly queueDeleteProviderIntegrationEdgeLearning: QueueDeleteProviderIntegrationEdgeLearningPayload;
    readonly queueDeleteProviderIntegrationKamar: QueueDeleteProviderIntegrationKamarPayload;
    readonly queueDeleteProviderIntegrationLiss: QueueDeleteProviderIntegrationLissPayload;
    readonly queueDeleteProviderIntegrationManualSmsData: QueueDeleteProviderIntegrationManualSmsDataPayload;
    readonly queueDeleteProviderIntegrationWonde: QueueDeleteProviderIntegrationWondePayload;
    readonly queueDeleteProviderRewards: QueueDeleteProviderRewardPayload;
    readonly queueImportMentorGroupsFromLearnerSmsData: QueueImportMentorGroupsFromLearnerSmsDataPayload;
    readonly queueUrlScrape: QueueUrlScrapePayload;
    readonly reactMentorGroupDiscussionMessage: ReactMentorGroupDiscussionMessagePayload;
    readonly reactProviderPost: ReactProviderPostPayload;
    readonly reactivateUserEmail: ReactivateUserEmailSuppressionPayload;
    readonly redeemProviderJoinCode: RedeemProviderJoinCodePayload;
    readonly regenerateMentorGroupInviteCode: RegenerateMentorGroupInviteCodePayload;
    readonly regenerateProviderIntegrationKamarToken: RegenerateProviderIntegrationKamarTokenPayload;
    readonly regenerateProviderIntegrationLissToken: RegenerateProviderIntegrationLissTokenPayload;
    readonly rejectUserConnectionRequest: RejectUserConnectionRequestPayload;
    readonly removeConnectedUser: RemoveConnectedUserPayload;
    readonly removeMentorGroupMember: RemoveMentorGroupMemberPayload;
    readonly removeMentorGroupStudent: RemoveMentorGroupStudentPayload;
    readonly removeMentorStudent: RemoveMentorStudentPayload;
    readonly reportJobProfileActivity: ReportJobProfileActivityPayload;
    readonly repostProviderPost: RepostProviderPostPayload;
    readonly requestProjectSectionReview: RequestProjectSectionReviewPayload;
    readonly requestProviderModule: RequestProviderModulePayload;
    readonly requestUserConnection: RequestUserConnectionPayload;
    readonly requestVerifiedIdCard: RequestVerifiedIdCardPayload;
    readonly resolveWalletCardPresentationSimple: ResolveWalletCardPresentationSimplePayload;
    readonly resyncProviderIntegrationWondeData: ResyncProviderIntegrationWondeDataPayload;
    readonly saveStudyProgramme: SaveStudyProgrammePayload;
    readonly sendBroadcastEmail: SendBroadcastEmailPayload;
    readonly sendMentorGroupDiscussionMessage: SendMentorGroupDiscussionMessagePayload;
    readonly sendMentorGroupInvites: SendMentorGroupInvitesPayload;
    readonly setMentorStudentAsMain: SetMentorStudentAsMainPayload;
    readonly shareMentorStudentNote: ShareMentorStudentNotePayload;
    readonly shareMentorStudentTodo: ShareMentorStudentTodoPayload;
    readonly startCurriculumVitae: StartCurriculumVitaePayload;
    readonly startMentorGroupDiscussionMessageImageUpload: StartMentorGroupDiscussionMessageImageUploadPayload;
    readonly startProviderImageUpload: StartProviderImageUploadPayload;
    readonly startUserImageUpload: StartUserImageUploadPayload;
    readonly startUserVerificationRequest: StartUserVerificationRequestPayload;
    readonly startWalletCardVerificationSimple: StartWalletCardVerificationSimplePayload;
    readonly submitCareersRiasecResult: SubmitCareersRiasecResultPayload;
    readonly toggleFavoriteLesson: ToggleFavoriteLessonPayload;
    readonly toggleFeaturedLesson: ToggleFeaturedLessonPayload;
    readonly toggleIsFavouriteStudentJobProfile: ToggleIsFavouriteStudentJobProfilePayload;
    readonly toggleMentorGroupJoinability: ToggleMentorGroupJoinabilityPayload;
    readonly togglePinnedConnection: TogglePinnedConnectionPayload;
    readonly togglePinnedMentorGroup: TogglePinnedMentorGroupPayload;
    readonly toggleProviderLessonAccess: ToggleProviderLessonAccessPayload;
    readonly toggleReadLesson: ToggleReadLessonPayload;
    readonly toggleStudentHomeworkDiaryItemCompletion: ToggleStudentHomeworkDiaryItemCompletionPayload;
    readonly uncompleteProjectSection: UncompleteProjectSectionPayload;
    readonly unfollowProvider: UnfollowProviderPayload;
    readonly unlinkAccount: UnlinkAccountPayload;
    readonly unlockMentorGroupDiscussion: UnlockMentorGroupDiscussionPayload;
    readonly unmuteMentorGroupDiscussionUser: UnmuteMentorGroupDiscussionUserPayload;
    readonly unpinMentorGroupDiscussionMessage: UnpinMentorGroupDiscussionMessagePayload;
    readonly unpublishSponsorCampaign: UnpublishSponsorCampaignPayload;
    readonly unreactMentorGroupDiscussionMessage: UnreactMentorGroupDiscussionMessagePayload;
    readonly unreactProviderPost: UnreactProviderPostPayload;
    readonly unsaveStudyProgramme: UnsaveStudyProgrammePayload;
    readonly unshareMentorStudentNote: UnshareMentorStudentNotePayload;
    readonly unshareMentorStudentTodo: UnshareMentorStudentTodoPayload;
    readonly updateConnectedUserActivitySharing: UpdateConnectedUserActivitySharingPayload;
    readonly updateCurriculumVitae: UpdateCurriculumVitaePayload;
    readonly updateGoal: UpdateGoalPayload;
    readonly updateGoalNote: UpdateGoalNotePayload;
    readonly updateGoalTask: UpdateGoalTaskPayload;
    readonly updateJobProfile: UpdateJobProfilePayload;
    readonly updateLesson: UpdateLessonPayload;
    readonly updateLessonLocalization: UpdateLessonLocalizationPayload;
    readonly updateMentorGroup: UpdateMentorGroupPayload;
    readonly updateMentorGroupBannerImage: UpdateMentorGroupBannerImagePayload;
    readonly updateMentorGroupDiscussionLastRead: UpdateMentorGroupDiscussionLastReadPayload;
    readonly updateMentorGroupDiscussionLastTyping: UpdateMentorGroupDiscussionLastTypingPayload;
    readonly updateMentorGroupInformation: UpdateMentorGroupInformationPayload;
    readonly updateMentorRewardItem: UpdateMentorRewardItemPayload;
    readonly updateMentorRewardValue: UpdateMentorRewardValuePayload;
    readonly updateMentorRewardViewed: UpdateMentorRewardViewedPayload;
    readonly updateMentorSharedResource: UpdateMentorSharedResourcePayload;
    readonly updateMentorStudentNote: UpdateMentorStudentNotePayload;
    readonly updateMentorStudentNoteViewed: UpdateMentorStudentNoteViewedPayload;
    readonly updateMentorStudentSharedActivity: UpdateMentorStudentSharedActivityPayload;
    readonly updateMentorStudentTodo: UpdateMentorStudentTodoPayload;
    readonly updateMentorStudentTodoViewed: UpdateMentorStudentTodoViewedPayload;
    readonly updateOpportunityListing: UpdateOpportunityListingPayload;
    readonly updatePathwayPlanner: UpdatePathwayPlannerPayload;
    readonly updatePathwayPlannerPersonalRequirement: UpdatePathwayPlannerPersonalRequirementPayload;
    readonly updatePathwayPlannerQualification: UpdatePathwayPlannerQualificationPayload;
    readonly updatePathwayPlannerSkillAndKnowledge: UpdatePathwayPlannerSkillAndKnowledgePayload;
    readonly updatePathwayPlannerStage: UpdatePathwayPlannerStagePayload;
    readonly updatePathwayPlannerStageSubject: UpdatePathwayPlannerStageSubjectPayload;
    readonly updatePathwayPlannerStageUsefulExperience: UpdatePathwayPlannerStageUsefulExperiencePayload;
    readonly updatePathwayPlannerUsefulExperience: UpdatePathwayPlannerUsefulExperiencePayload;
    readonly updateProjectMentorGroup: UpdateProjectMentorGroupPayload;
    readonly updateProjectSectionComment: UpdateProjectSectionCommentPayload;
    readonly updateProjectSectionEvidence: UpdateProjectSectionEvidencePayload;
    readonly updateProjectSectionResponse: UpdateProjectSectionResponsePayload;
    readonly updateProviderDetails: UpdateProviderDetailsPayload;
    readonly updateProviderIntegrationKamarTimetableDisabledUntil: UpdateProviderIntegrationKamarTimetableDisabledUntilPayload;
    readonly updateProviderIntegrationManualSmsDataEmployees: UpdateProviderIntegrationManualSmsDataEmployeesPayload;
    readonly updateProviderIntegrationManualSmsDataGroupEmployees: UpdateProviderIntegrationManualSmsDataGroupEmployeesPayload;
    readonly updateProviderIntegrationManualSmsDataGroupStudents: UpdateProviderIntegrationManualSmsDataGroupStudentsPayload;
    readonly updateProviderIntegrationManualSmsDataGroups: UpdateProviderIntegrationManualSmsDataGroupsPayload;
    readonly updateProviderIntegrationManualSmsDataStudents: UpdateProviderIntegrationManualSmsDataStudentsPayload;
    readonly updateProviderMemberRoles: UpdateProviderMemberRolesPayload;
    readonly updateProviderMySchoolInformation: UpdateProviderMySchoolInformationPayload;
    readonly updateProviderMySchoolInformationSequence: UpdateProviderMySchoolInformationSequencePayload;
    readonly updateProviderPost: UpdateProviderPostPayload;
    readonly updateProviderProjectTemplate: UpdateProviderProjectTemplatePayload;
    readonly updateProviderProjectTemplateBannerImage: UpdateProviderProjectTemplateBannerImagePayload;
    readonly updateProviderProjectTemplateOverviewResource: UpdateProviderProjectTemplateOverviewResourcePayload;
    readonly updateProviderProjectTemplateSection: UpdateProviderProjectTemplateSectionPayload;
    readonly updateProviderProjectTemplateSectionOrder: UpdateProviderProjectTemplateSectionOrderPayload;
    readonly updateProviderProjectTemplateSectionResource: UpdateProviderProjectTemplateSectionResourcePayload;
    readonly updateReflection: UpdateReflectionPayload;
    readonly updateResourceHubItem: UpdateResourceHubItemPayload;
    readonly updateSponsorAsset: UpdateSponsorAssetPayload;
    readonly updateSponsorCampaign: UpdateSponsorCampaignPayload;
    readonly updateSponsorCampaignAsset: UpdateSponsorCampaignAssetPayload;
    readonly updateSponsorProvider: UpdateSponsorProviderPayload;
    readonly updateStudentHomeworkDiaryItem: UpdateStudentHomeworkDiaryItemPayload;
    readonly updateStudentHomeworkDiaryMentorTask: UpdateStudentHomeworkDiaryMentorTaskPayload;
    readonly updateStudyCategory: UpdateStudyCategoryPayload;
    readonly updateStudyProgramme: UpdateStudyProgrammePayload;
    readonly updateStudyProvider: UpdateStudyProviderPayload;
    readonly updateThreeGoodThings: UpdateThreeGoodThingsPayload;
    readonly updateUser: UpdateUserPayload;
    readonly updateUserEmailAddress: UpdateUserEmailAddressPayload;
    readonly updateUserEmailNotificationsSetting: UpdateUserEmailNotificationsSettingPayload;
    readonly updateUserPicture: UpdateUserPicturePayload;
    readonly validateMentorStudentInviteCode: ValidateMentorStudentInviteCodePayload;
    readonly validateUserConnectionRequest: ValidateUserConnectionRequestPayload;
}

export interface MutationAcceptMentorStudentInviteCodeArgs {
    input: AcceptMentorStudentInviteCodeInput;
}

export interface MutationAcceptUserConnectionRequestArgs {
    input: AcceptUserConnectionRequestInput;
}

export interface MutationAcknowledgeMentorGroupDiscussionMessageArgs {
    input: AcknowledgeMentorGroupDiscussionMessageInput;
}

export interface MutationAddMentorGroupMemberArgs {
    input: AddMentorGroupMemberInput;
}

export interface MutationAddMentorGroupStudentArgs {
    input: AddMentorGroupStudentInput;
}

export interface MutationAttachProviderIntegrationLissToParentIntegrationArgs {
    input: AttachProviderIntegrationLissToParentIntegrationInput;
}

export interface MutationCheckUserVerificationRequestResultArgs {
    input: CheckUserVerificationRequestResultInput;
}

export interface MutationCheckVerifiedIdCardRequestArgs {
    input: CheckVerifiedIdCardRequestInput;
}

export interface MutationChooseMentorGroupDiscussionPollResponseArgs {
    input: ChooseMentorGroupDiscussionPollResponseInput;
}

export interface MutationChooseProviderPostPollResponseArgs {
    input: ChooseProviderPostPollResponseInput;
}

export interface MutationCompleteProjectSectionArgs {
    input: CompleteProjectSectionInput;
}

export interface MutationCompleteProjectSectionReviewArgs {
    input: CompleteProjectSectionReviewInput;
}

export interface MutationConsumeMentorRewardArgs {
    input: ConsumeMentorRewardInput;
}

export interface MutationContactStudentJobProfileArgs {
    input: ContactStudentJobProfileInput;
}

export interface MutationCopyProviderProjectTemplateArgs {
    input: CopyProviderProjectTemplateInput;
}

export interface MutationCreateBadgeArgs {
    input: CreateBadgeInput;
}

export interface MutationCreateBadgesArgs {
    input: CreateBadgesInput;
}

export interface MutationCreateGoalArgs {
    input: CreateGoalInput;
}

export interface MutationCreateGoalNoteArgs {
    input: CreateGoalNoteInput;
}

export interface MutationCreateGoalTaskArgs {
    input: CreateGoalTaskInput;
}

export interface MutationCreateLessonArgs {
    input: CreateLessonInput;
}

export interface MutationCreateLessonLocalizationArgs {
    input: CreateLessonLocalizationInput;
}

export interface MutationCreateMentorGroupArgs {
    input: CreateMentorGroupInput;
}

export interface MutationCreateMentorRewardItemArgs {
    input: CreateMentorRewardItemInput;
}

export interface MutationCreateMentorRewardValueArgs {
    input: CreateMentorRewardValueInput;
}

export interface MutationCreateMentorSharedResourceArgs {
    input: CreateMentorSharedResourceInput;
}

export interface MutationCreateMentorStudentNoteArgs {
    input: CreateMentorStudentNoteInput;
}

export interface MutationCreateMentorStudentTodoArgs {
    input: CreateMentorStudentTodoInput;
}

export interface MutationCreateOpportunityListingArgs {
    input: CreateOpportunityListingInput;
}

export interface MutationCreatePathwayPlannerArgs {
    input: CreatePathwayPlannerInput;
}

export interface MutationCreatePathwayPlannerPersonalRequirementArgs {
    input: CreatePathwayPlannerPersonalRequirementTodoInput;
}

export interface MutationCreatePathwayPlannerQualificationArgs {
    input: CreatePathwayPlannerQualificationTodoInput;
}

export interface MutationCreatePathwayPlannerSkillAndKnowledgeArgs {
    input: CreatePathwayPlannerSkillAndKnowledgeTodoInput;
}

export interface MutationCreatePathwayPlannerStageArgs {
    input: CreatePathwayPlannerStageInput;
}

export interface MutationCreatePathwayPlannerStageSubjectArgs {
    input: CreatePathwayPlannerStageSubjectTodoInput;
}

export interface MutationCreatePathwayPlannerStageUsefulExperienceArgs {
    input: CreatePathwayPlannerStageUsefulExperienceTodoInput;
}

export interface MutationCreatePathwayPlannerUsefulExperienceArgs {
    input: CreatePathwayPlannerUsefulExperienceTodoInput;
}

export interface MutationCreateProjectArgs {
    input: CreateProjectInput;
}

export interface MutationCreateProjectSectionCommentArgs {
    input: CreateProjectSectionCommentInput;
}

export interface MutationCreateProjectSectionEvidenceArgs {
    input: CreateProjectSectionEvidenceInput;
}

export interface MutationCreateProjectsForMentorGroupStudentsArgs {
    input: CreateProjectsForMentorGroupStudentsInput;
}

export interface MutationCreateProviderArgs {
    input: CreateProviderInput;
}

export interface MutationCreateProviderBadgeTemplateArgs {
    input: CreateProviderBadgeTemplateInput;
}

export interface MutationCreateProviderIntegrationArgs {
    input: CreateProviderIntegrationInput;
}

export interface MutationCreateProviderMemberInviteArgs {
    input: CreateProviderMemberInviteInput;
}

export interface MutationCreateProviderMemberInvitesArgs {
    input: CreateProviderMemberInvitesInput;
}

export interface MutationCreateProviderMySchoolInformationArgs {
    input: CreateProviderMySchoolInformationInput;
}

export interface MutationCreateProviderPostArgs {
    input: CreateProviderPostInput;
}

export interface MutationCreateProviderProjectTemplateArgs {
    input: CreateProviderProjectTemplateInput;
}

export interface MutationCreateProviderProjectTemplateOverviewResourceArgs {
    input: CreateProviderProjectTemplateOverviewResourceInput;
}

export interface MutationCreateProviderProjectTemplateSectionArgs {
    input: CreateProviderProjectTemplateSectionInput;
}

export interface MutationCreateProviderProjectTemplateSectionResourceArgs {
    input: CreateProviderProjectTemplateSectionResourceInput;
}

export interface MutationCreateReflectionArgs {
    input: CreateReflectionInput;
}

export interface MutationCreateResourceHubItemArgs {
    input: CreateResourceHubItemInput;
}

export interface MutationCreateSponsorAssetArgs {
    input: CreateSponsorAssetInput;
}

export interface MutationCreateSponsorCampaignArgs {
    input: CreateSponsorCampaignInput;
}

export interface MutationCreateSponsorCampaignAssetArgs {
    input: CreateSponsorCampaignAssetInput;
}

export interface MutationCreateSponsorProviderArgs {
    input: CreateSponsorProviderInput;
}

export interface MutationCreateStudentHomeworkDiaryItemArgs {
    input: CreateStudentHomeworkDiaryItemInput;
}

export interface MutationCreateStudentHomeworkDiaryMentorTaskArgs {
    input: CreateStudentHomeworkDiaryMentorTaskInput;
}

export interface MutationCreateStudyCategoryArgs {
    input: CreateStudyCategoryInput;
}

export interface MutationCreateStudyProgrammeArgs {
    input: CreateStudyProgrammeInput;
}

export interface MutationCreateStudyProviderArgs {
    input: CreateStudyProviderInput;
}

export interface MutationCreateThreeGoodThingsArgs {
    input: CreateThreeGoodThingsInput;
}

export interface MutationDeleteBadgeArgs {
    input: DeleteBadgeInput;
}

export interface MutationDeleteGoalArgs {
    input: DeleteGoalInput;
}

export interface MutationDeleteGoalNoteArgs {
    input: DeleteGoalNoteInput;
}

export interface MutationDeleteGoalTaskArgs {
    input: DeleteGoalTaskInput;
}

export interface MutationDeleteLessonArgs {
    input: DeleteLessonInput;
}

export interface MutationDeleteLessonLocalizationArgs {
    input: DeleteLessonLocalizationInput;
}

export interface MutationDeleteMentorGroupArgs {
    input: DeleteMentorGroupInput;
}

export interface MutationDeleteMentorGroupBannerImageArgs {
    input: DeleteMentorGroupBannerImageInput;
}

export interface MutationDeleteMentorGroupDiscussionMessageArgs {
    input: DeleteMentorGroupDiscussionMessageInput;
}

export interface MutationDeleteMentorRewardItemArgs {
    input: DeleteMentorRewardItemInput;
}

export interface MutationDeleteMentorRewardValueArgs {
    input: DeleteMentorRewardValueInput;
}

export interface MutationDeleteMentorSharedResourceArgs {
    input: DeleteMentorSharedResourceInput;
}

export interface MutationDeleteMentorStudentNoteArgs {
    input: DeleteMentorStudentNoteInput;
}

export interface MutationDeleteMentorStudentTodoArgs {
    input: DeleteMentorStudentTodoInput;
}

export interface MutationDeletePathwayPlannerArgs {
    input: DeletePathwayPlannerInput;
}

export interface MutationDeletePathwayPlannerPersonalRequirementArgs {
    input: DeletePathwayPlannerPersonalRequirementTodoInput;
}

export interface MutationDeletePathwayPlannerQualificationArgs {
    input: DeletePathwayPlannerQualificationTodoInput;
}

export interface MutationDeletePathwayPlannerSkillAndKnowledgeArgs {
    input: DeletePathwayPlannerSkillAndKnowledgeTodoInput;
}

export interface MutationDeletePathwayPlannerStageArgs {
    input: DeletePathwayPlannerStageInput;
}

export interface MutationDeletePathwayPlannerStageSubjectArgs {
    input: DeletePathwayPlannerStageSubjectTodoInput;
}

export interface MutationDeletePathwayPlannerStageUsefulExperienceArgs {
    input: DeletePathwayPlannerStageUsefulExperienceTodoInput;
}

export interface MutationDeletePathwayPlannerUsefulExperienceArgs {
    input: DeletePathwayPlannerUsefulExperienceTodoInput;
}

export interface MutationDeleteProjectArgs {
    input: DeleteProjectInput;
}

export interface MutationDeleteProjectSectionCommentArgs {
    input: DeleteProjectSectionCommentInput;
}

export interface MutationDeleteProjectSectionEvidenceArgs {
    input: DeleteProjectSectionEvidenceInput;
}

export interface MutationDeleteProviderArgs {
    input: DeleteProviderInput;
}

export interface MutationDeleteProviderBadgeTemplateArgs {
    input: DeleteProviderBadgeTemplateInput;
}

export interface MutationDeleteProviderJoinCodeArgs {
    input: DeleteProviderJoinCodeInput;
}

export interface MutationDeleteProviderMemberArgs {
    input: DeleteProviderMemberInput;
}

export interface MutationDeleteProviderMemberInviteArgs {
    input: DeleteProviderMemberInviteInput;
}

export interface MutationDeleteProviderMySchoolInformationArgs {
    input: DeleteProviderMySchoolInformationInput;
}

export interface MutationDeleteProviderPostArgs {
    input: DeleteProviderPostInput;
}

export interface MutationDeleteProviderProjectTemplateArgs {
    input: DeleteProviderProjectTemplateInput;
}

export interface MutationDeleteProviderProjectTemplateOverviewResourceArgs {
    input: DeleteProviderProjectTemplateOverviewResourceInput;
}

export interface MutationDeleteProviderProjectTemplateSectionArgs {
    input: DeleteProviderProjectTemplateSectionInput;
}

export interface MutationDeleteProviderProjectTemplateSectionResourceArgs {
    input: DeleteProviderProjectTemplateSectionResourceInput;
}

export interface MutationDeleteReflectionArgs {
    input: DeleteReflectionInput;
}

export interface MutationDeleteResourceHubItemArgs {
    input: DeleteResourceHubItemInput;
}

export interface MutationDeleteSponsorAssetArgs {
    input: DeleteSponsorAssetInput;
}

export interface MutationDeleteSponsorCampaignArgs {
    input: DeleteSponsorCampaignInput;
}

export interface MutationDeleteSponsorCampaignAssetArgs {
    input: DeleteSponsorCampaignAssetInput;
}

export interface MutationDeleteSponsorProviderArgs {
    input: DeleteSponsorProviderInput;
}

export interface MutationDeleteStudentHomeworkDiaryItemArgs {
    input: DeleteStudentHomeworkDiaryItemInput;
}

export interface MutationDeleteStudentHomeworkDiaryMentorTaskArgs {
    input: DeleteStudentHomeworkDiaryMentorTaskInput;
}

export interface MutationDeleteStudyCategoryArgs {
    input: DeleteStudyCategoryInput;
}

export interface MutationDeleteStudyProgrammeArgs {
    input: DeleteStudyProgrammeInput;
}

export interface MutationDeleteStudyProviderArgs {
    input: DeleteStudyProviderInput;
}

export interface MutationDeleteThreeGoodThingsArgs {
    input: DeleteThreeGoodThingsInput;
}

export interface MutationDisableJobProfileArgs {
    input: DisableJobProfileInput;
}

export interface MutationDisableProviderJoinCodeArgs {
    input: DisableProviderJoinCodeInput;
}

export interface MutationDisableProviderModuleArgs {
    input: DisableProviderModuleInput;
}

export interface MutationDisconnectMentorGroupArgs {
    input: DisconnectMentorGroupInput;
}

export interface MutationDisconnectMentorProviderArgs {
    input: DisconnectMentorProviderInput;
}

export interface MutationEnableJobProfileArgs {
    input: EnableJobProfileInput;
}

export interface MutationEnableProviderJoinCodeArgs {
    input: EnableProviderJoinCodeInput;
}

export interface MutationEnableProviderModuleArgs {
    input: EnableProviderModuleInput;
}

export interface MutationEnquireStudyProgrammeArgs {
    input: EnquireStudyProgrammeInput;
}

export interface MutationEnrolInStudyProgrammeArgs {
    input: EnrolInStudyProgrammeInput;
}

export interface MutationExpireOpportunityListingArgs {
    input: ExpireOpportunityListingInput;
}

export interface MutationFollowProviderArgs {
    input: FollowProviderInput;
}

export interface MutationGenerateCoverLetterArgs {
    input: GenerateCoverLetterInput;
}

export interface MutationGenerateCurriculumVitaeFileArgs {
    input: GenerateCurriculumVitaeFileInput;
}

export interface MutationGeneratePathwayPlannerInformationSuggestionsArgs {
    input: GeneratePathwayPlannerInformationSuggestionsInput;
}

export interface MutationGenerateProviderJoinCodeArgs {
    input: GenerateProviderJoinCodeInput;
}

export interface MutationGiveMentorRewardsArgs {
    input: GiveMentorRewardsInput;
}

export interface MutationImportMentorRewardEventPointsArgs {
    input: ImportMentorRewardEventPointsInput;
}

export interface MutationImportMentorRewardPointsArgs {
    input: ImportMentorRewardPointsInput;
}

export interface MutationJoinAvailableMentorGroupArgs {
    input: JoinAvailableMentorGroupInput;
}

export interface MutationJoinAvailableMentorProviderArgs {
    input: JoinAvailableMentorProviderInput;
}

export interface MutationJoinJoinableMentorGroupArgs {
    input: JoinJoinableMentorGroupInput;
}

export interface MutationLeaveProviderArgs {
    input: LeaveProviderInput;
}

export interface MutationLockMentorGroupDiscussionArgs {
    input: LockMentorGroupDiscussionInput;
}

export interface MutationLogEventsArgs {
    input: LogEventsInput;
}

export interface MutationMuteMentorGroupDiscussionUserArgs {
    input: MuteMentorGroupDiscussionUserInput;
}

export interface MutationPinMentorGroupDiscussionMessageArgs {
    input: PinMentorGroupDiscussionMessageInput;
}

export interface MutationPublishSponsorCampaignArgs {
    input: PublishSponsorCampaignInput;
}

export interface MutationQueueDeleteProviderIntegrationEdgeLearningArgs {
    input: QueueDeleteProviderIntegrationEdgeLearningInput;
}

export interface MutationQueueDeleteProviderIntegrationKamarArgs {
    input: QueueDeleteProviderIntegrationKamarInput;
}

export interface MutationQueueDeleteProviderIntegrationLissArgs {
    input: QueueDeleteProviderIntegrationLissInput;
}

export interface MutationQueueDeleteProviderIntegrationManualSmsDataArgs {
    input: QueueDeleteProviderIntegrationManualSmsDataInput;
}

export interface MutationQueueDeleteProviderIntegrationWondeArgs {
    input: QueueDeleteProviderIntegrationWondeInput;
}

export interface MutationQueueDeleteProviderRewardsArgs {
    input: QueueDeleteProviderRewardInput;
}

export interface MutationQueueImportMentorGroupsFromLearnerSmsDataArgs {
    input: QueueImportMentorGroupsFromLearnerSmsDataInput;
}

export interface MutationQueueUrlScrapeArgs {
    force?: InputMaybe<Scalars['Boolean']['input']>;
    url: Scalars['URL']['input'];
}

export interface MutationReactMentorGroupDiscussionMessageArgs {
    input: ReactMentorGroupDiscussionMessageInput;
}

export interface MutationReactProviderPostArgs {
    input: ReactProviderPostInput;
}

export interface MutationRedeemProviderJoinCodeArgs {
    input: RedeemProviderJoinCodeInput;
}

export interface MutationRegenerateMentorGroupInviteCodeArgs {
    input: RegenerateMentorGroupInviteCodeInput;
}

export interface MutationRegenerateProviderIntegrationKamarTokenArgs {
    input: RegenerateProviderIntegrationKamarTokenInput;
}

export interface MutationRegenerateProviderIntegrationLissTokenArgs {
    input: RegenerateProviderIntegrationLissTokenInput;
}

export interface MutationRejectUserConnectionRequestArgs {
    input: RejectUserConnectionRequestInput;
}

export interface MutationRemoveConnectedUserArgs {
    input: RemoveConnectedUserInput;
}

export interface MutationRemoveMentorGroupMemberArgs {
    input: RemoveMentorGroupMemberInput;
}

export interface MutationRemoveMentorGroupStudentArgs {
    input: RemoveMentorGroupStudentInput;
}

export interface MutationRemoveMentorStudentArgs {
    input: RemoveMentorStudentInput;
}

export interface MutationReportJobProfileActivityArgs {
    input: ReportJobProfileActivityInput;
}

export interface MutationRepostProviderPostArgs {
    input: RepostProviderPostInput;
}

export interface MutationRequestProjectSectionReviewArgs {
    input: RequestProjectSectionReviewInput;
}

export interface MutationRequestProviderModuleArgs {
    input: RequestProviderModuleInput;
}

export interface MutationRequestUserConnectionArgs {
    input: RequestUserConnectionInput;
}

export interface MutationRequestVerifiedIdCardArgs {
    input: RequestVerifiedIdCardInput;
}

export interface MutationResolveWalletCardPresentationSimpleArgs {
    input: ResolveWalletCardPresentationSimpleInput;
}

export interface MutationResyncProviderIntegrationWondeDataArgs {
    input: ResyncProviderIntegrationWondeDataInput;
}

export interface MutationSaveStudyProgrammeArgs {
    input: SaveStudyProgrammeInput;
}

export interface MutationSendBroadcastEmailArgs {
    input: SendBroadcastEmailInput;
}

export interface MutationSendMentorGroupDiscussionMessageArgs {
    input: SendMentorGroupDiscussionMessageInput;
}

export interface MutationSendMentorGroupInvitesArgs {
    input: SendMentorGroupInvitesInput;
}

export interface MutationSetMentorStudentAsMainArgs {
    input: SetMentorStudentAsMainInput;
}

export interface MutationShareMentorStudentNoteArgs {
    input: ShareMentorStudentNoteInput;
}

export interface MutationShareMentorStudentTodoArgs {
    input: ShareMentorStudentTodoInput;
}

export interface MutationStartMentorGroupDiscussionMessageImageUploadArgs {
    input: StartMentorGroupDiscussionMessageImageUploadInput;
}

export interface MutationStartProviderImageUploadArgs {
    input: StartProviderImageUploadInput;
}

export interface MutationStartUserImageUploadArgs {
    input: StartUserImageUploadInput;
}

export interface MutationStartUserVerificationRequestArgs {
    input: StartUserVerificationRequestInput;
}

export interface MutationStartWalletCardVerificationSimpleArgs {
    input: StartWalletCardVerificationSimpleInput;
}

export interface MutationSubmitCareersRiasecResultArgs {
    input: SubmitCareersRiasecResultInput;
}

export interface MutationToggleFavoriteLessonArgs {
    input: ToggleFavoriteLessonInput;
}

export interface MutationToggleFeaturedLessonArgs {
    input: ToggleFeaturedLessonInput;
}

export interface MutationToggleIsFavouriteStudentJobProfileArgs {
    input: ToggleIsFavouriteStudentJobProfileInput;
}

export interface MutationToggleMentorGroupJoinabilityArgs {
    input: ToggleMentorGroupJoinabilityInput;
}

export interface MutationTogglePinnedConnectionArgs {
    input: TogglePinnedConnectionInput;
}

export interface MutationTogglePinnedMentorGroupArgs {
    input: TogglePinnedMentorGroupInput;
}

export interface MutationToggleProviderLessonAccessArgs {
    input: ToggleProviderLessonAccessInput;
}

export interface MutationToggleReadLessonArgs {
    input: ToggleReadLessonInput;
}

export interface MutationToggleStudentHomeworkDiaryItemCompletionArgs {
    input: ToggleStudentHomeworkDiaryItemCompletionInput;
}

export interface MutationUncompleteProjectSectionArgs {
    input: UncompleteProjectSectionInput;
}

export interface MutationUnfollowProviderArgs {
    input: UnfollowProviderInput;
}

export interface MutationUnlinkAccountArgs {
    input: UnlinkAccountInput;
}

export interface MutationUnlockMentorGroupDiscussionArgs {
    input: UnlockMentorGroupDiscussionInput;
}

export interface MutationUnmuteMentorGroupDiscussionUserArgs {
    input: UnmuteMentorGroupDiscussionUserInput;
}

export interface MutationUnpinMentorGroupDiscussionMessageArgs {
    input: UnpinMentorGroupDiscussionMessageInput;
}

export interface MutationUnpublishSponsorCampaignArgs {
    input: UnpublishSponsorCampaignInput;
}

export interface MutationUnreactMentorGroupDiscussionMessageArgs {
    input: UnreactMentorGroupDiscussionMessageInput;
}

export interface MutationUnreactProviderPostArgs {
    input: UnreactProviderPostInput;
}

export interface MutationUnsaveStudyProgrammeArgs {
    input: UnsaveStudyProgrammeInput;
}

export interface MutationUnshareMentorStudentNoteArgs {
    input: UnshareMentorStudentNoteInput;
}

export interface MutationUnshareMentorStudentTodoArgs {
    input: UnshareMentorStudentTodoInput;
}

export interface MutationUpdateConnectedUserActivitySharingArgs {
    input: UpdateConnectedUserActivitySharingInput;
}

export interface MutationUpdateCurriculumVitaeArgs {
    input: UpdateCurriculumVitaeInput;
}

export interface MutationUpdateGoalArgs {
    input: UpdateGoalInput;
}

export interface MutationUpdateGoalNoteArgs {
    input: UpdateGoalNoteInput;
}

export interface MutationUpdateGoalTaskArgs {
    input: UpdateGoalTaskInput;
}

export interface MutationUpdateJobProfileArgs {
    input: UpdateJobProfileInput;
}

export interface MutationUpdateLessonArgs {
    input: UpdateLessonInput;
}

export interface MutationUpdateLessonLocalizationArgs {
    input: UpdateLessonLocalizationInput;
}

export interface MutationUpdateMentorGroupArgs {
    input: UpdateMentorGroupInput;
}

export interface MutationUpdateMentorGroupBannerImageArgs {
    input: UpdateMentorGroupBannerImageInput;
}

export interface MutationUpdateMentorGroupDiscussionLastReadArgs {
    input: UpdateMentorGroupDiscussionLastReadInput;
}

export interface MutationUpdateMentorGroupDiscussionLastTypingArgs {
    input: UpdateMentorGroupDiscussionLastTypingInput;
}

export interface MutationUpdateMentorGroupInformationArgs {
    input: UpdateMentorGroupInformationInput;
}

export interface MutationUpdateMentorRewardItemArgs {
    input: UpdateMentorRewardItemInput;
}

export interface MutationUpdateMentorRewardValueArgs {
    input: UpdateMentorRewardValueInput;
}

export interface MutationUpdateMentorRewardViewedArgs {
    input: UpdateMentorRewardViewedInput;
}

export interface MutationUpdateMentorSharedResourceArgs {
    input: UpdateMentorSharedResourceInput;
}

export interface MutationUpdateMentorStudentNoteArgs {
    input: UpdateMentorStudentNoteInput;
}

export interface MutationUpdateMentorStudentNoteViewedArgs {
    input: UpdateMentorStudentNoteViewedInput;
}

export interface MutationUpdateMentorStudentSharedActivityArgs {
    input: UpdateMentorStudentSharedActivityInput;
}

export interface MutationUpdateMentorStudentTodoArgs {
    input: UpdateMentorStudentTodoInput;
}

export interface MutationUpdateMentorStudentTodoViewedArgs {
    input: UpdateMentorStudentTodoViewedInput;
}

export interface MutationUpdateOpportunityListingArgs {
    input: UpdateOpportunityListingInput;
}

export interface MutationUpdatePathwayPlannerArgs {
    input: UpdatePathwayPlannerInput;
}

export interface MutationUpdatePathwayPlannerPersonalRequirementArgs {
    input: UpdatePathwayPlannerPersonalRequirementTodoInput;
}

export interface MutationUpdatePathwayPlannerQualificationArgs {
    input: UpdatePathwayPlannerQualificationTodoInput;
}

export interface MutationUpdatePathwayPlannerSkillAndKnowledgeArgs {
    input: UpdatePathwayPlannerSkillAndKnowledgeTodoInput;
}

export interface MutationUpdatePathwayPlannerStageArgs {
    input: UpdatePathwayPlannerStageInput;
}

export interface MutationUpdatePathwayPlannerStageSubjectArgs {
    input: UpdatePathwayPlannerStageSubjectTodoInput;
}

export interface MutationUpdatePathwayPlannerStageUsefulExperienceArgs {
    input: UpdatePathwayPlannerStageUsefulExperienceTodoInput;
}

export interface MutationUpdatePathwayPlannerUsefulExperienceArgs {
    input: UpdatePathwayPlannerUsefulExperienceTodoInput;
}

export interface MutationUpdateProjectMentorGroupArgs {
    input: UpdateProjectMentorGroupInput;
}

export interface MutationUpdateProjectSectionCommentArgs {
    input: UpdateProjectSectionCommentInput;
}

export interface MutationUpdateProjectSectionEvidenceArgs {
    input: UpdateProjectSectionEvidenceInput;
}

export interface MutationUpdateProjectSectionResponseArgs {
    input: UpdateProjectSectionResponseInput;
}

export interface MutationUpdateProviderDetailsArgs {
    input: UpdateProviderDetailsInput;
}

export interface MutationUpdateProviderIntegrationKamarTimetableDisabledUntilArgs {
    input: UpdateProviderIntegrationKamarTimetableDisabledUntilInput;
}

export interface MutationUpdateProviderIntegrationManualSmsDataEmployeesArgs {
    input: UpdateProviderIntegrationManualSmsDataEmployeesInput;
}

export interface MutationUpdateProviderIntegrationManualSmsDataGroupEmployeesArgs {
    input: UpdateProviderIntegrationManualSmsDataGroupEmployeesInput;
}

export interface MutationUpdateProviderIntegrationManualSmsDataGroupStudentsArgs {
    input: UpdateProviderIntegrationManualSmsDataGroupStudentsInput;
}

export interface MutationUpdateProviderIntegrationManualSmsDataGroupsArgs {
    input: UpdateProviderIntegrationManualSmsDataGroupsInput;
}

export interface MutationUpdateProviderIntegrationManualSmsDataStudentsArgs {
    input: UpdateProviderIntegrationManualSmsDataStudentsInput;
}

export interface MutationUpdateProviderMemberRolesArgs {
    input: UpdateProviderMemberRolesInput;
}

export interface MutationUpdateProviderMySchoolInformationArgs {
    input: UpdateProviderMySchoolInformationInput;
}

export interface MutationUpdateProviderMySchoolInformationSequenceArgs {
    input: UpdateProviderMySchoolInformationSequenceInput;
}

export interface MutationUpdateProviderPostArgs {
    input: UpdateProviderPostInput;
}

export interface MutationUpdateProviderProjectTemplateArgs {
    input: UpdateProviderProjectTemplateInput;
}

export interface MutationUpdateProviderProjectTemplateBannerImageArgs {
    input: UpdateProviderProjectTemplateBannerImageInput;
}

export interface MutationUpdateProviderProjectTemplateOverviewResourceArgs {
    input: UpdateProviderProjectTemplateOverviewResourceInput;
}

export interface MutationUpdateProviderProjectTemplateSectionArgs {
    input: UpdateProviderProjectTemplateSectionInput;
}

export interface MutationUpdateProviderProjectTemplateSectionOrderArgs {
    input: UpdateProviderProjectTemplateSectionOrderInput;
}

export interface MutationUpdateProviderProjectTemplateSectionResourceArgs {
    input: UpdateProviderProjectTemplateSectionResourceInput;
}

export interface MutationUpdateReflectionArgs {
    input: UpdateReflectionInput;
}

export interface MutationUpdateResourceHubItemArgs {
    input: UpdateResourceHubItemInput;
}

export interface MutationUpdateSponsorAssetArgs {
    input: UpdateSponsorAssetInput;
}

export interface MutationUpdateSponsorCampaignArgs {
    input: UpdateSponsorCampaignInput;
}

export interface MutationUpdateSponsorCampaignAssetArgs {
    input: UpdateSponsorCampaignAssetInput;
}

export interface MutationUpdateSponsorProviderArgs {
    input: UpdateSponsorProviderInput;
}

export interface MutationUpdateStudentHomeworkDiaryItemArgs {
    input: UpdateStudentHomeworkDiaryItemInput;
}

export interface MutationUpdateStudentHomeworkDiaryMentorTaskArgs {
    input: UpdateStudentHomeworkDiaryMentorTaskInput;
}

export interface MutationUpdateStudyCategoryArgs {
    input: UpdateStudyCategoryInput;
}

export interface MutationUpdateStudyProgrammeArgs {
    input: UpdateStudyProgrammeInput;
}

export interface MutationUpdateStudyProviderArgs {
    input: UpdateStudyProviderInput;
}

export interface MutationUpdateThreeGoodThingsArgs {
    input: UpdateThreeGoodThingsInput;
}

export interface MutationUpdateUserArgs {
    input: UpdateUserInput;
}

export interface MutationUpdateUserEmailAddressArgs {
    input: UpdateUserEmailAddressInput;
}

export interface MutationUpdateUserEmailNotificationsSettingArgs {
    input: UpdateUserEmailNotificationsSettingInput;
}

export interface MutationUpdateUserPictureArgs {
    input: UpdateUserPictureInput;
}

export interface MutationValidateMentorStudentInviteCodeArgs {
    input: ValidateMentorStudentInviteCodeInput;
}

export interface MutationValidateUserConnectionRequestArgs {
    input: ValidateUserConnectionRequestInput;
}

export interface MuteMentorGroupDiscussionUserInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly userId: Scalars['UUID']['input'];
}

export interface MuteMentorGroupDiscussionUserPayload {
    readonly __typename: 'MuteMentorGroupDiscussionUserPayload';
    readonly mentorGroup: MentorGroup;
}

export interface NzStudentId extends WalletCard {
    readonly __typename: 'NZStudentId';
    readonly barcodeNumber: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly dateOfBirth: Scalars['Date']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly fullName: Scalars['String']['output'];
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly idNumber: Scalars['String']['output'];
    readonly issuerLogoUrl: Scalars['URL']['output'];
    readonly issuerTitle: Scalars['String']['output'];
    readonly nationalCurriculumYear: Scalars['String']['output'];
    readonly photoUrl: Scalars['URL']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly walletCardType: WalletCardType;
}

export enum NavigationItem {
    ADMIN = 'ADMIN',
    CAMPAIGN = 'CAMPAIGN',
    CAREERS_CENTRE = 'CAREERS_CENTRE',
    CONNECTIONS = 'CONNECTIONS',
    COURSES = 'COURSES',
    EMPLOYER = 'EMPLOYER',
    GOALS = 'GOALS',
    GROUPS = 'GROUPS',
    LESSONS = 'LESSONS',
    MENTOR = 'MENTOR',
    MY_SCHOOL = 'MY_SCHOOL',
    NEWSFEED = 'NEWSFEED',
    PROJECTS = 'PROJECTS',
    REFLECTIONS = 'REFLECTIONS',
    RESOURCE_HUB = 'RESOURCE_HUB',
    SEARCH = 'SEARCH',
    SETTINGS = 'SETTINGS',
    WALLET = 'WALLET'
}

export interface Newsfeed {
    readonly __typename: 'Newsfeed';
    readonly cursor: Scalars['String']['output'];
    readonly items: ReadonlyArray<NewsfeedItem>;
}

export interface NewsfeedItem {
    readonly itemCreated: Scalars['DateTime']['output'];
    readonly itemId: Scalars['UUID']['output'];
    readonly itemType: NewsfeedItemType;
    readonly itemUpdated?: Maybe<Scalars['DateTime']['output']>;
}

export interface NewsfeedItemConnection {
    readonly __typename: 'NewsfeedItemConnection';
    readonly edges: ReadonlyArray<NewsfeedItemEdge>;
    readonly pageInfo: PageInfo;
}

export interface NewsfeedItemEdge {
    readonly __typename: 'NewsfeedItemEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<NewsfeedItem>;
}

export enum NewsfeedItemType {
    GOALS_DUE_REMINDER = 'GOALS_DUE_REMINDER',
    OPPORTUNITY_LISTING_POST = 'OPPORTUNITY_LISTING_POST',
    PROVIDER_POST = 'PROVIDER_POST',
    SPONSOR_POST = 'SPONSOR_POST',
    STUDENT_HOMEWORK_ITEMS_DUE_REMINDER = 'STUDENT_HOMEWORK_ITEMS_DUE_REMINDER'
}

export interface OpportunityListing extends Updatable {
    readonly __typename: 'OpportunityListing';
    readonly canExpire: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly company: Scalars['String']['output'];
    readonly contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
    readonly contactPhone?: Maybe<Scalars['String']['output']>;
    readonly contactWebLink?: Maybe<Scalars['URL']['output']>;
    readonly country: Scalars['CountryCode']['output'];
    readonly coverImageId?: Maybe<Scalars['UUID']['output']>;
    readonly coverImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly geoLocation?: Maybe<GeoLocation>;
    readonly id: Scalars['UUID']['output'];
    readonly isPinned: Scalars['Boolean']['output'];
    readonly jobDescription?: Maybe<Scalars['String']['output']>;
    readonly listingRange: OpportunityListingRange;
    readonly payRateDescription?: Maybe<Scalars['Float']['output']>;
    readonly provider: Provider;
    readonly referenceCode?: Maybe<Scalars['String']['output']>;
    readonly title: Scalars['String']['output'];
    readonly type: OpportunityListingType;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly workingHoursDescription?: Maybe<Scalars['Float']['output']>;
}

export interface OpportunityListingConnection {
    readonly __typename: 'OpportunityListingConnection';
    readonly edges: ReadonlyArray<OpportunityListingEdge>;
    readonly nodes: ReadonlyArray<Maybe<OpportunityListing>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface OpportunityListingEdge {
    readonly __typename: 'OpportunityListingEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<OpportunityListing>;
}

export interface OpportunityListingPost extends NewsfeedItem {
    readonly __typename: 'OpportunityListingPost';
    readonly country: Scalars['String']['output'];
    readonly coverImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly geoLocation: GeoLocation;
    readonly itemCreated: Scalars['DateTime']['output'];
    readonly itemId: Scalars['UUID']['output'];
    readonly itemType: NewsfeedItemType;
    readonly itemUpdated?: Maybe<Scalars['DateTime']['output']>;
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly type: OpportunityListingType;
}

export enum OpportunityListingRange {
    INTERNATIONAL = 'INTERNATIONAL',
    NATIONAL = 'NATIONAL',
    REGIONAL = 'REGIONAL'
}

export enum OpportunityListingType {
    APPRENTICESHIP = 'APPRENTICESHIP',
    CASUAL_WORK = 'CASUAL_WORK',
    FULLTIME_JOB = 'FULLTIME_JOB',
    PARTTIME_JOB = 'PARTTIME_JOB'
}

export enum OpportunitySortType {
    HIGH_PAYRATE = 'HIGH_PAYRATE',
    LATEST = 'LATEST',
    LOW_PAYRATE = 'LOW_PAYRATE'
}

export interface PageInfo {
    readonly __typename: 'PageInfo';
    readonly endCursor?: Maybe<Scalars['String']['output']>;
    readonly hasNextPage: Scalars['Boolean']['output'];
    readonly hasPreviousPage: Scalars['Boolean']['output'];
    readonly startCursor?: Maybe<Scalars['String']['output']>;
}

export interface PathwayPlanner extends Deletable, Updatable {
    readonly __typename: 'PathwayPlanner';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly personalRequirementsList?: Maybe<ReadonlyArray<PathwayPlannerPersonalRequirementTodo>>;
    readonly qualificationsList?: Maybe<ReadonlyArray<PathwayPlannerQualificationTodo>>;
    readonly skillsAndKnowledgeList?: Maybe<ReadonlyArray<PathwayPlannerSkillAndKnowledgeTodo>>;
    readonly stagesList?: Maybe<ReadonlyArray<PathwayPlannerStage>>;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly usefulExperienceList?: Maybe<ReadonlyArray<PathwayPlannerUsefulExperienceTodo>>;
    readonly user: User;
}

export interface PathwayPlannerConnection {
    readonly __typename: 'PathwayPlannerConnection';
    readonly edges: ReadonlyArray<PathwayPlannerEdge>;
    readonly lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly nodes: ReadonlyArray<Maybe<PathwayPlanner>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface PathwayPlannerEdge {
    readonly __typename: 'PathwayPlannerEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<PathwayPlanner>;
}

export enum PathwayPlannerInformationType {
    EXTRACURRICULAR = 'EXTRACURRICULAR',
    NEEDED_EXPERIENCE = 'NEEDED_EXPERIENCE',
    NEEDED_PERSONAL_CAPABILITIES = 'NEEDED_PERSONAL_CAPABILITIES',
    NEEDED_QUALIFICATIONS = 'NEEDED_QUALIFICATIONS',
    NEEDED_SPECIALIST_SKILLS = 'NEEDED_SPECIALIST_SKILLS',
    SUBJECTS = 'SUBJECTS'
}

export interface PathwayPlannerPersonalRequirementTodo {
    readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
    readonly completed: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export interface PathwayPlannerQualificationTodo {
    readonly __typename: 'PathwayPlannerQualificationTodo';
    readonly completed: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export interface PathwayPlannerSkillAndKnowledgeTodo {
    readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
    readonly completed: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export interface PathwayPlannerStage {
    readonly __typename: 'PathwayPlannerStage';
    readonly id: Scalars['UUID']['output'];
    readonly subjectsList?: Maybe<ReadonlyArray<PathwayPlannerStageSubjectTodo>>;
    readonly title: Scalars['String']['output'];
    readonly usefulExperienceList?: Maybe<ReadonlyArray<PathwayPlannerStageUsefulExperienceTodo>>;
}

export interface PathwayPlannerStageSubjectTodo {
    readonly __typename: 'PathwayPlannerStageSubjectTodo';
    readonly completed: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export interface PathwayPlannerStageUsefulExperienceTodo {
    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
    readonly completed: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export interface PathwayPlannerTodoPayload {
    readonly __typename: 'PathwayPlannerTodoPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface PathwayPlannerUsefulExperienceTodo {
    readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
    readonly completed: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export interface PinMentorGroupDiscussionMessageInput {
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['input'];
}

export interface PinMentorGroupDiscussionMessagePayload {
    readonly __typename: 'PinMentorGroupDiscussionMessagePayload';
    readonly mentorGroupDiscussionMessage: MentorGroupDiscussionMessage;
}

export interface PointsSummaryByType {
    readonly __typename: 'PointsSummaryByType';
    readonly consumedPoints: Scalars['Int']['output'];
    readonly givenPoints: Scalars['Int']['output'];
}

export interface PointsYearSummaryByType {
    readonly __typename: 'PointsYearSummaryByType';
    readonly consumedPoints: Scalars['Int']['output'];
    readonly givenPoints: Scalars['Int']['output'];
    readonly month: Scalars['Int']['output'];
}

export interface PostMetadata {
    readonly __typename: 'PostMetadata';
    readonly author?: Maybe<Scalars['String']['output']>;
    readonly date?: Maybe<Scalars['DateTime']['output']>;
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly image?: Maybe<Scalars['URL']['output']>;
    readonly lang?: Maybe<Scalars['String']['output']>;
    readonly logo?: Maybe<Scalars['URL']['output']>;
    readonly publisher?: Maybe<Scalars['String']['output']>;
    readonly title?: Maybe<Scalars['String']['output']>;
    readonly url?: Maybe<Scalars['URL']['output']>;
    readonly video?: Maybe<Scalars['URL']['output']>;
}

export interface Project extends Deletable {
    readonly __typename: 'Project';
    readonly bannerImageId?: Maybe<Scalars['UUID']['output']>;
    readonly bannerImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdateMentorGroup: Scalars['Boolean']['output'];
    readonly completedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly isLocked: Scalars['Boolean']['output'];
    readonly latestReviewRequestedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly mentorGroup?: Maybe<MentorGroup>;
    readonly overviewDescription: Scalars['String']['output'];
    readonly overviewResources: ReadonlyArray<ProjectOverviewResource>;
    readonly provider: Provider;
    readonly sections: ReadonlyArray<ProjectSection>;
    readonly sourceTemplateId?: Maybe<Scalars['UUID']['output']>;
    readonly title: Scalars['String']['output'];
    readonly totalCompletedProjectSections: Scalars['Int']['output'];
    readonly totalProjectSections: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface ProjectConnection {
    readonly __typename: 'ProjectConnection';
    readonly edges: ReadonlyArray<ProjectEdge>;
    readonly lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly nodes: ReadonlyArray<Maybe<Project>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProjectEdge {
    readonly __typename: 'ProjectEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<Project>;
}

export enum ProjectFilterType {
    ALL = 'ALL',
    COMPLETED = 'COMPLETED',
    PROGRESS_DESC = 'PROGRESS_DESC',
    READY_FOR_REVIEW = 'READY_FOR_REVIEW'
}

export interface ProjectOverviewResource {
    readonly __typename: 'ProjectOverviewResource';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly lesson?: Maybe<Lesson>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly url: Scalars['URL']['output'];
}

export interface ProjectSection extends Updatable {
    readonly __typename: 'ProjectSection';
    readonly canCompleteReview: Scalars['Boolean']['output'];
    readonly canCompleteSection: Scalars['Boolean']['output'];
    readonly canCreateComment: Scalars['Boolean']['output'];
    readonly canRequestReview: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly color: Scalars['HexColorCode']['output'];
    readonly comments: ReadonlyArray<ProjectSectionComment>;
    readonly completedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly description: Scalars['String']['output'];
    readonly evidences: ReadonlyArray<ProjectSectionEvidence>;
    readonly id: Scalars['UUID']['output'];
    readonly isComplete: Scalars['Boolean']['output'];
    readonly isReviewComplete: Scalars['Boolean']['output'];
    readonly isReviewRequested: Scalars['Boolean']['output'];
    readonly resources: ReadonlyArray<ProjectSectionResource>;
    readonly response?: Maybe<Scalars['String']['output']>;
    readonly reviewCompletedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly reviewRequestedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProjectSectionComment extends Deletable, Updatable {
    readonly __typename: 'ProjectSectionComment';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly comment: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProjectSectionEvidence extends Deletable, Updatable {
    readonly __typename: 'ProjectSectionEvidence';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly resourceHubItem: ResourceHubItem;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProjectSectionResource {
    readonly __typename: 'ProjectSectionResource';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly lesson?: Maybe<Lesson>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly url: Scalars['URL']['output'];
}

export enum ProjectSortType {
    ALPHABETICAL_A_Z = 'ALPHABETICAL_A_Z',
    LAST_REVIEW_REQUESTED_FIRST = 'LAST_REVIEW_REQUESTED_FIRST',
    LAST_UPDATED_FIRST = 'LAST_UPDATED_FIRST',
    MOST_PROGRESS_FIRST = 'MOST_PROGRESS_FIRST'
}

export interface Provider {
    readonly __typename: 'Provider';
    readonly activeModulesList: ReadonlyArray<ProviderModule>;
    readonly availableIntegrationTypes: ReadonlyArray<ProviderIntegrationType>;
    readonly bannerImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly canAccessAdminFeature: Scalars['Boolean']['output'];
    readonly canAccessBadgeTemplatesFeature: Scalars['Boolean']['output'];
    readonly canAccessEmploymentFeature: Scalars['Boolean']['output'];
    readonly canAccessMentorFeature: Scalars['Boolean']['output'];
    readonly canAccessMentorRewardFeature: Scalars['Boolean']['output'];
    readonly canAccessNewsfeedFeature: Scalars['Boolean']['output'];
    readonly canAccessOpportunityFeature: Scalars['Boolean']['output'];
    readonly canAccessProjectTemplatesFeature: Scalars['Boolean']['output'];
    readonly canImportMentorGroupsFromLearnerSmsData: Scalars['Boolean']['output'];
    readonly canImportMentorRewardPoints: Scalars['Boolean']['output'];
    readonly canPinPost: Scalars['Boolean']['output'];
    readonly canToggleProviderLessonAccess: Scalars['Boolean']['output'];
    readonly canUpdateProviderDetails: Scalars['Boolean']['output'];
    readonly countryCode: Scalars['String']['output'];
    readonly dailyNoticesList: ReadonlyArray<DailyNotice>;
    readonly hasFollowed: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly integrations: ProviderIntegrationConnection;
    readonly internalContactDetails?: Maybe<ProviderInternalContactDetails>;
    readonly isDailyNoticesAvailable: Scalars['Boolean']['output'];
    readonly isLessonsDisabled: Scalars['Boolean']['output'];
    readonly joinCode?: Maybe<ProviderJoinCode>;
    readonly learnerSmsGroups: LearnerSmsGroupConnection;
    readonly logoImageUrl: Scalars['URL']['output'];
    readonly memberInvitesList: ReadonlyArray<ProviderMemberInvite>;
    readonly membersList: ReadonlyArray<ProviderMember>;
    readonly mentorBadgeStudents: MentorBadgeStudentConnection;
    readonly mentorGroups: MentorGroupConnection;
    readonly mentorRewardAnalytics: MentorRewardAnalytics;
    readonly mentorRewardEvents: MentorRewardEventConnection;
    readonly mentorRewardItems: ReadonlyArray<MentorRewardItem>;
    readonly mentorRewardStudents: MentorRewardStudentConnection;
    readonly mentorRewardValues: ReadonlyArray<MentorRewardValue>;
    readonly mentorSessionsSummaryAnalytics: MentorSessionsSummaryConnection;
    readonly mentorSharedResources: MentorSharedResourceConnection;
    readonly mentorStudents: MentorStudentConnection;
    readonly mentorStudentsProjects: ProjectConnection;
    readonly mySchoolInformationItems: ProviderMySchoolInformationConnection;
    readonly newsfeedProviderPosts: ProviderPostConnection;
    readonly opportunityListing?: Maybe<OpportunityListing>;
    readonly opportunityListings: OpportunityListingConnection;
    readonly providerBadgeTemplates: ProviderBadgeTemplateConnection;
    readonly providerProjectTemplates: ProviderProjectTemplateConnection;
    readonly providerType: ProviderType;
    readonly searchStudentJobProfiles: StudentJobProfileSearchResultItemConnection;
    readonly studentJobProfile?: Maybe<StudentJobProfile>;
    readonly technicalContactDetails?: Maybe<ProviderTechnicalContactDetails>;
    readonly title: Scalars['String']['output'];
}

export interface ProviderDailyNoticesListArgs {
    end?: InputMaybe<Scalars['DateTime']['input']>;
    start?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ProviderIntegrationsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    type?: InputMaybe<ProviderIntegrationType>;
}

export interface ProviderLearnerSmsGroupsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProviderMentorBadgeStudentsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface ProviderMentorGroupsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    mentorStudentId?: InputMaybe<Scalars['UUID']['input']>;
    ownedGroupsOnly?: InputMaybe<Scalars['Boolean']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface ProviderMentorRewardEventsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface ProviderMentorRewardStudentsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface ProviderMentorSessionsSummaryAnalyticsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    end: Scalars['DateTime']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    start: Scalars['DateTime']['input'];
}

export interface ProviderMentorSharedResourcesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProviderMentorStudentsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    jobProfileEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    withNoGroups?: InputMaybe<Scalars['Boolean']['input']>;
    withNoLearnerSmsProfile?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProviderMentorStudentsProjectsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    filter?: InputMaybe<ProjectFilterType>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<ProjectSortType>;
}

export interface ProviderMySchoolInformationItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProviderNewsfeedProviderPostsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProviderOpportunityListingArgs {
    id: Scalars['UUID']['input'];
}

export interface ProviderOpportunityListingsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    country?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    geoLocationId?: InputMaybe<Scalars['UUID']['input']>;
    includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<OpportunitySortType>;
    types?: InputMaybe<ReadonlyArray<OpportunityListingType>>;
}

export interface ProviderProviderBadgeTemplatesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProviderProviderProjectTemplatesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    includePublic?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProviderSearchStudentJobProfilesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    ageGroups?: InputMaybe<ReadonlyArray<UserAgeGroup>>;
    before?: InputMaybe<Scalars['String']['input']>;
    countryCode?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    geoLocationId?: InputMaybe<Scalars['UUID']['input']>;
    hasContacted?: InputMaybe<Scalars['Boolean']['input']>;
    isFavourite?: InputMaybe<Scalars['Boolean']['input']>;
    jobCategories?: InputMaybe<ReadonlyArray<JobCategory>>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface ProviderStudentJobProfileArgs {
    id: Scalars['UUID']['input'];
}

export interface ProviderBadgeTemplate extends Deletable {
    readonly __typename: 'ProviderBadgeTemplate';
    readonly badgeImageId: Scalars['UUID']['output'];
    readonly badgeImageUrl: Scalars['URL']['output'];
    readonly canDelete: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly provider: Provider;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderBadgeTemplateConnection {
    readonly __typename: 'ProviderBadgeTemplateConnection';
    readonly edges: ReadonlyArray<ProviderBadgeTemplateEdge>;
    readonly nodes: ReadonlyArray<Maybe<ProviderBadgeTemplate>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProviderBadgeTemplateEdge {
    readonly __typename: 'ProviderBadgeTemplateEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ProviderBadgeTemplate>;
}

export interface ProviderConnection {
    readonly __typename: 'ProviderConnection';
    readonly edges: ReadonlyArray<ProviderEdge>;
    readonly nodes: ReadonlyArray<Maybe<Provider>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProviderEdge {
    readonly __typename: 'ProviderEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<Provider>;
}

export interface ProviderIntegration {
    readonly childIntegrations: ReadonlyArray<ProviderIntegration>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly integrationType: ProviderIntegrationType;
    readonly parentIntegration?: Maybe<ProviderIntegration>;
    readonly provider: Provider;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderIntegrationConnection {
    readonly __typename: 'ProviderIntegrationConnection';
    readonly edges: ReadonlyArray<ProviderIntegrationEdge>;
    readonly nodes: ReadonlyArray<Maybe<ProviderIntegration>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProviderIntegrationEdge {
    readonly __typename: 'ProviderIntegrationEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ProviderIntegration>;
}

export interface ProviderIntegrationEdgeLearning extends Deletable, ProviderIntegration, ProviderIntegrationLearnerSmsData {
    readonly __typename: 'ProviderIntegrationEdgeLearning';
    readonly assessmentDataCount: Scalars['Int']['output'];
    readonly attendanceDataCount: Scalars['Int']['output'];
    readonly canDelete: Scalars['Boolean']['output'];
    readonly childIntegrations: ReadonlyArray<ProviderIntegration>;
    readonly classDataCount: Scalars['Int']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly employeeDataCount: Scalars['Int']['output'];
    readonly employeePhotoDataCount: Scalars['Int']['output'];
    readonly eventDataCount: Scalars['Int']['output'];
    readonly groupDataCount: Scalars['Int']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly integrationType: ProviderIntegrationType;
    readonly isDeleting: Scalars['Boolean']['output'];
    readonly lessonDataCount: Scalars['Int']['output'];
    readonly moeNumber?: Maybe<Scalars['String']['output']>;
    readonly noticeDataCount: Scalars['Int']['output'];
    readonly parentIntegration?: Maybe<ProviderIntegration>;
    readonly provider: Provider;
    readonly resultDataCount: Scalars['Int']['output'];
    readonly studentDataCount: Scalars['Int']['output'];
    readonly studentPhotoDataCount: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderIntegrationKamar extends Deletable, ProviderIntegration, ProviderIntegrationLearnerSmsData {
    readonly __typename: 'ProviderIntegrationKamar';
    readonly assessmentDataCount: Scalars['Int']['output'];
    readonly attendanceDataCount: Scalars['Int']['output'];
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canRegenerateToken: Scalars['Boolean']['output'];
    readonly canUpdateTimetableDisabledUntil: Scalars['Boolean']['output'];
    readonly childIntegrations: ReadonlyArray<ProviderIntegration>;
    readonly classDataCount: Scalars['Int']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly employeeDataCount: Scalars['Int']['output'];
    readonly employeePhotoDataCount: Scalars['Int']['output'];
    readonly eventDataCount: Scalars['Int']['output'];
    readonly groupDataCount: Scalars['Int']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly integrationType: ProviderIntegrationType;
    readonly isDeleting: Scalars['Boolean']['output'];
    readonly lessonDataCount: Scalars['Int']['output'];
    readonly noticeDataCount: Scalars['Int']['output'];
    readonly parentIntegration?: Maybe<ProviderIntegration>;
    readonly provider: Provider;
    readonly resultDataCount: Scalars['Int']['output'];
    readonly studentDataCount: Scalars['Int']['output'];
    readonly studentPhotoDataCount: Scalars['Int']['output'];
    readonly timetableDisabledUntil?: Maybe<Scalars['Date']['output']>;
    readonly token?: Maybe<Scalars['String']['output']>;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderIntegrationLearnerSmsData {
    readonly classDataCount: Scalars['Int']['output'];
    readonly employeeDataCount: Scalars['Int']['output'];
    readonly employeePhotoDataCount: Scalars['Int']['output'];
    readonly eventDataCount: Scalars['Int']['output'];
    readonly groupDataCount: Scalars['Int']['output'];
    readonly isDeleting: Scalars['Boolean']['output'];
    readonly lessonDataCount: Scalars['Int']['output'];
    readonly studentDataCount: Scalars['Int']['output'];
    readonly studentPhotoDataCount: Scalars['Int']['output'];
}

export interface ProviderIntegrationLiss extends Deletable, ProviderIntegration, ProviderIntegrationLearnerSmsData {
    readonly __typename: 'ProviderIntegrationLiss';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canRegenerateToken: Scalars['Boolean']['output'];
    readonly childIntegrations: ReadonlyArray<ProviderIntegration>;
    readonly classDataCount: Scalars['Int']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly employeeDataCount: Scalars['Int']['output'];
    readonly employeePhotoDataCount: Scalars['Int']['output'];
    readonly eventDataCount: Scalars['Int']['output'];
    readonly groupDataCount: Scalars['Int']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly integrationType: ProviderIntegrationType;
    readonly isDeleting: Scalars['Boolean']['output'];
    readonly lessonDataCount: Scalars['Int']['output'];
    readonly parentIntegration?: Maybe<ProviderIntegration>;
    readonly provider: Provider;
    readonly studentDataCount: Scalars['Int']['output'];
    readonly studentPhotoDataCount: Scalars['Int']['output'];
    readonly token?: Maybe<Scalars['String']['output']>;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderIntegrationManualSmsData extends Deletable, ProviderIntegration, ProviderIntegrationLearnerSmsData {
    readonly __typename: 'ProviderIntegrationManualSmsData';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly childIntegrations: ReadonlyArray<ProviderIntegration>;
    readonly classDataCount: Scalars['Int']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly employeeDataCount: Scalars['Int']['output'];
    readonly employeePhotoDataCount: Scalars['Int']['output'];
    readonly eventDataCount: Scalars['Int']['output'];
    readonly groupDataCount: Scalars['Int']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly integrationType: ProviderIntegrationType;
    readonly isDeleting: Scalars['Boolean']['output'];
    readonly lessonDataCount: Scalars['Int']['output'];
    readonly parentIntegration?: Maybe<ProviderIntegration>;
    readonly provider: Provider;
    readonly studentDataCount: Scalars['Int']['output'];
    readonly studentPhotoDataCount: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export enum ProviderIntegrationType {
    EDGE_LEARNING = 'EDGE_LEARNING',
    KAMAR = 'KAMAR',
    LISS = 'LISS',
    MANUAL_SMS_DATA = 'MANUAL_SMS_DATA',
    WONDE = 'WONDE'
}

export interface ProviderIntegrationWonde extends Deletable, ProviderIntegration, ProviderIntegrationLearnerSmsData {
    readonly __typename: 'ProviderIntegrationWonde';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canResync: Scalars['Boolean']['output'];
    readonly childIntegrations: ReadonlyArray<ProviderIntegration>;
    readonly classDataCount: Scalars['Int']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly employeeDataCount: Scalars['Int']['output'];
    readonly employeePhotoDataCount: Scalars['Int']['output'];
    readonly eventDataCount: Scalars['Int']['output'];
    readonly groupDataCount: Scalars['Int']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly integrationType: ProviderIntegrationType;
    readonly isDeleting: Scalars['Boolean']['output'];
    readonly lastSyncedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly lessonDataCount: Scalars['Int']['output'];
    readonly parentIntegration?: Maybe<ProviderIntegration>;
    readonly provider: Provider;
    readonly studentDataCount: Scalars['Int']['output'];
    readonly studentPhotoDataCount: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderInternalContactDetails {
    readonly __typename: 'ProviderInternalContactDetails';
    readonly email?: Maybe<Scalars['EmailAddress']['output']>;
    readonly fullName?: Maybe<Scalars['String']['output']>;
    readonly phone?: Maybe<Scalars['String']['output']>;
}

export interface ProviderJoinCode {
    readonly __typename: 'ProviderJoinCode';
    readonly code: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly isEnabled: Scalars['Boolean']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderMember extends Deletable {
    readonly __typename: 'ProviderMember';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly provider: Provider;
    readonly roles: ReadonlyArray<ProviderRole>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface ProviderMemberInvite {
    readonly __typename: 'ProviderMemberInvite';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly email: Scalars['EmailAddress']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly provider: Provider;
    readonly redeemedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly roles: ReadonlyArray<ProviderRole>;
}

export interface ProviderModule {
    readonly __typename: 'ProviderModule';
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export interface ProviderMySchoolInformationConnection {
    readonly __typename: 'ProviderMySchoolInformationConnection';
    readonly edges: ReadonlyArray<ProviderMySchoolInformationEdge>;
    readonly nodes: ReadonlyArray<Maybe<ProviderMySchoolInformationItem>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProviderMySchoolInformationEdge {
    readonly __typename: 'ProviderMySchoolInformationEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ProviderMySchoolInformationItem>;
}

export interface ProviderMySchoolInformationItem {
    readonly __typename: 'ProviderMySchoolInformationItem';
    readonly createdAt: Scalars['Date']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly sequence: Scalars['Int']['output'];
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['Date']['output'];
}

export interface ProviderMySchoolInformationSortInput {
    readonly id: Scalars['UUID']['input'];
    readonly sequence: Scalars['Int']['input'];
}

export interface ProviderPost extends Deletable, NewsfeedItem, Updatable {
    readonly __typename: 'ProviderPost';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly canViewPollResults: Scalars['Boolean']['output'];
    readonly content: Scalars['String']['output'];
    readonly countryCodes?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
    readonly createdBy?: Maybe<User>;
    readonly hasReaction: Scalars['Boolean']['output'];
    readonly imageUrl?: Maybe<Scalars['URL']['output']>;
    readonly isPinned: Scalars['Boolean']['output'];
    readonly itemCreated: Scalars['DateTime']['output'];
    readonly itemId: Scalars['UUID']['output'];
    readonly itemType: NewsfeedItemType;
    readonly itemUpdated?: Maybe<Scalars['DateTime']['output']>;
    readonly mentorOnly: Scalars['Boolean']['output'];
    readonly mentorStudentOnly: Scalars['Boolean']['output'];
    readonly myReaction?: Maybe<ProviderPostReaction>;
    readonly pollExpiresAt?: Maybe<Scalars['DateTime']['output']>;
    readonly pollOptions?: Maybe<ReadonlyArray<ProviderPostPollOption>>;
    readonly postMetadata: PostMetadata;
    readonly provider: Provider;
    readonly reactions: ProviderPostReactionConnection;
    readonly reactionsTotalCountByType: ReadonlyArray<ProviderPostReactionTotalCountByType>;
    readonly selectedPollOption?: Maybe<ProviderPostPollResponse>;
    readonly totalPollResponsesCount: Scalars['Int']['output'];
}

export interface ProviderPostReactionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    type?: InputMaybe<ProviderPostReactionType>;
}

export interface ProviderPostConnection {
    readonly __typename: 'ProviderPostConnection';
    readonly edges: ReadonlyArray<ProviderPostEdge>;
    readonly nodes: ReadonlyArray<Maybe<ProviderPost>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProviderPostEdge {
    readonly __typename: 'ProviderPostEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ProviderPost>;
}

export interface ProviderPostPollOption {
    readonly __typename: 'ProviderPostPollOption';
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
    readonly totalResponsesCount: Scalars['Int']['output'];
    readonly totalResponsesCountPercentage: Scalars['Float']['output'];
}

export interface ProviderPostPollResponse {
    readonly __typename: 'ProviderPostPollResponse';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly providerPost: ProviderPost;
    readonly providerPostPollOption: ProviderPostPollOption;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface ProviderPostReaction {
    readonly __typename: 'ProviderPostReaction';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly providerPost: ProviderPost;
    readonly type: ProviderPostReactionType;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface ProviderPostReactionConnection {
    readonly __typename: 'ProviderPostReactionConnection';
    readonly edges: ReadonlyArray<ProviderPostReactionEdge>;
    readonly nodes: ReadonlyArray<Maybe<ProviderPostReaction>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProviderPostReactionEdge {
    readonly __typename: 'ProviderPostReactionEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ProviderPostReaction>;
}

export interface ProviderPostReactionTotalCountByType {
    readonly __typename: 'ProviderPostReactionTotalCountByType';
    readonly count: Scalars['Int']['output'];
    readonly type: ProviderPostReactionType;
}

export enum ProviderPostReactionType {
    ANGRY = 'ANGRY',
    LAUGH = 'LAUGH',
    LOVE = 'LOVE',
    SURPRISE = 'SURPRISE'
}

export interface ProviderProjectTemplate extends Deletable, Updatable {
    readonly __typename: 'ProviderProjectTemplate';
    readonly bannerImageId?: Maybe<Scalars['UUID']['output']>;
    readonly bannerImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly id: Scalars['UUID']['output'];
    readonly isPublic: Scalars['Boolean']['output'];
    readonly overviewDescription: Scalars['String']['output'];
    readonly overviewResources: ReadonlyArray<ProviderProjectTemplateOverviewResource>;
    readonly provider: Provider;
    readonly sections: ReadonlyArray<ProviderProjectTemplateSection>;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly updatedBy: User;
}

export interface ProviderProjectTemplateConnection {
    readonly __typename: 'ProviderProjectTemplateConnection';
    readonly edges: ReadonlyArray<ProviderProjectTemplateEdge>;
    readonly nodes: ReadonlyArray<Maybe<ProviderProjectTemplate>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ProviderProjectTemplateEdge {
    readonly __typename: 'ProviderProjectTemplateEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ProviderProjectTemplate>;
}

export interface ProviderProjectTemplateOverviewResource extends Deletable, Updatable {
    readonly __typename: 'ProviderProjectTemplateOverviewResource';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly lesson?: Maybe<Lesson>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly url: Scalars['URL']['output'];
}

export interface ProviderProjectTemplateSection extends Deletable, Updatable {
    readonly __typename: 'ProviderProjectTemplateSection';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly color: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly resources: ReadonlyArray<ProviderProjectTemplateSectionResource>;
    readonly sectionOrder: Scalars['Int']['output'];
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProviderProjectTemplateSectionResource extends Deletable, Updatable {
    readonly __typename: 'ProviderProjectTemplateSectionResource';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly lesson?: Maybe<Lesson>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly url: Scalars['URL']['output'];
}

export interface ProviderRole {
    readonly __typename: 'ProviderRole';
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
}

export enum ProviderSortType {
    ALPHABETICAL_A_Z = 'ALPHABETICAL_A_Z',
    ALPHABETICAL_Z_A = 'ALPHABETICAL_Z_A',
    SUGGESTED = 'SUGGESTED'
}

export interface ProviderTechnicalContactDetails {
    readonly __typename: 'ProviderTechnicalContactDetails';
    readonly email?: Maybe<Scalars['EmailAddress']['output']>;
    readonly fullName?: Maybe<Scalars['String']['output']>;
    readonly phone?: Maybe<Scalars['String']['output']>;
}

export enum ProviderType {
    COMMERCIAL = 'COMMERCIAL',
    HIGHSCHOOL = 'HIGHSCHOOL',
    MIDDLESCHOOL = 'MIDDLESCHOOL',
    OTHER = 'OTHER',
    PRIMARYSCHOOL = 'PRIMARYSCHOOL',
    TERTIARY = 'TERTIARY'
}

export interface PublishSponsorCampaignInput {
    readonly id: Scalars['UUID']['input'];
}

export interface PublishSponsorCampaignPayload {
    readonly __typename: 'PublishSponsorCampaignPayload';
    readonly sponsorCampaign: SponsorCampaign;
}

export interface Query {
    readonly __typename: 'Query';
    readonly badge?: Maybe<Badge>;
    readonly bannerAds: ReadonlyArray<BannerAd>;
    readonly checkWalletCardVerificationSimple: WalletCardVerificationSimpleStatus;
    readonly connectedUser: ConnectedUser;
    readonly geoLocation?: Maybe<GeoLocation>;
    readonly geoLocationCountryRegions: GeoLocationConnection;
    readonly geoLocationSearch: GeoLocationConnection;
    readonly goal?: Maybe<Goal>;
    readonly goals: GoalConnection;
    readonly joinableMentorGroups: JoinableMentorGroupConnection;
    readonly lesson?: Maybe<Lesson>;
    readonly lessonCategories: LessonCategoryConnection;
    readonly lessonCategory?: Maybe<LessonCategory>;
    readonly lessonTagsList: ReadonlyArray<Scalars['String']['output']>;
    readonly lessons: LessonConnection;
    readonly me: UserData;
    readonly mentorBadgeStudent?: Maybe<MentorBadgeStudent>;
    readonly mentorGroup?: Maybe<MentorGroup>;
    readonly mentorGroupDiscussionMessage?: Maybe<MentorGroupDiscussionMessage>;
    readonly mentorGroupStudent?: Maybe<MentorGroupStudent>;
    readonly mentorRewardStudent?: Maybe<MentorRewardStudent>;
    readonly mentorSharedResource?: Maybe<MentorSharedResource>;
    readonly mentorStudent?: Maybe<MentorStudent>;
    readonly newsfeed: NewsfeedItemConnection;
    readonly newsfeedItem?: Maybe<NewsfeedItem>;
    readonly opportunityListing?: Maybe<OpportunityListing>;
    readonly opportunityListings: OpportunityListingConnection;
    readonly pathwayPlanner?: Maybe<PathwayPlanner>;
    readonly pathwayPlanners: PathwayPlannerConnection;
    readonly project?: Maybe<Project>;
    readonly projectSection?: Maybe<ProjectSection>;
    readonly projects: ProjectConnection;
    readonly projectsSimilarInMentorGroup: ProjectConnection;
    readonly provider?: Maybe<Provider>;
    readonly providerBadgeTemplate?: Maybe<ProviderBadgeTemplate>;
    readonly providerIntegration?: Maybe<ProviderIntegration>;
    readonly providerModules: ReadonlyArray<ProviderModule>;
    readonly providerMySchoolInformationItem?: Maybe<ProviderMySchoolInformationItem>;
    readonly providerProjectTemplate?: Maybe<ProviderProjectTemplate>;
    readonly providerProjectTemplateSection?: Maybe<ProviderProjectTemplateSection>;
    readonly providerRoles: ReadonlyArray<ProviderRole>;
    readonly providers: ProviderConnection;
    readonly reflection?: Maybe<Reflection>;
    readonly reflections: ReflectionConnection;
    readonly resourceHubItem?: Maybe<ResourceHubItem>;
    readonly savedStudyProgrammes: SavedStudyProgrammeConnection;
    readonly simpleMatchWalletCardsForPresentation: WalletCardPresentationSimpleMatches;
    readonly sponsorAssets: SponsorAssetConnection;
    readonly sponsorCampaign?: Maybe<SponsorCampaign>;
    readonly sponsorCampaignBanner?: Maybe<SponsorCampaignBanner>;
    readonly sponsorCampaigns: SponsorCampaignConnection;
    readonly sponsorProvider?: Maybe<SponsorProvider>;
    readonly sponsorProviders: SponsorProviderConnection;
    readonly studentHomeworkDiaryMentorTask?: Maybe<StudentHomeworkDiaryMentorTask>;
    readonly studyCategories: StudyCategoryConnection;
    readonly studyCategory?: Maybe<StudyCategory>;
    readonly studyProgramme?: Maybe<StudyProgramme>;
    readonly studyProgrammes: StudyProgrammeConnection;
    readonly studyProvider?: Maybe<StudyProvider>;
    readonly studyProviders: StudyProviderConnection;
    readonly threeGoodThingsList: ThreeGoodThingsConnection;
    readonly urlMetadata: Scalars['JSON']['output'];
    readonly walletCard?: Maybe<WalletCard>;
}

export interface QueryBadgeArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryCheckWalletCardVerificationSimpleArgs {
    transactionId: Scalars['UUID']['input'];
}

export interface QueryConnectedUserArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryGeoLocationArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryGeoLocationCountryRegionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    countryCode: Scalars['CountryCode']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryGeoLocationSearchArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    countryCode: Scalars['CountryCode']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query: Scalars['String']['input'];
    type: GeoLocationSearchType;
}

export interface QueryGoalArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryGoalsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<GoalType>>;
    userId: Scalars['UUID']['input'];
}

export interface QueryJoinableMentorGroupsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    privateOnly?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryLessonArgs {
    id: Scalars['UUID']['input'];
    locale?: InputMaybe<Scalars['Locale']['input']>;
}

export interface QueryLessonCategoriesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locale?: InputMaybe<Scalars['Locale']['input']>;
}

export interface QueryLessonCategoryArgs {
    id: Scalars['UUID']['input'];
    locale?: InputMaybe<Scalars['Locale']['input']>;
}

export interface QueryLessonTagsListArgs {
    locale?: InputMaybe<Scalars['Locale']['input']>;
}

export interface QueryLessonsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    countryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    first?: InputMaybe<Scalars['Int']['input']>;
    isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    lessonCategoryId?: InputMaybe<Scalars['String']['input']>;
    locale?: InputMaybe<Scalars['Locale']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<LessonSortType>;
    tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface QueryMentorBadgeStudentArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryMentorGroupArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryMentorGroupDiscussionMessageArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryMentorGroupStudentArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryMentorRewardStudentArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryMentorSharedResourceArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryMentorStudentArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryNewsfeedArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryNewsfeedItemArgs {
    itemId: Scalars['UUID']['input'];
    itemType: NewsfeedItemType;
}

export interface QueryOpportunityListingArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryOpportunityListingsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    country?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    geoLocationId?: InputMaybe<Scalars['UUID']['input']>;
    includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<OpportunitySortType>;
    types?: InputMaybe<ReadonlyArray<OpportunityListingType>>;
}

export interface QueryPathwayPlannerArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryPathwayPlannersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    userId: Scalars['UUID']['input'];
}

export interface QueryProjectArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProjectSectionArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProjectsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    userId: Scalars['UUID']['input'];
}

export interface QueryProjectsSimilarInMentorGroupArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    mentorGroupId: Scalars['UUID']['input'];
    projectId: Scalars['UUID']['input'];
}

export interface QueryProviderArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProviderBadgeTemplateArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProviderIntegrationArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProviderMySchoolInformationItemArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProviderProjectTemplateArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProviderProjectTemplateSectionArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryProvidersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<ProviderSortType>;
    types?: InputMaybe<ReadonlyArray<ProviderType>>;
}

export interface QueryReflectionArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryReflectionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<ReflectionType>>;
    userId: Scalars['UUID']['input'];
}

export interface QueryResourceHubItemArgs {
    id: Scalars['UUID']['input'];
}

export interface QuerySavedStudyProgrammesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    userId: Scalars['UUID']['input'];
}

export interface QuerySimpleMatchWalletCardsForPresentationArgs {
    clientId: Scalars['String']['input'];
    clientMetadata: Scalars['JSONObject']['input'];
    dcqlQuery: Scalars['JSONObject']['input'];
}

export interface QuerySponsorAssetsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    sponsorProviderId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QuerySponsorCampaignArgs {
    id: Scalars['UUID']['input'];
}

export interface QuerySponsorCampaignBannerArgs {
    type: SponsorAssetType;
}

export interface QuerySponsorCampaignsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    sponsorCampaignStatus?: InputMaybe<SponsorCampaignStatus>;
    sponsorProviderId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QuerySponsorProviderArgs {
    id: Scalars['UUID']['input'];
}

export interface QuerySponsorProvidersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryStudentHomeworkDiaryMentorTaskArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryStudyCategoriesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryStudyCategoryArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryStudyProgrammeArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryStudyProgrammesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forLandingList?: InputMaybe<Scalars['Boolean']['input']>;
    isEnquiriesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isEnrolmentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
    studyCategoryId?: InputMaybe<Scalars['UUID']['input']>;
    studyCountryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    studyProviderId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryStudyProviderArgs {
    id: Scalars['UUID']['input'];
}

export interface QueryStudyProvidersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    studyCountryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface QueryThreeGoodThingsListArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryUrlMetadataArgs {
    url: Scalars['URL']['input'];
}

export interface QueryWalletCardArgs {
    id: Scalars['UUID']['input'];
}

export interface QueueDeleteProviderIntegrationEdgeLearningInput {
    readonly id: Scalars['UUID']['input'];
}

export interface QueueDeleteProviderIntegrationEdgeLearningPayload {
    readonly __typename: 'QueueDeleteProviderIntegrationEdgeLearningPayload';
    readonly providerIntegration: ProviderIntegrationEdgeLearning;
    readonly success: Scalars['Boolean']['output'];
}

export interface QueueDeleteProviderIntegrationKamarInput {
    readonly id: Scalars['UUID']['input'];
}

export interface QueueDeleteProviderIntegrationKamarPayload {
    readonly __typename: 'QueueDeleteProviderIntegrationKamarPayload';
    readonly providerIntegration: ProviderIntegrationKamar;
    readonly success: Scalars['Boolean']['output'];
}

export interface QueueDeleteProviderIntegrationLissInput {
    readonly id: Scalars['UUID']['input'];
}

export interface QueueDeleteProviderIntegrationLissPayload {
    readonly __typename: 'QueueDeleteProviderIntegrationLissPayload';
    readonly providerIntegration: ProviderIntegrationLiss;
    readonly success: Scalars['Boolean']['output'];
}

export interface QueueDeleteProviderIntegrationManualSmsDataInput {
    readonly id: Scalars['UUID']['input'];
}

export interface QueueDeleteProviderIntegrationManualSmsDataPayload {
    readonly __typename: 'QueueDeleteProviderIntegrationManualSmsDataPayload';
    readonly providerIntegration: ProviderIntegrationManualSmsData;
    readonly success: Scalars['Boolean']['output'];
}

export interface QueueDeleteProviderIntegrationWondeInput {
    readonly id: Scalars['UUID']['input'];
}

export interface QueueDeleteProviderIntegrationWondePayload {
    readonly __typename: 'QueueDeleteProviderIntegrationWondePayload';
    readonly providerIntegration: ProviderIntegrationWonde;
    readonly success: Scalars['Boolean']['output'];
}

export interface QueueDeleteProviderRewardInput {
    readonly providerId: Scalars['UUID']['input'];
}

export interface QueueDeleteProviderRewardPayload {
    readonly __typename: 'QueueDeleteProviderRewardPayload';
    readonly providerId: Scalars['UUID']['output'];
}

export interface QueueImportMentorGroupsFromLearnerSmsDataInput {
    readonly groups: ReadonlyArray<QueueImportMentorGroupsFromLearnerSmsDataInputGroup>;
    readonly providerId: Scalars['String']['input'];
}

export interface QueueImportMentorGroupsFromLearnerSmsDataInputGroup {
    readonly employeeIds: ReadonlyArray<Scalars['UUID']['input']>;
    readonly groupId: Scalars['UUID']['input'];
}

export interface QueueImportMentorGroupsFromLearnerSmsDataPayload {
    readonly __typename: 'QueueImportMentorGroupsFromLearnerSmsDataPayload';
    readonly success: Scalars['Boolean']['output'];
}

export interface QueueUrlScrapePayload {
    readonly __typename: 'QueueUrlScrapePayload';
    readonly success: Scalars['Boolean']['output'];
}

export interface ReactMentorGroupDiscussionMessageInput {
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['input'];
    readonly type: Scalars['Emoji']['input'];
}

export interface ReactMentorGroupDiscussionMessagePayload {
    readonly __typename: 'ReactMentorGroupDiscussionMessagePayload';
    readonly mentorGroupDiscussionMessage: MentorGroupDiscussionMessage;
    readonly mentorGroupDiscussionMessageReaction: MentorGroupDiscussionMessageReaction;
}

export interface ReactProviderPostInput {
    readonly providerPostId: Scalars['UUID']['input'];
    readonly type: ProviderPostReactionType;
}

export interface ReactProviderPostPayload {
    readonly __typename: 'ReactProviderPostPayload';
    readonly providerPost: ProviderPost;
    readonly providerPostReaction: ProviderPostReaction;
}

export interface ReactivateUserEmailSuppressionPayload {
    readonly __typename: 'ReactivateUserEmailSuppressionPayload';
    readonly success: Scalars['Boolean']['output'];
}

export interface RedeemProviderJoinCodeInput {
    readonly code: Scalars['String']['input'];
}

export interface RedeemProviderJoinCodePayload {
    readonly __typename: 'RedeemProviderJoinCodePayload';
    readonly providerMember: ProviderMember;
}

export interface Reflection extends Deletable, Updatable {
    readonly __typename: 'Reflection';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly reflectionType: ReflectionType;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface ReflectionConnection {
    readonly __typename: 'ReflectionConnection';
    readonly edges: ReadonlyArray<ReflectionEdge>;
    readonly lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly nodes: ReadonlyArray<Maybe<Reflection>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ReflectionEdge {
    readonly __typename: 'ReflectionEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<Reflection>;
}

export enum ReflectionType {
    LEARNING = 'LEARNING',
    PASSION = 'PASSION',
    STRENGTH = 'STRENGTH',
    VALUE = 'VALUE',
    WEAKNESS = 'WEAKNESS'
}

export interface RegenerateMentorGroupInviteCodeInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface RegenerateMentorGroupInviteCodePayload {
    readonly __typename: 'RegenerateMentorGroupInviteCodePayload';
    readonly mentorGroup: MentorGroup;
}

export interface RegenerateProviderIntegrationKamarTokenInput {
    readonly id: Scalars['UUID']['input'];
}

export interface RegenerateProviderIntegrationKamarTokenPayload {
    readonly __typename: 'RegenerateProviderIntegrationKamarTokenPayload';
    readonly providerIntegration: ProviderIntegrationKamar;
}

export interface RegenerateProviderIntegrationLissTokenInput {
    readonly id: Scalars['UUID']['input'];
}

export interface RegenerateProviderIntegrationLissTokenPayload {
    readonly __typename: 'RegenerateProviderIntegrationLissTokenPayload';
    readonly providerIntegration: ProviderIntegrationLiss;
}

export interface RejectUserConnectionRequestInput {
    readonly requestCode: Scalars['String']['input'];
}

export interface RejectUserConnectionRequestPayload {
    readonly __typename: 'RejectUserConnectionRequestPayload';
    readonly userConnectionRequestId: Scalars['UUID']['output'];
}

export interface RemoveConnectedUserInput {
    readonly connectedUserId: Scalars['UUID']['input'];
}

export interface RemoveConnectedUserPayload {
    readonly __typename: 'RemoveConnectedUserPayload';
    readonly connectedUserId: Scalars['UUID']['output'];
}

export interface RemoveMentorGroupMemberInput {
    readonly mentorGroupMemberId: Scalars['UUID']['input'];
}

export interface RemoveMentorGroupMemberPayload {
    readonly __typename: 'RemoveMentorGroupMemberPayload';
    readonly mentorGroupMemberId: Scalars['UUID']['output'];
}

export interface RemoveMentorGroupStudentInput {
    readonly mentorGroupStudentId: Scalars['UUID']['input'];
}

export interface RemoveMentorGroupStudentPayload {
    readonly __typename: 'RemoveMentorGroupStudentPayload';
    readonly mentorGroupStudentId: Scalars['UUID']['output'];
}

export interface RemoveMentorStudentInput {
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface RemoveMentorStudentPayload {
    readonly __typename: 'RemoveMentorStudentPayload';
    readonly mentorStudentId: Scalars['UUID']['output'];
}

export interface ReportJobProfileActivityInput {
    readonly jobProfileActivityLogId: Scalars['UUID']['input'];
    readonly reason?: InputMaybe<Scalars['String']['input']>;
    readonly reportType: ReportType;
}

export interface ReportJobProfileActivityPayload {
    readonly __typename: 'ReportJobProfileActivityPayload';
    readonly reported: Scalars['Boolean']['output'];
}

export enum ReportType {
    ABUSE = 'ABUSE',
    MISLEADING = 'MISLEADING',
    OTHER = 'OTHER',
    SPAM = 'SPAM'
}

export interface RepostProviderPostInput {
    readonly id: Scalars['UUID']['input'];
}

export interface RepostProviderPostPayload {
    readonly __typename: 'RepostProviderPostPayload';
    readonly providerPost: ProviderPost;
}

export interface RequestProjectSectionReviewInput {
    readonly id: Scalars['UUID']['input'];
}

export interface RequestProjectSectionReviewPayload {
    readonly __typename: 'RequestProjectSectionReviewPayload';
    readonly projectSection: ProjectSection;
}

export interface RequestProviderModuleInput {
    readonly moduleId: Scalars['UUID']['input'];
    readonly providerId: Scalars['UUID']['input'];
}

export interface RequestProviderModulePayload {
    readonly __typename: 'RequestProviderModulePayload';
    readonly module: ProviderModule;
}

export interface RequestUserConnectionInput {
    readonly isLinkShare?: InputMaybe<Scalars['Boolean']['input']>;
    readonly shareeEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly sharerSharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
}

export interface RequestUserConnectionPayload {
    readonly __typename: 'RequestUserConnectionPayload';
    readonly userConnectionRequest: UserConnectionRequest;
}

export interface RequestVerifiedIdCardInput {
    readonly passportDetails?: InputMaybe<RequestVerifiedIdCardInputPassportDetails>;
    readonly sourceWalletCardId: Scalars['UUID']['input'];
    readonly verificationType: RequestVerifiedIdCardInputVerificationType;
}

export interface RequestVerifiedIdCardInputPassportDetails {
    readonly countryCode: Scalars['CountryCode']['input'];
    readonly expiry: Scalars['Date']['input'];
    readonly number: Scalars['String']['input'];
}

export enum RequestVerifiedIdCardInputVerificationType {
    NZ_BIRTH_CERTIFICATE = 'NZ_BIRTH_CERTIFICATE',
    PASSPORT = 'PASSPORT'
}

export interface RequestVerifiedIdCardPayload {
    readonly __typename: 'RequestVerifiedIdCardPayload';
    readonly iFrameSourceUrl: Scalars['String']['output'];
    readonly userVerificationRequestId: Scalars['UUID']['output'];
}

export interface ResolveWalletCardPresentationSimpleInput {
    readonly clientId: Scalars['String']['input'];
    readonly clientMetadata: Scalars['JSONObject']['input'];
    readonly dcqlQuery: Scalars['JSONObject']['input'];
    readonly nonce: Scalars['String']['input'];
    readonly responseUri: Scalars['URL']['input'];
    readonly selectedWalletCards: ReadonlyArray<ResolveWalletCardPresentationSimpleInputSelectedWalletCard>;
    readonly state: Scalars['String']['input'];
}

export interface ResolveWalletCardPresentationSimpleInputSelectedWalletCard {
    readonly acceptedClaims: ReadonlyArray<Scalars['String']['input']>;
    readonly credentialId: Scalars['String']['input'];
    readonly walletCardId: Scalars['UUID']['input'];
}

export interface ResolveWalletCardPresentationSimplePayload {
    readonly __typename: 'ResolveWalletCardPresentationSimplePayload';
    readonly redirectUri?: Maybe<Scalars['URL']['output']>;
    readonly success: Scalars['Boolean']['output'];
}

export interface ResourceHubItem extends Deletable, Updatable {
    readonly __typename: 'ResourceHubItem';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly category: ResourceHubItemCategory;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly fileAvailable: Scalars['Boolean']['output'];
    readonly fileId: Scalars['UUID']['output'];
    readonly fileMimeType: Scalars['String']['output'];
    readonly fileName: Scalars['String']['output'];
    readonly filePreviewUrl: Scalars['URL']['output'];
    readonly fileUrl?: Maybe<Scalars['URL']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
    readonly type: ResourceHubItemType;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
    readonly uses: Scalars['Int']['output'];
}

export enum ResourceHubItemCategory {
    CERTIFICATE = 'CERTIFICATE',
    EVIDENCE = 'EVIDENCE',
    LICENSE = 'LICENSE',
    OTHER = 'OTHER'
}

export interface ResourceHubItemConnection {
    readonly __typename: 'ResourceHubItemConnection';
    readonly edges: ReadonlyArray<ResourceHubItemEdge>;
    readonly lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly nodes: ReadonlyArray<Maybe<ResourceHubItem>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ResourceHubItemEdge {
    readonly __typename: 'ResourceHubItemEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ResourceHubItem>;
}

export enum ResourceHubItemType {
    AUDIO = 'AUDIO',
    DOCUMENT = 'DOCUMENT',
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
    VIDEO = 'VIDEO'
}

export interface ResyncProviderIntegrationWondeDataInput {
    readonly id: Scalars['UUID']['input'];
}

export interface ResyncProviderIntegrationWondeDataPayload {
    readonly __typename: 'ResyncProviderIntegrationWondeDataPayload';
    readonly providerIntegration: ProviderIntegrationWonde;
}

export interface SaveStudyProgrammeInput {
    readonly studyProgrammeId: Scalars['UUID']['input'];
}

export interface SaveStudyProgrammePayload {
    readonly __typename: 'SaveStudyProgrammePayload';
    readonly studyProgramme: StudyProgramme;
}

export interface SavedStudyProgrammeConnection {
    readonly __typename: 'SavedStudyProgrammeConnection';
    readonly edges: ReadonlyArray<SavedStudyProgrammeEdge>;
    readonly lastSavedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly nodes: ReadonlyArray<Maybe<StudyProgramme>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface SavedStudyProgrammeEdge {
    readonly __typename: 'SavedStudyProgrammeEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<StudyProgramme>;
}

export interface SendBroadcastEmailInput {
    readonly template: BroadcastEmailTemplate;
    readonly templateModel: Scalars['JSONObject']['input'];
}

export interface SendBroadcastEmailPayload {
    readonly __typename: 'SendBroadcastEmailPayload';
    readonly numberOfRecipients: Scalars['Int']['output'];
}

export interface SendMentorGroupDiscussionMessageInput {
    readonly imageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly message: Scalars['String']['input'];
    readonly pollExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
    readonly pollOptions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly requiresAcknowledgement?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SendMentorGroupDiscussionMessagePayload {
    readonly __typename: 'SendMentorGroupDiscussionMessagePayload';
    readonly mentorGroupDiscussionMessage: MentorGroupDiscussionMessage;
}

export interface SendMentorGroupInvitesInput {
    readonly emails: ReadonlyArray<Scalars['EmailAddress']['input']>;
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface SendMentorGroupInvitesPayload {
    readonly __typename: 'SendMentorGroupInvitesPayload';
    readonly invites: ReadonlyArray<MentorStudentInvite>;
}

export interface SetMentorStudentAsMainInput {
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface SetMentorStudentAsMainPayload {
    readonly __typename: 'SetMentorStudentAsMainPayload';
    readonly mentorStudent: MentorStudent;
}

export interface ShareMentorStudentNoteInput {
    readonly mentorStudentNoteId: Scalars['UUID']['input'];
}

export interface ShareMentorStudentNotePayload {
    readonly __typename: 'ShareMentorStudentNotePayload';
    readonly mentorStudentNote: MentorStudentNote;
}

export interface ShareMentorStudentTodoInput {
    readonly mentorStudentTodoId: Scalars['UUID']['input'];
}

export interface ShareMentorStudentTodoPayload {
    readonly __typename: 'ShareMentorStudentTodoPayload';
    readonly mentorStudentTodo: MentorStudentTodo;
}

export interface SponsorAsset extends Deletable, Updatable {
    readonly __typename: 'SponsorAsset';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly content?: Maybe<Scalars['String']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly label?: Maybe<Scalars['String']['output']>;
    readonly mediaFileAvailable: Scalars['Boolean']['output'];
    readonly mediaFileId: Scalars['UUID']['output'];
    readonly mediaFileUrl?: Maybe<Scalars['URL']['output']>;
    readonly mediaType: SponsorAssetMediaType;
    readonly sponsorProvider: SponsorProvider;
    readonly targetUrl: Scalars['URL']['output'];
    readonly title?: Maybe<Scalars['String']['output']>;
    readonly type: SponsorAssetType;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface SponsorAssetConnection {
    readonly __typename: 'SponsorAssetConnection';
    readonly edges: ReadonlyArray<SponsorAssetEdge>;
    readonly nodes: ReadonlyArray<Maybe<SponsorAsset>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface SponsorAssetEdge {
    readonly __typename: 'SponsorAssetEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<SponsorAsset>;
}

export enum SponsorAssetMediaType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO'
}

export enum SponsorAssetType {
    GOAL = 'GOAL',
    MENTOR_GROUP = 'MENTOR_GROUP',
    NEWSFEED = 'NEWSFEED',
    REFLECTION = 'REFLECTION'
}

export interface SponsorCampaign extends Deletable, Updatable {
    readonly __typename: 'SponsorCampaign';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canPublish: Scalars['Boolean']['output'];
    readonly canUnpublish: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly endDate: Scalars['Date']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly sponsorCampaignAssets: SponsorCampaignAssetConnection;
    readonly sponsorProvider: SponsorProvider;
    readonly startDate: Scalars['Date']['output'];
    readonly status: SponsorCampaignStatus;
    readonly targetCountries: ReadonlyArray<Scalars['CountryCode']['output']>;
    readonly timezone: Scalars['TimeZone']['output'];
    readonly totalClicksCount: Scalars['Int']['output'];
    readonly totalViewsCount: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface SponsorCampaignSponsorCampaignAssetsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface SponsorCampaignAsset extends Deletable, Updatable {
    readonly __typename: 'SponsorCampaignAsset';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly endDate: Scalars['Date']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly newsfeedPostIndex?: Maybe<Scalars['NonNegativeInt']['output']>;
    readonly sponsorAsset: SponsorAsset;
    readonly startDate: Scalars['Date']['output'];
    readonly targetUser: SponsorTargetUserRole;
    readonly totalClicksCount: Scalars['Int']['output'];
    readonly totalViewsCount: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface SponsorCampaignAssetConnection {
    readonly __typename: 'SponsorCampaignAssetConnection';
    readonly edges: ReadonlyArray<SponsorCampaignAssetEdge>;
    readonly nodes: ReadonlyArray<Maybe<SponsorCampaignAsset>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface SponsorCampaignAssetEdge {
    readonly __typename: 'SponsorCampaignAssetEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<SponsorCampaignAsset>;
}

export interface SponsorCampaignBanner {
    readonly __typename: 'SponsorCampaignBanner';
    readonly content?: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly mediaImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly mediaVideoUrl?: Maybe<Scalars['URL']['output']>;
    readonly sponsorCampaignId: Scalars['UUID']['output'];
    readonly sponsorProviderLogoImageUrl: Scalars['URL']['output'];
    readonly sponsorProviderTitle: Scalars['String']['output'];
    readonly targetUrl: Scalars['URL']['output'];
    readonly title?: Maybe<Scalars['String']['output']>;
}

export interface SponsorCampaignConnection {
    readonly __typename: 'SponsorCampaignConnection';
    readonly edges: ReadonlyArray<SponsorCampaignEdge>;
    readonly nodes: ReadonlyArray<Maybe<SponsorCampaign>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface SponsorCampaignEdge {
    readonly __typename: 'SponsorCampaignEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<SponsorCampaign>;
}

export enum SponsorCampaignStatus {
    EXPIRED = 'EXPIRED',
    ONGOING = 'ONGOING',
    UNPUBLISHED = 'UNPUBLISHED',
    UPCOMING = 'UPCOMING'
}

export interface SponsorPost extends NewsfeedItem {
    readonly __typename: 'SponsorPost';
    readonly content: Scalars['String']['output'];
    readonly itemCreated: Scalars['DateTime']['output'];
    readonly itemId: Scalars['UUID']['output'];
    readonly itemType: NewsfeedItemType;
    readonly itemUpdated?: Maybe<Scalars['DateTime']['output']>;
    readonly mediaImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly mediaVideoUrl?: Maybe<Scalars['URL']['output']>;
    readonly sponsorCampaignId: Scalars['UUID']['output'];
    readonly sponsorProviderLogoImageUrl: Scalars['URL']['output'];
    readonly sponsorProviderTitle: Scalars['String']['output'];
    readonly targetUrl: Scalars['URL']['output'];
    readonly title: Scalars['String']['output'];
}

export interface SponsorProvider extends Deletable, Updatable {
    readonly __typename: 'SponsorProvider';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly logoImageUrl: Scalars['URL']['output'];
    readonly sponsorAssets: SponsorAssetConnection;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface SponsorProviderSponsorAssetsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface SponsorProviderConnection {
    readonly __typename: 'SponsorProviderConnection';
    readonly edges: ReadonlyArray<SponsorProviderEdge>;
    readonly nodes: ReadonlyArray<Maybe<SponsorProvider>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface SponsorProviderEdge {
    readonly __typename: 'SponsorProviderEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<SponsorProvider>;
}

export enum SponsorTargetUserRole {
    ALL = 'ALL',
    LEARNER = 'LEARNER',
    MENTOR = 'MENTOR'
}

export interface StartCurriculumVitaePayload {
    readonly __typename: 'StartCurriculumVitaePayload';
    readonly curriculumVitae: CurriculumVitae;
}

export interface StartMentorGroupDiscussionMessageImageUploadInput {
    readonly fileName: Scalars['String']['input'];
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly mimeType: Scalars['String']['input'];
}

export interface StartMentorGroupDiscussionMessageImageUploadPayload {
    readonly __typename: 'StartMentorGroupDiscussionMessageImageUploadPayload';
    readonly fileId: Scalars['UUID']['output'];
    readonly uploadUrl: Scalars['URL']['output'];
}

export interface StartProviderImageUploadInput {
    readonly fileName: Scalars['String']['input'];
    readonly mimeType: Scalars['String']['input'];
    readonly providerId: Scalars['UUID']['input'];
}

export interface StartProviderImageUploadPayload {
    readonly __typename: 'StartProviderImageUploadPayload';
    readonly fileId: Scalars['UUID']['output'];
    readonly uploadUrl: Scalars['URL']['output'];
}

export interface StartUserImageUploadInput {
    readonly fileName: Scalars['String']['input'];
    readonly mimeType: Scalars['String']['input'];
}

export interface StartUserImageUploadPayload {
    readonly __typename: 'StartUserImageUploadPayload';
    readonly fileId: Scalars['UUID']['output'];
    readonly uploadUrl: Scalars['URL']['output'];
}

export interface StartUserVerificationRequestInput {
    readonly userId: Scalars['String']['input'];
}

export interface StartUserVerificationRequestPayload {
    readonly __typename: 'StartUserVerificationRequestPayload';
    readonly iFrameSourceUrl: Scalars['String']['output'];
    readonly userVerificationRequestId: Scalars['UUID']['output'];
}

export interface StartWalletCardVerificationSimpleInput {
    readonly dcqlQuery: Scalars['JSONObject']['input'];
    readonly redirectUri: Scalars['URL']['input'];
}

export interface StartWalletCardVerificationSimplePayload {
    readonly __typename: 'StartWalletCardVerificationSimplePayload';
    readonly oid4vpUrl: Scalars['URL']['output'];
    readonly transactionId: Scalars['UUID']['output'];
}

export interface StudentHomeworkDiaryConnection {
    readonly __typename: 'StudentHomeworkDiaryConnection';
    readonly edges: ReadonlyArray<StudentHomeworkDiaryEdge>;
    readonly nodes: ReadonlyArray<Maybe<StudentHomeworkDiaryItem>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface StudentHomeworkDiaryDaySummary {
    readonly __typename: 'StudentHomeworkDiaryDaySummary';
    readonly colors: ReadonlyArray<Maybe<Scalars['HexColorCode']['output']>>;
    readonly completed: Scalars['Int']['output'];
    readonly date: Scalars['Date']['output'];
    readonly total: Scalars['Int']['output'];
}

export interface StudentHomeworkDiaryEdge {
    readonly __typename: 'StudentHomeworkDiaryEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<StudentHomeworkDiaryItem>;
}

export interface StudentHomeworkDiaryItem extends Deletable, Updatable {
    readonly __typename: 'StudentHomeworkDiaryItem';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly color?: Maybe<Scalars['HexColorCode']['output']>;
    readonly completedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly date: Scalars['Date']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly imageId?: Maybe<Scalars['UUID']['output']>;
    readonly imageUrl?: Maybe<Scalars['URL']['output']>;
    readonly information?: Maybe<Scalars['String']['output']>;
    readonly mentorGroup?: Maybe<MentorGroup>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface StudentHomeworkDiaryMentorTask extends Deletable, Updatable {
    readonly __typename: 'StudentHomeworkDiaryMentorTask';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly color?: Maybe<Scalars['HexColorCode']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly createdBy: User;
    readonly date: Scalars['Date']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly imageId?: Maybe<Scalars['UUID']['output']>;
    readonly imageUrl?: Maybe<Scalars['URL']['output']>;
    readonly information?: Maybe<Scalars['String']['output']>;
    readonly studentsAssigned: MentorStudentConnection;
    readonly studentsCompleted: MentorStudentConnection;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface StudentHomeworkDiaryMentorTaskStudentsAssignedArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface StudentHomeworkDiaryMentorTaskStudentsCompletedArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface StudentHomeworkDiaryMentorTaskConnection {
    readonly __typename: 'StudentHomeworkDiaryMentorTaskConnection';
    readonly edges: ReadonlyArray<StudentHomeworkDiaryMentorTaskEdge>;
    readonly nodes: ReadonlyArray<Maybe<StudentHomeworkDiaryMentorTask>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface StudentHomeworkDiaryMentorTaskDaySummary {
    readonly __typename: 'StudentHomeworkDiaryMentorTaskDaySummary';
    readonly colors: ReadonlyArray<Maybe<Scalars['HexColorCode']['output']>>;
    readonly date: Scalars['Date']['output'];
}

export interface StudentHomeworkDiaryMentorTaskEdge {
    readonly __typename: 'StudentHomeworkDiaryMentorTaskEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<StudentHomeworkDiaryMentorTask>;
}

export interface StudentHomeworkDiaryMentorTaskMonthSummary {
    readonly __typename: 'StudentHomeworkDiaryMentorTaskMonthSummary';
    readonly colors: ReadonlyArray<Maybe<Scalars['HexColorCode']['output']>>;
    readonly days: ReadonlyArray<StudentHomeworkDiaryMentorTaskDaySummary>;
}

export interface StudentHomeworkDiaryMonthSummary {
    readonly __typename: 'StudentHomeworkDiaryMonthSummary';
    readonly colors: ReadonlyArray<Maybe<Scalars['HexColorCode']['output']>>;
    readonly completed: Scalars['Int']['output'];
    readonly days: ReadonlyArray<StudentHomeworkDiaryDaySummary>;
    readonly total: Scalars['Int']['output'];
}

export interface StudentHomeworkItemsDueReminder extends NewsfeedItem {
    readonly __typename: 'StudentHomeworkItemsDueReminder';
    readonly itemCreated: Scalars['DateTime']['output'];
    readonly itemId: Scalars['UUID']['output'];
    readonly itemType: NewsfeedItemType;
    readonly itemUpdated?: Maybe<Scalars['DateTime']['output']>;
    readonly studentHomeworkItems: ReadonlyArray<StudentHomeworkDiaryItem>;
    readonly totalDue: Scalars['Int']['output'];
}

export interface StudentJobProfile {
    readonly __typename: 'StudentJobProfile';
    readonly achievementsAndAwards?: Maybe<CurriculumVitaeAchievementsAndAwards>;
    readonly age: Scalars['Int']['output'];
    readonly canContact: Scalars['Boolean']['output'];
    readonly driversLicenceType: DriversLicenceType;
    readonly education: CurriculumVitaeEducation;
    readonly employment?: Maybe<CurriculumVitaeEmployment>;
    readonly extracurricularActivities?: Maybe<CurriculumVitaeExtracurricularActivities>;
    readonly givenName: Scalars['String']['output'];
    readonly hasContacted: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly interests: Scalars['String']['output'];
    readonly isFavourite: Scalars['Boolean']['output'];
    readonly languageLocales?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
    readonly lastContactedAt?: Maybe<Scalars['DateTime']['output']>;
    readonly personalAttributes: CurriculumVitaePersonalAttributes;
    readonly personalStatement: CurriculumVitaePersonalStatement;
    readonly preferredJobAvailability: Scalars['String']['output'];
    readonly preferredJobCategories: ReadonlyArray<JobCategory>;
    readonly preferredJobGeoLocations: ReadonlyArray<GeoLocation>;
    readonly preferredJobTypes: ReadonlyArray<JobType>;
    readonly profileImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly references: CurriculumVitaeReferences;
    readonly skills: CurriculumVitaeSkills;
    readonly volunteer?: Maybe<CurriculumVitaeVolunteer>;
}

export interface StudentJobProfileSearchResultItem {
    readonly __typename: 'StudentJobProfileSearchResultItem';
    readonly age: Scalars['Int']['output'];
    readonly givenName: Scalars['String']['output'];
    readonly hasContacted: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly isFavourite: Scalars['Boolean']['output'];
    readonly preferredJobCategories: ReadonlyArray<JobCategory>;
    readonly preferredJobGeoLocations: ReadonlyArray<GeoLocation>;
    readonly preferredJobTypes: ReadonlyArray<JobType>;
    readonly profileImageUrl?: Maybe<Scalars['URL']['output']>;
}

export interface StudentJobProfileSearchResultItemConnection {
    readonly __typename: 'StudentJobProfileSearchResultItemConnection';
    readonly edges: ReadonlyArray<StudentJobProfileSearchResultItemEdge>;
    readonly nodes: ReadonlyArray<Maybe<StudentJobProfileSearchResultItem>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface StudentJobProfileSearchResultItemEdge {
    readonly __typename: 'StudentJobProfileSearchResultItemEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<StudentJobProfileSearchResultItem>;
}

export interface StudyCategory {
    readonly __typename: 'StudyCategory';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface StudyCategoryConnection {
    readonly __typename: 'StudyCategoryConnection';
    readonly edges: ReadonlyArray<StudyCategoryEdge>;
    readonly nodes: ReadonlyArray<Maybe<StudyCategory>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface StudyCategoryEdge {
    readonly __typename: 'StudyCategoryEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<StudyCategory>;
}

export interface StudyProgramme extends Deletable, Updatable {
    readonly __typename: 'StudyProgramme';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canEnquireStudyProgramme: Scalars['Boolean']['output'];
    readonly canEnrolInStudyProgramme: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly hasSavedStudyProgramme: Scalars['Boolean']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly information: Scalars['String']['output'];
    readonly link: Scalars['URL']['output'];
    readonly studyCategory: StudyCategory;
    readonly studyCountryCodes: ReadonlyArray<Scalars['String']['output']>;
    readonly studyProgrammeType: StudyProgrammeType;
    readonly studyProvider: StudyProvider;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface StudyProgrammeConnection {
    readonly __typename: 'StudyProgrammeConnection';
    readonly edges: ReadonlyArray<StudyProgrammeEdge>;
    readonly nodes: ReadonlyArray<Maybe<StudyProgramme>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface StudyProgrammeEdge {
    readonly __typename: 'StudyProgrammeEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<StudyProgramme>;
}

export interface StudyProgrammeSearchInput {
    readonly keywords: Scalars['String']['input'];
    readonly limit?: InputMaybe<Scalars['Int']['input']>;
    readonly offset?: InputMaybe<Scalars['Int']['input']>;
}

export enum StudyProgrammeType {
    NZ_STP = 'NZ_STP',
    STANDARD = 'STANDARD'
}

export interface StudyProvider {
    readonly __typename: 'StudyProvider';
    readonly countryCode: Scalars['String']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly email?: Maybe<Scalars['EmailAddress']['output']>;
    readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
    readonly id: Scalars['UUID']['output'];
    readonly isEnquiriesEnabled: Scalars['Boolean']['output'];
    readonly isEnrolmentEnabled: Scalars['Boolean']['output'];
    readonly logoImageId?: Maybe<Scalars['UUID']['output']>;
    readonly logoImageUrl?: Maybe<Scalars['URL']['output']>;
    readonly title: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface StudyProviderConnection {
    readonly __typename: 'StudyProviderConnection';
    readonly edges: ReadonlyArray<StudyProviderEdge>;
    readonly nodes: ReadonlyArray<Maybe<StudyProvider>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface StudyProviderEdge {
    readonly __typename: 'StudyProviderEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<StudyProvider>;
}

export interface SubmitCareersRiasecResultInput {
    readonly interestArtistic: Scalars['Float']['input'];
    readonly interestConventional: Scalars['Float']['input'];
    readonly interestEnterprising: Scalars['Float']['input'];
    readonly interestInvestigative: Scalars['Float']['input'];
    readonly interestRealistic: Scalars['Float']['input'];
    readonly interestSocial: Scalars['Float']['input'];
}

export interface SubmitCareersRiasecResultPayload {
    readonly __typename: 'SubmitCareersRiasecResultPayload';
    readonly careersRiasecResult: CareersRiasecResult;
}

export interface Subscription {
    readonly __typename: 'Subscription';
    readonly mentorGroupDiscussionMessageChanged: MentorGroupDiscussionMessage;
    readonly mentorGroupDiscussionMessageDeleted: Scalars['UUID']['output'];
    readonly mentorGroupDiscussionMessageSent: MentorGroupDiscussionMessage;
}

export interface SubscriptionMentorGroupDiscussionMessageChangedArgs {
    mentorGroupId: Scalars['UUID']['input'];
}

export interface SubscriptionMentorGroupDiscussionMessageDeletedArgs {
    mentorGroupId: Scalars['UUID']['input'];
}

export interface SubscriptionMentorGroupDiscussionMessageSentArgs {
    mentorGroupId: Scalars['UUID']['input'];
}

export interface ThreeGoodThings extends Deletable, Updatable {
    readonly __typename: 'ThreeGoodThings';
    readonly canDelete: Scalars['Boolean']['output'];
    readonly canUpdate: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly date: Scalars['Date']['output'];
    readonly firstItem: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly secondItem?: Maybe<Scalars['String']['output']>;
    readonly thirdItem?: Maybe<Scalars['String']['output']>;
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly user: User;
}

export interface ThreeGoodThingsConnection {
    readonly __typename: 'ThreeGoodThingsConnection';
    readonly edges: ReadonlyArray<ThreeGoodThingsEdge>;
    readonly nodes: ReadonlyArray<Maybe<ThreeGoodThings>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface ThreeGoodThingsEdge {
    readonly __typename: 'ThreeGoodThingsEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<ThreeGoodThings>;
}

export interface TimetableEvent {
    readonly __typename: 'TimetableEvent';
    readonly color: Scalars['HexColorCode']['output'];
    readonly dateType: TimetableEventDateType;
    readonly description?: Maybe<Scalars['String']['output']>;
    readonly end?: Maybe<Scalars['DateTime']['output']>;
    readonly id: Scalars['UUID']['output'];
    readonly location?: Maybe<Scalars['String']['output']>;
    readonly priority: Scalars['Int']['output'];
    readonly start: Scalars['DateTime']['output'];
    readonly summary: Scalars['String']['output'];
}

export enum TimetableEventDateType {
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME'
}

export interface ToggleFavoriteLessonInput {
    readonly favorite: Scalars['Boolean']['input'];
    readonly lessonId: Scalars['UUID']['input'];
    readonly lessonLocale?: InputMaybe<Scalars['Locale']['input']>;
    readonly userId: Scalars['UUID']['input'];
}

export interface ToggleFavoriteLessonPayload {
    readonly __typename: 'ToggleFavoriteLessonPayload';
    readonly lesson: Lesson;
}

export interface ToggleFeaturedLessonInput {
    readonly lessonId: Scalars['UUID']['input'];
}

export interface ToggleFeaturedLessonPayload {
    readonly __typename: 'ToggleFeaturedLessonPayload';
    readonly lesson: Lesson;
}

export interface ToggleIsFavouriteStudentJobProfileInput {
    readonly jobProfileId: Scalars['UUID']['input'];
    readonly providerId: Scalars['UUID']['input'];
}

export interface ToggleIsFavouriteStudentJobProfilePayload {
    readonly __typename: 'ToggleIsFavouriteStudentJobProfilePayload';
    readonly studentJobProfile: StudentJobProfile;
}

export interface ToggleMentorGroupJoinabilityInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface ToggleMentorGroupJoinabilityPayload {
    readonly __typename: 'ToggleMentorGroupJoinabilityPayload';
    readonly mentorGroup: MentorGroup;
}

export interface TogglePinnedConnectionInput {
    readonly connectedUserId: Scalars['UUID']['input'];
}

export interface TogglePinnedConnectionPayload {
    readonly __typename: 'TogglePinnedConnectionPayload';
    readonly connectedUser: ConnectedUser;
}

export interface TogglePinnedMentorGroupInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly userId: Scalars['UUID']['input'];
}

export interface TogglePinnedMentorGroupPayload {
    readonly __typename: 'TogglePinnedMentorGroupPayload';
    readonly mentorGroup: MentorGroup;
}

export interface ToggleProviderLessonAccessInput {
    readonly providerId: Scalars['UUID']['input'];
}

export interface ToggleProviderLessonAccessPayload {
    readonly __typename: 'ToggleProviderLessonAccessPayload';
    readonly provider: Provider;
}

export interface ToggleReadLessonInput {
    readonly lessonId: Scalars['UUID']['input'];
    readonly lessonLocale?: InputMaybe<Scalars['Locale']['input']>;
    readonly read: Scalars['Boolean']['input'];
    readonly userId: Scalars['UUID']['input'];
}

export interface ToggleReadLessonPayload {
    readonly __typename: 'ToggleReadLessonPayload';
    readonly lesson: Lesson;
}

export interface ToggleStudentHomeworkDiaryItemCompletionInput {
    readonly id: Scalars['UUID']['input'];
}

export interface ToggleStudentHomeworkDiaryItemCompletionPayload {
    readonly __typename: 'ToggleStudentHomeworkDiaryItemCompletionPayload';
    readonly studentHomeworkDiaryItem: StudentHomeworkDiaryItem;
}

export interface UncompleteProjectSectionInput {
    readonly id: Scalars['UUID']['input'];
}

export interface UncompleteProjectSectionPayload {
    readonly __typename: 'UncompleteProjectSectionPayload';
    readonly projectSection: ProjectSection;
}

export interface UnfollowProviderInput {
    readonly providerId: Scalars['UUID']['input'];
}

export interface UnfollowProviderPayload {
    readonly __typename: 'UnfollowProviderPayload';
    readonly providerId: Scalars['UUID']['output'];
}

export interface UnlinkAccountInput {
    readonly connection: Scalars['String']['input'];
    readonly email: Scalars['EmailAddress']['input'];
    readonly federatedId: Scalars['String']['input'];
}

export interface UnlinkAccountPayload {
    readonly __typename: 'UnlinkAccountPayload';
    readonly user: UserData;
}

export interface UnlockMentorGroupDiscussionInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface UnlockMentorGroupDiscussionPayload {
    readonly __typename: 'UnlockMentorGroupDiscussionPayload';
    readonly mentorGroup: MentorGroup;
}

export interface UnmuteMentorGroupDiscussionUserInput {
    readonly mentorGroupId: Scalars['UUID']['input'];
    readonly userId: Scalars['UUID']['input'];
}

export interface UnmuteMentorGroupDiscussionUserPayload {
    readonly __typename: 'UnmuteMentorGroupDiscussionUserPayload';
    readonly mentorGroup: MentorGroup;
}

export interface UnpinMentorGroupDiscussionMessageInput {
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['input'];
}

export interface UnpinMentorGroupDiscussionMessagePayload {
    readonly __typename: 'UnpinMentorGroupDiscussionMessagePayload';
    readonly mentorGroupDiscussionMessage: MentorGroupDiscussionMessage;
}

export interface UnpublishSponsorCampaignInput {
    readonly id: Scalars['UUID']['input'];
}

export interface UnpublishSponsorCampaignPayload {
    readonly __typename: 'UnpublishSponsorCampaignPayload';
    readonly sponsorCampaign: SponsorCampaign;
}

export interface UnreactMentorGroupDiscussionMessageInput {
    readonly mentorGroupDiscussionMessageId: Scalars['UUID']['input'];
}

export interface UnreactMentorGroupDiscussionMessagePayload {
    readonly __typename: 'UnreactMentorGroupDiscussionMessagePayload';
    readonly mentorGroupDiscussionMessage: MentorGroupDiscussionMessage;
    readonly mentorGroupDiscussionMessageReactionId: Scalars['UUID']['output'];
}

export interface UnreactProviderPostInput {
    readonly providerPostId: Scalars['UUID']['input'];
}

export interface UnreactProviderPostPayload {
    readonly __typename: 'UnreactProviderPostPayload';
    readonly newsfeedProviderPostReactionId: Scalars['UUID']['output'];
    readonly providerPost: ProviderPost;
}

export interface UnsaveStudyProgrammeInput {
    readonly studyProgrammeId: Scalars['UUID']['input'];
}

export interface UnsaveStudyProgrammePayload {
    readonly __typename: 'UnsaveStudyProgrammePayload';
    readonly studyProgramme: StudyProgramme;
}

export interface UnshareMentorStudentNoteInput {
    readonly mentorStudentNoteId: Scalars['UUID']['input'];
}

export interface UnshareMentorStudentNotePayload {
    readonly __typename: 'UnshareMentorStudentNotePayload';
    readonly mentorStudentNote: MentorStudentNote;
}

export interface UnshareMentorStudentTodoInput {
    readonly mentorStudentTodoId: Scalars['UUID']['input'];
}

export interface UnshareMentorStudentTodoPayload {
    readonly __typename: 'UnshareMentorStudentTodoPayload';
    readonly mentorStudentTodo: MentorStudentTodo;
}

export interface Updatable {
    readonly canUpdate: Scalars['Boolean']['output'];
}

export interface UpdateConnectedUserActivitySharingInput {
    readonly connectedUserId: Scalars['UUID']['input'];
    readonly sharerSharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
}

export interface UpdateConnectedUserActivitySharingPayload {
    readonly __typename: 'UpdateConnectedUserActivitySharingPayload';
    readonly connectedUser: ConnectedUser;
}

export interface UpdateCurriculumVitaeAchievementsAndAwardsEntryInput {
    readonly description: Scalars['String']['input'];
    readonly year: Scalars['Date']['input'];
}

export interface UpdateCurriculumVitaeAchievementsAndAwardsInput {
    readonly entries?: InputMaybe<ReadonlyArray<UpdateCurriculumVitaeAchievementsAndAwardsEntryInput>>;
}

export interface UpdateCurriculumVitaeBadgesInput {
    readonly selectedBadgeIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
}

export interface UpdateCurriculumVitaeEducationEntryInput {
    readonly school: Scalars['String']['input'];
    readonly subjects?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly title: Scalars['String']['input'];
    readonly year: Scalars['Date']['input'];
}

export interface UpdateCurriculumVitaeEducationInput {
    readonly entries?: InputMaybe<ReadonlyArray<UpdateCurriculumVitaeEducationEntryInput>>;
}

export interface UpdateCurriculumVitaeEmploymentEntryInput {
    readonly company: Scalars['String']['input'];
    readonly description?: InputMaybe<Scalars['String']['input']>;
    readonly end?: InputMaybe<Scalars['Date']['input']>;
    readonly start: Scalars['Date']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateCurriculumVitaeEmploymentInput {
    readonly entries?: InputMaybe<ReadonlyArray<UpdateCurriculumVitaeEmploymentEntryInput>>;
}

export interface UpdateCurriculumVitaeExtracurricularActivitiesEntryInput {
    readonly description: Scalars['String']['input'];
    readonly toYear?: InputMaybe<Scalars['Date']['input']>;
    readonly year: Scalars['Date']['input'];
}

export interface UpdateCurriculumVitaeExtracurricularActivitiesInput {
    readonly entries?: InputMaybe<ReadonlyArray<UpdateCurriculumVitaeExtracurricularActivitiesEntryInput>>;
}

export interface UpdateCurriculumVitaeInput {
    readonly achievementsAndAwards?: InputMaybe<UpdateCurriculumVitaeAchievementsAndAwardsInput>;
    readonly badges?: InputMaybe<UpdateCurriculumVitaeBadgesInput>;
    readonly education?: InputMaybe<UpdateCurriculumVitaeEducationInput>;
    readonly employment?: InputMaybe<UpdateCurriculumVitaeEmploymentInput>;
    readonly extracurricularActivities?: InputMaybe<UpdateCurriculumVitaeExtracurricularActivitiesInput>;
    readonly id?: InputMaybe<Scalars['UUID']['input']>;
    readonly personalAttributes?: InputMaybe<UpdateCurriculumVitaePersonalAttributesInput>;
    readonly personalDetails?: InputMaybe<UpdateCurriculumVitaePersonalDetailsInput>;
    readonly personalStatement?: InputMaybe<UpdateCurriculumVitaePersonalStatementInput>;
    readonly profileImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly references?: InputMaybe<UpdateCurriculumVitaeReferencesInput>;
    readonly resourceHubItems?: InputMaybe<UpdateCurriculumVitaeResourceHubItemsInput>;
    readonly skills?: InputMaybe<UpdateCurriculumVitaeSkillsInput>;
    readonly volunteer?: InputMaybe<UpdateCurriculumVitaeVolunteerInput>;
}

export interface UpdateCurriculumVitaePayload {
    readonly __typename: 'UpdateCurriculumVitaePayload';
    readonly curriculumVitae: CurriculumVitae;
}

export interface UpdateCurriculumVitaePersonalAttributesInput {
    readonly attributes: ReadonlyArray<CurriculumVitaePersonalAttribute>;
}

export interface UpdateCurriculumVitaePersonalDetailsInput {
    readonly city?: InputMaybe<Scalars['String']['input']>;
    readonly countryCode?: InputMaybe<Scalars['String']['input']>;
    readonly dob?: InputMaybe<Scalars['Date']['input']>;
    readonly driversLicenceType?: InputMaybe<DriversLicenceType>;
    readonly email?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly familyName?: InputMaybe<Scalars['String']['input']>;
    readonly givenName?: InputMaybe<Scalars['String']['input']>;
    readonly interests?: InputMaybe<Scalars['String']['input']>;
    readonly languageLocales?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
    readonly street?: InputMaybe<Scalars['String']['input']>;
    readonly suburb?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateCurriculumVitaePersonalStatementInput {
    readonly description: Scalars['String']['input'];
}

export interface UpdateCurriculumVitaeReferenceEntryInput {
    readonly company: Scalars['String']['input'];
    readonly email: Scalars['String']['input'];
    readonly name: Scalars['String']['input'];
    readonly phoneNumber: Scalars['String']['input'];
    readonly position: Scalars['String']['input'];
}

export interface UpdateCurriculumVitaeReferencesInput {
    readonly entries?: InputMaybe<ReadonlyArray<UpdateCurriculumVitaeReferenceEntryInput>>;
}

export interface UpdateCurriculumVitaeResourceHubItemsInput {
    readonly selectedResourceHubItemIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
}

export interface UpdateCurriculumVitaeSkillsInput {
    readonly description: Scalars['String']['input'];
}

export interface UpdateCurriculumVitaeVolunteerEntryInput {
    readonly company: Scalars['String']['input'];
    readonly description?: InputMaybe<Scalars['String']['input']>;
    readonly end?: InputMaybe<Scalars['Date']['input']>;
    readonly start: Scalars['Date']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateCurriculumVitaeVolunteerInput {
    readonly entries?: InputMaybe<ReadonlyArray<UpdateCurriculumVitaeVolunteerEntryInput>>;
}

export interface UpdateGoalInput {
    readonly color?: InputMaybe<Scalars['HexColorCode']['input']>;
    readonly complete?: InputMaybe<Scalars['Boolean']['input']>;
    readonly dueAt?: InputMaybe<Scalars['DateTime']['input']>;
    readonly goalType?: InputMaybe<GoalType>;
    readonly id: Scalars['UUID']['input'];
    readonly imageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly information?: InputMaybe<Scalars['String']['input']>;
    readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateGoalNoteInput {
    readonly id: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
}

export interface UpdateGoalNotePayload {
    readonly __typename: 'UpdateGoalNotePayload';
    readonly goalNote: GoalNote;
}

export interface UpdateGoalPayload {
    readonly __typename: 'UpdateGoalPayload';
    readonly goal: Goal;
}

export interface UpdateGoalTaskInput {
    readonly complete: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
}

export interface UpdateGoalTaskPayload {
    readonly __typename: 'UpdateGoalTaskPayload';
    readonly goalTask: GoalTask;
}

export interface UpdateJobProfileInput {
    readonly id: Scalars['UUID']['input'];
    readonly preferredJobAvailability?: InputMaybe<Scalars['String']['input']>;
    readonly preferredJobCategories?: InputMaybe<ReadonlyArray<JobCategory>>;
    readonly preferredJobGeoLocationIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']>>;
    readonly preferredJobTypes?: InputMaybe<ReadonlyArray<JobType>>;
}

export interface UpdateJobProfilePayload {
    readonly __typename: 'UpdateJobProfilePayload';
    readonly jobProfile: JobProfile;
}

export interface UpdateLessonInput {
    readonly countryCodes: ReadonlyArray<Scalars['String']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly lessonCategoryId: Scalars['UUID']['input'];
}

export interface UpdateLessonLocalizationInput {
    readonly coverImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly description: Scalars['String']['input'];
    readonly documentId: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly locale: Scalars['Locale']['input'];
    readonly tags: ReadonlyArray<Scalars['String']['input']>;
    readonly title: Scalars['String']['input'];
}

export interface UpdateLessonLocalizationPayload {
    readonly __typename: 'UpdateLessonLocalizationPayload';
    readonly lesson: Lesson;
}

export interface UpdateLessonPayload {
    readonly __typename: 'UpdateLessonPayload';
    readonly lesson: Lesson;
}

export interface UpdateMentorGroupBannerImageInput {
    readonly bannerImageId: Scalars['UUID']['input'];
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface UpdateMentorGroupBannerImagePayload {
    readonly __typename: 'UpdateMentorGroupBannerImagePayload';
    readonly mentorGroup: MentorGroup;
}

export interface UpdateMentorGroupDiscussionLastReadInput {
    readonly lastRead: Scalars['DateTime']['input'];
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface UpdateMentorGroupDiscussionLastReadPayload {
    readonly __typename: 'UpdateMentorGroupDiscussionLastReadPayload';
    readonly mentorGroupDiscussionUserState: MentorGroupDiscussionUserState;
}

export interface UpdateMentorGroupDiscussionLastTypingInput {
    readonly lastTyping: Scalars['DateTime']['input'];
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface UpdateMentorGroupDiscussionLastTypingPayload {
    readonly __typename: 'UpdateMentorGroupDiscussionLastTypingPayload';
    readonly mentorGroupDiscussionUserState: MentorGroupDiscussionUserState;
}

export interface UpdateMentorGroupInformationInput {
    readonly information: Scalars['String']['input'];
    readonly mentorGroupId: Scalars['UUID']['input'];
}

export interface UpdateMentorGroupInformationPayload {
    readonly __typename: 'UpdateMentorGroupInformationPayload';
    readonly mentorGroup: MentorGroup;
}

export interface UpdateMentorGroupInput {
    readonly description?: InputMaybe<Scalars['String']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateMentorGroupPayload {
    readonly __typename: 'UpdateMentorGroupPayload';
    readonly mentorGroup: MentorGroup;
}

export interface UpdateMentorRewardItemInput {
    readonly description: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly imageId: Scalars['UUID']['input'];
    readonly points: Scalars['Int']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateMentorRewardItemPayload {
    readonly __typename: 'UpdateMentorRewardItemPayload';
    readonly mentorRewardItem: MentorRewardItem;
}

export interface UpdateMentorRewardValueInput {
    readonly color: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
    readonly worth: Scalars['Int']['input'];
}

export interface UpdateMentorRewardValuePayload {
    readonly __typename: 'UpdateMentorRewardValuePayload';
    readonly mentorRewardValue: MentorRewardValue;
}

export interface UpdateMentorRewardViewedInput {
    readonly mentorRewardStudentId: Scalars['UUID']['input'];
}

export interface UpdateMentorRewardViewedPayload {
    readonly __typename: 'UpdateMentorRewardViewedPayload';
    readonly viewedCount: Scalars['Int']['output'];
}

export interface UpdateMentorSharedResourceInput {
    readonly id: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
    readonly sharedResourceType: MentorSharedResourceType;
}

export interface UpdateMentorSharedResourcePayload {
    readonly __typename: 'UpdateMentorSharedResourcePayload';
    readonly mentorSharedResource: MentorSharedResource;
}

export interface UpdateMentorStudentNoteInput {
    readonly id: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
}

export interface UpdateMentorStudentNotePayload {
    readonly __typename: 'UpdateMentorStudentNotePayload';
    readonly mentorStudentNote: MentorStudentNote;
}

export interface UpdateMentorStudentNoteViewedInput {
    readonly mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface UpdateMentorStudentNoteViewedPayload {
    readonly __typename: 'UpdateMentorStudentNoteViewedPayload';
    readonly viewedCount: Scalars['Int']['output'];
}

export interface UpdateMentorStudentSharedActivityInput {
    readonly isSharingOtherProviderActivity: Scalars['Boolean']['input'];
    readonly mentorStudentId: Scalars['UUID']['input'];
    readonly sharedActivity: ReadonlyArray<MentorStudentSharedActivityType>;
}

export interface UpdateMentorStudentSharedActivityPayload {
    readonly __typename: 'UpdateMentorStudentSharedActivityPayload';
    readonly mentorStudent: MentorStudent;
}

export interface UpdateMentorStudentTodoInput {
    readonly complete: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
}

export interface UpdateMentorStudentTodoPayload {
    readonly __typename: 'UpdateMentorStudentTodoPayload';
    readonly mentorStudentTodo: MentorStudentTodo;
}

export interface UpdateMentorStudentTodoViewedInput {
    readonly mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    readonly mentorStudentId: Scalars['UUID']['input'];
}

export interface UpdateMentorStudentTodoViewedPayload {
    readonly __typename: 'UpdateMentorStudentTodoViewedPayload';
    readonly viewedCount: Scalars['Int']['output'];
}

export interface UpdateOpportunityListingInput {
    readonly company?: InputMaybe<Scalars['String']['input']>;
    readonly contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly contactPhone?: InputMaybe<Scalars['String']['input']>;
    readonly contactWebLink?: InputMaybe<Scalars['URL']['input']>;
    readonly country?: InputMaybe<Scalars['CountryCode']['input']>;
    readonly coverImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly geoLocationId?: InputMaybe<Scalars['UUID']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly isPinned?: InputMaybe<Scalars['Boolean']['input']>;
    readonly jobDescription?: InputMaybe<Scalars['String']['input']>;
    readonly listingRange?: InputMaybe<OpportunityListingRange>;
    readonly payRateDescription?: InputMaybe<Scalars['Float']['input']>;
    readonly referenceCode?: InputMaybe<Scalars['String']['input']>;
    readonly title?: InputMaybe<Scalars['String']['input']>;
    readonly workingHoursDescription?: InputMaybe<Scalars['Float']['input']>;
}

export interface UpdateOpportunityListingPayload {
    readonly __typename: 'UpdateOpportunityListingPayload';
    readonly opportunityListing: OpportunityListing;
}

export interface UpdatePathwayPlannerInput {
    readonly id: Scalars['UUID']['input'];
    readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePathwayPlannerPayload {
    readonly __typename: 'UpdatePathwayPlannerPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerPersonalRequirementPayload {
    readonly __typename: 'UpdatePathwayPlannerPersonalRequirementPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerPersonalRequirementTodoInput {
    readonly completed: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdatePathwayPlannerQualificationPayload {
    readonly __typename: 'UpdatePathwayPlannerQualificationPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerQualificationTodoInput {
    readonly completed: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdatePathwayPlannerSkillAndKnowledgePayload {
    readonly __typename: 'UpdatePathwayPlannerSkillAndKnowledgePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerSkillAndKnowledgeTodoInput {
    readonly completed: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdatePathwayPlannerStageInput {
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdatePathwayPlannerStagePayload {
    readonly __typename: 'UpdatePathwayPlannerStagePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerStageSubjectPayload {
    readonly __typename: 'UpdatePathwayPlannerStageSubjectPayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerStageSubjectTodoInput {
    readonly completed: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly pathwayPlannerStageId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdatePathwayPlannerStageUsefulExperiencePayload {
    readonly __typename: 'UpdatePathwayPlannerStageUsefulExperiencePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerStageUsefulExperienceTodoInput {
    readonly completed: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly pathwayPlannerStageId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdatePathwayPlannerUsefulExperiencePayload {
    readonly __typename: 'UpdatePathwayPlannerUsefulExperiencePayload';
    readonly pathwayPlanner: PathwayPlanner;
}

export interface UpdatePathwayPlannerUsefulExperienceTodoInput {
    readonly completed: Scalars['Boolean']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly pathwayPlannerId: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateProjectMentorGroupInput {
    readonly mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    readonly projectId: Scalars['UUID']['input'];
}

export interface UpdateProjectMentorGroupPayload {
    readonly __typename: 'UpdateProjectMentorGroupPayload';
    readonly project: Project;
}

export interface UpdateProjectSectionCommentInput {
    readonly comment: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
}

export interface UpdateProjectSectionCommentPayload {
    readonly __typename: 'UpdateProjectSectionCommentPayload';
    readonly projectSectionComment: ProjectSectionComment;
}

export interface UpdateProjectSectionEvidenceInput {
    readonly description: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
}

export interface UpdateProjectSectionEvidencePayload {
    readonly __typename: 'UpdateProjectSectionEvidencePayload';
    readonly projectSectionEvidence?: Maybe<ProjectSectionEvidence>;
}

export interface UpdateProjectSectionResponseInput {
    readonly id: Scalars['UUID']['input'];
    readonly response: Scalars['String']['input'];
}

export interface UpdateProjectSectionResponsePayload {
    readonly __typename: 'UpdateProjectSectionResponsePayload';
    readonly projectSection: ProjectSection;
}

export interface UpdateProviderDetailsInput {
    readonly bannerImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly countryCode?: InputMaybe<Scalars['String']['input']>;
    readonly internalContactDetails?: InputMaybe<UpdateProviderDetailsInternalContactDetailsInput>;
    readonly logoImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly providerId: Scalars['UUID']['input'];
    readonly providerType?: InputMaybe<ProviderType>;
    readonly technicalContactDetails?: InputMaybe<UpdateProviderDetailsTechnicalContactDetailsInput>;
    readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProviderDetailsInternalContactDetailsInput {
    readonly email?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly fullName?: InputMaybe<Scalars['String']['input']>;
    readonly phone?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProviderDetailsPayload {
    readonly __typename: 'UpdateProviderDetailsPayload';
    readonly provider: Provider;
}

export interface UpdateProviderDetailsTechnicalContactDetailsInput {
    readonly email?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly fullName?: InputMaybe<Scalars['String']['input']>;
    readonly phone?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProviderIntegrationKamarTimetableDisabledUntilInput {
    readonly disabledUntil?: InputMaybe<Scalars['Date']['input']>;
    readonly id: Scalars['UUID']['input'];
}

export interface UpdateProviderIntegrationKamarTimetableDisabledUntilPayload {
    readonly __typename: 'UpdateProviderIntegrationKamarTimetableDisabledUntilPayload';
    readonly providerIntegration: ProviderIntegrationKamar;
}

export interface UpdateProviderIntegrationManualSmsDataEmployeesInput {
    readonly employees: ReadonlyArray<UpdateProviderIntegrationManualSmsDataEmployeesInputEmployeeDetails>;
    readonly id: Scalars['UUID']['input'];
}

export interface UpdateProviderIntegrationManualSmsDataEmployeesInputEmployeeDetails {
    readonly barcodeNumber?: InputMaybe<Scalars['String']['input']>;
    readonly dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
    readonly email: Scalars['EmailAddress']['input'];
    readonly employmentEndDate?: InputMaybe<Scalars['Date']['input']>;
    readonly employmentStartDate?: InputMaybe<Scalars['Date']['input']>;
    readonly familyName?: InputMaybe<Scalars['String']['input']>;
    readonly givenName: Scalars['String']['input'];
    readonly preferredFamilyName?: InputMaybe<Scalars['String']['input']>;
    readonly preferredGivenName?: InputMaybe<Scalars['String']['input']>;
    readonly staffCode: Scalars['String']['input'];
    readonly teacherNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProviderIntegrationManualSmsDataEmployeesPayload {
    readonly __typename: 'UpdateProviderIntegrationManualSmsDataEmployeesPayload';
    readonly providerIntegration: ProviderIntegrationManualSmsData;
}

export interface UpdateProviderIntegrationManualSmsDataGroupEmployeesInput {
    readonly groupEmployees: ReadonlyArray<UpdateProviderIntegrationManualSmsDataGroupEmployeesInputGroupEmployeeDetails>;
    readonly id: Scalars['UUID']['input'];
}

export interface UpdateProviderIntegrationManualSmsDataGroupEmployeesInputGroupEmployeeDetails {
    readonly employeeStaffCode: Scalars['String']['input'];
    readonly groupCode: Scalars['String']['input'];
}

export interface UpdateProviderIntegrationManualSmsDataGroupEmployeesPayload {
    readonly __typename: 'UpdateProviderIntegrationManualSmsDataGroupEmployeesPayload';
    readonly providerIntegration: ProviderIntegrationManualSmsData;
}

export interface UpdateProviderIntegrationManualSmsDataGroupStudentsInput {
    readonly groupStudents: ReadonlyArray<UpdateProviderIntegrationManualSmsDataGroupStudentsInputGroupStudentDetails>;
    readonly id: Scalars['UUID']['input'];
}

export interface UpdateProviderIntegrationManualSmsDataGroupStudentsInputGroupStudentDetails {
    readonly groupCode: Scalars['String']['input'];
    readonly studentIdNumber: Scalars['String']['input'];
}

export interface UpdateProviderIntegrationManualSmsDataGroupStudentsPayload {
    readonly __typename: 'UpdateProviderIntegrationManualSmsDataGroupStudentsPayload';
    readonly providerIntegration: ProviderIntegrationManualSmsData;
}

export interface UpdateProviderIntegrationManualSmsDataGroupsInput {
    readonly groups: ReadonlyArray<UpdateProviderIntegrationManualSmsDataGroupsInputGroupDetails>;
    readonly id: Scalars['UUID']['input'];
}

export interface UpdateProviderIntegrationManualSmsDataGroupsInputGroupDetails {
    readonly code: Scalars['String']['input'];
    readonly name: Scalars['String']['input'];
    readonly type: LearnerSmsGroupType;
}

export interface UpdateProviderIntegrationManualSmsDataGroupsPayload {
    readonly __typename: 'UpdateProviderIntegrationManualSmsDataGroupsPayload';
    readonly providerIntegration: ProviderIntegrationManualSmsData;
}

export interface UpdateProviderIntegrationManualSmsDataStudentsInput {
    readonly id: Scalars['UUID']['input'];
    readonly students: ReadonlyArray<UpdateProviderIntegrationManualSmsDataStudentsInputStudentDetails>;
}

export interface UpdateProviderIntegrationManualSmsDataStudentsInputStudentDetails {
    readonly admissionDate?: InputMaybe<Scalars['Date']['input']>;
    readonly barcodeNumber?: InputMaybe<Scalars['String']['input']>;
    readonly dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
    readonly email: Scalars['EmailAddress']['input'];
    readonly familyName?: InputMaybe<Scalars['String']['input']>;
    readonly givenName: Scalars['String']['input'];
    readonly idNumber: Scalars['String']['input'];
    readonly leavingDate?: InputMaybe<Scalars['Date']['input']>;
    readonly nationalCurriculumYear?: InputMaybe<Scalars['String']['input']>;
    readonly preferredFamilyName?: InputMaybe<Scalars['String']['input']>;
    readonly preferredGivenName?: InputMaybe<Scalars['String']['input']>;
    readonly uniqueStudentNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProviderIntegrationManualSmsDataStudentsPayload {
    readonly __typename: 'UpdateProviderIntegrationManualSmsDataStudentsPayload';
    readonly providerIntegration: ProviderIntegrationManualSmsData;
}

export interface UpdateProviderMemberRolesInput {
    readonly providerMemberId: Scalars['UUID']['input'];
    readonly roleIds: ReadonlyArray<Scalars['UUID']['input']>;
}

export interface UpdateProviderMemberRolesPayload {
    readonly __typename: 'UpdateProviderMemberRolesPayload';
    readonly member: ProviderMember;
}

export interface UpdateProviderMySchoolInformationInput {
    readonly id: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateProviderMySchoolInformationPayload {
    readonly __typename: 'UpdateProviderMySchoolInformationPayload';
    readonly providerMySchoolInformationItem: ProviderMySchoolInformationItem;
}

export interface UpdateProviderMySchoolInformationSequenceInput {
    readonly providerId: Scalars['UUID']['input'];
    readonly providerMySchoolInformationSorted: ReadonlyArray<ProviderMySchoolInformationSortInput>;
}

export interface UpdateProviderMySchoolInformationSequencePayload {
    readonly __typename: 'UpdateProviderMySchoolInformationSequencePayload';
    readonly providerMySchoolInformationItems: ReadonlyArray<ProviderMySchoolInformationItem>;
}

export interface UpdateProviderPostInput {
    readonly content: Scalars['String']['input'];
    readonly countryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly id: Scalars['UUID']['input'];
    readonly isPinned?: InputMaybe<Scalars['Boolean']['input']>;
    readonly mentorOnly?: InputMaybe<Scalars['Boolean']['input']>;
    readonly mentorStudentOnly?: InputMaybe<Scalars['Boolean']['input']>;
    readonly pollExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
    readonly pollOptions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface UpdateProviderPostPayload {
    readonly __typename: 'UpdateProviderPostPayload';
    readonly providerPost: ProviderPost;
}

export interface UpdateProviderProjectTemplateBannerImageInput {
    readonly bannerImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly providerProjectTemplateId: Scalars['UUID']['input'];
}

export interface UpdateProviderProjectTemplateBannerImagePayload {
    readonly __typename: 'UpdateProviderProjectTemplateBannerImagePayload';
    readonly providerProjectTemplate: ProviderProjectTemplate;
}

export interface UpdateProviderProjectTemplateInput {
    readonly id: Scalars['UUID']['input'];
    readonly overviewDescription?: InputMaybe<Scalars['String']['input']>;
    readonly title: Scalars['String']['input'];
}

export interface UpdateProviderProjectTemplateOverviewResourceInput {
    readonly id: Scalars['UUID']['input'];
    readonly url: Scalars['URL']['input'];
}

export interface UpdateProviderProjectTemplateOverviewResourcePayload {
    readonly __typename: 'UpdateProviderProjectTemplateOverviewResourcePayload';
    readonly providerProjectTemplateOverviewResource: ProviderProjectTemplateOverviewResource;
}

export interface UpdateProviderProjectTemplatePayload {
    readonly __typename: 'UpdateProviderProjectTemplatePayload';
    readonly providerProjectTemplate: ProviderProjectTemplate;
}

export interface UpdateProviderProjectTemplateSectionInput {
    readonly color: Scalars['HexColorCode']['input'];
    readonly description: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateProviderProjectTemplateSectionOrderInput {
    readonly providerProjectTemplateId: Scalars['UUID']['input'];
    readonly providerProjectTemplateSectionsSort: ReadonlyArray<UpdateProviderProjectTemplateSectionOrderInputSortInput>;
}

export interface UpdateProviderProjectTemplateSectionOrderInputSortInput {
    readonly id: Scalars['UUID']['input'];
    readonly sectionOrder: Scalars['Int']['input'];
}

export interface UpdateProviderProjectTemplateSectionOrderPayload {
    readonly __typename: 'UpdateProviderProjectTemplateSectionOrderPayload';
    readonly providerProjectTemplateSections: ReadonlyArray<ProviderProjectTemplateSection>;
}

export interface UpdateProviderProjectTemplateSectionPayload {
    readonly __typename: 'UpdateProviderProjectTemplateSectionPayload';
    readonly providerProjectTemplateSection: ProviderProjectTemplateSection;
}

export interface UpdateProviderProjectTemplateSectionResourceInput {
    readonly id: Scalars['UUID']['input'];
    readonly url: Scalars['URL']['input'];
}

export interface UpdateProviderProjectTemplateSectionResourcePayload {
    readonly __typename: 'UpdateProviderProjectTemplateSectionResourcePayload';
    readonly providerProjectTemplateSectionResource?: Maybe<ProviderProjectTemplateSectionResource>;
}

export interface UpdateReflectionInput {
    readonly id: Scalars['UUID']['input'];
    readonly information?: InputMaybe<Scalars['String']['input']>;
    readonly reflectionType?: InputMaybe<ReflectionType>;
}

export interface UpdateReflectionPayload {
    readonly __typename: 'UpdateReflectionPayload';
    readonly reflection: Reflection;
}

export interface UpdateResourceHubItemInput {
    readonly category: ResourceHubItemCategory;
    readonly id: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateResourceHubItemPayload {
    readonly __typename: 'UpdateResourceHubItemPayload';
    readonly resourceHubItem: ResourceHubItem;
}

export interface UpdateSponsorAssetInput {
    readonly content?: InputMaybe<Scalars['String']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly label?: InputMaybe<Scalars['String']['input']>;
    readonly targetUrl?: InputMaybe<Scalars['URL']['input']>;
    readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateSponsorAssetPayload {
    readonly __typename: 'UpdateSponsorAssetPayload';
    readonly sponsorAsset: SponsorAsset;
}

export interface UpdateSponsorCampaignAssetInput {
    readonly endDate?: InputMaybe<Scalars['Date']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly newsfeedPostIndex?: InputMaybe<Scalars['NonNegativeInt']['input']>;
    readonly startDate?: InputMaybe<Scalars['Date']['input']>;
    readonly targetUser?: InputMaybe<SponsorTargetUserRole>;
}

export interface UpdateSponsorCampaignAssetPayload {
    readonly __typename: 'UpdateSponsorCampaignAssetPayload';
    readonly sponsorCampaignAsset: SponsorCampaignAsset;
}

export interface UpdateSponsorCampaignInput {
    readonly endDate?: InputMaybe<Scalars['Date']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly startDate?: InputMaybe<Scalars['Date']['input']>;
    readonly targetCountries?: InputMaybe<ReadonlyArray<Scalars['CountryCode']['input']>>;
    readonly timezone?: InputMaybe<Scalars['TimeZone']['input']>;
}

export interface UpdateSponsorCampaignPayload {
    readonly __typename: 'UpdateSponsorCampaignPayload';
    readonly sponsorCampaign: SponsorCampaign;
}

export interface UpdateSponsorProviderInput {
    readonly id: Scalars['UUID']['input'];
    readonly logoImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateSponsorProviderPayload {
    readonly __typename: 'UpdateSponsorProviderPayload';
    readonly sponsorProvider: SponsorProvider;
}

export interface UpdateStudentHomeworkDiaryItemInput {
    readonly color?: InputMaybe<Scalars['HexColorCode']['input']>;
    readonly date?: InputMaybe<Scalars['Date']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly information?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateStudentHomeworkDiaryItemPayload {
    readonly __typename: 'UpdateStudentHomeworkDiaryItemPayload';
    readonly studentHomeworkDiaryItem: StudentHomeworkDiaryItem;
}

export interface UpdateStudentHomeworkDiaryMentorTaskInput {
    readonly color?: InputMaybe<Scalars['HexColorCode']['input']>;
    readonly date?: InputMaybe<Scalars['Date']['input']>;
    readonly id: Scalars['UUID']['input'];
    readonly information?: InputMaybe<Scalars['String']['input']>;
    readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateStudentHomeworkDiaryMentorTaskPayload {
    readonly __typename: 'UpdateStudentHomeworkDiaryMentorTaskPayload';
    readonly studentHomeworkDiaryMentorTask: StudentHomeworkDiaryMentorTask;
}

export interface UpdateStudyCategoryInput {
    readonly id: Scalars['UUID']['input'];
    readonly title: Scalars['String']['input'];
}

export interface UpdateStudyCategoryPayload {
    readonly __typename: 'UpdateStudyCategoryPayload';
    readonly studyCategory: StudyCategory;
}

export interface UpdateStudyProgrammeInput {
    readonly id: Scalars['UUID']['input'];
    readonly information: Scalars['String']['input'];
    readonly link: Scalars['URL']['input'];
    readonly studyCategoryId: Scalars['UUID']['input'];
    readonly studyCountryCodes: ReadonlyArray<Scalars['String']['input']>;
    readonly studyProgrammeType: StudyProgrammeType;
    readonly title: Scalars['String']['input'];
}

export interface UpdateStudyProgrammePayload {
    readonly __typename: 'UpdateStudyProgrammePayload';
    readonly studyProgramme: StudyProgramme;
}

export interface UpdateStudyProviderInput {
    readonly countryCode: Scalars['String']['input'];
    readonly email?: InputMaybe<Scalars['EmailAddress']['input']>;
    readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
    readonly id: Scalars['UUID']['input'];
    readonly isEnquiriesEnabled: Scalars['Boolean']['input'];
    readonly isEnrolmentEnabled: Scalars['Boolean']['input'];
    readonly logoImageId?: InputMaybe<Scalars['UUID']['input']>;
    readonly title: Scalars['String']['input'];
}

export interface UpdateStudyProviderPayload {
    readonly __typename: 'UpdateStudyProviderPayload';
    readonly studyProvider: StudyProvider;
}

export interface UpdateThreeGoodThingsInput {
    readonly firstItem: Scalars['String']['input'];
    readonly id: Scalars['UUID']['input'];
    readonly secondItem?: InputMaybe<Scalars['String']['input']>;
    readonly thirdItem?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateThreeGoodThingsPayload {
    readonly __typename: 'UpdateThreeGoodThingsPayload';
    readonly threeGoodThings: ThreeGoodThings;
}

export interface UpdateUserEmailAddressInput {
    readonly email: Scalars['EmailAddress']['input'];
}

export interface UpdateUserEmailAddressPayload {
    readonly __typename: 'UpdateUserEmailAddressPayload';
    readonly user: UserData;
}

export interface UpdateUserEmailNotificationsSettingInput {
    readonly isConnectionNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    readonly isDailySummaryNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    readonly isDiscussionNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    readonly isProjectNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface UpdateUserEmailNotificationsSettingPayload {
    readonly __typename: 'UpdateUserEmailNotificationsSettingPayload';
    readonly userEmailNotificationsSetting: UserEmailNotificationsSetting;
}

export interface UpdateUserInput {
    readonly ageGroup?: InputMaybe<UserAgeGroup>;
    readonly countryCode?: InputMaybe<Scalars['String']['input']>;
    readonly familyName?: InputMaybe<Scalars['String']['input']>;
    readonly geoRegionId?: InputMaybe<Scalars['UUID']['input']>;
    readonly givenName?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserPayload {
    readonly __typename: 'UpdateUserPayload';
    readonly user: UserData;
}

export interface UpdateUserPictureInput {
    readonly fileId: Scalars['UUID']['input'];
}

export interface UpdateUserPicturePayload {
    readonly __typename: 'UpdateUserPicturePayload';
    readonly user: UserData;
}

export interface User {
    readonly __typename: 'User';
    readonly educationProvider?: Maybe<Provider>;
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly fullName: Scalars['String']['output'];
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly pictureUrl?: Maybe<Scalars['URL']['output']>;
}

export enum UserAgeGroup {
    EIGHTEEN_TO_TWENTY = 'EIGHTEEN_TO_TWENTY',
    SIXTEEN_TO_SEVENTEEN = 'SIXTEEN_TO_SEVENTEEN',
    THIRTEEN_TO_FIFTEEN = 'THIRTEEN_TO_FIFTEEN',
    TWENTYFIVE_AND_OVER = 'TWENTYFIVE_AND_OVER',
    TWENTYONE_TO_TWENTYFOUR = 'TWENTYONE_TO_TWENTYFOUR',
    UNDER_THIRTEEN = 'UNDER_THIRTEEN'
}

export interface UserConnectionRequest {
    readonly __typename: 'UserConnectionRequest';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly isLinkShare: Scalars['Boolean']['output'];
    readonly requestCode: Scalars['String']['output'];
    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
    readonly shareeEmail?: Maybe<Scalars['EmailAddress']['output']>;
    readonly sharer: User;
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface UserConnectionRequestConnection {
    readonly __typename: 'UserConnectionRequestConnection';
    readonly edges: ReadonlyArray<UserConnectionRequestEdge>;
    readonly nodes: ReadonlyArray<Maybe<UserConnectionRequest>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface UserConnectionRequestEdge {
    readonly __typename: 'UserConnectionRequestEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<UserConnectionRequest>;
}

export interface UserData {
    readonly __typename: 'UserData';
    readonly ageGroup?: Maybe<UserAgeGroup>;
    readonly availableMentorProviders: ProviderConnection;
    readonly badges: BadgeConnection;
    readonly calendars: ReadonlyArray<Calendar>;
    readonly canAccessLessons: Scalars['Boolean']['output'];
    readonly canAccessStudentHomeworkDiary: Scalars['Boolean']['output'];
    readonly careersRiasecResult?: Maybe<CareersRiasecResult>;
    readonly connectedMentorProviders: ReadonlyArray<MentorStudent>;
    readonly connectedUsers: ConnectedUserConnection;
    readonly connectionRequests: UserConnectionRequestConnection;
    readonly connectionRequestsSent: UserConnectionRequestConnection;
    readonly countryCode: Scalars['String']['output'];
    readonly currentLearnerSmsProfile?: Maybe<LearnerSmsProfile>;
    readonly currentMySchoolProvider?: Maybe<MentorStudent>;
    readonly curriculumVitae?: Maybe<CurriculumVitae>;
    readonly dateOfBirth?: Maybe<Scalars['Date']['output']>;
    readonly email: Scalars['EmailAddress']['output'];
    readonly emailNotificationsSetting: UserEmailNotificationsSetting;
    readonly emailStatus: UserEmailStatus;
    readonly extraMenuNavigationItems: ReadonlyArray<NavigationItem>;
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly favoriteLessons: LessonConnection;
    readonly fullName: Scalars['String']['output'];
    readonly geoRegion?: Maybe<GeoLocation>;
    readonly givenName: Scalars['String']['output'];
    readonly goals: GoalConnection;
    readonly id: Scalars['UUID']['output'];
    readonly jobProfile?: Maybe<JobProfile>;
    readonly learnerSmsProfiles: LearnerSmsProfileConnection;
    readonly loginIdentities: ReadonlyArray<UserLoginIdentity>;
    readonly menuNavigationItems: ReadonlyArray<NavigationItem>;
    readonly pathwayPlanners: PathwayPlannerConnection;
    readonly pictureUrl?: Maybe<Scalars['URL']['output']>;
    readonly preferredWalletCard?: Maybe<WalletCard>;
    readonly projects: ProjectConnection;
    readonly providersWithMembership: ReadonlyArray<Provider>;
    readonly reflections: ReflectionConnection;
    readonly resourceHubItems: ResourceHubItemConnection;
    readonly savedStudyProgrammes: SavedStudyProgrammeConnection;
    readonly studentHomeworkDiaryDailyItems: StudentHomeworkDiaryConnection;
    readonly studentHomeworkDiaryMonthSummary: StudentHomeworkDiaryMonthSummary;
    readonly userType: UserType;
    readonly verifiedIdentity?: Maybe<UserVerifiedIdentity>;
    readonly walletCards: WalletCardConnection;
}

export interface UserDataAvailableMentorProvidersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataBadgesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataCalendarsArgs {
    calendarId?: InputMaybe<Scalars['UUID']['input']>;
}

export interface UserDataConnectedUsersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface UserDataConnectionRequestsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataConnectionRequestsSentArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataFavoriteLessonsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataGoalsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<GoalType>>;
}

export interface UserDataLearnerSmsProfilesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataPathwayPlannersArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}

export interface UserDataProjectsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataReflectionsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<ReflectionType>>;
}

export interface UserDataResourceHubItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    mimeTypes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface UserDataSavedStudyProgrammesArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataStudentHomeworkDiaryDailyItemsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}

export interface UserDataStudentHomeworkDiaryMonthSummaryArgs {
    date: Scalars['Date']['input'];
}

export interface UserDataWalletCardsArgs {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    types?: InputMaybe<ReadonlyArray<WalletCardType>>;
}

export interface UserEmailNotificationsSetting {
    readonly __typename: 'UserEmailNotificationsSetting';
    readonly isConnectionNotificationsEnabled: Scalars['Boolean']['output'];
    readonly isDailySummaryNotificationsEnabled: Scalars['Boolean']['output'];
    readonly isDiscussionNotificationsEnabled: Scalars['Boolean']['output'];
    readonly isProjectNotificationsEnabled: Scalars['Boolean']['output'];
}

export interface UserEmailStatus {
    readonly __typename: 'UserEmailStatus';
    readonly hasBouncedEmail?: Maybe<Scalars['Boolean']['output']>;
    readonly hasMarkedAsSpamEmail?: Maybe<Scalars['Boolean']['output']>;
    readonly hasPersonalEmail?: Maybe<Scalars['Boolean']['output']>;
}

export interface UserIdentity {
    readonly __typename: 'UserIdentity';
    readonly connection: Scalars['String']['output'];
    readonly email: Scalars['EmailAddress']['output'];
    readonly federatedId: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
}

export interface UserLoginIdentity {
    readonly __typename: 'UserLoginIdentity';
    readonly connection: Scalars['String']['output'];
    readonly email: Scalars['EmailAddress']['output'];
    readonly federatedId: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
}

export enum UserType {
    STANDARD = 'STANDARD',
    SUPERADMIN = 'SUPERADMIN'
}

export enum UserVerificationType {
    FULL = 'FULL',
    LEARNER = 'LEARNER'
}

export interface UserVerifiedIdentity {
    readonly __typename: 'UserVerifiedIdentity';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly dateOfBirth: Scalars['Date']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly familyName: Scalars['String']['output'];
    readonly fullName: Scalars['String']['output'];
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly realAmlSignatureKey: Scalars['String']['output'];
    readonly realAmlVerificationType: Scalars['String']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ValidateMentorStudentInviteCodeInput {
    readonly code: Scalars['String']['input'];
}

export interface ValidateMentorStudentInviteCodePayload {
    readonly __typename: 'ValidateMentorStudentInviteCodePayload';
    readonly mentorGroupTitles: ReadonlyArray<Scalars['String']['output']>;
    readonly provider: Provider;
}

export interface ValidateUserConnectionRequestInput {
    readonly requestCode: Scalars['String']['input'];
}

export interface ValidateUserConnectionRequestPayload {
    readonly __typename: 'ValidateUserConnectionRequestPayload';
    readonly userConnectionRequest: UserConnectionRequest;
}

export interface VerifiedIdVerifiableCredential extends WalletCard {
    readonly __typename: 'VerifiedIdVerifiableCredential';
    readonly address?: Maybe<VerifiedIdVerifiableCredentialAddress>;
    readonly age: Scalars['Int']['output'];
    readonly ageOver13: Scalars['Boolean']['output'];
    readonly ageOver16: Scalars['Boolean']['output'];
    readonly ageOver18: Scalars['Boolean']['output'];
    readonly ageOver21: Scalars['Boolean']['output'];
    readonly birthDate: Scalars['Date']['output'];
    readonly countryCode: Scalars['CountryCode']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly expiresAt: Scalars['DateTime']['output'];
    readonly familyName?: Maybe<Scalars['String']['output']>;
    readonly givenName: Scalars['String']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly pictureUrl: Scalars['URL']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly verificationSources: ReadonlyArray<Scalars['String']['output']>;
    readonly walletCardType: WalletCardType;
}

export interface VerifiedIdVerifiableCredentialAddress {
    readonly __typename: 'VerifiedIdVerifiableCredentialAddress';
    readonly country: Scalars['String']['output'];
    readonly locality?: Maybe<Scalars['String']['output']>;
    readonly postalCode?: Maybe<Scalars['String']['output']>;
    readonly region?: Maybe<Scalars['String']['output']>;
    readonly streetAddress?: Maybe<Scalars['String']['output']>;
}

export interface WalletCard {
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['UUID']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly walletCardType: WalletCardType;
}

export interface WalletCardConnection {
    readonly __typename: 'WalletCardConnection';
    readonly edges: ReadonlyArray<WalletCardEdge>;
    readonly nodes: ReadonlyArray<Maybe<WalletCard>>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int']['output'];
}

export interface WalletCardEdge {
    readonly __typename: 'WalletCardEdge';
    readonly cursor: Scalars['String']['output'];
    readonly node?: Maybe<WalletCard>;
}

export interface WalletCardPresentationSimpleGroup {
    readonly __typename: 'WalletCardPresentationSimpleGroup';
    readonly combinations: ReadonlyArray<ReadonlyArray<Scalars['String']['output']>>;
    readonly groupIndex: Scalars['Int']['output'];
    readonly purpose?: Maybe<Scalars['String']['output']>;
    readonly required: Scalars['Boolean']['output'];
}

export interface WalletCardPresentationSimpleMatch {
    readonly __typename: 'WalletCardPresentationSimpleMatch';
    readonly credentialId: Scalars['String']['output'];
    readonly requestedClaims: ReadonlyArray<Scalars['String']['output']>;
    readonly walletCard: WalletCard;
}

export interface WalletCardPresentationSimpleMatches {
    readonly __typename: 'WalletCardPresentationSimpleMatches';
    readonly groups: ReadonlyArray<WalletCardPresentationSimpleGroup>;
    readonly matches: ReadonlyArray<WalletCardPresentationSimpleMatch>;
}

export enum WalletCardType {
    /** @deprecated Permanently deprecated in favor of VERIFIED_ID_VC */
    BASIC_VERIFIED_ID = 'BASIC_VERIFIED_ID',
    LEARNER_ID_VC = 'LEARNER_ID_VC',
    LOYALTY_VC = 'LOYALTY_VC',
    /** @deprecated Permanently deprecated in favor of LEARNER_ID_VC */
    NZ_STUDENT_ID = 'NZ_STUDENT_ID',
    VERIFIED_ID_VC = 'VERIFIED_ID_VC'
}

export interface WalletCardVerificationSimpleStatus {
    readonly __typename: 'WalletCardVerificationSimpleStatus';
    readonly credentials?: Maybe<ReadonlyArray<WalletCardVerificationSimpleStatusCredential>>;
    readonly errorDetails?: Maybe<WalletCardVerificationSimpleStatusErrorDetails>;
    readonly status: WalletCardVerificationSimpleStatusEnum;
    readonly transactionId: Scalars['UUID']['output'];
}

export interface WalletCardVerificationSimpleStatusCredential {
    readonly __typename: 'WalletCardVerificationSimpleStatusCredential';
    readonly claims: Scalars['JSONObject']['output'];
    readonly credentialId: Scalars['String']['output'];
}

export enum WalletCardVerificationSimpleStatusEnum {
    FAILURE = 'FAILURE',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS'
}

export interface WalletCardVerificationSimpleStatusErrorDetails {
    readonly __typename: 'WalletCardVerificationSimpleStatusErrorDetails';
    readonly error: Scalars['String']['output'];
    readonly errorDescription: Scalars['String']['output'];
}

export type PageInfoFragment = {
    readonly __typename: 'PageInfo';
    readonly hasPreviousPage: boolean;
    readonly hasNextPage: boolean;
    readonly startCursor?: string | null;
    readonly endCursor?: string | null;
};

export type UserFragment = {
    readonly __typename: 'User';
    readonly id: string;
    readonly pictureUrl?: string | null;
    readonly fullName: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
};

export type UserDataSimpleFragment = {
    readonly __typename: 'UserData';
    readonly id: string;
    readonly email: string;
    readonly countryCode: string;
    readonly userType: UserType;
    readonly pictureUrl?: string | null;
    readonly fullName: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly dateOfBirth?: string | null;
    readonly ageGroup?: UserAgeGroup | null;
    readonly geoRegion?: { readonly __typename: 'GeoLocation'; readonly id: string; readonly name: string } | null;
};

export type UserDataWithVerifiedIdentityFragment = {
    readonly __typename: 'UserData';
    readonly id: string;
    readonly email: string;
    readonly countryCode: string;
    readonly userType: UserType;
    readonly pictureUrl?: string | null;
    readonly fullName: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly dateOfBirth?: string | null;
    readonly ageGroup?: UserAgeGroup | null;
    readonly verifiedIdentity?: {
        readonly __typename: 'UserVerifiedIdentity';
        readonly id: string;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName: string;
        readonly dateOfBirth: string;
        readonly realAmlSignatureKey: string;
        readonly realAmlVerificationType: string;
        readonly expiresAt: string;
        readonly updatedAt: string;
        readonly createdAt: string;
    } | null;
    readonly geoRegion?: { readonly __typename: 'GeoLocation'; readonly id: string; readonly name: string } | null;
};

export type UserDataWithMenuNavigationDetailsFragment = {
    readonly __typename: 'UserData';
    readonly menuNavigationItems: ReadonlyArray<NavigationItem>;
    readonly extraMenuNavigationItems: ReadonlyArray<NavigationItem>;
    readonly id: string;
    readonly email: string;
    readonly countryCode: string;
    readonly userType: UserType;
    readonly pictureUrl?: string | null;
    readonly fullName: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly dateOfBirth?: string | null;
    readonly ageGroup?: UserAgeGroup | null;
    readonly geoRegion?: { readonly __typename: 'GeoLocation'; readonly id: string; readonly name: string } | null;
};

export type UserDataDetailedFragment = {
    readonly __typename: 'UserData';
    readonly id: string;
    readonly email: string;
    readonly countryCode: string;
    readonly userType: UserType;
    readonly pictureUrl?: string | null;
    readonly fullName: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly dateOfBirth?: string | null;
    readonly ageGroup?: UserAgeGroup | null;
    readonly canAccessLessons: boolean;
    readonly emailStatus: {
        readonly __typename: 'UserEmailStatus';
        readonly hasBouncedEmail?: boolean | null;
        readonly hasMarkedAsSpamEmail?: boolean | null;
        readonly hasPersonalEmail?: boolean | null;
    };
    readonly geoRegion?: {
        readonly __typename: 'GeoLocation';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
    } | null;
    readonly loginIdentities: ReadonlyArray<{
        readonly __typename: 'UserLoginIdentity';
        readonly id: string;
        readonly connection: string;
        readonly federatedId: string;
        readonly email: string;
    }>;
    readonly connectedMentorProviders: ReadonlyArray<{
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly mentorGroups: ReadonlyArray<{
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        }>;
    }>;
    readonly availableMentorProviders: {
        readonly __typename: 'ProviderConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'ProviderEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            } | null;
        }>;
    };
    readonly providersWithMembership: ReadonlyArray<{
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly canUpdateProviderDetails: boolean;
        readonly canAccessAdminFeature: boolean;
        readonly canAccessMentorFeature: boolean;
        readonly canAccessMentorRewardFeature: boolean;
        readonly canAccessOpportunityFeature: boolean;
        readonly canAccessNewsfeedFeature: boolean;
        readonly canAccessProjectTemplatesFeature: boolean;
        readonly canAccessEmploymentFeature: boolean;
        readonly canAccessBadgeTemplatesFeature: boolean;
        readonly canImportMentorRewardPoints: boolean;
        readonly canImportMentorGroupsFromLearnerSmsData: boolean;
        readonly canPinPost: boolean;
        readonly canToggleProviderLessonAccess: boolean;
        readonly isDailyNoticesAvailable: boolean;
        readonly isLessonsDisabled: boolean;
    }>;
    readonly currentLearnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly uniqueStudentNumber?: string | null;
    } | null;
};

export type UserEmailStatusFragment = {
    readonly __typename: 'UserData';
    readonly id: string;
    readonly email: string;
    readonly emailStatus: {
        readonly __typename: 'UserEmailStatus';
        readonly hasBouncedEmail?: boolean | null;
        readonly hasMarkedAsSpamEmail?: boolean | null;
        readonly hasPersonalEmail?: boolean | null;
    };
};

export type UserLoginIdentityFragment = {
    readonly __typename: 'UserLoginIdentity';
    readonly id: string;
    readonly connection: string;
    readonly federatedId: string;
    readonly email: string;
};

export type UserEmailNotificationsSettingFragment = {
    readonly __typename: 'UserEmailNotificationsSetting';
    readonly isConnectionNotificationsEnabled: boolean;
    readonly isDailySummaryNotificationsEnabled: boolean;
    readonly isDiscussionNotificationsEnabled: boolean;
    readonly isProjectNotificationsEnabled: boolean;
};

export type UserVerifiedIdentityFragment = {
    readonly __typename: 'UserVerifiedIdentity';
    readonly id: string;
    readonly fullName: string;
    readonly givenName: string;
    readonly familyName: string;
    readonly dateOfBirth: string;
    readonly realAmlSignatureKey: string;
    readonly realAmlVerificationType: string;
    readonly expiresAt: string;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type TimetableEventFragment = {
    readonly __typename: 'TimetableEvent';
    readonly id: string;
    readonly color: string;
    readonly priority: number;
    readonly dateType: TimetableEventDateType;
    readonly start: string;
    readonly end?: string | null;
    readonly summary: string;
    readonly location?: string | null;
    readonly description?: string | null;
};

export type DailyNoticeFragment = {
    readonly __typename: 'DailyNotice';
    readonly id: string;
    readonly color: string;
    readonly level: string;
    readonly start: string;
    readonly end: string;
    readonly subject: string;
    readonly body?: string | null;
    readonly teacher?: string | null;
    readonly meetingDate?: string | null;
    readonly meetingPlace?: string | null;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type AssessmentResultFragment = {
    readonly __typename: 'AssessmentResult';
    readonly id: string;
    readonly type: AssessmentResultType;
    readonly number: string;
    readonly version: string;
    readonly level?: number | null;
    readonly credits?: number | null;
    readonly weighting?: number | null;
    readonly points?: number | null;
    readonly title?: string | null;
    readonly description?: string | null;
    readonly purpose?: string | null;
    readonly subField?: string | null;
    readonly date?: string | null;
    readonly subject?: string | null;
    readonly result?: string | null;
    readonly comment?: string | null;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type LearnerSmsGroupFragment = {
    readonly __typename: 'LearnerSmsGroup';
    readonly id: string;
    readonly name: string;
    readonly code: string;
    readonly numberOfStudents: number;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly employeesList: ReadonlyArray<{
        readonly __typename: 'LearnerSmsEmployee';
        readonly id: string;
        readonly pictureUrl: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly fullName: string;
    }>;
    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
};

export type LearnerSmsEmployeeFragment = {
    readonly __typename: 'LearnerSmsEmployee';
    readonly id: string;
    readonly pictureUrl: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly fullName: string;
};

export type LearnerSmsProfileDetailedFragment = {
    readonly __typename: 'LearnerSmsProfile';
    readonly id: string;
    readonly pictureUrl: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly fullName: string;
    readonly preferredGivenName: string;
    readonly preferredFamilyName?: string | null;
    readonly preferredFullName: string;
    readonly email: string;
    readonly idNumber: string;
    readonly barcodeNumber: string;
    readonly nationalCurriculumYear: string;
    readonly uniqueStudentNumber?: string | null;
    readonly mobile?: string | null;
    readonly isAssessmentResultsAvailable: boolean;
    readonly isAttendanceAvailable: boolean;
    readonly isDailyNoticesAvailable: boolean;
    readonly isTimetableAvailable: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type LearnerSmsProfileSimpleFragment = {
    readonly __typename: 'LearnerSmsProfile';
    readonly id: string;
    readonly pictureUrl: string;
    readonly preferredGivenName: string;
    readonly preferredFamilyName?: string | null;
    readonly preferredFullName: string;
    readonly email: string;
    readonly idNumber: string;
    readonly nationalCurriculumYear: string;
};

export type LearnerSmsAttendanceSummaryFragment = {
    readonly __typename: 'LearnerSmsAttendanceSummary';
    readonly halfDaysJustified: number;
    readonly halfDaysUnjustified: number;
    readonly halfDaysPresent: number;
    readonly halfDaysTotal: number;
    readonly standing: LearnerSmsAttendanceSummaryStanding;
};

export type CalendarEventFragment = {
    readonly __typename: 'CalendarEvent';
    readonly id: string;
    readonly dateType: CalendarEventDateType;
    readonly start: string;
    readonly end?: string | null;
    readonly summary: string;
    readonly location?: string | null;
    readonly description?: string | null;
};

export type CalendarFragment = {
    readonly __typename: 'Calendar';
    readonly id: string;
    readonly title: string;
    readonly color: string;
    readonly priority: number;
    readonly events: ReadonlyArray<{
        readonly __typename: 'CalendarEvent';
        readonly id: string;
        readonly dateType: CalendarEventDateType;
        readonly start: string;
        readonly end?: string | null;
        readonly summary: string;
        readonly location?: string | null;
        readonly description?: string | null;
    }>;
};

export type ProviderSimpleFragment = {
    readonly __typename: 'Provider';
    readonly id: string;
    readonly title: string;
    readonly countryCode: string;
    readonly providerType: ProviderType;
    readonly logoImageUrl: string;
    readonly bannerImageUrl?: string | null;
};

export type ProviderWithPermissionsFragment = {
    readonly __typename: 'Provider';
    readonly id: string;
    readonly title: string;
    readonly countryCode: string;
    readonly providerType: ProviderType;
    readonly logoImageUrl: string;
    readonly bannerImageUrl?: string | null;
    readonly canUpdateProviderDetails: boolean;
    readonly canAccessAdminFeature: boolean;
    readonly canAccessMentorFeature: boolean;
    readonly canAccessMentorRewardFeature: boolean;
    readonly canAccessOpportunityFeature: boolean;
    readonly canAccessNewsfeedFeature: boolean;
    readonly canAccessProjectTemplatesFeature: boolean;
    readonly canAccessEmploymentFeature: boolean;
    readonly canAccessBadgeTemplatesFeature: boolean;
    readonly canImportMentorRewardPoints: boolean;
    readonly canImportMentorGroupsFromLearnerSmsData: boolean;
    readonly canPinPost: boolean;
    readonly canToggleProviderLessonAccess: boolean;
    readonly isDailyNoticesAvailable: boolean;
    readonly isLessonsDisabled: boolean;
};

export type ProviderSearchResultFragment = {
    readonly __typename: 'Provider';
    readonly hasFollowed: boolean;
    readonly id: string;
    readonly title: string;
    readonly countryCode: string;
    readonly providerType: ProviderType;
    readonly logoImageUrl: string;
    readonly bannerImageUrl?: string | null;
};

export type ProviderInternalContactDetailsFragment = {
    readonly __typename: 'Provider';
    readonly id: string;
    readonly internalContactDetails?: {
        readonly __typename: 'ProviderInternalContactDetails';
        readonly fullName?: string | null;
        readonly email?: string | null;
        readonly phone?: string | null;
    } | null;
};

export type ProviderTechnicalContactDetailsFragment = {
    readonly __typename: 'Provider';
    readonly id: string;
    readonly technicalContactDetails?: {
        readonly __typename: 'ProviderTechnicalContactDetails';
        readonly fullName?: string | null;
        readonly email?: string | null;
        readonly phone?: string | null;
    } | null;
};

export type ProviderActiveModulesFragment = {
    readonly __typename: 'Provider';
    readonly id: string;
    readonly activeModules: ReadonlyArray<{ readonly __typename: 'ProviderModule'; readonly id: string; readonly title: string }>;
};

export type ProviderPermissionsFragment = {
    readonly __typename: 'Provider';
    readonly id: string;
    readonly canUpdateProviderDetails: boolean;
    readonly canAccessAdminFeature: boolean;
    readonly canAccessMentorFeature: boolean;
    readonly canAccessMentorRewardFeature: boolean;
    readonly canAccessOpportunityFeature: boolean;
    readonly canAccessNewsfeedFeature: boolean;
    readonly canAccessProjectTemplatesFeature: boolean;
    readonly canAccessEmploymentFeature: boolean;
    readonly canAccessBadgeTemplatesFeature: boolean;
    readonly canImportMentorRewardPoints: boolean;
    readonly canImportMentorGroupsFromLearnerSmsData: boolean;
    readonly canPinPost: boolean;
    readonly canToggleProviderLessonAccess: boolean;
    readonly isDailyNoticesAvailable: boolean;
    readonly isLessonsDisabled: boolean;
};

type ProviderIntegrationProviderIntegrationEdgeLearningFragment = {
    readonly __typename: 'ProviderIntegrationEdgeLearning';
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

type ProviderIntegrationProviderIntegrationKamarFragment = {
    readonly __typename: 'ProviderIntegrationKamar';
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

type ProviderIntegrationProviderIntegrationLissFragment = {
    readonly __typename: 'ProviderIntegrationLiss';
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

type ProviderIntegrationProviderIntegrationManualSmsDataFragment = {
    readonly __typename: 'ProviderIntegrationManualSmsData';
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

type ProviderIntegrationProviderIntegrationWondeFragment = {
    readonly __typename: 'ProviderIntegrationWonde';
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

export type ProviderIntegrationFragment =
    | ProviderIntegrationProviderIntegrationEdgeLearningFragment
    | ProviderIntegrationProviderIntegrationKamarFragment
    | ProviderIntegrationProviderIntegrationLissFragment
    | ProviderIntegrationProviderIntegrationManualSmsDataFragment
    | ProviderIntegrationProviderIntegrationWondeFragment;

type ProviderIntegrationLearnerSmsDataProviderIntegrationEdgeLearningFragment = {
    readonly __typename: 'ProviderIntegrationEdgeLearning';
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
};

type ProviderIntegrationLearnerSmsDataProviderIntegrationKamarFragment = {
    readonly __typename: 'ProviderIntegrationKamar';
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
};

type ProviderIntegrationLearnerSmsDataProviderIntegrationLissFragment = {
    readonly __typename: 'ProviderIntegrationLiss';
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
};

type ProviderIntegrationLearnerSmsDataProviderIntegrationManualSmsDataFragment = {
    readonly __typename: 'ProviderIntegrationManualSmsData';
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
};

type ProviderIntegrationLearnerSmsDataProviderIntegrationWondeFragment = {
    readonly __typename: 'ProviderIntegrationWonde';
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
};

export type ProviderIntegrationLearnerSmsDataFragment =
    | ProviderIntegrationLearnerSmsDataProviderIntegrationEdgeLearningFragment
    | ProviderIntegrationLearnerSmsDataProviderIntegrationKamarFragment
    | ProviderIntegrationLearnerSmsDataProviderIntegrationLissFragment
    | ProviderIntegrationLearnerSmsDataProviderIntegrationManualSmsDataFragment
    | ProviderIntegrationLearnerSmsDataProviderIntegrationWondeFragment;

export type ProviderIntegrationEdgeLearningFragment = {
    readonly __typename: 'ProviderIntegrationEdgeLearning';
    readonly moeNumber?: string | null;
    readonly noticeDataCount: number;
    readonly assessmentDataCount: number;
    readonly resultDataCount: number;
    readonly attendanceDataCount: number;
    readonly canDelete: boolean;
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

export type ProviderIntegrationKamarFragment = {
    readonly __typename: 'ProviderIntegrationKamar';
    readonly token?: string | null;
    readonly noticeDataCount: number;
    readonly assessmentDataCount: number;
    readonly resultDataCount: number;
    readonly attendanceDataCount: number;
    readonly timetableDisabledUntil?: string | null;
    readonly canUpdateTimetableDisabledUntil: boolean;
    readonly canRegenerateToken: boolean;
    readonly canDelete: boolean;
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

export type ProviderIntegrationLissFragment = {
    readonly __typename: 'ProviderIntegrationLiss';
    readonly token?: string | null;
    readonly canRegenerateToken: boolean;
    readonly canDelete: boolean;
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

export type ProviderIntegrationManualSmsDataFragment = {
    readonly __typename: 'ProviderIntegrationManualSmsData';
    readonly canDelete: boolean;
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

export type ProviderIntegrationWondeFragment = {
    readonly __typename: 'ProviderIntegrationWonde';
    readonly lastSyncedAt?: string | null;
    readonly canResync: boolean;
    readonly canDelete: boolean;
    readonly id: string;
    readonly integrationType: ProviderIntegrationType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly isDeleting: boolean;
    readonly studentDataCount: number;
    readonly studentPhotoDataCount: number;
    readonly employeeDataCount: number;
    readonly employeePhotoDataCount: number;
    readonly eventDataCount: number;
    readonly groupDataCount: number;
    readonly classDataCount: number;
    readonly lessonDataCount: number;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly parentIntegration?:
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | null;
    readonly childIntegrations: ReadonlyArray<
        | { readonly __typename: 'ProviderIntegrationEdgeLearning'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationKamar'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
          }
        | { readonly __typename: 'ProviderIntegrationWonde'; readonly id: string; readonly integrationType: ProviderIntegrationType }
    >;
};

export type ProviderMemberFragment = {
    readonly __typename: 'ProviderMember';
    readonly id: string;
    readonly canDelete: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
};

export type ProviderMemberProviderFragment = {
    readonly __typename: 'ProviderMember';
    readonly id: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type ProviderJoinCodeFragment = {
    readonly __typename: 'ProviderJoinCode';
    readonly id: string;
    readonly code: string;
    readonly isEnabled: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type ProviderMemberInviteFragment = {
    readonly __typename: 'ProviderMemberInvite';
    readonly id: string;
    readonly email: string;
    readonly redeemedAt?: string | null;
    readonly expiresAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
};

export type ProviderRoleFragment = { readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string };

export type ProviderModuleFragment = { readonly __typename: 'ProviderModule'; readonly id: string; readonly title: string };

export type GeoLocationFragment = {
    readonly __typename: 'GeoLocation';
    readonly id: string;
    readonly name: string;
    readonly displayName: string;
    readonly longitude?: number | null;
    readonly latitude?: number | null;
    readonly street?: string | null;
    readonly city?: string | null;
    readonly county?: string | null;
    readonly state?: string | null;
    readonly country?: string | null;
    readonly countryCode?: string | null;
};

export type ProviderPostFragment = {
    readonly __typename: 'ProviderPost';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
    readonly content: string;
    readonly countryCodes?: ReadonlyArray<string> | null;
    readonly mentorOnly: boolean;
    readonly mentorStudentOnly: boolean;
    readonly imageUrl?: string | null;
    readonly hasReaction: boolean;
    readonly pollExpiresAt?: string | null;
    readonly totalPollResponsesCount: number;
    readonly canViewPollResults: boolean;
    readonly isPinned: boolean;
    readonly postMetadata: {
        readonly __typename: 'PostMetadata';
        readonly author?: string | null;
        readonly date?: string | null;
        readonly description?: string | null;
        readonly image?: string | null;
        readonly video?: string | null;
        readonly lang?: string | null;
        readonly logo?: string | null;
        readonly publisher?: string | null;
        readonly title?: string | null;
        readonly url?: string | null;
    };
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly canUpdateProviderDetails: boolean;
        readonly canAccessAdminFeature: boolean;
        readonly canAccessMentorFeature: boolean;
        readonly canAccessMentorRewardFeature: boolean;
        readonly canAccessOpportunityFeature: boolean;
        readonly canAccessNewsfeedFeature: boolean;
        readonly canAccessProjectTemplatesFeature: boolean;
        readonly canAccessEmploymentFeature: boolean;
        readonly canAccessBadgeTemplatesFeature: boolean;
        readonly canImportMentorRewardPoints: boolean;
        readonly canImportMentorGroupsFromLearnerSmsData: boolean;
        readonly canPinPost: boolean;
        readonly canToggleProviderLessonAccess: boolean;
        readonly isDailyNoticesAvailable: boolean;
        readonly isLessonsDisabled: boolean;
    };
    readonly reactionsTotalCountByType: ReadonlyArray<{
        readonly __typename: 'ProviderPostReactionTotalCountByType';
        readonly type: ProviderPostReactionType;
        readonly count: number;
    }>;
    readonly myReaction?: {
        readonly __typename: 'ProviderPostReaction';
        readonly id: string;
        readonly type: ProviderPostReactionType;
    } | null;
    readonly pollOptions?: ReadonlyArray<{
        readonly __typename: 'ProviderPostPollOption';
        readonly id: string;
        readonly title: string;
        readonly totalResponsesCount: number;
        readonly totalResponsesCountPercentage: number;
    }> | null;
    readonly selectedPollOption?: {
        readonly __typename: 'ProviderPostPollResponse';
        readonly id: string;
        readonly providerPostPollOption: {
            readonly __typename: 'ProviderPostPollOption';
            readonly id: string;
            readonly title: string;
            readonly totalResponsesCount: number;
            readonly totalResponsesCountPercentage: number;
        };
    } | null;
};

export type ProviderPostPollOptionFragment = {
    readonly __typename: 'ProviderPostPollOption';
    readonly id: string;
    readonly title: string;
    readonly totalResponsesCount: number;
    readonly totalResponsesCountPercentage: number;
};

export type ProviderPostReactionTotalCountByTypeFragment = {
    readonly __typename: 'ProviderPostReactionTotalCountByType';
    readonly type: ProviderPostReactionType;
    readonly count: number;
};

export type ProviderPostReactionFragment = {
    readonly __typename: 'ProviderPostReaction';
    readonly id: string;
    readonly type: ProviderPostReactionType;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type OpportunityListingPostFragment = {
    readonly __typename: 'OpportunityListingPost';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
    readonly type: OpportunityListingType;
    readonly title: string;
    readonly country: string;
    readonly coverImageUrl?: string | null;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly geoLocation: {
        readonly __typename: 'GeoLocation';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
        readonly longitude?: number | null;
        readonly latitude?: number | null;
        readonly street?: string | null;
        readonly city?: string | null;
        readonly county?: string | null;
        readonly state?: string | null;
        readonly country?: string | null;
        readonly countryCode?: string | null;
    };
};

type NewsfeedItemGoalsDueReminderFragment = {
    readonly __typename: 'GoalsDueReminder';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
};

type NewsfeedItemOpportunityListingPostFragment = {
    readonly __typename: 'OpportunityListingPost';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
    readonly type: OpportunityListingType;
    readonly title: string;
    readonly country: string;
    readonly coverImageUrl?: string | null;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly geoLocation: {
        readonly __typename: 'GeoLocation';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
        readonly longitude?: number | null;
        readonly latitude?: number | null;
        readonly street?: string | null;
        readonly city?: string | null;
        readonly county?: string | null;
        readonly state?: string | null;
        readonly country?: string | null;
        readonly countryCode?: string | null;
    };
};

type NewsfeedItemProviderPostFragment = {
    readonly __typename: 'ProviderPost';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
    readonly content: string;
    readonly countryCodes?: ReadonlyArray<string> | null;
    readonly mentorOnly: boolean;
    readonly mentorStudentOnly: boolean;
    readonly imageUrl?: string | null;
    readonly hasReaction: boolean;
    readonly pollExpiresAt?: string | null;
    readonly totalPollResponsesCount: number;
    readonly canViewPollResults: boolean;
    readonly isPinned: boolean;
    readonly postMetadata: {
        readonly __typename: 'PostMetadata';
        readonly author?: string | null;
        readonly date?: string | null;
        readonly description?: string | null;
        readonly image?: string | null;
        readonly video?: string | null;
        readonly lang?: string | null;
        readonly logo?: string | null;
        readonly publisher?: string | null;
        readonly title?: string | null;
        readonly url?: string | null;
    };
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly canUpdateProviderDetails: boolean;
        readonly canAccessAdminFeature: boolean;
        readonly canAccessMentorFeature: boolean;
        readonly canAccessMentorRewardFeature: boolean;
        readonly canAccessOpportunityFeature: boolean;
        readonly canAccessNewsfeedFeature: boolean;
        readonly canAccessProjectTemplatesFeature: boolean;
        readonly canAccessEmploymentFeature: boolean;
        readonly canAccessBadgeTemplatesFeature: boolean;
        readonly canImportMentorRewardPoints: boolean;
        readonly canImportMentorGroupsFromLearnerSmsData: boolean;
        readonly canPinPost: boolean;
        readonly canToggleProviderLessonAccess: boolean;
        readonly isDailyNoticesAvailable: boolean;
        readonly isLessonsDisabled: boolean;
    };
    readonly reactionsTotalCountByType: ReadonlyArray<{
        readonly __typename: 'ProviderPostReactionTotalCountByType';
        readonly type: ProviderPostReactionType;
        readonly count: number;
    }>;
    readonly myReaction?: {
        readonly __typename: 'ProviderPostReaction';
        readonly id: string;
        readonly type: ProviderPostReactionType;
    } | null;
    readonly pollOptions?: ReadonlyArray<{
        readonly __typename: 'ProviderPostPollOption';
        readonly id: string;
        readonly title: string;
        readonly totalResponsesCount: number;
        readonly totalResponsesCountPercentage: number;
    }> | null;
    readonly selectedPollOption?: {
        readonly __typename: 'ProviderPostPollResponse';
        readonly id: string;
        readonly providerPostPollOption: {
            readonly __typename: 'ProviderPostPollOption';
            readonly id: string;
            readonly title: string;
            readonly totalResponsesCount: number;
            readonly totalResponsesCountPercentage: number;
        };
    } | null;
};

type NewsfeedItemSponsorPostFragment = {
    readonly __typename: 'SponsorPost';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
};

type NewsfeedItemStudentHomeworkItemsDueReminderFragment = {
    readonly __typename: 'StudentHomeworkItemsDueReminder';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
};

export type NewsfeedItemFragment =
    | NewsfeedItemGoalsDueReminderFragment
    | NewsfeedItemOpportunityListingPostFragment
    | NewsfeedItemProviderPostFragment
    | NewsfeedItemSponsorPostFragment
    | NewsfeedItemStudentHomeworkItemsDueReminderFragment;

export type OpportunityListingFragment = {
    readonly __typename: 'OpportunityListing';
    readonly id: string;
    readonly type: OpportunityListingType;
    readonly title: string;
    readonly company: string;
    readonly country: string;
    readonly coverImageId?: string | null;
    readonly coverImageUrl?: string | null;
    readonly jobDescription?: string | null;
    readonly payRateDescription?: number | null;
    readonly workingHoursDescription?: number | null;
    readonly contactEmail?: string | null;
    readonly contactPhone?: string | null;
    readonly contactWebLink?: string | null;
    readonly listingRange: OpportunityListingRange;
    readonly referenceCode?: string | null;
    readonly expiresAt: string;
    readonly isPinned: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canExpire: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly geoLocation?: {
        readonly __typename: 'GeoLocation';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
        readonly longitude?: number | null;
        readonly latitude?: number | null;
        readonly street?: string | null;
        readonly city?: string | null;
        readonly county?: string | null;
        readonly state?: string | null;
        readonly country?: string | null;
        readonly countryCode?: string | null;
    } | null;
};

export type GoalsDueReminderFragment = {
    readonly __typename: 'GoalsDueReminder';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
    readonly totalDue: number;
    readonly goals: ReadonlyArray<{
        readonly __typename: 'Goal';
        readonly id: string;
        readonly goalType: GoalType;
        readonly title: string;
        readonly imageUrl?: string | null;
        readonly imageId?: string | null;
        readonly information: string;
        readonly complete: boolean;
        readonly dueAt?: string | null;
        readonly color?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
    }>;
};

export type StudentHomeworkItemsDueReminderFragment = {
    readonly __typename: 'StudentHomeworkItemsDueReminder';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
    readonly totalDue: number;
    readonly studentHomeworkItems: ReadonlyArray<{
        readonly __typename: 'StudentHomeworkDiaryItem';
        readonly id: string;
        readonly date: string;
        readonly information?: string | null;
        readonly imageId?: string | null;
        readonly imageUrl?: string | null;
        readonly color?: string | null;
        readonly completedAt?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
    }>;
};

export type SponsorPostFragment = {
    readonly __typename: 'SponsorPost';
    readonly itemType: NewsfeedItemType;
    readonly itemId: string;
    readonly itemCreated: string;
    readonly itemUpdated?: string | null;
    readonly sponsorCampaignId: string;
    readonly sponsorProviderTitle: string;
    readonly sponsorProviderLogoImageUrl: string;
    readonly title: string;
    readonly content: string;
    readonly targetUrl: string;
    readonly mediaImageUrl?: string | null;
    readonly mediaVideoUrl?: string | null;
};

export type CurriculumVitaeFragment = {
    readonly __typename: 'CurriculumVitae';
    readonly id: string;
    readonly profileImageId?: string | null;
    readonly profileImageUrl?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly personalDetails: {
        readonly __typename: 'CurriculumVitaePersonalDetails';
        readonly givenName?: string | null;
        readonly familyName?: string | null;
        readonly dob?: string | null;
        readonly languageLocales?: ReadonlyArray<string> | null;
        readonly interests?: string | null;
        readonly driversLicenceType?: DriversLicenceType | null;
        readonly phoneNumber?: string | null;
        readonly email?: string | null;
        readonly street?: string | null;
        readonly suburb?: string | null;
        readonly city?: string | null;
        readonly countryCode?: string | null;
    };
    readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
    readonly employment: {
        readonly __typename: 'CurriculumVitaeEmployment';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeEmploymentEntry';
            readonly title: string;
            readonly company: string;
            readonly start: string;
            readonly end?: string | null;
            readonly description?: string | null;
        }> | null;
    };
    readonly volunteer: {
        readonly __typename: 'CurriculumVitaeVolunteer';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeVolunteerEntry';
            readonly title: string;
            readonly company: string;
            readonly start: string;
            readonly end?: string | null;
            readonly description?: string | null;
        }> | null;
    };
    readonly education: {
        readonly __typename: 'CurriculumVitaeEducation';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeEducationEntry';
            readonly title: string;
            readonly school: string;
            readonly year: string;
            readonly subjects?: ReadonlyArray<string> | null;
        }> | null;
    };
    readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
    readonly personalAttributes: {
        readonly __typename: 'CurriculumVitaePersonalAttributes';
        readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
    };
    readonly achievementsAndAwards: {
        readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
            readonly description: string;
            readonly year: string;
        }> | null;
    };
    readonly badges: {
        readonly __typename: 'CurriculumVitaeBadges';
        readonly selectedBadgesList: ReadonlyArray<{
            readonly __typename: 'Badge';
            readonly id: string;
            readonly title: string;
            readonly comment: string;
            readonly badgeImageUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    };
    readonly resourceHubItems: {
        readonly __typename: 'CurriculumVitaeResourceHubItems';
        readonly selectedResourceHubItemsList: ReadonlyArray<{
            readonly __typename: 'ResourceHubItem';
            readonly id: string;
            readonly title: string;
            readonly category: ResourceHubItemCategory;
            readonly type: ResourceHubItemType;
            readonly uses: number;
            readonly fileId: string;
            readonly fileAvailable: boolean;
            readonly fileName: string;
            readonly fileMimeType: string;
            readonly fileUrl?: string | null;
            readonly filePreviewUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    };
    readonly extracurricularActivities: {
        readonly __typename: 'CurriculumVitaeExtracurricularActivities';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
            readonly description: string;
            readonly year: string;
            readonly toYear?: string | null;
        }> | null;
    };
    readonly references: {
        readonly __typename: 'CurriculumVitaeReferences';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeReferenceEntry';
            readonly name: string;
            readonly company: string;
            readonly position: string;
            readonly email: string;
            readonly phoneNumber: string;
        }> | null;
    };
    readonly generatedFiles: ReadonlyArray<{
        readonly __typename: 'CurriculumVitaeGeneratedFile';
        readonly id: string;
        readonly fileId: string;
        readonly fileUrl: string;
        readonly generatedAt: string;
    }>;
};

export type CurriculumVitaePersonalDetailsFragment = {
    readonly __typename: 'CurriculumVitaePersonalDetails';
    readonly givenName?: string | null;
    readonly familyName?: string | null;
    readonly dob?: string | null;
    readonly languageLocales?: ReadonlyArray<string> | null;
    readonly interests?: string | null;
    readonly driversLicenceType?: DriversLicenceType | null;
    readonly phoneNumber?: string | null;
    readonly email?: string | null;
    readonly street?: string | null;
    readonly suburb?: string | null;
    readonly city?: string | null;
    readonly countryCode?: string | null;
};

export type CurriculumVitaePersonalStatementFragment = {
    readonly __typename: 'CurriculumVitaePersonalStatement';
    readonly description?: string | null;
};

export type CurriculumVitaeEmploymentFragment = {
    readonly __typename: 'CurriculumVitaeEmployment';
    readonly entries?: ReadonlyArray<{
        readonly __typename: 'CurriculumVitaeEmploymentEntry';
        readonly title: string;
        readonly company: string;
        readonly start: string;
        readonly end?: string | null;
        readonly description?: string | null;
    }> | null;
};

export type CurriculumVitaeEmploymentEntryFragment = {
    readonly __typename: 'CurriculumVitaeEmploymentEntry';
    readonly title: string;
    readonly company: string;
    readonly start: string;
    readonly end?: string | null;
    readonly description?: string | null;
};

export type CurriculumVitaeVolunteerFragment = {
    readonly __typename: 'CurriculumVitaeVolunteer';
    readonly entries?: ReadonlyArray<{
        readonly __typename: 'CurriculumVitaeVolunteerEntry';
        readonly title: string;
        readonly company: string;
        readonly start: string;
        readonly end?: string | null;
        readonly description?: string | null;
    }> | null;
};

export type CurriculumVitaeVolunteerEntryFragment = {
    readonly __typename: 'CurriculumVitaeVolunteerEntry';
    readonly title: string;
    readonly company: string;
    readonly start: string;
    readonly end?: string | null;
    readonly description?: string | null;
};

export type CurriculumVitaeEducationFragment = {
    readonly __typename: 'CurriculumVitaeEducation';
    readonly entries?: ReadonlyArray<{
        readonly __typename: 'CurriculumVitaeEducationEntry';
        readonly title: string;
        readonly school: string;
        readonly year: string;
        readonly subjects?: ReadonlyArray<string> | null;
    }> | null;
};

export type CurriculumVitaeEducationEntryFragment = {
    readonly __typename: 'CurriculumVitaeEducationEntry';
    readonly title: string;
    readonly school: string;
    readonly year: string;
    readonly subjects?: ReadonlyArray<string> | null;
};

export type CurriculumVitaeSkillsFragment = { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };

export type CurriculumVitaePersonalAttributesFragment = {
    readonly __typename: 'CurriculumVitaePersonalAttributes';
    readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
};

export type CurriculumVitaeAchievementsAndAwardsFragment = {
    readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
    readonly entries?: ReadonlyArray<{
        readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
        readonly description: string;
        readonly year: string;
    }> | null;
};

export type CurriculumVitaeBadgesFragment = {
    readonly __typename: 'CurriculumVitaeBadges';
    readonly selectedBadgesList: ReadonlyArray<{
        readonly __typename: 'Badge';
        readonly id: string;
        readonly title: string;
        readonly comment: string;
        readonly badgeImageUrl: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canDelete: boolean;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    }>;
};

export type CurriculumVitaeResourceHubItemsFragment = {
    readonly __typename: 'CurriculumVitaeResourceHubItems';
    readonly selectedResourceHubItemsList: ReadonlyArray<{
        readonly __typename: 'ResourceHubItem';
        readonly id: string;
        readonly title: string;
        readonly category: ResourceHubItemCategory;
        readonly type: ResourceHubItemType;
        readonly uses: number;
        readonly fileId: string;
        readonly fileAvailable: boolean;
        readonly fileName: string;
        readonly fileMimeType: string;
        readonly fileUrl?: string | null;
        readonly filePreviewUrl: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    }>;
};

export type CurriculumVitaeAchievementsAndAwardsEntryFragment = {
    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
    readonly description: string;
    readonly year: string;
};

export type CurriculumVitaeExtracurricularActivitiesFragment = {
    readonly __typename: 'CurriculumVitaeExtracurricularActivities';
    readonly entries?: ReadonlyArray<{
        readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
        readonly description: string;
        readonly year: string;
        readonly toYear?: string | null;
    }> | null;
};

export type CurriculumVitaeExtracurricularActivitiesEntryFragment = {
    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
    readonly description: string;
    readonly year: string;
    readonly toYear?: string | null;
};

export type CurriculumVitaeReferencesFragment = {
    readonly __typename: 'CurriculumVitaeReferences';
    readonly entries?: ReadonlyArray<{
        readonly __typename: 'CurriculumVitaeReferenceEntry';
        readonly name: string;
        readonly company: string;
        readonly position: string;
        readonly email: string;
        readonly phoneNumber: string;
    }> | null;
};

export type CurriculumVitaeReferenceEntryFragment = {
    readonly __typename: 'CurriculumVitaeReferenceEntry';
    readonly name: string;
    readonly company: string;
    readonly position: string;
    readonly email: string;
    readonly phoneNumber: string;
};

export type JobProfileFragment = {
    readonly __typename: 'JobProfile';
    readonly id: string;
    readonly curriculumVitaeCompletedSections: ReadonlyArray<JobProfileRequiredSection>;
    readonly preferredJobCategories: ReadonlyArray<JobCategory>;
    readonly preferredJobTypes: ReadonlyArray<JobType>;
    readonly preferredJobAvailability: string;
    readonly canEnable: boolean;
    readonly enabledAt?: string | null;
    readonly preferredJobGeoLocations: ReadonlyArray<{
        readonly __typename: 'GeoLocation';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
        readonly longitude?: number | null;
        readonly latitude?: number | null;
        readonly street?: string | null;
        readonly city?: string | null;
        readonly county?: string | null;
        readonly state?: string | null;
        readonly country?: string | null;
        readonly countryCode?: string | null;
    }>;
};

export type JobProfileActivityFragment = {
    readonly __typename: 'JobProfileActivity';
    readonly id: string;
    readonly activityType: JobProfileActivityType;
    readonly actorProviderTitle?: string | null;
    readonly actorFullName?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly actorProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    readonly actor?: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
    } | null;
};

export type StudentJobProfileFragment = {
    readonly __typename: 'StudentJobProfile';
    readonly id: string;
    readonly givenName: string;
    readonly age: number;
    readonly profileImageUrl?: string | null;
    readonly languageLocales?: ReadonlyArray<string> | null;
    readonly interests: string;
    readonly driversLicenceType: DriversLicenceType;
    readonly preferredJobAvailability: string;
    readonly preferredJobTypes: ReadonlyArray<JobType>;
    readonly preferredJobCategories: ReadonlyArray<JobCategory>;
    readonly isFavourite: boolean;
    readonly hasContacted: boolean;
    readonly lastContactedAt?: string | null;
    readonly preferredJobGeoLocations: ReadonlyArray<{ readonly __typename: 'GeoLocation'; readonly id: string; readonly name: string }>;
    readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
    readonly employment?: {
        readonly __typename: 'CurriculumVitaeEmployment';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeEmploymentEntry';
            readonly title: string;
            readonly company: string;
            readonly start: string;
            readonly end?: string | null;
            readonly description?: string | null;
        }> | null;
    } | null;
    readonly volunteer?: {
        readonly __typename: 'CurriculumVitaeVolunteer';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeVolunteerEntry';
            readonly title: string;
            readonly company: string;
            readonly start: string;
            readonly end?: string | null;
            readonly description?: string | null;
        }> | null;
    } | null;
    readonly education: {
        readonly __typename: 'CurriculumVitaeEducation';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeEducationEntry';
            readonly title: string;
            readonly school: string;
            readonly year: string;
            readonly subjects?: ReadonlyArray<string> | null;
        }> | null;
    };
    readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
    readonly personalAttributes: {
        readonly __typename: 'CurriculumVitaePersonalAttributes';
        readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
    };
    readonly achievementsAndAwards?: {
        readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
            readonly description: string;
            readonly year: string;
        }> | null;
    } | null;
    readonly extracurricularActivities?: {
        readonly __typename: 'CurriculumVitaeExtracurricularActivities';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
            readonly description: string;
            readonly year: string;
            readonly toYear?: string | null;
        }> | null;
    } | null;
    readonly references: {
        readonly __typename: 'CurriculumVitaeReferences';
        readonly entries?: ReadonlyArray<{
            readonly __typename: 'CurriculumVitaeReferenceEntry';
            readonly name: string;
            readonly company: string;
            readonly position: string;
            readonly email: string;
            readonly phoneNumber: string;
        }> | null;
    };
};

export type StudentJobProfileSummaryFragment = {
    readonly __typename: 'StudentJobProfileSearchResultItem';
    readonly id: string;
    readonly givenName: string;
    readonly age: number;
    readonly profileImageUrl?: string | null;
    readonly preferredJobCategories: ReadonlyArray<JobCategory>;
    readonly preferredJobTypes: ReadonlyArray<JobType>;
    readonly isFavourite: boolean;
    readonly hasContacted: boolean;
    readonly preferredJobGeoLocations: ReadonlyArray<{
        readonly __typename: 'GeoLocation';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
        readonly longitude?: number | null;
        readonly latitude?: number | null;
        readonly street?: string | null;
        readonly city?: string | null;
        readonly county?: string | null;
        readonly state?: string | null;
        readonly country?: string | null;
        readonly countryCode?: string | null;
    }>;
};

export type GoalFragment = {
    readonly __typename: 'Goal';
    readonly id: string;
    readonly goalType: GoalType;
    readonly title: string;
    readonly imageUrl?: string | null;
    readonly imageId?: string | null;
    readonly information: string;
    readonly complete: boolean;
    readonly dueAt?: string | null;
    readonly color?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly canCreateTask: boolean;
    readonly canCreateNote: boolean;
    readonly tasks: ReadonlyArray<{
        readonly __typename: 'GoalTask';
        readonly id: string;
        readonly information: string;
        readonly complete: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
    }>;
    readonly notes: ReadonlyArray<{
        readonly __typename: 'GoalNote';
        readonly id: string;
        readonly information: string;
        readonly updatedAt: string;
        readonly createdAt: string;
    }>;
};

export type GoalSimpleFragment = {
    readonly __typename: 'Goal';
    readonly id: string;
    readonly goalType: GoalType;
    readonly title: string;
    readonly imageUrl?: string | null;
    readonly imageId?: string | null;
    readonly information: string;
    readonly complete: boolean;
    readonly dueAt?: string | null;
    readonly color?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type GoalTaskFragment = {
    readonly __typename: 'GoalTask';
    readonly id: string;
    readonly information: string;
    readonly complete: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type GoalNoteFragment = {
    readonly __typename: 'GoalNote';
    readonly id: string;
    readonly information: string;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type PathwayPlannerStageFragment = {
    readonly __typename: 'PathwayPlannerStage';
    readonly id: string;
    readonly title: string;
    readonly usefulExperienceList?: ReadonlyArray<{
        readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
        readonly id: string;
        readonly completed: boolean;
        readonly title: string;
    }> | null;
    readonly subjectsList?: ReadonlyArray<{
        readonly __typename: 'PathwayPlannerStageSubjectTodo';
        readonly id: string;
        readonly completed: boolean;
        readonly title: string;
    }> | null;
};

export type PathwayPlannerStageUsefulExperienceTodoFragment = {
    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
    readonly id: string;
    readonly completed: boolean;
    readonly title: string;
};

export type PathwayPlannerStageSubjectTodoFragment = {
    readonly __typename: 'PathwayPlannerStageSubjectTodo';
    readonly id: string;
    readonly completed: boolean;
    readonly title: string;
};

export type PathwayPlannerUsefulExperienceTodoFragment = {
    readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
    readonly id: string;
    readonly completed: boolean;
    readonly title: string;
};

export type PathwayPlannerQualificationTodoFragment = {
    readonly __typename: 'PathwayPlannerQualificationTodo';
    readonly id: string;
    readonly completed: boolean;
    readonly title: string;
};

export type PathwayPlannerSkillAndKnowledgeTodoFragment = {
    readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
    readonly id: string;
    readonly completed: boolean;
    readonly title: string;
};

export type PathwayPlannerPersonalRequirementTodoFragment = {
    readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
    readonly id: string;
    readonly completed: boolean;
    readonly title: string;
};

export type PathwayPlannerFragment = {
    readonly __typename: 'PathwayPlanner';
    readonly id: string;
    readonly title: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly usefulExperienceList?: ReadonlyArray<{
        readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
        readonly id: string;
        readonly completed: boolean;
        readonly title: string;
    }> | null;
    readonly qualificationsList?: ReadonlyArray<{
        readonly __typename: 'PathwayPlannerQualificationTodo';
        readonly id: string;
        readonly completed: boolean;
        readonly title: string;
    }> | null;
    readonly personalRequirementsList?: ReadonlyArray<{
        readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
        readonly id: string;
        readonly completed: boolean;
        readonly title: string;
    }> | null;
    readonly skillsAndKnowledgeList?: ReadonlyArray<{
        readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
        readonly id: string;
        readonly completed: boolean;
        readonly title: string;
    }> | null;
    readonly stagesList?: ReadonlyArray<{
        readonly __typename: 'PathwayPlannerStage';
        readonly id: string;
        readonly title: string;
        readonly usefulExperienceList?: ReadonlyArray<{
            readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
            readonly id: string;
            readonly completed: boolean;
            readonly title: string;
        }> | null;
        readonly subjectsList?: ReadonlyArray<{
            readonly __typename: 'PathwayPlannerStageSubjectTodo';
            readonly id: string;
            readonly completed: boolean;
            readonly title: string;
        }> | null;
    }> | null;
};

export type ReflectionFragment = {
    readonly __typename: 'Reflection';
    readonly id: string;
    readonly reflectionType: ReflectionType;
    readonly information: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
};

export type ResourceHubItemFragment = {
    readonly __typename: 'ResourceHubItem';
    readonly id: string;
    readonly title: string;
    readonly category: ResourceHubItemCategory;
    readonly type: ResourceHubItemType;
    readonly uses: number;
    readonly fileId: string;
    readonly fileAvailable: boolean;
    readonly fileName: string;
    readonly fileMimeType: string;
    readonly fileUrl?: string | null;
    readonly filePreviewUrl: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type StudyCategoryFragment = {
    readonly __typename: 'StudyCategory';
    readonly id: string;
    readonly title: string;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type StudyProviderFragment = {
    readonly __typename: 'StudyProvider';
    readonly id: string;
    readonly countryCode: string;
    readonly title: string;
    readonly logoImageUrl?: string | null;
    readonly isEnquiriesEnabled: boolean;
    readonly isEnrolmentEnabled: boolean;
    readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type StudyProviderForSuperadminFragment = {
    readonly __typename: 'StudyProvider';
    readonly id: string;
    readonly countryCode: string;
    readonly title: string;
    readonly logoImageUrl?: string | null;
    readonly logoImageId?: string | null;
    readonly email?: string | null;
    readonly isEnquiriesEnabled: boolean;
    readonly isEnrolmentEnabled: boolean;
    readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type StudyProgrammeFragment = {
    readonly __typename: 'StudyProgramme';
    readonly id: string;
    readonly studyProgrammeType: StudyProgrammeType;
    readonly title: string;
    readonly link: string;
    readonly information: string;
    readonly hasSavedStudyProgramme: boolean;
    readonly studyCountryCodes: ReadonlyArray<string>;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canEnquireStudyProgramme: boolean;
    readonly canEnrolInStudyProgramme: boolean;
    readonly studyCategory: {
        readonly __typename: 'StudyCategory';
        readonly id: string;
        readonly title: string;
        readonly updatedAt: string;
        readonly createdAt: string;
    };
    readonly studyProvider: {
        readonly __typename: 'StudyProvider';
        readonly id: string;
        readonly countryCode: string;
        readonly title: string;
        readonly logoImageUrl?: string | null;
        readonly isEnquiriesEnabled: boolean;
        readonly isEnrolmentEnabled: boolean;
        readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
        readonly updatedAt: string;
        readonly createdAt: string;
    };
};

export type SavedStudyProgrammeFragment = {
    readonly __typename: 'StudyProgramme';
    readonly id: string;
    readonly title: string;
    readonly link: string;
    readonly studyProvider: {
        readonly __typename: 'StudyProvider';
        readonly id: string;
        readonly countryCode: string;
        readonly title: string;
        readonly logoImageUrl?: string | null;
        readonly isEnquiriesEnabled: boolean;
        readonly isEnrolmentEnabled: boolean;
        readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
        readonly updatedAt: string;
        readonly createdAt: string;
    };
};

export type ThreeGoodThingsFragment = {
    readonly __typename: 'ThreeGoodThings';
    readonly id: string;
    readonly date: string;
    readonly firstItem: string;
    readonly secondItem?: string | null;
    readonly thirdItem?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
};

export type LessonCategoryFragment = {
    readonly __typename: 'LessonCategory';
    readonly id: string;
    readonly locale: string;
    readonly title: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly parent?: {
        readonly __typename: 'LessonCategory';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
    } | null;
};

export type LessonCategoryWithLocalizationsFragment = {
    readonly __typename: 'LessonCategory';
    readonly defaultLocale: string;
    readonly id: string;
    readonly locale: string;
    readonly title: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly localizations: ReadonlyArray<{
        readonly __typename: 'LessonCategoryLocalization';
        readonly locale: string;
        readonly title: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
    }>;
    readonly parent?: {
        readonly __typename: 'LessonCategory';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
    } | null;
};

export type LessonCategoryLocalizationFragment = {
    readonly __typename: 'LessonCategoryLocalization';
    readonly locale: string;
    readonly title: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
};

export type LessonFragment = {
    readonly __typename: 'Lesson';
    readonly id: string;
    readonly locale: string;
    readonly title: string;
    readonly description: string;
    readonly coverImageUrl?: string | null;
    readonly documentId: string;
    readonly documentIframeUrl?: string | null;
    readonly tags: ReadonlyArray<string>;
    readonly isFeatured: boolean;
    readonly countryCodes: ReadonlyArray<string>;
    readonly favoriteCount: number;
    readonly hasRead: boolean;
    readonly hasFavorited: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly lessonCategory: {
        readonly __typename: 'LessonCategory';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly parent?: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
        } | null;
    };
};

export type LessonWithLocalizationsFragment = {
    readonly __typename: 'Lesson';
    readonly defaultLocale: string;
    readonly id: string;
    readonly locale: string;
    readonly title: string;
    readonly description: string;
    readonly coverImageUrl?: string | null;
    readonly documentId: string;
    readonly documentIframeUrl?: string | null;
    readonly tags: ReadonlyArray<string>;
    readonly isFeatured: boolean;
    readonly countryCodes: ReadonlyArray<string>;
    readonly favoriteCount: number;
    readonly hasRead: boolean;
    readonly hasFavorited: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly localizations: ReadonlyArray<{
        readonly __typename: 'LessonLocalization';
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageId?: string | null;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly tags: ReadonlyArray<string>;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
    }>;
    readonly lessonCategory: {
        readonly __typename: 'LessonCategory';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly parent?: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
        } | null;
    };
};

export type LessonLocalizationFragment = {
    readonly __typename: 'LessonLocalization';
    readonly locale: string;
    readonly title: string;
    readonly description: string;
    readonly coverImageId?: string | null;
    readonly coverImageUrl?: string | null;
    readonly documentId: string;
    readonly tags: ReadonlyArray<string>;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
};

export type LessonSearchResultFragment = {
    readonly __typename: 'Lesson';
    readonly id: string;
    readonly locale: string;
    readonly title: string;
    readonly description: string;
    readonly coverImageUrl?: string | null;
    readonly documentId: string;
    readonly documentIframeUrl?: string | null;
    readonly tags: ReadonlyArray<string>;
    readonly isFeatured: boolean;
    readonly countryCodes: ReadonlyArray<string>;
    readonly favoriteCount: number;
    readonly hasRead: boolean;
    readonly hasFavorited: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly lessonCategory: {
        readonly __typename: 'LessonCategory';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly parent?: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
        } | null;
    };
};

export type MentorGroupSimpleFragment = {
    readonly __typename: 'MentorGroup';
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly information?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorGroupWithDiscussionUserStateFragment = {
    readonly __typename: 'MentorGroup';
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly information?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly discussionUserState: {
        readonly __typename: 'MentorGroupDiscussionUserState';
        readonly lastRead?: string | null;
        readonly lastTyping?: string | null;
        readonly unreadMessageCount: number;
        readonly mutedAt?: string | null;
    };
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorGroupForDiscussionFragment = {
    readonly __typename: 'MentorGroup';
    readonly isDiscussionLocked: boolean;
    readonly canLockDiscussion: boolean;
    readonly canSendDiscussionMessage: boolean;
    readonly canMuteDiscussionUser: boolean;
    readonly canCreateDiscussionPoll: boolean;
    readonly canRequireDiscussionAcknowledgement: boolean;
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly information?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly discussionUserState: {
        readonly __typename: 'MentorGroupDiscussionUserState';
        readonly lastRead?: string | null;
        readonly lastTyping?: string | null;
        readonly unreadMessageCount: number;
        readonly mutedAt?: string | null;
    };
    readonly discussionMessagesPinned?: ReadonlyArray<{
        readonly __typename: 'MentorGroupDiscussionMessage';
        readonly id: string;
        readonly message: string;
        readonly messageLinks?: ReadonlyArray<string> | null;
        readonly imageAvailable: boolean;
        readonly imageId?: string | null;
        readonly imageUrl?: string | null;
        readonly isPinned: boolean;
        readonly pollExpiresAt?: string | null;
        readonly totalPollResponsesCount: number;
        readonly canViewPollResults: boolean;
        readonly requiresAcknowledgement: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canReact: boolean;
        readonly canPin: boolean;
        readonly canAcknowledge: boolean;
        readonly canDelete: boolean;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly reactions: {
            readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
            readonly totalCount: number;
            readonly topEmojis: ReadonlyArray<string>;
        };
        readonly myReaction?: {
            readonly __typename: 'MentorGroupDiscussionMessageReaction';
            readonly id: string;
            readonly type: string;
        } | null;
        readonly pollOptions?: ReadonlyArray<{
            readonly __typename: 'MentorGroupDiscussionMessagePollOption';
            readonly id: string;
            readonly option: string;
            readonly totalResponsesCount: number;
            readonly totalResponsesCountPercentage: number;
        }> | null;
        readonly selectedPollOption?: {
            readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
            readonly id: string;
            readonly mentorGroupDiscussionMessagePollOption: {
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            };
        } | null;
        readonly acknowledgementStatuses?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
            readonly totalCount: number;
            readonly totalAcknowledgedCount: number;
        } | null;
        readonly myAcknowledgementStatus?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
            readonly id: string;
            readonly acknowledged: boolean;
            readonly acknowledgedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        } | null;
    } | null> | null;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorGroupForJoiningFragment = {
    readonly __typename: 'MentorGroup';
    readonly hasJoinedAsStudent: boolean;
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly information?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorGroupDetailedFragment = {
    readonly __typename: 'MentorGroup';
    readonly id: string;
    readonly title: string;
    readonly description?: string | null;
    readonly bannerImageUrl?: string | null;
    readonly information?: string | null;
    readonly inviteCode: string;
    readonly hasPinned: boolean;
    readonly pinnedAt?: string | null;
    readonly isJoinable: boolean;
    readonly isPublic: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canAddMember: boolean;
    readonly canSendDiscussionMessage: boolean;
    readonly canRegenerateInviteCode: boolean;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly canAddMentorStudent: boolean;
    readonly canCreateDiscussionPoll: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly discussionUserState: {
        readonly __typename: 'MentorGroupDiscussionUserState';
        readonly lastRead?: string | null;
        readonly lastTyping?: string | null;
        readonly unreadMessageCount: number;
        readonly mutedAt?: string | null;
    };
};

export type JoinableMentorGroupFragment = {
    readonly __typename: 'JoinableMentorGroup';
    readonly id: string;
    readonly title: string;
    readonly description?: string | null;
    readonly bannerImageUrl?: string | null;
    readonly totalStudentCount: number;
    readonly hasConsent: boolean;
    readonly hasJoined: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type JoinableMentorGroupSummaryFragment = {
    readonly __typename: 'JoinableMentorGroup';
    readonly id: string;
    readonly title: string;
    readonly description?: string | null;
    readonly bannerImageUrl?: string | null;
    readonly totalStudentCount: number;
    readonly hasConsent: boolean;
    readonly hasJoined: boolean;
    readonly members: {
        readonly __typename: 'JoinableMentorGroupMemberConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'JoinableMentorGroupMemberEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            } | null;
        }>;
    };
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorGroupMemberFragment = {
    readonly __typename: 'MentorGroupMember';
    readonly id: string;
    readonly canRemove: boolean;
    readonly providerMember: {
        readonly __typename: 'ProviderMember';
        readonly id: string;
        readonly canDelete: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
    };
};

export type MentorGroupDiscussionMessageFragment = {
    readonly __typename: 'MentorGroupDiscussionMessage';
    readonly id: string;
    readonly message: string;
    readonly messageLinks?: ReadonlyArray<string> | null;
    readonly imageAvailable: boolean;
    readonly imageId?: string | null;
    readonly imageUrl?: string | null;
    readonly isPinned: boolean;
    readonly pollExpiresAt?: string | null;
    readonly totalPollResponsesCount: number;
    readonly canViewPollResults: boolean;
    readonly requiresAcknowledgement: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canReact: boolean;
    readonly canPin: boolean;
    readonly canAcknowledge: boolean;
    readonly canDelete: boolean;
    readonly lesson?: {
        readonly __typename: 'Lesson';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly documentIframeUrl?: string | null;
        readonly tags: ReadonlyArray<string>;
        readonly isFeatured: boolean;
        readonly countryCodes: ReadonlyArray<string>;
        readonly favoriteCount: number;
        readonly hasRead: boolean;
        readonly hasFavorited: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lessonCategory: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly parent?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
            } | null;
        };
    } | null;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly reactions: {
        readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
        readonly totalCount: number;
        readonly topEmojis: ReadonlyArray<string>;
    };
    readonly myReaction?: {
        readonly __typename: 'MentorGroupDiscussionMessageReaction';
        readonly id: string;
        readonly type: string;
    } | null;
    readonly pollOptions?: ReadonlyArray<{
        readonly __typename: 'MentorGroupDiscussionMessagePollOption';
        readonly id: string;
        readonly option: string;
        readonly totalResponsesCount: number;
        readonly totalResponsesCountPercentage: number;
    }> | null;
    readonly selectedPollOption?: {
        readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
        readonly id: string;
        readonly mentorGroupDiscussionMessagePollOption: {
            readonly __typename: 'MentorGroupDiscussionMessagePollOption';
            readonly id: string;
            readonly option: string;
            readonly totalResponsesCount: number;
            readonly totalResponsesCountPercentage: number;
        };
    } | null;
    readonly acknowledgementStatuses?: {
        readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
        readonly totalCount: number;
        readonly totalAcknowledgedCount: number;
    } | null;
    readonly myAcknowledgementStatus?: {
        readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
        readonly id: string;
        readonly acknowledged: boolean;
        readonly acknowledgedAt?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    } | null;
};

export type MentorGroupDiscussionMessageAcknowledgementStatusFragment = {
    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
    readonly id: string;
    readonly acknowledged: boolean;
    readonly acknowledgedAt?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type MentorGroupDiscussionMessagePollOptionFragment = {
    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
    readonly id: string;
    readonly option: string;
    readonly totalResponsesCount: number;
    readonly totalResponsesCountPercentage: number;
};

export type MentorGroupDiscussionMessagePollResponseFragment = {
    readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
    readonly id: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly mentorGroupDiscussionMessagePollOption: {
        readonly __typename: 'MentorGroupDiscussionMessagePollOption';
        readonly id: string;
        readonly option: string;
        readonly totalResponsesCount: number;
        readonly totalResponsesCountPercentage: number;
    };
};

export type MentorGroupDiscussionUserStateFragment = {
    readonly __typename: 'MentorGroupDiscussionUserState';
    readonly lastRead?: string | null;
    readonly lastTyping?: string | null;
    readonly unreadMessageCount: number;
    readonly mutedAt?: string | null;
};

export type MentorGroupWithDiscussionMutedUsersFragment = {
    readonly __typename: 'MentorGroup';
    readonly mentorGroupDiscussionMutedUsers: {
        readonly __typename: 'MentorGroupDiscussionMutedUserConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'MentorGroupDiscussionMutedUserEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            } | null;
        }>;
    };
};

export type MentorGroupStudentSimpleFragment = {
    readonly __typename: 'MentorGroupStudent';
    readonly id: string;
    readonly mentorStudent: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly learnerSmsProfile?: {
            readonly __typename: 'LearnerSmsProfile';
            readonly id: string;
            readonly pictureUrl: string;
            readonly preferredGivenName: string;
            readonly preferredFamilyName?: string | null;
            readonly preferredFullName: string;
            readonly email: string;
            readonly idNumber: string;
            readonly nationalCurriculumYear: string;
        } | null;
    };
    readonly mentorGroup: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string };
};

export type MentorStudentSummaryFragment = {
    readonly __typename: 'MentorStudent';
    readonly id: string;
    readonly isAssessmentResultsAvailable: boolean;
    readonly isAttendanceAvailable: boolean;
    readonly isDailyNoticesAvailable: boolean;
    readonly isTimetableAvailable: boolean;
    readonly isSharingCurriculumVitae: boolean;
    readonly isSharingGoals: boolean;
    readonly isSharingPathwayPlanners: boolean;
    readonly isSharingReflections: boolean;
    readonly isSharingResourceHubItems: boolean;
    readonly isSharingSavedCourses: boolean;
    readonly sharedActivity?: ReadonlyArray<MentorStudentSharedActivityType> | null;
    readonly isSharingOtherProviderActivity: boolean;
    readonly canRemove: boolean;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly learnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly pictureUrl: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly fullName: string;
        readonly preferredGivenName: string;
        readonly preferredFamilyName?: string | null;
        readonly preferredFullName: string;
        readonly email: string;
        readonly idNumber: string;
        readonly barcodeNumber: string;
        readonly nationalCurriculumYear: string;
        readonly uniqueStudentNumber?: string | null;
        readonly mobile?: string | null;
        readonly isAssessmentResultsAvailable: boolean;
        readonly isAttendanceAvailable: boolean;
        readonly isDailyNoticesAvailable: boolean;
        readonly isTimetableAvailable: boolean;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    } | null;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly badges: { readonly __typename: 'BadgeConnection'; readonly totalCount: number; readonly lastCreatedAt?: string | null };
    readonly curriculumVitae?: { readonly __typename: 'CurriculumVitae'; readonly id: string; readonly updatedAt: string } | null;
    readonly jobProfile?: { readonly __typename: 'JobProfile'; readonly id: string; readonly enabledAt?: string | null } | null;
    readonly goals?: { readonly __typename: 'GoalConnection'; readonly totalCount: number; readonly lastUpdatedAt?: string | null } | null;
    readonly reflections?: {
        readonly __typename: 'ReflectionConnection';
        readonly totalCount: number;
        readonly lastUpdatedAt?: string | null;
    } | null;
    readonly resourceHubItems?: {
        readonly __typename: 'ResourceHubItemConnection';
        readonly totalCount: number;
        readonly lastUpdatedAt?: string | null;
    } | null;
    readonly savedStudyProgrammes?: {
        readonly __typename: 'SavedStudyProgrammeConnection';
        readonly totalCount: number;
        readonly lastSavedAt?: string | null;
    } | null;
    readonly pathwayPlanners?: {
        readonly __typename: 'PathwayPlannerConnection';
        readonly totalCount: number;
        readonly lastUpdatedAt?: string | null;
    } | null;
    readonly notes: {
        readonly __typename: 'MentorStudentNoteConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'MentorStudentNoteEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'MentorStudentNote';
                readonly id: string;
                readonly information: string;
                readonly isShared: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        }>;
    };
    readonly todos: {
        readonly __typename: 'MentorStudentTodoConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'MentorStudentTodoEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'MentorStudentTodo';
                readonly id: string;
                readonly information: string;
                readonly isShared: boolean;
                readonly complete: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        }>;
    };
    readonly projects: {
        readonly __typename: 'ProjectConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'ProjectEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'Project';
                readonly id: string;
                readonly title: string;
                readonly updatedAt: string;
            } | null;
        }>;
    };
    readonly mentorGroups: ReadonlyArray<{ readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string }>;
    readonly availableMentorGroups: {
        readonly __typename: 'AvailableMentorGroupConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'MentorGroupEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'MentorGroup';
                readonly id: string;
                readonly title: string;
                readonly bannerImageUrl?: string | null;
                readonly information?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
        }>;
    };
};

export type MentorStudentNoteFragment = {
    readonly __typename: 'MentorStudentNote';
    readonly id: string;
    readonly information: string;
    readonly isShared: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type MentorStudentTodoFragment = {
    readonly __typename: 'MentorStudentTodo';
    readonly id: string;
    readonly information: string;
    readonly isShared: boolean;
    readonly complete: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type MentorStudentSimpleFragment = {
    readonly __typename: 'MentorStudent';
    readonly id: string;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly learnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly pictureUrl: string;
        readonly preferredGivenName: string;
        readonly preferredFamilyName?: string | null;
        readonly preferredFullName: string;
        readonly email: string;
        readonly idNumber: string;
        readonly nationalCurriculumYear: string;
    } | null;
};

export type MentorStudentForMySchoolFragment = {
    readonly __typename: 'MentorStudent';
    readonly isAssessmentResultsAvailable: boolean;
    readonly isAttendanceAvailable: boolean;
    readonly isDailyNoticesAvailable: boolean;
    readonly isTimetableAvailable: boolean;
    readonly id: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly learnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly pictureUrl: string;
        readonly preferredGivenName: string;
        readonly preferredFamilyName?: string | null;
        readonly preferredFullName: string;
        readonly email: string;
        readonly idNumber: string;
        readonly nationalCurriculumYear: string;
    } | null;
};

export type MentorStudentGroupsFragment = {
    readonly __typename: 'MentorStudent';
    readonly id: string;
    readonly mentorGroups: ReadonlyArray<{ readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string }>;
};

export type MentorSharedResourceFragment = {
    readonly __typename: 'MentorSharedResource';
    readonly id: string;
    readonly sharedResourceType: MentorSharedResourceType;
    readonly information: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type MentorRewardValueFragment = {
    readonly __typename: 'MentorRewardValue';
    readonly id: string;
    readonly title: string;
    readonly color: string;
    readonly worth: number;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorRewardEventFragment = {
    readonly __typename: 'MentorRewardEvent';
    readonly id: string;
    readonly title: string;
    readonly description?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorRewardItemFragment = {
    readonly __typename: 'MentorRewardItem';
    readonly id: string;
    readonly title: string;
    readonly points: number;
    readonly description: string;
    readonly imageId: string;
    readonly imageUrl: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type MentorRewardStudentSimpleFragment = {
    readonly __typename: 'MentorRewardStudent';
    readonly id: string;
    readonly availablePoints: number;
    readonly totalPoints: number;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly learnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly pictureUrl: string;
        readonly preferredGivenName: string;
        readonly preferredFamilyName?: string | null;
        readonly preferredFullName: string;
        readonly email: string;
        readonly idNumber: string;
        readonly nationalCurriculumYear: string;
    } | null;
};

export type MentorRewardStudentDetailedFragment = {
    readonly __typename: 'MentorRewardStudent';
    readonly id: string;
    readonly availablePoints: number;
    readonly totalPoints: number;
    readonly canGiveReward: boolean;
    readonly canConsumeReward: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly learnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly pictureUrl: string;
        readonly preferredGivenName: string;
        readonly preferredFamilyName?: string | null;
        readonly preferredFullName: string;
        readonly email: string;
        readonly idNumber: string;
        readonly nationalCurriculumYear: string;
    } | null;
    readonly totalPointsByValue: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentValuePoints';
        readonly totalPoints: number;
        readonly mentorRewardValue: {
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
    readonly totalPointsByEvent: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentEventPoints';
        readonly totalPoints: number;
        readonly mentorRewardEvent: {
            readonly __typename: 'MentorRewardEvent';
            readonly id: string;
            readonly title: string;
            readonly description?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
};

export type MentorRewardStudentForMentorGroupFragment = {
    readonly __typename: 'MentorRewardStudent';
    readonly id: string;
    readonly availablePoints: number;
    readonly totalPoints: number;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly learnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly pictureUrl: string;
        readonly preferredGivenName: string;
        readonly preferredFamilyName?: string | null;
        readonly preferredFullName: string;
        readonly email: string;
        readonly idNumber: string;
        readonly nationalCurriculumYear: string;
    } | null;
    readonly totalPointsByValue: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentValuePoints';
        readonly totalPoints: number;
        readonly mentorRewardValue: {
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
    readonly totalPointsByEvent: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentEventPoints';
        readonly totalPoints: number;
        readonly mentorRewardEvent: {
            readonly __typename: 'MentorRewardEvent';
            readonly id: string;
            readonly title: string;
            readonly description?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
};

export type MentorRewardStudentValuePointsFragment = {
    readonly __typename: 'MentorRewardStudentValuePoints';
    readonly totalPoints: number;
    readonly mentorRewardValue: {
        readonly __typename: 'MentorRewardValue';
        readonly id: string;
        readonly title: string;
        readonly color: string;
        readonly worth: number;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    };
};

export type MentorRewardStudentEventPointsFragment = {
    readonly __typename: 'MentorRewardStudentEventPoints';
    readonly totalPoints: number;
    readonly mentorRewardEvent: {
        readonly __typename: 'MentorRewardEvent';
        readonly id: string;
        readonly title: string;
        readonly description?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    };
};

export type MentorRewardStudentPointsSummaryRangeFragment = {
    readonly __typename: 'MentorRewardStudentPointsSummaryRange';
    readonly totalPoints: number;
    readonly totalPointsByValue: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentValuePoints';
        readonly totalPoints: number;
        readonly mentorRewardValue: {
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
    readonly totalPointsByEvent: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentEventPoints';
        readonly totalPoints: number;
        readonly mentorRewardEvent: {
            readonly __typename: 'MentorRewardEvent';
            readonly id: string;
            readonly title: string;
            readonly description?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
};

export type MentorRewardTransactionFragment = {
    readonly __typename: 'MentorRewardTransaction';
    readonly id: string;
    readonly type: MentorRewardTransactionType;
    readonly comment?: string | null;
    readonly points: number;
    readonly mentorGroupTitle?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly mentorRewardStudent: { readonly __typename: 'MentorRewardStudent'; readonly id: string };
    readonly mentorRewardValue?: {
        readonly __typename: 'MentorRewardValue';
        readonly id: string;
        readonly title: string;
        readonly color: string;
        readonly worth: number;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    } | null;
    readonly mentorRewardEvent?: {
        readonly __typename: 'MentorRewardEvent';
        readonly id: string;
        readonly title: string;
        readonly description?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    } | null;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type PointsSummaryByTypeFragment = {
    readonly __typename: 'PointsSummaryByType';
    readonly givenPoints: number;
    readonly consumedPoints: number;
};

export type PointsYearSummaryByTypeFragment = {
    readonly __typename: 'PointsYearSummaryByType';
    readonly month: number;
    readonly givenPoints: number;
    readonly consumedPoints: number;
};

export type MentorRewardTopMentorFragment = {
    readonly __typename: 'MentorRewardTopMentor';
    readonly points: number;
    readonly rank: number;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type MentorRewardTopLearnerFragment = {
    readonly __typename: 'MentorRewardTopLearner';
    readonly points: number;
    readonly pointsByOther: number;
    readonly rank: number;
    readonly rewardStudent: {
        readonly __typename: 'MentorRewardStudent';
        readonly id: string;
        readonly availablePoints: number;
        readonly totalPoints: number;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly learnerSmsProfile?: {
            readonly __typename: 'LearnerSmsProfile';
            readonly id: string;
            readonly pictureUrl: string;
            readonly preferredGivenName: string;
            readonly preferredFamilyName?: string | null;
            readonly preferredFullName: string;
            readonly email: string;
            readonly idNumber: string;
            readonly nationalCurriculumYear: string;
        } | null;
    };
    readonly pointsByValue: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentValuePoints';
        readonly totalPoints: number;
        readonly mentorRewardValue: {
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
};

export type MentorRewardTopMentorGroupFragment = {
    readonly __typename: 'MentorRewardTopGroup';
    readonly points: number;
    readonly pointsByOther: number;
    readonly rank: number;
    readonly group: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly bannerImageUrl?: string | null;
        readonly information?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    };
    readonly pointsByValue: ReadonlyArray<{
        readonly __typename: 'MentorRewardStudentValuePoints';
        readonly totalPoints: number;
        readonly mentorRewardValue: {
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    }>;
};

export type ProviderProjectTemplateSimpleFragment = {
    readonly __typename: 'ProviderProjectTemplate';
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly bannerImageId?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly isPublic: boolean;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly sections: ReadonlyArray<{ readonly __typename: 'ProviderProjectTemplateSection'; readonly id: string }>;
    readonly updatedBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type ProviderProjectTemplateFragment = {
    readonly __typename: 'ProviderProjectTemplate';
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly bannerImageId?: string | null;
    readonly overviewDescription: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly isPublic: boolean;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly overviewResources: ReadonlyArray<{
        readonly __typename: 'ProviderProjectTemplateOverviewResource';
        readonly id: string;
        readonly url: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
    }>;
    readonly sections: ReadonlyArray<{
        readonly __typename: 'ProviderProjectTemplateSection';
        readonly id: string;
        readonly sectionOrder: number;
        readonly title: string;
        readonly color: string;
        readonly description: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly resources: ReadonlyArray<{
            readonly __typename: 'ProviderProjectTemplateSectionResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
    }>;
    readonly updatedBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type ProviderProjectTemplateOverviewResourceFragment = {
    readonly __typename: 'ProviderProjectTemplateOverviewResource';
    readonly id: string;
    readonly url: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly lesson?: {
        readonly __typename: 'Lesson';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly documentIframeUrl?: string | null;
        readonly tags: ReadonlyArray<string>;
        readonly isFeatured: boolean;
        readonly countryCodes: ReadonlyArray<string>;
        readonly favoriteCount: number;
        readonly hasRead: boolean;
        readonly hasFavorited: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lessonCategory: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly parent?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
            } | null;
        };
    } | null;
};

export type ProviderProjectTemplateSectionFragment = {
    readonly __typename: 'ProviderProjectTemplateSection';
    readonly id: string;
    readonly sectionOrder: number;
    readonly title: string;
    readonly color: string;
    readonly description: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly resources: ReadonlyArray<{
        readonly __typename: 'ProviderProjectTemplateSectionResource';
        readonly id: string;
        readonly url: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
    }>;
};

export type ProviderProjectTemplateSectionResourceFragment = {
    readonly __typename: 'ProviderProjectTemplateSectionResource';
    readonly id: string;
    readonly url: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly lesson?: {
        readonly __typename: 'Lesson';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly documentIframeUrl?: string | null;
        readonly tags: ReadonlyArray<string>;
        readonly isFeatured: boolean;
        readonly countryCodes: ReadonlyArray<string>;
        readonly favoriteCount: number;
        readonly hasRead: boolean;
        readonly hasFavorited: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lessonCategory: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly parent?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
            } | null;
        };
    } | null;
};

export type ProjectSimpleFragment = {
    readonly __typename: 'Project';
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly bannerImageId?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly totalCompletedProjectSections: number;
    readonly totalProjectSections: number;
    readonly latestReviewRequestedAt?: string | null;
    readonly completedAt?: string | null;
    readonly canUpdateMentorGroup: boolean;
    readonly canDelete: boolean;
    readonly isLocked: boolean;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type ProjectDetailedFragment = {
    readonly __typename: 'Project';
    readonly id: string;
    readonly title: string;
    readonly bannerImageUrl?: string | null;
    readonly bannerImageId?: string | null;
    readonly overviewDescription: string;
    readonly sourceTemplateId?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly totalProjectSections: number;
    readonly totalCompletedProjectSections: number;
    readonly latestReviewRequestedAt?: string | null;
    readonly completedAt?: string | null;
    readonly canUpdateMentorGroup: boolean;
    readonly canDelete: boolean;
    readonly isLocked: boolean;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
    readonly overviewResources: ReadonlyArray<{
        readonly __typename: 'ProjectOverviewResource';
        readonly id: string;
        readonly url: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
    }>;
    readonly sections: ReadonlyArray<{
        readonly __typename: 'ProjectSection';
        readonly id: string;
        readonly title: string;
        readonly color: string;
        readonly description: string;
        readonly response?: string | null;
        readonly isComplete: boolean;
        readonly isReviewComplete: boolean;
        readonly isReviewRequested: boolean;
        readonly completedAt?: string | null;
        readonly reviewCompletedAt?: string | null;
        readonly reviewRequestedAt?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canRequestReview: boolean;
        readonly canCompleteReview: boolean;
        readonly canCompleteSection: boolean;
        readonly canCreateComment: boolean;
        readonly resources: ReadonlyArray<{
            readonly __typename: 'ProjectSectionResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
        readonly evidences: ReadonlyArray<{
            readonly __typename: 'ProjectSectionEvidence';
            readonly id: string;
            readonly description?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resourceHubItem: {
                readonly __typename: 'ResourceHubItem';
                readonly id: string;
                readonly title: string;
                readonly category: ResourceHubItemCategory;
                readonly type: ResourceHubItemType;
                readonly uses: number;
                readonly fileId: string;
                readonly fileAvailable: boolean;
                readonly fileName: string;
                readonly fileMimeType: string;
                readonly fileUrl?: string | null;
                readonly filePreviewUrl: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            };
        }>;
        readonly comments: ReadonlyArray<{
            readonly __typename: 'ProjectSectionComment';
            readonly id: string;
            readonly comment: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    }>;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type SimilarProjectFragment = {
    readonly __typename: 'Project';
    readonly id: string;
    readonly title: string;
    readonly user: { readonly __typename: 'User'; readonly id: string; readonly fullName: string; readonly pictureUrl?: string | null };
};

export type ProjectMentorGroupFragment = { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string };

export type ProjectOverviewResourceFragment = {
    readonly __typename: 'ProjectOverviewResource';
    readonly id: string;
    readonly url: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly lesson?: {
        readonly __typename: 'Lesson';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly documentIframeUrl?: string | null;
        readonly tags: ReadonlyArray<string>;
        readonly isFeatured: boolean;
        readonly countryCodes: ReadonlyArray<string>;
        readonly favoriteCount: number;
        readonly hasRead: boolean;
        readonly hasFavorited: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lessonCategory: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly parent?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
            } | null;
        };
    } | null;
};

export type ProjectSectionFragment = {
    readonly __typename: 'ProjectSection';
    readonly id: string;
    readonly title: string;
    readonly color: string;
    readonly description: string;
    readonly response?: string | null;
    readonly isComplete: boolean;
    readonly isReviewComplete: boolean;
    readonly isReviewRequested: boolean;
    readonly completedAt?: string | null;
    readonly reviewCompletedAt?: string | null;
    readonly reviewRequestedAt?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canRequestReview: boolean;
    readonly canCompleteReview: boolean;
    readonly canCompleteSection: boolean;
    readonly canCreateComment: boolean;
    readonly resources: ReadonlyArray<{
        readonly __typename: 'ProjectSectionResource';
        readonly id: string;
        readonly url: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
    }>;
    readonly evidences: ReadonlyArray<{
        readonly __typename: 'ProjectSectionEvidence';
        readonly id: string;
        readonly description?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly resourceHubItem: {
            readonly __typename: 'ResourceHubItem';
            readonly id: string;
            readonly title: string;
            readonly category: ResourceHubItemCategory;
            readonly type: ResourceHubItemType;
            readonly uses: number;
            readonly fileId: string;
            readonly fileAvailable: boolean;
            readonly fileName: string;
            readonly fileMimeType: string;
            readonly fileUrl?: string | null;
            readonly filePreviewUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    }>;
    readonly comments: ReadonlyArray<{
        readonly __typename: 'ProjectSectionComment';
        readonly id: string;
        readonly comment: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    }>;
};

export type ProjectSectionResourceFragment = {
    readonly __typename: 'ProjectSectionResource';
    readonly id: string;
    readonly url: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly lesson?: {
        readonly __typename: 'Lesson';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly documentIframeUrl?: string | null;
        readonly tags: ReadonlyArray<string>;
        readonly isFeatured: boolean;
        readonly countryCodes: ReadonlyArray<string>;
        readonly favoriteCount: number;
        readonly hasRead: boolean;
        readonly hasFavorited: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lessonCategory: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly parent?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
            } | null;
        };
    } | null;
};

export type ProjectSectionEvidenceFragment = {
    readonly __typename: 'ProjectSectionEvidence';
    readonly id: string;
    readonly description?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly resourceHubItem: {
        readonly __typename: 'ResourceHubItem';
        readonly id: string;
        readonly title: string;
        readonly category: ResourceHubItemCategory;
        readonly type: ResourceHubItemType;
        readonly uses: number;
        readonly fileId: string;
        readonly fileAvailable: boolean;
        readonly fileName: string;
        readonly fileMimeType: string;
        readonly fileUrl?: string | null;
        readonly filePreviewUrl: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    };
};

export type ProjectSectionCommentFragment = {
    readonly __typename: 'ProjectSectionComment';
    readonly id: string;
    readonly comment: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type MentorSessionsSummaryFragment = {
    readonly __typename: 'MentorSessionsSummary';
    readonly sessionCount: number;
    readonly sessionDuration: string;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

type WalletCardBasicVerifiedIdCardFragment = {
    readonly __typename: 'BasicVerifiedIdCard';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
};

type WalletCardLearnerIdVerifiableCredentialFragment = {
    readonly __typename: 'LearnerIdVerifiableCredential';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
};

type WalletCardLoyaltyVerifiableCredentialFragment = {
    readonly __typename: 'LoyaltyVerifiableCredential';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
};

type WalletCardNzStudentIdFragment = {
    readonly __typename: 'NZStudentId';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
};

type WalletCardVerifiedIdVerifiableCredentialFragment = {
    readonly __typename: 'VerifiedIdVerifiableCredential';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
};

export type WalletCardFragment =
    | WalletCardBasicVerifiedIdCardFragment
    | WalletCardLearnerIdVerifiableCredentialFragment
    | WalletCardLoyaltyVerifiableCredentialFragment
    | WalletCardNzStudentIdFragment
    | WalletCardVerifiedIdVerifiableCredentialFragment;

export type LearnerIdVerifiableCredentialFragment = {
    readonly __typename: 'LearnerIdVerifiableCredential';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly idNumber: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly birthDate: string;
    readonly pictureUrl: string;
    readonly email: string;
    readonly countryCode: string;
    readonly organisationTitle: string;
    readonly organisationLogoUrl: string;
    readonly barcodeNumber: string;
    readonly nationalCurriculumYear: string;
    readonly age: number;
    readonly ageOver13: boolean;
    readonly ageOver16: boolean;
    readonly ageOver18: boolean;
    readonly ageOver21: boolean;
    readonly expiresAt: string;
    readonly address?: {
        readonly __typename: 'LearnerIdVerifiableCredentialAddress';
        readonly streetAddress?: string | null;
        readonly locality?: string | null;
        readonly region?: string | null;
        readonly postalCode?: string | null;
        readonly country: string;
    } | null;
};

export type VerifiedIdVerifiableCredentialFragment = {
    readonly __typename: 'VerifiedIdVerifiableCredential';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly givenName: string;
    readonly familyName?: string | null;
    readonly birthDate: string;
    readonly pictureUrl: string;
    readonly countryCode: string;
    readonly verificationSources: ReadonlyArray<string>;
    readonly age: number;
    readonly ageOver13: boolean;
    readonly ageOver16: boolean;
    readonly ageOver18: boolean;
    readonly ageOver21: boolean;
    readonly expiresAt: string;
    readonly address?: {
        readonly __typename: 'VerifiedIdVerifiableCredentialAddress';
        readonly streetAddress?: string | null;
        readonly locality?: string | null;
        readonly region?: string | null;
        readonly postalCode?: string | null;
        readonly country: string;
    } | null;
};

export type LoyaltyVerifiableCredentialFragment = {
    readonly __typename: 'LoyaltyVerifiableCredential';
    readonly id: string;
    readonly walletCardType: WalletCardType;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly issuerId: string;
    readonly issuerTitle: string;
    readonly issuerLogoUrl: string;
    readonly programId: string;
    readonly programTitle: string;
    readonly hexBackgroundColor: string;
    readonly termsAndConditionsUrl?: string | null;
    readonly possibleBarcodeNumber?: string | null;
    readonly possibleExpiresAt?: string | null;
};

export type ConnectedUserSimpleFragment = {
    readonly __typename: 'ConnectedUser';
    readonly id: string;
    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
    readonly hasPinned: boolean;
    readonly pinnedAt?: string | null;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type UserConnectionRequestFragment = {
    readonly __typename: 'UserConnectionRequest';
    readonly id: string;
    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
    readonly requestCode: string;
    readonly shareeEmail?: string | null;
    readonly isLinkShare: boolean;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly sharer: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type ConnectedUserSummaryFragment = {
    readonly __typename: 'ConnectedUser';
    readonly id: string;
    readonly isSharingReward: boolean;
    readonly isSharingSavedCourses: boolean;
    readonly isSharingTimetable: boolean;
    readonly isSharingAssessmentResults: boolean;
    readonly isSharingAttendance: boolean;
    readonly isSharingCurriculumVitae: boolean;
    readonly isSharingDailyNotices: boolean;
    readonly isSharingGoals: boolean;
    readonly isSharingPathwayPlanners: boolean;
    readonly isSharingProjects: boolean;
    readonly isSharingReflections: boolean;
    readonly isSharingStudentHomeworkDiary: boolean;
    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
    readonly hasPinned: boolean;
    readonly pinnedAt?: string | null;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly curriculumVitae?: { readonly __typename: 'CurriculumVitae'; readonly updatedAt: string } | null;
    readonly goals?: { readonly __typename: 'GoalConnection'; readonly totalCount: number; readonly lastUpdatedAt?: string | null } | null;
    readonly pathwayPlanners?: {
        readonly __typename: 'PathwayPlannerConnection';
        readonly totalCount: number;
        readonly lastUpdatedAt?: string | null;
    } | null;
    readonly projects?: {
        readonly __typename: 'ProjectConnection';
        readonly totalCount: number;
        readonly lastUpdatedAt?: string | null;
    } | null;
    readonly providerActivities: ReadonlyArray<{
        readonly __typename: 'ConnectedUserProviderActivity';
        readonly id: string;
        readonly providerTitle: string;
        readonly reward?: { readonly __typename: 'ConnectedUserReward'; readonly totalPoints: number } | null;
    }>;
    readonly reflections?: {
        readonly __typename: 'ReflectionConnection';
        readonly totalCount: number;
        readonly lastUpdatedAt?: string | null;
    } | null;
    readonly savedStudyProgrammes?: {
        readonly __typename: 'SavedStudyProgrammeConnection';
        readonly totalCount: number;
        readonly lastSavedAt?: string | null;
    } | null;
};

export type ConnectedUserRewardTransactionFragment = {
    readonly __typename: 'ConnectedUserRewardTransaction';
    readonly id: string;
    readonly type: ConnectedUserRewardTransactionType;
    readonly comment?: string | null;
    readonly points: number;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly rewardValue?: {
        readonly __typename: 'ConnectedUserRewardValue';
        readonly id: string;
        readonly title: string;
        readonly color: string;
    } | null;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type StudentHomeworkDiaryItemFragment = {
    readonly __typename: 'StudentHomeworkDiaryItem';
    readonly id: string;
    readonly date: string;
    readonly information?: string | null;
    readonly imageId?: string | null;
    readonly imageUrl?: string | null;
    readonly color?: string | null;
    readonly completedAt?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
};

export type StudentHomeworkDiaryMentorTaskFragment = {
    readonly __typename: 'StudentHomeworkDiaryMentorTask';
    readonly id: string;
    readonly date: string;
    readonly title: string;
    readonly information?: string | null;
    readonly imageId?: string | null;
    readonly imageUrl?: string | null;
    readonly color?: string | null;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly studentsAssigned: {
        readonly __typename: 'MentorStudentConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'MentorStudentEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'MentorStudent';
                readonly id: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
                readonly learnerSmsProfile?: {
                    readonly __typename: 'LearnerSmsProfile';
                    readonly id: string;
                    readonly pictureUrl: string;
                    readonly preferredGivenName: string;
                    readonly preferredFamilyName?: string | null;
                    readonly preferredFullName: string;
                    readonly email: string;
                    readonly idNumber: string;
                    readonly nationalCurriculumYear: string;
                } | null;
            } | null;
        }>;
    };
    readonly studentsCompleted: {
        readonly __typename: 'MentorStudentConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'MentorStudentEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'MentorStudent';
                readonly id: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
                readonly learnerSmsProfile?: {
                    readonly __typename: 'LearnerSmsProfile';
                    readonly id: string;
                    readonly pictureUrl: string;
                    readonly preferredGivenName: string;
                    readonly preferredFamilyName?: string | null;
                    readonly preferredFullName: string;
                    readonly email: string;
                    readonly idNumber: string;
                    readonly nationalCurriculumYear: string;
                } | null;
            } | null;
        }>;
    };
};

export type SponsorProviderDetailedFragment = {
    readonly __typename: 'SponsorProvider';
    readonly id: string;
    readonly title: string;
    readonly logoImageUrl: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
};

export type SponsorProviderSimpleFragment = {
    readonly __typename: 'SponsorProvider';
    readonly id: string;
    readonly title: string;
    readonly logoImageUrl: string;
};

export type SponsorAssetDetailedFragment = {
    readonly __typename: 'SponsorAsset';
    readonly id: string;
    readonly label?: string | null;
    readonly type: SponsorAssetType;
    readonly title?: string | null;
    readonly content?: string | null;
    readonly targetUrl: string;
    readonly mediaFileId: string;
    readonly mediaFileAvailable: boolean;
    readonly mediaFileUrl?: string | null;
    readonly mediaType: SponsorAssetMediaType;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly canUpdate: boolean;
    readonly canDelete: boolean;
    readonly sponsorProvider: {
        readonly __typename: 'SponsorProvider';
        readonly id: string;
        readonly title: string;
        readonly logoImageUrl: string;
    };
};

export type SponsorCampaignDetailedFragment = {
    readonly __typename: 'SponsorCampaign';
    readonly id: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly timezone: string;
    readonly status: SponsorCampaignStatus;
    readonly targetCountries: ReadonlyArray<string>;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly canPublish: boolean;
    readonly canUnpublish: boolean;
    readonly canDelete: boolean;
    readonly canUpdate: boolean;
    readonly sponsorProvider: {
        readonly __typename: 'SponsorProvider';
        readonly id: string;
        readonly title: string;
        readonly logoImageUrl: string;
    };
    readonly sponsorCampaignAssets: { readonly __typename: 'SponsorCampaignAssetConnection'; readonly totalCount: number };
};

export type SponsorCampaignSummaryFragment = {
    readonly __typename: 'SponsorCampaign';
    readonly id: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly status: SponsorCampaignStatus;
    readonly targetCountries: ReadonlyArray<string>;
    readonly sponsorProvider: {
        readonly __typename: 'SponsorProvider';
        readonly id: string;
        readonly title: string;
        readonly logoImageUrl: string;
    };
    readonly sponsorCampaignAssets: { readonly __typename: 'SponsorCampaignAssetConnection'; readonly totalCount: number };
};

export type SponsorCampaignWithoutAssetFragment = {
    readonly __typename: 'SponsorCampaign';
    readonly id: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly timezone: string;
    readonly status: SponsorCampaignStatus;
    readonly targetCountries: ReadonlyArray<string>;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly canPublish: boolean;
    readonly canUnpublish: boolean;
    readonly canDelete: boolean;
    readonly canUpdate: boolean;
    readonly sponsorProvider: {
        readonly __typename: 'SponsorProvider';
        readonly id: string;
        readonly title: string;
        readonly logoImageUrl: string;
    };
};

export type SponsorCampaignSimpleFragment = {
    readonly __typename: 'SponsorCampaign';
    readonly id: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly timezone: string;
    readonly status: SponsorCampaignStatus;
    readonly targetCountries: ReadonlyArray<string>;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly sponsorProvider: {
        readonly __typename: 'SponsorProvider';
        readonly id: string;
        readonly title: string;
        readonly logoImageUrl: string;
    };
};

export type SponsorCampaignAssetFragment = {
    readonly __typename: 'SponsorCampaignAsset';
    readonly id: string;
    readonly targetUser: SponsorTargetUserRole;
    readonly newsfeedPostIndex?: number | null;
    readonly startDate: string;
    readonly endDate: string;
    readonly totalViewsCount: number;
    readonly totalClicksCount: number;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly canDelete: boolean;
    readonly canUpdate: boolean;
    readonly sponsorAsset: {
        readonly __typename: 'SponsorAsset';
        readonly id: string;
        readonly label?: string | null;
        readonly type: SponsorAssetType;
        readonly title?: string | null;
        readonly content?: string | null;
        readonly targetUrl: string;
        readonly mediaFileId: string;
        readonly mediaFileAvailable: boolean;
        readonly mediaFileUrl?: string | null;
        readonly mediaType: SponsorAssetMediaType;
        readonly createdAt: string;
        readonly updatedAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly sponsorProvider: {
            readonly __typename: 'SponsorProvider';
            readonly id: string;
            readonly title: string;
            readonly logoImageUrl: string;
        };
    };
};

export type SponsorCampaignBannerFragment = {
    readonly __typename: 'SponsorCampaignBanner';
    readonly id: string;
    readonly sponsorCampaignId: string;
    readonly sponsorProviderTitle: string;
    readonly sponsorProviderLogoImageUrl: string;
    readonly title?: string | null;
    readonly content?: string | null;
    readonly targetUrl: string;
    readonly mediaImageUrl?: string | null;
    readonly mediaVideoUrl?: string | null;
};

export type ProviderMySchoolInformationItemFragment = {
    readonly __typename: 'ProviderMySchoolInformationItem';
    readonly id: string;
    readonly title: string;
    readonly information: string;
    readonly sequence: number;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type CareersRiasecResultFragment = {
    readonly __typename: 'CareersRiasecResult';
    readonly id: string;
    readonly interestCode: string;
    readonly interestRealistic: number;
    readonly interestInvestigative: number;
    readonly interestArtistic: number;
    readonly interestSocial: number;
    readonly interestEnterprising: number;
    readonly interestConventional: number;
    readonly updatedAt: string;
    readonly createdAt: string;
};

export type ProviderBadgeTemplateFragment = {
    readonly __typename: 'ProviderBadgeTemplate';
    readonly id: string;
    readonly title: string;
    readonly description: string;
    readonly badgeImageId: string;
    readonly badgeImageUrl: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canDelete: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
};

export type BadgeFragment = {
    readonly __typename: 'Badge';
    readonly id: string;
    readonly title: string;
    readonly comment: string;
    readonly badgeImageUrl: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly canDelete: boolean;
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly createdBy: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
};

export type MentorBadgeStudentDetailedFragment = {
    readonly __typename: 'MentorBadgeStudent';
    readonly id: string;
    readonly canCreateBadge: boolean;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly user: {
        readonly __typename: 'User';
        readonly id: string;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
    };
    readonly learnerSmsProfile?: {
        readonly __typename: 'LearnerSmsProfile';
        readonly id: string;
        readonly pictureUrl: string;
        readonly preferredGivenName: string;
        readonly preferredFamilyName?: string | null;
        readonly preferredFullName: string;
        readonly email: string;
        readonly idNumber: string;
        readonly nationalCurriculumYear: string;
    } | null;
};

export type MentorStudentInviteFragment = {
    readonly __typename: 'MentorStudentInvite';
    readonly id: string;
    readonly email: string;
    readonly redeemedAt?: string | null;
    readonly expiresAt: string;
    readonly createdAt: string;
    readonly provider: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
    };
    readonly mentorGroups: ReadonlyArray<{
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly bannerImageUrl?: string | null;
        readonly information?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    }>;
};

export type LogEventsMutationVariables = Exact<{
    input: LogEventsInput;
}>;

export type LogEventsMutation = {
    readonly __typename: 'Mutation';
    readonly logEvents: { readonly __typename: 'LogEventsPayload'; readonly eventsLogged: number };
};

export type ProviderMentorSessionsSummaryAnalyticsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type ProviderMentorSessionsSummaryAnalyticsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorSessionsSummaryAnalytics: {
            readonly __typename: 'MentorSessionsSummaryConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorSessionsSummaryEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorSessionsSummary';
                    readonly sessionCount: number;
                    readonly sessionDuration: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type CreateBadgeMutationVariables = Exact<{
    input: CreateBadgeInput;
}>;

export type CreateBadgeMutation = {
    readonly __typename: 'Mutation';
    readonly createBadge: {
        readonly __typename: 'CreateBadgePayload';
        readonly badge: {
            readonly __typename: 'Badge';
            readonly id: string;
            readonly title: string;
            readonly comment: string;
            readonly badgeImageUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateProviderBadgeTemplateMutationVariables = Exact<{
    input: CreateProviderBadgeTemplateInput;
}>;

export type CreateProviderBadgeTemplateMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderBadgeTemplate: {
        readonly __typename: 'CreateProviderBadgeTemplatePayload';
        readonly providerBadgeTemplate: {
            readonly __typename: 'ProviderBadgeTemplate';
            readonly id: string;
            readonly title: string;
            readonly description: string;
            readonly badgeImageId: string;
            readonly badgeImageUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canDelete: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type DeleteBadgeMutationVariables = Exact<{
    input: DeleteBadgeInput;
}>;

export type DeleteBadgeMutation = {
    readonly __typename: 'Mutation';
    readonly deleteBadge: { readonly __typename: 'DeleteBadgePayload'; readonly badgeId: string };
};

export type DeleteProviderBadgeTemplateMutationVariables = Exact<{
    input: DeleteProviderBadgeTemplateInput;
}>;

export type DeleteProviderBadgeTemplateMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderBadgeTemplate: {
        readonly __typename: 'DeleteProviderBadgeTemplatePayload';
        readonly providerBadgeTemplateId: string;
    };
};

export type MentorBadgeStudentQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    currentProviderOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MentorBadgeStudentQuery = {
    readonly __typename: 'Query';
    readonly mentorBadgeStudent?: {
        readonly __typename: 'MentorBadgeStudent';
        readonly id: string;
        readonly canCreateBadge: boolean;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly learnerSmsProfile?: {
            readonly __typename: 'LearnerSmsProfile';
            readonly id: string;
            readonly pictureUrl: string;
            readonly preferredGivenName: string;
            readonly preferredFamilyName?: string | null;
            readonly preferredFullName: string;
            readonly email: string;
            readonly idNumber: string;
            readonly nationalCurriculumYear: string;
        } | null;
        readonly badges: {
            readonly __typename: 'BadgeConnection';
            readonly totalCount: number;
            readonly lastCreatedAt?: string | null;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'BadgeEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorBadgeStudentsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    currentProviderOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MentorBadgeStudentsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorBadgeStudents: {
            readonly __typename: 'MentorBadgeStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorBadgeStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorBadgeStudent';
                    readonly id: string;
                    readonly canCreateBadge: boolean;
                    readonly badges: { readonly __typename: 'BadgeConnection'; readonly totalCount: number };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                } | null;
            }>;
        };
    } | null;
};

export type MentorGroupStudentsBadgeQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupStudentsBadgeQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly students: {
            readonly __typename: 'MentorGroupStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupStudent';
                    readonly mentorStudent: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                        readonly badges: { readonly __typename: 'BadgeConnection'; readonly totalCount: number };
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorStudentBadgePermissionQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type MentorStudentBadgePermissionQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: { readonly __typename: 'MentorStudent'; readonly id: string; readonly canCreateBadge: boolean } | null;
};

export type MentorStudentBadgeQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    currentProviderOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MentorStudentBadgeQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly badges: {
            readonly __typename: 'BadgeConnection';
            readonly totalCount: number;
            readonly lastCreatedAt?: string | null;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'BadgeEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MyBadgesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyBadgesQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly badges: {
            readonly __typename: 'BadgeConnection';
            readonly totalCount: number;
            readonly lastCreatedAt?: string | null;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'BadgeEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type ProviderBadgeTemplatesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProviderBadgeTemplatesQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly providerBadgeTemplates: {
            readonly __typename: 'ProviderBadgeTemplateConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProviderBadgeTemplateEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'ProviderBadgeTemplate';
                    readonly id: string;
                    readonly title: string;
                    readonly description: string;
                    readonly badgeImageId: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type CalendarsQueryVariables = Exact<{
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type CalendarsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly calendars: ReadonlyArray<{
            readonly __typename: 'Calendar';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly priority: number;
            readonly events: ReadonlyArray<{
                readonly __typename: 'CalendarEvent';
                readonly id: string;
                readonly dateType: CalendarEventDateType;
                readonly start: string;
                readonly end?: string | null;
                readonly summary: string;
                readonly location?: string | null;
                readonly description?: string | null;
            }>;
        }>;
    };
};

export type AcceptUserConnectionRequestMutationVariables = Exact<{
    input: AcceptUserConnectionRequestInput;
}>;

export type AcceptUserConnectionRequestMutation = {
    readonly __typename: 'Mutation';
    readonly acceptUserConnectionRequest: {
        readonly __typename: 'AcceptUserConnectionRequestPayload';
        readonly userConnectionRequestId: string;
        readonly connectedUser: {
            readonly __typename: 'ConnectedUser';
            readonly id: string;
            readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
            readonly hasPinned: boolean;
            readonly pinnedAt?: string | null;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type ConnectedUserAssessmentResultsQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
}>;

export type ConnectedUserAssessmentResultsQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly assessmentResultsList?: ReadonlyArray<{
                readonly __typename: 'AssessmentResult';
                readonly id: string;
                readonly type: AssessmentResultType;
                readonly number: string;
                readonly version: string;
                readonly level?: number | null;
                readonly credits?: number | null;
                readonly weighting?: number | null;
                readonly points?: number | null;
                readonly title?: string | null;
                readonly description?: string | null;
                readonly purpose?: string | null;
                readonly subField?: string | null;
                readonly date?: string | null;
                readonly subject?: string | null;
                readonly result?: string | null;
                readonly comment?: string | null;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            }> | null;
        } | null;
    };
};

export type ConnectedUserAttendanceSummaryQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type ConnectedUserAttendanceSummaryQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly attendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
        } | null;
    };
};

export type ConnectedUserAttendanceYearSummaryQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
    year: Scalars['Int']['input'];
}>;

export type ConnectedUserAttendanceYearSummaryQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly januaryAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly februaryAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly marchAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly aprilAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly mayAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly juneAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly julyAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly augustAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly septemberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly octoberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly novemberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly decemberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
        } | null;
    };
};

export type ConnectedUserCurriculumVitaeQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
}>;

export type ConnectedUserCurriculumVitaeQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly curriculumVitae?: {
            readonly __typename: 'CurriculumVitae';
            readonly id: string;
            readonly profileImageId?: string | null;
            readonly profileImageUrl?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly personalDetails: {
                readonly __typename: 'CurriculumVitaePersonalDetails';
                readonly givenName?: string | null;
                readonly familyName?: string | null;
                readonly dob?: string | null;
                readonly languageLocales?: ReadonlyArray<string> | null;
                readonly interests?: string | null;
                readonly driversLicenceType?: DriversLicenceType | null;
                readonly phoneNumber?: string | null;
                readonly email?: string | null;
                readonly street?: string | null;
                readonly suburb?: string | null;
                readonly city?: string | null;
                readonly countryCode?: string | null;
            };
            readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
            readonly employment: {
                readonly __typename: 'CurriculumVitaeEmployment';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEmploymentEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly volunteer: {
                readonly __typename: 'CurriculumVitaeVolunteer';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeVolunteerEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly education: {
                readonly __typename: 'CurriculumVitaeEducation';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEducationEntry';
                    readonly title: string;
                    readonly school: string;
                    readonly year: string;
                    readonly subjects?: ReadonlyArray<string> | null;
                }> | null;
            };
            readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
            readonly personalAttributes: {
                readonly __typename: 'CurriculumVitaePersonalAttributes';
                readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
            };
            readonly achievementsAndAwards: {
                readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
                    readonly description: string;
                    readonly year: string;
                }> | null;
            };
            readonly badges: {
                readonly __typename: 'CurriculumVitaeBadges';
                readonly selectedBadgesList: ReadonlyArray<{
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly resourceHubItems: {
                readonly __typename: 'CurriculumVitaeResourceHubItems';
                readonly selectedResourceHubItemsList: ReadonlyArray<{
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly extracurricularActivities: {
                readonly __typename: 'CurriculumVitaeExtracurricularActivities';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
                    readonly description: string;
                    readonly year: string;
                    readonly toYear?: string | null;
                }> | null;
            };
            readonly references: {
                readonly __typename: 'CurriculumVitaeReferences';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeReferenceEntry';
                    readonly name: string;
                    readonly company: string;
                    readonly position: string;
                    readonly email: string;
                    readonly phoneNumber: string;
                }> | null;
            };
            readonly generatedFiles: ReadonlyArray<{
                readonly __typename: 'CurriculumVitaeGeneratedFile';
                readonly id: string;
                readonly fileId: string;
                readonly fileUrl: string;
                readonly generatedAt: string;
            }>;
        } | null;
    };
};

export type ConnectedUserDailyNoticesQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type ConnectedUserDailyNoticesQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly dailyNoticesList?: ReadonlyArray<{
                readonly __typename: 'DailyNotice';
                readonly id: string;
                readonly color: string;
                readonly level: string;
                readonly start: string;
                readonly end: string;
                readonly subject: string;
                readonly body?: string | null;
                readonly teacher?: string | null;
                readonly meetingDate?: string | null;
                readonly meetingPlace?: string | null;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            }> | null;
        } | null;
    };
};

export type ConnectedUserGoalsQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectedUserGoalsQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly goals?: {
            readonly __typename: 'GoalConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'GoalEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Goal';
                    readonly id: string;
                    readonly goalType: GoalType;
                    readonly title: string;
                    readonly imageUrl?: string | null;
                    readonly imageId?: string | null;
                    readonly information: string;
                    readonly complete: boolean;
                    readonly dueAt?: string | null;
                    readonly color?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly canCreateTask: boolean;
                    readonly canCreateNote: boolean;
                    readonly tasks: ReadonlyArray<{
                        readonly __typename: 'GoalTask';
                        readonly id: string;
                        readonly information: string;
                        readonly complete: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    }>;
                    readonly notes: ReadonlyArray<{
                        readonly __typename: 'GoalNote';
                        readonly id: string;
                        readonly information: string;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    }>;
                } | null;
            }>;
        } | null;
    };
};

export type ConnectedUserPathwayPlannersQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectedUserPathwayPlannersQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly pathwayPlanners?: {
            readonly __typename: 'PathwayPlannerConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'PathwayPlanner';
                    readonly id: string;
                    readonly title: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly usefulExperienceList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly qualificationsList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerQualificationTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly personalRequirementsList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly skillsAndKnowledgeList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly stagesList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerStage';
                        readonly id: string;
                        readonly title: string;
                        readonly usefulExperienceList?: ReadonlyArray<{
                            readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                            readonly id: string;
                            readonly completed: boolean;
                            readonly title: string;
                        }> | null;
                        readonly subjectsList?: ReadonlyArray<{
                            readonly __typename: 'PathwayPlannerStageSubjectTodo';
                            readonly id: string;
                            readonly completed: boolean;
                            readonly title: string;
                        }> | null;
                    }> | null;
                } | null;
            }>;
        } | null;
    };
};

export type ConnectedUserProjectsQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectedUserProjectsQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly projects?: {
            readonly __typename: 'ProjectConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProjectEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Project';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly bannerImageId?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly totalCompletedProjectSections: number;
                    readonly totalProjectSections: number;
                    readonly latestReviewRequestedAt?: string | null;
                    readonly completedAt?: string | null;
                    readonly canUpdateMentorGroup: boolean;
                    readonly canDelete: boolean;
                    readonly isLocked: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        } | null;
    };
};

export type ConnectedUserReflectionsQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectedUserReflectionsQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly reflections?: {
            readonly __typename: 'ReflectionConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ReflectionEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Reflection';
                    readonly id: string;
                    readonly reflectionType: ReflectionType;
                    readonly information: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                } | null;
            }>;
        } | null;
    };
};

export type ConnectedUserRewardsQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectedUserRewardsQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly reward?: {
                readonly __typename: 'ConnectedUserReward';
                readonly totalPoints: number;
                readonly availablePoints: number;
                readonly transactions: {
                    readonly __typename: 'ConnectedUserRewardTransactionConnection';
                    readonly totalCount: number;
                    readonly pageInfo: {
                        readonly __typename: 'PageInfo';
                        readonly hasPreviousPage: boolean;
                        readonly hasNextPage: boolean;
                        readonly startCursor?: string | null;
                        readonly endCursor?: string | null;
                    };
                    readonly edges: ReadonlyArray<{
                        readonly __typename: 'ConnectedUserRewardTransactionEdge';
                        readonly cursor: string;
                        readonly node?: {
                            readonly __typename: 'ConnectedUserRewardTransaction';
                            readonly id: string;
                            readonly type: ConnectedUserRewardTransactionType;
                            readonly comment?: string | null;
                            readonly points: number;
                            readonly updatedAt: string;
                            readonly createdAt: string;
                            readonly rewardValue?: {
                                readonly __typename: 'ConnectedUserRewardValue';
                                readonly id: string;
                                readonly title: string;
                                readonly color: string;
                            } | null;
                            readonly createdBy: {
                                readonly __typename: 'User';
                                readonly id: string;
                                readonly pictureUrl?: string | null;
                                readonly fullName: string;
                                readonly givenName: string;
                                readonly familyName?: string | null;
                                readonly educationProvider?: {
                                    readonly __typename: 'Provider';
                                    readonly id: string;
                                    readonly title: string;
                                } | null;
                            };
                        } | null;
                    }>;
                };
            } | null;
        } | null;
    };
};

export type ConnectedUserSavedStudyProgrammesQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectedUserSavedStudyProgrammesQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly savedStudyProgrammes?: {
            readonly __typename: 'SavedStudyProgrammeConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'SavedStudyProgrammeEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'StudyProgramme';
                    readonly id: string;
                    readonly title: string;
                    readonly link: string;
                    readonly studyProvider: {
                        readonly __typename: 'StudyProvider';
                        readonly id: string;
                        readonly countryCode: string;
                        readonly title: string;
                        readonly logoImageUrl?: string | null;
                        readonly isEnquiriesEnabled: boolean;
                        readonly isEnrolmentEnabled: boolean;
                        readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    };
                } | null;
            }>;
        } | null;
    };
};

export type ConnectedUserStudentHomeworkDiaryDailyItemsQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectedUserStudentHomeworkDiaryDailyItemsQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly studentHomeworkDiaryDailyItems?: {
                readonly __typename: 'StudentHomeworkDiaryConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'StudentHomeworkDiaryEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'StudentHomeworkDiaryItem';
                        readonly id: string;
                        readonly date: string;
                        readonly information?: string | null;
                        readonly imageId?: string | null;
                        readonly imageUrl?: string | null;
                        readonly color?: string | null;
                        readonly completedAt?: string | null;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                    } | null;
                }>;
            } | null;
        } | null;
    };
};

export type ConnectedUserStudentHomeworkDiaryMonthSummaryQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
    date: Scalars['Date']['input'];
}>;

export type ConnectedUserStudentHomeworkDiaryMonthSummaryQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly studentHomeworkDiaryMonthSummary: {
                readonly __typename: 'StudentHomeworkDiaryMonthSummary';
                readonly completed: number;
                readonly total: number;
                readonly colors: ReadonlyArray<string | null>;
                readonly days: ReadonlyArray<{
                    readonly __typename: 'StudentHomeworkDiaryDaySummary';
                    readonly date: string;
                    readonly completed: number;
                    readonly total: number;
                    readonly colors: ReadonlyArray<string | null>;
                }>;
            };
        } | null;
    };
};

export type ConnectedUserTimetableEventsQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
    providerId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type ConnectedUserTimetableEventsQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly providerActivity?: {
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly timetableEventsList?: ReadonlyArray<{
                readonly __typename: 'TimetableEvent';
                readonly id: string;
                readonly color: string;
                readonly priority: number;
                readonly dateType: TimetableEventDateType;
                readonly start: string;
                readonly end?: string | null;
                readonly summary: string;
                readonly location?: string | null;
                readonly description?: string | null;
            }> | null;
        } | null;
    };
};

export type ConnectionSummaryQueryVariables = Exact<{
    connectedUserId: Scalars['UUID']['input'];
}>;

export type ConnectionSummaryQuery = {
    readonly __typename: 'Query';
    readonly connectedUser: {
        readonly __typename: 'ConnectedUser';
        readonly id: string;
        readonly isSharingReward: boolean;
        readonly isSharingSavedCourses: boolean;
        readonly isSharingTimetable: boolean;
        readonly isSharingAssessmentResults: boolean;
        readonly isSharingAttendance: boolean;
        readonly isSharingCurriculumVitae: boolean;
        readonly isSharingDailyNotices: boolean;
        readonly isSharingGoals: boolean;
        readonly isSharingPathwayPlanners: boolean;
        readonly isSharingProjects: boolean;
        readonly isSharingReflections: boolean;
        readonly isSharingStudentHomeworkDiary: boolean;
        readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
        readonly hasPinned: boolean;
        readonly pinnedAt?: string | null;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly curriculumVitae?: { readonly __typename: 'CurriculumVitae'; readonly updatedAt: string } | null;
        readonly goals?: {
            readonly __typename: 'GoalConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly pathwayPlanners?: {
            readonly __typename: 'PathwayPlannerConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly projects?: {
            readonly __typename: 'ProjectConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly providerActivities: ReadonlyArray<{
            readonly __typename: 'ConnectedUserProviderActivity';
            readonly id: string;
            readonly providerTitle: string;
            readonly reward?: { readonly __typename: 'ConnectedUserReward'; readonly totalPoints: number } | null;
        }>;
        readonly reflections?: {
            readonly __typename: 'ReflectionConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly savedStudyProgrammes?: {
            readonly __typename: 'SavedStudyProgrammeConnection';
            readonly totalCount: number;
            readonly lastSavedAt?: string | null;
        } | null;
    };
};

export type MyConnectedUsersQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyConnectedUsersQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly connectedUsers: {
            readonly __typename: 'ConnectedUserConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ConnectedUserEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'ConnectedUser';
                    readonly id: string;
                    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
                    readonly hasPinned: boolean;
                    readonly pinnedAt?: string | null;
                    readonly createdAt: string;
                    readonly updatedAt: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type RejectUserConnectionRequestMutationVariables = Exact<{
    input: RejectUserConnectionRequestInput;
}>;

export type RejectUserConnectionRequestMutation = {
    readonly __typename: 'Mutation';
    readonly rejectUserConnectionRequest: {
        readonly __typename: 'RejectUserConnectionRequestPayload';
        readonly userConnectionRequestId: string;
    };
};

export type RemoveConnectedUserMutationVariables = Exact<{
    input: RemoveConnectedUserInput;
}>;

export type RemoveConnectedUserMutation = {
    readonly __typename: 'Mutation';
    readonly removeConnectedUser: { readonly __typename: 'RemoveConnectedUserPayload'; readonly connectedUserId: string };
};

export type RequestUserConnectionMutationVariables = Exact<{
    input: RequestUserConnectionInput;
}>;

export type RequestUserConnectionMutation = {
    readonly __typename: 'Mutation';
    readonly requestUserConnection: {
        readonly __typename: 'RequestUserConnectionPayload';
        readonly userConnectionRequest: {
            readonly __typename: 'UserConnectionRequest';
            readonly id: string;
            readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
            readonly requestCode: string;
            readonly shareeEmail?: string | null;
            readonly isLinkShare: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly sharer: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type TogglePinnedConnectionMutationVariables = Exact<{
    input: TogglePinnedConnectionInput;
}>;

export type TogglePinnedConnectionMutation = {
    readonly __typename: 'Mutation';
    readonly togglePinnedConnection: {
        readonly __typename: 'TogglePinnedConnectionPayload';
        readonly connectedUser: {
            readonly __typename: 'ConnectedUser';
            readonly id: string;
            readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
            readonly hasPinned: boolean;
            readonly pinnedAt?: string | null;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateConnectedUserActivitySharingMutationVariables = Exact<{
    input: UpdateConnectedUserActivitySharingInput;
}>;

export type UpdateConnectedUserActivitySharingMutation = {
    readonly __typename: 'Mutation';
    readonly updateConnectedUserActivitySharing: {
        readonly __typename: 'UpdateConnectedUserActivitySharingPayload';
        readonly connectedUser: {
            readonly __typename: 'ConnectedUser';
            readonly id: string;
            readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
            readonly hasPinned: boolean;
            readonly pinnedAt?: string | null;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UserConnectionRequestsCountQueryVariables = Exact<{ [key: string]: never }>;

export type UserConnectionRequestsCountQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly connectionRequests: { readonly __typename: 'UserConnectionRequestConnection'; readonly totalCount: number };
    };
};

export type UserConnectionRequestsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserConnectionRequestsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly connectionRequests: {
            readonly __typename: 'UserConnectionRequestConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'UserConnectionRequestEdge';
                readonly node?: {
                    readonly __typename: 'UserConnectionRequest';
                    readonly id: string;
                    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
                    readonly requestCode: string;
                    readonly shareeEmail?: string | null;
                    readonly isLinkShare: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly sharer: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type UserConnectionRequestsSentQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserConnectionRequestsSentQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly connectionRequestsSent: {
            readonly __typename: 'UserConnectionRequestConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'UserConnectionRequestEdge';
                readonly node?: {
                    readonly __typename: 'UserConnectionRequest';
                    readonly id: string;
                    readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
                    readonly requestCode: string;
                    readonly shareeEmail?: string | null;
                    readonly isLinkShare: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly sharer: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type ValidateUserConnectionRequestMutationVariables = Exact<{
    input: ValidateUserConnectionRequestInput;
}>;

export type ValidateUserConnectionRequestMutation = {
    readonly __typename: 'Mutation';
    readonly validateUserConnectionRequest: {
        readonly __typename: 'ValidateUserConnectionRequestPayload';
        readonly userConnectionRequest: {
            readonly __typename: 'UserConnectionRequest';
            readonly id: string;
            readonly sharedActivity: ReadonlyArray<ConnectedUserSharedActivityType>;
            readonly requestCode: string;
            readonly shareeEmail?: string | null;
            readonly isLinkShare: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly sharer: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateStudyCategoryMutationVariables = Exact<{
    input: CreateStudyCategoryInput;
}>;

export type CreateStudyCategoryMutation = {
    readonly __typename: 'Mutation';
    readonly createStudyCategory: {
        readonly __typename: 'CreateStudyCategoryPayload';
        readonly studyCategory: {
            readonly __typename: 'StudyCategory';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type CreateStudyProgrammeMutationVariables = Exact<{
    input: CreateStudyProgrammeInput;
}>;

export type CreateStudyProgrammeMutation = {
    readonly __typename: 'Mutation';
    readonly createStudyProgramme: {
        readonly __typename: 'CreateStudyProgrammePayload';
        readonly studyProgramme: {
            readonly __typename: 'StudyProgramme';
            readonly id: string;
            readonly studyProgrammeType: StudyProgrammeType;
            readonly title: string;
            readonly link: string;
            readonly information: string;
            readonly hasSavedStudyProgramme: boolean;
            readonly studyCountryCodes: ReadonlyArray<string>;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canEnquireStudyProgramme: boolean;
            readonly canEnrolInStudyProgramme: boolean;
            readonly studyCategory: {
                readonly __typename: 'StudyCategory';
                readonly id: string;
                readonly title: string;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
            readonly studyProvider: {
                readonly __typename: 'StudyProvider';
                readonly id: string;
                readonly countryCode: string;
                readonly title: string;
                readonly logoImageUrl?: string | null;
                readonly isEnquiriesEnabled: boolean;
                readonly isEnrolmentEnabled: boolean;
                readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
        };
    };
};

export type CreateStudyProviderMutationVariables = Exact<{
    input: CreateStudyProviderInput;
}>;

export type CreateStudyProviderMutation = {
    readonly __typename: 'Mutation';
    readonly createStudyProvider: {
        readonly __typename: 'CreateStudyProviderPayload';
        readonly studyProvider: {
            readonly __typename: 'StudyProvider';
            readonly id: string;
            readonly countryCode: string;
            readonly title: string;
            readonly logoImageUrl?: string | null;
            readonly logoImageId?: string | null;
            readonly email?: string | null;
            readonly isEnquiriesEnabled: boolean;
            readonly isEnrolmentEnabled: boolean;
            readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type DeleteStudyCategoryMutationVariables = Exact<{
    input: DeleteStudyCategoryInput;
}>;

export type DeleteStudyCategoryMutation = {
    readonly __typename: 'Mutation';
    readonly deleteStudyCategory: { readonly __typename: 'DeleteStudyCategoryPayload'; readonly studyCategoryId: string };
};

export type DeleteStudyProgrammeMutationVariables = Exact<{
    input: DeleteStudyProgrammeInput;
}>;

export type DeleteStudyProgrammeMutation = {
    readonly __typename: 'Mutation';
    readonly deleteStudyProgramme: { readonly __typename: 'DeleteStudyProgrammePayload'; readonly studyProgrammeId: string };
};

export type DeleteStudyProviderMutationVariables = Exact<{
    input: DeleteStudyProviderInput;
}>;

export type DeleteStudyProviderMutation = {
    readonly __typename: 'Mutation';
    readonly deleteStudyProvider: { readonly __typename: 'DeleteStudyProviderPayload'; readonly studyProviderId: string };
};

export type EnquireStudyProgrammeMutationVariables = Exact<{
    input: EnquireStudyProgrammeInput;
}>;

export type EnquireStudyProgrammeMutation = {
    readonly __typename: 'Mutation';
    readonly enquireStudyProgramme: { readonly __typename: 'EnquireStudyProgrammePayload'; readonly enquirySent: boolean };
};

export type EnrolInStudyProgrammeMutationVariables = Exact<{
    input: EnrolInStudyProgrammeInput;
}>;

export type EnrolInStudyProgrammeMutation = {
    readonly __typename: 'Mutation';
    readonly enrolInStudyProgramme: { readonly __typename: 'EnrolInStudyProgrammePayload'; readonly enrolmentSent: boolean };
};

export type MySavedStudyProgrammesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MySavedStudyProgrammesQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly savedStudyProgrammes: {
            readonly __typename: 'SavedStudyProgrammeConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'SavedStudyProgrammeEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'StudyProgramme';
                    readonly id: string;
                    readonly studyProgrammeType: StudyProgrammeType;
                    readonly title: string;
                    readonly link: string;
                    readonly information: string;
                    readonly hasSavedStudyProgramme: boolean;
                    readonly studyCountryCodes: ReadonlyArray<string>;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canEnquireStudyProgramme: boolean;
                    readonly canEnrolInStudyProgramme: boolean;
                    readonly studyCategory: {
                        readonly __typename: 'StudyCategory';
                        readonly id: string;
                        readonly title: string;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    };
                    readonly studyProvider: {
                        readonly __typename: 'StudyProvider';
                        readonly id: string;
                        readonly countryCode: string;
                        readonly title: string;
                        readonly logoImageUrl?: string | null;
                        readonly isEnquiriesEnabled: boolean;
                        readonly isEnrolmentEnabled: boolean;
                        readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    };
                } | null;
            }>;
        };
    };
};

export type SaveStudyProgrammeMutationVariables = Exact<{
    input: SaveStudyProgrammeInput;
}>;

export type SaveStudyProgrammeMutation = {
    readonly __typename: 'Mutation';
    readonly saveStudyProgramme: {
        readonly __typename: 'SaveStudyProgrammePayload';
        readonly studyProgramme: {
            readonly __typename: 'StudyProgramme';
            readonly id: string;
            readonly studyProgrammeType: StudyProgrammeType;
            readonly title: string;
            readonly link: string;
            readonly information: string;
            readonly hasSavedStudyProgramme: boolean;
            readonly studyCountryCodes: ReadonlyArray<string>;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canEnquireStudyProgramme: boolean;
            readonly canEnrolInStudyProgramme: boolean;
            readonly studyCategory: {
                readonly __typename: 'StudyCategory';
                readonly id: string;
                readonly title: string;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
            readonly studyProvider: {
                readonly __typename: 'StudyProvider';
                readonly id: string;
                readonly countryCode: string;
                readonly title: string;
                readonly logoImageUrl?: string | null;
                readonly isEnquiriesEnabled: boolean;
                readonly isEnrolmentEnabled: boolean;
                readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
        };
    };
};

export type StudyCategoriesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type StudyCategoriesQuery = {
    readonly __typename: 'Query';
    readonly studyCategories: {
        readonly __typename: 'StudyCategoryConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'StudyCategoryEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'StudyCategory';
                readonly id: string;
                readonly title: string;
                readonly updatedAt: string;
                readonly createdAt: string;
            } | null;
        }>;
    };
};

export type StudyCategoryQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type StudyCategoryQuery = {
    readonly __typename: 'Query';
    readonly studyCategory?: {
        readonly __typename: 'StudyCategory';
        readonly id: string;
        readonly title: string;
        readonly updatedAt: string;
        readonly createdAt: string;
    } | null;
};

export type StudyProgrammeQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type StudyProgrammeQuery = {
    readonly __typename: 'Query';
    readonly studyProgramme?: {
        readonly __typename: 'StudyProgramme';
        readonly id: string;
        readonly studyProgrammeType: StudyProgrammeType;
        readonly title: string;
        readonly link: string;
        readonly information: string;
        readonly hasSavedStudyProgramme: boolean;
        readonly studyCountryCodes: ReadonlyArray<string>;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canEnquireStudyProgramme: boolean;
        readonly canEnrolInStudyProgramme: boolean;
        readonly studyCategory: {
            readonly __typename: 'StudyCategory';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
        readonly studyProvider: {
            readonly __typename: 'StudyProvider';
            readonly id: string;
            readonly countryCode: string;
            readonly title: string;
            readonly logoImageUrl?: string | null;
            readonly isEnquiriesEnabled: boolean;
            readonly isEnrolmentEnabled: boolean;
            readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    } | null;
};

export type StudyProgrammesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    studyProviderId?: InputMaybe<Scalars['UUID']['input']>;
    studyCategoryId?: InputMaybe<Scalars['UUID']['input']>;
    studyCountryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
    isEnquiriesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    forLandingList?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type StudyProgrammesQuery = {
    readonly __typename: 'Query';
    readonly studyProgrammes: {
        readonly __typename: 'StudyProgrammeConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'StudyProgrammeEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'StudyProgramme';
                readonly id: string;
                readonly studyProgrammeType: StudyProgrammeType;
                readonly title: string;
                readonly link: string;
                readonly information: string;
                readonly hasSavedStudyProgramme: boolean;
                readonly studyCountryCodes: ReadonlyArray<string>;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canEnquireStudyProgramme: boolean;
                readonly canEnrolInStudyProgramme: boolean;
                readonly studyCategory: {
                    readonly __typename: 'StudyCategory';
                    readonly id: string;
                    readonly title: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                };
                readonly studyProvider: {
                    readonly __typename: 'StudyProvider';
                    readonly id: string;
                    readonly countryCode: string;
                    readonly title: string;
                    readonly logoImageUrl?: string | null;
                    readonly isEnquiriesEnabled: boolean;
                    readonly isEnrolmentEnabled: boolean;
                    readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                };
            } | null;
        }>;
    };
};

export type StudyProviderForSuperadminQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type StudyProviderForSuperadminQuery = {
    readonly __typename: 'Query';
    readonly studyProvider?: {
        readonly __typename: 'StudyProvider';
        readonly id: string;
        readonly countryCode: string;
        readonly title: string;
        readonly logoImageUrl?: string | null;
        readonly logoImageId?: string | null;
        readonly email?: string | null;
        readonly isEnquiriesEnabled: boolean;
        readonly isEnrolmentEnabled: boolean;
        readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
        readonly updatedAt: string;
        readonly createdAt: string;
    } | null;
};

export type StudyProviderQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type StudyProviderQuery = {
    readonly __typename: 'Query';
    readonly studyProvider?: {
        readonly __typename: 'StudyProvider';
        readonly id: string;
        readonly countryCode: string;
        readonly title: string;
        readonly logoImageUrl?: string | null;
        readonly isEnquiriesEnabled: boolean;
        readonly isEnrolmentEnabled: boolean;
        readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
        readonly updatedAt: string;
        readonly createdAt: string;
    } | null;
};

export type StudyProvidersForSuperadminQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    studyCountryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type StudyProvidersForSuperadminQuery = {
    readonly __typename: 'Query';
    readonly studyProviders: {
        readonly __typename: 'StudyProviderConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'StudyProviderEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'StudyProvider';
                readonly id: string;
                readonly countryCode: string;
                readonly title: string;
                readonly logoImageUrl?: string | null;
                readonly logoImageId?: string | null;
                readonly email?: string | null;
                readonly isEnquiriesEnabled: boolean;
                readonly isEnrolmentEnabled: boolean;
                readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                readonly updatedAt: string;
                readonly createdAt: string;
            } | null;
        }>;
    };
};

export type StudyProvidersQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    studyCountryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type StudyProvidersQuery = {
    readonly __typename: 'Query';
    readonly studyProviders: {
        readonly __typename: 'StudyProviderConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'StudyProviderEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'StudyProvider';
                readonly id: string;
                readonly countryCode: string;
                readonly title: string;
                readonly logoImageUrl?: string | null;
                readonly isEnquiriesEnabled: boolean;
                readonly isEnrolmentEnabled: boolean;
                readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                readonly updatedAt: string;
                readonly createdAt: string;
            } | null;
        }>;
    };
};

export type UnsaveStudyProgrammeMutationVariables = Exact<{
    input: UnsaveStudyProgrammeInput;
}>;

export type UnsaveStudyProgrammeMutation = {
    readonly __typename: 'Mutation';
    readonly unsaveStudyProgramme: {
        readonly __typename: 'UnsaveStudyProgrammePayload';
        readonly studyProgramme: {
            readonly __typename: 'StudyProgramme';
            readonly id: string;
            readonly studyProgrammeType: StudyProgrammeType;
            readonly title: string;
            readonly link: string;
            readonly information: string;
            readonly hasSavedStudyProgramme: boolean;
            readonly studyCountryCodes: ReadonlyArray<string>;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canEnquireStudyProgramme: boolean;
            readonly canEnrolInStudyProgramme: boolean;
            readonly studyCategory: {
                readonly __typename: 'StudyCategory';
                readonly id: string;
                readonly title: string;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
            readonly studyProvider: {
                readonly __typename: 'StudyProvider';
                readonly id: string;
                readonly countryCode: string;
                readonly title: string;
                readonly logoImageUrl?: string | null;
                readonly isEnquiriesEnabled: boolean;
                readonly isEnrolmentEnabled: boolean;
                readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
        };
    };
};

export type UpdateStudyCategoryMutationVariables = Exact<{
    input: UpdateStudyCategoryInput;
}>;

export type UpdateStudyCategoryMutation = {
    readonly __typename: 'Mutation';
    readonly updateStudyCategory: {
        readonly __typename: 'UpdateStudyCategoryPayload';
        readonly studyCategory: {
            readonly __typename: 'StudyCategory';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type UpdateStudyProgrammeMutationVariables = Exact<{
    input: UpdateStudyProgrammeInput;
}>;

export type UpdateStudyProgrammeMutation = {
    readonly __typename: 'Mutation';
    readonly updateStudyProgramme: {
        readonly __typename: 'UpdateStudyProgrammePayload';
        readonly studyProgramme: {
            readonly __typename: 'StudyProgramme';
            readonly id: string;
            readonly studyProgrammeType: StudyProgrammeType;
            readonly title: string;
            readonly link: string;
            readonly information: string;
            readonly hasSavedStudyProgramme: boolean;
            readonly studyCountryCodes: ReadonlyArray<string>;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canEnquireStudyProgramme: boolean;
            readonly canEnrolInStudyProgramme: boolean;
            readonly studyCategory: {
                readonly __typename: 'StudyCategory';
                readonly id: string;
                readonly title: string;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
            readonly studyProvider: {
                readonly __typename: 'StudyProvider';
                readonly id: string;
                readonly countryCode: string;
                readonly title: string;
                readonly logoImageUrl?: string | null;
                readonly isEnquiriesEnabled: boolean;
                readonly isEnrolmentEnabled: boolean;
                readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                readonly updatedAt: string;
                readonly createdAt: string;
            };
        };
    };
};

export type UpdateStudyProviderMutationVariables = Exact<{
    input: UpdateStudyProviderInput;
}>;

export type UpdateStudyProviderMutation = {
    readonly __typename: 'Mutation';
    readonly updateStudyProvider: {
        readonly __typename: 'UpdateStudyProviderPayload';
        readonly studyProvider: {
            readonly __typename: 'StudyProvider';
            readonly id: string;
            readonly countryCode: string;
            readonly title: string;
            readonly logoImageUrl?: string | null;
            readonly logoImageId?: string | null;
            readonly email?: string | null;
            readonly isEnquiriesEnabled: boolean;
            readonly isEnrolmentEnabled: boolean;
            readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type ContactStudentJobProfileMutationVariables = Exact<{
    input: ContactStudentJobProfileInput;
}>;

export type ContactStudentJobProfileMutation = {
    readonly __typename: 'Mutation';
    readonly contactStudentJobProfile: { readonly __typename: 'ContactStudentJobProfilePayload'; readonly contacted: boolean };
};

export type DisableJobProfileMutationVariables = Exact<{
    input: DisableJobProfileInput;
}>;

export type DisableJobProfileMutation = {
    readonly __typename: 'Mutation';
    readonly disableJobProfile: {
        readonly __typename: 'DisableJobProfilePayload';
        readonly jobProfile: {
            readonly __typename: 'JobProfile';
            readonly id: string;
            readonly curriculumVitaeCompletedSections: ReadonlyArray<JobProfileRequiredSection>;
            readonly preferredJobCategories: ReadonlyArray<JobCategory>;
            readonly preferredJobTypes: ReadonlyArray<JobType>;
            readonly preferredJobAvailability: string;
            readonly canEnable: boolean;
            readonly enabledAt?: string | null;
            readonly preferredJobGeoLocations: ReadonlyArray<{
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            }>;
        };
    };
};

export type EnableJobProfileMutationVariables = Exact<{
    input: EnableJobProfileInput;
}>;

export type EnableJobProfileMutation = {
    readonly __typename: 'Mutation';
    readonly enableJobProfile: {
        readonly __typename: 'EnableJobProfilePayload';
        readonly jobProfile: {
            readonly __typename: 'JobProfile';
            readonly id: string;
            readonly curriculumVitaeCompletedSections: ReadonlyArray<JobProfileRequiredSection>;
            readonly preferredJobCategories: ReadonlyArray<JobCategory>;
            readonly preferredJobTypes: ReadonlyArray<JobType>;
            readonly preferredJobAvailability: string;
            readonly canEnable: boolean;
            readonly enabledAt?: string | null;
            readonly preferredJobGeoLocations: ReadonlyArray<{
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            }>;
        };
    };
};

export type GenerateCoverLetterMutationVariables = Exact<{
    input: GenerateCoverLetterInput;
}>;

export type GenerateCoverLetterMutation = {
    readonly __typename: 'Mutation';
    readonly generateCoverLetter: { readonly __typename: 'GenerateCoverLetterPayload'; readonly coverLetterText: string };
};

export type GenerateCurriculumVitaeFileMutationVariables = Exact<{
    input: GenerateCurriculumVitaeFileInput;
}>;

export type GenerateCurriculumVitaeFileMutation = {
    readonly __typename: 'Mutation';
    readonly generateCurriculumVitaeFile: {
        readonly __typename: 'GenerateCurriculumVitaeFilePayload';
        readonly generatedFile: {
            readonly __typename: 'CurriculumVitaeGeneratedFile';
            readonly id: string;
            readonly fileId: string;
            readonly fileUrl: string;
            readonly generatedAt: string;
        };
    };
};

export type MyCurriculumVitaeQueryVariables = Exact<{ [key: string]: never }>;

export type MyCurriculumVitaeQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly curriculumVitae?: {
            readonly __typename: 'CurriculumVitae';
            readonly id: string;
            readonly profileImageId?: string | null;
            readonly profileImageUrl?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly personalDetails: {
                readonly __typename: 'CurriculumVitaePersonalDetails';
                readonly givenName?: string | null;
                readonly familyName?: string | null;
                readonly dob?: string | null;
                readonly languageLocales?: ReadonlyArray<string> | null;
                readonly interests?: string | null;
                readonly driversLicenceType?: DriversLicenceType | null;
                readonly phoneNumber?: string | null;
                readonly email?: string | null;
                readonly street?: string | null;
                readonly suburb?: string | null;
                readonly city?: string | null;
                readonly countryCode?: string | null;
            };
            readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
            readonly employment: {
                readonly __typename: 'CurriculumVitaeEmployment';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEmploymentEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly volunteer: {
                readonly __typename: 'CurriculumVitaeVolunteer';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeVolunteerEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly education: {
                readonly __typename: 'CurriculumVitaeEducation';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEducationEntry';
                    readonly title: string;
                    readonly school: string;
                    readonly year: string;
                    readonly subjects?: ReadonlyArray<string> | null;
                }> | null;
            };
            readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
            readonly personalAttributes: {
                readonly __typename: 'CurriculumVitaePersonalAttributes';
                readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
            };
            readonly achievementsAndAwards: {
                readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
                    readonly description: string;
                    readonly year: string;
                }> | null;
            };
            readonly badges: {
                readonly __typename: 'CurriculumVitaeBadges';
                readonly selectedBadgesList: ReadonlyArray<{
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly resourceHubItems: {
                readonly __typename: 'CurriculumVitaeResourceHubItems';
                readonly selectedResourceHubItemsList: ReadonlyArray<{
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly extracurricularActivities: {
                readonly __typename: 'CurriculumVitaeExtracurricularActivities';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
                    readonly description: string;
                    readonly year: string;
                    readonly toYear?: string | null;
                }> | null;
            };
            readonly references: {
                readonly __typename: 'CurriculumVitaeReferences';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeReferenceEntry';
                    readonly name: string;
                    readonly company: string;
                    readonly position: string;
                    readonly email: string;
                    readonly phoneNumber: string;
                }> | null;
            };
            readonly generatedFiles: ReadonlyArray<{
                readonly __typename: 'CurriculumVitaeGeneratedFile';
                readonly id: string;
                readonly fileId: string;
                readonly fileUrl: string;
                readonly generatedAt: string;
            }>;
        } | null;
    };
};

export type MyJobProfileActivitiesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyJobProfileActivitiesQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly jobProfile?: {
            readonly __typename: 'JobProfile';
            readonly id: string;
            readonly activities: {
                readonly __typename: 'JobProfileActivitiesConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'JobProfileActivityEdge';
                    readonly node?: {
                        readonly __typename: 'JobProfileActivity';
                        readonly id: string;
                        readonly activityType: JobProfileActivityType;
                        readonly actorProviderTitle?: string | null;
                        readonly actorFullName?: string | null;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly actorProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                        readonly actor?: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                        } | null;
                    } | null;
                }>;
            };
        } | null;
    };
};

export type MyJobProfileQueryVariables = Exact<{ [key: string]: never }>;

export type MyJobProfileQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly jobProfile?: {
            readonly __typename: 'JobProfile';
            readonly id: string;
            readonly curriculumVitaeCompletedSections: ReadonlyArray<JobProfileRequiredSection>;
            readonly preferredJobCategories: ReadonlyArray<JobCategory>;
            readonly preferredJobTypes: ReadonlyArray<JobType>;
            readonly preferredJobAvailability: string;
            readonly canEnable: boolean;
            readonly enabledAt?: string | null;
            readonly preferredJobGeoLocations: ReadonlyArray<{
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            }>;
        } | null;
    };
};

export type ReportJobProfileActivityMutationVariables = Exact<{
    input: ReportJobProfileActivityInput;
}>;

export type ReportJobProfileActivityMutation = {
    readonly __typename: 'Mutation';
    readonly reportJobProfileActivity: { readonly __typename: 'ReportJobProfileActivityPayload'; readonly reported: boolean };
};

export type SearchStudentJobProfilesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    countryCode?: InputMaybe<Scalars['String']['input']>;
    geoLocationId?: InputMaybe<Scalars['UUID']['input']>;
    jobCategories?: InputMaybe<ReadonlyArray<JobCategory> | JobCategory>;
    ageGroups?: InputMaybe<ReadonlyArray<UserAgeGroup> | UserAgeGroup>;
    query?: InputMaybe<Scalars['String']['input']>;
    isFavourite?: InputMaybe<Scalars['Boolean']['input']>;
    hasContacted?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchStudentJobProfilesQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly searchStudentJobProfiles: {
            readonly __typename: 'StudentJobProfileSearchResultItemConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'StudentJobProfileSearchResultItemEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'StudentJobProfileSearchResultItem';
                    readonly id: string;
                    readonly givenName: string;
                    readonly age: number;
                    readonly profileImageUrl?: string | null;
                    readonly preferredJobCategories: ReadonlyArray<JobCategory>;
                    readonly preferredJobTypes: ReadonlyArray<JobType>;
                    readonly isFavourite: boolean;
                    readonly hasContacted: boolean;
                    readonly preferredJobGeoLocations: ReadonlyArray<{
                        readonly __typename: 'GeoLocation';
                        readonly id: string;
                        readonly name: string;
                        readonly displayName: string;
                        readonly longitude?: number | null;
                        readonly latitude?: number | null;
                        readonly street?: string | null;
                        readonly city?: string | null;
                        readonly county?: string | null;
                        readonly state?: string | null;
                        readonly country?: string | null;
                        readonly countryCode?: string | null;
                    }>;
                } | null;
            }>;
        };
    } | null;
};

export type StartCurriculumVitaeMutationVariables = Exact<{ [key: string]: never }>;

export type StartCurriculumVitaeMutation = {
    readonly __typename: 'Mutation';
    readonly startCurriculumVitae: {
        readonly __typename: 'StartCurriculumVitaePayload';
        readonly curriculumVitae: {
            readonly __typename: 'CurriculumVitae';
            readonly id: string;
            readonly profileImageId?: string | null;
            readonly profileImageUrl?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly personalDetails: {
                readonly __typename: 'CurriculumVitaePersonalDetails';
                readonly givenName?: string | null;
                readonly familyName?: string | null;
                readonly dob?: string | null;
                readonly languageLocales?: ReadonlyArray<string> | null;
                readonly interests?: string | null;
                readonly driversLicenceType?: DriversLicenceType | null;
                readonly phoneNumber?: string | null;
                readonly email?: string | null;
                readonly street?: string | null;
                readonly suburb?: string | null;
                readonly city?: string | null;
                readonly countryCode?: string | null;
            };
            readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
            readonly employment: {
                readonly __typename: 'CurriculumVitaeEmployment';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEmploymentEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly volunteer: {
                readonly __typename: 'CurriculumVitaeVolunteer';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeVolunteerEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly education: {
                readonly __typename: 'CurriculumVitaeEducation';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEducationEntry';
                    readonly title: string;
                    readonly school: string;
                    readonly year: string;
                    readonly subjects?: ReadonlyArray<string> | null;
                }> | null;
            };
            readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
            readonly personalAttributes: {
                readonly __typename: 'CurriculumVitaePersonalAttributes';
                readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
            };
            readonly achievementsAndAwards: {
                readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
                    readonly description: string;
                    readonly year: string;
                }> | null;
            };
            readonly badges: {
                readonly __typename: 'CurriculumVitaeBadges';
                readonly selectedBadgesList: ReadonlyArray<{
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly resourceHubItems: {
                readonly __typename: 'CurriculumVitaeResourceHubItems';
                readonly selectedResourceHubItemsList: ReadonlyArray<{
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly extracurricularActivities: {
                readonly __typename: 'CurriculumVitaeExtracurricularActivities';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
                    readonly description: string;
                    readonly year: string;
                    readonly toYear?: string | null;
                }> | null;
            };
            readonly references: {
                readonly __typename: 'CurriculumVitaeReferences';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeReferenceEntry';
                    readonly name: string;
                    readonly company: string;
                    readonly position: string;
                    readonly email: string;
                    readonly phoneNumber: string;
                }> | null;
            };
            readonly generatedFiles: ReadonlyArray<{
                readonly __typename: 'CurriculumVitaeGeneratedFile';
                readonly id: string;
                readonly fileId: string;
                readonly fileUrl: string;
                readonly generatedAt: string;
            }>;
        };
    };
};

export type StudentJobProfileQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    studentJobProfileId: Scalars['UUID']['input'];
}>;

export type StudentJobProfileQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly studentJobProfile?: {
            readonly __typename: 'StudentJobProfile';
            readonly id: string;
            readonly givenName: string;
            readonly age: number;
            readonly profileImageUrl?: string | null;
            readonly languageLocales?: ReadonlyArray<string> | null;
            readonly interests: string;
            readonly driversLicenceType: DriversLicenceType;
            readonly preferredJobAvailability: string;
            readonly preferredJobTypes: ReadonlyArray<JobType>;
            readonly preferredJobCategories: ReadonlyArray<JobCategory>;
            readonly isFavourite: boolean;
            readonly hasContacted: boolean;
            readonly lastContactedAt?: string | null;
            readonly preferredJobGeoLocations: ReadonlyArray<{
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
            }>;
            readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
            readonly employment?: {
                readonly __typename: 'CurriculumVitaeEmployment';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEmploymentEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            } | null;
            readonly volunteer?: {
                readonly __typename: 'CurriculumVitaeVolunteer';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeVolunteerEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            } | null;
            readonly education: {
                readonly __typename: 'CurriculumVitaeEducation';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEducationEntry';
                    readonly title: string;
                    readonly school: string;
                    readonly year: string;
                    readonly subjects?: ReadonlyArray<string> | null;
                }> | null;
            };
            readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
            readonly personalAttributes: {
                readonly __typename: 'CurriculumVitaePersonalAttributes';
                readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
            };
            readonly achievementsAndAwards?: {
                readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
                    readonly description: string;
                    readonly year: string;
                }> | null;
            } | null;
            readonly extracurricularActivities?: {
                readonly __typename: 'CurriculumVitaeExtracurricularActivities';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
                    readonly description: string;
                    readonly year: string;
                    readonly toYear?: string | null;
                }> | null;
            } | null;
            readonly references: {
                readonly __typename: 'CurriculumVitaeReferences';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeReferenceEntry';
                    readonly name: string;
                    readonly company: string;
                    readonly position: string;
                    readonly email: string;
                    readonly phoneNumber: string;
                }> | null;
            };
        } | null;
    } | null;
};

export type ToggleIsFavouriteStudentJobProfileMutationVariables = Exact<{
    input: ToggleIsFavouriteStudentJobProfileInput;
}>;

export type ToggleIsFavouriteStudentJobProfileMutation = {
    readonly __typename: 'Mutation';
    readonly toggleIsFavouriteStudentJobProfile: {
        readonly __typename: 'ToggleIsFavouriteStudentJobProfilePayload';
        readonly studentJobProfile: {
            readonly __typename: 'StudentJobProfile';
            readonly id: string;
            readonly givenName: string;
            readonly age: number;
            readonly profileImageUrl?: string | null;
            readonly languageLocales?: ReadonlyArray<string> | null;
            readonly interests: string;
            readonly driversLicenceType: DriversLicenceType;
            readonly preferredJobAvailability: string;
            readonly preferredJobTypes: ReadonlyArray<JobType>;
            readonly preferredJobCategories: ReadonlyArray<JobCategory>;
            readonly isFavourite: boolean;
            readonly hasContacted: boolean;
            readonly lastContactedAt?: string | null;
            readonly preferredJobGeoLocations: ReadonlyArray<{
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
            }>;
            readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
            readonly employment?: {
                readonly __typename: 'CurriculumVitaeEmployment';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEmploymentEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            } | null;
            readonly volunteer?: {
                readonly __typename: 'CurriculumVitaeVolunteer';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeVolunteerEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            } | null;
            readonly education: {
                readonly __typename: 'CurriculumVitaeEducation';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEducationEntry';
                    readonly title: string;
                    readonly school: string;
                    readonly year: string;
                    readonly subjects?: ReadonlyArray<string> | null;
                }> | null;
            };
            readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
            readonly personalAttributes: {
                readonly __typename: 'CurriculumVitaePersonalAttributes';
                readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
            };
            readonly achievementsAndAwards?: {
                readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
                    readonly description: string;
                    readonly year: string;
                }> | null;
            } | null;
            readonly extracurricularActivities?: {
                readonly __typename: 'CurriculumVitaeExtracurricularActivities';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
                    readonly description: string;
                    readonly year: string;
                    readonly toYear?: string | null;
                }> | null;
            } | null;
            readonly references: {
                readonly __typename: 'CurriculumVitaeReferences';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeReferenceEntry';
                    readonly name: string;
                    readonly company: string;
                    readonly position: string;
                    readonly email: string;
                    readonly phoneNumber: string;
                }> | null;
            };
        };
    };
};

export type UpdateCurriculumVitaeMutationVariables = Exact<{
    input: UpdateCurriculumVitaeInput;
}>;

export type UpdateCurriculumVitaeMutation = {
    readonly __typename: 'Mutation';
    readonly updateCurriculumVitae: {
        readonly __typename: 'UpdateCurriculumVitaePayload';
        readonly curriculumVitae: {
            readonly __typename: 'CurriculumVitae';
            readonly id: string;
            readonly profileImageId?: string | null;
            readonly profileImageUrl?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly personalDetails: {
                readonly __typename: 'CurriculumVitaePersonalDetails';
                readonly givenName?: string | null;
                readonly familyName?: string | null;
                readonly dob?: string | null;
                readonly languageLocales?: ReadonlyArray<string> | null;
                readonly interests?: string | null;
                readonly driversLicenceType?: DriversLicenceType | null;
                readonly phoneNumber?: string | null;
                readonly email?: string | null;
                readonly street?: string | null;
                readonly suburb?: string | null;
                readonly city?: string | null;
                readonly countryCode?: string | null;
            };
            readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
            readonly employment: {
                readonly __typename: 'CurriculumVitaeEmployment';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEmploymentEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly volunteer: {
                readonly __typename: 'CurriculumVitaeVolunteer';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeVolunteerEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly education: {
                readonly __typename: 'CurriculumVitaeEducation';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEducationEntry';
                    readonly title: string;
                    readonly school: string;
                    readonly year: string;
                    readonly subjects?: ReadonlyArray<string> | null;
                }> | null;
            };
            readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
            readonly personalAttributes: {
                readonly __typename: 'CurriculumVitaePersonalAttributes';
                readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
            };
            readonly achievementsAndAwards: {
                readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
                    readonly description: string;
                    readonly year: string;
                }> | null;
            };
            readonly badges: {
                readonly __typename: 'CurriculumVitaeBadges';
                readonly selectedBadgesList: ReadonlyArray<{
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly resourceHubItems: {
                readonly __typename: 'CurriculumVitaeResourceHubItems';
                readonly selectedResourceHubItemsList: ReadonlyArray<{
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly extracurricularActivities: {
                readonly __typename: 'CurriculumVitaeExtracurricularActivities';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
                    readonly description: string;
                    readonly year: string;
                    readonly toYear?: string | null;
                }> | null;
            };
            readonly references: {
                readonly __typename: 'CurriculumVitaeReferences';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeReferenceEntry';
                    readonly name: string;
                    readonly company: string;
                    readonly position: string;
                    readonly email: string;
                    readonly phoneNumber: string;
                }> | null;
            };
            readonly generatedFiles: ReadonlyArray<{
                readonly __typename: 'CurriculumVitaeGeneratedFile';
                readonly id: string;
                readonly fileId: string;
                readonly fileUrl: string;
                readonly generatedAt: string;
            }>;
        };
    };
};

export type UpdateJobProfileMutationVariables = Exact<{
    input: UpdateJobProfileInput;
}>;

export type UpdateJobProfileMutation = {
    readonly __typename: 'Mutation';
    readonly updateJobProfile: {
        readonly __typename: 'UpdateJobProfilePayload';
        readonly jobProfile: {
            readonly __typename: 'JobProfile';
            readonly id: string;
            readonly curriculumVitaeCompletedSections: ReadonlyArray<JobProfileRequiredSection>;
            readonly preferredJobCategories: ReadonlyArray<JobCategory>;
            readonly preferredJobTypes: ReadonlyArray<JobType>;
            readonly preferredJobAvailability: string;
            readonly canEnable: boolean;
            readonly enabledAt?: string | null;
            readonly preferredJobGeoLocations: ReadonlyArray<{
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            }>;
        };
    };
};

export type GeoLocationCountryRegionsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    countryCode: Scalars['CountryCode']['input'];
}>;

export type GeoLocationCountryRegionsQuery = {
    readonly __typename: 'Query';
    readonly geoLocationCountryRegions: {
        readonly __typename: 'GeoLocationConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'GeoLocationEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            } | null;
        }>;
    };
};

export type GeoLocationQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type GeoLocationQuery = {
    readonly __typename: 'Query';
    readonly geoLocation?: {
        readonly __typename: 'GeoLocation';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
        readonly longitude?: number | null;
        readonly latitude?: number | null;
        readonly street?: string | null;
        readonly city?: string | null;
        readonly county?: string | null;
        readonly state?: string | null;
        readonly country?: string | null;
        readonly countryCode?: string | null;
    } | null;
};

export type GeoLocationSearchQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    countryCode: Scalars['CountryCode']['input'];
    type: GeoLocationSearchType;
    query: Scalars['String']['input'];
}>;

export type GeoLocationSearchQuery = {
    readonly __typename: 'Query';
    readonly geoLocationSearch: {
        readonly __typename: 'GeoLocationConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'GeoLocationEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            } | null;
        }>;
    };
};

export type CreateGoalMutationVariables = Exact<{
    input: CreateGoalInput;
}>;

export type CreateGoalMutation = {
    readonly __typename: 'Mutation';
    readonly createGoal: {
        readonly __typename: 'CreateGoalPayload';
        readonly goal: {
            readonly __typename: 'Goal';
            readonly id: string;
            readonly goalType: GoalType;
            readonly title: string;
            readonly imageUrl?: string | null;
            readonly imageId?: string | null;
            readonly information: string;
            readonly complete: boolean;
            readonly dueAt?: string | null;
            readonly color?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly canCreateTask: boolean;
            readonly canCreateNote: boolean;
            readonly tasks: ReadonlyArray<{
                readonly __typename: 'GoalTask';
                readonly id: string;
                readonly information: string;
                readonly complete: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
            }>;
            readonly notes: ReadonlyArray<{
                readonly __typename: 'GoalNote';
                readonly id: string;
                readonly information: string;
                readonly updatedAt: string;
                readonly createdAt: string;
            }>;
        };
    };
};

export type CreateGoalNoteMutationVariables = Exact<{
    input: CreateGoalNoteInput;
}>;

export type CreateGoalNoteMutation = {
    readonly __typename: 'Mutation';
    readonly createGoalNote: {
        readonly __typename: 'CreateGoalNotePayload';
        readonly goalNote: {
            readonly __typename: 'GoalNote';
            readonly id: string;
            readonly information: string;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type CreateGoalTaskMutationVariables = Exact<{
    input: CreateGoalTaskInput;
}>;

export type CreateGoalTaskMutation = {
    readonly __typename: 'Mutation';
    readonly createGoalTask: {
        readonly __typename: 'CreateGoalTaskPayload';
        readonly goalTask: {
            readonly __typename: 'GoalTask';
            readonly id: string;
            readonly information: string;
            readonly complete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type DeleteGoalMutationVariables = Exact<{
    input: DeleteGoalInput;
}>;

export type DeleteGoalMutation = {
    readonly __typename: 'Mutation';
    readonly deleteGoal: { readonly __typename: 'DeleteGoalPayload'; readonly goalId: string };
};

export type DeleteGoalNoteMutationVariables = Exact<{
    input: DeleteGoalNoteInput;
}>;

export type DeleteGoalNoteMutation = {
    readonly __typename: 'Mutation';
    readonly deleteGoalNote: { readonly __typename: 'DeleteGoalNotePayload'; readonly goalNoteId: string };
};

export type DeleteGoalTaskMutationVariables = Exact<{
    input: DeleteGoalTaskInput;
}>;

export type DeleteGoalTaskMutation = {
    readonly __typename: 'Mutation';
    readonly deleteGoalTask: { readonly __typename: 'DeleteGoalTaskPayload'; readonly goalTaskId: string };
};

export type GoalQueryVariables = Exact<{
    goalId: Scalars['UUID']['input'];
}>;

export type GoalQuery = {
    readonly __typename: 'Query';
    readonly goal?: {
        readonly __typename: 'Goal';
        readonly id: string;
        readonly goalType: GoalType;
        readonly title: string;
        readonly imageUrl?: string | null;
        readonly imageId?: string | null;
        readonly information: string;
        readonly complete: boolean;
        readonly dueAt?: string | null;
        readonly color?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly canCreateTask: boolean;
        readonly canCreateNote: boolean;
        readonly tasks: ReadonlyArray<{
            readonly __typename: 'GoalTask';
            readonly id: string;
            readonly information: string;
            readonly complete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
        }>;
        readonly notes: ReadonlyArray<{
            readonly __typename: 'GoalNote';
            readonly id: string;
            readonly information: string;
            readonly updatedAt: string;
            readonly createdAt: string;
        }>;
    } | null;
};

export type MyGoalsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<GoalType> | GoalType>;
    query?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyGoalsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly goals: {
            readonly __typename: 'GoalConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'GoalEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Goal';
                    readonly id: string;
                    readonly goalType: GoalType;
                    readonly title: string;
                    readonly imageUrl?: string | null;
                    readonly imageId?: string | null;
                    readonly information: string;
                    readonly complete: boolean;
                    readonly dueAt?: string | null;
                    readonly color?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly canCreateTask: boolean;
                    readonly canCreateNote: boolean;
                    readonly tasks: ReadonlyArray<{
                        readonly __typename: 'GoalTask';
                        readonly id: string;
                        readonly information: string;
                        readonly complete: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    }>;
                    readonly notes: ReadonlyArray<{
                        readonly __typename: 'GoalNote';
                        readonly id: string;
                        readonly information: string;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    }>;
                } | null;
            }>;
        };
    };
};

export type UpdateGoalMutationVariables = Exact<{
    input: UpdateGoalInput;
}>;

export type UpdateGoalMutation = {
    readonly __typename: 'Mutation';
    readonly updateGoal: {
        readonly __typename: 'UpdateGoalPayload';
        readonly goal: {
            readonly __typename: 'Goal';
            readonly id: string;
            readonly goalType: GoalType;
            readonly title: string;
            readonly imageUrl?: string | null;
            readonly imageId?: string | null;
            readonly information: string;
            readonly complete: boolean;
            readonly dueAt?: string | null;
            readonly color?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly canCreateTask: boolean;
            readonly canCreateNote: boolean;
            readonly tasks: ReadonlyArray<{
                readonly __typename: 'GoalTask';
                readonly id: string;
                readonly information: string;
                readonly complete: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
            }>;
            readonly notes: ReadonlyArray<{
                readonly __typename: 'GoalNote';
                readonly id: string;
                readonly information: string;
                readonly updatedAt: string;
                readonly createdAt: string;
            }>;
        };
    };
};

export type UpdateGoalNoteMutationVariables = Exact<{
    input: UpdateGoalNoteInput;
}>;

export type UpdateGoalNoteMutation = {
    readonly __typename: 'Mutation';
    readonly updateGoalNote: {
        readonly __typename: 'UpdateGoalNotePayload';
        readonly goalNote: {
            readonly __typename: 'GoalNote';
            readonly id: string;
            readonly information: string;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type UpdateGoalTaskMutationVariables = Exact<{
    input: UpdateGoalTaskInput;
}>;

export type UpdateGoalTaskMutation = {
    readonly __typename: 'Mutation';
    readonly updateGoalTask: {
        readonly __typename: 'UpdateGoalTaskPayload';
        readonly goalTask: {
            readonly __typename: 'GoalTask';
            readonly id: string;
            readonly information: string;
            readonly complete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type AttachProviderIntegrationLissToParentIntegrationMutationVariables = Exact<{
    input: AttachProviderIntegrationLissToParentIntegrationInput;
}>;

export type AttachProviderIntegrationLissToParentIntegrationMutation = {
    readonly __typename: 'Mutation';
    readonly attachProviderIntegrationLissToParentIntegration: {
        readonly __typename: 'AttachProviderIntegrationLissToParentIntegrationPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationLiss';
            readonly token?: string | null;
            readonly canRegenerateToken: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type CreateProviderIntegrationMutationVariables = Exact<{
    input: CreateProviderIntegrationInput;
}>;

export type CreateProviderIntegrationMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderIntegration: {
        readonly __typename: 'CreateProviderIntegrationPayload';
        readonly providerIntegration:
            | {
                  readonly __typename: 'ProviderIntegrationEdgeLearning';
                  readonly id: string;
                  readonly integrationType: ProviderIntegrationType;
                  readonly updatedAt: string;
                  readonly createdAt: string;
                  readonly provider: {
                      readonly __typename: 'Provider';
                      readonly id: string;
                      readonly title: string;
                      readonly countryCode: string;
                      readonly providerType: ProviderType;
                      readonly logoImageUrl: string;
                      readonly bannerImageUrl?: string | null;
                  };
                  readonly parentIntegration?:
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | null;
                  readonly childIntegrations: ReadonlyArray<
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                  >;
              }
            | {
                  readonly __typename: 'ProviderIntegrationKamar';
                  readonly id: string;
                  readonly integrationType: ProviderIntegrationType;
                  readonly updatedAt: string;
                  readonly createdAt: string;
                  readonly provider: {
                      readonly __typename: 'Provider';
                      readonly id: string;
                      readonly title: string;
                      readonly countryCode: string;
                      readonly providerType: ProviderType;
                      readonly logoImageUrl: string;
                      readonly bannerImageUrl?: string | null;
                  };
                  readonly parentIntegration?:
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | null;
                  readonly childIntegrations: ReadonlyArray<
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                  >;
              }
            | {
                  readonly __typename: 'ProviderIntegrationLiss';
                  readonly id: string;
                  readonly integrationType: ProviderIntegrationType;
                  readonly updatedAt: string;
                  readonly createdAt: string;
                  readonly provider: {
                      readonly __typename: 'Provider';
                      readonly id: string;
                      readonly title: string;
                      readonly countryCode: string;
                      readonly providerType: ProviderType;
                      readonly logoImageUrl: string;
                      readonly bannerImageUrl?: string | null;
                  };
                  readonly parentIntegration?:
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | null;
                  readonly childIntegrations: ReadonlyArray<
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                  >;
              }
            | {
                  readonly __typename: 'ProviderIntegrationManualSmsData';
                  readonly id: string;
                  readonly integrationType: ProviderIntegrationType;
                  readonly updatedAt: string;
                  readonly createdAt: string;
                  readonly provider: {
                      readonly __typename: 'Provider';
                      readonly id: string;
                      readonly title: string;
                      readonly countryCode: string;
                      readonly providerType: ProviderType;
                      readonly logoImageUrl: string;
                      readonly bannerImageUrl?: string | null;
                  };
                  readonly parentIntegration?:
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | null;
                  readonly childIntegrations: ReadonlyArray<
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                  >;
              }
            | {
                  readonly __typename: 'ProviderIntegrationWonde';
                  readonly id: string;
                  readonly integrationType: ProviderIntegrationType;
                  readonly updatedAt: string;
                  readonly createdAt: string;
                  readonly provider: {
                      readonly __typename: 'Provider';
                      readonly id: string;
                      readonly title: string;
                      readonly countryCode: string;
                      readonly providerType: ProviderType;
                      readonly logoImageUrl: string;
                      readonly bannerImageUrl?: string | null;
                  };
                  readonly parentIntegration?:
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | null;
                  readonly childIntegrations: ReadonlyArray<
                      | {
                            readonly __typename: 'ProviderIntegrationEdgeLearning';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationKamar';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationLiss';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationManualSmsData';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                      | {
                            readonly __typename: 'ProviderIntegrationWonde';
                            readonly id: string;
                            readonly integrationType: ProviderIntegrationType;
                        }
                  >;
              };
    };
};

export type ProviderIntegrationQueryVariables = Exact<{
    providerIntegrationId: Scalars['UUID']['input'];
}>;

export type ProviderIntegrationQuery = {
    readonly __typename: 'Query';
    readonly providerIntegration?:
        | {
              readonly __typename: 'ProviderIntegrationEdgeLearning';
              readonly moeNumber?: string | null;
              readonly noticeDataCount: number;
              readonly assessmentDataCount: number;
              readonly resultDataCount: number;
              readonly attendanceDataCount: number;
              readonly canDelete: boolean;
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
              readonly updatedAt: string;
              readonly createdAt: string;
              readonly isDeleting: boolean;
              readonly studentDataCount: number;
              readonly studentPhotoDataCount: number;
              readonly employeeDataCount: number;
              readonly employeePhotoDataCount: number;
              readonly eventDataCount: number;
              readonly groupDataCount: number;
              readonly classDataCount: number;
              readonly lessonDataCount: number;
              readonly provider: {
                  readonly __typename: 'Provider';
                  readonly id: string;
                  readonly title: string;
                  readonly countryCode: string;
                  readonly providerType: ProviderType;
                  readonly logoImageUrl: string;
                  readonly bannerImageUrl?: string | null;
              };
              readonly parentIntegration?:
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | null;
              readonly childIntegrations: ReadonlyArray<
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
              >;
          }
        | {
              readonly __typename: 'ProviderIntegrationKamar';
              readonly token?: string | null;
              readonly noticeDataCount: number;
              readonly assessmentDataCount: number;
              readonly resultDataCount: number;
              readonly attendanceDataCount: number;
              readonly timetableDisabledUntil?: string | null;
              readonly canUpdateTimetableDisabledUntil: boolean;
              readonly canRegenerateToken: boolean;
              readonly canDelete: boolean;
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
              readonly updatedAt: string;
              readonly createdAt: string;
              readonly isDeleting: boolean;
              readonly studentDataCount: number;
              readonly studentPhotoDataCount: number;
              readonly employeeDataCount: number;
              readonly employeePhotoDataCount: number;
              readonly eventDataCount: number;
              readonly groupDataCount: number;
              readonly classDataCount: number;
              readonly lessonDataCount: number;
              readonly provider: {
                  readonly __typename: 'Provider';
                  readonly id: string;
                  readonly title: string;
                  readonly countryCode: string;
                  readonly providerType: ProviderType;
                  readonly logoImageUrl: string;
                  readonly bannerImageUrl?: string | null;
              };
              readonly parentIntegration?:
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | null;
              readonly childIntegrations: ReadonlyArray<
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
              >;
          }
        | {
              readonly __typename: 'ProviderIntegrationLiss';
              readonly token?: string | null;
              readonly canRegenerateToken: boolean;
              readonly canDelete: boolean;
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
              readonly updatedAt: string;
              readonly createdAt: string;
              readonly isDeleting: boolean;
              readonly studentDataCount: number;
              readonly studentPhotoDataCount: number;
              readonly employeeDataCount: number;
              readonly employeePhotoDataCount: number;
              readonly eventDataCount: number;
              readonly groupDataCount: number;
              readonly classDataCount: number;
              readonly lessonDataCount: number;
              readonly provider: {
                  readonly __typename: 'Provider';
                  readonly id: string;
                  readonly title: string;
                  readonly countryCode: string;
                  readonly providerType: ProviderType;
                  readonly logoImageUrl: string;
                  readonly bannerImageUrl?: string | null;
              };
              readonly parentIntegration?:
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | null;
              readonly childIntegrations: ReadonlyArray<
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
              >;
          }
        | {
              readonly __typename: 'ProviderIntegrationManualSmsData';
              readonly canDelete: boolean;
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
              readonly updatedAt: string;
              readonly createdAt: string;
              readonly isDeleting: boolean;
              readonly studentDataCount: number;
              readonly studentPhotoDataCount: number;
              readonly employeeDataCount: number;
              readonly employeePhotoDataCount: number;
              readonly eventDataCount: number;
              readonly groupDataCount: number;
              readonly classDataCount: number;
              readonly lessonDataCount: number;
              readonly provider: {
                  readonly __typename: 'Provider';
                  readonly id: string;
                  readonly title: string;
                  readonly countryCode: string;
                  readonly providerType: ProviderType;
                  readonly logoImageUrl: string;
                  readonly bannerImageUrl?: string | null;
              };
              readonly parentIntegration?:
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | null;
              readonly childIntegrations: ReadonlyArray<
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
              >;
          }
        | {
              readonly __typename: 'ProviderIntegrationWonde';
              readonly lastSyncedAt?: string | null;
              readonly canResync: boolean;
              readonly canDelete: boolean;
              readonly id: string;
              readonly integrationType: ProviderIntegrationType;
              readonly updatedAt: string;
              readonly createdAt: string;
              readonly isDeleting: boolean;
              readonly studentDataCount: number;
              readonly studentPhotoDataCount: number;
              readonly employeeDataCount: number;
              readonly employeePhotoDataCount: number;
              readonly eventDataCount: number;
              readonly groupDataCount: number;
              readonly classDataCount: number;
              readonly lessonDataCount: number;
              readonly provider: {
                  readonly __typename: 'Provider';
                  readonly id: string;
                  readonly title: string;
                  readonly countryCode: string;
                  readonly providerType: ProviderType;
                  readonly logoImageUrl: string;
                  readonly bannerImageUrl?: string | null;
              };
              readonly parentIntegration?:
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | null;
              readonly childIntegrations: ReadonlyArray<
                  | {
                        readonly __typename: 'ProviderIntegrationEdgeLearning';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationKamar';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationLiss';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationManualSmsData';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
                  | {
                        readonly __typename: 'ProviderIntegrationWonde';
                        readonly id: string;
                        readonly integrationType: ProviderIntegrationType;
                    }
              >;
          }
        | null;
};

export type ProviderIntegrationsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProviderIntegrationsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly availableIntegrationTypes: ReadonlyArray<ProviderIntegrationType>;
        readonly integrations: {
            readonly __typename: 'ProviderIntegrationConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProviderIntegrationEdge';
                readonly cursor: string;
                readonly node?:
                    | {
                          readonly __typename: 'ProviderIntegrationEdgeLearning';
                          readonly id: string;
                          readonly integrationType: ProviderIntegrationType;
                          readonly updatedAt: string;
                          readonly createdAt: string;
                          readonly provider: {
                              readonly __typename: 'Provider';
                              readonly id: string;
                              readonly title: string;
                              readonly countryCode: string;
                              readonly providerType: ProviderType;
                              readonly logoImageUrl: string;
                              readonly bannerImageUrl?: string | null;
                          };
                          readonly parentIntegration?:
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | null;
                          readonly childIntegrations: ReadonlyArray<
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                          >;
                      }
                    | {
                          readonly __typename: 'ProviderIntegrationKamar';
                          readonly id: string;
                          readonly integrationType: ProviderIntegrationType;
                          readonly updatedAt: string;
                          readonly createdAt: string;
                          readonly provider: {
                              readonly __typename: 'Provider';
                              readonly id: string;
                              readonly title: string;
                              readonly countryCode: string;
                              readonly providerType: ProviderType;
                              readonly logoImageUrl: string;
                              readonly bannerImageUrl?: string | null;
                          };
                          readonly parentIntegration?:
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | null;
                          readonly childIntegrations: ReadonlyArray<
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                          >;
                      }
                    | {
                          readonly __typename: 'ProviderIntegrationLiss';
                          readonly id: string;
                          readonly integrationType: ProviderIntegrationType;
                          readonly updatedAt: string;
                          readonly createdAt: string;
                          readonly provider: {
                              readonly __typename: 'Provider';
                              readonly id: string;
                              readonly title: string;
                              readonly countryCode: string;
                              readonly providerType: ProviderType;
                              readonly logoImageUrl: string;
                              readonly bannerImageUrl?: string | null;
                          };
                          readonly parentIntegration?:
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | null;
                          readonly childIntegrations: ReadonlyArray<
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                          >;
                      }
                    | {
                          readonly __typename: 'ProviderIntegrationManualSmsData';
                          readonly id: string;
                          readonly integrationType: ProviderIntegrationType;
                          readonly updatedAt: string;
                          readonly createdAt: string;
                          readonly provider: {
                              readonly __typename: 'Provider';
                              readonly id: string;
                              readonly title: string;
                              readonly countryCode: string;
                              readonly providerType: ProviderType;
                              readonly logoImageUrl: string;
                              readonly bannerImageUrl?: string | null;
                          };
                          readonly parentIntegration?:
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | null;
                          readonly childIntegrations: ReadonlyArray<
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                          >;
                      }
                    | {
                          readonly __typename: 'ProviderIntegrationWonde';
                          readonly id: string;
                          readonly integrationType: ProviderIntegrationType;
                          readonly updatedAt: string;
                          readonly createdAt: string;
                          readonly provider: {
                              readonly __typename: 'Provider';
                              readonly id: string;
                              readonly title: string;
                              readonly countryCode: string;
                              readonly providerType: ProviderType;
                              readonly logoImageUrl: string;
                              readonly bannerImageUrl?: string | null;
                          };
                          readonly parentIntegration?:
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | null;
                          readonly childIntegrations: ReadonlyArray<
                              | {
                                    readonly __typename: 'ProviderIntegrationEdgeLearning';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationKamar';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationLiss';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationManualSmsData';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                              | {
                                    readonly __typename: 'ProviderIntegrationWonde';
                                    readonly id: string;
                                    readonly integrationType: ProviderIntegrationType;
                                }
                          >;
                      }
                    | null;
            }>;
        };
    } | null;
};

export type QueueDeleteProviderIntegrationEdgeLearningMutationVariables = Exact<{
    input: QueueDeleteProviderIntegrationEdgeLearningInput;
}>;

export type QueueDeleteProviderIntegrationEdgeLearningMutation = {
    readonly __typename: 'Mutation';
    readonly queueDeleteProviderIntegrationEdgeLearning: {
        readonly __typename: 'QueueDeleteProviderIntegrationEdgeLearningPayload';
        readonly success: boolean;
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationEdgeLearning';
            readonly moeNumber?: string | null;
            readonly noticeDataCount: number;
            readonly assessmentDataCount: number;
            readonly resultDataCount: number;
            readonly attendanceDataCount: number;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type QueueDeleteProviderIntegrationKamarMutationVariables = Exact<{
    input: QueueDeleteProviderIntegrationKamarInput;
}>;

export type QueueDeleteProviderIntegrationKamarMutation = {
    readonly __typename: 'Mutation';
    readonly queueDeleteProviderIntegrationKamar: {
        readonly __typename: 'QueueDeleteProviderIntegrationKamarPayload';
        readonly success: boolean;
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationKamar';
            readonly token?: string | null;
            readonly noticeDataCount: number;
            readonly assessmentDataCount: number;
            readonly resultDataCount: number;
            readonly attendanceDataCount: number;
            readonly timetableDisabledUntil?: string | null;
            readonly canUpdateTimetableDisabledUntil: boolean;
            readonly canRegenerateToken: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type QueueDeleteProviderIntegrationLissMutationVariables = Exact<{
    input: QueueDeleteProviderIntegrationLissInput;
}>;

export type QueueDeleteProviderIntegrationLissMutation = {
    readonly __typename: 'Mutation';
    readonly queueDeleteProviderIntegrationLiss: {
        readonly __typename: 'QueueDeleteProviderIntegrationLissPayload';
        readonly success: boolean;
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationLiss';
            readonly token?: string | null;
            readonly canRegenerateToken: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type QueueDeleteProviderIntegrationManualSmsDataMutationVariables = Exact<{
    input: QueueDeleteProviderIntegrationManualSmsDataInput;
}>;

export type QueueDeleteProviderIntegrationManualSmsDataMutation = {
    readonly __typename: 'Mutation';
    readonly queueDeleteProviderIntegrationManualSmsData: {
        readonly __typename: 'QueueDeleteProviderIntegrationManualSmsDataPayload';
        readonly success: boolean;
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationManualSmsData';
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type QueueDeleteProviderIntegrationWondeMutationVariables = Exact<{
    input: QueueDeleteProviderIntegrationWondeInput;
}>;

export type QueueDeleteProviderIntegrationWondeMutation = {
    readonly __typename: 'Mutation';
    readonly queueDeleteProviderIntegrationWonde: {
        readonly __typename: 'QueueDeleteProviderIntegrationWondePayload';
        readonly success: boolean;
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationWonde';
            readonly lastSyncedAt?: string | null;
            readonly canResync: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type RegenerateProviderIntegrationKamarTokenMutationVariables = Exact<{
    input: RegenerateProviderIntegrationKamarTokenInput;
}>;

export type RegenerateProviderIntegrationKamarTokenMutation = {
    readonly __typename: 'Mutation';
    readonly regenerateProviderIntegrationKamarToken: {
        readonly __typename: 'RegenerateProviderIntegrationKamarTokenPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationKamar';
            readonly token?: string | null;
            readonly noticeDataCount: number;
            readonly assessmentDataCount: number;
            readonly resultDataCount: number;
            readonly attendanceDataCount: number;
            readonly timetableDisabledUntil?: string | null;
            readonly canUpdateTimetableDisabledUntil: boolean;
            readonly canRegenerateToken: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type RegenerateProviderIntegrationLissTokenMutationVariables = Exact<{
    input: RegenerateProviderIntegrationLissTokenInput;
}>;

export type RegenerateProviderIntegrationLissTokenMutation = {
    readonly __typename: 'Mutation';
    readonly regenerateProviderIntegrationLissToken: {
        readonly __typename: 'RegenerateProviderIntegrationLissTokenPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationLiss';
            readonly token?: string | null;
            readonly canRegenerateToken: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type ResyncProviderIntegrationWondeDataMutationVariables = Exact<{
    input: ResyncProviderIntegrationWondeDataInput;
}>;

export type ResyncProviderIntegrationWondeDataMutation = {
    readonly __typename: 'Mutation';
    readonly resyncProviderIntegrationWondeData: {
        readonly __typename: 'ResyncProviderIntegrationWondeDataPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationWonde';
            readonly lastSyncedAt?: string | null;
            readonly canResync: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type UpdateProviderIntegrationKamarTimetableDisabledUntilMutationVariables = Exact<{
    input: UpdateProviderIntegrationKamarTimetableDisabledUntilInput;
}>;

export type UpdateProviderIntegrationKamarTimetableDisabledUntilMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderIntegrationKamarTimetableDisabledUntil: {
        readonly __typename: 'UpdateProviderIntegrationKamarTimetableDisabledUntilPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationKamar';
            readonly token?: string | null;
            readonly noticeDataCount: number;
            readonly assessmentDataCount: number;
            readonly resultDataCount: number;
            readonly attendanceDataCount: number;
            readonly timetableDisabledUntil?: string | null;
            readonly canUpdateTimetableDisabledUntil: boolean;
            readonly canRegenerateToken: boolean;
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type UpdateProviderIntegrationManualSmsDataEmployeesMutationVariables = Exact<{
    input: UpdateProviderIntegrationManualSmsDataEmployeesInput;
}>;

export type UpdateProviderIntegrationManualSmsDataEmployeesMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderIntegrationManualSmsDataEmployees: {
        readonly __typename: 'UpdateProviderIntegrationManualSmsDataEmployeesPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationManualSmsData';
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type UpdateProviderIntegrationManualSmsDataGroupEmployeesMutationVariables = Exact<{
    input: UpdateProviderIntegrationManualSmsDataGroupEmployeesInput;
}>;

export type UpdateProviderIntegrationManualSmsDataGroupEmployeesMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderIntegrationManualSmsDataGroupEmployees: {
        readonly __typename: 'UpdateProviderIntegrationManualSmsDataGroupEmployeesPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationManualSmsData';
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type UpdateProviderIntegrationManualSmsDataGroupStudentsMutationVariables = Exact<{
    input: UpdateProviderIntegrationManualSmsDataGroupStudentsInput;
}>;

export type UpdateProviderIntegrationManualSmsDataGroupStudentsMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderIntegrationManualSmsDataGroupStudents: {
        readonly __typename: 'UpdateProviderIntegrationManualSmsDataGroupStudentsPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationManualSmsData';
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type UpdateProviderIntegrationManualSmsDataGroupsMutationVariables = Exact<{
    input: UpdateProviderIntegrationManualSmsDataGroupsInput;
}>;

export type UpdateProviderIntegrationManualSmsDataGroupsMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderIntegrationManualSmsDataGroups: {
        readonly __typename: 'UpdateProviderIntegrationManualSmsDataGroupsPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationManualSmsData';
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type UpdateProviderIntegrationManualSmsDataStudentsMutationVariables = Exact<{
    input: UpdateProviderIntegrationManualSmsDataStudentsInput;
}>;

export type UpdateProviderIntegrationManualSmsDataStudentsMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderIntegrationManualSmsDataStudents: {
        readonly __typename: 'UpdateProviderIntegrationManualSmsDataStudentsPayload';
        readonly providerIntegration: {
            readonly __typename: 'ProviderIntegrationManualSmsData';
            readonly canDelete: boolean;
            readonly id: string;
            readonly integrationType: ProviderIntegrationType;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isDeleting: boolean;
            readonly studentDataCount: number;
            readonly studentPhotoDataCount: number;
            readonly employeeDataCount: number;
            readonly employeePhotoDataCount: number;
            readonly eventDataCount: number;
            readonly groupDataCount: number;
            readonly classDataCount: number;
            readonly lessonDataCount: number;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly parentIntegration?:
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | null;
            readonly childIntegrations: ReadonlyArray<
                | {
                      readonly __typename: 'ProviderIntegrationEdgeLearning';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationKamar';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | { readonly __typename: 'ProviderIntegrationLiss'; readonly id: string; readonly integrationType: ProviderIntegrationType }
                | {
                      readonly __typename: 'ProviderIntegrationManualSmsData';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
                | {
                      readonly __typename: 'ProviderIntegrationWonde';
                      readonly id: string;
                      readonly integrationType: ProviderIntegrationType;
                  }
            >;
        };
    };
};

export type MyLearnerSmsProfilesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyLearnerSmsProfilesQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly learnerSmsProfiles: {
            readonly __typename: 'LearnerSmsProfileConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'LearnerSmsProfileEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'LearnerSmsProfile';
                    readonly id: string;
                    readonly pictureUrl: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly fullName: string;
                    readonly preferredGivenName: string;
                    readonly preferredFamilyName?: string | null;
                    readonly preferredFullName: string;
                    readonly email: string;
                    readonly idNumber: string;
                    readonly barcodeNumber: string;
                    readonly nationalCurriculumYear: string;
                    readonly uniqueStudentNumber?: string | null;
                    readonly mobile?: string | null;
                    readonly isAssessmentResultsAvailable: boolean;
                    readonly isAttendanceAvailable: boolean;
                    readonly isDailyNoticesAvailable: boolean;
                    readonly isTimetableAvailable: boolean;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                } | null;
            }>;
        };
    };
};

export type ProviderLearnerSmsGroupsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProviderLearnerSmsGroupsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly learnerSmsGroups: {
            readonly __typename: 'LearnerSmsGroupConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'LearnerSmsGroupEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'LearnerSmsGroup';
                    readonly id: string;
                    readonly name: string;
                    readonly code: string;
                    readonly numberOfStudents: number;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly employeesList: ReadonlyArray<{
                        readonly __typename: 'LearnerSmsEmployee';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly fullName: string;
                    }>;
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                } | null;
            }>;
        };
    } | null;
};

export type CreateLessonLocalizationMutationVariables = Exact<{
    input: CreateLessonLocalizationInput;
}>;

export type CreateLessonLocalizationMutation = {
    readonly __typename: 'Mutation';
    readonly createLessonLocalization: {
        readonly __typename: 'CreateLessonLocalizationPayload';
        readonly lesson: {
            readonly __typename: 'Lesson';
            readonly defaultLocale: string;
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly localizations: ReadonlyArray<{
                readonly __typename: 'LessonLocalization';
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageId?: string | null;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly tags: ReadonlyArray<string>;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
            }>;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        };
    };
};

export type CreateLessonMutationVariables = Exact<{
    input: CreateLessonInput;
}>;

export type CreateLessonMutation = {
    readonly __typename: 'Mutation';
    readonly createLesson: {
        readonly __typename: 'CreateLessonPayload';
        readonly lesson: {
            readonly __typename: 'Lesson';
            readonly defaultLocale: string;
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly localizations: ReadonlyArray<{
                readonly __typename: 'LessonLocalization';
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageId?: string | null;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly tags: ReadonlyArray<string>;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
            }>;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        };
    };
};

export type DeleteLessonLocalizationMutationVariables = Exact<{
    input: DeleteLessonLocalizationInput;
}>;

export type DeleteLessonLocalizationMutation = {
    readonly __typename: 'Mutation';
    readonly deleteLessonLocalization: {
        readonly __typename: 'DeleteLessonLocalizationPayload';
        readonly lesson: {
            readonly __typename: 'Lesson';
            readonly defaultLocale: string;
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly localizations: ReadonlyArray<{
                readonly __typename: 'LessonLocalization';
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageId?: string | null;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly tags: ReadonlyArray<string>;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
            }>;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        };
    };
};

export type DeleteLessonMutationVariables = Exact<{
    input: DeleteLessonInput;
}>;

export type DeleteLessonMutation = {
    readonly __typename: 'Mutation';
    readonly deleteLesson: { readonly __typename: 'DeleteLessonPayload'; readonly lessonId: string };
};

export type LessonCategoriesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type LessonCategoriesQuery = {
    readonly __typename: 'Query';
    readonly lessonCategories: {
        readonly __typename: 'LessonCategoryConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'LessonCategoryEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            } | null;
        }>;
    };
};

export type LessonCategoriesWithLocalizationsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type LessonCategoriesWithLocalizationsQuery = {
    readonly __typename: 'Query';
    readonly lessonCategories: {
        readonly __typename: 'LessonCategoryConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'LessonCategoryEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'LessonCategory';
                readonly defaultLocale: string;
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly localizations: ReadonlyArray<{
                    readonly __typename: 'LessonCategoryLocalization';
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                }>;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            } | null;
        }>;
    };
};

export type LessonQueryVariables = Exact<{
    lessonId: Scalars['UUID']['input'];
    locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type LessonQuery = {
    readonly __typename: 'Query';
    readonly lesson?: {
        readonly __typename: 'Lesson';
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly documentIframeUrl?: string | null;
        readonly tags: ReadonlyArray<string>;
        readonly isFeatured: boolean;
        readonly countryCodes: ReadonlyArray<string>;
        readonly favoriteCount: number;
        readonly hasRead: boolean;
        readonly hasFavorited: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly lessonCategory: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly parent?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
            } | null;
        };
    } | null;
};

export type LessonTagsListQueryVariables = Exact<{
    locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type LessonTagsListQuery = { readonly __typename: 'Query'; readonly lessonTagsList: ReadonlyArray<string> };

export type LessonWithLocalizationsQueryVariables = Exact<{
    lessonId: Scalars['UUID']['input'];
}>;

export type LessonWithLocalizationsQuery = {
    readonly __typename: 'Query';
    readonly lesson?: {
        readonly __typename: 'Lesson';
        readonly defaultLocale: string;
        readonly id: string;
        readonly locale: string;
        readonly title: string;
        readonly description: string;
        readonly coverImageUrl?: string | null;
        readonly documentId: string;
        readonly documentIframeUrl?: string | null;
        readonly tags: ReadonlyArray<string>;
        readonly isFeatured: boolean;
        readonly countryCodes: ReadonlyArray<string>;
        readonly favoriteCount: number;
        readonly hasRead: boolean;
        readonly hasFavorited: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly localizations: ReadonlyArray<{
            readonly __typename: 'LessonLocalization';
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageId?: string | null;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly tags: ReadonlyArray<string>;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        }>;
        readonly lessonCategory: {
            readonly __typename: 'LessonCategory';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly parent?: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
            } | null;
        };
    } | null;
};

export type LessonsSearchQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    locale?: InputMaybe<Scalars['Locale']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    countryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
    tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
    lessonCategoryId?: InputMaybe<Scalars['String']['input']>;
    isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
    sort?: InputMaybe<LessonSortType>;
}>;

export type LessonsSearchQuery = {
    readonly __typename: 'Query';
    readonly lessons: {
        readonly __typename: 'LessonConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'LessonEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
    };
};

export type LessonsWithLocalizationsSearchQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    locale?: InputMaybe<Scalars['Locale']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    countryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
    tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
    lessonCategoryId?: InputMaybe<Scalars['String']['input']>;
    isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
    sort?: InputMaybe<LessonSortType>;
}>;

export type LessonsWithLocalizationsSearchQuery = {
    readonly __typename: 'Query';
    readonly lessons: {
        readonly __typename: 'LessonConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'LessonEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'Lesson';
                readonly defaultLocale: string;
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly localizations: ReadonlyArray<{
                    readonly __typename: 'LessonLocalization';
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageId?: string | null;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly tags: ReadonlyArray<string>;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                }>;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
    };
};

export type ToggleFavoriteLessonMutationVariables = Exact<{
    input: ToggleFavoriteLessonInput;
}>;

export type ToggleFavoriteLessonMutation = {
    readonly __typename: 'Mutation';
    readonly toggleFavoriteLesson: {
        readonly __typename: 'ToggleFavoriteLessonPayload';
        readonly lesson: { readonly __typename: 'Lesson'; readonly id: string; readonly locale: string; readonly hasFavorited: boolean };
    };
};

export type ToggleFeaturedLessonMutationVariables = Exact<{
    input: ToggleFeaturedLessonInput;
}>;

export type ToggleFeaturedLessonMutation = {
    readonly __typename: 'Mutation';
    readonly toggleFeaturedLesson: {
        readonly __typename: 'ToggleFeaturedLessonPayload';
        readonly lesson: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly isFeatured: boolean;
            readonly updatedAt: string;
        };
    };
};

export type ToggleProviderLessonAccessMutationVariables = Exact<{
    input: ToggleProviderLessonAccessInput;
}>;

export type ToggleProviderLessonAccessMutation = {
    readonly __typename: 'Mutation';
    readonly toggleProviderLessonAccess: {
        readonly __typename: 'ToggleProviderLessonAccessPayload';
        readonly provider: { readonly __typename: 'Provider'; readonly id: string; readonly isLessonsDisabled: boolean };
    };
};

export type ToggleReadLessonMutationVariables = Exact<{
    input: ToggleReadLessonInput;
}>;

export type ToggleReadLessonMutation = {
    readonly __typename: 'Mutation';
    readonly toggleReadLesson: {
        readonly __typename: 'ToggleReadLessonPayload';
        readonly lesson: { readonly __typename: 'Lesson'; readonly id: string; readonly locale: string; readonly hasRead: boolean };
    };
};

export type UpdateLessonLocalizationMutationVariables = Exact<{
    input: UpdateLessonLocalizationInput;
}>;

export type UpdateLessonLocalizationMutation = {
    readonly __typename: 'Mutation';
    readonly updateLessonLocalization: {
        readonly __typename: 'UpdateLessonLocalizationPayload';
        readonly lesson: {
            readonly __typename: 'Lesson';
            readonly defaultLocale: string;
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly localizations: ReadonlyArray<{
                readonly __typename: 'LessonLocalization';
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageId?: string | null;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly tags: ReadonlyArray<string>;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
            }>;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        };
    };
};

export type UpdateLessonMutationVariables = Exact<{
    input: UpdateLessonInput;
}>;

export type UpdateLessonMutation = {
    readonly __typename: 'Mutation';
    readonly updateLesson: {
        readonly __typename: 'UpdateLessonPayload';
        readonly lesson: {
            readonly __typename: 'Lesson';
            readonly defaultLocale: string;
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly localizations: ReadonlyArray<{
                readonly __typename: 'LessonLocalization';
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageId?: string | null;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly tags: ReadonlyArray<string>;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
            }>;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        };
    };
};

export type AddMentorGroupMemberMutationVariables = Exact<{
    input: AddMentorGroupMemberInput;
}>;

export type AddMentorGroupMemberMutation = {
    readonly __typename: 'Mutation';
    readonly addMentorGroupMember: {
        readonly __typename: 'AddMentorGroupMemberPayload';
        readonly mentorGroupMember: {
            readonly __typename: 'MentorGroupMember';
            readonly id: string;
            readonly canRemove: boolean;
            readonly providerMember: {
                readonly __typename: 'ProviderMember';
                readonly id: string;
                readonly canDelete: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
                readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
            };
        };
    };
};

export type AddMentorGroupStudentMutationVariables = Exact<{
    input: AddMentorGroupStudentInput;
}>;

export type AddMentorGroupStudentMutation = {
    readonly __typename: 'Mutation';
    readonly addMentorGroupStudent: {
        readonly __typename: 'AddMentorGroupStudentPayload';
        readonly mentorGroupStudent: {
            readonly __typename: 'MentorGroupStudent';
            readonly id: string;
            readonly mentorStudent: {
                readonly __typename: 'MentorStudent';
                readonly id: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
                readonly learnerSmsProfile?: {
                    readonly __typename: 'LearnerSmsProfile';
                    readonly id: string;
                    readonly pictureUrl: string;
                    readonly preferredGivenName: string;
                    readonly preferredFamilyName?: string | null;
                    readonly preferredFullName: string;
                    readonly email: string;
                    readonly idNumber: string;
                    readonly nationalCurriculumYear: string;
                } | null;
            };
            readonly mentorGroup: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string };
        };
    };
};

export type CreateMentorGroupMutationVariables = Exact<{
    input: CreateMentorGroupInput;
}>;

export type CreateMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly createMentorGroup: {
        readonly __typename: 'CreateMentorGroupPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly title: string;
            readonly description?: string | null;
            readonly createdAt: string;
            readonly updatedAt: string;
        };
    };
};

export type CreateMentorSharedResourceMutationVariables = Exact<{
    input: CreateMentorSharedResourceInput;
}>;

export type CreateMentorSharedResourceMutation = {
    readonly __typename: 'Mutation';
    readonly createMentorSharedResource: {
        readonly __typename: 'CreateMentorSharedResourcePayload';
        readonly mentorSharedResource: {
            readonly __typename: 'MentorSharedResource';
            readonly id: string;
            readonly sharedResourceType: MentorSharedResourceType;
            readonly information: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateStudentHomeworkDiaryMentorTaskMutationVariables = Exact<{
    input: CreateStudentHomeworkDiaryMentorTaskInput;
}>;

export type CreateStudentHomeworkDiaryMentorTaskMutation = {
    readonly __typename: 'Mutation';
    readonly createStudentHomeworkDiaryMentorTask: {
        readonly __typename: 'CreateStudentHomeworkDiaryMentorTaskPayload';
        readonly studentHomeworkDiaryMentorTask: {
            readonly __typename: 'StudentHomeworkDiaryMentorTask';
            readonly id: string;
            readonly date: string;
            readonly title: string;
            readonly information?: string | null;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly color?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly studentsAssigned: {
                readonly __typename: 'MentorStudentConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'MentorStudentEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                    } | null;
                }>;
            };
            readonly studentsCompleted: {
                readonly __typename: 'MentorStudentConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'MentorStudentEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                    } | null;
                }>;
            };
        };
    };
};

export type DeleteMentorGroupMutationVariables = Exact<{
    input: DeleteMentorGroupInput;
}>;

export type DeleteMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly deleteMentorGroup: { readonly __typename: 'DeleteMentorGroupPayload'; readonly mentorGroupId: string };
};

export type DeleteMentorSharedResourceMutationVariables = Exact<{
    input: DeleteMentorSharedResourceInput;
}>;

export type DeleteMentorSharedResourceMutation = {
    readonly __typename: 'Mutation';
    readonly deleteMentorSharedResource: {
        readonly __typename: 'DeleteMentorSharedResourcePayload';
        readonly mentorSharedResourceId: string;
    };
};

export type DeleteStudentHomeworkDiaryMentorTaskMutationVariables = Exact<{
    input: DeleteStudentHomeworkDiaryMentorTaskInput;
}>;

export type DeleteStudentHomeworkDiaryMentorTaskMutation = {
    readonly __typename: 'Mutation';
    readonly deleteStudentHomeworkDiaryMentorTask: {
        readonly __typename: 'DeleteStudentHomeworkDiaryMentorTaskPayload';
        readonly studentHomeworkDiaryMentorTaskId: string;
    };
};

export type DisconnectMentorGroupMutationVariables = Exact<{
    input: DisconnectMentorGroupInput;
}>;

export type DisconnectMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly disconnectMentorGroup: { readonly __typename: 'DisconnectMentorGroupPayload'; readonly mentorGroupId: string };
};

export type DisconnectMentorProviderMutationVariables = Exact<{
    input: DisconnectMentorProviderInput;
}>;

export type DisconnectMentorProviderMutation = {
    readonly __typename: 'Mutation';
    readonly disconnectMentorProvider: { readonly __typename: 'DisconnectMentorProviderPayload'; readonly providerId: string };
};

export type MentorGroupInformationQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
}>;

export type MentorGroupInformationQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly information?: string | null;
    } | null;
};

export type MentorGroupMembersQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupMembersQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly description?: string | null;
        readonly bannerImageUrl?: string | null;
        readonly information?: string | null;
        readonly inviteCode: string;
        readonly hasPinned: boolean;
        readonly pinnedAt?: string | null;
        readonly isJoinable: boolean;
        readonly isPublic: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canAddMember: boolean;
        readonly canSendDiscussionMessage: boolean;
        readonly canRegenerateInviteCode: boolean;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly canAddMentorStudent: boolean;
        readonly canCreateDiscussionPoll: boolean;
        readonly members: {
            readonly __typename: 'MentorGroupMemberConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupMemberEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupMember';
                    readonly id: string;
                    readonly canRemove: boolean;
                    readonly providerMember: {
                        readonly __typename: 'ProviderMember';
                        readonly id: string;
                        readonly canDelete: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
                    };
                } | null;
            }>;
        };
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly discussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
    } | null;
};

export type MentorGroupMentorStudentsQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupMentorStudentsQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly description?: string | null;
        readonly bannerImageUrl?: string | null;
        readonly information?: string | null;
        readonly inviteCode: string;
        readonly hasPinned: boolean;
        readonly pinnedAt?: string | null;
        readonly isJoinable: boolean;
        readonly isPublic: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canAddMember: boolean;
        readonly canSendDiscussionMessage: boolean;
        readonly canRegenerateInviteCode: boolean;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly canAddMentorStudent: boolean;
        readonly canCreateDiscussionPoll: boolean;
        readonly students: {
            readonly __typename: 'MentorGroupStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupStudent';
                    readonly id: string;
                    readonly mentorStudent: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                    };
                } | null;
            }>;
        };
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly discussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
    } | null;
};

export type MentorGroupMissingStudentsQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupMissingStudentsQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly missingStudents: {
            readonly __typename: 'MentorGroupMissingStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupMissingStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupMissingStudent';
                    readonly id: string;
                    readonly preferredFullName: string;
                    readonly preferredGivenName: string;
                    readonly preferredFamilyName?: string | null;
                    readonly pictureUrl?: string | null;
                    readonly idNumber: string;
                } | null;
            }>;
        };
    } | null;
};

export type MentorGroupQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
}>;

export type MentorGroupQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly description?: string | null;
        readonly bannerImageUrl?: string | null;
        readonly information?: string | null;
        readonly inviteCode: string;
        readonly hasPinned: boolean;
        readonly pinnedAt?: string | null;
        readonly isJoinable: boolean;
        readonly isPublic: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canAddMember: boolean;
        readonly canSendDiscussionMessage: boolean;
        readonly canRegenerateInviteCode: boolean;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly canAddMentorStudent: boolean;
        readonly canCreateDiscussionPoll: boolean;
        readonly students: { readonly __typename: 'MentorGroupStudentConnection'; readonly totalCount: number };
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly discussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
    } | null;
};

export type MentorGroupStudentsQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupStudentsQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly description?: string | null;
        readonly bannerImageUrl?: string | null;
        readonly information?: string | null;
        readonly inviteCode: string;
        readonly hasPinned: boolean;
        readonly pinnedAt?: string | null;
        readonly isJoinable: boolean;
        readonly isPublic: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canAddMember: boolean;
        readonly canSendDiscussionMessage: boolean;
        readonly canRegenerateInviteCode: boolean;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly canAddMentorStudent: boolean;
        readonly canCreateDiscussionPoll: boolean;
        readonly students: {
            readonly __typename: 'MentorGroupStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupStudent';
                    readonly canRemove: boolean;
                    readonly id: string;
                    readonly mentorStudent: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                    };
                    readonly mentorGroup: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string };
                } | null;
            }>;
        };
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly discussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
    } | null;
};

export type MentorGroupStudentsSimpleQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupStudentsSimpleQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly students: {
            readonly __typename: 'MentorGroupStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupStudent';
                    readonly id: string;
                    readonly mentorStudent: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                    };
                    readonly mentorGroup: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string };
                } | null;
            }>;
        };
    } | null;
};

export type MentorGroupTitleQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
}>;

export type MentorGroupTitleQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
};

export type ProviderDailyNoticesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type ProviderDailyNoticesQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly dailyNotices: ReadonlyArray<{
            readonly __typename: 'DailyNotice';
            readonly id: string;
            readonly color: string;
            readonly level: string;
            readonly start: string;
            readonly end: string;
            readonly subject: string;
            readonly body?: string | null;
            readonly teacher?: string | null;
            readonly meetingDate?: string | null;
            readonly meetingPlace?: string | null;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        }>;
    } | null;
};

export type ProviderMentorGroupSummariesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    ownedGroupsOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProviderMentorGroupSummariesQuery = {
    readonly __typename: 'Query';
    readonly me: { readonly __typename: 'UserData'; readonly id: string };
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorGroups: {
            readonly __typename: 'MentorGroupConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly description?: string | null;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly inviteCode: string;
                    readonly hasPinned: boolean;
                    readonly pinnedAt?: string | null;
                    readonly isJoinable: boolean;
                    readonly isPublic: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canAddMember: boolean;
                    readonly canSendDiscussionMessage: boolean;
                    readonly canRegenerateInviteCode: boolean;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly canAddMentorStudent: boolean;
                    readonly canCreateDiscussionPoll: boolean;
                    readonly members: {
                        readonly __typename: 'MentorGroupMemberConnection';
                        readonly totalCount: number;
                        readonly pageInfo: {
                            readonly __typename: 'PageInfo';
                            readonly hasPreviousPage: boolean;
                            readonly hasNextPage: boolean;
                            readonly startCursor?: string | null;
                            readonly endCursor?: string | null;
                        };
                        readonly edges: ReadonlyArray<{
                            readonly __typename: 'MentorGroupMemberEdge';
                            readonly cursor: string;
                            readonly node?: {
                                readonly __typename: 'MentorGroupMember';
                                readonly id: string;
                                readonly canRemove: boolean;
                                readonly providerMember: {
                                    readonly __typename: 'ProviderMember';
                                    readonly id: string;
                                    readonly canDelete: boolean;
                                    readonly updatedAt: string;
                                    readonly createdAt: string;
                                    readonly user: {
                                        readonly __typename: 'User';
                                        readonly id: string;
                                        readonly pictureUrl?: string | null;
                                        readonly fullName: string;
                                        readonly givenName: string;
                                        readonly familyName?: string | null;
                                        readonly educationProvider?: {
                                            readonly __typename: 'Provider';
                                            readonly id: string;
                                            readonly title: string;
                                        } | null;
                                    };
                                    readonly roles: ReadonlyArray<{
                                        readonly __typename: 'ProviderRole';
                                        readonly id: string;
                                        readonly title: string;
                                    }>;
                                };
                            } | null;
                        }>;
                    };
                    readonly discussionUserState: {
                        readonly __typename: 'MentorGroupDiscussionUserState';
                        readonly lastRead?: string | null;
                        readonly lastTyping?: string | null;
                        readonly unreadMessageCount: number;
                        readonly mutedAt?: string | null;
                    };
                    readonly students: { readonly __typename: 'MentorGroupStudentConnection'; readonly totalCount: number };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type ProviderMentorGroupsSimpleQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProviderMentorGroupsSimpleQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorGroups: {
            readonly __typename: 'MentorGroupConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type ProviderMentorGroupsUnreadMessageCountQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
}>;

export type ProviderMentorGroupsUnreadMessageCountQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorGroups: { readonly __typename: 'MentorGroupConnection'; readonly unreadMessageCount: number };
    } | null;
};

export type ProviderMentorSharedResourcesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProviderMentorSharedResourcesQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorSharedResources: {
            readonly __typename: 'MentorSharedResourceConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorSharedResourceEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorSharedResource';
                    readonly id: string;
                    readonly sharedResourceType: MentorSharedResourceType;
                    readonly information: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type QueueImportMentorGroupsFromLearnerSmsDataMutationVariables = Exact<{
    input: QueueImportMentorGroupsFromLearnerSmsDataInput;
}>;

export type QueueImportMentorGroupsFromLearnerSmsDataMutation = {
    readonly __typename: 'Mutation';
    readonly queueImportMentorGroupsFromLearnerSmsData: {
        readonly __typename: 'QueueImportMentorGroupsFromLearnerSmsDataPayload';
        readonly success: boolean;
    };
};

export type RegenerateMentorGroupInviteCodeMutationVariables = Exact<{
    input: RegenerateMentorGroupInviteCodeInput;
}>;

export type RegenerateMentorGroupInviteCodeMutation = {
    readonly __typename: 'Mutation';
    readonly regenerateMentorGroupInviteCode: {
        readonly __typename: 'RegenerateMentorGroupInviteCodePayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type RemoveMentorGroupMemberMutationVariables = Exact<{
    input: RemoveMentorGroupMemberInput;
}>;

export type RemoveMentorGroupMemberMutation = {
    readonly __typename: 'Mutation';
    readonly removeMentorGroupMember: { readonly __typename: 'RemoveMentorGroupMemberPayload'; readonly mentorGroupMemberId: string };
};

export type RemoveMentorGroupStudentMutationVariables = Exact<{
    input: RemoveMentorGroupStudentInput;
}>;

export type RemoveMentorGroupStudentMutation = {
    readonly __typename: 'Mutation';
    readonly removeMentorGroupStudent: { readonly __typename: 'RemoveMentorGroupStudentPayload'; readonly mentorGroupStudentId: string };
};

export type SendMentorGroupInvitesMutationVariables = Exact<{
    input: SendMentorGroupInvitesInput;
}>;

export type SendMentorGroupInvitesMutation = {
    readonly __typename: 'Mutation';
    readonly sendMentorGroupInvites: {
        readonly __typename: 'SendMentorGroupInvitesPayload';
        readonly invites: ReadonlyArray<{
            readonly __typename: 'MentorStudentInvite';
            readonly id: string;
            readonly email: string;
            readonly redeemedAt?: string | null;
            readonly expiresAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly mentorGroups: ReadonlyArray<{
                readonly __typename: 'MentorGroup';
                readonly id: string;
                readonly title: string;
                readonly bannerImageUrl?: string | null;
                readonly information?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            }>;
        }>;
    };
};

export type StudentHomeworkDiaryDailyMentorTasksQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type StudentHomeworkDiaryDailyMentorTasksQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly studentHomeworkDiaryDailyMentorTasks: {
            readonly __typename: 'StudentHomeworkDiaryMentorTaskConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'StudentHomeworkDiaryMentorTaskEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'StudentHomeworkDiaryMentorTask';
                    readonly id: string;
                    readonly date: string;
                    readonly title: string;
                    readonly information?: string | null;
                    readonly imageId?: string | null;
                    readonly imageUrl?: string | null;
                    readonly color?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly studentsAssigned: {
                        readonly __typename: 'MentorStudentConnection';
                        readonly totalCount: number;
                        readonly pageInfo: {
                            readonly __typename: 'PageInfo';
                            readonly hasPreviousPage: boolean;
                            readonly hasNextPage: boolean;
                            readonly startCursor?: string | null;
                            readonly endCursor?: string | null;
                        };
                        readonly edges: ReadonlyArray<{
                            readonly __typename: 'MentorStudentEdge';
                            readonly cursor: string;
                            readonly node?: {
                                readonly __typename: 'MentorStudent';
                                readonly id: string;
                                readonly user: {
                                    readonly __typename: 'User';
                                    readonly id: string;
                                    readonly pictureUrl?: string | null;
                                    readonly fullName: string;
                                    readonly givenName: string;
                                    readonly familyName?: string | null;
                                    readonly educationProvider?: {
                                        readonly __typename: 'Provider';
                                        readonly id: string;
                                        readonly title: string;
                                    } | null;
                                };
                                readonly learnerSmsProfile?: {
                                    readonly __typename: 'LearnerSmsProfile';
                                    readonly id: string;
                                    readonly pictureUrl: string;
                                    readonly preferredGivenName: string;
                                    readonly preferredFamilyName?: string | null;
                                    readonly preferredFullName: string;
                                    readonly email: string;
                                    readonly idNumber: string;
                                    readonly nationalCurriculumYear: string;
                                } | null;
                            } | null;
                        }>;
                    };
                    readonly studentsCompleted: {
                        readonly __typename: 'MentorStudentConnection';
                        readonly totalCount: number;
                        readonly pageInfo: {
                            readonly __typename: 'PageInfo';
                            readonly hasPreviousPage: boolean;
                            readonly hasNextPage: boolean;
                            readonly startCursor?: string | null;
                            readonly endCursor?: string | null;
                        };
                        readonly edges: ReadonlyArray<{
                            readonly __typename: 'MentorStudentEdge';
                            readonly cursor: string;
                            readonly node?: {
                                readonly __typename: 'MentorStudent';
                                readonly id: string;
                                readonly user: {
                                    readonly __typename: 'User';
                                    readonly id: string;
                                    readonly pictureUrl?: string | null;
                                    readonly fullName: string;
                                    readonly givenName: string;
                                    readonly familyName?: string | null;
                                    readonly educationProvider?: {
                                        readonly __typename: 'Provider';
                                        readonly id: string;
                                        readonly title: string;
                                    } | null;
                                };
                                readonly learnerSmsProfile?: {
                                    readonly __typename: 'LearnerSmsProfile';
                                    readonly id: string;
                                    readonly pictureUrl: string;
                                    readonly preferredGivenName: string;
                                    readonly preferredFamilyName?: string | null;
                                    readonly preferredFullName: string;
                                    readonly email: string;
                                    readonly idNumber: string;
                                    readonly nationalCurriculumYear: string;
                                } | null;
                            } | null;
                        }>;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type StudentHomeworkDiaryMentorTaskMonthSummaryQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    date: Scalars['Date']['input'];
}>;

export type StudentHomeworkDiaryMentorTaskMonthSummaryQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly studentHomeworkDiaryMentorTaskMonthSummary: {
            readonly __typename: 'StudentHomeworkDiaryMentorTaskMonthSummary';
            readonly colors: ReadonlyArray<string | null>;
            readonly days: ReadonlyArray<{
                readonly __typename: 'StudentHomeworkDiaryMentorTaskDaySummary';
                readonly date: string;
                readonly colors: ReadonlyArray<string | null>;
            }>;
        };
    } | null;
};

export type StudentHomeworkDiaryMentorTaskStudentsAssignedQueryVariables = Exact<{
    studentHomeworkDiaryMentorTaskId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type StudentHomeworkDiaryMentorTaskStudentsAssignedQuery = {
    readonly __typename: 'Query';
    readonly studentHomeworkDiaryMentorTask?: {
        readonly __typename: 'StudentHomeworkDiaryMentorTask';
        readonly id: string;
        readonly studentsAssigned: {
            readonly __typename: 'MentorStudentConnection';
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorStudent';
                    readonly id: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                } | null;
            }>;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
        };
    } | null;
};

export type StudentHomeworkDiaryMentorTaskStudentsCompletedQueryVariables = Exact<{
    studentHomeworkDiaryMentorTaskId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type StudentHomeworkDiaryMentorTaskStudentsCompletedQuery = {
    readonly __typename: 'Query';
    readonly studentHomeworkDiaryMentorTask?: {
        readonly __typename: 'StudentHomeworkDiaryMentorTask';
        readonly id: string;
        readonly studentsCompleted: {
            readonly __typename: 'MentorStudentConnection';
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorStudent';
                    readonly id: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                } | null;
            }>;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
        };
    } | null;
};

export type ToggleMentorGroupJoinabilityMutationVariables = Exact<{
    input: ToggleMentorGroupJoinabilityInput;
}>;

export type ToggleMentorGroupJoinabilityMutation = {
    readonly __typename: 'Mutation';
    readonly toggleMentorGroupJoinability: {
        readonly __typename: 'ToggleMentorGroupJoinabilityPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly isJoinable: boolean;
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type TogglePinnedMentorGroupMutationVariables = Exact<{
    input: TogglePinnedMentorGroupInput;
}>;

export type TogglePinnedMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly togglePinnedMentorGroup: {
        readonly __typename: 'TogglePinnedMentorGroupPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly title: string;
            readonly description?: string | null;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly inviteCode: string;
            readonly hasPinned: boolean;
            readonly pinnedAt?: string | null;
            readonly isJoinable: boolean;
            readonly isPublic: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canAddMember: boolean;
            readonly canSendDiscussionMessage: boolean;
            readonly canRegenerateInviteCode: boolean;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly canAddMentorStudent: boolean;
            readonly canCreateDiscussionPoll: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly discussionUserState: {
                readonly __typename: 'MentorGroupDiscussionUserState';
                readonly lastRead?: string | null;
                readonly lastTyping?: string | null;
                readonly unreadMessageCount: number;
                readonly mutedAt?: string | null;
            };
        };
    };
};

export type UpdateMentorGroupBannerImageMutationVariables = Exact<{
    input: UpdateMentorGroupBannerImageInput;
}>;

export type UpdateMentorGroupBannerImageMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorGroupBannerImage: {
        readonly __typename: 'UpdateMentorGroupBannerImagePayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type UpdateMentorGroupInformationMutationVariables = Exact<{
    input: UpdateMentorGroupInformationInput;
}>;

export type UpdateMentorGroupInformationMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorGroupInformation: {
        readonly __typename: 'UpdateMentorGroupInformationPayload';
        readonly mentorGroup: { readonly __typename: 'MentorGroup'; readonly id: string; readonly information?: string | null };
    };
};

export type UpdateMentorGroupMutationVariables = Exact<{
    input: UpdateMentorGroupInput;
}>;

export type UpdateMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorGroup: {
        readonly __typename: 'UpdateMentorGroupPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly title: string;
            readonly description?: string | null;
            readonly createdAt: string;
            readonly updatedAt: string;
        };
    };
};

export type UpdateMentorSharedResourceMutationVariables = Exact<{
    input: UpdateMentorSharedResourceInput;
}>;

export type UpdateMentorSharedResourceMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorSharedResource: {
        readonly __typename: 'UpdateMentorSharedResourcePayload';
        readonly mentorSharedResource: {
            readonly __typename: 'MentorSharedResource';
            readonly id: string;
            readonly sharedResourceType: MentorSharedResourceType;
            readonly information: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateStudentHomeworkDiaryMentorTaskMutationVariables = Exact<{
    input: UpdateStudentHomeworkDiaryMentorTaskInput;
}>;

export type UpdateStudentHomeworkDiaryMentorTaskMutation = {
    readonly __typename: 'Mutation';
    readonly updateStudentHomeworkDiaryMentorTask: {
        readonly __typename: 'UpdateStudentHomeworkDiaryMentorTaskPayload';
        readonly studentHomeworkDiaryMentorTask: {
            readonly __typename: 'StudentHomeworkDiaryMentorTask';
            readonly id: string;
            readonly date: string;
            readonly title: string;
            readonly information?: string | null;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly color?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly studentsAssigned: {
                readonly __typename: 'MentorStudentConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'MentorStudentEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                    } | null;
                }>;
            };
            readonly studentsCompleted: {
                readonly __typename: 'MentorStudentConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'MentorStudentEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'MentorStudent';
                        readonly id: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly learnerSmsProfile?: {
                            readonly __typename: 'LearnerSmsProfile';
                            readonly id: string;
                            readonly pictureUrl: string;
                            readonly preferredGivenName: string;
                            readonly preferredFamilyName?: string | null;
                            readonly preferredFullName: string;
                            readonly email: string;
                            readonly idNumber: string;
                            readonly nationalCurriculumYear: string;
                        } | null;
                    } | null;
                }>;
            };
        };
    };
};

export type AcknowledgeMentorGroupDiscussionMessageMutationVariables = Exact<{
    input: AcknowledgeMentorGroupDiscussionMessageInput;
}>;

export type AcknowledgeMentorGroupDiscussionMessageMutation = {
    readonly __typename: 'Mutation';
    readonly acknowledgeMentorGroupDiscussionMessage: {
        readonly __typename: 'AcknowledgeMentorGroupDiscussionMessagePayload';
        readonly mentorGroupDiscussionMessage: {
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        };
    };
};

export type ChooseMentorGroupDiscussionPollResponseMutationVariables = Exact<{
    input: ChooseMentorGroupDiscussionPollResponseInput;
}>;

export type ChooseMentorGroupDiscussionPollResponseMutation = {
    readonly __typename: 'Mutation';
    readonly chooseMentorGroupDiscussionPollResponse: {
        readonly __typename: 'ChooseMentorGroupDiscussionPollResponsePayload';
        readonly mentorGroupDiscussionMessagePollResponse: {
            readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
            readonly id: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly mentorGroupDiscussionMessagePollOption: {
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            };
        };
        readonly mentorGroupDiscussionMessage: {
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        };
    };
};

export type DeleteMentorGroupDiscussionMessageMutationVariables = Exact<{
    input: DeleteMentorGroupDiscussionMessageInput;
}>;

export type DeleteMentorGroupDiscussionMessageMutation = {
    readonly __typename: 'Mutation';
    readonly deleteMentorGroupDiscussionMessage: {
        readonly __typename: 'DeleteMentorGroupDiscussionMessagePayload';
        readonly mentorGroupDiscussionMessageId: string;
    };
};

export type LockMentorGroupDiscussionMutationVariables = Exact<{
    input: LockMentorGroupDiscussionInput;
}>;

export type LockMentorGroupDiscussionMutation = {
    readonly __typename: 'Mutation';
    readonly lockMentorGroupDiscussion: {
        readonly __typename: 'LockMentorGroupDiscussionPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly isDiscussionLocked: boolean;
            readonly canLockDiscussion: boolean;
            readonly canSendDiscussionMessage: boolean;
            readonly canMuteDiscussionUser: boolean;
            readonly canCreateDiscussionPoll: boolean;
            readonly canRequireDiscussionAcknowledgement: boolean;
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly discussionUserState: {
                readonly __typename: 'MentorGroupDiscussionUserState';
                readonly lastRead?: string | null;
                readonly lastTyping?: string | null;
                readonly unreadMessageCount: number;
                readonly mutedAt?: string | null;
            };
            readonly discussionMessagesPinned?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessage';
                readonly id: string;
                readonly message: string;
                readonly messageLinks?: ReadonlyArray<string> | null;
                readonly imageAvailable: boolean;
                readonly imageId?: string | null;
                readonly imageUrl?: string | null;
                readonly isPinned: boolean;
                readonly pollExpiresAt?: string | null;
                readonly totalPollResponsesCount: number;
                readonly canViewPollResults: boolean;
                readonly requiresAcknowledgement: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canReact: boolean;
                readonly canPin: boolean;
                readonly canAcknowledge: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
                readonly reactions: {
                    readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                    readonly totalCount: number;
                    readonly topEmojis: ReadonlyArray<string>;
                };
                readonly myReaction?: {
                    readonly __typename: 'MentorGroupDiscussionMessageReaction';
                    readonly id: string;
                    readonly type: string;
                } | null;
                readonly pollOptions?: ReadonlyArray<{
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                }> | null;
                readonly selectedPollOption?: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                    readonly id: string;
                    readonly mentorGroupDiscussionMessagePollOption: {
                        readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                        readonly id: string;
                        readonly option: string;
                        readonly totalResponsesCount: number;
                        readonly totalResponsesCountPercentage: number;
                    };
                } | null;
                readonly acknowledgementStatuses?: {
                    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                    readonly totalCount: number;
                    readonly totalAcknowledgedCount: number;
                } | null;
                readonly myAcknowledgementStatus?: {
                    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                    readonly id: string;
                    readonly acknowledged: boolean;
                    readonly acknowledgedAt?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            } | null> | null;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type MentorGroupDiscussionMessageAcknowledgementStatusesQueryVariables = Exact<{
    messageId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupDiscussionMessageAcknowledgementStatusesQuery = {
    readonly __typename: 'Query';
    readonly mentorGroupDiscussionMessage?: {
        readonly __typename: 'MentorGroupDiscussionMessage';
        readonly id: string;
        readonly acknowledgementStatuses?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
            readonly totalCount: number;
            readonly totalAcknowledgedCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                    readonly id: string;
                    readonly acknowledged: boolean;
                    readonly acknowledgedAt?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        } | null;
    } | null;
};

export type MentorGroupDiscussionMessageChangedSubscriptionVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
}>;

export type MentorGroupDiscussionMessageChangedSubscription = {
    readonly __typename: 'Subscription';
    readonly mentorGroupDiscussionMessageChanged: {
        readonly __typename: 'MentorGroupDiscussionMessage';
        readonly id: string;
        readonly message: string;
        readonly messageLinks?: ReadonlyArray<string> | null;
        readonly imageAvailable: boolean;
        readonly imageId?: string | null;
        readonly imageUrl?: string | null;
        readonly isPinned: boolean;
        readonly pollExpiresAt?: string | null;
        readonly totalPollResponsesCount: number;
        readonly canViewPollResults: boolean;
        readonly requiresAcknowledgement: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canReact: boolean;
        readonly canPin: boolean;
        readonly canAcknowledge: boolean;
        readonly canDelete: boolean;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly reactions: {
            readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
            readonly totalCount: number;
            readonly topEmojis: ReadonlyArray<string>;
        };
        readonly myReaction?: {
            readonly __typename: 'MentorGroupDiscussionMessageReaction';
            readonly id: string;
            readonly type: string;
        } | null;
        readonly pollOptions?: ReadonlyArray<{
            readonly __typename: 'MentorGroupDiscussionMessagePollOption';
            readonly id: string;
            readonly option: string;
            readonly totalResponsesCount: number;
            readonly totalResponsesCountPercentage: number;
        }> | null;
        readonly selectedPollOption?: {
            readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
            readonly id: string;
            readonly mentorGroupDiscussionMessagePollOption: {
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            };
        } | null;
        readonly acknowledgementStatuses?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
            readonly totalCount: number;
            readonly totalAcknowledgedCount: number;
        } | null;
        readonly myAcknowledgementStatus?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
            readonly id: string;
            readonly acknowledged: boolean;
            readonly acknowledgedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        } | null;
    };
};

export type MentorGroupDiscussionMessageDeletedSubscriptionVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
}>;

export type MentorGroupDiscussionMessageDeletedSubscription = {
    readonly __typename: 'Subscription';
    readonly mentorGroupDiscussionMessageDeleted: string;
};

export type MentorGroupDiscussionMessageQueryVariables = Exact<{
    messageId: Scalars['UUID']['input'];
}>;

export type MentorGroupDiscussionMessageQuery = {
    readonly __typename: 'Query';
    readonly mentorGroupDiscussionMessage?: {
        readonly __typename: 'MentorGroupDiscussionMessage';
        readonly id: string;
        readonly message: string;
        readonly messageLinks?: ReadonlyArray<string> | null;
        readonly imageAvailable: boolean;
        readonly imageId?: string | null;
        readonly imageUrl?: string | null;
        readonly isPinned: boolean;
        readonly pollExpiresAt?: string | null;
        readonly totalPollResponsesCount: number;
        readonly canViewPollResults: boolean;
        readonly requiresAcknowledgement: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canReact: boolean;
        readonly canPin: boolean;
        readonly canAcknowledge: boolean;
        readonly canDelete: boolean;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly reactions: {
            readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
            readonly totalCount: number;
            readonly topEmojis: ReadonlyArray<string>;
        };
        readonly myReaction?: {
            readonly __typename: 'MentorGroupDiscussionMessageReaction';
            readonly id: string;
            readonly type: string;
        } | null;
        readonly pollOptions?: ReadonlyArray<{
            readonly __typename: 'MentorGroupDiscussionMessagePollOption';
            readonly id: string;
            readonly option: string;
            readonly totalResponsesCount: number;
            readonly totalResponsesCountPercentage: number;
        }> | null;
        readonly selectedPollOption?: {
            readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
            readonly id: string;
            readonly mentorGroupDiscussionMessagePollOption: {
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            };
        } | null;
        readonly acknowledgementStatuses?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
            readonly totalCount: number;
            readonly totalAcknowledgedCount: number;
        } | null;
        readonly myAcknowledgementStatus?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
            readonly id: string;
            readonly acknowledged: boolean;
            readonly acknowledgedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        } | null;
    } | null;
};

export type MentorGroupDiscussionMessageSentSubscriptionVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
}>;

export type MentorGroupDiscussionMessageSentSubscription = {
    readonly __typename: 'Subscription';
    readonly mentorGroupDiscussionMessageSent: {
        readonly __typename: 'MentorGroupDiscussionMessage';
        readonly id: string;
        readonly message: string;
        readonly messageLinks?: ReadonlyArray<string> | null;
        readonly imageAvailable: boolean;
        readonly imageId?: string | null;
        readonly imageUrl?: string | null;
        readonly isPinned: boolean;
        readonly pollExpiresAt?: string | null;
        readonly totalPollResponsesCount: number;
        readonly canViewPollResults: boolean;
        readonly requiresAcknowledgement: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canReact: boolean;
        readonly canPin: boolean;
        readonly canAcknowledge: boolean;
        readonly canDelete: boolean;
        readonly lesson?: {
            readonly __typename: 'Lesson';
            readonly id: string;
            readonly locale: string;
            readonly title: string;
            readonly description: string;
            readonly coverImageUrl?: string | null;
            readonly documentId: string;
            readonly documentIframeUrl?: string | null;
            readonly tags: ReadonlyArray<string>;
            readonly isFeatured: boolean;
            readonly countryCodes: ReadonlyArray<string>;
            readonly favoriteCount: number;
            readonly hasRead: boolean;
            readonly hasFavorited: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lessonCategory: {
                readonly __typename: 'LessonCategory';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly parent?: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                } | null;
            };
        } | null;
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly reactions: {
            readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
            readonly totalCount: number;
            readonly topEmojis: ReadonlyArray<string>;
        };
        readonly myReaction?: {
            readonly __typename: 'MentorGroupDiscussionMessageReaction';
            readonly id: string;
            readonly type: string;
        } | null;
        readonly pollOptions?: ReadonlyArray<{
            readonly __typename: 'MentorGroupDiscussionMessagePollOption';
            readonly id: string;
            readonly option: string;
            readonly totalResponsesCount: number;
            readonly totalResponsesCountPercentage: number;
        }> | null;
        readonly selectedPollOption?: {
            readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
            readonly id: string;
            readonly mentorGroupDiscussionMessagePollOption: {
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            };
        } | null;
        readonly acknowledgementStatuses?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
            readonly totalCount: number;
            readonly totalAcknowledgedCount: number;
        } | null;
        readonly myAcknowledgementStatus?: {
            readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
            readonly id: string;
            readonly acknowledged: boolean;
            readonly acknowledgedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        } | null;
    };
};

export type MentorGroupDiscussionMessagesQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupDiscussionMessagesQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly canSendDiscussionMessage: boolean;
        readonly discussionMessages: {
            readonly __typename: 'MentorGroupDiscussionMessageConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessageEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroupDiscussionMessage';
                    readonly id: string;
                    readonly message: string;
                    readonly messageLinks?: ReadonlyArray<string> | null;
                    readonly imageAvailable: boolean;
                    readonly imageId?: string | null;
                    readonly imageUrl?: string | null;
                    readonly isPinned: boolean;
                    readonly pollExpiresAt?: string | null;
                    readonly totalPollResponsesCount: number;
                    readonly canViewPollResults: boolean;
                    readonly requiresAcknowledgement: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canReact: boolean;
                    readonly canPin: boolean;
                    readonly canAcknowledge: boolean;
                    readonly canDelete: boolean;
                    readonly lesson?: {
                        readonly __typename: 'Lesson';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly description: string;
                        readonly coverImageUrl?: string | null;
                        readonly documentId: string;
                        readonly documentIframeUrl?: string | null;
                        readonly tags: ReadonlyArray<string>;
                        readonly isFeatured: boolean;
                        readonly countryCodes: ReadonlyArray<string>;
                        readonly favoriteCount: number;
                        readonly hasRead: boolean;
                        readonly hasFavorited: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly lessonCategory: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                            readonly canUpdate: boolean;
                            readonly canDelete: boolean;
                            readonly parent?: {
                                readonly __typename: 'LessonCategory';
                                readonly id: string;
                                readonly locale: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly reactions: {
                        readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                        readonly totalCount: number;
                        readonly topEmojis: ReadonlyArray<string>;
                    };
                    readonly myReaction?: {
                        readonly __typename: 'MentorGroupDiscussionMessageReaction';
                        readonly id: string;
                        readonly type: string;
                    } | null;
                    readonly pollOptions?: ReadonlyArray<{
                        readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                        readonly id: string;
                        readonly option: string;
                        readonly totalResponsesCount: number;
                        readonly totalResponsesCountPercentage: number;
                    }> | null;
                    readonly selectedPollOption?: {
                        readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                        readonly id: string;
                        readonly mentorGroupDiscussionMessagePollOption: {
                            readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                            readonly id: string;
                            readonly option: string;
                            readonly totalResponsesCount: number;
                            readonly totalResponsesCountPercentage: number;
                        };
                    } | null;
                    readonly acknowledgementStatuses?: {
                        readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                        readonly totalCount: number;
                        readonly totalAcknowledgedCount: number;
                    } | null;
                    readonly myAcknowledgementStatus?: {
                        readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                        readonly id: string;
                        readonly acknowledged: boolean;
                        readonly acknowledgedAt?: string | null;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                } | null;
            }>;
        };
        readonly discussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
    } | null;
};

export type MentorGroupDiscussionMutedUsersQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupDiscussionMutedUsersQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly mentorGroupDiscussionMutedUsers: {
            readonly __typename: 'MentorGroupDiscussionMutedUserConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMutedUserEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                } | null;
            }>;
        };
    } | null;
};

export type MentorGroupDiscussionQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
}>;

export type MentorGroupDiscussionQuery = {
    readonly __typename: 'Query';
    readonly me: { readonly __typename: 'UserData'; readonly id: string };
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly isDiscussionLocked: boolean;
        readonly canLockDiscussion: boolean;
        readonly canSendDiscussionMessage: boolean;
        readonly canMuteDiscussionUser: boolean;
        readonly canCreateDiscussionPoll: boolean;
        readonly canRequireDiscussionAcknowledgement: boolean;
        readonly id: string;
        readonly title: string;
        readonly bannerImageUrl?: string | null;
        readonly information?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly discussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
        readonly discussionMessagesPinned?: ReadonlyArray<{
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        } | null> | null;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    } | null;
};

export type MuteMentorGroupDiscussionUserMutationVariables = Exact<{
    input: MuteMentorGroupDiscussionUserInput;
}>;

export type MuteMentorGroupDiscussionUserMutation = {
    readonly __typename: 'Mutation';
    readonly muteMentorGroupDiscussionUser: {
        readonly __typename: 'MuteMentorGroupDiscussionUserPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly mentorGroupDiscussionMutedUsers: {
                readonly __typename: 'MentorGroupDiscussionMutedUserConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'MentorGroupDiscussionMutedUserEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    } | null;
                }>;
            };
        };
    };
};

export type PinMentorGroupDiscussionMessageMutationVariables = Exact<{
    input: PinMentorGroupDiscussionMessageInput;
}>;

export type PinMentorGroupDiscussionMessageMutation = {
    readonly __typename: 'Mutation';
    readonly pinMentorGroupDiscussionMessage: {
        readonly __typename: 'PinMentorGroupDiscussionMessagePayload';
        readonly mentorGroupDiscussionMessage: {
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        };
    };
};

export type ReactMentorGroupDiscussionMessageMutationVariables = Exact<{
    input: ReactMentorGroupDiscussionMessageInput;
}>;

export type ReactMentorGroupDiscussionMessageMutation = {
    readonly __typename: 'Mutation';
    readonly reactMentorGroupDiscussionMessage: {
        readonly __typename: 'ReactMentorGroupDiscussionMessagePayload';
        readonly mentorGroupDiscussionMessage: {
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        };
    };
};

export type SendMentorGroupDiscussionMessageMutationVariables = Exact<{
    input: SendMentorGroupDiscussionMessageInput;
}>;

export type SendMentorGroupDiscussionMessageMutation = {
    readonly __typename: 'Mutation';
    readonly sendMentorGroupDiscussionMessage: {
        readonly __typename: 'SendMentorGroupDiscussionMessagePayload';
        readonly mentorGroupDiscussionMessage: {
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        };
    };
};

export type StartMentorGroupDiscussionMessageImageUploadMutationVariables = Exact<{
    input: StartMentorGroupDiscussionMessageImageUploadInput;
}>;

export type StartMentorGroupDiscussionMessageImageUploadMutation = {
    readonly __typename: 'Mutation';
    readonly startMentorGroupDiscussionMessageImageUpload: {
        readonly __typename: 'StartMentorGroupDiscussionMessageImageUploadPayload';
        readonly fileId: string;
        readonly uploadUrl: string;
    };
};

export type UnlockMentorGroupDiscussionMutationVariables = Exact<{
    input: UnlockMentorGroupDiscussionInput;
}>;

export type UnlockMentorGroupDiscussionMutation = {
    readonly __typename: 'Mutation';
    readonly unlockMentorGroupDiscussion: {
        readonly __typename: 'UnlockMentorGroupDiscussionPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly isDiscussionLocked: boolean;
            readonly canLockDiscussion: boolean;
            readonly canSendDiscussionMessage: boolean;
            readonly canMuteDiscussionUser: boolean;
            readonly canCreateDiscussionPoll: boolean;
            readonly canRequireDiscussionAcknowledgement: boolean;
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly discussionUserState: {
                readonly __typename: 'MentorGroupDiscussionUserState';
                readonly lastRead?: string | null;
                readonly lastTyping?: string | null;
                readonly unreadMessageCount: number;
                readonly mutedAt?: string | null;
            };
            readonly discussionMessagesPinned?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessage';
                readonly id: string;
                readonly message: string;
                readonly messageLinks?: ReadonlyArray<string> | null;
                readonly imageAvailable: boolean;
                readonly imageId?: string | null;
                readonly imageUrl?: string | null;
                readonly isPinned: boolean;
                readonly pollExpiresAt?: string | null;
                readonly totalPollResponsesCount: number;
                readonly canViewPollResults: boolean;
                readonly requiresAcknowledgement: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canReact: boolean;
                readonly canPin: boolean;
                readonly canAcknowledge: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
                readonly reactions: {
                    readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                    readonly totalCount: number;
                    readonly topEmojis: ReadonlyArray<string>;
                };
                readonly myReaction?: {
                    readonly __typename: 'MentorGroupDiscussionMessageReaction';
                    readonly id: string;
                    readonly type: string;
                } | null;
                readonly pollOptions?: ReadonlyArray<{
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                }> | null;
                readonly selectedPollOption?: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                    readonly id: string;
                    readonly mentorGroupDiscussionMessagePollOption: {
                        readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                        readonly id: string;
                        readonly option: string;
                        readonly totalResponsesCount: number;
                        readonly totalResponsesCountPercentage: number;
                    };
                } | null;
                readonly acknowledgementStatuses?: {
                    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                    readonly totalCount: number;
                    readonly totalAcknowledgedCount: number;
                } | null;
                readonly myAcknowledgementStatus?: {
                    readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                    readonly id: string;
                    readonly acknowledged: boolean;
                    readonly acknowledgedAt?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            } | null> | null;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type UnmuteMentorGroupDiscussionUserMutationVariables = Exact<{
    input: UnmuteMentorGroupDiscussionUserInput;
}>;

export type UnmuteMentorGroupDiscussionUserMutation = {
    readonly __typename: 'Mutation';
    readonly unmuteMentorGroupDiscussionUser: {
        readonly __typename: 'UnmuteMentorGroupDiscussionUserPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly id: string;
            readonly mentorGroupDiscussionMutedUsers: {
                readonly __typename: 'MentorGroupDiscussionMutedUserConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'MentorGroupDiscussionMutedUserEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    } | null;
                }>;
            };
        };
    };
};

export type UnpinMentorGroupDiscussionMessageMutationVariables = Exact<{
    input: UnpinMentorGroupDiscussionMessageInput;
}>;

export type UnpinMentorGroupDiscussionMessageMutation = {
    readonly __typename: 'Mutation';
    readonly unpinMentorGroupDiscussionMessage: {
        readonly __typename: 'UnpinMentorGroupDiscussionMessagePayload';
        readonly mentorGroupDiscussionMessage: {
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        };
    };
};

export type UnreactMentorGroupDiscussionMessageMutationVariables = Exact<{
    input: UnreactMentorGroupDiscussionMessageInput;
}>;

export type UnreactMentorGroupDiscussionMessageMutation = {
    readonly __typename: 'Mutation';
    readonly unreactMentorGroupDiscussionMessage: {
        readonly __typename: 'UnreactMentorGroupDiscussionMessagePayload';
        readonly mentorGroupDiscussionMessage: {
            readonly __typename: 'MentorGroupDiscussionMessage';
            readonly id: string;
            readonly message: string;
            readonly messageLinks?: ReadonlyArray<string> | null;
            readonly imageAvailable: boolean;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly isPinned: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly requiresAcknowledgement: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canReact: boolean;
            readonly canPin: boolean;
            readonly canAcknowledge: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly reactions: {
                readonly __typename: 'MentorGroupDiscussionMessageReactionConnection';
                readonly totalCount: number;
                readonly topEmojis: ReadonlyArray<string>;
            };
            readonly myReaction?: {
                readonly __typename: 'MentorGroupDiscussionMessageReaction';
                readonly id: string;
                readonly type: string;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                readonly id: string;
                readonly option: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'MentorGroupDiscussionMessagePollResponse';
                readonly id: string;
                readonly mentorGroupDiscussionMessagePollOption: {
                    readonly __typename: 'MentorGroupDiscussionMessagePollOption';
                    readonly id: string;
                    readonly option: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
            readonly acknowledgementStatuses?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatusConnection';
                readonly totalCount: number;
                readonly totalAcknowledgedCount: number;
            } | null;
            readonly myAcknowledgementStatus?: {
                readonly __typename: 'MentorGroupDiscussionMessageAcknowledgementStatus';
                readonly id: string;
                readonly acknowledged: boolean;
                readonly acknowledgedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            } | null;
        };
    };
};

export type UpdateMentorGroupDiscussionLastReadMutationVariables = Exact<{
    input: UpdateMentorGroupDiscussionLastReadInput;
}>;

export type UpdateMentorGroupDiscussionLastReadMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorGroupDiscussionLastRead: {
        readonly __typename: 'UpdateMentorGroupDiscussionLastReadPayload';
        readonly mentorGroupDiscussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
    };
};

export type UpdateMentorGroupDiscussionLastTypingMutationVariables = Exact<{
    input: UpdateMentorGroupDiscussionLastTypingInput;
}>;

export type UpdateMentorGroupDiscussionLastTypingMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorGroupDiscussionLastTyping: {
        readonly __typename: 'UpdateMentorGroupDiscussionLastTypingPayload';
        readonly mentorGroupDiscussionUserState: {
            readonly __typename: 'MentorGroupDiscussionUserState';
            readonly lastRead?: string | null;
            readonly lastTyping?: string | null;
            readonly unreadMessageCount: number;
            readonly mutedAt?: string | null;
        };
    };
};

export type AcceptMentorStudentInviteCodeMutationVariables = Exact<{
    input: AcceptMentorStudentInviteCodeInput;
}>;

export type AcceptMentorStudentInviteCodeMutation = {
    readonly __typename: 'Mutation';
    readonly acceptMentorStudentInviteCode: {
        readonly __typename: 'AcceptMentorStudentInviteCodePayload';
        readonly mentorStudent: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly learnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly pictureUrl: string;
                readonly preferredGivenName: string;
                readonly preferredFamilyName?: string | null;
                readonly preferredFullName: string;
                readonly email: string;
                readonly idNumber: string;
                readonly nationalCurriculumYear: string;
            } | null;
        };
    };
};

export type CreateMentorStudentNoteMutationVariables = Exact<{
    input: CreateMentorStudentNoteInput;
}>;

export type CreateMentorStudentNoteMutation = {
    readonly __typename: 'Mutation';
    readonly createMentorStudentNote: {
        readonly __typename: 'CreateMentorStudentNotePayload';
        readonly mentorStudentNote: {
            readonly __typename: 'MentorStudentNote';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateMentorStudentTodoMutationVariables = Exact<{
    input: CreateMentorStudentTodoInput;
}>;

export type CreateMentorStudentTodoMutation = {
    readonly __typename: 'Mutation';
    readonly createMentorStudentTodo: {
        readonly __typename: 'CreateMentorStudentTodoPayload';
        readonly mentorStudentTodo: {
            readonly __typename: 'MentorStudentTodo';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly complete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type DeleteMentorStudentNoteMutationVariables = Exact<{
    input: DeleteMentorStudentNoteInput;
}>;

export type DeleteMentorStudentNoteMutation = {
    readonly __typename: 'Mutation';
    readonly deleteMentorStudentNote: { readonly __typename: 'DeleteMentorStudentNotePayload'; readonly mentorStudentNoteId: string };
};

export type DeleteMentorStudentTodoMutationVariables = Exact<{
    input: DeleteMentorStudentTodoInput;
}>;

export type DeleteMentorStudentTodoMutation = {
    readonly __typename: 'Mutation';
    readonly deleteMentorStudentTodo: { readonly __typename: 'DeleteMentorStudentTodoPayload'; readonly mentorStudentTodoId: string };
};

export type JoinAvailableMentorGroupMutationVariables = Exact<{
    input: JoinAvailableMentorGroupInput;
}>;

export type JoinAvailableMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly joinAvailableMentorGroup: {
        readonly __typename: 'JoinAvailableMentorGroupPayload';
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly hasJoinedAsStudent: boolean;
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type JoinAvailableMentorProviderMutationVariables = Exact<{
    input: JoinAvailableMentorProviderInput;
}>;

export type JoinAvailableMentorProviderMutation = {
    readonly __typename: 'Mutation';
    readonly joinAvailableMentorProvider: {
        readonly __typename: 'JoinAvailableMentorProviderPayload';
        readonly mentorStudent: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly learnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly pictureUrl: string;
                readonly preferredGivenName: string;
                readonly preferredFamilyName?: string | null;
                readonly preferredFullName: string;
                readonly email: string;
                readonly idNumber: string;
                readonly nationalCurriculumYear: string;
            } | null;
        };
    };
};

export type JoinJoinableMentorGroupMutationVariables = Exact<{
    input: JoinJoinableMentorGroupInput;
}>;

export type JoinJoinableMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly joinJoinableMentorGroup: {
        readonly __typename: 'JoinJoinableMentorGroupPayload';
        readonly joinableMentorGroup: {
            readonly __typename: 'JoinableMentorGroup';
            readonly id: string;
            readonly title: string;
            readonly description?: string | null;
            readonly bannerImageUrl?: string | null;
            readonly totalStudentCount: number;
            readonly hasConsent: boolean;
            readonly hasJoined: boolean;
            readonly members: {
                readonly __typename: 'JoinableMentorGroupMemberConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'JoinableMentorGroupMemberEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    } | null;
                }>;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
        readonly mentorGroup: {
            readonly __typename: 'MentorGroup';
            readonly hasJoinedAsStudent: boolean;
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly information?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type JoinableMentorGroupsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
    privateOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type JoinableMentorGroupsQuery = {
    readonly __typename: 'Query';
    readonly joinableMentorGroups: {
        readonly __typename: 'JoinableMentorGroupConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'JoinableMentorGroupEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'JoinableMentorGroup';
                readonly id: string;
                readonly title: string;
                readonly description?: string | null;
                readonly bannerImageUrl?: string | null;
                readonly totalStudentCount: number;
                readonly hasConsent: boolean;
                readonly hasJoined: boolean;
                readonly members: {
                    readonly __typename: 'JoinableMentorGroupMemberConnection';
                    readonly totalCount: number;
                    readonly pageInfo: {
                        readonly __typename: 'PageInfo';
                        readonly hasPreviousPage: boolean;
                        readonly hasNextPage: boolean;
                        readonly startCursor?: string | null;
                        readonly endCursor?: string | null;
                    };
                    readonly edges: ReadonlyArray<{
                        readonly __typename: 'JoinableMentorGroupMemberEdge';
                        readonly cursor: string;
                        readonly node?: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        } | null;
                    }>;
                };
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
        }>;
    };
};

export type MentorStudentAssessmentResultsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
}>;

export type MentorStudentAssessmentResultsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly assessmentResults: ReadonlyArray<{
            readonly __typename: 'AssessmentResult';
            readonly id: string;
            readonly type: AssessmentResultType;
            readonly number: string;
            readonly version: string;
            readonly level?: number | null;
            readonly credits?: number | null;
            readonly weighting?: number | null;
            readonly points?: number | null;
            readonly title?: string | null;
            readonly description?: string | null;
            readonly purpose?: string | null;
            readonly subField?: string | null;
            readonly date?: string | null;
            readonly subject?: string | null;
            readonly result?: string | null;
            readonly comment?: string | null;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        }>;
    } | null;
};

export type MentorStudentAttendanceSummaryQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type MentorStudentAttendanceSummaryQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly attendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
    } | null;
};

export type MentorStudentAttendanceYearSummaryQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    year: Scalars['Int']['input'];
}>;

export type MentorStudentAttendanceYearSummaryQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly januaryAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly februaryAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly marchAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly aprilAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly mayAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly juneAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly julyAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly augustAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly septemberAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly octoberAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly novemberAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
        readonly decemberAttendanceSummary?: {
            readonly __typename: 'LearnerSmsAttendanceSummary';
            readonly halfDaysJustified: number;
            readonly halfDaysUnjustified: number;
            readonly halfDaysPresent: number;
            readonly halfDaysTotal: number;
            readonly standing: LearnerSmsAttendanceSummaryStanding;
        } | null;
    } | null;
};

export type MentorStudentAvailableMentorGroupsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentAvailableMentorGroupsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly availableMentorGroups: {
            readonly __typename: 'AvailableMentorGroupConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroup';
                    readonly hasJoinedAsStudent: boolean;
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorStudentCurriculumVitaeQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
}>;

export type MentorStudentCurriculumVitaeQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly curriculumVitae?: {
            readonly __typename: 'CurriculumVitae';
            readonly id: string;
            readonly profileImageId?: string | null;
            readonly profileImageUrl?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly personalDetails: {
                readonly __typename: 'CurriculumVitaePersonalDetails';
                readonly givenName?: string | null;
                readonly familyName?: string | null;
                readonly dob?: string | null;
                readonly languageLocales?: ReadonlyArray<string> | null;
                readonly interests?: string | null;
                readonly driversLicenceType?: DriversLicenceType | null;
                readonly phoneNumber?: string | null;
                readonly email?: string | null;
                readonly street?: string | null;
                readonly suburb?: string | null;
                readonly city?: string | null;
                readonly countryCode?: string | null;
            };
            readonly personalStatement: { readonly __typename: 'CurriculumVitaePersonalStatement'; readonly description?: string | null };
            readonly employment: {
                readonly __typename: 'CurriculumVitaeEmployment';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEmploymentEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly volunteer: {
                readonly __typename: 'CurriculumVitaeVolunteer';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeVolunteerEntry';
                    readonly title: string;
                    readonly company: string;
                    readonly start: string;
                    readonly end?: string | null;
                    readonly description?: string | null;
                }> | null;
            };
            readonly education: {
                readonly __typename: 'CurriculumVitaeEducation';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeEducationEntry';
                    readonly title: string;
                    readonly school: string;
                    readonly year: string;
                    readonly subjects?: ReadonlyArray<string> | null;
                }> | null;
            };
            readonly skills: { readonly __typename: 'CurriculumVitaeSkills'; readonly description?: string | null };
            readonly personalAttributes: {
                readonly __typename: 'CurriculumVitaePersonalAttributes';
                readonly attributes?: ReadonlyArray<CurriculumVitaePersonalAttribute> | null;
            };
            readonly achievementsAndAwards: {
                readonly __typename: 'CurriculumVitaeAchievementsAndAwards';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeAchievementsAndAwardsEntry';
                    readonly description: string;
                    readonly year: string;
                }> | null;
            };
            readonly badges: {
                readonly __typename: 'CurriculumVitaeBadges';
                readonly selectedBadgesList: ReadonlyArray<{
                    readonly __typename: 'Badge';
                    readonly id: string;
                    readonly title: string;
                    readonly comment: string;
                    readonly badgeImageUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly resourceHubItems: {
                readonly __typename: 'CurriculumVitaeResourceHubItems';
                readonly selectedResourceHubItemsList: ReadonlyArray<{
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            };
            readonly extracurricularActivities: {
                readonly __typename: 'CurriculumVitaeExtracurricularActivities';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeExtracurricularActivitiesEntry';
                    readonly description: string;
                    readonly year: string;
                    readonly toYear?: string | null;
                }> | null;
            };
            readonly references: {
                readonly __typename: 'CurriculumVitaeReferences';
                readonly entries?: ReadonlyArray<{
                    readonly __typename: 'CurriculumVitaeReferenceEntry';
                    readonly name: string;
                    readonly company: string;
                    readonly position: string;
                    readonly email: string;
                    readonly phoneNumber: string;
                }> | null;
            };
            readonly generatedFiles: ReadonlyArray<{
                readonly __typename: 'CurriculumVitaeGeneratedFile';
                readonly id: string;
                readonly fileId: string;
                readonly fileUrl: string;
                readonly generatedAt: string;
            }>;
        } | null;
    } | null;
};

export type MentorStudentGoalsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentGoalsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly goals?: {
            readonly __typename: 'GoalConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'GoalEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Goal';
                    readonly id: string;
                    readonly goalType: GoalType;
                    readonly title: string;
                    readonly imageUrl?: string | null;
                    readonly imageId?: string | null;
                    readonly information: string;
                    readonly complete: boolean;
                    readonly dueAt?: string | null;
                    readonly color?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly canCreateTask: boolean;
                    readonly canCreateNote: boolean;
                    readonly tasks: ReadonlyArray<{
                        readonly __typename: 'GoalTask';
                        readonly id: string;
                        readonly information: string;
                        readonly complete: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    }>;
                    readonly notes: ReadonlyArray<{
                        readonly __typename: 'GoalNote';
                        readonly id: string;
                        readonly information: string;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    }>;
                } | null;
            }>;
        } | null;
    } | null;
};

export type MentorStudentHomeworkDiaryDailyItemsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentHomeworkDiaryDailyItemsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly studentHomeworkDiaryDailyItems: {
            readonly __typename: 'StudentHomeworkDiaryConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'StudentHomeworkDiaryEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'StudentHomeworkDiaryItem';
                    readonly id: string;
                    readonly date: string;
                    readonly information?: string | null;
                    readonly imageId?: string | null;
                    readonly imageUrl?: string | null;
                    readonly color?: string | null;
                    readonly completedAt?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                } | null;
            }>;
        };
    } | null;
};

export type MentorStudentHomeworkDiaryMonthSummaryQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    date: Scalars['Date']['input'];
}>;

export type MentorStudentHomeworkDiaryMonthSummaryQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly studentHomeworkDiaryMonthSummary: {
            readonly __typename: 'StudentHomeworkDiaryMonthSummary';
            readonly completed: number;
            readonly total: number;
            readonly colors: ReadonlyArray<string | null>;
            readonly days: ReadonlyArray<{
                readonly __typename: 'StudentHomeworkDiaryDaySummary';
                readonly date: string;
                readonly completed: number;
                readonly total: number;
                readonly colors: ReadonlyArray<string | null>;
            }>;
        };
    } | null;
};

export type MentorStudentJobProfileActivitiesQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentJobProfileActivitiesQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly jobProfile?: {
            readonly __typename: 'JobProfile';
            readonly id: string;
            readonly activities: {
                readonly __typename: 'JobProfileActivitiesConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'JobProfileActivityEdge';
                    readonly node?: {
                        readonly __typename: 'JobProfileActivity';
                        readonly id: string;
                        readonly activityType: JobProfileActivityType;
                        readonly actorProviderTitle?: string | null;
                        readonly actorFullName?: string | null;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly actorProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                        readonly actor?: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                        } | null;
                    } | null;
                }>;
            };
        } | null;
    } | null;
};

export type MentorStudentJobProfileQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
}>;

export type MentorStudentJobProfileQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly jobProfile?: {
            readonly __typename: 'JobProfile';
            readonly id: string;
            readonly curriculumVitaeCompletedSections: ReadonlyArray<JobProfileRequiredSection>;
            readonly preferredJobCategories: ReadonlyArray<JobCategory>;
            readonly preferredJobTypes: ReadonlyArray<JobType>;
            readonly preferredJobAvailability: string;
            readonly canEnable: boolean;
            readonly enabledAt?: string | null;
            readonly preferredJobGeoLocations: ReadonlyArray<{
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            }>;
        } | null;
    } | null;
};

export type MentorStudentNotViewedSharedNotesAndTodosCountQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type MentorStudentNotViewedSharedNotesAndTodosCountQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly todos: { readonly __typename: 'MentorStudentTodoConnection'; readonly notViewedCount: number };
        readonly notes: { readonly __typename: 'MentorStudentNoteConnection'; readonly notViewedCount: number };
    } | null;
};

export type MentorStudentNotesQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type MentorStudentNotesQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly notes: {
            readonly __typename: 'MentorStudentNoteConnection';
            readonly notViewedCount: number;
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorStudentNoteEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorStudentNote';
                    readonly id: string;
                    readonly information: string;
                    readonly isShared: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorStudentPathwayPlannersQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentPathwayPlannersQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly pathwayPlanners?: {
            readonly __typename: 'PathwayPlannerConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'PathwayPlanner';
                    readonly id: string;
                    readonly title: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly usefulExperienceList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly qualificationsList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerQualificationTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly personalRequirementsList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly skillsAndKnowledgeList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly stagesList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerStage';
                        readonly id: string;
                        readonly title: string;
                        readonly usefulExperienceList?: ReadonlyArray<{
                            readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                            readonly id: string;
                            readonly completed: boolean;
                            readonly title: string;
                        }> | null;
                        readonly subjectsList?: ReadonlyArray<{
                            readonly __typename: 'PathwayPlannerStageSubjectTodo';
                            readonly id: string;
                            readonly completed: boolean;
                            readonly title: string;
                        }> | null;
                    }> | null;
                } | null;
            }>;
        } | null;
    } | null;
};

export type MentorStudentProjectsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentProjectsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly projects: {
            readonly __typename: 'ProjectConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProjectEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Project';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly bannerImageId?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly totalCompletedProjectSections: number;
                    readonly totalProjectSections: number;
                    readonly latestReviewRequestedAt?: string | null;
                    readonly completedAt?: string | null;
                    readonly canUpdateMentorGroup: boolean;
                    readonly canDelete: boolean;
                    readonly isLocked: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorStudentReflectionsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentReflectionsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly reflections?: {
            readonly __typename: 'ReflectionConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ReflectionEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Reflection';
                    readonly id: string;
                    readonly reflectionType: ReflectionType;
                    readonly information: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                } | null;
            }>;
        } | null;
    } | null;
};

export type MentorStudentResourceHubItemsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    mimeTypes?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type MentorStudentResourceHubItemsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly resourceHubItems?: {
            readonly __typename: 'ResourceHubItemConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ResourceHubItemEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        } | null;
    } | null;
};

export type MentorStudentSavedStudyProgrammesQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorStudentSavedStudyProgrammesQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly savedStudyProgrammes?: {
            readonly __typename: 'SavedStudyProgrammeConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'SavedStudyProgrammeEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'StudyProgramme';
                    readonly id: string;
                    readonly title: string;
                    readonly link: string;
                    readonly studyProvider: {
                        readonly __typename: 'StudyProvider';
                        readonly id: string;
                        readonly countryCode: string;
                        readonly title: string;
                        readonly logoImageUrl?: string | null;
                        readonly isEnquiriesEnabled: boolean;
                        readonly isEnrolmentEnabled: boolean;
                        readonly enrolmentTypesEnabled: ReadonlyArray<StudyProgrammeType>;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    };
                } | null;
            }>;
        } | null;
    } | null;
};

export type MentorStudentSimpleQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
}>;

export type MentorStudentSimpleQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly learnerSmsProfile?: {
            readonly __typename: 'LearnerSmsProfile';
            readonly id: string;
            readonly pictureUrl: string;
            readonly preferredGivenName: string;
            readonly preferredFamilyName?: string | null;
            readonly preferredFullName: string;
            readonly email: string;
            readonly idNumber: string;
            readonly nationalCurriculumYear: string;
        } | null;
    } | null;
};

export type MentorStudentSummaryQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
    rewards?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MentorStudentSummaryQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly isAssessmentResultsAvailable: boolean;
        readonly isAttendanceAvailable: boolean;
        readonly isDailyNoticesAvailable: boolean;
        readonly isTimetableAvailable: boolean;
        readonly isSharingCurriculumVitae: boolean;
        readonly isSharingGoals: boolean;
        readonly isSharingPathwayPlanners: boolean;
        readonly isSharingReflections: boolean;
        readonly isSharingResourceHubItems: boolean;
        readonly isSharingSavedCourses: boolean;
        readonly sharedActivity?: ReadonlyArray<MentorStudentSharedActivityType> | null;
        readonly isSharingOtherProviderActivity: boolean;
        readonly canRemove: boolean;
        readonly rewards?: {
            readonly __typename: 'MentorRewardStudent';
            readonly id: string;
            readonly availablePoints: number;
            readonly totalPoints: number;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly learnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly pictureUrl: string;
                readonly preferredGivenName: string;
                readonly preferredFamilyName?: string | null;
                readonly preferredFullName: string;
                readonly email: string;
                readonly idNumber: string;
                readonly nationalCurriculumYear: string;
            } | null;
        };
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly learnerSmsProfile?: {
            readonly __typename: 'LearnerSmsProfile';
            readonly id: string;
            readonly pictureUrl: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly fullName: string;
            readonly preferredGivenName: string;
            readonly preferredFamilyName?: string | null;
            readonly preferredFullName: string;
            readonly email: string;
            readonly idNumber: string;
            readonly barcodeNumber: string;
            readonly nationalCurriculumYear: string;
            readonly uniqueStudentNumber?: string | null;
            readonly mobile?: string | null;
            readonly isAssessmentResultsAvailable: boolean;
            readonly isAttendanceAvailable: boolean;
            readonly isDailyNoticesAvailable: boolean;
            readonly isTimetableAvailable: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        } | null;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly badges: { readonly __typename: 'BadgeConnection'; readonly totalCount: number; readonly lastCreatedAt?: string | null };
        readonly curriculumVitae?: { readonly __typename: 'CurriculumVitae'; readonly id: string; readonly updatedAt: string } | null;
        readonly jobProfile?: { readonly __typename: 'JobProfile'; readonly id: string; readonly enabledAt?: string | null } | null;
        readonly goals?: {
            readonly __typename: 'GoalConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly reflections?: {
            readonly __typename: 'ReflectionConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly resourceHubItems?: {
            readonly __typename: 'ResourceHubItemConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly savedStudyProgrammes?: {
            readonly __typename: 'SavedStudyProgrammeConnection';
            readonly totalCount: number;
            readonly lastSavedAt?: string | null;
        } | null;
        readonly pathwayPlanners?: {
            readonly __typename: 'PathwayPlannerConnection';
            readonly totalCount: number;
            readonly lastUpdatedAt?: string | null;
        } | null;
        readonly notes: {
            readonly __typename: 'MentorStudentNoteConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorStudentNoteEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorStudentNote';
                    readonly id: string;
                    readonly information: string;
                    readonly isShared: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
        readonly todos: {
            readonly __typename: 'MentorStudentTodoConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorStudentTodoEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorStudentTodo';
                    readonly id: string;
                    readonly information: string;
                    readonly isShared: boolean;
                    readonly complete: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
        readonly projects: {
            readonly __typename: 'ProjectConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProjectEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Project';
                    readonly id: string;
                    readonly title: string;
                    readonly updatedAt: string;
                } | null;
            }>;
        };
        readonly mentorGroups: ReadonlyArray<{ readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string }>;
        readonly availableMentorGroups: {
            readonly __typename: 'AvailableMentorGroupConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorStudentTimetableEventsQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type MentorStudentTimetableEventsQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly timetableEventsList?: ReadonlyArray<{
            readonly __typename: 'TimetableEvent';
            readonly id: string;
            readonly color: string;
            readonly priority: number;
            readonly dateType: TimetableEventDateType;
            readonly start: string;
            readonly end?: string | null;
            readonly summary: string;
            readonly location?: string | null;
            readonly description?: string | null;
        }> | null;
    } | null;
};

export type MentorStudentTodosQueryVariables = Exact<{
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    mentorGroupId?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type MentorStudentTodosQuery = {
    readonly __typename: 'Query';
    readonly mentorStudent?: {
        readonly __typename: 'MentorStudent';
        readonly id: string;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly todos: {
            readonly __typename: 'MentorStudentTodoConnection';
            readonly notViewedCount: number;
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorStudentTodoEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorStudentTodo';
                    readonly id: string;
                    readonly information: string;
                    readonly isShared: boolean;
                    readonly complete: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type ProviderMentorStudentsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
    jobProfileEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    withNoGroups?: InputMaybe<Scalars['Boolean']['input']>;
    withNoLearnerSmsProfile?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProviderMentorStudentsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly logoImageUrl: string;
        readonly mentorStudents: {
            readonly __typename: 'MentorStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorStudent';
                    readonly canRemove: boolean;
                    readonly id: string;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                    readonly mentorGroups: ReadonlyArray<{
                        readonly __typename: 'MentorGroup';
                        readonly id: string;
                        readonly title: string;
                    }>;
                } | null;
            }>;
        };
    } | null;
};

export type RemoveMentorStudentMutationVariables = Exact<{
    input: RemoveMentorStudentInput;
}>;

export type RemoveMentorStudentMutation = {
    readonly __typename: 'Mutation';
    readonly removeMentorStudent: { readonly __typename: 'RemoveMentorStudentPayload'; readonly mentorStudentId: string };
};

export type ShareMentorStudentNoteMutationVariables = Exact<{
    input: ShareMentorStudentNoteInput;
}>;

export type ShareMentorStudentNoteMutation = {
    readonly __typename: 'Mutation';
    readonly shareMentorStudentNote: {
        readonly __typename: 'ShareMentorStudentNotePayload';
        readonly mentorStudentNote: {
            readonly __typename: 'MentorStudentNote';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type ShareMentorStudentTodoMutationVariables = Exact<{
    input: ShareMentorStudentTodoInput;
}>;

export type ShareMentorStudentTodoMutation = {
    readonly __typename: 'Mutation';
    readonly shareMentorStudentTodo: {
        readonly __typename: 'ShareMentorStudentTodoPayload';
        readonly mentorStudentTodo: {
            readonly __typename: 'MentorStudentTodo';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly complete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UnshareMentorStudentNoteMutationVariables = Exact<{
    input: UnshareMentorStudentNoteInput;
}>;

export type UnshareMentorStudentNoteMutation = {
    readonly __typename: 'Mutation';
    readonly unshareMentorStudentNote: {
        readonly __typename: 'UnshareMentorStudentNotePayload';
        readonly mentorStudentNote: {
            readonly __typename: 'MentorStudentNote';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UnshareMentorStudentTodoMutationVariables = Exact<{
    input: UnshareMentorStudentTodoInput;
}>;

export type UnshareMentorStudentTodoMutation = {
    readonly __typename: 'Mutation';
    readonly unshareMentorStudentTodo: {
        readonly __typename: 'UnshareMentorStudentTodoPayload';
        readonly mentorStudentTodo: {
            readonly __typename: 'MentorStudentTodo';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly complete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateMentorStudentNoteMutationVariables = Exact<{
    input: UpdateMentorStudentNoteInput;
}>;

export type UpdateMentorStudentNoteMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorStudentNote: {
        readonly __typename: 'UpdateMentorStudentNotePayload';
        readonly mentorStudentNote: {
            readonly __typename: 'MentorStudentNote';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateMentorStudentNoteViewedMutationVariables = Exact<{
    input: UpdateMentorStudentNoteViewedInput;
}>;

export type UpdateMentorStudentNoteViewedMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorStudentNoteViewed: { readonly __typename: 'UpdateMentorStudentNoteViewedPayload'; readonly viewedCount: number };
};

export type UpdateMentorStudentSharedActivityMutationVariables = Exact<{
    input: UpdateMentorStudentSharedActivityInput;
}>;

export type UpdateMentorStudentSharedActivityMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorStudentSharedActivity: {
        readonly __typename: 'UpdateMentorStudentSharedActivityPayload';
        readonly mentorStudent: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly learnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly pictureUrl: string;
                readonly preferredGivenName: string;
                readonly preferredFamilyName?: string | null;
                readonly preferredFullName: string;
                readonly email: string;
                readonly idNumber: string;
                readonly nationalCurriculumYear: string;
            } | null;
        };
    };
};

export type UpdateMentorStudentTodoMutationVariables = Exact<{
    input: UpdateMentorStudentTodoInput;
}>;

export type UpdateMentorStudentTodoMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorStudentTodo: {
        readonly __typename: 'UpdateMentorStudentTodoPayload';
        readonly mentorStudentTodo: {
            readonly __typename: 'MentorStudentTodo';
            readonly id: string;
            readonly information: string;
            readonly isShared: boolean;
            readonly complete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateMentorStudentTodoViewedMutationVariables = Exact<{
    input: UpdateMentorStudentTodoViewedInput;
}>;

export type UpdateMentorStudentTodoViewedMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorStudentTodoViewed: { readonly __typename: 'UpdateMentorStudentTodoViewedPayload'; readonly viewedCount: number };
};

export type ValidateMentorStudentInviteCodeMutationVariables = Exact<{
    input: ValidateMentorStudentInviteCodeInput;
}>;

export type ValidateMentorStudentInviteCodeMutation = {
    readonly __typename: 'Mutation';
    readonly validateMentorStudentInviteCode: {
        readonly __typename: 'ValidateMentorStudentInviteCodePayload';
        readonly mentorGroupTitles: ReadonlyArray<string>;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    };
};

export type CreateStudentHomeworkDiaryItemMutationVariables = Exact<{
    input: CreateStudentHomeworkDiaryItemInput;
}>;

export type CreateStudentHomeworkDiaryItemMutation = {
    readonly __typename: 'Mutation';
    readonly createStudentHomeworkDiaryItem: {
        readonly __typename: 'CreateStudentHomeworkDiaryItemPayload';
        readonly studentHomeworkDiaryItem: {
            readonly __typename: 'StudentHomeworkDiaryItem';
            readonly id: string;
            readonly date: string;
            readonly information?: string | null;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly color?: string | null;
            readonly completedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        };
    };
};

export type DeleteStudentHomeworkDiaryItemMutationVariables = Exact<{
    input: DeleteStudentHomeworkDiaryItemInput;
}>;

export type DeleteStudentHomeworkDiaryItemMutation = {
    readonly __typename: 'Mutation';
    readonly deleteStudentHomeworkDiaryItem: {
        readonly __typename: 'DeleteStudentHomeworkDiaryItemPayload';
        readonly studentHomeworkDiaryItemId: string;
    };
};

export type MyAssessmentResultsQueryVariables = Exact<{ [key: string]: never }>;

export type MyAssessmentResultsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly assessmentResultsList: ReadonlyArray<{
                readonly __typename: 'AssessmentResult';
                readonly id: string;
                readonly type: AssessmentResultType;
                readonly number: string;
                readonly version: string;
                readonly level?: number | null;
                readonly credits?: number | null;
                readonly weighting?: number | null;
                readonly points?: number | null;
                readonly title?: string | null;
                readonly description?: string | null;
                readonly purpose?: string | null;
                readonly subField?: string | null;
                readonly date?: string | null;
                readonly subject?: string | null;
                readonly result?: string | null;
                readonly comment?: string | null;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            }>;
        } | null;
    };
};

export type MyAttendanceSummaryQueryVariables = Exact<{
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type MyAttendanceSummaryQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly attendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
        } | null;
    };
};

export type MyAttendanceYearSummaryQueryVariables = Exact<{
    year: Scalars['Int']['input'];
}>;

export type MyAttendanceYearSummaryQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly januaryAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly februaryAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly marchAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly aprilAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly mayAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly juneAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly julyAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly augustAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly septemberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly octoberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly novemberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
            readonly decemberAttendanceSummary?: {
                readonly __typename: 'LearnerSmsAttendanceSummary';
                readonly halfDaysJustified: number;
                readonly halfDaysUnjustified: number;
                readonly halfDaysPresent: number;
                readonly halfDaysTotal: number;
                readonly standing: LearnerSmsAttendanceSummaryStanding;
            } | null;
        } | null;
    };
};

export type MyCurrentMySchoolProviderQueryVariables = Exact<{ [key: string]: never }>;

export type MyCurrentMySchoolProviderQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly isAssessmentResultsAvailable: boolean;
            readonly isAttendanceAvailable: boolean;
            readonly isDailyNoticesAvailable: boolean;
            readonly isTimetableAvailable: boolean;
            readonly id: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly learnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly pictureUrl: string;
                readonly preferredGivenName: string;
                readonly preferredFamilyName?: string | null;
                readonly preferredFullName: string;
                readonly email: string;
                readonly idNumber: string;
                readonly nationalCurriculumYear: string;
            } | null;
        } | null;
    };
};

export type MyDailyNoticesQueryVariables = Exact<{
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type MyDailyNoticesQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly dailyNoticesList?: ReadonlyArray<{
                readonly __typename: 'DailyNotice';
                readonly id: string;
                readonly color: string;
                readonly level: string;
                readonly start: string;
                readonly end: string;
                readonly subject: string;
                readonly body?: string | null;
                readonly teacher?: string | null;
                readonly meetingDate?: string | null;
                readonly meetingPlace?: string | null;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            }> | null;
        } | null;
    };
};

export type MyStudentHomeworkDiaryDailyItemsQueryVariables = Exact<{
    date: Scalars['Date']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyStudentHomeworkDiaryDailyItemsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly studentHomeworkDiaryDailyItems: {
                readonly __typename: 'StudentHomeworkDiaryConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'StudentHomeworkDiaryEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'StudentHomeworkDiaryItem';
                        readonly id: string;
                        readonly date: string;
                        readonly information?: string | null;
                        readonly imageId?: string | null;
                        readonly imageUrl?: string | null;
                        readonly color?: string | null;
                        readonly completedAt?: string | null;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                    } | null;
                }>;
            };
        } | null;
    };
};

export type MyStudentHomeworkDiaryMonthSummaryQueryVariables = Exact<{
    date: Scalars['Date']['input'];
}>;

export type MyStudentHomeworkDiaryMonthSummaryQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly studentHomeworkDiaryMonthSummary: {
                readonly __typename: 'StudentHomeworkDiaryMonthSummary';
                readonly completed: number;
                readonly total: number;
                readonly colors: ReadonlyArray<string | null>;
                readonly days: ReadonlyArray<{
                    readonly __typename: 'StudentHomeworkDiaryDaySummary';
                    readonly date: string;
                    readonly completed: number;
                    readonly total: number;
                    readonly colors: ReadonlyArray<string | null>;
                }>;
            };
        } | null;
    };
};

export type MySchoolInformationItemsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MySchoolInformationItemsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
            };
            readonly mySchoolInformationItems: {
                readonly __typename: 'ProviderMySchoolInformationConnection';
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'ProviderMySchoolInformationEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'ProviderMySchoolInformationItem';
                        readonly id: string;
                        readonly title: string;
                        readonly information: string;
                        readonly sequence: number;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                    } | null;
                }>;
            };
        } | null;
    };
};

export type MyTimetableEventsQueryVariables = Exact<{
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type MyTimetableEventsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly currentMySchoolProvider?: {
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly isTimetableDisabled: boolean;
            readonly timetableEventsList?: ReadonlyArray<{
                readonly __typename: 'TimetableEvent';
                readonly id: string;
                readonly color: string;
                readonly priority: number;
                readonly dateType: TimetableEventDateType;
                readonly start: string;
                readonly end?: string | null;
                readonly summary: string;
                readonly location?: string | null;
                readonly description?: string | null;
            }> | null;
        } | null;
    };
};

export type ToggleStudentHomeworkDiaryItemCompletionMutationVariables = Exact<{
    input: ToggleStudentHomeworkDiaryItemCompletionInput;
}>;

export type ToggleStudentHomeworkDiaryItemCompletionMutation = {
    readonly __typename: 'Mutation';
    readonly toggleStudentHomeworkDiaryItemCompletion: {
        readonly __typename: 'ToggleStudentHomeworkDiaryItemCompletionPayload';
        readonly studentHomeworkDiaryItem: {
            readonly __typename: 'StudentHomeworkDiaryItem';
            readonly id: string;
            readonly date: string;
            readonly information?: string | null;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly color?: string | null;
            readonly completedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        };
    };
};

export type UpdateStudentHomeworkDiaryItemMutationVariables = Exact<{
    input: UpdateStudentHomeworkDiaryItemInput;
}>;

export type UpdateStudentHomeworkDiaryItemMutation = {
    readonly __typename: 'Mutation';
    readonly updateStudentHomeworkDiaryItem: {
        readonly __typename: 'UpdateStudentHomeworkDiaryItemPayload';
        readonly studentHomeworkDiaryItem: {
            readonly __typename: 'StudentHomeworkDiaryItem';
            readonly id: string;
            readonly date: string;
            readonly information?: string | null;
            readonly imageId?: string | null;
            readonly imageUrl?: string | null;
            readonly color?: string | null;
            readonly completedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        };
    };
};

export type ChooseProviderPostPollResponseMutationVariables = Exact<{
    input: ChooseProviderPostPollResponseInput;
}>;

export type ChooseProviderPostPollResponseMutation = {
    readonly __typename: 'Mutation';
    readonly chooseProviderPostPollResponse: {
        readonly __typename: 'ChooseProviderPostPollResponsePayload';
        readonly providerPost: {
            readonly __typename: 'ProviderPost';
            readonly itemType: NewsfeedItemType;
            readonly itemId: string;
            readonly itemCreated: string;
            readonly itemUpdated?: string | null;
            readonly content: string;
            readonly countryCodes?: ReadonlyArray<string> | null;
            readonly mentorOnly: boolean;
            readonly mentorStudentOnly: boolean;
            readonly imageUrl?: string | null;
            readonly hasReaction: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly isPinned: boolean;
            readonly postMetadata: {
                readonly __typename: 'PostMetadata';
                readonly author?: string | null;
                readonly date?: string | null;
                readonly description?: string | null;
                readonly image?: string | null;
                readonly video?: string | null;
                readonly lang?: string | null;
                readonly logo?: string | null;
                readonly publisher?: string | null;
                readonly title?: string | null;
                readonly url?: string | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            };
            readonly reactionsTotalCountByType: ReadonlyArray<{
                readonly __typename: 'ProviderPostReactionTotalCountByType';
                readonly type: ProviderPostReactionType;
                readonly count: number;
            }>;
            readonly myReaction?: {
                readonly __typename: 'ProviderPostReaction';
                readonly id: string;
                readonly type: ProviderPostReactionType;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'ProviderPostPollOption';
                readonly id: string;
                readonly title: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'ProviderPostPollResponse';
                readonly id: string;
                readonly providerPostPollOption: {
                    readonly __typename: 'ProviderPostPollOption';
                    readonly id: string;
                    readonly title: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
        };
    };
};

export type CreateProviderPostMutationVariables = Exact<{
    input: CreateProviderPostInput;
}>;

export type CreateProviderPostMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderPost: {
        readonly __typename: 'CreateProviderPostPayload';
        readonly providerPost: {
            readonly __typename: 'ProviderPost';
            readonly itemType: NewsfeedItemType;
            readonly itemId: string;
            readonly itemCreated: string;
            readonly itemUpdated?: string | null;
            readonly content: string;
            readonly countryCodes?: ReadonlyArray<string> | null;
            readonly mentorOnly: boolean;
            readonly mentorStudentOnly: boolean;
            readonly imageUrl?: string | null;
            readonly hasReaction: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly isPinned: boolean;
            readonly postMetadata: {
                readonly __typename: 'PostMetadata';
                readonly author?: string | null;
                readonly date?: string | null;
                readonly description?: string | null;
                readonly image?: string | null;
                readonly video?: string | null;
                readonly lang?: string | null;
                readonly logo?: string | null;
                readonly publisher?: string | null;
                readonly title?: string | null;
                readonly url?: string | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            };
            readonly reactionsTotalCountByType: ReadonlyArray<{
                readonly __typename: 'ProviderPostReactionTotalCountByType';
                readonly type: ProviderPostReactionType;
                readonly count: number;
            }>;
            readonly myReaction?: {
                readonly __typename: 'ProviderPostReaction';
                readonly id: string;
                readonly type: ProviderPostReactionType;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'ProviderPostPollOption';
                readonly id: string;
                readonly title: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'ProviderPostPollResponse';
                readonly id: string;
                readonly providerPostPollOption: {
                    readonly __typename: 'ProviderPostPollOption';
                    readonly id: string;
                    readonly title: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
        };
    };
};

export type DeleteProviderPostMutationVariables = Exact<{
    input: DeleteProviderPostInput;
}>;

export type DeleteProviderPostMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderPost: { readonly __typename: 'DeleteProviderPostPayload'; readonly providerPostId: string };
};

export type NewsfeedItemQueryVariables = Exact<{
    itemType: NewsfeedItemType;
    itemId: Scalars['UUID']['input'];
}>;

export type NewsfeedItemQuery = {
    readonly __typename: 'Query';
    readonly newsfeedItem?:
        | {
              readonly __typename: 'GoalsDueReminder';
              readonly itemType: NewsfeedItemType;
              readonly itemId: string;
              readonly itemCreated: string;
              readonly itemUpdated?: string | null;
          }
        | {
              readonly __typename: 'OpportunityListingPost';
              readonly itemType: NewsfeedItemType;
              readonly itemId: string;
              readonly itemCreated: string;
              readonly itemUpdated?: string | null;
              readonly type: OpportunityListingType;
              readonly title: string;
              readonly country: string;
              readonly coverImageUrl?: string | null;
              readonly provider: {
                  readonly __typename: 'Provider';
                  readonly id: string;
                  readonly title: string;
                  readonly countryCode: string;
                  readonly providerType: ProviderType;
                  readonly logoImageUrl: string;
                  readonly bannerImageUrl?: string | null;
              };
              readonly geoLocation: {
                  readonly __typename: 'GeoLocation';
                  readonly id: string;
                  readonly name: string;
                  readonly displayName: string;
                  readonly longitude?: number | null;
                  readonly latitude?: number | null;
                  readonly street?: string | null;
                  readonly city?: string | null;
                  readonly county?: string | null;
                  readonly state?: string | null;
                  readonly country?: string | null;
                  readonly countryCode?: string | null;
              };
          }
        | {
              readonly __typename: 'ProviderPost';
              readonly itemType: NewsfeedItemType;
              readonly itemId: string;
              readonly itemCreated: string;
              readonly itemUpdated?: string | null;
              readonly content: string;
              readonly countryCodes?: ReadonlyArray<string> | null;
              readonly mentorOnly: boolean;
              readonly mentorStudentOnly: boolean;
              readonly imageUrl?: string | null;
              readonly hasReaction: boolean;
              readonly pollExpiresAt?: string | null;
              readonly totalPollResponsesCount: number;
              readonly canViewPollResults: boolean;
              readonly isPinned: boolean;
              readonly postMetadata: {
                  readonly __typename: 'PostMetadata';
                  readonly author?: string | null;
                  readonly date?: string | null;
                  readonly description?: string | null;
                  readonly image?: string | null;
                  readonly video?: string | null;
                  readonly lang?: string | null;
                  readonly logo?: string | null;
                  readonly publisher?: string | null;
                  readonly title?: string | null;
                  readonly url?: string | null;
              };
              readonly provider: {
                  readonly __typename: 'Provider';
                  readonly id: string;
                  readonly title: string;
                  readonly countryCode: string;
                  readonly providerType: ProviderType;
                  readonly logoImageUrl: string;
                  readonly bannerImageUrl?: string | null;
                  readonly canUpdateProviderDetails: boolean;
                  readonly canAccessAdminFeature: boolean;
                  readonly canAccessMentorFeature: boolean;
                  readonly canAccessMentorRewardFeature: boolean;
                  readonly canAccessOpportunityFeature: boolean;
                  readonly canAccessNewsfeedFeature: boolean;
                  readonly canAccessProjectTemplatesFeature: boolean;
                  readonly canAccessEmploymentFeature: boolean;
                  readonly canAccessBadgeTemplatesFeature: boolean;
                  readonly canImportMentorRewardPoints: boolean;
                  readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                  readonly canPinPost: boolean;
                  readonly canToggleProviderLessonAccess: boolean;
                  readonly isDailyNoticesAvailable: boolean;
                  readonly isLessonsDisabled: boolean;
              };
              readonly reactionsTotalCountByType: ReadonlyArray<{
                  readonly __typename: 'ProviderPostReactionTotalCountByType';
                  readonly type: ProviderPostReactionType;
                  readonly count: number;
              }>;
              readonly myReaction?: {
                  readonly __typename: 'ProviderPostReaction';
                  readonly id: string;
                  readonly type: ProviderPostReactionType;
              } | null;
              readonly pollOptions?: ReadonlyArray<{
                  readonly __typename: 'ProviderPostPollOption';
                  readonly id: string;
                  readonly title: string;
                  readonly totalResponsesCount: number;
                  readonly totalResponsesCountPercentage: number;
              }> | null;
              readonly selectedPollOption?: {
                  readonly __typename: 'ProviderPostPollResponse';
                  readonly id: string;
                  readonly providerPostPollOption: {
                      readonly __typename: 'ProviderPostPollOption';
                      readonly id: string;
                      readonly title: string;
                      readonly totalResponsesCount: number;
                      readonly totalResponsesCountPercentage: number;
                  };
              } | null;
          }
        | {
              readonly __typename: 'SponsorPost';
              readonly itemType: NewsfeedItemType;
              readonly itemId: string;
              readonly itemCreated: string;
              readonly itemUpdated?: string | null;
          }
        | {
              readonly __typename: 'StudentHomeworkItemsDueReminder';
              readonly itemType: NewsfeedItemType;
              readonly itemId: string;
              readonly itemCreated: string;
              readonly itemUpdated?: string | null;
          }
        | null;
};

export type NewsfeedQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type NewsfeedQuery = {
    readonly __typename: 'Query';
    readonly newsfeed: {
        readonly __typename: 'NewsfeedItemConnection';
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'NewsfeedItemEdge';
            readonly cursor: string;
            readonly node?:
                | {
                      readonly __typename: 'GoalsDueReminder';
                      readonly itemType: NewsfeedItemType;
                      readonly itemId: string;
                      readonly itemCreated: string;
                      readonly itemUpdated?: string | null;
                      readonly totalDue: number;
                      readonly goals: ReadonlyArray<{
                          readonly __typename: 'Goal';
                          readonly id: string;
                          readonly goalType: GoalType;
                          readonly title: string;
                          readonly imageUrl?: string | null;
                          readonly imageId?: string | null;
                          readonly information: string;
                          readonly complete: boolean;
                          readonly dueAt?: string | null;
                          readonly color?: string | null;
                          readonly updatedAt: string;
                          readonly createdAt: string;
                      }>;
                  }
                | {
                      readonly __typename: 'OpportunityListingPost';
                      readonly itemType: NewsfeedItemType;
                      readonly itemId: string;
                      readonly itemCreated: string;
                      readonly itemUpdated?: string | null;
                      readonly type: OpportunityListingType;
                      readonly title: string;
                      readonly country: string;
                      readonly coverImageUrl?: string | null;
                      readonly provider: {
                          readonly __typename: 'Provider';
                          readonly id: string;
                          readonly title: string;
                          readonly countryCode: string;
                          readonly providerType: ProviderType;
                          readonly logoImageUrl: string;
                          readonly bannerImageUrl?: string | null;
                      };
                      readonly geoLocation: {
                          readonly __typename: 'GeoLocation';
                          readonly id: string;
                          readonly name: string;
                          readonly displayName: string;
                          readonly longitude?: number | null;
                          readonly latitude?: number | null;
                          readonly street?: string | null;
                          readonly city?: string | null;
                          readonly county?: string | null;
                          readonly state?: string | null;
                          readonly country?: string | null;
                          readonly countryCode?: string | null;
                      };
                  }
                | {
                      readonly __typename: 'ProviderPost';
                      readonly itemType: NewsfeedItemType;
                      readonly itemId: string;
                      readonly itemCreated: string;
                      readonly itemUpdated?: string | null;
                      readonly content: string;
                      readonly countryCodes?: ReadonlyArray<string> | null;
                      readonly mentorOnly: boolean;
                      readonly mentorStudentOnly: boolean;
                      readonly imageUrl?: string | null;
                      readonly hasReaction: boolean;
                      readonly pollExpiresAt?: string | null;
                      readonly totalPollResponsesCount: number;
                      readonly canViewPollResults: boolean;
                      readonly isPinned: boolean;
                      readonly postMetadata: {
                          readonly __typename: 'PostMetadata';
                          readonly author?: string | null;
                          readonly date?: string | null;
                          readonly description?: string | null;
                          readonly image?: string | null;
                          readonly video?: string | null;
                          readonly lang?: string | null;
                          readonly logo?: string | null;
                          readonly publisher?: string | null;
                          readonly title?: string | null;
                          readonly url?: string | null;
                      };
                      readonly provider: {
                          readonly __typename: 'Provider';
                          readonly id: string;
                          readonly title: string;
                          readonly countryCode: string;
                          readonly providerType: ProviderType;
                          readonly logoImageUrl: string;
                          readonly bannerImageUrl?: string | null;
                          readonly canUpdateProviderDetails: boolean;
                          readonly canAccessAdminFeature: boolean;
                          readonly canAccessMentorFeature: boolean;
                          readonly canAccessMentorRewardFeature: boolean;
                          readonly canAccessOpportunityFeature: boolean;
                          readonly canAccessNewsfeedFeature: boolean;
                          readonly canAccessProjectTemplatesFeature: boolean;
                          readonly canAccessEmploymentFeature: boolean;
                          readonly canAccessBadgeTemplatesFeature: boolean;
                          readonly canImportMentorRewardPoints: boolean;
                          readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                          readonly canPinPost: boolean;
                          readonly canToggleProviderLessonAccess: boolean;
                          readonly isDailyNoticesAvailable: boolean;
                          readonly isLessonsDisabled: boolean;
                      };
                      readonly reactionsTotalCountByType: ReadonlyArray<{
                          readonly __typename: 'ProviderPostReactionTotalCountByType';
                          readonly type: ProviderPostReactionType;
                          readonly count: number;
                      }>;
                      readonly myReaction?: {
                          readonly __typename: 'ProviderPostReaction';
                          readonly id: string;
                          readonly type: ProviderPostReactionType;
                      } | null;
                      readonly pollOptions?: ReadonlyArray<{
                          readonly __typename: 'ProviderPostPollOption';
                          readonly id: string;
                          readonly title: string;
                          readonly totalResponsesCount: number;
                          readonly totalResponsesCountPercentage: number;
                      }> | null;
                      readonly selectedPollOption?: {
                          readonly __typename: 'ProviderPostPollResponse';
                          readonly id: string;
                          readonly providerPostPollOption: {
                              readonly __typename: 'ProviderPostPollOption';
                              readonly id: string;
                              readonly title: string;
                              readonly totalResponsesCount: number;
                              readonly totalResponsesCountPercentage: number;
                          };
                      } | null;
                  }
                | {
                      readonly __typename: 'SponsorPost';
                      readonly itemType: NewsfeedItemType;
                      readonly itemId: string;
                      readonly itemCreated: string;
                      readonly itemUpdated?: string | null;
                      readonly sponsorCampaignId: string;
                      readonly sponsorProviderTitle: string;
                      readonly sponsorProviderLogoImageUrl: string;
                      readonly title: string;
                      readonly content: string;
                      readonly targetUrl: string;
                      readonly mediaImageUrl?: string | null;
                      readonly mediaVideoUrl?: string | null;
                  }
                | {
                      readonly __typename: 'StudentHomeworkItemsDueReminder';
                      readonly itemType: NewsfeedItemType;
                      readonly itemId: string;
                      readonly itemCreated: string;
                      readonly itemUpdated?: string | null;
                      readonly totalDue: number;
                      readonly studentHomeworkItems: ReadonlyArray<{
                          readonly __typename: 'StudentHomeworkDiaryItem';
                          readonly id: string;
                          readonly date: string;
                          readonly information?: string | null;
                          readonly imageId?: string | null;
                          readonly imageUrl?: string | null;
                          readonly color?: string | null;
                          readonly completedAt?: string | null;
                          readonly updatedAt: string;
                          readonly createdAt: string;
                          readonly canUpdate: boolean;
                          readonly canDelete: boolean;
                      }>;
                  }
                | null;
        }>;
    };
};

export type ProviderNewsfeedQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    providerId: Scalars['UUID']['input'];
}>;

export type ProviderNewsfeedQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly canUpdateProviderDetails: boolean;
        readonly canAccessAdminFeature: boolean;
        readonly canAccessMentorFeature: boolean;
        readonly canAccessMentorRewardFeature: boolean;
        readonly canAccessOpportunityFeature: boolean;
        readonly canAccessNewsfeedFeature: boolean;
        readonly canAccessProjectTemplatesFeature: boolean;
        readonly canAccessEmploymentFeature: boolean;
        readonly canAccessBadgeTemplatesFeature: boolean;
        readonly canImportMentorRewardPoints: boolean;
        readonly canImportMentorGroupsFromLearnerSmsData: boolean;
        readonly canPinPost: boolean;
        readonly canToggleProviderLessonAccess: boolean;
        readonly isDailyNoticesAvailable: boolean;
        readonly isLessonsDisabled: boolean;
        readonly newsfeedProviderPosts: {
            readonly __typename: 'ProviderPostConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProviderPostEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'ProviderPost';
                    readonly itemType: NewsfeedItemType;
                    readonly itemId: string;
                    readonly itemCreated: string;
                    readonly itemUpdated?: string | null;
                    readonly content: string;
                    readonly countryCodes?: ReadonlyArray<string> | null;
                    readonly mentorOnly: boolean;
                    readonly mentorStudentOnly: boolean;
                    readonly imageUrl?: string | null;
                    readonly hasReaction: boolean;
                    readonly pollExpiresAt?: string | null;
                    readonly totalPollResponsesCount: number;
                    readonly canViewPollResults: boolean;
                    readonly isPinned: boolean;
                    readonly postMetadata: {
                        readonly __typename: 'PostMetadata';
                        readonly author?: string | null;
                        readonly date?: string | null;
                        readonly description?: string | null;
                        readonly image?: string | null;
                        readonly video?: string | null;
                        readonly lang?: string | null;
                        readonly logo?: string | null;
                        readonly publisher?: string | null;
                        readonly title?: string | null;
                        readonly url?: string | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                        readonly canUpdateProviderDetails: boolean;
                        readonly canAccessAdminFeature: boolean;
                        readonly canAccessMentorFeature: boolean;
                        readonly canAccessMentorRewardFeature: boolean;
                        readonly canAccessOpportunityFeature: boolean;
                        readonly canAccessNewsfeedFeature: boolean;
                        readonly canAccessProjectTemplatesFeature: boolean;
                        readonly canAccessEmploymentFeature: boolean;
                        readonly canAccessBadgeTemplatesFeature: boolean;
                        readonly canImportMentorRewardPoints: boolean;
                        readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                        readonly canPinPost: boolean;
                        readonly canToggleProviderLessonAccess: boolean;
                        readonly isDailyNoticesAvailable: boolean;
                        readonly isLessonsDisabled: boolean;
                    };
                    readonly reactionsTotalCountByType: ReadonlyArray<{
                        readonly __typename: 'ProviderPostReactionTotalCountByType';
                        readonly type: ProviderPostReactionType;
                        readonly count: number;
                    }>;
                    readonly myReaction?: {
                        readonly __typename: 'ProviderPostReaction';
                        readonly id: string;
                        readonly type: ProviderPostReactionType;
                    } | null;
                    readonly pollOptions?: ReadonlyArray<{
                        readonly __typename: 'ProviderPostPollOption';
                        readonly id: string;
                        readonly title: string;
                        readonly totalResponsesCount: number;
                        readonly totalResponsesCountPercentage: number;
                    }> | null;
                    readonly selectedPollOption?: {
                        readonly __typename: 'ProviderPostPollResponse';
                        readonly id: string;
                        readonly providerPostPollOption: {
                            readonly __typename: 'ProviderPostPollOption';
                            readonly id: string;
                            readonly title: string;
                            readonly totalResponsesCount: number;
                            readonly totalResponsesCountPercentage: number;
                        };
                    } | null;
                } | null;
            }>;
        };
    } | null;
};

export type ReactProviderPostMutationVariables = Exact<{
    input: ReactProviderPostInput;
}>;

export type ReactProviderPostMutation = {
    readonly __typename: 'Mutation';
    readonly reactProviderPost: {
        readonly __typename: 'ReactProviderPostPayload';
        readonly providerPost: {
            readonly __typename: 'ProviderPost';
            readonly itemType: NewsfeedItemType;
            readonly itemId: string;
            readonly itemCreated: string;
            readonly itemUpdated?: string | null;
            readonly content: string;
            readonly countryCodes?: ReadonlyArray<string> | null;
            readonly mentorOnly: boolean;
            readonly mentorStudentOnly: boolean;
            readonly imageUrl?: string | null;
            readonly hasReaction: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly isPinned: boolean;
            readonly postMetadata: {
                readonly __typename: 'PostMetadata';
                readonly author?: string | null;
                readonly date?: string | null;
                readonly description?: string | null;
                readonly image?: string | null;
                readonly video?: string | null;
                readonly lang?: string | null;
                readonly logo?: string | null;
                readonly publisher?: string | null;
                readonly title?: string | null;
                readonly url?: string | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            };
            readonly reactionsTotalCountByType: ReadonlyArray<{
                readonly __typename: 'ProviderPostReactionTotalCountByType';
                readonly type: ProviderPostReactionType;
                readonly count: number;
            }>;
            readonly myReaction?: {
                readonly __typename: 'ProviderPostReaction';
                readonly id: string;
                readonly type: ProviderPostReactionType;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'ProviderPostPollOption';
                readonly id: string;
                readonly title: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'ProviderPostPollResponse';
                readonly id: string;
                readonly providerPostPollOption: {
                    readonly __typename: 'ProviderPostPollOption';
                    readonly id: string;
                    readonly title: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
        };
    };
};

export type RepostProviderPostMutationVariables = Exact<{
    input: RepostProviderPostInput;
}>;

export type RepostProviderPostMutation = {
    readonly __typename: 'Mutation';
    readonly repostProviderPost: {
        readonly __typename: 'RepostProviderPostPayload';
        readonly providerPost: {
            readonly __typename: 'ProviderPost';
            readonly itemType: NewsfeedItemType;
            readonly itemId: string;
            readonly itemCreated: string;
            readonly itemUpdated?: string | null;
            readonly content: string;
            readonly countryCodes?: ReadonlyArray<string> | null;
            readonly mentorOnly: boolean;
            readonly mentorStudentOnly: boolean;
            readonly imageUrl?: string | null;
            readonly hasReaction: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly isPinned: boolean;
            readonly postMetadata: {
                readonly __typename: 'PostMetadata';
                readonly author?: string | null;
                readonly date?: string | null;
                readonly description?: string | null;
                readonly image?: string | null;
                readonly video?: string | null;
                readonly lang?: string | null;
                readonly logo?: string | null;
                readonly publisher?: string | null;
                readonly title?: string | null;
                readonly url?: string | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            };
            readonly reactionsTotalCountByType: ReadonlyArray<{
                readonly __typename: 'ProviderPostReactionTotalCountByType';
                readonly type: ProviderPostReactionType;
                readonly count: number;
            }>;
            readonly myReaction?: {
                readonly __typename: 'ProviderPostReaction';
                readonly id: string;
                readonly type: ProviderPostReactionType;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'ProviderPostPollOption';
                readonly id: string;
                readonly title: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'ProviderPostPollResponse';
                readonly id: string;
                readonly providerPostPollOption: {
                    readonly __typename: 'ProviderPostPollOption';
                    readonly id: string;
                    readonly title: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
        };
    };
};

export type UnreactProviderPostMutationVariables = Exact<{
    input: UnreactProviderPostInput;
}>;

export type UnreactProviderPostMutation = {
    readonly __typename: 'Mutation';
    readonly unreactProviderPost: {
        readonly __typename: 'UnreactProviderPostPayload';
        readonly providerPost: {
            readonly __typename: 'ProviderPost';
            readonly itemType: NewsfeedItemType;
            readonly itemId: string;
            readonly itemCreated: string;
            readonly itemUpdated?: string | null;
            readonly content: string;
            readonly countryCodes?: ReadonlyArray<string> | null;
            readonly mentorOnly: boolean;
            readonly mentorStudentOnly: boolean;
            readonly imageUrl?: string | null;
            readonly hasReaction: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly isPinned: boolean;
            readonly postMetadata: {
                readonly __typename: 'PostMetadata';
                readonly author?: string | null;
                readonly date?: string | null;
                readonly description?: string | null;
                readonly image?: string | null;
                readonly video?: string | null;
                readonly lang?: string | null;
                readonly logo?: string | null;
                readonly publisher?: string | null;
                readonly title?: string | null;
                readonly url?: string | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            };
            readonly reactionsTotalCountByType: ReadonlyArray<{
                readonly __typename: 'ProviderPostReactionTotalCountByType';
                readonly type: ProviderPostReactionType;
                readonly count: number;
            }>;
            readonly myReaction?: {
                readonly __typename: 'ProviderPostReaction';
                readonly id: string;
                readonly type: ProviderPostReactionType;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'ProviderPostPollOption';
                readonly id: string;
                readonly title: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'ProviderPostPollResponse';
                readonly id: string;
                readonly providerPostPollOption: {
                    readonly __typename: 'ProviderPostPollOption';
                    readonly id: string;
                    readonly title: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
        };
    };
};

export type UpdateProviderPostMutationVariables = Exact<{
    input: UpdateProviderPostInput;
}>;

export type UpdateProviderPostMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderPost: {
        readonly __typename: 'UpdateProviderPostPayload';
        readonly providerPost: {
            readonly __typename: 'ProviderPost';
            readonly itemType: NewsfeedItemType;
            readonly itemId: string;
            readonly itemCreated: string;
            readonly itemUpdated?: string | null;
            readonly content: string;
            readonly countryCodes?: ReadonlyArray<string> | null;
            readonly mentorOnly: boolean;
            readonly mentorStudentOnly: boolean;
            readonly imageUrl?: string | null;
            readonly hasReaction: boolean;
            readonly pollExpiresAt?: string | null;
            readonly totalPollResponsesCount: number;
            readonly canViewPollResults: boolean;
            readonly isPinned: boolean;
            readonly postMetadata: {
                readonly __typename: 'PostMetadata';
                readonly author?: string | null;
                readonly date?: string | null;
                readonly description?: string | null;
                readonly image?: string | null;
                readonly video?: string | null;
                readonly lang?: string | null;
                readonly logo?: string | null;
                readonly publisher?: string | null;
                readonly title?: string | null;
                readonly url?: string | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            };
            readonly reactionsTotalCountByType: ReadonlyArray<{
                readonly __typename: 'ProviderPostReactionTotalCountByType';
                readonly type: ProviderPostReactionType;
                readonly count: number;
            }>;
            readonly myReaction?: {
                readonly __typename: 'ProviderPostReaction';
                readonly id: string;
                readonly type: ProviderPostReactionType;
            } | null;
            readonly pollOptions?: ReadonlyArray<{
                readonly __typename: 'ProviderPostPollOption';
                readonly id: string;
                readonly title: string;
                readonly totalResponsesCount: number;
                readonly totalResponsesCountPercentage: number;
            }> | null;
            readonly selectedPollOption?: {
                readonly __typename: 'ProviderPostPollResponse';
                readonly id: string;
                readonly providerPostPollOption: {
                    readonly __typename: 'ProviderPostPollOption';
                    readonly id: string;
                    readonly title: string;
                    readonly totalResponsesCount: number;
                    readonly totalResponsesCountPercentage: number;
                };
            } | null;
        };
    };
};

export type CreateOpportunityListingMutationVariables = Exact<{
    input: CreateOpportunityListingInput;
}>;

export type CreateOpportunityListingMutation = {
    readonly __typename: 'Mutation';
    readonly createOpportunityListing: {
        readonly __typename: 'CreateOpportunityListingPayload';
        readonly opportunityListing: {
            readonly __typename: 'OpportunityListing';
            readonly id: string;
            readonly type: OpportunityListingType;
            readonly title: string;
            readonly company: string;
            readonly country: string;
            readonly coverImageId?: string | null;
            readonly coverImageUrl?: string | null;
            readonly jobDescription?: string | null;
            readonly payRateDescription?: number | null;
            readonly workingHoursDescription?: number | null;
            readonly contactEmail?: string | null;
            readonly contactPhone?: string | null;
            readonly contactWebLink?: string | null;
            readonly listingRange: OpportunityListingRange;
            readonly referenceCode?: string | null;
            readonly expiresAt: string;
            readonly isPinned: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canExpire: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly geoLocation?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            } | null;
        };
    };
};

export type ExpireOpportunityListingMutationVariables = Exact<{
    input: ExpireOpportunityListingInput;
}>;

export type ExpireOpportunityListingMutation = {
    readonly __typename: 'Mutation';
    readonly expireOpportunityListing: {
        readonly __typename: 'ExpireOpportunityListingPayload';
        readonly opportunityListing: {
            readonly __typename: 'OpportunityListing';
            readonly id: string;
            readonly type: OpportunityListingType;
            readonly title: string;
            readonly company: string;
            readonly country: string;
            readonly coverImageId?: string | null;
            readonly coverImageUrl?: string | null;
            readonly jobDescription?: string | null;
            readonly payRateDescription?: number | null;
            readonly workingHoursDescription?: number | null;
            readonly contactEmail?: string | null;
            readonly contactPhone?: string | null;
            readonly contactWebLink?: string | null;
            readonly listingRange: OpportunityListingRange;
            readonly referenceCode?: string | null;
            readonly expiresAt: string;
            readonly isPinned: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canExpire: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly geoLocation?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            } | null;
        };
    };
};

export type OpportunityListingForProviderQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    opportunityListingId: Scalars['UUID']['input'];
}>;

export type OpportunityListingForProviderQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly opportunityListing?: {
            readonly __typename: 'OpportunityListing';
            readonly id: string;
            readonly type: OpportunityListingType;
            readonly title: string;
            readonly company: string;
            readonly country: string;
            readonly coverImageId?: string | null;
            readonly coverImageUrl?: string | null;
            readonly jobDescription?: string | null;
            readonly payRateDescription?: number | null;
            readonly workingHoursDescription?: number | null;
            readonly contactEmail?: string | null;
            readonly contactPhone?: string | null;
            readonly contactWebLink?: string | null;
            readonly listingRange: OpportunityListingRange;
            readonly referenceCode?: string | null;
            readonly expiresAt: string;
            readonly isPinned: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canExpire: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly geoLocation?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            } | null;
        } | null;
    } | null;
};

export type OpportunityListingQueryVariables = Exact<{
    opportunityListingId: Scalars['UUID']['input'];
}>;

export type OpportunityListingQuery = {
    readonly __typename: 'Query';
    readonly opportunityListing?: {
        readonly __typename: 'OpportunityListing';
        readonly id: string;
        readonly type: OpportunityListingType;
        readonly title: string;
        readonly company: string;
        readonly country: string;
        readonly coverImageId?: string | null;
        readonly coverImageUrl?: string | null;
        readonly jobDescription?: string | null;
        readonly payRateDescription?: number | null;
        readonly workingHoursDescription?: number | null;
        readonly contactEmail?: string | null;
        readonly contactPhone?: string | null;
        readonly contactWebLink?: string | null;
        readonly listingRange: OpportunityListingRange;
        readonly referenceCode?: string | null;
        readonly expiresAt: string;
        readonly isPinned: boolean;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canExpire: boolean;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly geoLocation?: {
            readonly __typename: 'GeoLocation';
            readonly id: string;
            readonly name: string;
            readonly displayName: string;
            readonly longitude?: number | null;
            readonly latitude?: number | null;
            readonly street?: string | null;
            readonly city?: string | null;
            readonly county?: string | null;
            readonly state?: string | null;
            readonly country?: string | null;
            readonly countryCode?: string | null;
        } | null;
    } | null;
};

export type OpportunityListingsForProviderQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    country?: InputMaybe<Scalars['String']['input']>;
    geoLocationId?: InputMaybe<Scalars['UUID']['input']>;
    types?: InputMaybe<ReadonlyArray<OpportunityListingType> | OpportunityListingType>;
    query?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<OpportunitySortType>;
    includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type OpportunityListingsForProviderQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly opportunityListings: {
            readonly __typename: 'OpportunityListingConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'OpportunityListingEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'OpportunityListing';
                    readonly id: string;
                    readonly type: OpportunityListingType;
                    readonly title: string;
                    readonly company: string;
                    readonly country: string;
                    readonly coverImageId?: string | null;
                    readonly coverImageUrl?: string | null;
                    readonly jobDescription?: string | null;
                    readonly payRateDescription?: number | null;
                    readonly workingHoursDescription?: number | null;
                    readonly contactEmail?: string | null;
                    readonly contactPhone?: string | null;
                    readonly contactWebLink?: string | null;
                    readonly listingRange: OpportunityListingRange;
                    readonly referenceCode?: string | null;
                    readonly expiresAt: string;
                    readonly isPinned: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canExpire: boolean;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly geoLocation?: {
                        readonly __typename: 'GeoLocation';
                        readonly id: string;
                        readonly name: string;
                        readonly displayName: string;
                        readonly longitude?: number | null;
                        readonly latitude?: number | null;
                        readonly street?: string | null;
                        readonly city?: string | null;
                        readonly county?: string | null;
                        readonly state?: string | null;
                        readonly country?: string | null;
                        readonly countryCode?: string | null;
                    } | null;
                } | null;
            }>;
        };
    } | null;
};

export type OpportunityListingsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    country?: InputMaybe<Scalars['String']['input']>;
    geoLocationId?: InputMaybe<Scalars['UUID']['input']>;
    types?: InputMaybe<ReadonlyArray<OpportunityListingType> | OpportunityListingType>;
    query?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<OpportunitySortType>;
}>;

export type OpportunityListingsQuery = {
    readonly __typename: 'Query';
    readonly opportunityListings: {
        readonly __typename: 'OpportunityListingConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'OpportunityListingEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'OpportunityListing';
                readonly id: string;
                readonly type: OpportunityListingType;
                readonly title: string;
                readonly company: string;
                readonly country: string;
                readonly coverImageId?: string | null;
                readonly coverImageUrl?: string | null;
                readonly jobDescription?: string | null;
                readonly payRateDescription?: number | null;
                readonly workingHoursDescription?: number | null;
                readonly contactEmail?: string | null;
                readonly contactPhone?: string | null;
                readonly contactWebLink?: string | null;
                readonly listingRange: OpportunityListingRange;
                readonly referenceCode?: string | null;
                readonly expiresAt: string;
                readonly isPinned: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canExpire: boolean;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
                readonly geoLocation?: {
                    readonly __typename: 'GeoLocation';
                    readonly id: string;
                    readonly name: string;
                    readonly displayName: string;
                    readonly longitude?: number | null;
                    readonly latitude?: number | null;
                    readonly street?: string | null;
                    readonly city?: string | null;
                    readonly county?: string | null;
                    readonly state?: string | null;
                    readonly country?: string | null;
                    readonly countryCode?: string | null;
                } | null;
            } | null;
        }>;
    };
};

export type UpdateOpportunityListingMutationVariables = Exact<{
    input: UpdateOpportunityListingInput;
}>;

export type UpdateOpportunityListingMutation = {
    readonly __typename: 'Mutation';
    readonly updateOpportunityListing: {
        readonly __typename: 'UpdateOpportunityListingPayload';
        readonly opportunityListing: {
            readonly __typename: 'OpportunityListing';
            readonly id: string;
            readonly type: OpportunityListingType;
            readonly title: string;
            readonly company: string;
            readonly country: string;
            readonly coverImageId?: string | null;
            readonly coverImageUrl?: string | null;
            readonly jobDescription?: string | null;
            readonly payRateDescription?: number | null;
            readonly workingHoursDescription?: number | null;
            readonly contactEmail?: string | null;
            readonly contactPhone?: string | null;
            readonly contactWebLink?: string | null;
            readonly listingRange: OpportunityListingRange;
            readonly referenceCode?: string | null;
            readonly expiresAt: string;
            readonly isPinned: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canExpire: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly geoLocation?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly longitude?: number | null;
                readonly latitude?: number | null;
                readonly street?: string | null;
                readonly city?: string | null;
                readonly county?: string | null;
                readonly state?: string | null;
                readonly country?: string | null;
                readonly countryCode?: string | null;
            } | null;
        };
    };
};

export type CreatePathwayPlannerMutationVariables = Exact<{
    input: CreatePathwayPlannerInput;
}>;

export type CreatePathwayPlannerMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlanner: {
        readonly __typename: 'CreatePathwayPlannerPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CreatePathwayPlannerPersonalRequirementMutationVariables = Exact<{
    input: CreatePathwayPlannerPersonalRequirementTodoInput;
}>;

export type CreatePathwayPlannerPersonalRequirementMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlannerPersonalRequirement: {
        readonly __typename: 'CreatePathwayPlannerPersonalRequirementPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CreatePathwayPlannerQualificationMutationVariables = Exact<{
    input: CreatePathwayPlannerQualificationTodoInput;
}>;

export type CreatePathwayPlannerQualificationMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlannerQualification: {
        readonly __typename: 'CreatePathwayPlannerQualificationPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CreatePathwayPlannerSkillAndKnowledgeMutationVariables = Exact<{
    input: CreatePathwayPlannerSkillAndKnowledgeTodoInput;
}>;

export type CreatePathwayPlannerSkillAndKnowledgeMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlannerSkillAndKnowledge: {
        readonly __typename: 'CreatePathwayPlannerSkillAndKnowledgePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CreatePathwayPlannerStageMutationVariables = Exact<{
    input: CreatePathwayPlannerStageInput;
}>;

export type CreatePathwayPlannerStageMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlannerStage: {
        readonly __typename: 'CreatePathwayPlannerStagePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CreatePathwayPlannerStageSubjectMutationVariables = Exact<{
    input: CreatePathwayPlannerStageSubjectTodoInput;
}>;

export type CreatePathwayPlannerStageSubjectMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlannerStageSubject: {
        readonly __typename: 'CreatePathwayPlannerStageSubjectPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CreatePathwayPlannerStageUsefulExperienceMutationVariables = Exact<{
    input: CreatePathwayPlannerStageUsefulExperienceTodoInput;
}>;

export type CreatePathwayPlannerStageUsefulExperienceMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlannerStageUsefulExperience: {
        readonly __typename: 'CreatePathwayPlannerStageUsefulExperiencePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CreatePathwayPlannerUsefulExperienceMutationVariables = Exact<{
    input: CreatePathwayPlannerUsefulExperienceTodoInput;
}>;

export type CreatePathwayPlannerUsefulExperienceMutation = {
    readonly __typename: 'Mutation';
    readonly createPathwayPlannerUsefulExperience: {
        readonly __typename: 'CreatePathwayPlannerUsefulExperiencePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type DeletePathwayPlannerMutationVariables = Exact<{
    input: DeletePathwayPlannerInput;
}>;

export type DeletePathwayPlannerMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlanner: { readonly __typename: 'DeletePathwayPlannerPayload'; readonly pathwayPlannerId: string };
};

export type DeletePathwayPlannerPersonalRequirementMutationVariables = Exact<{
    input: DeletePathwayPlannerPersonalRequirementTodoInput;
}>;

export type DeletePathwayPlannerPersonalRequirementMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlannerPersonalRequirement: {
        readonly __typename: 'DeletePathwayPlannerPersonalRequirementPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type DeletePathwayPlannerQualificationMutationVariables = Exact<{
    input: DeletePathwayPlannerQualificationTodoInput;
}>;

export type DeletePathwayPlannerQualificationMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlannerQualification: {
        readonly __typename: 'DeletePathwayPlannerQualificationPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type DeletePathwayPlannerSkillAndKnowledgeMutationVariables = Exact<{
    input: DeletePathwayPlannerSkillAndKnowledgeTodoInput;
}>;

export type DeletePathwayPlannerSkillAndKnowledgeMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlannerSkillAndKnowledge: {
        readonly __typename: 'DeletePathwayPlannerSkillAndKnowledgePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type DeletePathwayPlannerStageMutationVariables = Exact<{
    input: DeletePathwayPlannerStageInput;
}>;

export type DeletePathwayPlannerStageMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlannerStage: {
        readonly __typename: 'DeletePathwayPlannerStagePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type DeletePathwayPlannerStageSubjectMutationVariables = Exact<{
    input: DeletePathwayPlannerStageSubjectTodoInput;
}>;

export type DeletePathwayPlannerStageSubjectMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlannerStageSubject: {
        readonly __typename: 'DeletePathwayPlannerStageSubjectPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type DeletePathwayPlannerStageUsefulExperienceMutationVariables = Exact<{
    input: DeletePathwayPlannerStageUsefulExperienceTodoInput;
}>;

export type DeletePathwayPlannerStageUsefulExperienceMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlannerStageUsefulExperience: {
        readonly __typename: 'DeletePathwayPlannerStageUsefulExperiencePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type DeletePathwayPlannerUsefulExperienceMutationVariables = Exact<{
    input: DeletePathwayPlannerUsefulExperienceTodoInput;
}>;

export type DeletePathwayPlannerUsefulExperienceMutation = {
    readonly __typename: 'Mutation';
    readonly deletePathwayPlannerUsefulExperience: {
        readonly __typename: 'DeletePathwayPlannerUsefulExperiencePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type GeneratePathwayPlannerInformationSuggestionsMutationVariables = Exact<{
    input: GeneratePathwayPlannerInformationSuggestionsInput;
}>;

export type GeneratePathwayPlannerInformationSuggestionsMutation = {
    readonly __typename: 'Mutation';
    readonly generatePathwayPlannerInformationSuggestions: {
        readonly __typename: 'GeneratePathwayPlannerInformationSuggestionsPayload';
        readonly pathwaySuggestions: ReadonlyArray<string>;
    };
};

export type MyPathwayPlannersQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyPathwayPlannersQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly countryCode: string;
        readonly pathwayPlanners: {
            readonly __typename: 'PathwayPlannerConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'PathwayPlanner';
                    readonly id: string;
                    readonly title: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly usefulExperienceList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly qualificationsList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerQualificationTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly personalRequirementsList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly skillsAndKnowledgeList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                        readonly id: string;
                        readonly completed: boolean;
                        readonly title: string;
                    }> | null;
                    readonly stagesList?: ReadonlyArray<{
                        readonly __typename: 'PathwayPlannerStage';
                        readonly id: string;
                        readonly title: string;
                        readonly usefulExperienceList?: ReadonlyArray<{
                            readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                            readonly id: string;
                            readonly completed: boolean;
                            readonly title: string;
                        }> | null;
                        readonly subjectsList?: ReadonlyArray<{
                            readonly __typename: 'PathwayPlannerStageSubjectTodo';
                            readonly id: string;
                            readonly completed: boolean;
                            readonly title: string;
                        }> | null;
                    }> | null;
                } | null;
            }>;
        };
    };
};

export type PathwayPlannerQueryVariables = Exact<{
    pathwayPlannerId: Scalars['UUID']['input'];
}>;

export type PathwayPlannerQuery = {
    readonly __typename: 'Query';
    readonly pathwayPlanner?: {
        readonly __typename: 'PathwayPlanner';
        readonly id: string;
        readonly title: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly usefulExperienceList?: ReadonlyArray<{
            readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
            readonly id: string;
            readonly completed: boolean;
            readonly title: string;
        }> | null;
        readonly qualificationsList?: ReadonlyArray<{
            readonly __typename: 'PathwayPlannerQualificationTodo';
            readonly id: string;
            readonly completed: boolean;
            readonly title: string;
        }> | null;
        readonly personalRequirementsList?: ReadonlyArray<{
            readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
            readonly id: string;
            readonly completed: boolean;
            readonly title: string;
        }> | null;
        readonly skillsAndKnowledgeList?: ReadonlyArray<{
            readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
            readonly id: string;
            readonly completed: boolean;
            readonly title: string;
        }> | null;
        readonly stagesList?: ReadonlyArray<{
            readonly __typename: 'PathwayPlannerStage';
            readonly id: string;
            readonly title: string;
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly subjectsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStageSubjectTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
        }> | null;
    } | null;
};

export type UpdatePathwayPlannerMutationVariables = Exact<{
    input: UpdatePathwayPlannerInput;
}>;

export type UpdatePathwayPlannerMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlanner: {
        readonly __typename: 'UpdatePathwayPlannerPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type UpdatePathwayPlannerPersonalRequirementMutationVariables = Exact<{
    input: UpdatePathwayPlannerPersonalRequirementTodoInput;
}>;

export type UpdatePathwayPlannerPersonalRequirementMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlannerPersonalRequirement: {
        readonly __typename: 'UpdatePathwayPlannerPersonalRequirementPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type UpdatePathwayPlannerQualificationMutationVariables = Exact<{
    input: UpdatePathwayPlannerQualificationTodoInput;
}>;

export type UpdatePathwayPlannerQualificationMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlannerQualification: {
        readonly __typename: 'UpdatePathwayPlannerQualificationPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type UpdatePathwayPlannerSkillAndKnowledgeMutationVariables = Exact<{
    input: UpdatePathwayPlannerSkillAndKnowledgeTodoInput;
}>;

export type UpdatePathwayPlannerSkillAndKnowledgeMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlannerSkillAndKnowledge: {
        readonly __typename: 'UpdatePathwayPlannerSkillAndKnowledgePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type UpdatePathwayPlannerStageMutationVariables = Exact<{
    input: UpdatePathwayPlannerStageInput;
}>;

export type UpdatePathwayPlannerStageMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlannerStage: {
        readonly __typename: 'UpdatePathwayPlannerStagePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type UpdatePathwayPlannerStageSubjectMutationVariables = Exact<{
    input: UpdatePathwayPlannerStageSubjectTodoInput;
}>;

export type UpdatePathwayPlannerStageSubjectMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlannerStageSubject: {
        readonly __typename: 'UpdatePathwayPlannerStageSubjectPayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type UpdatePathwayPlannerStageUsefulExperienceMutationVariables = Exact<{
    input: UpdatePathwayPlannerStageUsefulExperienceTodoInput;
}>;

export type UpdatePathwayPlannerStageUsefulExperienceMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlannerStageUsefulExperience: {
        readonly __typename: 'UpdatePathwayPlannerStageUsefulExperiencePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type UpdatePathwayPlannerUsefulExperienceMutationVariables = Exact<{
    input: UpdatePathwayPlannerUsefulExperienceTodoInput;
}>;

export type UpdatePathwayPlannerUsefulExperienceMutation = {
    readonly __typename: 'Mutation';
    readonly updatePathwayPlannerUsefulExperience: {
        readonly __typename: 'UpdatePathwayPlannerUsefulExperiencePayload';
        readonly pathwayPlanner: {
            readonly __typename: 'PathwayPlanner';
            readonly id: string;
            readonly title: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly usefulExperienceList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerUsefulExperienceTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly qualificationsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerQualificationTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly personalRequirementsList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerPersonalRequirementTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly skillsAndKnowledgeList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerSkillAndKnowledgeTodo';
                readonly id: string;
                readonly completed: boolean;
                readonly title: string;
            }> | null;
            readonly stagesList?: ReadonlyArray<{
                readonly __typename: 'PathwayPlannerStage';
                readonly id: string;
                readonly title: string;
                readonly usefulExperienceList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageUsefulExperienceTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
                readonly subjectsList?: ReadonlyArray<{
                    readonly __typename: 'PathwayPlannerStageSubjectTodo';
                    readonly id: string;
                    readonly completed: boolean;
                    readonly title: string;
                }> | null;
            }> | null;
        };
    };
};

export type CompleteProjectSectionMutationVariables = Exact<{
    input: CompleteProjectSectionInput;
}>;

export type CompleteProjectSectionMutation = {
    readonly __typename: 'Mutation';
    readonly completeProjectSection: {
        readonly __typename: 'CompleteProjectSectionPayload';
        readonly projectSection: {
            readonly __typename: 'ProjectSection';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly response?: string | null;
            readonly isComplete: boolean;
            readonly isReviewComplete: boolean;
            readonly isReviewRequested: boolean;
            readonly completedAt?: string | null;
            readonly reviewCompletedAt?: string | null;
            readonly reviewRequestedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canRequestReview: boolean;
            readonly canCompleteReview: boolean;
            readonly canCompleteSection: boolean;
            readonly canCreateComment: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProjectSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly evidences: ReadonlyArray<{
                readonly __typename: 'ProjectSectionEvidence';
                readonly id: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resourceHubItem: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                };
            }>;
            readonly comments: ReadonlyArray<{
                readonly __typename: 'ProjectSectionComment';
                readonly id: string;
                readonly comment: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            }>;
        };
    };
};

export type CompleteProjectSectionReviewMutationVariables = Exact<{
    input: CompleteProjectSectionReviewInput;
}>;

export type CompleteProjectSectionReviewMutation = {
    readonly __typename: 'Mutation';
    readonly completeProjectSectionReview: {
        readonly __typename: 'CompleteProjectSectionReviewPayload';
        readonly projectSection: {
            readonly __typename: 'ProjectSection';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly response?: string | null;
            readonly isComplete: boolean;
            readonly isReviewComplete: boolean;
            readonly isReviewRequested: boolean;
            readonly completedAt?: string | null;
            readonly reviewCompletedAt?: string | null;
            readonly reviewRequestedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canRequestReview: boolean;
            readonly canCompleteReview: boolean;
            readonly canCompleteSection: boolean;
            readonly canCreateComment: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProjectSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly evidences: ReadonlyArray<{
                readonly __typename: 'ProjectSectionEvidence';
                readonly id: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resourceHubItem: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                };
            }>;
            readonly comments: ReadonlyArray<{
                readonly __typename: 'ProjectSectionComment';
                readonly id: string;
                readonly comment: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            }>;
        };
    };
};

export type CreateProjectMutationVariables = Exact<{
    input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
    readonly __typename: 'Mutation';
    readonly createProject: {
        readonly __typename: 'CreateProjectPayload';
        readonly project: {
            readonly __typename: 'Project';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly bannerImageId?: string | null;
            readonly overviewDescription: string;
            readonly sourceTemplateId?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly totalProjectSections: number;
            readonly totalCompletedProjectSections: number;
            readonly latestReviewRequestedAt?: string | null;
            readonly completedAt?: string | null;
            readonly canUpdateMentorGroup: boolean;
            readonly canDelete: boolean;
            readonly isLocked: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly overviewResources: ReadonlyArray<{
                readonly __typename: 'ProjectOverviewResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly sections: ReadonlyArray<{
                readonly __typename: 'ProjectSection';
                readonly id: string;
                readonly title: string;
                readonly color: string;
                readonly description: string;
                readonly response?: string | null;
                readonly isComplete: boolean;
                readonly isReviewComplete: boolean;
                readonly isReviewRequested: boolean;
                readonly completedAt?: string | null;
                readonly reviewCompletedAt?: string | null;
                readonly reviewRequestedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canRequestReview: boolean;
                readonly canCompleteReview: boolean;
                readonly canCompleteSection: boolean;
                readonly canCreateComment: boolean;
                readonly resources: ReadonlyArray<{
                    readonly __typename: 'ProjectSectionResource';
                    readonly id: string;
                    readonly url: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly lesson?: {
                        readonly __typename: 'Lesson';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly description: string;
                        readonly coverImageUrl?: string | null;
                        readonly documentId: string;
                        readonly documentIframeUrl?: string | null;
                        readonly tags: ReadonlyArray<string>;
                        readonly isFeatured: boolean;
                        readonly countryCodes: ReadonlyArray<string>;
                        readonly favoriteCount: number;
                        readonly hasRead: boolean;
                        readonly hasFavorited: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly lessonCategory: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                            readonly canUpdate: boolean;
                            readonly canDelete: boolean;
                            readonly parent?: {
                                readonly __typename: 'LessonCategory';
                                readonly id: string;
                                readonly locale: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                }>;
                readonly evidences: ReadonlyArray<{
                    readonly __typename: 'ProjectSectionEvidence';
                    readonly id: string;
                    readonly description?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly resourceHubItem: {
                        readonly __typename: 'ResourceHubItem';
                        readonly id: string;
                        readonly title: string;
                        readonly category: ResourceHubItemCategory;
                        readonly type: ResourceHubItemType;
                        readonly uses: number;
                        readonly fileId: string;
                        readonly fileAvailable: boolean;
                        readonly fileName: string;
                        readonly fileMimeType: string;
                        readonly fileUrl?: string | null;
                        readonly filePreviewUrl: string;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                    };
                }>;
                readonly comments: ReadonlyArray<{
                    readonly __typename: 'ProjectSectionComment';
                    readonly id: string;
                    readonly comment: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            }>;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateProjectSectionCommentMutationVariables = Exact<{
    input: CreateProjectSectionCommentInput;
}>;

export type CreateProjectSectionCommentMutation = {
    readonly __typename: 'Mutation';
    readonly createProjectSectionComment: {
        readonly __typename: 'CreateProjectSectionCommentPayload';
        readonly projectSectionComment: {
            readonly __typename: 'ProjectSectionComment';
            readonly id: string;
            readonly comment: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateProjectSectionEvidenceMutationVariables = Exact<{
    input: CreateProjectSectionEvidenceInput;
}>;

export type CreateProjectSectionEvidenceMutation = {
    readonly __typename: 'Mutation';
    readonly createProjectSectionEvidence: {
        readonly __typename: 'CreateProjectSectionEvidencePayload';
        readonly projectSectionEvidence?: {
            readonly __typename: 'ProjectSectionEvidence';
            readonly id: string;
            readonly description?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resourceHubItem: {
                readonly __typename: 'ResourceHubItem';
                readonly id: string;
                readonly title: string;
                readonly category: ResourceHubItemCategory;
                readonly type: ResourceHubItemType;
                readonly uses: number;
                readonly fileId: string;
                readonly fileAvailable: boolean;
                readonly fileName: string;
                readonly fileMimeType: string;
                readonly fileUrl?: string | null;
                readonly filePreviewUrl: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            };
        } | null;
    };
};

export type CreateProjectsForMentorGroupStudentsMutationVariables = Exact<{
    input: CreateProjectsForMentorGroupStudentsInput;
}>;

export type CreateProjectsForMentorGroupStudentsMutation = {
    readonly __typename: 'Mutation';
    readonly createProjectsForMentorGroupStudents: {
        readonly __typename: 'CreateProjectsForMentorGroupStudentsPayload';
        readonly projects: ReadonlyArray<{
            readonly __typename: 'Project';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly bannerImageId?: string | null;
            readonly overviewDescription: string;
            readonly sourceTemplateId?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly totalProjectSections: number;
            readonly totalCompletedProjectSections: number;
            readonly latestReviewRequestedAt?: string | null;
            readonly completedAt?: string | null;
            readonly canUpdateMentorGroup: boolean;
            readonly canDelete: boolean;
            readonly isLocked: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            readonly overviewResources: ReadonlyArray<{
                readonly __typename: 'ProjectOverviewResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly sections: ReadonlyArray<{
                readonly __typename: 'ProjectSection';
                readonly id: string;
                readonly title: string;
                readonly color: string;
                readonly description: string;
                readonly response?: string | null;
                readonly isComplete: boolean;
                readonly isReviewComplete: boolean;
                readonly isReviewRequested: boolean;
                readonly completedAt?: string | null;
                readonly reviewCompletedAt?: string | null;
                readonly reviewRequestedAt?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canRequestReview: boolean;
                readonly canCompleteReview: boolean;
                readonly canCompleteSection: boolean;
                readonly canCreateComment: boolean;
                readonly resources: ReadonlyArray<{
                    readonly __typename: 'ProjectSectionResource';
                    readonly id: string;
                    readonly url: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly lesson?: {
                        readonly __typename: 'Lesson';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly description: string;
                        readonly coverImageUrl?: string | null;
                        readonly documentId: string;
                        readonly documentIframeUrl?: string | null;
                        readonly tags: ReadonlyArray<string>;
                        readonly isFeatured: boolean;
                        readonly countryCodes: ReadonlyArray<string>;
                        readonly favoriteCount: number;
                        readonly hasRead: boolean;
                        readonly hasFavorited: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly lessonCategory: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                            readonly canUpdate: boolean;
                            readonly canDelete: boolean;
                            readonly parent?: {
                                readonly __typename: 'LessonCategory';
                                readonly id: string;
                                readonly locale: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                }>;
                readonly evidences: ReadonlyArray<{
                    readonly __typename: 'ProjectSectionEvidence';
                    readonly id: string;
                    readonly description?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly resourceHubItem: {
                        readonly __typename: 'ResourceHubItem';
                        readonly id: string;
                        readonly title: string;
                        readonly category: ResourceHubItemCategory;
                        readonly type: ResourceHubItemType;
                        readonly uses: number;
                        readonly fileId: string;
                        readonly fileAvailable: boolean;
                        readonly fileName: string;
                        readonly fileMimeType: string;
                        readonly fileUrl?: string | null;
                        readonly filePreviewUrl: string;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                    };
                }>;
                readonly comments: ReadonlyArray<{
                    readonly __typename: 'ProjectSectionComment';
                    readonly id: string;
                    readonly comment: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                }>;
            }>;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    };
};

export type DeleteProjectMutationVariables = Exact<{
    input: DeleteProjectInput;
}>;

export type DeleteProjectMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProject: { readonly __typename: 'DeleteProjectPayload'; readonly projectId: string };
};

export type DeleteProjectSectionCommentMutationVariables = Exact<{
    input: DeleteProjectSectionCommentInput;
}>;

export type DeleteProjectSectionCommentMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProjectSectionComment: {
        readonly __typename: 'DeleteProjectSectionCommentPayload';
        readonly projectSectionCommentId: string;
    };
};

export type DeleteProjectSectionEvidenceMutationVariables = Exact<{
    input: DeleteProjectSectionEvidenceInput;
}>;

export type DeleteProjectSectionEvidenceMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProjectSectionEvidence: {
        readonly __typename: 'DeleteProjectSectionEvidencePayload';
        readonly projectSectionEvidenceId: string;
    };
};

export type MyProjectsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyProjectsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly projects: {
            readonly __typename: 'ProjectConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProjectEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Project';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly bannerImageId?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly totalCompletedProjectSections: number;
                    readonly totalProjectSections: number;
                    readonly latestReviewRequestedAt?: string | null;
                    readonly completedAt?: string | null;
                    readonly canUpdateMentorGroup: boolean;
                    readonly canDelete: boolean;
                    readonly isLocked: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type ProjectMentorGroupsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    mentorStudentId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProjectMentorGroupsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorGroups: {
            readonly __typename: 'MentorGroupConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorGroupEdge';
                readonly cursor: string;
                readonly node?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
            }>;
        };
    } | null;
};

export type ProjectQueryVariables = Exact<{
    projectId: Scalars['UUID']['input'];
}>;

export type ProjectQuery = {
    readonly __typename: 'Query';
    readonly project?: {
        readonly __typename: 'Project';
        readonly id: string;
        readonly title: string;
        readonly bannerImageUrl?: string | null;
        readonly bannerImageId?: string | null;
        readonly overviewDescription: string;
        readonly sourceTemplateId?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly totalProjectSections: number;
        readonly totalCompletedProjectSections: number;
        readonly latestReviewRequestedAt?: string | null;
        readonly completedAt?: string | null;
        readonly canUpdateMentorGroup: boolean;
        readonly canDelete: boolean;
        readonly isLocked: boolean;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
        readonly overviewResources: ReadonlyArray<{
            readonly __typename: 'ProjectOverviewResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
        readonly sections: ReadonlyArray<{
            readonly __typename: 'ProjectSection';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly response?: string | null;
            readonly isComplete: boolean;
            readonly isReviewComplete: boolean;
            readonly isReviewRequested: boolean;
            readonly completedAt?: string | null;
            readonly reviewCompletedAt?: string | null;
            readonly reviewRequestedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canRequestReview: boolean;
            readonly canCompleteReview: boolean;
            readonly canCompleteSection: boolean;
            readonly canCreateComment: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProjectSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly evidences: ReadonlyArray<{
                readonly __typename: 'ProjectSectionEvidence';
                readonly id: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resourceHubItem: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                };
            }>;
            readonly comments: ReadonlyArray<{
                readonly __typename: 'ProjectSectionComment';
                readonly id: string;
                readonly comment: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            }>;
        }>;
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    } | null;
};

export type ProjectSectionQueryVariables = Exact<{
    projectSectionId: Scalars['UUID']['input'];
}>;

export type ProjectSectionQuery = {
    readonly __typename: 'Query';
    readonly projectSection?: {
        readonly __typename: 'ProjectSection';
        readonly id: string;
        readonly title: string;
        readonly color: string;
        readonly description: string;
        readonly response?: string | null;
        readonly isComplete: boolean;
        readonly isReviewComplete: boolean;
        readonly isReviewRequested: boolean;
        readonly completedAt?: string | null;
        readonly reviewCompletedAt?: string | null;
        readonly reviewRequestedAt?: string | null;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canRequestReview: boolean;
        readonly canCompleteReview: boolean;
        readonly canCompleteSection: boolean;
        readonly canCreateComment: boolean;
        readonly resources: ReadonlyArray<{
            readonly __typename: 'ProjectSectionResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
        readonly evidences: ReadonlyArray<{
            readonly __typename: 'ProjectSectionEvidence';
            readonly id: string;
            readonly description?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resourceHubItem: {
                readonly __typename: 'ResourceHubItem';
                readonly id: string;
                readonly title: string;
                readonly category: ResourceHubItemCategory;
                readonly type: ResourceHubItemType;
                readonly uses: number;
                readonly fileId: string;
                readonly fileAvailable: boolean;
                readonly fileName: string;
                readonly fileMimeType: string;
                readonly fileUrl?: string | null;
                readonly filePreviewUrl: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            };
        }>;
        readonly comments: ReadonlyArray<{
            readonly __typename: 'ProjectSectionComment';
            readonly id: string;
            readonly comment: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    } | null;
};

export type ProjectsSimilarInMentorGroupQueryVariables = Exact<{
    projectId: Scalars['UUID']['input'];
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProjectsSimilarInMentorGroupQuery = {
    readonly __typename: 'Query';
    readonly projectsSimilarInMentorGroup: {
        readonly __typename: 'ProjectConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'ProjectEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'Project';
                readonly id: string;
                readonly title: string;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly fullName: string;
                    readonly pictureUrl?: string | null;
                };
            } | null;
        }>;
    };
};

export type ProviderMentorStudentsProjectsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    filter?: InputMaybe<ProjectFilterType>;
}>;

export type ProviderMentorStudentsProjectsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorStudentsProjects: {
            readonly __typename: 'ProjectConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProjectEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Project';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly bannerImageId?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly totalCompletedProjectSections: number;
                    readonly totalProjectSections: number;
                    readonly latestReviewRequestedAt?: string | null;
                    readonly completedAt?: string | null;
                    readonly canUpdateMentorGroup: boolean;
                    readonly canDelete: boolean;
                    readonly isLocked: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type RequestProjectSectionReviewMutationVariables = Exact<{
    input: RequestProjectSectionReviewInput;
}>;

export type RequestProjectSectionReviewMutation = {
    readonly __typename: 'Mutation';
    readonly requestProjectSectionReview: {
        readonly __typename: 'RequestProjectSectionReviewPayload';
        readonly projectSection: {
            readonly __typename: 'ProjectSection';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly response?: string | null;
            readonly isComplete: boolean;
            readonly isReviewComplete: boolean;
            readonly isReviewRequested: boolean;
            readonly completedAt?: string | null;
            readonly reviewCompletedAt?: string | null;
            readonly reviewRequestedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canRequestReview: boolean;
            readonly canCompleteReview: boolean;
            readonly canCompleteSection: boolean;
            readonly canCreateComment: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProjectSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly evidences: ReadonlyArray<{
                readonly __typename: 'ProjectSectionEvidence';
                readonly id: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resourceHubItem: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                };
            }>;
            readonly comments: ReadonlyArray<{
                readonly __typename: 'ProjectSectionComment';
                readonly id: string;
                readonly comment: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            }>;
        };
    };
};

export type UncompleteProjectSectionMutationVariables = Exact<{
    input: UncompleteProjectSectionInput;
}>;

export type UncompleteProjectSectionMutation = {
    readonly __typename: 'Mutation';
    readonly uncompleteProjectSection: {
        readonly __typename: 'UncompleteProjectSectionPayload';
        readonly projectSection: {
            readonly __typename: 'ProjectSection';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly response?: string | null;
            readonly isComplete: boolean;
            readonly isReviewComplete: boolean;
            readonly isReviewRequested: boolean;
            readonly completedAt?: string | null;
            readonly reviewCompletedAt?: string | null;
            readonly reviewRequestedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canRequestReview: boolean;
            readonly canCompleteReview: boolean;
            readonly canCompleteSection: boolean;
            readonly canCreateComment: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProjectSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly evidences: ReadonlyArray<{
                readonly __typename: 'ProjectSectionEvidence';
                readonly id: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resourceHubItem: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                };
            }>;
            readonly comments: ReadonlyArray<{
                readonly __typename: 'ProjectSectionComment';
                readonly id: string;
                readonly comment: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            }>;
        };
    };
};

export type UpdateProjectMentorGroupMutationVariables = Exact<{
    input: UpdateProjectMentorGroupInput;
}>;

export type UpdateProjectMentorGroupMutation = {
    readonly __typename: 'Mutation';
    readonly updateProjectMentorGroup: {
        readonly __typename: 'UpdateProjectMentorGroupPayload';
        readonly project: {
            readonly __typename: 'Project';
            readonly id: string;
            readonly mentorGroup?: { readonly __typename: 'MentorGroup'; readonly id: string; readonly title: string } | null;
        };
    };
};

export type UpdateProjectSectionCommentMutationVariables = Exact<{
    input: UpdateProjectSectionCommentInput;
}>;

export type UpdateProjectSectionCommentMutation = {
    readonly __typename: 'Mutation';
    readonly updateProjectSectionComment: {
        readonly __typename: 'UpdateProjectSectionCommentPayload';
        readonly projectSectionComment: {
            readonly __typename: 'ProjectSectionComment';
            readonly id: string;
            readonly comment: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateProjectSectionEvidenceMutationVariables = Exact<{
    input: UpdateProjectSectionEvidenceInput;
}>;

export type UpdateProjectSectionEvidenceMutation = {
    readonly __typename: 'Mutation';
    readonly updateProjectSectionEvidence: {
        readonly __typename: 'UpdateProjectSectionEvidencePayload';
        readonly projectSectionEvidence?: {
            readonly __typename: 'ProjectSectionEvidence';
            readonly id: string;
            readonly description?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resourceHubItem: {
                readonly __typename: 'ResourceHubItem';
                readonly id: string;
                readonly title: string;
                readonly category: ResourceHubItemCategory;
                readonly type: ResourceHubItemType;
                readonly uses: number;
                readonly fileId: string;
                readonly fileAvailable: boolean;
                readonly fileName: string;
                readonly fileMimeType: string;
                readonly fileUrl?: string | null;
                readonly filePreviewUrl: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            };
        } | null;
    };
};

export type UpdateProjectSectionResponseMutationVariables = Exact<{
    input: UpdateProjectSectionResponseInput;
}>;

export type UpdateProjectSectionResponseMutation = {
    readonly __typename: 'Mutation';
    readonly updateProjectSectionResponse: {
        readonly __typename: 'UpdateProjectSectionResponsePayload';
        readonly projectSection: {
            readonly __typename: 'ProjectSection';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly response?: string | null;
            readonly isComplete: boolean;
            readonly isReviewComplete: boolean;
            readonly isReviewRequested: boolean;
            readonly completedAt?: string | null;
            readonly reviewCompletedAt?: string | null;
            readonly reviewRequestedAt?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canRequestReview: boolean;
            readonly canCompleteReview: boolean;
            readonly canCompleteSection: boolean;
            readonly canCreateComment: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProjectSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly evidences: ReadonlyArray<{
                readonly __typename: 'ProjectSectionEvidence';
                readonly id: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resourceHubItem: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                };
            }>;
            readonly comments: ReadonlyArray<{
                readonly __typename: 'ProjectSectionComment';
                readonly id: string;
                readonly comment: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly createdBy: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            }>;
        };
    };
};

export type CreateProviderMemberInviteMutationVariables = Exact<{
    input: CreateProviderMemberInviteInput;
}>;

export type CreateProviderMemberInviteMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderMemberInvite: {
        readonly __typename: 'CreateProviderMemberInvitePayload';
        readonly invite: {
            readonly __typename: 'ProviderMemberInvite';
            readonly id: string;
            readonly email: string;
            readonly redeemedAt?: string | null;
            readonly expiresAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
        };
    };
};

export type CreateProviderMemberInvitesMutationVariables = Exact<{
    input: CreateProviderMemberInvitesInput;
}>;

export type CreateProviderMemberInvitesMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderMemberInvites: {
        readonly __typename: 'CreateProviderMemberInvitesPayload';
        readonly invites: ReadonlyArray<{
            readonly __typename: 'ProviderMemberInvite';
            readonly id: string;
            readonly email: string;
            readonly redeemedAt?: string | null;
            readonly expiresAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
        }>;
    };
};

export type CreateProviderMutationVariables = Exact<{
    input: CreateProviderInput;
}>;

export type CreateProviderMutation = {
    readonly __typename: 'Mutation';
    readonly createProvider: {
        readonly __typename: 'CreateProviderPayload';
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
    };
};

export type CreateProviderMySchoolInformationMutationVariables = Exact<{
    input: CreateProviderMySchoolInformationInput;
}>;

export type CreateProviderMySchoolInformationMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderMySchoolInformation: {
        readonly __typename: 'CreateProviderMySchoolInformationPayload';
        readonly providerMySchoolInformationItem: {
            readonly __typename: 'ProviderMySchoolInformationItem';
            readonly id: string;
            readonly title: string;
            readonly information: string;
            readonly sequence: number;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type DeleteProviderJoinCodeMutationVariables = Exact<{
    input: DeleteProviderJoinCodeInput;
}>;

export type DeleteProviderJoinCodeMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderJoinCode: { readonly __typename: 'DeleteProviderJoinCodePayload'; readonly providerJoinCodeId: string };
};

export type DeleteProviderMemberInviteMutationVariables = Exact<{
    input: DeleteProviderMemberInviteInput;
}>;

export type DeleteProviderMemberInviteMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderMemberInvite: {
        readonly __typename: 'DeleteProviderMemberInvitePayload';
        readonly providerMemberInviteId: string;
    };
};

export type DeleteProviderMemberMutationVariables = Exact<{
    input: DeleteProviderMemberInput;
}>;

export type DeleteProviderMemberMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderMember: { readonly __typename: 'DeleteProviderMemberPayload'; readonly providerMemberId: string };
};

export type DeleteProviderMutationVariables = Exact<{
    input: DeleteProviderInput;
}>;

export type DeleteProviderMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProvider: { readonly __typename: 'DeleteProviderPayload'; readonly providerId: string };
};

export type DeleteProviderMySchoolInformationMutationVariables = Exact<{
    input: DeleteProviderMySchoolInformationInput;
}>;

export type DeleteProviderMySchoolInformationMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderMySchoolInformation: {
        readonly __typename: 'DeleteProviderMySchoolInformationPayload';
        readonly providerMySchoolInformationId: string;
    };
};

export type DisableProviderJoinCodeMutationVariables = Exact<{
    input: DisableProviderJoinCodeInput;
}>;

export type DisableProviderJoinCodeMutation = {
    readonly __typename: 'Mutation';
    readonly disableProviderJoinCode: {
        readonly __typename: 'DisableProviderJoinCodePayload';
        readonly providerJoinCode: {
            readonly __typename: 'ProviderJoinCode';
            readonly id: string;
            readonly code: string;
            readonly isEnabled: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type DisableProviderModuleMutationVariables = Exact<{
    input: DisableProviderModuleInput;
}>;

export type DisableProviderModuleMutation = {
    readonly __typename: 'Mutation';
    readonly disableProviderModule: {
        readonly __typename: 'DisableProviderModulePayload';
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
            readonly activeModules: ReadonlyArray<{ readonly __typename: 'ProviderModule'; readonly id: string; readonly title: string }>;
        };
    };
};

export type EnableProviderJoinCodeMutationVariables = Exact<{
    input: EnableProviderJoinCodeInput;
}>;

export type EnableProviderJoinCodeMutation = {
    readonly __typename: 'Mutation';
    readonly enableProviderJoinCode: {
        readonly __typename: 'EnableProviderJoinCodePayload';
        readonly providerJoinCode: {
            readonly __typename: 'ProviderJoinCode';
            readonly id: string;
            readonly code: string;
            readonly isEnabled: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type EnableProviderModuleMutationVariables = Exact<{
    input: EnableProviderModuleInput;
}>;

export type EnableProviderModuleMutation = {
    readonly __typename: 'Mutation';
    readonly enableProviderModule: {
        readonly __typename: 'EnableProviderModulePayload';
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
            readonly activeModules: ReadonlyArray<{ readonly __typename: 'ProviderModule'; readonly id: string; readonly title: string }>;
        };
    };
};

export type FollowProviderMutationVariables = Exact<{
    input: FollowProviderInput;
}>;

export type FollowProviderMutation = {
    readonly __typename: 'Mutation';
    readonly followProvider: { readonly __typename: 'FollowProviderPayload'; readonly providerId: string };
};

export type GenerateProviderJoinCodeMutationVariables = Exact<{
    input: GenerateProviderJoinCodeInput;
}>;

export type GenerateProviderJoinCodeMutation = {
    readonly __typename: 'Mutation';
    readonly generateProviderJoinCode: {
        readonly __typename: 'GenerateProviderJoinCodePayload';
        readonly providerJoinCode: {
            readonly __typename: 'ProviderJoinCode';
            readonly id: string;
            readonly code: string;
            readonly isEnabled: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type LeaveProviderMutationVariables = Exact<{
    input: LeaveProviderInput;
}>;

export type LeaveProviderMutation = {
    readonly __typename: 'Mutation';
    readonly leaveProvider: { readonly __typename: 'LeaveProviderPayload'; readonly providerId: string };
};

export type ProviderJoinCodeQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
}>;

export type ProviderJoinCodeQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly joinCode?: {
            readonly __typename: 'ProviderJoinCode';
            readonly id: string;
            readonly code: string;
            readonly isEnabled: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
        } | null;
    } | null;
};

export type ProviderMemberInvitesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
}>;

export type ProviderMemberInvitesQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly memberInvites: ReadonlyArray<{
            readonly __typename: 'ProviderMemberInvite';
            readonly id: string;
            readonly email: string;
            readonly redeemedAt?: string | null;
            readonly expiresAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
        }>;
    } | null;
};

export type ProviderMembersQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
}>;

export type ProviderMembersQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly members: ReadonlyArray<{
            readonly __typename: 'ProviderMember';
            readonly id: string;
            readonly canDelete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
        }>;
    } | null;
};

export type ProviderModulesQueryVariables = Exact<{ [key: string]: never }>;

export type ProviderModulesQuery = {
    readonly __typename: 'Query';
    readonly providerModules: ReadonlyArray<{ readonly __typename: 'ProviderModule'; readonly id: string; readonly title: string }>;
};

export type ProviderMySchoolInformationItemQueryVariables = Exact<{
    itemId: Scalars['UUID']['input'];
}>;

export type ProviderMySchoolInformationItemQuery = {
    readonly __typename: 'Query';
    readonly providerMySchoolInformationItem?: {
        readonly __typename: 'ProviderMySchoolInformationItem';
        readonly id: string;
        readonly title: string;
        readonly information: string;
        readonly sequence: number;
        readonly updatedAt: string;
        readonly createdAt: string;
    } | null;
};

export type ProviderMySchoolInformationQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProviderMySchoolInformationQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mySchoolInformationItems: {
            readonly __typename: 'ProviderMySchoolInformationConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProviderMySchoolInformationEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'ProviderMySchoolInformationItem';
                    readonly id: string;
                    readonly title: string;
                    readonly information: string;
                    readonly sequence: number;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                } | null;
            }>;
        };
    } | null;
};

export type ProviderQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    withInternalContactDetails?: InputMaybe<Scalars['Boolean']['input']>;
    withTechnicalContactDetails?: InputMaybe<Scalars['Boolean']['input']>;
    withActiveModules?: InputMaybe<Scalars['Boolean']['input']>;
    withPermissions?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProviderQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly countryCode: string;
        readonly providerType: ProviderType;
        readonly logoImageUrl: string;
        readonly bannerImageUrl?: string | null;
        readonly canUpdateProviderDetails: boolean;
        readonly canAccessAdminFeature: boolean;
        readonly canAccessMentorFeature: boolean;
        readonly canAccessMentorRewardFeature: boolean;
        readonly canAccessOpportunityFeature: boolean;
        readonly canAccessNewsfeedFeature: boolean;
        readonly canAccessProjectTemplatesFeature: boolean;
        readonly canAccessEmploymentFeature: boolean;
        readonly canAccessBadgeTemplatesFeature: boolean;
        readonly canImportMentorRewardPoints: boolean;
        readonly canImportMentorGroupsFromLearnerSmsData: boolean;
        readonly canPinPost: boolean;
        readonly canToggleProviderLessonAccess: boolean;
        readonly isDailyNoticesAvailable: boolean;
        readonly isLessonsDisabled: boolean;
        readonly internalContactDetails?: {
            readonly __typename: 'ProviderInternalContactDetails';
            readonly fullName?: string | null;
            readonly email?: string | null;
            readonly phone?: string | null;
        } | null;
        readonly technicalContactDetails?: {
            readonly __typename: 'ProviderTechnicalContactDetails';
            readonly fullName?: string | null;
            readonly email?: string | null;
            readonly phone?: string | null;
        } | null;
        readonly activeModules: ReadonlyArray<{ readonly __typename: 'ProviderModule'; readonly id: string; readonly title: string }>;
    } | null;
};

export type ProviderRolesQueryVariables = Exact<{ [key: string]: never }>;

export type ProviderRolesQuery = {
    readonly __typename: 'Query';
    readonly providerRoles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
};

export type ProvidersSearchQueryVariables = Exact<{
    types?: InputMaybe<ReadonlyArray<ProviderType> | ProviderType>;
    query?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<ProviderSortType>;
}>;

export type ProvidersSearchQuery = {
    readonly __typename: 'Query';
    readonly providers: {
        readonly __typename: 'ProviderConnection';
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'ProviderEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'Provider';
                readonly hasFollowed: boolean;
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            } | null;
        }>;
    };
};

export type RedeemProviderJoinCodeMutationVariables = Exact<{
    input: RedeemProviderJoinCodeInput;
}>;

export type RedeemProviderJoinCodeMutation = {
    readonly __typename: 'Mutation';
    readonly redeemProviderJoinCode: {
        readonly __typename: 'RedeemProviderJoinCodePayload';
        readonly providerMember: {
            readonly __typename: 'ProviderMember';
            readonly id: string;
            readonly canDelete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type RequestProviderModuleMutationVariables = Exact<{
    input: RequestProviderModuleInput;
}>;

export type RequestProviderModuleMutation = {
    readonly __typename: 'Mutation';
    readonly requestProviderModule: {
        readonly __typename: 'RequestProviderModulePayload';
        readonly module: { readonly __typename: 'ProviderModule'; readonly id: string; readonly title: string };
    };
};

export type StartProviderImageUploadMutationVariables = Exact<{
    input: StartProviderImageUploadInput;
}>;

export type StartProviderImageUploadMutation = {
    readonly __typename: 'Mutation';
    readonly startProviderImageUpload: {
        readonly __typename: 'StartProviderImageUploadPayload';
        readonly fileId: string;
        readonly uploadUrl: string;
    };
};

export type UnfollowProviderMutationVariables = Exact<{
    input: UnfollowProviderInput;
}>;

export type UnfollowProviderMutation = {
    readonly __typename: 'Mutation';
    readonly unfollowProvider: { readonly __typename: 'UnfollowProviderPayload'; readonly providerId: string };
};

export type UpdateProviderDetailsMutationVariables = Exact<{
    input: UpdateProviderDetailsInput;
}>;

export type UpdateProviderDetailsMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderDetails: {
        readonly __typename: 'UpdateProviderDetailsPayload';
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
            readonly internalContactDetails?: {
                readonly __typename: 'ProviderInternalContactDetails';
                readonly fullName?: string | null;
                readonly email?: string | null;
                readonly phone?: string | null;
            } | null;
            readonly technicalContactDetails?: {
                readonly __typename: 'ProviderTechnicalContactDetails';
                readonly fullName?: string | null;
                readonly email?: string | null;
                readonly phone?: string | null;
            } | null;
        };
    };
};

export type UpdateProviderMemberRolesMutationVariables = Exact<{
    input: UpdateProviderMemberRolesInput;
}>;

export type UpdateProviderMemberRolesMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderMemberRoles: {
        readonly __typename: 'UpdateProviderMemberRolesPayload';
        readonly member: {
            readonly __typename: 'ProviderMember';
            readonly id: string;
            readonly canDelete: boolean;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly roles: ReadonlyArray<{ readonly __typename: 'ProviderRole'; readonly id: string; readonly title: string }>;
        };
    };
};

export type UpdateProviderMySchoolInformationMutationVariables = Exact<{
    input: UpdateProviderMySchoolInformationInput;
}>;

export type UpdateProviderMySchoolInformationMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderMySchoolInformation: {
        readonly __typename: 'UpdateProviderMySchoolInformationPayload';
        readonly providerMySchoolInformationItem: {
            readonly __typename: 'ProviderMySchoolInformationItem';
            readonly id: string;
            readonly title: string;
            readonly information: string;
            readonly sequence: number;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type UpdateProviderMySchoolInformationSequenceMutationVariables = Exact<{
    input: UpdateProviderMySchoolInformationSequenceInput;
}>;

export type UpdateProviderMySchoolInformationSequenceMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderMySchoolInformationSequence: {
        readonly __typename: 'UpdateProviderMySchoolInformationSequencePayload';
        readonly providerMySchoolInformationItems: ReadonlyArray<{
            readonly __typename: 'ProviderMySchoolInformationItem';
            readonly id: string;
            readonly title: string;
            readonly information: string;
            readonly sequence: number;
            readonly updatedAt: string;
            readonly createdAt: string;
        }>;
    };
};

export type CopyProviderProjectTemplateMutationVariables = Exact<{
    input: CopyProviderProjectTemplateInput;
}>;

export type CopyProviderProjectTemplateMutation = {
    readonly __typename: 'Mutation';
    readonly copyProviderProjectTemplate: {
        readonly __typename: 'CopyProviderProjectTemplatePayload';
        readonly providerProjectTemplate: {
            readonly __typename: 'ProviderProjectTemplate';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly bannerImageId?: string | null;
            readonly overviewDescription: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isPublic: boolean;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly overviewResources: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateOverviewResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly sections: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateSection';
                readonly id: string;
                readonly sectionOrder: number;
                readonly title: string;
                readonly color: string;
                readonly description: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resources: ReadonlyArray<{
                    readonly __typename: 'ProviderProjectTemplateSectionResource';
                    readonly id: string;
                    readonly url: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lesson?: {
                        readonly __typename: 'Lesson';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly description: string;
                        readonly coverImageUrl?: string | null;
                        readonly documentId: string;
                        readonly documentIframeUrl?: string | null;
                        readonly tags: ReadonlyArray<string>;
                        readonly isFeatured: boolean;
                        readonly countryCodes: ReadonlyArray<string>;
                        readonly favoriteCount: number;
                        readonly hasRead: boolean;
                        readonly hasFavorited: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly lessonCategory: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                            readonly canUpdate: boolean;
                            readonly canDelete: boolean;
                            readonly parent?: {
                                readonly __typename: 'LessonCategory';
                                readonly id: string;
                                readonly locale: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                }>;
            }>;
            readonly updatedBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateProviderProjectTemplateMutationVariables = Exact<{
    input: CreateProviderProjectTemplateInput;
}>;

export type CreateProviderProjectTemplateMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderProjectTemplate: {
        readonly __typename: 'CreateProviderProjectTemplatePayload';
        readonly providerProjectTemplate: {
            readonly __typename: 'ProviderProjectTemplate';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly bannerImageId?: string | null;
            readonly overviewDescription: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isPublic: boolean;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly overviewResources: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateOverviewResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly sections: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateSection';
                readonly id: string;
                readonly sectionOrder: number;
                readonly title: string;
                readonly color: string;
                readonly description: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resources: ReadonlyArray<{
                    readonly __typename: 'ProviderProjectTemplateSectionResource';
                    readonly id: string;
                    readonly url: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lesson?: {
                        readonly __typename: 'Lesson';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly description: string;
                        readonly coverImageUrl?: string | null;
                        readonly documentId: string;
                        readonly documentIframeUrl?: string | null;
                        readonly tags: ReadonlyArray<string>;
                        readonly isFeatured: boolean;
                        readonly countryCodes: ReadonlyArray<string>;
                        readonly favoriteCount: number;
                        readonly hasRead: boolean;
                        readonly hasFavorited: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly lessonCategory: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                            readonly canUpdate: boolean;
                            readonly canDelete: boolean;
                            readonly parent?: {
                                readonly __typename: 'LessonCategory';
                                readonly id: string;
                                readonly locale: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                }>;
            }>;
            readonly updatedBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateProviderProjectTemplateOverviewResourceMutationVariables = Exact<{
    input: CreateProviderProjectTemplateOverviewResourceInput;
}>;

export type CreateProviderProjectTemplateOverviewResourceMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderProjectTemplateOverviewResource: {
        readonly __typename: 'CreateProviderProjectTemplateOverviewResourcePayload';
        readonly providerProjectTemplateOverviewResource: {
            readonly __typename: 'ProviderProjectTemplateOverviewResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        };
    };
};

export type CreateProviderProjectTemplateSectionMutationVariables = Exact<{
    input: CreateProviderProjectTemplateSectionInput;
}>;

export type CreateProviderProjectTemplateSectionMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderProjectTemplateSection: {
        readonly __typename: 'CreateProviderProjectTemplateSectionPayload';
        readonly providerProjectTemplateSection: {
            readonly __typename: 'ProviderProjectTemplateSection';
            readonly id: string;
            readonly sectionOrder: number;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type CreateProviderProjectTemplateSectionResourceMutationVariables = Exact<{
    input: CreateProviderProjectTemplateSectionResourceInput;
}>;

export type CreateProviderProjectTemplateSectionResourceMutation = {
    readonly __typename: 'Mutation';
    readonly createProviderProjectTemplateSectionResource: {
        readonly __typename: 'CreateProviderProjectTemplateSectionResourcePayload';
        readonly providerProjectTemplateSectionResource?: {
            readonly __typename: 'ProviderProjectTemplateSectionResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        } | null;
    };
};

export type DeleteProviderProjectTemplateMutationVariables = Exact<{
    input: DeleteProviderProjectTemplateInput;
}>;

export type DeleteProviderProjectTemplateMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderProjectTemplate: {
        readonly __typename: 'DeleteProviderProjectTemplatePayload';
        readonly providerProjectTemplateId: string;
    };
};

export type DeleteProviderProjectTemplateOverviewResourceMutationVariables = Exact<{
    input: DeleteProviderProjectTemplateOverviewResourceInput;
}>;

export type DeleteProviderProjectTemplateOverviewResourceMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderProjectTemplateOverviewResource: {
        readonly __typename: 'DeleteProviderProjectTemplateOverviewResourcePayload';
        readonly providerProjectTemplateOverviewResourceId: string;
    };
};

export type DeleteProviderProjectTemplateSectionMutationVariables = Exact<{
    input: DeleteProviderProjectTemplateSectionInput;
}>;

export type DeleteProviderProjectTemplateSectionMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderProjectTemplateSection: {
        readonly __typename: 'DeleteProviderProjectTemplateSectionPayload';
        readonly providerProjectTemplateSectionId: string;
    };
};

export type DeleteProviderProjectTemplateSectionResourceMutationVariables = Exact<{
    input: DeleteProviderProjectTemplateSectionResourceInput;
}>;

export type DeleteProviderProjectTemplateSectionResourceMutation = {
    readonly __typename: 'Mutation';
    readonly deleteProviderProjectTemplateSectionResource: {
        readonly __typename: 'DeleteProviderProjectTemplateSectionResourcePayload';
        readonly providerProjectTemplateSectionResourceId: string;
    };
};

export type ProviderProjectTemplateQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type ProviderProjectTemplateQuery = {
    readonly __typename: 'Query';
    readonly providerProjectTemplate?: {
        readonly __typename: 'ProviderProjectTemplate';
        readonly id: string;
        readonly title: string;
        readonly bannerImageUrl?: string | null;
        readonly bannerImageId?: string | null;
        readonly overviewDescription: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly isPublic: boolean;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly overviewResources: ReadonlyArray<{
            readonly __typename: 'ProviderProjectTemplateOverviewResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
        readonly sections: ReadonlyArray<{
            readonly __typename: 'ProviderProjectTemplateSection';
            readonly id: string;
            readonly sectionOrder: number;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        }>;
        readonly updatedBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly createdBy: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    } | null;
};

export type ProviderProjectTemplateSectionQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type ProviderProjectTemplateSectionQuery = {
    readonly __typename: 'Query';
    readonly providerProjectTemplateSection?: {
        readonly __typename: 'ProviderProjectTemplateSection';
        readonly id: string;
        readonly sectionOrder: number;
        readonly title: string;
        readonly color: string;
        readonly description: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly resources: ReadonlyArray<{
            readonly __typename: 'ProviderProjectTemplateSectionResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        }>;
    } | null;
};

export type ProviderProjectTemplatesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    includePublic?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProviderProjectTemplatesQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly providerProjectTemplates: {
            readonly __typename: 'ProviderProjectTemplateConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'ProviderProjectTemplate';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly bannerImageId?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly isPublic: boolean;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly sections: ReadonlyArray<{ readonly __typename: 'ProviderProjectTemplateSection'; readonly id: string }>;
                    readonly updatedBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type UpdateProviderProjectTemplateBannerImageMutationVariables = Exact<{
    input: UpdateProviderProjectTemplateBannerImageInput;
}>;

export type UpdateProviderProjectTemplateBannerImageMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderProjectTemplateBannerImage: {
        readonly __typename: 'UpdateProviderProjectTemplateBannerImagePayload';
        readonly providerProjectTemplate: {
            readonly __typename: 'ProviderProjectTemplate';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly bannerImageId?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isPublic: boolean;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly sections: ReadonlyArray<{ readonly __typename: 'ProviderProjectTemplateSection'; readonly id: string }>;
            readonly updatedBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateProviderProjectTemplateMutationVariables = Exact<{
    input: UpdateProviderProjectTemplateInput;
}>;

export type UpdateProviderProjectTemplateMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderProjectTemplate: {
        readonly __typename: 'UpdateProviderProjectTemplatePayload';
        readonly providerProjectTemplate: {
            readonly __typename: 'ProviderProjectTemplate';
            readonly id: string;
            readonly title: string;
            readonly bannerImageUrl?: string | null;
            readonly bannerImageId?: string | null;
            readonly overviewDescription: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly isPublic: boolean;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly overviewResources: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateOverviewResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
            readonly sections: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateSection';
                readonly id: string;
                readonly sectionOrder: number;
                readonly title: string;
                readonly color: string;
                readonly description: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly resources: ReadonlyArray<{
                    readonly __typename: 'ProviderProjectTemplateSectionResource';
                    readonly id: string;
                    readonly url: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lesson?: {
                        readonly __typename: 'Lesson';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly description: string;
                        readonly coverImageUrl?: string | null;
                        readonly documentId: string;
                        readonly documentIframeUrl?: string | null;
                        readonly tags: ReadonlyArray<string>;
                        readonly isFeatured: boolean;
                        readonly countryCodes: ReadonlyArray<string>;
                        readonly favoriteCount: number;
                        readonly hasRead: boolean;
                        readonly hasFavorited: boolean;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly lessonCategory: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                            readonly canUpdate: boolean;
                            readonly canDelete: boolean;
                            readonly parent?: {
                                readonly __typename: 'LessonCategory';
                                readonly id: string;
                                readonly locale: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                }>;
            }>;
            readonly updatedBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type UpdateProviderProjectTemplateOverviewResourceMutationVariables = Exact<{
    input: UpdateProviderProjectTemplateOverviewResourceInput;
}>;

export type UpdateProviderProjectTemplateOverviewResourceMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderProjectTemplateOverviewResource: {
        readonly __typename: 'UpdateProviderProjectTemplateOverviewResourcePayload';
        readonly providerProjectTemplateOverviewResource: {
            readonly __typename: 'ProviderProjectTemplateOverviewResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        };
    };
};

export type UpdateProviderProjectTemplateSectionMutationVariables = Exact<{
    input: UpdateProviderProjectTemplateSectionInput;
}>;

export type UpdateProviderProjectTemplateSectionMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderProjectTemplateSection: {
        readonly __typename: 'UpdateProviderProjectTemplateSectionPayload';
        readonly providerProjectTemplateSection: {
            readonly __typename: 'ProviderProjectTemplateSection';
            readonly id: string;
            readonly sectionOrder: number;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type UpdateProviderProjectTemplateSectionOrderMutationVariables = Exact<{
    input: UpdateProviderProjectTemplateSectionOrderInput;
}>;

export type UpdateProviderProjectTemplateSectionOrderMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderProjectTemplateSectionOrder: {
        readonly __typename: 'UpdateProviderProjectTemplateSectionOrderPayload';
        readonly providerProjectTemplateSections: ReadonlyArray<{
            readonly __typename: 'ProviderProjectTemplateSection';
            readonly id: string;
            readonly sectionOrder: number;
            readonly title: string;
            readonly color: string;
            readonly description: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly resources: ReadonlyArray<{
                readonly __typename: 'ProviderProjectTemplateSectionResource';
                readonly id: string;
                readonly url: string;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lesson?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        }>;
    };
};

export type UpdateProviderProjectTemplateSectionResourceMutationVariables = Exact<{
    input: UpdateProviderProjectTemplateSectionResourceInput;
}>;

export type UpdateProviderProjectTemplateSectionResourceMutation = {
    readonly __typename: 'Mutation';
    readonly updateProviderProjectTemplateSectionResource: {
        readonly __typename: 'UpdateProviderProjectTemplateSectionResourcePayload';
        readonly providerProjectTemplateSectionResource?: {
            readonly __typename: 'ProviderProjectTemplateSectionResource';
            readonly id: string;
            readonly url: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly lesson?: {
                readonly __typename: 'Lesson';
                readonly id: string;
                readonly locale: string;
                readonly title: string;
                readonly description: string;
                readonly coverImageUrl?: string | null;
                readonly documentId: string;
                readonly documentIframeUrl?: string | null;
                readonly tags: ReadonlyArray<string>;
                readonly isFeatured: boolean;
                readonly countryCodes: ReadonlyArray<string>;
                readonly favoriteCount: number;
                readonly hasRead: boolean;
                readonly hasFavorited: boolean;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly lessonCategory: {
                    readonly __typename: 'LessonCategory';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly parent?: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                    } | null;
                };
            } | null;
        } | null;
    };
};

export type CreateReflectionMutationVariables = Exact<{
    input: CreateReflectionInput;
}>;

export type CreateReflectionMutation = {
    readonly __typename: 'Mutation';
    readonly createReflection: {
        readonly __typename: 'CreateReflectionPayload';
        readonly reflection: {
            readonly __typename: 'Reflection';
            readonly id: string;
            readonly reflectionType: ReflectionType;
            readonly information: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        };
    };
};

export type DeleteReflectionMutationVariables = Exact<{
    input: DeleteReflectionInput;
}>;

export type DeleteReflectionMutation = {
    readonly __typename: 'Mutation';
    readonly deleteReflection: { readonly __typename: 'DeleteReflectionPayload'; readonly reflectionId: string };
};

export type MyReflectionsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<ReflectionType> | ReflectionType>;
    query?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyReflectionsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly reflections: {
            readonly __typename: 'ReflectionConnection';
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ReflectionEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Reflection';
                    readonly id: string;
                    readonly reflectionType: ReflectionType;
                    readonly information: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                } | null;
            }>;
        };
    };
};

export type ReflectionQueryVariables = Exact<{
    reflectionId: Scalars['UUID']['input'];
}>;

export type ReflectionQuery = {
    readonly __typename: 'Query';
    readonly reflection?: {
        readonly __typename: 'Reflection';
        readonly id: string;
        readonly reflectionType: ReflectionType;
        readonly information: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
    } | null;
};

export type UpdateReflectionMutationVariables = Exact<{
    input: UpdateReflectionInput;
}>;

export type UpdateReflectionMutation = {
    readonly __typename: 'Mutation';
    readonly updateReflection: {
        readonly __typename: 'UpdateReflectionPayload';
        readonly reflection: {
            readonly __typename: 'Reflection';
            readonly id: string;
            readonly reflectionType: ReflectionType;
            readonly information: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        };
    };
};

export type CreateResourceHubItemMutationVariables = Exact<{
    input: CreateResourceHubItemInput;
}>;

export type CreateResourceHubItemMutation = {
    readonly __typename: 'Mutation';
    readonly createResourceHubItem: {
        readonly __typename: 'CreateResourceHubItemPayload';
        readonly fileId: string;
        readonly uploadUrl: string;
        readonly resourceHubItem: {
            readonly __typename: 'ResourceHubItem';
            readonly id: string;
            readonly title: string;
            readonly category: ResourceHubItemCategory;
            readonly type: ResourceHubItemType;
            readonly uses: number;
            readonly fileId: string;
            readonly fileAvailable: boolean;
            readonly fileName: string;
            readonly fileMimeType: string;
            readonly fileUrl?: string | null;
            readonly filePreviewUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type DeleteResourceHubItemMutationVariables = Exact<{
    input: DeleteResourceHubItemInput;
}>;

export type DeleteResourceHubItemMutation = {
    readonly __typename: 'Mutation';
    readonly deleteResourceHubItem: { readonly __typename: 'DeleteResourceHubItemPayload'; readonly resourceHubItemId: string };
};

export type MyResourceHubItemsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    mimeTypes?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type MyResourceHubItemsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly resourceHubItems: {
            readonly __typename: 'ResourceHubItemConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ResourceHubItemEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'ResourceHubItem';
                    readonly id: string;
                    readonly title: string;
                    readonly category: ResourceHubItemCategory;
                    readonly type: ResourceHubItemType;
                    readonly uses: number;
                    readonly fileId: string;
                    readonly fileAvailable: boolean;
                    readonly fileName: string;
                    readonly fileMimeType: string;
                    readonly fileUrl?: string | null;
                    readonly filePreviewUrl: string;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type ResourceHubItemQueryVariables = Exact<{
    resourceHubItemId: Scalars['UUID']['input'];
}>;

export type ResourceHubItemQuery = {
    readonly __typename: 'Query';
    readonly resourceHubItem?: {
        readonly __typename: 'ResourceHubItem';
        readonly id: string;
        readonly title: string;
        readonly category: ResourceHubItemCategory;
        readonly type: ResourceHubItemType;
        readonly uses: number;
        readonly fileId: string;
        readonly fileAvailable: boolean;
        readonly fileName: string;
        readonly fileMimeType: string;
        readonly fileUrl?: string | null;
        readonly filePreviewUrl: string;
        readonly updatedAt: string;
        readonly createdAt: string;
        readonly canUpdate: boolean;
        readonly canDelete: boolean;
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
    } | null;
};

export type UpdateResourceHubItemMutationVariables = Exact<{
    input: UpdateResourceHubItemInput;
}>;

export type UpdateResourceHubItemMutation = {
    readonly __typename: 'Mutation';
    readonly updateResourceHubItem: {
        readonly __typename: 'UpdateResourceHubItemPayload';
        readonly resourceHubItem: {
            readonly __typename: 'ResourceHubItem';
            readonly id: string;
            readonly title: string;
            readonly category: ResourceHubItemCategory;
            readonly type: ResourceHubItemType;
            readonly uses: number;
            readonly fileId: string;
            readonly fileAvailable: boolean;
            readonly fileName: string;
            readonly fileMimeType: string;
            readonly fileUrl?: string | null;
            readonly filePreviewUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly user: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type ConsumeMentorRewardMutationVariables = Exact<{
    input: ConsumeMentorRewardInput;
}>;

export type ConsumeMentorRewardMutation = {
    readonly __typename: 'Mutation';
    readonly consumeMentorReward: {
        readonly __typename: 'ConsumeMentorRewardPayload';
        readonly mentorRewardTransaction: {
            readonly __typename: 'MentorRewardTransaction';
            readonly id: string;
            readonly type: MentorRewardTransactionType;
            readonly comment?: string | null;
            readonly points: number;
            readonly mentorGroupTitle?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly mentorRewardStudent: { readonly __typename: 'MentorRewardStudent'; readonly id: string };
            readonly mentorRewardValue?: {
                readonly __typename: 'MentorRewardValue';
                readonly id: string;
                readonly title: string;
                readonly color: string;
                readonly worth: number;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly mentorRewardEvent?: {
                readonly __typename: 'MentorRewardEvent';
                readonly id: string;
                readonly title: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        };
    };
};

export type CreateMentorRewardItemMutationVariables = Exact<{
    input: CreateMentorRewardItemInput;
}>;

export type CreateMentorRewardItemMutation = {
    readonly __typename: 'Mutation';
    readonly createMentorRewardItem: {
        readonly __typename: 'CreateMentorRewardItemPayload';
        readonly mentorRewardItem: {
            readonly __typename: 'MentorRewardItem';
            readonly id: string;
            readonly title: string;
            readonly points: number;
            readonly description: string;
            readonly imageId: string;
            readonly imageUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type CreateMentorRewardValueMutationVariables = Exact<{
    input: CreateMentorRewardValueInput;
}>;

export type CreateMentorRewardValueMutation = {
    readonly __typename: 'Mutation';
    readonly createMentorRewardValue: {
        readonly __typename: 'CreateMentorRewardValuePayload';
        readonly mentorRewardValue: {
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type DeleteMentorRewardItemMutationVariables = Exact<{
    input: DeleteMentorRewardItemInput;
}>;

export type DeleteMentorRewardItemMutation = {
    readonly __typename: 'Mutation';
    readonly deleteMentorRewardItem: {
        readonly __typename: 'DeleteMentorRewardItemPayload';
        readonly mentorRewardItem: {
            readonly __typename: 'MentorRewardItem';
            readonly id: string;
            readonly title: string;
            readonly points: number;
            readonly description: string;
            readonly imageId: string;
            readonly imageUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type DeleteMentorRewardValueMutationVariables = Exact<{
    input: DeleteMentorRewardValueInput;
}>;

export type DeleteMentorRewardValueMutation = {
    readonly __typename: 'Mutation';
    readonly deleteMentorRewardValue: { readonly __typename: 'DeleteMentorRewardValuePayload'; readonly mentorRewardValueId: string };
};

export type QueueDeleteProviderRewardsMutationVariables = Exact<{
    input: QueueDeleteProviderRewardInput;
}>;

export type QueueDeleteProviderRewardsMutation = {
    readonly __typename: 'Mutation';
    readonly queueDeleteProviderRewards: { readonly __typename: 'QueueDeleteProviderRewardPayload'; readonly providerId: string };
};

export type GiveMentorRewardsMutationVariables = Exact<{
    input: GiveMentorRewardsInput;
}>;

export type GiveMentorRewardsMutation = {
    readonly __typename: 'Mutation';
    readonly giveMentorRewards: {
        readonly __typename: 'GiveMentorRewardsPayload';
        readonly mentorRewardTransactions: ReadonlyArray<{
            readonly __typename: 'MentorRewardTransaction';
            readonly id: string;
            readonly type: MentorRewardTransactionType;
            readonly comment?: string | null;
            readonly points: number;
            readonly mentorGroupTitle?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly mentorRewardStudent: { readonly __typename: 'MentorRewardStudent'; readonly id: string };
            readonly mentorRewardValue?: {
                readonly __typename: 'MentorRewardValue';
                readonly id: string;
                readonly title: string;
                readonly color: string;
                readonly worth: number;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly mentorRewardEvent?: {
                readonly __typename: 'MentorRewardEvent';
                readonly id: string;
                readonly title: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    };
};

export type ImportMentorRewardEventPointsMutationVariables = Exact<{
    input: ImportMentorRewardEventPointsInput;
}>;

export type ImportMentorRewardEventPointsMutation = {
    readonly __typename: 'Mutation';
    readonly importMentorRewardEventPoints: {
        readonly __typename: 'ImportMentorRewardEventPointsPayload';
        readonly mentorRewardEventTransactions: ReadonlyArray<{
            readonly __typename: 'MentorRewardTransaction';
            readonly id: string;
            readonly type: MentorRewardTransactionType;
            readonly comment?: string | null;
            readonly points: number;
            readonly mentorGroupTitle?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly mentorRewardStudent: { readonly __typename: 'MentorRewardStudent'; readonly id: string };
            readonly mentorRewardValue?: {
                readonly __typename: 'MentorRewardValue';
                readonly id: string;
                readonly title: string;
                readonly color: string;
                readonly worth: number;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly mentorRewardEvent?: {
                readonly __typename: 'MentorRewardEvent';
                readonly id: string;
                readonly title: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    };
};

export type ImportMentorRewardPointsMutationVariables = Exact<{
    input: ImportMentorRewardPointsInput;
}>;

export type ImportMentorRewardPointsMutation = {
    readonly __typename: 'Mutation';
    readonly importMentorRewardPoints: {
        readonly __typename: 'ImportMentorRewardPointsPayload';
        readonly mentorRewardTransactions: ReadonlyArray<{
            readonly __typename: 'MentorRewardTransaction';
            readonly id: string;
            readonly type: MentorRewardTransactionType;
            readonly comment?: string | null;
            readonly points: number;
            readonly mentorGroupTitle?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly mentorRewardStudent: { readonly __typename: 'MentorRewardStudent'; readonly id: string };
            readonly mentorRewardValue?: {
                readonly __typename: 'MentorRewardValue';
                readonly id: string;
                readonly title: string;
                readonly color: string;
                readonly worth: number;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly mentorRewardEvent?: {
                readonly __typename: 'MentorRewardEvent';
                readonly id: string;
                readonly title: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            } | null;
            readonly createdBy: {
                readonly __typename: 'User';
                readonly id: string;
                readonly pictureUrl?: string | null;
                readonly fullName: string;
                readonly givenName: string;
                readonly familyName?: string | null;
                readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
            };
        }>;
    };
};

export type MentorGroupRewardStudentsQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type MentorGroupRewardStudentsQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly rewardStudents: {
            readonly __typename: 'MentorRewardStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorRewardStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorRewardStudent';
                    readonly id: string;
                    readonly availablePoints: number;
                    readonly totalPoints: number;
                    readonly canGiveReward: boolean;
                    readonly canConsumeReward: boolean;
                    readonly pointsSummaryRange: {
                        readonly __typename: 'MentorRewardStudentPointsSummaryRange';
                        readonly totalPoints: number;
                        readonly totalPointsByValue: ReadonlyArray<{
                            readonly __typename: 'MentorRewardStudentValuePoints';
                            readonly totalPoints: number;
                            readonly mentorRewardValue: {
                                readonly __typename: 'MentorRewardValue';
                                readonly id: string;
                                readonly title: string;
                                readonly color: string;
                                readonly worth: number;
                                readonly updatedAt: string;
                                readonly createdAt: string;
                                readonly provider: {
                                    readonly __typename: 'Provider';
                                    readonly id: string;
                                    readonly title: string;
                                    readonly countryCode: string;
                                    readonly providerType: ProviderType;
                                    readonly logoImageUrl: string;
                                    readonly bannerImageUrl?: string | null;
                                };
                            };
                        }>;
                        readonly totalPointsByEvent: ReadonlyArray<{
                            readonly __typename: 'MentorRewardStudentEventPoints';
                            readonly totalPoints: number;
                            readonly mentorRewardEvent: {
                                readonly __typename: 'MentorRewardEvent';
                                readonly id: string;
                                readonly title: string;
                                readonly description?: string | null;
                                readonly updatedAt: string;
                                readonly createdAt: string;
                                readonly provider: {
                                    readonly __typename: 'Provider';
                                    readonly id: string;
                                    readonly title: string;
                                    readonly countryCode: string;
                                    readonly providerType: ProviderType;
                                    readonly logoImageUrl: string;
                                    readonly bannerImageUrl?: string | null;
                                };
                            };
                        }>;
                    };
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                    readonly totalPointsByValue: ReadonlyArray<{
                        readonly __typename: 'MentorRewardStudentValuePoints';
                        readonly totalPoints: number;
                        readonly mentorRewardValue: {
                            readonly __typename: 'MentorRewardValue';
                            readonly id: string;
                            readonly title: string;
                            readonly color: string;
                            readonly worth: number;
                            readonly updatedAt: string;
                            readonly createdAt: string;
                            readonly provider: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                                readonly countryCode: string;
                                readonly providerType: ProviderType;
                                readonly logoImageUrl: string;
                                readonly bannerImageUrl?: string | null;
                            };
                        };
                    }>;
                    readonly totalPointsByEvent: ReadonlyArray<{
                        readonly __typename: 'MentorRewardStudentEventPoints';
                        readonly totalPoints: number;
                        readonly mentorRewardEvent: {
                            readonly __typename: 'MentorRewardEvent';
                            readonly id: string;
                            readonly title: string;
                            readonly description?: string | null;
                            readonly updatedAt: string;
                            readonly createdAt: string;
                            readonly provider: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                                readonly countryCode: string;
                                readonly providerType: ProviderType;
                                readonly logoImageUrl: string;
                                readonly bannerImageUrl?: string | null;
                            };
                        };
                    }>;
                } | null;
            }>;
        };
    } | null;
};

export type MentorGroupRewardStudentsSimpleQueryVariables = Exact<{
    mentorGroupId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorGroupRewardStudentsSimpleQuery = {
    readonly __typename: 'Query';
    readonly mentorGroup?: {
        readonly __typename: 'MentorGroup';
        readonly id: string;
        readonly title: string;
        readonly rewardStudents: {
            readonly __typename: 'MentorRewardStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorRewardStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorRewardStudent';
                    readonly id: string;
                    readonly availablePoints: number;
                    readonly totalPoints: number;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                } | null;
            }>;
        };
    } | null;
};

export type MentorRewardEventsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorRewardEventsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardEvents: {
            readonly __typename: 'MentorRewardEventConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorRewardEventEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorRewardEvent';
                    readonly id: string;
                    readonly title: string;
                    readonly description?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorRewardItemsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
}>;

export type MentorRewardItemsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardItems: ReadonlyArray<{
            readonly __typename: 'MentorRewardItem';
            readonly id: string;
            readonly title: string;
            readonly points: number;
            readonly description: string;
            readonly imageId: string;
            readonly imageUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        }>;
    } | null;
};

export type MentorRewardStudentQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
}>;

export type MentorRewardStudentQuery = {
    readonly __typename: 'Query';
    readonly mentorRewardStudent?: {
        readonly __typename: 'MentorRewardStudent';
        readonly id: string;
        readonly availablePoints: number;
        readonly totalPoints: number;
        readonly canGiveReward: boolean;
        readonly canConsumeReward: boolean;
        readonly provider: {
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
        };
        readonly user: {
            readonly __typename: 'User';
            readonly id: string;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
        };
        readonly learnerSmsProfile?: {
            readonly __typename: 'LearnerSmsProfile';
            readonly id: string;
            readonly pictureUrl: string;
            readonly preferredGivenName: string;
            readonly preferredFamilyName?: string | null;
            readonly preferredFullName: string;
            readonly email: string;
            readonly idNumber: string;
            readonly nationalCurriculumYear: string;
        } | null;
        readonly totalPointsByValue: ReadonlyArray<{
            readonly __typename: 'MentorRewardStudentValuePoints';
            readonly totalPoints: number;
            readonly mentorRewardValue: {
                readonly __typename: 'MentorRewardValue';
                readonly id: string;
                readonly title: string;
                readonly color: string;
                readonly worth: number;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            };
        }>;
        readonly totalPointsByEvent: ReadonlyArray<{
            readonly __typename: 'MentorRewardStudentEventPoints';
            readonly totalPoints: number;
            readonly mentorRewardEvent: {
                readonly __typename: 'MentorRewardEvent';
                readonly id: string;
                readonly title: string;
                readonly description?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            };
        }>;
    } | null;
};

export type MentorRewardStudentTransactionsQueryVariables = Exact<{
    id: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MentorRewardStudentTransactionsQuery = {
    readonly __typename: 'Query';
    readonly mentorRewardStudent?: {
        readonly __typename: 'MentorRewardStudent';
        readonly id: string;
        readonly transactions: {
            readonly __typename: 'MentorRewardTransactionConnection';
            readonly notViewedCount: number;
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorRewardTransactionEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorRewardTransaction';
                    readonly id: string;
                    readonly type: MentorRewardTransactionType;
                    readonly comment?: string | null;
                    readonly points: number;
                    readonly mentorGroupTitle?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly mentorRewardStudent: { readonly __typename: 'MentorRewardStudent'; readonly id: string };
                    readonly mentorRewardValue?: {
                        readonly __typename: 'MentorRewardValue';
                        readonly id: string;
                        readonly title: string;
                        readonly color: string;
                        readonly worth: number;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly provider: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                            readonly countryCode: string;
                            readonly providerType: ProviderType;
                            readonly logoImageUrl: string;
                            readonly bannerImageUrl?: string | null;
                        };
                    } | null;
                    readonly mentorRewardEvent?: {
                        readonly __typename: 'MentorRewardEvent';
                        readonly id: string;
                        readonly title: string;
                        readonly description?: string | null;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly provider: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                            readonly countryCode: string;
                            readonly providerType: ProviderType;
                            readonly logoImageUrl: string;
                            readonly bannerImageUrl?: string | null;
                        };
                    } | null;
                    readonly createdBy: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    } | null;
};

export type MentorRewardValuesQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
}>;

export type MentorRewardValuesQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardValues: ReadonlyArray<{
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        }>;
    } | null;
};

export type ProviderMentorRewardAnalyticsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    year: Scalars['Int']['input'];
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
    mentorRewardEventIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
}>;

export type ProviderMentorRewardAnalyticsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardAnalytics: {
            readonly __typename: 'MentorRewardAnalytics';
            readonly pointsYearSummaryByType: ReadonlyArray<{
                readonly __typename: 'PointsYearSummaryByType';
                readonly month: number;
                readonly givenPoints: number;
                readonly consumedPoints: number;
            }>;
        };
    } | null;
};

export type ProviderMentorRewardPointStatsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
    mentorRewardEventIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
}>;

export type ProviderMentorRewardPointStatsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardAnalytics: {
            readonly __typename: 'MentorRewardAnalytics';
            readonly pointsSummaryByType: {
                readonly __typename: 'PointsSummaryByType';
                readonly givenPoints: number;
                readonly consumedPoints: number;
            };
        };
    } | null;
};

export type ProviderMentorRewardStudentsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    query?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProviderMentorRewardStudentsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly title: string;
        readonly logoImageUrl: string;
        readonly mentorRewardStudents: {
            readonly __typename: 'MentorRewardStudentConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'MentorRewardStudentEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'MentorRewardStudent';
                    readonly id: string;
                    readonly availablePoints: number;
                    readonly totalPoints: number;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                } | null;
            }>;
        };
    } | null;
};

export type ProviderMentorRewardTopLearnersQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
    mentorRewardEventIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
}>;

export type ProviderMentorRewardTopLearnersQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardAnalytics: {
            readonly __typename: 'MentorRewardAnalytics';
            readonly topLearners: ReadonlyArray<{
                readonly __typename: 'MentorRewardTopLearner';
                readonly points: number;
                readonly pointsByOther: number;
                readonly rank: number;
                readonly rewardStudent: {
                    readonly __typename: 'MentorRewardStudent';
                    readonly id: string;
                    readonly availablePoints: number;
                    readonly totalPoints: number;
                    readonly user: {
                        readonly __typename: 'User';
                        readonly id: string;
                        readonly pictureUrl?: string | null;
                        readonly fullName: string;
                        readonly givenName: string;
                        readonly familyName?: string | null;
                        readonly educationProvider?: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    };
                    readonly learnerSmsProfile?: {
                        readonly __typename: 'LearnerSmsProfile';
                        readonly id: string;
                        readonly pictureUrl: string;
                        readonly preferredGivenName: string;
                        readonly preferredFamilyName?: string | null;
                        readonly preferredFullName: string;
                        readonly email: string;
                        readonly idNumber: string;
                        readonly nationalCurriculumYear: string;
                    } | null;
                };
                readonly pointsByValue: ReadonlyArray<{
                    readonly __typename: 'MentorRewardStudentValuePoints';
                    readonly totalPoints: number;
                    readonly mentorRewardValue: {
                        readonly __typename: 'MentorRewardValue';
                        readonly id: string;
                        readonly title: string;
                        readonly color: string;
                        readonly worth: number;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly provider: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                            readonly countryCode: string;
                            readonly providerType: ProviderType;
                            readonly logoImageUrl: string;
                            readonly bannerImageUrl?: string | null;
                        };
                    };
                }>;
            }>;
        };
        readonly mentorRewardValues: ReadonlyArray<{
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        }>;
    } | null;
};

export type ProviderMentorRewardTopMentorGroupsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
}>;

export type ProviderMentorRewardTopMentorGroupsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardAnalytics: {
            readonly __typename: 'MentorRewardAnalytics';
            readonly topMentorGroups: ReadonlyArray<{
                readonly __typename: 'MentorRewardTopGroup';
                readonly points: number;
                readonly pointsByOther: number;
                readonly rank: number;
                readonly group: {
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                };
                readonly pointsByValue: ReadonlyArray<{
                    readonly __typename: 'MentorRewardStudentValuePoints';
                    readonly totalPoints: number;
                    readonly mentorRewardValue: {
                        readonly __typename: 'MentorRewardValue';
                        readonly id: string;
                        readonly title: string;
                        readonly color: string;
                        readonly worth: number;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly provider: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                            readonly countryCode: string;
                            readonly providerType: ProviderType;
                            readonly logoImageUrl: string;
                            readonly bannerImageUrl?: string | null;
                        };
                    };
                }>;
            }>;
        };
        readonly mentorRewardValues: ReadonlyArray<{
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        }>;
    } | null;
};

export type ProviderMentorRewardTopMentorsQueryVariables = Exact<{
    providerId: Scalars['UUID']['input'];
    start: Scalars['DateTime']['input'];
    end: Scalars['DateTime']['input'];
    mentorGroupIds?: InputMaybe<ReadonlyArray<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
}>;

export type ProviderMentorRewardTopMentorsQuery = {
    readonly __typename: 'Query';
    readonly provider?: {
        readonly __typename: 'Provider';
        readonly id: string;
        readonly mentorRewardAnalytics: {
            readonly __typename: 'MentorRewardAnalytics';
            readonly topMentors: ReadonlyArray<{
                readonly __typename: 'MentorRewardTopMentor';
                readonly points: number;
                readonly rank: number;
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
            }>;
        };
    } | null;
};

export type UpdateMentorRewardItemMutationVariables = Exact<{
    input: UpdateMentorRewardItemInput;
}>;

export type UpdateMentorRewardItemMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorRewardItem: {
        readonly __typename: 'UpdateMentorRewardItemPayload';
        readonly mentorRewardItem: {
            readonly __typename: 'MentorRewardItem';
            readonly id: string;
            readonly title: string;
            readonly points: number;
            readonly description: string;
            readonly imageId: string;
            readonly imageUrl: string;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type UpdateMentorRewardValueMutationVariables = Exact<{
    input: UpdateMentorRewardValueInput;
}>;

export type UpdateMentorRewardValueMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorRewardValue: {
        readonly __typename: 'UpdateMentorRewardValuePayload';
        readonly mentorRewardValue: {
            readonly __typename: 'MentorRewardValue';
            readonly id: string;
            readonly title: string;
            readonly color: string;
            readonly worth: number;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
        };
    };
};

export type UpdateMentorRewardViewedMutationVariables = Exact<{
    input: UpdateMentorRewardViewedInput;
}>;

export type UpdateMentorRewardViewedMutation = {
    readonly __typename: 'Mutation';
    readonly updateMentorRewardViewed: { readonly __typename: 'UpdateMentorRewardViewedPayload'; readonly viewedCount: number };
};

export type UrlMetadataQueryVariables = Exact<{
    url: Scalars['URL']['input'];
}>;

export type UrlMetadataQuery = { readonly __typename: 'Query'; readonly urlMetadata: any };

export type ActiveSponsorCampaignBannerQueryVariables = Exact<{
    type: SponsorAssetType;
}>;

export type ActiveSponsorCampaignBannerQuery = {
    readonly __typename: 'Query';
    readonly sponsorCampaignBanner?: {
        readonly __typename: 'SponsorCampaignBanner';
        readonly id: string;
        readonly sponsorCampaignId: string;
        readonly sponsorProviderTitle: string;
        readonly sponsorProviderLogoImageUrl: string;
        readonly title?: string | null;
        readonly content?: string | null;
        readonly targetUrl: string;
        readonly mediaImageUrl?: string | null;
        readonly mediaVideoUrl?: string | null;
    } | null;
};

export type CreateSponsorAssetMutationVariables = Exact<{
    input: CreateSponsorAssetInput;
}>;

export type CreateSponsorAssetMutation = {
    readonly __typename: 'Mutation';
    readonly createSponsorAsset: {
        readonly __typename: 'CreateSponsorAssetPayload';
        readonly fileId: string;
        readonly uploadUrl: string;
        readonly sponsorAsset: {
            readonly __typename: 'SponsorAsset';
            readonly id: string;
            readonly label?: string | null;
            readonly type: SponsorAssetType;
            readonly title?: string | null;
            readonly content?: string | null;
            readonly targetUrl: string;
            readonly mediaFileId: string;
            readonly mediaFileAvailable: boolean;
            readonly mediaFileUrl?: string | null;
            readonly mediaType: SponsorAssetMediaType;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly sponsorProvider: {
                readonly __typename: 'SponsorProvider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
            };
        };
    };
};

export type CreateSponsorCampaignAssetMutationVariables = Exact<{
    input: CreateSponsorCampaignAssetInput;
}>;

export type CreateSponsorCampaignAssetMutation = {
    readonly __typename: 'Mutation';
    readonly createSponsorCampaignAsset: {
        readonly __typename: 'CreateSponsorCampaignAssetPayload';
        readonly sponsorCampaignAsset: {
            readonly __typename: 'SponsorCampaignAsset';
            readonly id: string;
            readonly targetUser: SponsorTargetUserRole;
            readonly newsfeedPostIndex?: number | null;
            readonly startDate: string;
            readonly endDate: string;
            readonly totalViewsCount: number;
            readonly totalClicksCount: number;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly canDelete: boolean;
            readonly canUpdate: boolean;
            readonly sponsorAsset: {
                readonly __typename: 'SponsorAsset';
                readonly id: string;
                readonly label?: string | null;
                readonly type: SponsorAssetType;
                readonly title?: string | null;
                readonly content?: string | null;
                readonly targetUrl: string;
                readonly mediaFileId: string;
                readonly mediaFileAvailable: boolean;
                readonly mediaFileUrl?: string | null;
                readonly mediaType: SponsorAssetMediaType;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly sponsorProvider: {
                    readonly __typename: 'SponsorProvider';
                    readonly id: string;
                    readonly title: string;
                    readonly logoImageUrl: string;
                };
            };
        };
    };
};

export type CreateSponsorCampaignMutationVariables = Exact<{
    input: CreateSponsorCampaignInput;
}>;

export type CreateSponsorCampaignMutation = {
    readonly __typename: 'Mutation';
    readonly createSponsorCampaign: {
        readonly __typename: 'CreateSponsorCampaignPayload';
        readonly sponsorCampaign: {
            readonly __typename: 'SponsorCampaign';
            readonly id: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly timezone: string;
            readonly status: SponsorCampaignStatus;
            readonly targetCountries: ReadonlyArray<string>;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly sponsorProvider: {
                readonly __typename: 'SponsorProvider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
            };
        };
    };
};

export type CreateSponsorProviderMutationVariables = Exact<{
    input: CreateSponsorProviderInput;
}>;

export type CreateSponsorProviderMutation = {
    readonly __typename: 'Mutation';
    readonly createSponsorProvider: {
        readonly __typename: 'CreateSponsorProviderPayload';
        readonly sponsorProvider: {
            readonly __typename: 'SponsorProvider';
            readonly id: string;
            readonly title: string;
            readonly logoImageUrl: string;
        };
    };
};

export type DeleteSponsorAssetMutationVariables = Exact<{
    input: DeleteSponsorAssetInput;
}>;

export type DeleteSponsorAssetMutation = {
    readonly __typename: 'Mutation';
    readonly deleteSponsorAsset: { readonly __typename: 'DeleteSponsorAssetPayload'; readonly sponsorAssetId: string };
};

export type DeleteSponsorCampaignAssetMutationVariables = Exact<{
    input: DeleteSponsorCampaignAssetInput;
}>;

export type DeleteSponsorCampaignAssetMutation = {
    readonly __typename: 'Mutation';
    readonly deleteSponsorCampaignAsset: {
        readonly __typename: 'DeleteSponsorCampaignAssetPayload';
        readonly sponsorCampaignAssetId: string;
    };
};

export type DeleteSponsorCampaignMutationVariables = Exact<{
    input: DeleteSponsorCampaignInput;
}>;

export type DeleteSponsorCampaignMutation = {
    readonly __typename: 'Mutation';
    readonly deleteSponsorCampaign: { readonly __typename: 'DeleteSponsorCampaignPayload'; readonly sponsorCampaignId: string };
};

export type DeleteSponsorProviderMutationVariables = Exact<{
    input: DeleteSponsorProviderInput;
}>;

export type DeleteSponsorProviderMutation = {
    readonly __typename: 'Mutation';
    readonly deleteSponsorProvider: { readonly __typename: 'DeleteSponsorProviderPayload'; readonly sponsorProviderId: string };
};

export type PublishSponsorCampaignMutationVariables = Exact<{
    input: PublishSponsorCampaignInput;
}>;

export type PublishSponsorCampaignMutation = {
    readonly __typename: 'Mutation';
    readonly publishSponsorCampaign: {
        readonly __typename: 'PublishSponsorCampaignPayload';
        readonly sponsorCampaign: {
            readonly __typename: 'SponsorCampaign';
            readonly id: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly timezone: string;
            readonly status: SponsorCampaignStatus;
            readonly targetCountries: ReadonlyArray<string>;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly canPublish: boolean;
            readonly canUnpublish: boolean;
            readonly canDelete: boolean;
            readonly canUpdate: boolean;
            readonly sponsorProvider: {
                readonly __typename: 'SponsorProvider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
            };
            readonly sponsorCampaignAssets: { readonly __typename: 'SponsorCampaignAssetConnection'; readonly totalCount: number };
        };
    };
};

export type SponsorAssetsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    sponsorProviderId?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type SponsorAssetsQuery = {
    readonly __typename: 'Query';
    readonly sponsorAssets: {
        readonly __typename: 'SponsorAssetConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'SponsorAssetEdge';
            readonly node?: {
                readonly __typename: 'SponsorAsset';
                readonly id: string;
                readonly label?: string | null;
                readonly type: SponsorAssetType;
                readonly title?: string | null;
                readonly content?: string | null;
                readonly targetUrl: string;
                readonly mediaFileId: string;
                readonly mediaFileAvailable: boolean;
                readonly mediaFileUrl?: string | null;
                readonly mediaType: SponsorAssetMediaType;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly sponsorProvider: {
                    readonly __typename: 'SponsorProvider';
                    readonly id: string;
                    readonly title: string;
                    readonly logoImageUrl: string;
                };
            } | null;
        }>;
    };
};

export type SponsorCampaignAnalyticQueryVariables = Exact<{
    sponsorCampaignId: Scalars['UUID']['input'];
}>;

export type SponsorCampaignAnalyticQuery = {
    readonly __typename: 'Query';
    readonly sponsorCampaign?: {
        readonly __typename: 'SponsorCampaign';
        readonly totalClicksCount: number;
        readonly totalViewsCount: number;
        readonly sponsorCampaignAssets: { readonly __typename: 'SponsorCampaignAssetConnection'; readonly totalCount: number };
    } | null;
};

export type SponsorCampaignQueryVariables = Exact<{
    sponsorCampaignId: Scalars['UUID']['input'];
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SponsorCampaignQuery = {
    readonly __typename: 'Query';
    readonly sponsorCampaign?: {
        readonly __typename: 'SponsorCampaign';
        readonly id: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly timezone: string;
        readonly status: SponsorCampaignStatus;
        readonly targetCountries: ReadonlyArray<string>;
        readonly createdAt: string;
        readonly updatedAt: string;
        readonly canPublish: boolean;
        readonly canUnpublish: boolean;
        readonly canDelete: boolean;
        readonly canUpdate: boolean;
        readonly sponsorCampaignAssets: {
            readonly __typename: 'SponsorCampaignAssetConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'SponsorCampaignAssetEdge';
                readonly node?: {
                    readonly __typename: 'SponsorCampaignAsset';
                    readonly id: string;
                    readonly targetUser: SponsorTargetUserRole;
                    readonly newsfeedPostIndex?: number | null;
                    readonly startDate: string;
                    readonly endDate: string;
                    readonly totalViewsCount: number;
                    readonly totalClicksCount: number;
                    readonly createdAt: string;
                    readonly updatedAt: string;
                    readonly canDelete: boolean;
                    readonly canUpdate: boolean;
                    readonly sponsorAsset: {
                        readonly __typename: 'SponsorAsset';
                        readonly id: string;
                        readonly label?: string | null;
                        readonly type: SponsorAssetType;
                        readonly title?: string | null;
                        readonly content?: string | null;
                        readonly targetUrl: string;
                        readonly mediaFileId: string;
                        readonly mediaFileAvailable: boolean;
                        readonly mediaFileUrl?: string | null;
                        readonly mediaType: SponsorAssetMediaType;
                        readonly createdAt: string;
                        readonly updatedAt: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly sponsorProvider: {
                            readonly __typename: 'SponsorProvider';
                            readonly id: string;
                            readonly title: string;
                            readonly logoImageUrl: string;
                        };
                    };
                } | null;
            }>;
        };
        readonly sponsorProvider: {
            readonly __typename: 'SponsorProvider';
            readonly id: string;
            readonly title: string;
            readonly logoImageUrl: string;
        };
    } | null;
};

export type SponsorCampaignsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    sponsorProviderId?: InputMaybe<Scalars['UUID']['input']>;
    sponsorCampaignStatus?: InputMaybe<SponsorCampaignStatus>;
}>;

export type SponsorCampaignsQuery = {
    readonly __typename: 'Query';
    readonly sponsorCampaigns: {
        readonly __typename: 'SponsorCampaignConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'SponsorCampaignEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'SponsorCampaign';
                readonly id: string;
                readonly startDate: string;
                readonly endDate: string;
                readonly status: SponsorCampaignStatus;
                readonly targetCountries: ReadonlyArray<string>;
                readonly sponsorProvider: {
                    readonly __typename: 'SponsorProvider';
                    readonly id: string;
                    readonly title: string;
                    readonly logoImageUrl: string;
                };
                readonly sponsorCampaignAssets: { readonly __typename: 'SponsorCampaignAssetConnection'; readonly totalCount: number };
            } | null;
        }>;
    };
};

export type SponsorProvidersQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SponsorProvidersQuery = {
    readonly __typename: 'Query';
    readonly sponsorProviders: {
        readonly __typename: 'SponsorProviderConnection';
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'SponsorProviderEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'SponsorProvider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
            } | null;
        }>;
    };
};

export type UnpublishSponsorCampaignMutationVariables = Exact<{
    input: UnpublishSponsorCampaignInput;
}>;

export type UnpublishSponsorCampaignMutation = {
    readonly __typename: 'Mutation';
    readonly unpublishSponsorCampaign: {
        readonly __typename: 'UnpublishSponsorCampaignPayload';
        readonly sponsorCampaign: {
            readonly __typename: 'SponsorCampaign';
            readonly id: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly timezone: string;
            readonly status: SponsorCampaignStatus;
            readonly targetCountries: ReadonlyArray<string>;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly canPublish: boolean;
            readonly canUnpublish: boolean;
            readonly canDelete: boolean;
            readonly canUpdate: boolean;
            readonly sponsorProvider: {
                readonly __typename: 'SponsorProvider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
            };
            readonly sponsorCampaignAssets: { readonly __typename: 'SponsorCampaignAssetConnection'; readonly totalCount: number };
        };
    };
};

export type UpdateSponsorAssetMutationVariables = Exact<{
    input: UpdateSponsorAssetInput;
}>;

export type UpdateSponsorAssetMutation = {
    readonly __typename: 'Mutation';
    readonly updateSponsorAsset: {
        readonly __typename: 'UpdateSponsorAssetPayload';
        readonly sponsorAsset: {
            readonly __typename: 'SponsorAsset';
            readonly id: string;
            readonly label?: string | null;
            readonly type: SponsorAssetType;
            readonly title?: string | null;
            readonly content?: string | null;
            readonly targetUrl: string;
            readonly mediaFileId: string;
            readonly mediaFileAvailable: boolean;
            readonly mediaFileUrl?: string | null;
            readonly mediaType: SponsorAssetMediaType;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
            readonly sponsorProvider: {
                readonly __typename: 'SponsorProvider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
            };
        };
    };
};

export type UpdateSponsorCampaignAssetMutationVariables = Exact<{
    input: UpdateSponsorCampaignAssetInput;
}>;

export type UpdateSponsorCampaignAssetMutation = {
    readonly __typename: 'Mutation';
    readonly updateSponsorCampaignAsset: {
        readonly __typename: 'UpdateSponsorCampaignAssetPayload';
        readonly sponsorCampaignAsset: {
            readonly __typename: 'SponsorCampaignAsset';
            readonly id: string;
            readonly targetUser: SponsorTargetUserRole;
            readonly newsfeedPostIndex?: number | null;
            readonly startDate: string;
            readonly endDate: string;
            readonly totalViewsCount: number;
            readonly totalClicksCount: number;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly canDelete: boolean;
            readonly canUpdate: boolean;
            readonly sponsorAsset: {
                readonly __typename: 'SponsorAsset';
                readonly id: string;
                readonly label?: string | null;
                readonly type: SponsorAssetType;
                readonly title?: string | null;
                readonly content?: string | null;
                readonly targetUrl: string;
                readonly mediaFileId: string;
                readonly mediaFileAvailable: boolean;
                readonly mediaFileUrl?: string | null;
                readonly mediaType: SponsorAssetMediaType;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
                readonly sponsorProvider: {
                    readonly __typename: 'SponsorProvider';
                    readonly id: string;
                    readonly title: string;
                    readonly logoImageUrl: string;
                };
            };
        };
    };
};

export type UpdateSponsorCampaignMutationVariables = Exact<{
    input: UpdateSponsorCampaignInput;
}>;

export type UpdateSponsorCampaignMutation = {
    readonly __typename: 'Mutation';
    readonly updateSponsorCampaign: {
        readonly __typename: 'UpdateSponsorCampaignPayload';
        readonly sponsorCampaign: {
            readonly __typename: 'SponsorCampaign';
            readonly id: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly timezone: string;
            readonly status: SponsorCampaignStatus;
            readonly targetCountries: ReadonlyArray<string>;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly canPublish: boolean;
            readonly canUnpublish: boolean;
            readonly canDelete: boolean;
            readonly canUpdate: boolean;
            readonly sponsorProvider: {
                readonly __typename: 'SponsorProvider';
                readonly id: string;
                readonly title: string;
                readonly logoImageUrl: string;
            };
            readonly sponsorCampaignAssets: { readonly __typename: 'SponsorCampaignAssetConnection'; readonly totalCount: number };
        };
    };
};

export type UpdateSponsorProviderMutationVariables = Exact<{
    input: UpdateSponsorProviderInput;
}>;

export type UpdateSponsorProviderMutation = {
    readonly __typename: 'Mutation';
    readonly updateSponsorProvider: {
        readonly __typename: 'UpdateSponsorProviderPayload';
        readonly sponsorProvider: {
            readonly __typename: 'SponsorProvider';
            readonly id: string;
            readonly title: string;
            readonly logoImageUrl: string;
        };
    };
};

export type CreateThreeGoodThingsMutationVariables = Exact<{
    input: CreateThreeGoodThingsInput;
}>;

export type CreateThreeGoodThingsMutation = {
    readonly __typename: 'Mutation';
    readonly createThreeGoodThings: {
        readonly __typename: 'CreateThreeGoodThingsPayload';
        readonly threeGoodThings: {
            readonly __typename: 'ThreeGoodThings';
            readonly id: string;
            readonly date: string;
            readonly firstItem: string;
            readonly secondItem?: string | null;
            readonly thirdItem?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        };
    };
};

export type DeleteThreeGoodThingsMutationVariables = Exact<{
    input: DeleteThreeGoodThingsInput;
}>;

export type DeleteThreeGoodThingsMutation = {
    readonly __typename: 'Mutation';
    readonly deleteThreeGoodThings: { readonly __typename: 'DeleteThreeGoodThingsPayload'; readonly threeGoodThingsId: string };
};

export type ThreeGoodThingsListQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ThreeGoodThingsListQuery = {
    readonly __typename: 'Query';
    readonly threeGoodThingsList: {
        readonly __typename: 'ThreeGoodThingsConnection';
        readonly totalCount: number;
        readonly pageInfo: {
            readonly __typename: 'PageInfo';
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor?: string | null;
            readonly endCursor?: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly __typename: 'ThreeGoodThingsEdge';
            readonly cursor: string;
            readonly node?: {
                readonly __typename: 'ThreeGoodThings';
                readonly id: string;
                readonly date: string;
                readonly firstItem: string;
                readonly secondItem?: string | null;
                readonly thirdItem?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly canUpdate: boolean;
                readonly canDelete: boolean;
            } | null;
        }>;
    };
};

export type UpdateThreeGoodThingsMutationVariables = Exact<{
    input: UpdateThreeGoodThingsInput;
}>;

export type UpdateThreeGoodThingsMutation = {
    readonly __typename: 'Mutation';
    readonly updateThreeGoodThings: {
        readonly __typename: 'UpdateThreeGoodThingsPayload';
        readonly threeGoodThings: {
            readonly __typename: 'ThreeGoodThings';
            readonly id: string;
            readonly date: string;
            readonly firstItem: string;
            readonly secondItem?: string | null;
            readonly thirdItem?: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
            readonly canUpdate: boolean;
            readonly canDelete: boolean;
        };
    };
};

export type CheckUserVerificationRequestResultMutationVariables = Exact<{
    input: CheckUserVerificationRequestResultInput;
}>;

export type CheckUserVerificationRequestResultMutation = {
    readonly __typename: 'Mutation';
    readonly checkUserVerificationRequestResult: {
        readonly __typename: 'CheckUserVerificationRequestResultPayload';
        readonly success: boolean;
        readonly isComplete: boolean;
    };
};

export type MeDetailedQueryVariables = Exact<{ [key: string]: never }>;

export type MeDetailedQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly email: string;
        readonly countryCode: string;
        readonly userType: UserType;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly dateOfBirth?: string | null;
        readonly ageGroup?: UserAgeGroup | null;
        readonly canAccessLessons: boolean;
        readonly emailStatus: {
            readonly __typename: 'UserEmailStatus';
            readonly hasBouncedEmail?: boolean | null;
            readonly hasMarkedAsSpamEmail?: boolean | null;
            readonly hasPersonalEmail?: boolean | null;
        };
        readonly geoRegion?: {
            readonly __typename: 'GeoLocation';
            readonly id: string;
            readonly name: string;
            readonly displayName: string;
        } | null;
        readonly loginIdentities: ReadonlyArray<{
            readonly __typename: 'UserLoginIdentity';
            readonly id: string;
            readonly connection: string;
            readonly federatedId: string;
            readonly email: string;
        }>;
        readonly connectedMentorProviders: ReadonlyArray<{
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly mentorGroups: ReadonlyArray<{
                readonly __typename: 'MentorGroup';
                readonly id: string;
                readonly title: string;
                readonly bannerImageUrl?: string | null;
                readonly information?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            }>;
        }>;
        readonly availableMentorProviders: {
            readonly __typename: 'ProviderConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProviderEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                } | null;
            }>;
        };
        readonly providersWithMembership: ReadonlyArray<{
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
            readonly canUpdateProviderDetails: boolean;
            readonly canAccessAdminFeature: boolean;
            readonly canAccessMentorFeature: boolean;
            readonly canAccessMentorRewardFeature: boolean;
            readonly canAccessOpportunityFeature: boolean;
            readonly canAccessNewsfeedFeature: boolean;
            readonly canAccessProjectTemplatesFeature: boolean;
            readonly canAccessEmploymentFeature: boolean;
            readonly canAccessBadgeTemplatesFeature: boolean;
            readonly canImportMentorRewardPoints: boolean;
            readonly canImportMentorGroupsFromLearnerSmsData: boolean;
            readonly canPinPost: boolean;
            readonly canToggleProviderLessonAccess: boolean;
            readonly isDailyNoticesAvailable: boolean;
            readonly isLessonsDisabled: boolean;
        }>;
        readonly currentLearnerSmsProfile?: {
            readonly __typename: 'LearnerSmsProfile';
            readonly id: string;
            readonly uniqueStudentNumber?: string | null;
        } | null;
    };
};

export type MeSimpleQueryVariables = Exact<{ [key: string]: never }>;

export type MeSimpleQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly email: string;
        readonly countryCode: string;
        readonly userType: UserType;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly dateOfBirth?: string | null;
        readonly ageGroup?: UserAgeGroup | null;
        readonly geoRegion?: { readonly __typename: 'GeoLocation'; readonly id: string; readonly name: string } | null;
    };
};

export type MeVerifiedIdentityQueryVariables = Exact<{ [key: string]: never }>;

export type MeVerifiedIdentityQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly email: string;
        readonly countryCode: string;
        readonly userType: UserType;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly dateOfBirth?: string | null;
        readonly ageGroup?: UserAgeGroup | null;
        readonly verifiedIdentity?: {
            readonly __typename: 'UserVerifiedIdentity';
            readonly id: string;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName: string;
            readonly dateOfBirth: string;
            readonly realAmlSignatureKey: string;
            readonly realAmlVerificationType: string;
            readonly expiresAt: string;
            readonly updatedAt: string;
            readonly createdAt: string;
        } | null;
        readonly geoRegion?: { readonly __typename: 'GeoLocation'; readonly id: string; readonly name: string } | null;
    };
};

export type MyCareersRiasecResultQueryVariables = Exact<{ [key: string]: never }>;

export type MyCareersRiasecResultQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly careersRiasecResult?: {
            readonly __typename: 'CareersRiasecResult';
            readonly id: string;
            readonly interestCode: string;
            readonly interestRealistic: number;
            readonly interestInvestigative: number;
            readonly interestArtistic: number;
            readonly interestSocial: number;
            readonly interestEnterprising: number;
            readonly interestConventional: number;
            readonly updatedAt: string;
            readonly createdAt: string;
        } | null;
    };
};

export type MyConnectedMentorProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type MyConnectedMentorProvidersQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly connectedMentorProviders: ReadonlyArray<{
            readonly __typename: 'MentorStudent';
            readonly id: string;
            readonly provider: {
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
            };
            readonly mentorGroups: ReadonlyArray<{
                readonly __typename: 'MentorGroup';
                readonly id: string;
                readonly title: string;
                readonly bannerImageUrl?: string | null;
                readonly information?: string | null;
                readonly updatedAt: string;
                readonly createdAt: string;
                readonly discussionUserState: {
                    readonly __typename: 'MentorGroupDiscussionUserState';
                    readonly lastRead?: string | null;
                    readonly lastTyping?: string | null;
                    readonly unreadMessageCount: number;
                    readonly mutedAt?: string | null;
                };
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
            }>;
            readonly rewards: {
                readonly __typename: 'MentorRewardStudent';
                readonly id: string;
                readonly availablePoints: number;
                readonly totalPoints: number;
                readonly transactions: { readonly __typename: 'MentorRewardTransactionConnection'; readonly notViewedCount: number };
                readonly user: {
                    readonly __typename: 'User';
                    readonly id: string;
                    readonly pictureUrl?: string | null;
                    readonly fullName: string;
                    readonly givenName: string;
                    readonly familyName?: string | null;
                    readonly educationProvider?: { readonly __typename: 'Provider'; readonly id: string; readonly title: string } | null;
                };
                readonly learnerSmsProfile?: {
                    readonly __typename: 'LearnerSmsProfile';
                    readonly id: string;
                    readonly pictureUrl: string;
                    readonly preferredGivenName: string;
                    readonly preferredFamilyName?: string | null;
                    readonly preferredFullName: string;
                    readonly email: string;
                    readonly idNumber: string;
                    readonly nationalCurriculumYear: string;
                } | null;
            };
            readonly notes: { readonly __typename: 'MentorStudentNoteConnection'; readonly notViewedCount: number };
            readonly todos: { readonly __typename: 'MentorStudentTodoConnection'; readonly notViewedCount: number };
            readonly badges: {
                readonly __typename: 'BadgeConnection';
                readonly totalCount: number;
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'BadgeEdge';
                    readonly node?: {
                        readonly __typename: 'Badge';
                        readonly id: string;
                        readonly title: string;
                        readonly comment: string;
                        readonly badgeImageUrl: string;
                        readonly updatedAt: string;
                        readonly createdAt: string;
                        readonly canDelete: boolean;
                        readonly user: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                        readonly provider: {
                            readonly __typename: 'Provider';
                            readonly id: string;
                            readonly title: string;
                            readonly countryCode: string;
                            readonly providerType: ProviderType;
                            readonly logoImageUrl: string;
                            readonly bannerImageUrl?: string | null;
                        };
                        readonly createdBy: {
                            readonly __typename: 'User';
                            readonly id: string;
                            readonly pictureUrl?: string | null;
                            readonly fullName: string;
                            readonly givenName: string;
                            readonly familyName?: string | null;
                            readonly educationProvider?: {
                                readonly __typename: 'Provider';
                                readonly id: string;
                                readonly title: string;
                            } | null;
                        };
                    } | null;
                }>;
            };
        }>;
        readonly availableMentorProviders: {
            readonly __typename: 'ProviderConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'ProviderEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                } | null;
            }>;
        };
    };
};

export type MyEmailNotificationsSettingQueryVariables = Exact<{ [key: string]: never }>;

export type MyEmailNotificationsSettingQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly emailNotificationsSetting: {
            readonly __typename: 'UserEmailNotificationsSetting';
            readonly isConnectionNotificationsEnabled: boolean;
            readonly isDailySummaryNotificationsEnabled: boolean;
            readonly isDiscussionNotificationsEnabled: boolean;
            readonly isProjectNotificationsEnabled: boolean;
        };
    };
};

export type MyEmailStatusQueryVariables = Exact<{ [key: string]: never }>;

export type MyEmailStatusQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly email: string;
        readonly emailStatus: {
            readonly __typename: 'UserEmailStatus';
            readonly hasBouncedEmail?: boolean | null;
            readonly hasMarkedAsSpamEmail?: boolean | null;
            readonly hasPersonalEmail?: boolean | null;
        };
    };
};

export type MyFavouriteLessonsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyFavouriteLessonsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly favoriteLessons: {
            readonly __typename: 'LessonConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'LessonEdge';
                readonly cursor: string;
                readonly node?: {
                    readonly __typename: 'Lesson';
                    readonly id: string;
                    readonly locale: string;
                    readonly title: string;
                    readonly description: string;
                    readonly coverImageUrl?: string | null;
                    readonly documentId: string;
                    readonly documentIframeUrl?: string | null;
                    readonly tags: ReadonlyArray<string>;
                    readonly isFeatured: boolean;
                    readonly countryCodes: ReadonlyArray<string>;
                    readonly favoriteCount: number;
                    readonly hasRead: boolean;
                    readonly hasFavorited: boolean;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly canUpdate: boolean;
                    readonly canDelete: boolean;
                    readonly lessonCategory: {
                        readonly __typename: 'LessonCategory';
                        readonly id: string;
                        readonly locale: string;
                        readonly title: string;
                        readonly canUpdate: boolean;
                        readonly canDelete: boolean;
                        readonly parent?: {
                            readonly __typename: 'LessonCategory';
                            readonly id: string;
                            readonly locale: string;
                            readonly title: string;
                        } | null;
                    };
                } | null;
            }>;
        };
    };
};

export type MyMenuNavigationItemsQueryVariables = Exact<{ [key: string]: never }>;

export type MyMenuNavigationItemsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly menuNavigationItems: ReadonlyArray<NavigationItem>;
        readonly extraMenuNavigationItems: ReadonlyArray<NavigationItem>;
        readonly id: string;
        readonly email: string;
        readonly countryCode: string;
        readonly userType: UserType;
        readonly pictureUrl?: string | null;
        readonly fullName: string;
        readonly givenName: string;
        readonly familyName?: string | null;
        readonly dateOfBirth?: string | null;
        readonly ageGroup?: UserAgeGroup | null;
        readonly geoRegion?: { readonly __typename: 'GeoLocation'; readonly id: string; readonly name: string } | null;
    };
};

export type MyNotificationsCountQueryVariables = Exact<{ [key: string]: never }>;

export type MyNotificationsCountQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly connectedMentorProviders: ReadonlyArray<{
            readonly __typename: 'MentorStudent';
            readonly mentorGroups: ReadonlyArray<{
                readonly __typename: 'MentorGroup';
                readonly id: string;
                readonly discussionUserState: {
                    readonly __typename: 'MentorGroupDiscussionUserState';
                    readonly unreadMessageCount: number;
                };
            }>;
            readonly notes: { readonly __typename: 'MentorStudentNoteConnection'; readonly notViewedCount: number };
            readonly todos: { readonly __typename: 'MentorStudentTodoConnection'; readonly notViewedCount: number };
            readonly rewards: {
                readonly __typename: 'MentorRewardStudent';
                readonly id: string;
                readonly transactions: { readonly __typename: 'MentorRewardTransactionConnection'; readonly notViewedCount: number };
            };
        }>;
        readonly providersWithMembership: ReadonlyArray<{
            readonly __typename: 'Provider';
            readonly id: string;
            readonly mentorGroups: { readonly __typename: 'MentorGroupConnection'; readonly unreadMessageCount: number };
        }>;
        readonly connectionRequests: { readonly __typename: 'UserConnectionRequestConnection'; readonly totalCount: number };
    };
};

export type MyProvidersWithMembershipMentorGroupsUnreadMessageCountQueryVariables = Exact<{ [key: string]: never }>;

export type MyProvidersWithMembershipMentorGroupsUnreadMessageCountQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly providersWithMembership: ReadonlyArray<{
            readonly __typename: 'Provider';
            readonly id: string;
            readonly mentorGroups: { readonly __typename: 'MentorGroupConnection'; readonly unreadMessageCount: number };
        }>;
    };
};

export type MyProvidersWithMembershipQueryVariables = Exact<{ [key: string]: never }>;

export type MyProvidersWithMembershipQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly providersWithMembership: ReadonlyArray<{
            readonly __typename: 'Provider';
            readonly id: string;
            readonly title: string;
            readonly countryCode: string;
            readonly providerType: ProviderType;
            readonly logoImageUrl: string;
            readonly bannerImageUrl?: string | null;
            readonly canUpdateProviderDetails: boolean;
            readonly canAccessAdminFeature: boolean;
            readonly canAccessMentorFeature: boolean;
            readonly canAccessMentorRewardFeature: boolean;
            readonly canAccessOpportunityFeature: boolean;
            readonly canAccessNewsfeedFeature: boolean;
            readonly canAccessProjectTemplatesFeature: boolean;
            readonly canAccessEmploymentFeature: boolean;
            readonly canAccessBadgeTemplatesFeature: boolean;
            readonly canImportMentorRewardPoints: boolean;
            readonly canImportMentorGroupsFromLearnerSmsData: boolean;
            readonly canPinPost: boolean;
            readonly canToggleProviderLessonAccess: boolean;
            readonly isDailyNoticesAvailable: boolean;
            readonly isLessonsDisabled: boolean;
        }>;
    };
};

export type ReactivateUserEmailMutationVariables = Exact<{ [key: string]: never }>;

export type ReactivateUserEmailMutation = {
    readonly __typename: 'Mutation';
    readonly reactivateUserEmail: { readonly __typename: 'ReactivateUserEmailSuppressionPayload'; readonly success: boolean };
};

export type StartUserImageUploadMutationVariables = Exact<{
    input: StartUserImageUploadInput;
}>;

export type StartUserImageUploadMutation = {
    readonly __typename: 'Mutation';
    readonly startUserImageUpload: {
        readonly __typename: 'StartUserImageUploadPayload';
        readonly fileId: string;
        readonly uploadUrl: string;
    };
};

export type StartUserVerificationRequestMutationVariables = Exact<{
    input: StartUserVerificationRequestInput;
}>;

export type StartUserVerificationRequestMutation = {
    readonly __typename: 'Mutation';
    readonly startUserVerificationRequest: {
        readonly __typename: 'StartUserVerificationRequestPayload';
        readonly iFrameSourceUrl: string;
        readonly userVerificationRequestId: string;
    };
};

export type SubmitCareersRiasecResultMutationVariables = Exact<{
    input: SubmitCareersRiasecResultInput;
}>;

export type SubmitCareersRiasecResultMutation = {
    readonly __typename: 'Mutation';
    readonly submitCareersRiasecResult: {
        readonly __typename: 'SubmitCareersRiasecResultPayload';
        readonly careersRiasecResult: {
            readonly __typename: 'CareersRiasecResult';
            readonly id: string;
            readonly interestCode: string;
            readonly interestRealistic: number;
            readonly interestInvestigative: number;
            readonly interestArtistic: number;
            readonly interestSocial: number;
            readonly interestEnterprising: number;
            readonly interestConventional: number;
            readonly updatedAt: string;
            readonly createdAt: string;
        };
    };
};

export type UnlinkAccountMutationVariables = Exact<{
    input: UnlinkAccountInput;
}>;

export type UnlinkAccountMutation = {
    readonly __typename: 'Mutation';
    readonly unlinkAccount: {
        readonly __typename: 'UnlinkAccountPayload';
        readonly user: {
            readonly __typename: 'UserData';
            readonly id: string;
            readonly email: string;
            readonly countryCode: string;
            readonly userType: UserType;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly dateOfBirth?: string | null;
            readonly ageGroup?: UserAgeGroup | null;
            readonly canAccessLessons: boolean;
            readonly emailStatus: {
                readonly __typename: 'UserEmailStatus';
                readonly hasBouncedEmail?: boolean | null;
                readonly hasMarkedAsSpamEmail?: boolean | null;
                readonly hasPersonalEmail?: boolean | null;
            };
            readonly geoRegion?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
            } | null;
            readonly loginIdentities: ReadonlyArray<{
                readonly __typename: 'UserLoginIdentity';
                readonly id: string;
                readonly connection: string;
                readonly federatedId: string;
                readonly email: string;
            }>;
            readonly connectedMentorProviders: ReadonlyArray<{
                readonly __typename: 'MentorStudent';
                readonly id: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
                readonly mentorGroups: ReadonlyArray<{
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                }>;
            }>;
            readonly availableMentorProviders: {
                readonly __typename: 'ProviderConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'ProviderEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    } | null;
                }>;
            };
            readonly providersWithMembership: ReadonlyArray<{
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            }>;
            readonly currentLearnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly uniqueStudentNumber?: string | null;
            } | null;
        };
    };
};

export type UpdateUserEmailAddressMutationVariables = Exact<{
    input: UpdateUserEmailAddressInput;
}>;

export type UpdateUserEmailAddressMutation = {
    readonly __typename: 'Mutation';
    readonly updateUserEmailAddress: {
        readonly __typename: 'UpdateUserEmailAddressPayload';
        readonly user: {
            readonly __typename: 'UserData';
            readonly id: string;
            readonly email: string;
            readonly countryCode: string;
            readonly userType: UserType;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly dateOfBirth?: string | null;
            readonly ageGroup?: UserAgeGroup | null;
            readonly canAccessLessons: boolean;
            readonly emailStatus: {
                readonly __typename: 'UserEmailStatus';
                readonly hasBouncedEmail?: boolean | null;
                readonly hasMarkedAsSpamEmail?: boolean | null;
                readonly hasPersonalEmail?: boolean | null;
            };
            readonly geoRegion?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
            } | null;
            readonly loginIdentities: ReadonlyArray<{
                readonly __typename: 'UserLoginIdentity';
                readonly id: string;
                readonly connection: string;
                readonly federatedId: string;
                readonly email: string;
            }>;
            readonly connectedMentorProviders: ReadonlyArray<{
                readonly __typename: 'MentorStudent';
                readonly id: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
                readonly mentorGroups: ReadonlyArray<{
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                }>;
            }>;
            readonly availableMentorProviders: {
                readonly __typename: 'ProviderConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'ProviderEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    } | null;
                }>;
            };
            readonly providersWithMembership: ReadonlyArray<{
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            }>;
            readonly currentLearnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly uniqueStudentNumber?: string | null;
            } | null;
        };
    };
};

export type UpdateUserEmailNotificationsSettingMutationVariables = Exact<{
    input: UpdateUserEmailNotificationsSettingInput;
}>;

export type UpdateUserEmailNotificationsSettingMutation = {
    readonly __typename: 'Mutation';
    readonly updateUserEmailNotificationsSetting: {
        readonly __typename: 'UpdateUserEmailNotificationsSettingPayload';
        readonly userEmailNotificationsSetting: {
            readonly __typename: 'UserEmailNotificationsSetting';
            readonly isConnectionNotificationsEnabled: boolean;
            readonly isDailySummaryNotificationsEnabled: boolean;
            readonly isDiscussionNotificationsEnabled: boolean;
            readonly isProjectNotificationsEnabled: boolean;
        };
    };
};

export type UpdateUserMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
    readonly __typename: 'Mutation';
    readonly updateUser: {
        readonly __typename: 'UpdateUserPayload';
        readonly user: {
            readonly __typename: 'UserData';
            readonly id: string;
            readonly email: string;
            readonly countryCode: string;
            readonly userType: UserType;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly dateOfBirth?: string | null;
            readonly ageGroup?: UserAgeGroup | null;
            readonly canAccessLessons: boolean;
            readonly emailStatus: {
                readonly __typename: 'UserEmailStatus';
                readonly hasBouncedEmail?: boolean | null;
                readonly hasMarkedAsSpamEmail?: boolean | null;
                readonly hasPersonalEmail?: boolean | null;
            };
            readonly geoRegion?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
            } | null;
            readonly loginIdentities: ReadonlyArray<{
                readonly __typename: 'UserLoginIdentity';
                readonly id: string;
                readonly connection: string;
                readonly federatedId: string;
                readonly email: string;
            }>;
            readonly connectedMentorProviders: ReadonlyArray<{
                readonly __typename: 'MentorStudent';
                readonly id: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
                readonly mentorGroups: ReadonlyArray<{
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                }>;
            }>;
            readonly availableMentorProviders: {
                readonly __typename: 'ProviderConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'ProviderEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    } | null;
                }>;
            };
            readonly providersWithMembership: ReadonlyArray<{
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            }>;
            readonly currentLearnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly uniqueStudentNumber?: string | null;
            } | null;
        };
    };
};

export type UpdateUserPictureMutationVariables = Exact<{
    input: UpdateUserPictureInput;
}>;

export type UpdateUserPictureMutation = {
    readonly __typename: 'Mutation';
    readonly updateUserPicture: {
        readonly __typename: 'UpdateUserPicturePayload';
        readonly user: {
            readonly __typename: 'UserData';
            readonly id: string;
            readonly email: string;
            readonly countryCode: string;
            readonly userType: UserType;
            readonly pictureUrl?: string | null;
            readonly fullName: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly dateOfBirth?: string | null;
            readonly ageGroup?: UserAgeGroup | null;
            readonly canAccessLessons: boolean;
            readonly emailStatus: {
                readonly __typename: 'UserEmailStatus';
                readonly hasBouncedEmail?: boolean | null;
                readonly hasMarkedAsSpamEmail?: boolean | null;
                readonly hasPersonalEmail?: boolean | null;
            };
            readonly geoRegion?: {
                readonly __typename: 'GeoLocation';
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
            } | null;
            readonly loginIdentities: ReadonlyArray<{
                readonly __typename: 'UserLoginIdentity';
                readonly id: string;
                readonly connection: string;
                readonly federatedId: string;
                readonly email: string;
            }>;
            readonly connectedMentorProviders: ReadonlyArray<{
                readonly __typename: 'MentorStudent';
                readonly id: string;
                readonly provider: {
                    readonly __typename: 'Provider';
                    readonly id: string;
                    readonly title: string;
                    readonly countryCode: string;
                    readonly providerType: ProviderType;
                    readonly logoImageUrl: string;
                    readonly bannerImageUrl?: string | null;
                };
                readonly mentorGroups: ReadonlyArray<{
                    readonly __typename: 'MentorGroup';
                    readonly id: string;
                    readonly title: string;
                    readonly bannerImageUrl?: string | null;
                    readonly information?: string | null;
                    readonly updatedAt: string;
                    readonly createdAt: string;
                    readonly provider: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    };
                }>;
            }>;
            readonly availableMentorProviders: {
                readonly __typename: 'ProviderConnection';
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly __typename: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor?: string | null;
                    readonly endCursor?: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly __typename: 'ProviderEdge';
                    readonly cursor: string;
                    readonly node?: {
                        readonly __typename: 'Provider';
                        readonly id: string;
                        readonly title: string;
                        readonly countryCode: string;
                        readonly providerType: ProviderType;
                        readonly logoImageUrl: string;
                        readonly bannerImageUrl?: string | null;
                    } | null;
                }>;
            };
            readonly providersWithMembership: ReadonlyArray<{
                readonly __typename: 'Provider';
                readonly id: string;
                readonly title: string;
                readonly countryCode: string;
                readonly providerType: ProviderType;
                readonly logoImageUrl: string;
                readonly bannerImageUrl?: string | null;
                readonly canUpdateProviderDetails: boolean;
                readonly canAccessAdminFeature: boolean;
                readonly canAccessMentorFeature: boolean;
                readonly canAccessMentorRewardFeature: boolean;
                readonly canAccessOpportunityFeature: boolean;
                readonly canAccessNewsfeedFeature: boolean;
                readonly canAccessProjectTemplatesFeature: boolean;
                readonly canAccessEmploymentFeature: boolean;
                readonly canAccessBadgeTemplatesFeature: boolean;
                readonly canImportMentorRewardPoints: boolean;
                readonly canImportMentorGroupsFromLearnerSmsData: boolean;
                readonly canPinPost: boolean;
                readonly canToggleProviderLessonAccess: boolean;
                readonly isDailyNoticesAvailable: boolean;
                readonly isLessonsDisabled: boolean;
            }>;
            readonly currentLearnerSmsProfile?: {
                readonly __typename: 'LearnerSmsProfile';
                readonly id: string;
                readonly uniqueStudentNumber?: string | null;
            } | null;
        };
    };
};

export type CheckVerifiedIdCardRequestMutationVariables = Exact<{
    input: CheckVerifiedIdCardRequestInput;
}>;

export type CheckVerifiedIdCardRequestMutation = {
    readonly __typename: 'Mutation';
    readonly checkVerifiedIdCardRequest: {
        readonly __typename: 'CheckVerifiedIdCardRequestPayload';
        readonly success: boolean;
        readonly isComplete: boolean;
        readonly verifiedIdVerifiableCredential?: {
            readonly __typename: 'VerifiedIdVerifiableCredential';
            readonly id: string;
            readonly walletCardType: WalletCardType;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly givenName: string;
            readonly familyName?: string | null;
            readonly birthDate: string;
            readonly pictureUrl: string;
            readonly countryCode: string;
            readonly verificationSources: ReadonlyArray<string>;
            readonly age: number;
            readonly ageOver13: boolean;
            readonly ageOver16: boolean;
            readonly ageOver18: boolean;
            readonly ageOver21: boolean;
            readonly expiresAt: string;
            readonly address?: {
                readonly __typename: 'VerifiedIdVerifiableCredentialAddress';
                readonly streetAddress?: string | null;
                readonly locality?: string | null;
                readonly region?: string | null;
                readonly postalCode?: string | null;
                readonly country: string;
            } | null;
        } | null;
    };
};

export type CheckWalletCardVerificationSimpleQueryVariables = Exact<{
    transactionId: Scalars['UUID']['input'];
}>;

export type CheckWalletCardVerificationSimpleQuery = {
    readonly __typename: 'Query';
    readonly checkWalletCardVerificationSimple: {
        readonly __typename: 'WalletCardVerificationSimpleStatus';
        readonly transactionId: string;
        readonly status: WalletCardVerificationSimpleStatusEnum;
        readonly credentials?: ReadonlyArray<{
            readonly __typename: 'WalletCardVerificationSimpleStatusCredential';
            readonly credentialId: string;
            readonly claims: { [key: string]: any };
        }> | null;
        readonly errorDetails?: {
            readonly __typename: 'WalletCardVerificationSimpleStatusErrorDetails';
            readonly error: string;
            readonly errorDescription: string;
        } | null;
    };
};

export type MyPreferredWalletCardQueryVariables = Exact<{ [key: string]: never }>;

export type MyPreferredWalletCardQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly preferredWalletCard?:
            | {
                  readonly __typename: 'BasicVerifiedIdCard';
                  readonly id: string;
                  readonly walletCardType: WalletCardType;
                  readonly createdAt: string;
                  readonly updatedAt: string;
              }
            | {
                  readonly __typename: 'LearnerIdVerifiableCredential';
                  readonly id: string;
                  readonly walletCardType: WalletCardType;
                  readonly createdAt: string;
                  readonly updatedAt: string;
                  readonly idNumber: string;
                  readonly givenName: string;
                  readonly familyName?: string | null;
                  readonly birthDate: string;
                  readonly pictureUrl: string;
                  readonly email: string;
                  readonly countryCode: string;
                  readonly organisationTitle: string;
                  readonly organisationLogoUrl: string;
                  readonly barcodeNumber: string;
                  readonly nationalCurriculumYear: string;
                  readonly age: number;
                  readonly ageOver13: boolean;
                  readonly ageOver16: boolean;
                  readonly ageOver18: boolean;
                  readonly ageOver21: boolean;
                  readonly expiresAt: string;
                  readonly address?: {
                      readonly __typename: 'LearnerIdVerifiableCredentialAddress';
                      readonly streetAddress?: string | null;
                      readonly locality?: string | null;
                      readonly region?: string | null;
                      readonly postalCode?: string | null;
                      readonly country: string;
                  } | null;
              }
            | {
                  readonly __typename: 'LoyaltyVerifiableCredential';
                  readonly id: string;
                  readonly walletCardType: WalletCardType;
                  readonly createdAt: string;
                  readonly updatedAt: string;
              }
            | {
                  readonly __typename: 'NZStudentId';
                  readonly id: string;
                  readonly walletCardType: WalletCardType;
                  readonly createdAt: string;
                  readonly updatedAt: string;
              }
            | {
                  readonly __typename: 'VerifiedIdVerifiableCredential';
                  readonly id: string;
                  readonly walletCardType: WalletCardType;
                  readonly createdAt: string;
                  readonly updatedAt: string;
              }
            | null;
    };
};

export type MyWalletCardsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<ReadonlyArray<WalletCardType> | WalletCardType>;
}>;

export type MyWalletCardsQuery = {
    readonly __typename: 'Query';
    readonly me: {
        readonly __typename: 'UserData';
        readonly id: string;
        readonly walletCards: {
            readonly __typename: 'WalletCardConnection';
            readonly totalCount: number;
            readonly pageInfo: {
                readonly __typename: 'PageInfo';
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor?: string | null;
                readonly endCursor?: string | null;
            };
            readonly edges: ReadonlyArray<{
                readonly __typename: 'WalletCardEdge';
                readonly node?:
                    | {
                          readonly __typename: 'BasicVerifiedIdCard';
                          readonly id: string;
                          readonly walletCardType: WalletCardType;
                          readonly createdAt: string;
                          readonly updatedAt: string;
                      }
                    | {
                          readonly __typename: 'LearnerIdVerifiableCredential';
                          readonly id: string;
                          readonly walletCardType: WalletCardType;
                          readonly createdAt: string;
                          readonly updatedAt: string;
                          readonly idNumber: string;
                          readonly givenName: string;
                          readonly familyName?: string | null;
                          readonly birthDate: string;
                          readonly pictureUrl: string;
                          readonly email: string;
                          readonly countryCode: string;
                          readonly organisationTitle: string;
                          readonly organisationLogoUrl: string;
                          readonly barcodeNumber: string;
                          readonly nationalCurriculumYear: string;
                          readonly age: number;
                          readonly ageOver13: boolean;
                          readonly ageOver16: boolean;
                          readonly ageOver18: boolean;
                          readonly ageOver21: boolean;
                          readonly expiresAt: string;
                          readonly address?: {
                              readonly __typename: 'LearnerIdVerifiableCredentialAddress';
                              readonly streetAddress?: string | null;
                              readonly locality?: string | null;
                              readonly region?: string | null;
                              readonly postalCode?: string | null;
                              readonly country: string;
                          } | null;
                      }
                    | {
                          readonly __typename: 'LoyaltyVerifiableCredential';
                          readonly id: string;
                          readonly walletCardType: WalletCardType;
                          readonly createdAt: string;
                          readonly updatedAt: string;
                          readonly issuerId: string;
                          readonly issuerTitle: string;
                          readonly issuerLogoUrl: string;
                          readonly programId: string;
                          readonly programTitle: string;
                          readonly hexBackgroundColor: string;
                          readonly termsAndConditionsUrl?: string | null;
                          readonly possibleBarcodeNumber?: string | null;
                          readonly possibleExpiresAt?: string | null;
                      }
                    | {
                          readonly __typename: 'NZStudentId';
                          readonly id: string;
                          readonly walletCardType: WalletCardType;
                          readonly createdAt: string;
                          readonly updatedAt: string;
                      }
                    | {
                          readonly __typename: 'VerifiedIdVerifiableCredential';
                          readonly id: string;
                          readonly walletCardType: WalletCardType;
                          readonly createdAt: string;
                          readonly updatedAt: string;
                          readonly givenName: string;
                          readonly familyName?: string | null;
                          readonly birthDate: string;
                          readonly pictureUrl: string;
                          readonly countryCode: string;
                          readonly verificationSources: ReadonlyArray<string>;
                          readonly age: number;
                          readonly ageOver13: boolean;
                          readonly ageOver16: boolean;
                          readonly ageOver18: boolean;
                          readonly ageOver21: boolean;
                          readonly expiresAt: string;
                          readonly address?: {
                              readonly __typename: 'VerifiedIdVerifiableCredentialAddress';
                              readonly streetAddress?: string | null;
                              readonly locality?: string | null;
                              readonly region?: string | null;
                              readonly postalCode?: string | null;
                              readonly country: string;
                          } | null;
                      }
                    | null;
            }>;
        };
    };
};

export type RequestVerifiedIdCardMutationVariables = Exact<{
    input: RequestVerifiedIdCardInput;
}>;

export type RequestVerifiedIdCardMutation = {
    readonly __typename: 'Mutation';
    readonly requestVerifiedIdCard: {
        readonly __typename: 'RequestVerifiedIdCardPayload';
        readonly iFrameSourceUrl: string;
        readonly userVerificationRequestId: string;
    };
};

export type ResolveWalletCardPresentationSimpleMutationVariables = Exact<{
    input: ResolveWalletCardPresentationSimpleInput;
}>;

export type ResolveWalletCardPresentationSimpleMutation = {
    readonly __typename: 'Mutation';
    readonly resolveWalletCardPresentationSimple: {
        readonly __typename: 'ResolveWalletCardPresentationSimplePayload';
        readonly success: boolean;
        readonly redirectUri?: string | null;
    };
};

export type SimpleMatchWalletCardsForPresentationQueryVariables = Exact<{
    dcqlQuery: Scalars['JSONObject']['input'];
    clientId: Scalars['String']['input'];
    clientMetadata: Scalars['JSONObject']['input'];
}>;

export type SimpleMatchWalletCardsForPresentationQuery = {
    readonly __typename: 'Query';
    readonly simpleMatchWalletCardsForPresentation: {
        readonly __typename: 'WalletCardPresentationSimpleMatches';
        readonly matches: ReadonlyArray<{
            readonly __typename: 'WalletCardPresentationSimpleMatch';
            readonly credentialId: string;
            readonly requestedClaims: ReadonlyArray<string>;
            readonly walletCard:
                | {
                      readonly __typename: 'BasicVerifiedIdCard';
                      readonly id: string;
                      readonly walletCardType: WalletCardType;
                      readonly createdAt: string;
                      readonly updatedAt: string;
                  }
                | {
                      readonly __typename: 'LearnerIdVerifiableCredential';
                      readonly id: string;
                      readonly walletCardType: WalletCardType;
                      readonly createdAt: string;
                      readonly updatedAt: string;
                      readonly idNumber: string;
                      readonly givenName: string;
                      readonly familyName?: string | null;
                      readonly birthDate: string;
                      readonly pictureUrl: string;
                      readonly email: string;
                      readonly countryCode: string;
                      readonly organisationTitle: string;
                      readonly organisationLogoUrl: string;
                      readonly barcodeNumber: string;
                      readonly nationalCurriculumYear: string;
                      readonly age: number;
                      readonly ageOver13: boolean;
                      readonly ageOver16: boolean;
                      readonly ageOver18: boolean;
                      readonly ageOver21: boolean;
                      readonly expiresAt: string;
                      readonly address?: {
                          readonly __typename: 'LearnerIdVerifiableCredentialAddress';
                          readonly streetAddress?: string | null;
                          readonly locality?: string | null;
                          readonly region?: string | null;
                          readonly postalCode?: string | null;
                          readonly country: string;
                      } | null;
                  }
                | {
                      readonly __typename: 'LoyaltyVerifiableCredential';
                      readonly id: string;
                      readonly walletCardType: WalletCardType;
                      readonly createdAt: string;
                      readonly updatedAt: string;
                      readonly issuerId: string;
                      readonly issuerTitle: string;
                      readonly issuerLogoUrl: string;
                      readonly programId: string;
                      readonly programTitle: string;
                      readonly hexBackgroundColor: string;
                      readonly termsAndConditionsUrl?: string | null;
                      readonly possibleBarcodeNumber?: string | null;
                      readonly possibleExpiresAt?: string | null;
                  }
                | {
                      readonly __typename: 'NZStudentId';
                      readonly id: string;
                      readonly walletCardType: WalletCardType;
                      readonly createdAt: string;
                      readonly updatedAt: string;
                  }
                | {
                      readonly __typename: 'VerifiedIdVerifiableCredential';
                      readonly id: string;
                      readonly walletCardType: WalletCardType;
                      readonly createdAt: string;
                      readonly updatedAt: string;
                      readonly givenName: string;
                      readonly familyName?: string | null;
                      readonly birthDate: string;
                      readonly pictureUrl: string;
                      readonly countryCode: string;
                      readonly verificationSources: ReadonlyArray<string>;
                      readonly age: number;
                      readonly ageOver13: boolean;
                      readonly ageOver16: boolean;
                      readonly ageOver18: boolean;
                      readonly ageOver21: boolean;
                      readonly expiresAt: string;
                      readonly address?: {
                          readonly __typename: 'VerifiedIdVerifiableCredentialAddress';
                          readonly streetAddress?: string | null;
                          readonly locality?: string | null;
                          readonly region?: string | null;
                          readonly postalCode?: string | null;
                          readonly country: string;
                      } | null;
                  };
        }>;
        readonly groups: ReadonlyArray<{
            readonly __typename: 'WalletCardPresentationSimpleGroup';
            readonly groupIndex: number;
            readonly purpose?: string | null;
            readonly required: boolean;
            readonly combinations: ReadonlyArray<ReadonlyArray<string>>;
        }>;
    };
};

export type StartWalletCardVerificationSimpleMutationVariables = Exact<{
    input: StartWalletCardVerificationSimpleInput;
}>;

export type StartWalletCardVerificationSimpleMutation = {
    readonly __typename: 'Mutation';
    readonly startWalletCardVerificationSimple: {
        readonly __typename: 'StartWalletCardVerificationSimplePayload';
        readonly transactionId: string;
        readonly oid4vpUrl: string;
    };
};

export type WalletCardQueryVariables = Exact<{
    walletCardId: Scalars['UUID']['input'];
}>;

export type WalletCardQuery = {
    readonly __typename: 'Query';
    readonly walletCard?:
        | {
              readonly __typename: 'BasicVerifiedIdCard';
              readonly id: string;
              readonly walletCardType: WalletCardType;
              readonly createdAt: string;
              readonly updatedAt: string;
          }
        | {
              readonly __typename: 'LearnerIdVerifiableCredential';
              readonly id: string;
              readonly walletCardType: WalletCardType;
              readonly createdAt: string;
              readonly updatedAt: string;
              readonly idNumber: string;
              readonly givenName: string;
              readonly familyName?: string | null;
              readonly birthDate: string;
              readonly pictureUrl: string;
              readonly email: string;
              readonly countryCode: string;
              readonly organisationTitle: string;
              readonly organisationLogoUrl: string;
              readonly barcodeNumber: string;
              readonly nationalCurriculumYear: string;
              readonly age: number;
              readonly ageOver13: boolean;
              readonly ageOver16: boolean;
              readonly ageOver18: boolean;
              readonly ageOver21: boolean;
              readonly expiresAt: string;
              readonly address?: {
                  readonly __typename: 'LearnerIdVerifiableCredentialAddress';
                  readonly streetAddress?: string | null;
                  readonly locality?: string | null;
                  readonly region?: string | null;
                  readonly postalCode?: string | null;
                  readonly country: string;
              } | null;
          }
        | {
              readonly __typename: 'LoyaltyVerifiableCredential';
              readonly id: string;
              readonly walletCardType: WalletCardType;
              readonly createdAt: string;
              readonly updatedAt: string;
              readonly issuerId: string;
              readonly issuerTitle: string;
              readonly issuerLogoUrl: string;
              readonly programId: string;
              readonly programTitle: string;
              readonly hexBackgroundColor: string;
              readonly termsAndConditionsUrl?: string | null;
              readonly possibleBarcodeNumber?: string | null;
              readonly possibleExpiresAt?: string | null;
          }
        | {
              readonly __typename: 'NZStudentId';
              readonly id: string;
              readonly walletCardType: WalletCardType;
              readonly createdAt: string;
              readonly updatedAt: string;
          }
        | {
              readonly __typename: 'VerifiedIdVerifiableCredential';
              readonly id: string;
              readonly walletCardType: WalletCardType;
              readonly createdAt: string;
              readonly updatedAt: string;
              readonly givenName: string;
              readonly familyName?: string | null;
              readonly birthDate: string;
              readonly pictureUrl: string;
              readonly countryCode: string;
              readonly verificationSources: ReadonlyArray<string>;
              readonly age: number;
              readonly ageOver13: boolean;
              readonly ageOver16: boolean;
              readonly ageOver18: boolean;
              readonly ageOver21: boolean;
              readonly expiresAt: string;
              readonly address?: {
                  readonly __typename: 'VerifiedIdVerifiableCredentialAddress';
                  readonly streetAddress?: string | null;
                  readonly locality?: string | null;
                  readonly region?: string | null;
                  readonly postalCode?: string | null;
                  readonly country: string;
              } | null;
          }
        | null;
};

export const UserDataSimpleFragmentDoc = gql`
    fragment UserDataSimple on UserData {
        id
        email
        countryCode
        geoRegion {
            id
            name
        }
        userType
        pictureUrl
        fullName
        givenName
        familyName
        dateOfBirth
        ageGroup
    }
`;
export const UserVerifiedIdentityFragmentDoc = gql`
    fragment UserVerifiedIdentity on UserVerifiedIdentity {
        id
        fullName
        givenName
        familyName
        dateOfBirth
        realAmlSignatureKey
        realAmlVerificationType
        expiresAt
        updatedAt
        createdAt
    }
`;
export const UserDataWithVerifiedIdentityFragmentDoc = gql`
    fragment UserDataWithVerifiedIdentity on UserData {
        ...UserDataSimple
        verifiedIdentity {
            ...UserVerifiedIdentity
        }
    }
    ${UserDataSimpleFragmentDoc}
    ${UserVerifiedIdentityFragmentDoc}
`;
export const UserDataWithMenuNavigationDetailsFragmentDoc = gql`
    fragment UserDataWithMenuNavigationDetails on UserData {
        ...UserDataSimple
        menuNavigationItems
        extraMenuNavigationItems
    }
    ${UserDataSimpleFragmentDoc}
`;
export const UserLoginIdentityFragmentDoc = gql`
    fragment UserLoginIdentity on UserLoginIdentity {
        id
        connection
        federatedId
        email
    }
`;
export const ProviderSimpleFragmentDoc = gql`
    fragment ProviderSimple on Provider {
        id
        title
        countryCode
        providerType
        logoImageUrl
        bannerImageUrl
    }
`;
export const MentorGroupSimpleFragmentDoc = gql`
    fragment MentorGroupSimple on MentorGroup {
        id
        title
        bannerImageUrl
        information
        provider {
            ...ProviderSimple
        }
        updatedAt
        createdAt
    }
    ${ProviderSimpleFragmentDoc}
`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
    }
`;
export const ProviderPermissionsFragmentDoc = gql`
    fragment ProviderPermissions on Provider {
        id
        canUpdateProviderDetails
        canAccessAdminFeature
        canAccessMentorFeature
        canAccessMentorRewardFeature
        canAccessOpportunityFeature
        canAccessNewsfeedFeature
        canAccessProjectTemplatesFeature
        canAccessEmploymentFeature
        canAccessBadgeTemplatesFeature
        canImportMentorRewardPoints
        canImportMentorGroupsFromLearnerSmsData
        canPinPost
        canToggleProviderLessonAccess
        isDailyNoticesAvailable
        isLessonsDisabled
    }
`;
export const ProviderWithPermissionsFragmentDoc = gql`
    fragment ProviderWithPermissions on Provider {
        ...ProviderSimple
        ...ProviderPermissions
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderPermissionsFragmentDoc}
`;
export const UserDataDetailedFragmentDoc = gql`
    fragment UserDataDetailed on UserData {
        id
        email
        emailStatus {
            hasBouncedEmail
            hasMarkedAsSpamEmail
            hasPersonalEmail
        }
        countryCode
        geoRegion {
            id
            name
            displayName
        }
        userType
        pictureUrl
        fullName
        givenName
        familyName
        dateOfBirth
        ageGroup
        loginIdentities {
            ...UserLoginIdentity
        }
        connectedMentorProviders {
            id
            provider {
                ...ProviderSimple
            }
            mentorGroups {
                ...MentorGroupSimple
            }
        }
        availableMentorProviders(first: 100) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...ProviderSimple
                }
                cursor
            }
        }
        providersWithMembership {
            ...ProviderWithPermissions
        }
        canAccessLessons
        currentLearnerSmsProfile {
            id
            uniqueStudentNumber
        }
    }
    ${UserLoginIdentityFragmentDoc}
    ${ProviderSimpleFragmentDoc}
    ${MentorGroupSimpleFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ProviderWithPermissionsFragmentDoc}
`;
export const UserEmailStatusFragmentDoc = gql`
    fragment UserEmailStatus on UserData {
        id
        email
        emailStatus {
            hasBouncedEmail
            hasMarkedAsSpamEmail
            hasPersonalEmail
        }
    }
`;
export const UserEmailNotificationsSettingFragmentDoc = gql`
    fragment UserEmailNotificationsSetting on UserEmailNotificationsSetting {
        isConnectionNotificationsEnabled
        isDailySummaryNotificationsEnabled
        isDiscussionNotificationsEnabled
        isProjectNotificationsEnabled
    }
`;
export const TimetableEventFragmentDoc = gql`
    fragment TimetableEvent on TimetableEvent {
        id
        color
        priority
        dateType
        start
        end
        summary
        location
        description
    }
`;
export const DailyNoticeFragmentDoc = gql`
    fragment DailyNotice on DailyNotice {
        id
        color
        provider {
            ...ProviderSimple
        }
        level
        start
        end
        subject
        body
        teacher
        meetingDate
        meetingPlace
    }
    ${ProviderSimpleFragmentDoc}
`;
export const AssessmentResultFragmentDoc = gql`
    fragment AssessmentResult on AssessmentResult {
        id
        provider {
            ...ProviderSimple
        }
        type
        number
        version
        level
        credits
        weighting
        points
        title
        description
        purpose
        subField
        date
        subject
        result
        comment
    }
    ${ProviderSimpleFragmentDoc}
`;
export const LearnerSmsEmployeeFragmentDoc = gql`
    fragment LearnerSmsEmployee on LearnerSmsEmployee {
        id
        pictureUrl
        givenName
        familyName
        fullName
    }
`;
export const LearnerSmsGroupFragmentDoc = gql`
    fragment LearnerSmsGroup on LearnerSmsGroup {
        id
        provider {
            ...ProviderSimple
        }
        name
        code
        numberOfStudents
        employeesList {
            ...LearnerSmsEmployee
        }
        mentorGroup {
            id
            title
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${LearnerSmsEmployeeFragmentDoc}
`;
export const LearnerSmsAttendanceSummaryFragmentDoc = gql`
    fragment LearnerSmsAttendanceSummary on LearnerSmsAttendanceSummary {
        halfDaysJustified
        halfDaysUnjustified
        halfDaysPresent
        halfDaysTotal
        standing
    }
`;
export const CalendarEventFragmentDoc = gql`
    fragment CalendarEvent on CalendarEvent {
        id
        dateType
        start
        end
        summary
        location
        description
    }
`;
export const CalendarFragmentDoc = gql`
    fragment Calendar on Calendar {
        id
        title
        color
        priority
        events(start: $start, end: $end) {
            ...CalendarEvent
        }
    }
    ${CalendarEventFragmentDoc}
`;
export const ProviderSearchResultFragmentDoc = gql`
    fragment ProviderSearchResult on Provider {
        ...ProviderSimple
        hasFollowed
    }
    ${ProviderSimpleFragmentDoc}
`;
export const ProviderInternalContactDetailsFragmentDoc = gql`
    fragment ProviderInternalContactDetails on Provider {
        id
        internalContactDetails {
            fullName
            email
            phone
        }
    }
`;
export const ProviderTechnicalContactDetailsFragmentDoc = gql`
    fragment ProviderTechnicalContactDetails on Provider {
        id
        technicalContactDetails {
            fullName
            email
            phone
        }
    }
`;
export const ProviderModuleFragmentDoc = gql`
    fragment ProviderModule on ProviderModule {
        id
        title
    }
`;
export const ProviderActiveModulesFragmentDoc = gql`
    fragment ProviderActiveModules on Provider {
        id
        activeModules: activeModulesList {
            ...ProviderModule
        }
    }
    ${ProviderModuleFragmentDoc}
`;
export const ProviderIntegrationFragmentDoc = gql`
    fragment ProviderIntegration on ProviderIntegration {
        id
        provider {
            ...ProviderSimple
        }
        integrationType
        parentIntegration {
            id
            integrationType
        }
        childIntegrations {
            id
            integrationType
        }
        updatedAt
        createdAt
    }
    ${ProviderSimpleFragmentDoc}
`;
export const ProviderIntegrationLearnerSmsDataFragmentDoc = gql`
    fragment ProviderIntegrationLearnerSmsData on ProviderIntegrationLearnerSmsData {
        isDeleting
        studentDataCount
        studentPhotoDataCount
        employeeDataCount
        employeePhotoDataCount
        eventDataCount
        groupDataCount
        classDataCount
        lessonDataCount
    }
`;
export const ProviderIntegrationEdgeLearningFragmentDoc = gql`
    fragment ProviderIntegrationEdgeLearning on ProviderIntegrationEdgeLearning {
        ...ProviderIntegration
        ...ProviderIntegrationLearnerSmsData
        moeNumber
        noticeDataCount
        assessmentDataCount
        resultDataCount
        attendanceDataCount
        canDelete
    }
    ${ProviderIntegrationFragmentDoc}
    ${ProviderIntegrationLearnerSmsDataFragmentDoc}
`;
export const ProviderIntegrationKamarFragmentDoc = gql`
    fragment ProviderIntegrationKamar on ProviderIntegrationKamar {
        ...ProviderIntegration
        ...ProviderIntegrationLearnerSmsData
        token
        noticeDataCount
        assessmentDataCount
        resultDataCount
        attendanceDataCount
        timetableDisabledUntil
        canUpdateTimetableDisabledUntil
        canRegenerateToken
        canDelete
    }
    ${ProviderIntegrationFragmentDoc}
    ${ProviderIntegrationLearnerSmsDataFragmentDoc}
`;
export const ProviderIntegrationLissFragmentDoc = gql`
    fragment ProviderIntegrationLiss on ProviderIntegrationLiss {
        ...ProviderIntegration
        ...ProviderIntegrationLearnerSmsData
        token
        canRegenerateToken
        canDelete
    }
    ${ProviderIntegrationFragmentDoc}
    ${ProviderIntegrationLearnerSmsDataFragmentDoc}
`;
export const ProviderIntegrationManualSmsDataFragmentDoc = gql`
    fragment ProviderIntegrationManualSmsData on ProviderIntegrationManualSmsData {
        ...ProviderIntegration
        ...ProviderIntegrationLearnerSmsData
        canDelete
    }
    ${ProviderIntegrationFragmentDoc}
    ${ProviderIntegrationLearnerSmsDataFragmentDoc}
`;
export const ProviderIntegrationWondeFragmentDoc = gql`
    fragment ProviderIntegrationWonde on ProviderIntegrationWonde {
        ...ProviderIntegration
        ...ProviderIntegrationLearnerSmsData
        lastSyncedAt
        canResync
        canDelete
    }
    ${ProviderIntegrationFragmentDoc}
    ${ProviderIntegrationLearnerSmsDataFragmentDoc}
`;
export const ProviderMemberProviderFragmentDoc = gql`
    fragment ProviderMemberProvider on ProviderMember {
        id
        provider {
            ...ProviderSimple
        }
    }
    ${ProviderSimpleFragmentDoc}
`;
export const ProviderJoinCodeFragmentDoc = gql`
    fragment ProviderJoinCode on ProviderJoinCode {
        id
        code
        isEnabled
        updatedAt
        createdAt
    }
`;
export const ProviderRoleFragmentDoc = gql`
    fragment ProviderRole on ProviderRole {
        id
        title
    }
`;
export const ProviderMemberInviteFragmentDoc = gql`
    fragment ProviderMemberInvite on ProviderMemberInvite {
        id
        provider {
            ...ProviderSimple
        }
        email
        roles {
            ...ProviderRole
        }
        redeemedAt
        expiresAt
        createdAt
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderRoleFragmentDoc}
`;
export const UserFragmentDoc = gql`
    fragment User on User {
        id
        pictureUrl
        fullName
        givenName
        familyName
        educationProvider {
            id
            title
        }
    }
`;
export const ProviderPostReactionFragmentDoc = gql`
    fragment ProviderPostReaction on ProviderPostReaction {
        id
        user {
            ...User
        }
        type
        updatedAt
        createdAt
    }
    ${UserFragmentDoc}
`;
export const ProviderPostReactionTotalCountByTypeFragmentDoc = gql`
    fragment ProviderPostReactionTotalCountByType on ProviderPostReactionTotalCountByType {
        type
        count
    }
`;
export const ProviderPostPollOptionFragmentDoc = gql`
    fragment ProviderPostPollOption on ProviderPostPollOption {
        id
        title
        totalResponsesCount
        totalResponsesCountPercentage
    }
`;
export const ProviderPostFragmentDoc = gql`
    fragment ProviderPost on ProviderPost {
        itemType
        itemId
        itemCreated
        itemUpdated
        content
        countryCodes
        mentorOnly
        mentorStudentOnly
        imageUrl
        postMetadata {
            author
            date
            description
            image
            video
            lang
            logo
            publisher
            title
            url
        }
        provider {
            ...ProviderWithPermissions
        }
        reactionsTotalCountByType {
            ...ProviderPostReactionTotalCountByType
        }
        hasReaction
        myReaction {
            id
            type
        }
        pollOptions {
            ...ProviderPostPollOption
        }
        pollExpiresAt
        selectedPollOption {
            id
            providerPostPollOption {
                ...ProviderPostPollOption
            }
        }
        totalPollResponsesCount
        canViewPollResults
        isPinned
    }
    ${ProviderWithPermissionsFragmentDoc}
    ${ProviderPostReactionTotalCountByTypeFragmentDoc}
    ${ProviderPostPollOptionFragmentDoc}
`;
export const GeoLocationFragmentDoc = gql`
    fragment GeoLocation on GeoLocation {
        id
        name
        displayName
        longitude
        latitude
        street
        city
        county
        state
        country
        countryCode
    }
`;
export const OpportunityListingPostFragmentDoc = gql`
    fragment OpportunityListingPost on OpportunityListingPost {
        itemType
        itemId
        itemCreated
        itemUpdated
        provider {
            ...ProviderSimple
        }
        type
        title
        country
        geoLocation {
            ...GeoLocation
        }
        coverImageUrl
    }
    ${ProviderSimpleFragmentDoc}
    ${GeoLocationFragmentDoc}
`;
export const NewsfeedItemFragmentDoc = gql`
    fragment NewsfeedItem on NewsfeedItem {
        itemType
        itemId
        itemCreated
        itemUpdated
        ... on ProviderPost {
            ...ProviderPost
        }
        ... on OpportunityListingPost {
            ...OpportunityListingPost
        }
    }
    ${ProviderPostFragmentDoc}
    ${OpportunityListingPostFragmentDoc}
`;
export const OpportunityListingFragmentDoc = gql`
    fragment OpportunityListing on OpportunityListing {
        id
        provider {
            ...ProviderSimple
        }
        type
        title
        company
        country
        geoLocation {
            ...GeoLocation
        }
        coverImageId
        coverImageUrl
        jobDescription
        payRateDescription
        workingHoursDescription
        contactEmail
        contactPhone
        contactWebLink
        listingRange
        referenceCode
        expiresAt
        isPinned
        updatedAt
        createdAt
        canUpdate
        canExpire
    }
    ${ProviderSimpleFragmentDoc}
    ${GeoLocationFragmentDoc}
`;
export const GoalSimpleFragmentDoc = gql`
    fragment GoalSimple on Goal {
        id
        goalType
        title
        imageUrl
        imageId
        information
        complete
        dueAt
        color
        updatedAt
        createdAt
    }
`;
export const GoalsDueReminderFragmentDoc = gql`
    fragment GoalsDueReminder on GoalsDueReminder {
        itemType
        itemId
        itemCreated
        itemUpdated
        goals {
            ...GoalSimple
        }
        totalDue
    }
    ${GoalSimpleFragmentDoc}
`;
export const StudentHomeworkDiaryItemFragmentDoc = gql`
    fragment StudentHomeworkDiaryItem on StudentHomeworkDiaryItem {
        id
        date
        information
        imageId
        imageUrl
        color
        completedAt
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
`;
export const StudentHomeworkItemsDueReminderFragmentDoc = gql`
    fragment StudentHomeworkItemsDueReminder on StudentHomeworkItemsDueReminder {
        itemType
        itemId
        itemCreated
        itemUpdated
        studentHomeworkItems {
            ...StudentHomeworkDiaryItem
        }
        totalDue
    }
    ${StudentHomeworkDiaryItemFragmentDoc}
`;
export const SponsorPostFragmentDoc = gql`
    fragment SponsorPost on SponsorPost {
        itemType
        itemId
        itemCreated
        itemUpdated
        sponsorCampaignId
        sponsorProviderTitle
        sponsorProviderLogoImageUrl
        title
        content
        targetUrl
        mediaImageUrl
        mediaVideoUrl
    }
`;
export const CurriculumVitaePersonalDetailsFragmentDoc = gql`
    fragment CurriculumVitaePersonalDetails on CurriculumVitaePersonalDetails {
        givenName
        familyName
        dob
        languageLocales
        interests
        driversLicenceType
        phoneNumber
        email
        street
        suburb
        city
        countryCode
    }
`;
export const CurriculumVitaePersonalStatementFragmentDoc = gql`
    fragment CurriculumVitaePersonalStatement on CurriculumVitaePersonalStatement {
        description
    }
`;
export const CurriculumVitaeEmploymentEntryFragmentDoc = gql`
    fragment CurriculumVitaeEmploymentEntry on CurriculumVitaeEmploymentEntry {
        title
        company
        start
        end
        description
    }
`;
export const CurriculumVitaeEmploymentFragmentDoc = gql`
    fragment CurriculumVitaeEmployment on CurriculumVitaeEmployment {
        entries {
            ...CurriculumVitaeEmploymentEntry
        }
    }
    ${CurriculumVitaeEmploymentEntryFragmentDoc}
`;
export const CurriculumVitaeVolunteerEntryFragmentDoc = gql`
    fragment CurriculumVitaeVolunteerEntry on CurriculumVitaeVolunteerEntry {
        title
        company
        start
        end
        description
    }
`;
export const CurriculumVitaeVolunteerFragmentDoc = gql`
    fragment CurriculumVitaeVolunteer on CurriculumVitaeVolunteer {
        entries {
            ...CurriculumVitaeVolunteerEntry
        }
    }
    ${CurriculumVitaeVolunteerEntryFragmentDoc}
`;
export const CurriculumVitaeEducationEntryFragmentDoc = gql`
    fragment CurriculumVitaeEducationEntry on CurriculumVitaeEducationEntry {
        title
        school
        year
        subjects
    }
`;
export const CurriculumVitaeEducationFragmentDoc = gql`
    fragment CurriculumVitaeEducation on CurriculumVitaeEducation {
        entries {
            ...CurriculumVitaeEducationEntry
        }
    }
    ${CurriculumVitaeEducationEntryFragmentDoc}
`;
export const CurriculumVitaeSkillsFragmentDoc = gql`
    fragment CurriculumVitaeSkills on CurriculumVitaeSkills {
        description
    }
`;
export const CurriculumVitaePersonalAttributesFragmentDoc = gql`
    fragment CurriculumVitaePersonalAttributes on CurriculumVitaePersonalAttributes {
        attributes
    }
`;
export const CurriculumVitaeAchievementsAndAwardsEntryFragmentDoc = gql`
    fragment CurriculumVitaeAchievementsAndAwardsEntry on CurriculumVitaeAchievementsAndAwardsEntry {
        description
        year
    }
`;
export const CurriculumVitaeAchievementsAndAwardsFragmentDoc = gql`
    fragment CurriculumVitaeAchievementsAndAwards on CurriculumVitaeAchievementsAndAwards {
        entries {
            ...CurriculumVitaeAchievementsAndAwardsEntry
        }
    }
    ${CurriculumVitaeAchievementsAndAwardsEntryFragmentDoc}
`;
export const BadgeFragmentDoc = gql`
    fragment Badge on Badge {
        id
        user {
            ...User
        }
        title
        comment
        badgeImageUrl
        provider {
            ...ProviderSimple
        }
        createdBy {
            ...User
        }
        updatedAt
        createdAt
        canDelete
    }
    ${UserFragmentDoc}
    ${ProviderSimpleFragmentDoc}
`;
export const CurriculumVitaeBadgesFragmentDoc = gql`
    fragment CurriculumVitaeBadges on CurriculumVitaeBadges {
        selectedBadgesList {
            ...Badge
        }
    }
    ${BadgeFragmentDoc}
`;
export const ResourceHubItemFragmentDoc = gql`
    fragment ResourceHubItem on ResourceHubItem {
        id
        user {
            ...User
        }
        title
        category
        type
        uses
        fileId
        fileAvailable
        fileName
        fileMimeType
        fileUrl
        filePreviewUrl
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${UserFragmentDoc}
`;
export const CurriculumVitaeResourceHubItemsFragmentDoc = gql`
    fragment CurriculumVitaeResourceHubItems on CurriculumVitaeResourceHubItems {
        selectedResourceHubItemsList {
            ...ResourceHubItem
        }
    }
    ${ResourceHubItemFragmentDoc}
`;
export const CurriculumVitaeExtracurricularActivitiesEntryFragmentDoc = gql`
    fragment CurriculumVitaeExtracurricularActivitiesEntry on CurriculumVitaeExtracurricularActivitiesEntry {
        description
        year
        toYear
    }
`;
export const CurriculumVitaeExtracurricularActivitiesFragmentDoc = gql`
    fragment CurriculumVitaeExtracurricularActivities on CurriculumVitaeExtracurricularActivities {
        entries {
            ...CurriculumVitaeExtracurricularActivitiesEntry
        }
    }
    ${CurriculumVitaeExtracurricularActivitiesEntryFragmentDoc}
`;
export const CurriculumVitaeReferenceEntryFragmentDoc = gql`
    fragment CurriculumVitaeReferenceEntry on CurriculumVitaeReferenceEntry {
        name
        company
        position
        email
        phoneNumber
    }
`;
export const CurriculumVitaeReferencesFragmentDoc = gql`
    fragment CurriculumVitaeReferences on CurriculumVitaeReferences {
        entries {
            ...CurriculumVitaeReferenceEntry
        }
    }
    ${CurriculumVitaeReferenceEntryFragmentDoc}
`;
export const CurriculumVitaeFragmentDoc = gql`
    fragment CurriculumVitae on CurriculumVitae {
        id
        profileImageId
        profileImageUrl
        personalDetails {
            ...CurriculumVitaePersonalDetails
        }
        personalStatement {
            ...CurriculumVitaePersonalStatement
        }
        employment {
            ...CurriculumVitaeEmployment
        }
        volunteer {
            ...CurriculumVitaeVolunteer
        }
        education {
            ...CurriculumVitaeEducation
        }
        skills {
            ...CurriculumVitaeSkills
        }
        personalAttributes {
            ...CurriculumVitaePersonalAttributes
        }
        achievementsAndAwards {
            ...CurriculumVitaeAchievementsAndAwards
        }
        badges {
            ...CurriculumVitaeBadges
        }
        resourceHubItems {
            ...CurriculumVitaeResourceHubItems
        }
        extracurricularActivities {
            ...CurriculumVitaeExtracurricularActivities
        }
        references {
            ...CurriculumVitaeReferences
        }
        generatedFiles {
            id
            fileId
            fileUrl
            generatedAt
        }
        updatedAt
        createdAt
    }
    ${CurriculumVitaePersonalDetailsFragmentDoc}
    ${CurriculumVitaePersonalStatementFragmentDoc}
    ${CurriculumVitaeEmploymentFragmentDoc}
    ${CurriculumVitaeVolunteerFragmentDoc}
    ${CurriculumVitaeEducationFragmentDoc}
    ${CurriculumVitaeSkillsFragmentDoc}
    ${CurriculumVitaePersonalAttributesFragmentDoc}
    ${CurriculumVitaeAchievementsAndAwardsFragmentDoc}
    ${CurriculumVitaeBadgesFragmentDoc}
    ${CurriculumVitaeResourceHubItemsFragmentDoc}
    ${CurriculumVitaeExtracurricularActivitiesFragmentDoc}
    ${CurriculumVitaeReferencesFragmentDoc}
`;
export const JobProfileFragmentDoc = gql`
    fragment JobProfile on JobProfile {
        id
        curriculumVitaeCompletedSections
        preferredJobCategories
        preferredJobGeoLocations {
            ...GeoLocation
        }
        preferredJobTypes
        preferredJobAvailability
        canEnable
        enabledAt
    }
    ${GeoLocationFragmentDoc}
`;
export const JobProfileActivityFragmentDoc = gql`
    fragment JobProfileActivity on JobProfileActivity {
        id
        activityType
        actorProvider {
            id
            title
        }
        actorProviderTitle
        actor {
            id
            pictureUrl
            fullName
        }
        actorFullName
        updatedAt
        createdAt
    }
`;
export const StudentJobProfileFragmentDoc = gql`
    fragment StudentJobProfile on StudentJobProfile {
        id
        givenName
        age
        profileImageUrl
        languageLocales
        interests
        driversLicenceType
        preferredJobAvailability
        preferredJobTypes
        preferredJobGeoLocations {
            id
            name
        }
        preferredJobCategories
        personalStatement {
            ...CurriculumVitaePersonalStatement
        }
        employment {
            ...CurriculumVitaeEmployment
        }
        volunteer {
            ...CurriculumVitaeVolunteer
        }
        education {
            ...CurriculumVitaeEducation
        }
        skills {
            ...CurriculumVitaeSkills
        }
        personalAttributes {
            ...CurriculumVitaePersonalAttributes
        }
        achievementsAndAwards {
            ...CurriculumVitaeAchievementsAndAwards
        }
        extracurricularActivities {
            ...CurriculumVitaeExtracurricularActivities
        }
        references {
            ...CurriculumVitaeReferences
        }
        isFavourite
        hasContacted
        lastContactedAt
    }
    ${CurriculumVitaePersonalStatementFragmentDoc}
    ${CurriculumVitaeEmploymentFragmentDoc}
    ${CurriculumVitaeVolunteerFragmentDoc}
    ${CurriculumVitaeEducationFragmentDoc}
    ${CurriculumVitaeSkillsFragmentDoc}
    ${CurriculumVitaePersonalAttributesFragmentDoc}
    ${CurriculumVitaeAchievementsAndAwardsFragmentDoc}
    ${CurriculumVitaeExtracurricularActivitiesFragmentDoc}
    ${CurriculumVitaeReferencesFragmentDoc}
`;
export const StudentJobProfileSummaryFragmentDoc = gql`
    fragment StudentJobProfileSummary on StudentJobProfileSearchResultItem {
        id
        givenName
        age
        profileImageUrl
        preferredJobCategories
        preferredJobGeoLocations {
            ...GeoLocation
        }
        preferredJobTypes
        isFavourite
        hasContacted
    }
    ${GeoLocationFragmentDoc}
`;
export const GoalTaskFragmentDoc = gql`
    fragment GoalTask on GoalTask {
        id
        information
        complete
        updatedAt
        createdAt
    }
`;
export const GoalNoteFragmentDoc = gql`
    fragment GoalNote on GoalNote {
        id
        information
        updatedAt
        createdAt
    }
`;
export const GoalFragmentDoc = gql`
    fragment Goal on Goal {
        id
        goalType
        title
        imageUrl
        imageId
        information
        complete
        dueAt
        color
        tasks {
            ...GoalTask
        }
        notes {
            ...GoalNote
        }
        updatedAt
        createdAt
        canUpdate
        canDelete
        canCreateTask
        canCreateNote
    }
    ${GoalTaskFragmentDoc}
    ${GoalNoteFragmentDoc}
`;
export const PathwayPlannerUsefulExperienceTodoFragmentDoc = gql`
    fragment PathwayPlannerUsefulExperienceTodo on PathwayPlannerUsefulExperienceTodo {
        id
        completed
        title
    }
`;
export const PathwayPlannerQualificationTodoFragmentDoc = gql`
    fragment PathwayPlannerQualificationTodo on PathwayPlannerQualificationTodo {
        id
        completed
        title
    }
`;
export const PathwayPlannerPersonalRequirementTodoFragmentDoc = gql`
    fragment PathwayPlannerPersonalRequirementTodo on PathwayPlannerPersonalRequirementTodo {
        id
        completed
        title
    }
`;
export const PathwayPlannerSkillAndKnowledgeTodoFragmentDoc = gql`
    fragment PathwayPlannerSkillAndKnowledgeTodo on PathwayPlannerSkillAndKnowledgeTodo {
        id
        completed
        title
    }
`;
export const PathwayPlannerStageUsefulExperienceTodoFragmentDoc = gql`
    fragment PathwayPlannerStageUsefulExperienceTodo on PathwayPlannerStageUsefulExperienceTodo {
        id
        completed
        title
    }
`;
export const PathwayPlannerStageSubjectTodoFragmentDoc = gql`
    fragment PathwayPlannerStageSubjectTodo on PathwayPlannerStageSubjectTodo {
        id
        completed
        title
    }
`;
export const PathwayPlannerStageFragmentDoc = gql`
    fragment PathwayPlannerStage on PathwayPlannerStage {
        id
        title
        usefulExperienceList {
            ...PathwayPlannerStageUsefulExperienceTodo
        }
        subjectsList {
            ...PathwayPlannerStageSubjectTodo
        }
    }
    ${PathwayPlannerStageUsefulExperienceTodoFragmentDoc}
    ${PathwayPlannerStageSubjectTodoFragmentDoc}
`;
export const PathwayPlannerFragmentDoc = gql`
    fragment PathwayPlanner on PathwayPlanner {
        id
        user {
            ...User
        }
        title
        usefulExperienceList {
            ...PathwayPlannerUsefulExperienceTodo
        }
        qualificationsList {
            ...PathwayPlannerQualificationTodo
        }
        personalRequirementsList {
            ...PathwayPlannerPersonalRequirementTodo
        }
        skillsAndKnowledgeList {
            ...PathwayPlannerSkillAndKnowledgeTodo
        }
        stagesList {
            ...PathwayPlannerStage
        }
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${UserFragmentDoc}
    ${PathwayPlannerUsefulExperienceTodoFragmentDoc}
    ${PathwayPlannerQualificationTodoFragmentDoc}
    ${PathwayPlannerPersonalRequirementTodoFragmentDoc}
    ${PathwayPlannerSkillAndKnowledgeTodoFragmentDoc}
    ${PathwayPlannerStageFragmentDoc}
`;
export const ReflectionFragmentDoc = gql`
    fragment Reflection on Reflection {
        id
        reflectionType
        information
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
`;
export const StudyProviderForSuperadminFragmentDoc = gql`
    fragment StudyProviderForSuperadmin on StudyProvider {
        id
        countryCode
        title
        logoImageUrl
        logoImageId
        email
        isEnquiriesEnabled
        isEnrolmentEnabled
        enrolmentTypesEnabled
        updatedAt
        createdAt
    }
`;
export const StudyCategoryFragmentDoc = gql`
    fragment StudyCategory on StudyCategory {
        id
        title
        updatedAt
        createdAt
    }
`;
export const StudyProviderFragmentDoc = gql`
    fragment StudyProvider on StudyProvider {
        id
        countryCode
        title
        logoImageUrl
        isEnquiriesEnabled
        isEnrolmentEnabled
        enrolmentTypesEnabled
        updatedAt
        createdAt
    }
`;
export const StudyProgrammeFragmentDoc = gql`
    fragment StudyProgramme on StudyProgramme {
        id
        studyProgrammeType
        title
        link
        information
        hasSavedStudyProgramme
        studyCountryCodes
        studyCategory {
            ...StudyCategory
        }
        studyProvider {
            ...StudyProvider
        }
        updatedAt
        createdAt
        canEnquireStudyProgramme
        canEnrolInStudyProgramme
    }
    ${StudyCategoryFragmentDoc}
    ${StudyProviderFragmentDoc}
`;
export const SavedStudyProgrammeFragmentDoc = gql`
    fragment SavedStudyProgramme on StudyProgramme {
        id
        studyProvider {
            ...StudyProvider
        }
        title
        link
    }
    ${StudyProviderFragmentDoc}
`;
export const ThreeGoodThingsFragmentDoc = gql`
    fragment ThreeGoodThings on ThreeGoodThings {
        id
        date
        firstItem
        secondItem
        thirdItem
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
`;
export const LessonCategoryFragmentDoc = gql`
    fragment LessonCategory on LessonCategory {
        id
        locale
        title
        parent {
            id
            locale
            title
        }
        canUpdate
        canDelete
    }
`;
export const LessonCategoryLocalizationFragmentDoc = gql`
    fragment LessonCategoryLocalization on LessonCategoryLocalization {
        locale
        title
        canUpdate
        canDelete
    }
`;
export const LessonCategoryWithLocalizationsFragmentDoc = gql`
    fragment LessonCategoryWithLocalizations on LessonCategory {
        ...LessonCategory
        defaultLocale
        localizations {
            ...LessonCategoryLocalization
        }
    }
    ${LessonCategoryFragmentDoc}
    ${LessonCategoryLocalizationFragmentDoc}
`;
export const LessonFragmentDoc = gql`
    fragment Lesson on Lesson {
        id
        lessonCategory {
            ...LessonCategory
        }
        locale
        title
        description
        coverImageUrl
        documentId
        documentIframeUrl
        tags
        isFeatured
        countryCodes
        favoriteCount
        hasRead
        hasFavorited
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${LessonCategoryFragmentDoc}
`;
export const LessonLocalizationFragmentDoc = gql`
    fragment LessonLocalization on LessonLocalization {
        locale
        title
        description
        coverImageId
        coverImageUrl
        documentId
        tags
        canUpdate
        canDelete
    }
`;
export const LessonWithLocalizationsFragmentDoc = gql`
    fragment LessonWithLocalizations on Lesson {
        ...Lesson
        defaultLocale
        localizations {
            ...LessonLocalization
        }
    }
    ${LessonFragmentDoc}
    ${LessonLocalizationFragmentDoc}
`;
export const LessonSearchResultFragmentDoc = gql`
    fragment LessonSearchResult on Lesson {
        ...Lesson
    }
    ${LessonFragmentDoc}
`;
export const MentorGroupDiscussionUserStateFragmentDoc = gql`
    fragment MentorGroupDiscussionUserState on MentorGroupDiscussionUserState {
        lastRead
        lastTyping
        unreadMessageCount
        mutedAt
    }
`;
export const MentorGroupWithDiscussionUserStateFragmentDoc = gql`
    fragment MentorGroupWithDiscussionUserState on MentorGroup {
        ...MentorGroupSimple
        discussionUserState {
            ...MentorGroupDiscussionUserState
        }
    }
    ${MentorGroupSimpleFragmentDoc}
    ${MentorGroupDiscussionUserStateFragmentDoc}
`;
export const MentorGroupDiscussionMessagePollOptionFragmentDoc = gql`
    fragment MentorGroupDiscussionMessagePollOption on MentorGroupDiscussionMessagePollOption {
        id
        option
        totalResponsesCount
        totalResponsesCountPercentage
    }
`;
export const MentorGroupDiscussionMessageAcknowledgementStatusFragmentDoc = gql`
    fragment MentorGroupDiscussionMessageAcknowledgementStatus on MentorGroupDiscussionMessageAcknowledgementStatus {
        id
        user {
            ...User
        }
        acknowledged
        acknowledgedAt
        updatedAt
        createdAt
    }
    ${UserFragmentDoc}
`;
export const MentorGroupDiscussionMessageFragmentDoc = gql`
    fragment MentorGroupDiscussionMessage on MentorGroupDiscussionMessage {
        id
        message
        messageLinks
        imageAvailable
        imageId
        imageUrl
        lesson {
            ...Lesson
        }
        isPinned
        createdBy {
            ...User
        }
        reactions {
            totalCount
            topEmojis
        }
        myReaction {
            id
            type
        }
        pollOptions {
            ...MentorGroupDiscussionMessagePollOption
        }
        pollExpiresAt
        selectedPollOption {
            id
            mentorGroupDiscussionMessagePollOption {
                ...MentorGroupDiscussionMessagePollOption
            }
        }
        totalPollResponsesCount
        canViewPollResults
        requiresAcknowledgement
        acknowledgementStatuses {
            totalCount
            totalAcknowledgedCount
        }
        myAcknowledgementStatus {
            ...MentorGroupDiscussionMessageAcknowledgementStatus
        }
        updatedAt
        createdAt
        canReact
        canPin
        canAcknowledge
        canDelete
    }
    ${LessonFragmentDoc}
    ${UserFragmentDoc}
    ${MentorGroupDiscussionMessagePollOptionFragmentDoc}
    ${MentorGroupDiscussionMessageAcknowledgementStatusFragmentDoc}
`;
export const MentorGroupForDiscussionFragmentDoc = gql`
    fragment MentorGroupForDiscussion on MentorGroup {
        ...MentorGroupSimple
        isDiscussionLocked
        discussionUserState {
            ...MentorGroupDiscussionUserState
        }
        discussionMessagesPinned {
            ...MentorGroupDiscussionMessage
        }
        canLockDiscussion
        canSendDiscussionMessage
        canMuteDiscussionUser
        canCreateDiscussionPoll
        canRequireDiscussionAcknowledgement
    }
    ${MentorGroupSimpleFragmentDoc}
    ${MentorGroupDiscussionUserStateFragmentDoc}
    ${MentorGroupDiscussionMessageFragmentDoc}
`;
export const MentorGroupForJoiningFragmentDoc = gql`
    fragment MentorGroupForJoining on MentorGroup {
        ...MentorGroupSimple
        hasJoinedAsStudent
    }
    ${MentorGroupSimpleFragmentDoc}
`;
export const MentorGroupDetailedFragmentDoc = gql`
    fragment MentorGroupDetailed on MentorGroup {
        id
        title
        description
        bannerImageUrl
        information
        provider {
            ...ProviderSimple
        }
        discussionUserState {
            ...MentorGroupDiscussionUserState
        }
        inviteCode
        hasPinned
        pinnedAt
        isJoinable
        isPublic
        updatedAt
        createdAt
        canAddMember
        canSendDiscussionMessage
        canRegenerateInviteCode
        canUpdate
        canDelete
        canAddMentorStudent
        canCreateDiscussionPoll
    }
    ${ProviderSimpleFragmentDoc}
    ${MentorGroupDiscussionUserStateFragmentDoc}
`;
export const JoinableMentorGroupFragmentDoc = gql`
    fragment JoinableMentorGroup on JoinableMentorGroup {
        id
        title
        description
        bannerImageUrl
        provider {
            ...ProviderSimple
        }
        totalStudentCount
        hasConsent
        hasJoined
    }
    ${ProviderSimpleFragmentDoc}
`;
export const JoinableMentorGroupSummaryFragmentDoc = gql`
    fragment JoinableMentorGroupSummary on JoinableMentorGroup {
        ...JoinableMentorGroup
        members(first: 1) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...User
                }
            }
        }
    }
    ${JoinableMentorGroupFragmentDoc}
    ${PageInfoFragmentDoc}
    ${UserFragmentDoc}
`;
export const ProviderMemberFragmentDoc = gql`
    fragment ProviderMember on ProviderMember {
        id
        user {
            ...User
        }
        roles {
            ...ProviderRole
        }
        canDelete
        updatedAt
        createdAt
    }
    ${UserFragmentDoc}
    ${ProviderRoleFragmentDoc}
`;
export const MentorGroupMemberFragmentDoc = gql`
    fragment MentorGroupMember on MentorGroupMember {
        id
        providerMember {
            ...ProviderMember
        }
        canRemove
    }
    ${ProviderMemberFragmentDoc}
`;
export const MentorGroupDiscussionMessagePollResponseFragmentDoc = gql`
    fragment MentorGroupDiscussionMessagePollResponse on MentorGroupDiscussionMessagePollResponse {
        id
        mentorGroupDiscussionMessagePollOption {
            ...MentorGroupDiscussionMessagePollOption
        }
        updatedAt
        createdAt
    }
    ${MentorGroupDiscussionMessagePollOptionFragmentDoc}
`;
export const MentorGroupWithDiscussionMutedUsersFragmentDoc = gql`
    fragment MentorGroupWithDiscussionMutedUsers on MentorGroup {
        mentorGroupDiscussionMutedUsers {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...User
                }
                cursor
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${UserFragmentDoc}
`;
export const LearnerSmsProfileSimpleFragmentDoc = gql`
    fragment LearnerSmsProfileSimple on LearnerSmsProfile {
        id
        pictureUrl
        preferredGivenName
        preferredFamilyName
        preferredFullName
        email
        idNumber
        nationalCurriculumYear
    }
`;
export const MentorStudentSimpleFragmentDoc = gql`
    fragment MentorStudentSimple on MentorStudent {
        id
        user {
            ...User
        }
        learnerSmsProfile {
            ...LearnerSmsProfileSimple
        }
    }
    ${UserFragmentDoc}
    ${LearnerSmsProfileSimpleFragmentDoc}
`;
export const MentorGroupStudentSimpleFragmentDoc = gql`
    fragment MentorGroupStudentSimple on MentorGroupStudent {
        id
        mentorStudent {
            ...MentorStudentSimple
        }
        mentorGroup {
            id
            title
        }
    }
    ${MentorStudentSimpleFragmentDoc}
`;
export const LearnerSmsProfileDetailedFragmentDoc = gql`
    fragment LearnerSmsProfileDetailed on LearnerSmsProfile {
        id
        provider {
            ...ProviderSimple
        }
        pictureUrl
        givenName
        familyName
        fullName
        preferredGivenName
        preferredFamilyName
        preferredFullName
        email
        idNumber
        barcodeNumber
        nationalCurriculumYear
        uniqueStudentNumber
        mobile
        isAssessmentResultsAvailable
        isAttendanceAvailable
        isDailyNoticesAvailable
        isTimetableAvailable
    }
    ${ProviderSimpleFragmentDoc}
`;
export const MentorStudentNoteFragmentDoc = gql`
    fragment MentorStudentNote on MentorStudentNote {
        id
        mentorGroup {
            id
            title
        }
        information
        isShared
        updatedAt
        createdAt
        createdBy {
            ...User
        }
        canUpdate
        canDelete
    }
    ${UserFragmentDoc}
`;
export const MentorStudentTodoFragmentDoc = gql`
    fragment MentorStudentTodo on MentorStudentTodo {
        id
        mentorGroup {
            id
            title
        }
        information
        isShared
        complete
        updatedAt
        createdAt
        createdBy {
            ...User
        }
        canUpdate
        canDelete
    }
    ${UserFragmentDoc}
`;
export const MentorStudentSummaryFragmentDoc = gql`
    fragment MentorStudentSummary on MentorStudent {
        id
        user {
            ...User
        }
        learnerSmsProfile {
            ...LearnerSmsProfileDetailed
        }
        provider {
            ...ProviderSimple
        }
        isAssessmentResultsAvailable
        isAttendanceAvailable
        isDailyNoticesAvailable
        isTimetableAvailable
        isSharingCurriculumVitae
        isSharingGoals
        isSharingPathwayPlanners
        isSharingReflections
        isSharingResourceHubItems
        isSharingSavedCourses
        sharedActivity
        isSharingOtherProviderActivity
        badges {
            totalCount
            lastCreatedAt
        }
        curriculumVitae {
            id
            updatedAt
        }
        jobProfile {
            id
            enabledAt
        }
        goals {
            totalCount
            lastUpdatedAt
        }
        reflections {
            totalCount
            lastUpdatedAt
        }
        resourceHubItems {
            totalCount
            lastUpdatedAt
        }
        savedStudyProgrammes {
            totalCount
            lastSavedAt
        }
        pathwayPlanners {
            totalCount
            lastUpdatedAt
        }
        notes(first: 5, mentorGroupId: $mentorGroupId) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...MentorStudentNote
                }
                cursor
            }
        }
        todos(first: 5, mentorGroupId: $mentorGroupId) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...MentorStudentTodo
                }
                cursor
            }
        }
        projects(first: 5) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    id
                    title
                    updatedAt
                }
                cursor
            }
        }
        mentorGroups {
            id
            title
        }
        availableMentorGroups {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...MentorGroupSimple
                }
                cursor
            }
        }
        canRemove
    }
    ${UserFragmentDoc}
    ${LearnerSmsProfileDetailedFragmentDoc}
    ${ProviderSimpleFragmentDoc}
    ${PageInfoFragmentDoc}
    ${MentorStudentNoteFragmentDoc}
    ${MentorStudentTodoFragmentDoc}
    ${MentorGroupSimpleFragmentDoc}
`;
export const MentorStudentForMySchoolFragmentDoc = gql`
    fragment MentorStudentForMySchool on MentorStudent {
        ...MentorStudentSimple
        provider {
            ...ProviderSimple
        }
        isAssessmentResultsAvailable
        isAttendanceAvailable
        isDailyNoticesAvailable
        isTimetableAvailable
    }
    ${MentorStudentSimpleFragmentDoc}
    ${ProviderSimpleFragmentDoc}
`;
export const MentorStudentGroupsFragmentDoc = gql`
    fragment MentorStudentGroups on MentorStudent {
        id
        mentorGroups {
            id
            title
        }
    }
`;
export const MentorSharedResourceFragmentDoc = gql`
    fragment MentorSharedResource on MentorSharedResource {
        id
        provider {
            ...ProviderSimple
        }
        sharedResourceType
        information
        updatedAt
        createdAt
        createdBy {
            ...User
        }
        canUpdate
        canDelete
    }
    ${ProviderSimpleFragmentDoc}
    ${UserFragmentDoc}
`;
export const MentorRewardItemFragmentDoc = gql`
    fragment MentorRewardItem on MentorRewardItem {
        id
        provider {
            ...ProviderSimple
        }
        title
        points
        description
        imageId
        imageUrl
        updatedAt
        createdAt
    }
    ${ProviderSimpleFragmentDoc}
`;
export const MentorRewardValueFragmentDoc = gql`
    fragment MentorRewardValue on MentorRewardValue {
        id
        provider {
            ...ProviderSimple
        }
        title
        color
        worth
        updatedAt
        createdAt
    }
    ${ProviderSimpleFragmentDoc}
`;
export const MentorRewardStudentValuePointsFragmentDoc = gql`
    fragment MentorRewardStudentValuePoints on MentorRewardStudentValuePoints {
        mentorRewardValue {
            ...MentorRewardValue
        }
        totalPoints
    }
    ${MentorRewardValueFragmentDoc}
`;
export const MentorRewardEventFragmentDoc = gql`
    fragment MentorRewardEvent on MentorRewardEvent {
        id
        provider {
            ...ProviderSimple
        }
        title
        description
        updatedAt
        createdAt
    }
    ${ProviderSimpleFragmentDoc}
`;
export const MentorRewardStudentEventPointsFragmentDoc = gql`
    fragment MentorRewardStudentEventPoints on MentorRewardStudentEventPoints {
        mentorRewardEvent {
            ...MentorRewardEvent
        }
        totalPoints
    }
    ${MentorRewardEventFragmentDoc}
`;
export const MentorRewardStudentDetailedFragmentDoc = gql`
    fragment MentorRewardStudentDetailed on MentorRewardStudent {
        id
        provider {
            ...ProviderSimple
        }
        user {
            ...User
        }
        learnerSmsProfile {
            ...LearnerSmsProfileSimple
        }
        availablePoints
        totalPoints
        totalPointsByValue {
            ...MentorRewardStudentValuePoints
        }
        totalPointsByEvent {
            ...MentorRewardStudentEventPoints
        }
        canGiveReward
        canConsumeReward
    }
    ${ProviderSimpleFragmentDoc}
    ${UserFragmentDoc}
    ${LearnerSmsProfileSimpleFragmentDoc}
    ${MentorRewardStudentValuePointsFragmentDoc}
    ${MentorRewardStudentEventPointsFragmentDoc}
`;
export const MentorRewardStudentForMentorGroupFragmentDoc = gql`
    fragment MentorRewardStudentForMentorGroup on MentorRewardStudent {
        id
        provider {
            ...ProviderSimple
        }
        user {
            ...User
        }
        learnerSmsProfile {
            ...LearnerSmsProfileSimple
        }
        availablePoints
        totalPoints
        totalPointsByValue {
            ...MentorRewardStudentValuePoints
        }
        totalPointsByEvent {
            ...MentorRewardStudentEventPoints
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${UserFragmentDoc}
    ${LearnerSmsProfileSimpleFragmentDoc}
    ${MentorRewardStudentValuePointsFragmentDoc}
    ${MentorRewardStudentEventPointsFragmentDoc}
`;
export const MentorRewardStudentPointsSummaryRangeFragmentDoc = gql`
    fragment MentorRewardStudentPointsSummaryRange on MentorRewardStudentPointsSummaryRange {
        totalPoints
        totalPointsByValue {
            ...MentorRewardStudentValuePoints
        }
        totalPointsByEvent {
            ...MentorRewardStudentEventPoints
        }
    }
    ${MentorRewardStudentValuePointsFragmentDoc}
    ${MentorRewardStudentEventPointsFragmentDoc}
`;
export const MentorRewardTransactionFragmentDoc = gql`
    fragment MentorRewardTransaction on MentorRewardTransaction {
        id
        mentorRewardStudent {
            id
        }
        type
        mentorRewardValue {
            ...MentorRewardValue
        }
        mentorRewardEvent {
            ...MentorRewardEvent
        }
        comment
        points
        mentorGroupTitle
        createdBy {
            ...User
        }
        updatedAt
        createdAt
    }
    ${MentorRewardValueFragmentDoc}
    ${MentorRewardEventFragmentDoc}
    ${UserFragmentDoc}
`;
export const PointsSummaryByTypeFragmentDoc = gql`
    fragment PointsSummaryByType on PointsSummaryByType {
        givenPoints
        consumedPoints
    }
`;
export const PointsYearSummaryByTypeFragmentDoc = gql`
    fragment PointsYearSummaryByType on PointsYearSummaryByType {
        month
        givenPoints
        consumedPoints
    }
`;
export const MentorRewardTopMentorFragmentDoc = gql`
    fragment MentorRewardTopMentor on MentorRewardTopMentor {
        user {
            ...User
        }
        points
        rank
    }
    ${UserFragmentDoc}
`;
export const MentorRewardStudentSimpleFragmentDoc = gql`
    fragment MentorRewardStudentSimple on MentorRewardStudent {
        id
        user {
            ...User
        }
        learnerSmsProfile {
            ...LearnerSmsProfileSimple
        }
        availablePoints
        totalPoints
    }
    ${UserFragmentDoc}
    ${LearnerSmsProfileSimpleFragmentDoc}
`;
export const MentorRewardTopLearnerFragmentDoc = gql`
    fragment MentorRewardTopLearner on MentorRewardTopLearner {
        rewardStudent {
            ...MentorRewardStudentSimple
        }
        points
        pointsByValue {
            ...MentorRewardStudentValuePoints
        }
        pointsByOther
        rank
    }
    ${MentorRewardStudentSimpleFragmentDoc}
    ${MentorRewardStudentValuePointsFragmentDoc}
`;
export const MentorRewardTopMentorGroupFragmentDoc = gql`
    fragment MentorRewardTopMentorGroup on MentorRewardTopGroup {
        group {
            ...MentorGroupSimple
        }
        points
        pointsByValue {
            ...MentorRewardStudentValuePoints
        }
        pointsByOther
        rank
    }
    ${MentorGroupSimpleFragmentDoc}
    ${MentorRewardStudentValuePointsFragmentDoc}
`;
export const ProviderProjectTemplateSimpleFragmentDoc = gql`
    fragment ProviderProjectTemplateSimple on ProviderProjectTemplate {
        id
        provider {
            ...ProviderSimple
        }
        title
        bannerImageUrl
        bannerImageId
        sections {
            id
        }
        updatedBy {
            ...User
        }
        createdBy {
            ...User
        }
        updatedAt
        createdAt
        isPublic
        canUpdate
        canDelete
    }
    ${ProviderSimpleFragmentDoc}
    ${UserFragmentDoc}
`;
export const ProviderProjectTemplateOverviewResourceFragmentDoc = gql`
    fragment ProviderProjectTemplateOverviewResource on ProviderProjectTemplateOverviewResource {
        id
        url
        lesson {
            ...Lesson
        }
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${LessonFragmentDoc}
`;
export const ProviderProjectTemplateSectionResourceFragmentDoc = gql`
    fragment ProviderProjectTemplateSectionResource on ProviderProjectTemplateSectionResource {
        id
        url
        lesson {
            ...Lesson
        }
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${LessonFragmentDoc}
`;
export const ProviderProjectTemplateSectionFragmentDoc = gql`
    fragment ProviderProjectTemplateSection on ProviderProjectTemplateSection {
        id
        sectionOrder
        title
        color
        description
        resources {
            ...ProviderProjectTemplateSectionResource
        }
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${ProviderProjectTemplateSectionResourceFragmentDoc}
`;
export const ProviderProjectTemplateFragmentDoc = gql`
    fragment ProviderProjectTemplate on ProviderProjectTemplate {
        id
        provider {
            ...ProviderSimple
        }
        title
        bannerImageUrl
        bannerImageId
        overviewDescription
        overviewResources {
            ...ProviderProjectTemplateOverviewResource
        }
        sections {
            ...ProviderProjectTemplateSection
        }
        updatedBy {
            ...User
        }
        createdBy {
            ...User
        }
        updatedAt
        createdAt
        isPublic
        canUpdate
        canDelete
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderProjectTemplateOverviewResourceFragmentDoc}
    ${ProviderProjectTemplateSectionFragmentDoc}
    ${UserFragmentDoc}
`;
export const ProjectMentorGroupFragmentDoc = gql`
    fragment ProjectMentorGroup on MentorGroup {
        id
        title
    }
`;
export const ProjectSimpleFragmentDoc = gql`
    fragment ProjectSimple on Project {
        id
        user {
            ...User
        }
        provider {
            ...ProviderSimple
        }
        mentorGroup {
            ...ProjectMentorGroup
        }
        title
        bannerImageUrl
        bannerImageId
        updatedAt
        createdAt
        totalCompletedProjectSections
        totalProjectSections
        latestReviewRequestedAt
        completedAt
        updatedAt
        canUpdateMentorGroup
        canDelete
        createdBy {
            ...User
        }
        isLocked
    }
    ${UserFragmentDoc}
    ${ProviderSimpleFragmentDoc}
    ${ProjectMentorGroupFragmentDoc}
`;
export const ProjectOverviewResourceFragmentDoc = gql`
    fragment ProjectOverviewResource on ProjectOverviewResource {
        id
        url
        lesson {
            ...Lesson
        }
        updatedAt
        createdAt
    }
    ${LessonFragmentDoc}
`;
export const ProjectSectionResourceFragmentDoc = gql`
    fragment ProjectSectionResource on ProjectSectionResource {
        id
        url
        lesson {
            ...Lesson
        }
        updatedAt
        createdAt
    }
    ${LessonFragmentDoc}
`;
export const ProjectSectionEvidenceFragmentDoc = gql`
    fragment ProjectSectionEvidence on ProjectSectionEvidence {
        id
        resourceHubItem {
            ...ResourceHubItem
        }
        description
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${ResourceHubItemFragmentDoc}
`;
export const ProjectSectionCommentFragmentDoc = gql`
    fragment ProjectSectionComment on ProjectSectionComment {
        id
        comment
        createdBy {
            ...User
        }
        updatedAt
        createdAt
        canUpdate
        canDelete
    }
    ${UserFragmentDoc}
`;
export const ProjectSectionFragmentDoc = gql`
    fragment ProjectSection on ProjectSection {
        id
        title
        color
        description
        resources {
            ...ProjectSectionResource
        }
        response
        evidences {
            ...ProjectSectionEvidence
        }
        isComplete
        isReviewComplete
        isReviewRequested
        completedAt
        reviewCompletedAt
        reviewRequestedAt
        comments {
            ...ProjectSectionComment
        }
        updatedAt
        createdAt
        canUpdate
        canRequestReview
        canCompleteReview
        canCompleteSection
        canCreateComment
    }
    ${ProjectSectionResourceFragmentDoc}
    ${ProjectSectionEvidenceFragmentDoc}
    ${ProjectSectionCommentFragmentDoc}
`;
export const ProjectDetailedFragmentDoc = gql`
    fragment ProjectDetailed on Project {
        id
        user {
            ...User
        }
        provider {
            ...ProviderSimple
        }
        mentorGroup {
            ...ProjectMentorGroup
        }
        title
        bannerImageUrl
        bannerImageId
        overviewDescription
        overviewResources {
            ...ProjectOverviewResource
        }
        sections {
            ...ProjectSection
        }
        sourceTemplateId
        updatedAt
        createdAt
        totalProjectSections
        totalCompletedProjectSections
        latestReviewRequestedAt
        completedAt
        canUpdateMentorGroup
        canDelete
        createdBy {
            ...User
        }
        isLocked
    }
    ${UserFragmentDoc}
    ${ProviderSimpleFragmentDoc}
    ${ProjectMentorGroupFragmentDoc}
    ${ProjectOverviewResourceFragmentDoc}
    ${ProjectSectionFragmentDoc}
`;
export const SimilarProjectFragmentDoc = gql`
    fragment SimilarProject on Project {
        id
        title
        user {
            id
            fullName
            pictureUrl
        }
    }
`;
export const MentorSessionsSummaryFragmentDoc = gql`
    fragment MentorSessionsSummary on MentorSessionsSummary {
        user {
            ...User
        }
        sessionCount
        sessionDuration
    }
    ${UserFragmentDoc}
`;
export const WalletCardFragmentDoc = gql`
    fragment WalletCard on WalletCard {
        id
        walletCardType
        createdAt
        updatedAt
    }
`;
export const LearnerIdVerifiableCredentialFragmentDoc = gql`
    fragment LearnerIdVerifiableCredential on LearnerIdVerifiableCredential {
        id
        walletCardType
        createdAt
        updatedAt
        idNumber
        givenName
        familyName
        birthDate
        pictureUrl
        email
        countryCode
        address {
            streetAddress
            locality
            region
            postalCode
            country
        }
        organisationTitle
        organisationLogoUrl
        barcodeNumber
        nationalCurriculumYear
        age
        ageOver13
        ageOver16
        ageOver18
        ageOver21
        expiresAt
    }
`;
export const VerifiedIdVerifiableCredentialFragmentDoc = gql`
    fragment VerifiedIdVerifiableCredential on VerifiedIdVerifiableCredential {
        id
        walletCardType
        createdAt
        updatedAt
        givenName
        familyName
        birthDate
        pictureUrl
        countryCode
        address {
            streetAddress
            locality
            region
            postalCode
            country
        }
        verificationSources
        age
        ageOver13
        ageOver16
        ageOver18
        ageOver21
        expiresAt
    }
`;
export const LoyaltyVerifiableCredentialFragmentDoc = gql`
    fragment LoyaltyVerifiableCredential on LoyaltyVerifiableCredential {
        id
        walletCardType
        createdAt
        updatedAt
        issuerId
        issuerTitle
        issuerLogoUrl
        programId
        programTitle
        possibleBarcodeNumber: barcodeNumber
        hexBackgroundColor
        termsAndConditionsUrl
        possibleExpiresAt: expiresAt
    }
`;
export const ConnectedUserSimpleFragmentDoc = gql`
    fragment ConnectedUserSimple on ConnectedUser {
        id
        user {
            ...User
        }
        sharedActivity
        hasPinned
        pinnedAt
        createdAt
        updatedAt
    }
    ${UserFragmentDoc}
`;
export const UserConnectionRequestFragmentDoc = gql`
    fragment UserConnectionRequest on UserConnectionRequest {
        id
        sharer {
            ...User
        }
        sharedActivity
        requestCode
        shareeEmail
        isLinkShare
        updatedAt
        createdAt
    }
    ${UserFragmentDoc}
`;
export const ConnectedUserSummaryFragmentDoc = gql`
    fragment ConnectedUserSummary on ConnectedUser {
        id
        user {
            ...User
        }
        isSharingReward
        isSharingSavedCourses
        isSharingTimetable
        isSharingAssessmentResults
        isSharingAttendance
        isSharingCurriculumVitae
        isSharingDailyNotices
        isSharingGoals
        isSharingPathwayPlanners
        isSharingProjects
        isSharingReflections
        isSharingStudentHomeworkDiary
        sharedActivity
        curriculumVitae {
            updatedAt
        }
        goals {
            totalCount
            lastUpdatedAt
        }
        pathwayPlanners {
            totalCount
            lastUpdatedAt
        }
        projects {
            totalCount
            lastUpdatedAt
        }
        providerActivities {
            id
            providerTitle
            reward {
                totalPoints
            }
        }
        reflections {
            totalCount
            lastUpdatedAt
        }
        savedStudyProgrammes {
            totalCount
            lastSavedAt
        }
        hasPinned
        pinnedAt
    }
    ${UserFragmentDoc}
`;
export const ConnectedUserRewardTransactionFragmentDoc = gql`
    fragment ConnectedUserRewardTransaction on ConnectedUserRewardTransaction {
        id
        type
        rewardValue {
            id
            title
            color
        }
        createdBy {
            ...User
        }
        comment
        points
        updatedAt
        createdAt
    }
    ${UserFragmentDoc}
`;
export const StudentHomeworkDiaryMentorTaskFragmentDoc = gql`
    fragment StudentHomeworkDiaryMentorTask on StudentHomeworkDiaryMentorTask {
        id
        date
        title
        information
        imageId
        imageUrl
        color
        createdBy {
            ...User
        }
        updatedAt
        createdAt
        studentsAssigned {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...MentorStudentSimple
                }
                cursor
            }
        }
        studentsCompleted {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...MentorStudentSimple
                }
                cursor
            }
        }
        canUpdate
        canDelete
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${MentorStudentSimpleFragmentDoc}
`;
export const SponsorProviderDetailedFragmentDoc = gql`
    fragment SponsorProviderDetailed on SponsorProvider {
        id
        title
        logoImageUrl
        canUpdate
        canDelete
    }
`;
export const SponsorProviderSimpleFragmentDoc = gql`
    fragment SponsorProviderSimple on SponsorProvider {
        id
        title
        logoImageUrl
    }
`;
export const SponsorCampaignDetailedFragmentDoc = gql`
    fragment SponsorCampaignDetailed on SponsorCampaign {
        id
        startDate
        endDate
        timezone
        sponsorProvider {
            ...SponsorProviderSimple
        }
        sponsorCampaignAssets {
            totalCount
        }
        status
        targetCountries
        createdAt
        updatedAt
        canPublish
        canUnpublish
        canDelete
        canUpdate
    }
    ${SponsorProviderSimpleFragmentDoc}
`;
export const SponsorCampaignSummaryFragmentDoc = gql`
    fragment SponsorCampaignSummary on SponsorCampaign {
        id
        startDate
        endDate
        sponsorProvider {
            ...SponsorProviderSimple
        }
        sponsorCampaignAssets {
            totalCount
        }
        status
        targetCountries
    }
    ${SponsorProviderSimpleFragmentDoc}
`;
export const SponsorCampaignWithoutAssetFragmentDoc = gql`
    fragment SponsorCampaignWithoutAsset on SponsorCampaign {
        id
        startDate
        endDate
        timezone
        sponsorProvider {
            ...SponsorProviderSimple
        }
        status
        targetCountries
        createdAt
        updatedAt
        canPublish
        canUnpublish
        canDelete
        canUpdate
    }
    ${SponsorProviderSimpleFragmentDoc}
`;
export const SponsorCampaignSimpleFragmentDoc = gql`
    fragment SponsorCampaignSimple on SponsorCampaign {
        id
        startDate
        endDate
        timezone
        status
        targetCountries
        sponsorProvider {
            ...SponsorProviderSimple
        }
        createdAt
        updatedAt
    }
    ${SponsorProviderSimpleFragmentDoc}
`;
export const SponsorAssetDetailedFragmentDoc = gql`
    fragment SponsorAssetDetailed on SponsorAsset {
        id
        label
        type
        title
        content
        targetUrl
        mediaFileId
        mediaFileAvailable
        mediaFileUrl
        mediaType
        sponsorProvider {
            ...SponsorProviderSimple
        }
        createdAt
        updatedAt
        canUpdate
        canDelete
    }
    ${SponsorProviderSimpleFragmentDoc}
`;
export const SponsorCampaignAssetFragmentDoc = gql`
    fragment SponsorCampaignAsset on SponsorCampaignAsset {
        id
        sponsorAsset {
            ...SponsorAssetDetailed
        }
        targetUser
        newsfeedPostIndex
        startDate
        endDate
        totalViewsCount
        totalClicksCount
        createdAt
        updatedAt
        canDelete
        canUpdate
    }
    ${SponsorAssetDetailedFragmentDoc}
`;
export const SponsorCampaignBannerFragmentDoc = gql`
    fragment SponsorCampaignBanner on SponsorCampaignBanner {
        id
        sponsorCampaignId
        sponsorProviderTitle
        sponsorProviderLogoImageUrl
        title
        content
        targetUrl
        mediaImageUrl
        mediaVideoUrl
    }
`;
export const ProviderMySchoolInformationItemFragmentDoc = gql`
    fragment ProviderMySchoolInformationItem on ProviderMySchoolInformationItem {
        id
        title
        information
        sequence
        updatedAt
        createdAt
    }
`;
export const CareersRiasecResultFragmentDoc = gql`
    fragment CareersRiasecResult on CareersRiasecResult {
        id
        interestCode
        interestRealistic
        interestInvestigative
        interestArtistic
        interestSocial
        interestEnterprising
        interestConventional
        updatedAt
        createdAt
    }
`;
export const ProviderBadgeTemplateFragmentDoc = gql`
    fragment ProviderBadgeTemplate on ProviderBadgeTemplate {
        id
        provider {
            ...ProviderSimple
        }
        title
        description
        badgeImageId
        badgeImageUrl
        updatedAt
        createdAt
        canDelete
    }
    ${ProviderSimpleFragmentDoc}
`;
export const MentorBadgeStudentDetailedFragmentDoc = gql`
    fragment MentorBadgeStudentDetailed on MentorBadgeStudent {
        id
        provider {
            ...ProviderSimple
        }
        user {
            ...User
        }
        learnerSmsProfile {
            ...LearnerSmsProfileSimple
        }
        canCreateBadge
    }
    ${ProviderSimpleFragmentDoc}
    ${UserFragmentDoc}
    ${LearnerSmsProfileSimpleFragmentDoc}
`;
export const MentorStudentInviteFragmentDoc = gql`
    fragment MentorStudentInvite on MentorStudentInvite {
        id
        provider {
            ...ProviderSimple
        }
        mentorGroups {
            ...MentorGroupSimple
        }
        email
        redeemedAt
        expiresAt
        createdAt
    }
    ${ProviderSimpleFragmentDoc}
    ${MentorGroupSimpleFragmentDoc}
`;
export const LogEventsMutationDocument = gql`
    mutation LogEventsMutation($input: LogEventsInput!) {
        logEvents(input: $input) {
            eventsLogged
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class LogEventsMutationGQL extends Apollo.Mutation<LogEventsMutation, LogEventsMutationVariables> {
    document = LogEventsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorSessionsSummaryAnalyticsQueryDocument = gql`
    query ProviderMentorSessionsSummaryAnalyticsQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $start: DateTime!
        $end: DateTime!
    ) {
        provider(id: $providerId) {
            id
            mentorSessionsSummaryAnalytics(first: $first, last: $last, before: $before, after: $after, start: $start, end: $end) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorSessionsSummary
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorSessionsSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorSessionsSummaryAnalyticsQueryGQL extends Apollo.Query<
    ProviderMentorSessionsSummaryAnalyticsQuery,
    ProviderMentorSessionsSummaryAnalyticsQueryVariables
> {
    document = ProviderMentorSessionsSummaryAnalyticsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateBadgeMutationDocument = gql`
    mutation CreateBadgeMutation($input: CreateBadgeInput!) {
        createBadge(input: $input) {
            badge {
                ...Badge
            }
        }
    }
    ${BadgeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateBadgeMutationGQL extends Apollo.Mutation<CreateBadgeMutation, CreateBadgeMutationVariables> {
    document = CreateBadgeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderBadgeTemplateMutationDocument = gql`
    mutation CreateProviderBadgeTemplateMutation($input: CreateProviderBadgeTemplateInput!) {
        createProviderBadgeTemplate(input: $input) {
            providerBadgeTemplate {
                ...ProviderBadgeTemplate
            }
        }
    }
    ${ProviderBadgeTemplateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderBadgeTemplateMutationGQL extends Apollo.Mutation<
    CreateProviderBadgeTemplateMutation,
    CreateProviderBadgeTemplateMutationVariables
> {
    document = CreateProviderBadgeTemplateMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteBadgeMutationDocument = gql`
    mutation DeleteBadgeMutation($input: DeleteBadgeInput!) {
        deleteBadge(input: $input) {
            badgeId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteBadgeMutationGQL extends Apollo.Mutation<DeleteBadgeMutation, DeleteBadgeMutationVariables> {
    document = DeleteBadgeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderBadgeTemplateMutationDocument = gql`
    mutation DeleteProviderBadgeTemplateMutation($input: DeleteProviderBadgeTemplateInput!) {
        deleteProviderBadgeTemplate(input: $input) {
            providerBadgeTemplateId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderBadgeTemplateMutationGQL extends Apollo.Mutation<
    DeleteProviderBadgeTemplateMutation,
    DeleteProviderBadgeTemplateMutationVariables
> {
    document = DeleteProviderBadgeTemplateMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorBadgeStudentQueryDocument = gql`
    query MentorBadgeStudentQuery($id: UUID!, $first: Int, $last: Int, $before: String, $after: String, $currentProviderOnly: Boolean) {
        mentorBadgeStudent(id: $id) {
            id
            user {
                ...User
            }
            provider {
                ...ProviderSimple
            }
            learnerSmsProfile {
                ...LearnerSmsProfileSimple
            }
            badges(first: $first, last: $last, before: $before, after: $after, currentProviderOnly: $currentProviderOnly) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...Badge
                    }
                    cursor
                }
                lastCreatedAt
            }
            canCreateBadge
        }
    }
    ${UserFragmentDoc}
    ${ProviderSimpleFragmentDoc}
    ${LearnerSmsProfileSimpleFragmentDoc}
    ${PageInfoFragmentDoc}
    ${BadgeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorBadgeStudentQueryGQL extends Apollo.Query<MentorBadgeStudentQuery, MentorBadgeStudentQueryVariables> {
    document = MentorBadgeStudentQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorBadgeStudentsQueryDocument = gql`
    query MentorBadgeStudentsQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $query: String
        $currentProviderOnly: Boolean
    ) {
        provider(id: $providerId) {
            id
            mentorBadgeStudents(first: $first, last: $last, before: $before, after: $after, query: $query) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorBadgeStudentDetailed
                        badges(currentProviderOnly: $currentProviderOnly) {
                            totalCount
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorBadgeStudentDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorBadgeStudentsQueryGQL extends Apollo.Query<MentorBadgeStudentsQuery, MentorBadgeStudentsQueryVariables> {
    document = MentorBadgeStudentsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupStudentsBadgeQueryDocument = gql`
    query MentorGroupStudentsBadgeQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            id
            students(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        mentorStudent {
                            id
                            user {
                                ...User
                            }
                            learnerSmsProfile {
                                ...LearnerSmsProfileSimple
                            }
                            badges {
                                totalCount
                            }
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${UserFragmentDoc}
    ${LearnerSmsProfileSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupStudentsBadgeQueryGQL extends Apollo.Query<MentorGroupStudentsBadgeQuery, MentorGroupStudentsBadgeQueryVariables> {
    document = MentorGroupStudentsBadgeQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentBadgePermissionQueryDocument = gql`
    query MentorStudentBadgePermissionQuery($id: UUID!) {
        mentorStudent(id: $id) {
            id
            canCreateBadge
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentBadgePermissionQueryGQL extends Apollo.Query<
    MentorStudentBadgePermissionQuery,
    MentorStudentBadgePermissionQueryVariables
> {
    document = MentorStudentBadgePermissionQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentBadgeQueryDocument = gql`
    query MentorStudentBadgeQuery(
        $mentorStudentId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $currentProviderOnly: Boolean
    ) {
        mentorStudent(id: $mentorStudentId) {
            id
            badges(first: $first, last: $last, before: $before, after: $after, currentProviderOnly: $currentProviderOnly) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...Badge
                    }
                    cursor
                }
                lastCreatedAt
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${BadgeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentBadgeQueryGQL extends Apollo.Query<MentorStudentBadgeQuery, MentorStudentBadgeQueryVariables> {
    document = MentorStudentBadgeQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyBadgesQueryDocument = gql`
    query MyBadgesQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            badges(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...Badge
                    }
                    cursor
                }
                lastCreatedAt
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${BadgeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyBadgesQueryGQL extends Apollo.Query<MyBadgesQuery, MyBadgesQueryVariables> {
    document = MyBadgesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderBadgeTemplatesQueryDocument = gql`
    query ProviderBadgeTemplatesQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        provider(id: $providerId) {
            id
            providerBadgeTemplates(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProviderBadgeTemplate
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProviderBadgeTemplateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderBadgeTemplatesQueryGQL extends Apollo.Query<ProviderBadgeTemplatesQuery, ProviderBadgeTemplatesQueryVariables> {
    document = ProviderBadgeTemplatesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CalendarsQueryDocument = gql`
    query CalendarsQuery($start: DateTime!, $end: DateTime!) {
        me {
            id
            calendars {
                ...Calendar
            }
        }
    }
    ${CalendarFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CalendarsQueryGQL extends Apollo.Query<CalendarsQuery, CalendarsQueryVariables> {
    document = CalendarsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AcceptUserConnectionRequestMutationDocument = gql`
    mutation AcceptUserConnectionRequestMutation($input: AcceptUserConnectionRequestInput!) {
        acceptUserConnectionRequest(input: $input) {
            userConnectionRequestId
            connectedUser {
                ...ConnectedUserSimple
            }
        }
    }
    ${ConnectedUserSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class AcceptUserConnectionRequestMutationGQL extends Apollo.Mutation<
    AcceptUserConnectionRequestMutation,
    AcceptUserConnectionRequestMutationVariables
> {
    document = AcceptUserConnectionRequestMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserAssessmentResultsQueryDocument = gql`
    query ConnectedUserAssessmentResultsQuery($connectedUserId: UUID!, $providerId: UUID!) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                id
                assessmentResultsList {
                    ...AssessmentResult
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${AssessmentResultFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserAssessmentResultsQueryGQL extends Apollo.Query<
    ConnectedUserAssessmentResultsQuery,
    ConnectedUserAssessmentResultsQueryVariables
> {
    document = ConnectedUserAssessmentResultsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserAttendanceSummaryQueryDocument = gql`
    query ConnectedUserAttendanceSummaryQuery($connectedUserId: UUID!, $providerId: UUID!, $start: Date!, $end: Date!) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                id
                attendanceSummary(start: $start, end: $end) {
                    ...LearnerSmsAttendanceSummary
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${LearnerSmsAttendanceSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserAttendanceSummaryQueryGQL extends Apollo.Query<
    ConnectedUserAttendanceSummaryQuery,
    ConnectedUserAttendanceSummaryQueryVariables
> {
    document = ConnectedUserAttendanceSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserAttendanceYearSummaryQueryDocument = gql`
    query ConnectedUserAttendanceYearSummaryQuery($connectedUserId: UUID!, $providerId: UUID!, $year: Int!) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                id
                januaryAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 1) {
                    ...LearnerSmsAttendanceSummary
                }
                februaryAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 2) {
                    ...LearnerSmsAttendanceSummary
                }
                marchAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 3) {
                    ...LearnerSmsAttendanceSummary
                }
                aprilAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 4) {
                    ...LearnerSmsAttendanceSummary
                }
                mayAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 5) {
                    ...LearnerSmsAttendanceSummary
                }
                juneAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 6) {
                    ...LearnerSmsAttendanceSummary
                }
                julyAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 7) {
                    ...LearnerSmsAttendanceSummary
                }
                augustAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 8) {
                    ...LearnerSmsAttendanceSummary
                }
                septemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 9) {
                    ...LearnerSmsAttendanceSummary
                }
                octoberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 10) {
                    ...LearnerSmsAttendanceSummary
                }
                novemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 11) {
                    ...LearnerSmsAttendanceSummary
                }
                decemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 12) {
                    ...LearnerSmsAttendanceSummary
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${LearnerSmsAttendanceSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserAttendanceYearSummaryQueryGQL extends Apollo.Query<
    ConnectedUserAttendanceYearSummaryQuery,
    ConnectedUserAttendanceYearSummaryQueryVariables
> {
    document = ConnectedUserAttendanceYearSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserCurriculumVitaeQueryDocument = gql`
    query ConnectedUserCurriculumVitaeQuery($connectedUserId: UUID!) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            curriculumVitae {
                ...CurriculumVitae
            }
        }
    }
    ${UserFragmentDoc}
    ${CurriculumVitaeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserCurriculumVitaeQueryGQL extends Apollo.Query<
    ConnectedUserCurriculumVitaeQuery,
    ConnectedUserCurriculumVitaeQueryVariables
> {
    document = ConnectedUserCurriculumVitaeQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserDailyNoticesQueryDocument = gql`
    query ConnectedUserDailyNoticesQuery($connectedUserId: UUID!, $providerId: UUID!, $start: DateTime!, $end: DateTime!) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                id
                dailyNoticesList(start: $start, end: $end) {
                    ...DailyNotice
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${DailyNoticeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserDailyNoticesQueryGQL extends Apollo.Query<
    ConnectedUserDailyNoticesQuery,
    ConnectedUserDailyNoticesQueryVariables
> {
    document = ConnectedUserDailyNoticesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserGoalsQueryDocument = gql`
    query ConnectedUserGoalsQuery($connectedUserId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            goals(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...Goal
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${GoalFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserGoalsQueryGQL extends Apollo.Query<ConnectedUserGoalsQuery, ConnectedUserGoalsQueryVariables> {
    document = ConnectedUserGoalsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserPathwayPlannersQueryDocument = gql`
    query ConnectedUserPathwayPlannersQuery($connectedUserId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            pathwayPlanners(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...PathwayPlanner
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserPathwayPlannersQueryGQL extends Apollo.Query<
    ConnectedUserPathwayPlannersQuery,
    ConnectedUserPathwayPlannersQueryVariables
> {
    document = ConnectedUserPathwayPlannersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserProjectsQueryDocument = gql`
    query ConnectedUserProjectsQuery($connectedUserId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            projects(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...ProjectSimple
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ProjectSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserProjectsQueryGQL extends Apollo.Query<ConnectedUserProjectsQuery, ConnectedUserProjectsQueryVariables> {
    document = ConnectedUserProjectsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserReflectionsQueryDocument = gql`
    query ConnectedUserReflectionsQuery($connectedUserId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            reflections(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...Reflection
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ReflectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserReflectionsQueryGQL extends Apollo.Query<ConnectedUserReflectionsQuery, ConnectedUserReflectionsQueryVariables> {
    document = ConnectedUserReflectionsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserRewardsQueryDocument = gql`
    query ConnectedUserRewardsQuery($connectedUserId: UUID!, $providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                id
                reward {
                    totalPoints
                    availablePoints
                    transactions(first: $first, last: $last, before: $before, after: $after) {
                        totalCount
                        pageInfo {
                            ...PageInfo
                        }
                        edges {
                            node {
                                ...ConnectedUserRewardTransaction
                            }
                            cursor
                        }
                    }
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ConnectedUserRewardTransactionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserRewardsQueryGQL extends Apollo.Query<ConnectedUserRewardsQuery, ConnectedUserRewardsQueryVariables> {
    document = ConnectedUserRewardsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserSavedStudyProgrammesQueryDocument = gql`
    query ConnectedUserSavedStudyProgrammesQuery($connectedUserId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            savedStudyProgrammes(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...SavedStudyProgramme
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${SavedStudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserSavedStudyProgrammesQueryGQL extends Apollo.Query<
    ConnectedUserSavedStudyProgrammesQuery,
    ConnectedUserSavedStudyProgrammesQueryVariables
> {
    document = ConnectedUserSavedStudyProgrammesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserStudentHomeworkDiaryDailyItemsQueryDocument = gql`
    query ConnectedUserStudentHomeworkDiaryDailyItemsQuery(
        $connectedUserId: UUID!
        $providerId: UUID!
        $date: Date!
        $first: Int
        $last: Int
        $before: String
        $after: String
    ) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                studentHomeworkDiaryDailyItems(date: $date, first: $first, last: $last, before: $before, after: $after) {
                    totalCount
                    pageInfo {
                        ...PageInfo
                    }
                    edges {
                        node {
                            ...StudentHomeworkDiaryItem
                        }
                        cursor
                    }
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${StudentHomeworkDiaryItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserStudentHomeworkDiaryDailyItemsQueryGQL extends Apollo.Query<
    ConnectedUserStudentHomeworkDiaryDailyItemsQuery,
    ConnectedUserStudentHomeworkDiaryDailyItemsQueryVariables
> {
    document = ConnectedUserStudentHomeworkDiaryDailyItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserStudentHomeworkDiaryMonthSummaryQueryDocument = gql`
    query ConnectedUserStudentHomeworkDiaryMonthSummaryQuery($connectedUserId: UUID!, $providerId: UUID!, $date: Date!) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                id
                studentHomeworkDiaryMonthSummary(date: $date) {
                    days {
                        date
                        completed
                        total
                        colors
                    }
                    completed
                    total
                    colors
                }
            }
        }
    }
    ${UserFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserStudentHomeworkDiaryMonthSummaryQueryGQL extends Apollo.Query<
    ConnectedUserStudentHomeworkDiaryMonthSummaryQuery,
    ConnectedUserStudentHomeworkDiaryMonthSummaryQueryVariables
> {
    document = ConnectedUserStudentHomeworkDiaryMonthSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectedUserTimetableEventsQueryDocument = gql`
    query ConnectedUserTimetableEventsQuery($connectedUserId: UUID!, $providerId: UUID!, $start: DateTime!, $end: DateTime!) {
        connectedUser(id: $connectedUserId) {
            id
            user {
                ...User
            }
            providerActivity(providerId: $providerId) {
                id
                timetableEventsList(start: $start, end: $end) {
                    ...TimetableEvent
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${TimetableEventFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectedUserTimetableEventsQueryGQL extends Apollo.Query<
    ConnectedUserTimetableEventsQuery,
    ConnectedUserTimetableEventsQueryVariables
> {
    document = ConnectedUserTimetableEventsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConnectionSummaryQueryDocument = gql`
    query ConnectionSummaryQuery($connectedUserId: UUID!) {
        connectedUser(id: $connectedUserId) {
            ...ConnectedUserSummary
        }
    }
    ${ConnectedUserSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConnectionSummaryQueryGQL extends Apollo.Query<ConnectionSummaryQuery, ConnectionSummaryQueryVariables> {
    document = ConnectionSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyConnectedUsersQueryDocument = gql`
    query MyConnectedUsersQuery($first: Int, $last: Int, $before: String, $after: String, $query: String) {
        me {
            id
            connectedUsers(first: $first, last: $last, before: $before, after: $after, query: $query) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ConnectedUserSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ConnectedUserSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyConnectedUsersQueryGQL extends Apollo.Query<MyConnectedUsersQuery, MyConnectedUsersQueryVariables> {
    document = MyConnectedUsersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RejectUserConnectionRequestMutationDocument = gql`
    mutation RejectUserConnectionRequestMutation($input: RejectUserConnectionRequestInput!) {
        rejectUserConnectionRequest(input: $input) {
            userConnectionRequestId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class RejectUserConnectionRequestMutationGQL extends Apollo.Mutation<
    RejectUserConnectionRequestMutation,
    RejectUserConnectionRequestMutationVariables
> {
    document = RejectUserConnectionRequestMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RemoveConnectedUserMutationDocument = gql`
    mutation RemoveConnectedUserMutation($input: RemoveConnectedUserInput!) {
        removeConnectedUser(input: $input) {
            connectedUserId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class RemoveConnectedUserMutationGQL extends Apollo.Mutation<RemoveConnectedUserMutation, RemoveConnectedUserMutationVariables> {
    document = RemoveConnectedUserMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RequestUserConnectionMutationDocument = gql`
    mutation RequestUserConnectionMutation($input: RequestUserConnectionInput!) {
        requestUserConnection(input: $input) {
            userConnectionRequest {
                ...UserConnectionRequest
            }
        }
    }
    ${UserConnectionRequestFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RequestUserConnectionMutationGQL extends Apollo.Mutation<
    RequestUserConnectionMutation,
    RequestUserConnectionMutationVariables
> {
    document = RequestUserConnectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TogglePinnedConnectionMutationDocument = gql`
    mutation togglePinnedConnectionMutation($input: TogglePinnedConnectionInput!) {
        togglePinnedConnection(input: $input) {
            connectedUser {
                ...ConnectedUserSimple
            }
        }
    }
    ${ConnectedUserSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class TogglePinnedConnectionMutationGQL extends Apollo.Mutation<
    TogglePinnedConnectionMutation,
    TogglePinnedConnectionMutationVariables
> {
    document = TogglePinnedConnectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateConnectedUserActivitySharingMutationDocument = gql`
    mutation UpdateConnectedUserActivitySharingMutation($input: UpdateConnectedUserActivitySharingInput!) {
        updateConnectedUserActivitySharing(input: $input) {
            connectedUser {
                ...ConnectedUserSimple
            }
        }
    }
    ${ConnectedUserSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateConnectedUserActivitySharingMutationGQL extends Apollo.Mutation<
    UpdateConnectedUserActivitySharingMutation,
    UpdateConnectedUserActivitySharingMutationVariables
> {
    document = UpdateConnectedUserActivitySharingMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UserConnectionRequestsCountQueryDocument = gql`
    query UserConnectionRequestsCountQuery {
        me {
            id
            connectionRequests {
                totalCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UserConnectionRequestsCountQueryGQL extends Apollo.Query<
    UserConnectionRequestsCountQuery,
    UserConnectionRequestsCountQueryVariables
> {
    document = UserConnectionRequestsCountQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UserConnectionRequestsQueryDocument = gql`
    query UserConnectionRequestsQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            connectionRequests(first: $first, last: $last, before: $before, after: $after) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...UserConnectionRequest
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${UserConnectionRequestFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UserConnectionRequestsQueryGQL extends Apollo.Query<UserConnectionRequestsQuery, UserConnectionRequestsQueryVariables> {
    document = UserConnectionRequestsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UserConnectionRequestsSentQueryDocument = gql`
    query UserConnectionRequestsSentQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            connectionRequestsSent(first: $first, last: $last, before: $before, after: $after) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...UserConnectionRequest
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${UserConnectionRequestFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UserConnectionRequestsSentQueryGQL extends Apollo.Query<
    UserConnectionRequestsSentQuery,
    UserConnectionRequestsSentQueryVariables
> {
    document = UserConnectionRequestsSentQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ValidateUserConnectionRequestMutationDocument = gql`
    mutation ValidateUserConnectionRequestMutation($input: ValidateUserConnectionRequestInput!) {
        validateUserConnectionRequest(input: $input) {
            userConnectionRequest {
                ...UserConnectionRequest
            }
        }
    }
    ${UserConnectionRequestFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ValidateUserConnectionRequestMutationGQL extends Apollo.Mutation<
    ValidateUserConnectionRequestMutation,
    ValidateUserConnectionRequestMutationVariables
> {
    document = ValidateUserConnectionRequestMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateStudyCategoryMutationDocument = gql`
    mutation CreateStudyCategoryMutation($input: CreateStudyCategoryInput!) {
        createStudyCategory(input: $input) {
            studyCategory {
                ...StudyCategory
            }
        }
    }
    ${StudyCategoryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateStudyCategoryMutationGQL extends Apollo.Mutation<CreateStudyCategoryMutation, CreateStudyCategoryMutationVariables> {
    document = CreateStudyCategoryMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateStudyProgrammeMutationDocument = gql`
    mutation CreateStudyProgrammeMutation($input: CreateStudyProgrammeInput!) {
        createStudyProgramme(input: $input) {
            studyProgramme {
                ...StudyProgramme
            }
        }
    }
    ${StudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateStudyProgrammeMutationGQL extends Apollo.Mutation<CreateStudyProgrammeMutation, CreateStudyProgrammeMutationVariables> {
    document = CreateStudyProgrammeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateStudyProviderMutationDocument = gql`
    mutation CreateStudyProviderMutation($input: CreateStudyProviderInput!) {
        createStudyProvider(input: $input) {
            studyProvider {
                ...StudyProviderForSuperadmin
            }
        }
    }
    ${StudyProviderForSuperadminFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateStudyProviderMutationGQL extends Apollo.Mutation<CreateStudyProviderMutation, CreateStudyProviderMutationVariables> {
    document = CreateStudyProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudyCategoryMutationDocument = gql`
    mutation DeleteStudyCategoryMutation($input: DeleteStudyCategoryInput!) {
        deleteStudyCategory(input: $input) {
            studyCategoryId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteStudyCategoryMutationGQL extends Apollo.Mutation<DeleteStudyCategoryMutation, DeleteStudyCategoryMutationVariables> {
    document = DeleteStudyCategoryMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudyProgrammeMutationDocument = gql`
    mutation DeleteStudyProgrammeMutation($input: DeleteStudyProgrammeInput!) {
        deleteStudyProgramme(input: $input) {
            studyProgrammeId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteStudyProgrammeMutationGQL extends Apollo.Mutation<DeleteStudyProgrammeMutation, DeleteStudyProgrammeMutationVariables> {
    document = DeleteStudyProgrammeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudyProviderMutationDocument = gql`
    mutation DeleteStudyProviderMutation($input: DeleteStudyProviderInput!) {
        deleteStudyProvider(input: $input) {
            studyProviderId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteStudyProviderMutationGQL extends Apollo.Mutation<DeleteStudyProviderMutation, DeleteStudyProviderMutationVariables> {
    document = DeleteStudyProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const EnquireStudyProgrammeMutationDocument = gql`
    mutation EnquireStudyProgrammeMutation($input: EnquireStudyProgrammeInput!) {
        enquireStudyProgramme(input: $input) {
            enquirySent
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class EnquireStudyProgrammeMutationGQL extends Apollo.Mutation<
    EnquireStudyProgrammeMutation,
    EnquireStudyProgrammeMutationVariables
> {
    document = EnquireStudyProgrammeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const EnrolInStudyProgrammeMutationDocument = gql`
    mutation EnrolInStudyProgrammeMutation($input: EnrolInStudyProgrammeInput!) {
        enrolInStudyProgramme(input: $input) {
            enrolmentSent
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class EnrolInStudyProgrammeMutationGQL extends Apollo.Mutation<
    EnrolInStudyProgrammeMutation,
    EnrolInStudyProgrammeMutationVariables
> {
    document = EnrolInStudyProgrammeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MySavedStudyProgrammesQueryDocument = gql`
    query MySavedStudyProgrammesQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            savedStudyProgrammes(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...StudyProgramme
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MySavedStudyProgrammesQueryGQL extends Apollo.Query<MySavedStudyProgrammesQuery, MySavedStudyProgrammesQueryVariables> {
    document = MySavedStudyProgrammesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SaveStudyProgrammeMutationDocument = gql`
    mutation SaveStudyProgrammeMutation($input: SaveStudyProgrammeInput!) {
        saveStudyProgramme(input: $input) {
            studyProgramme {
                ...StudyProgramme
            }
        }
    }
    ${StudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SaveStudyProgrammeMutationGQL extends Apollo.Mutation<SaveStudyProgrammeMutation, SaveStudyProgrammeMutationVariables> {
    document = SaveStudyProgrammeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyCategoriesQueryDocument = gql`
    query StudyCategoriesQuery($first: Int, $last: Int, $before: String, $after: String) {
        studyCategories(first: $first, last: $last, before: $before, after: $after) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...StudyCategory
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudyCategoryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyCategoriesQueryGQL extends Apollo.Query<StudyCategoriesQuery, StudyCategoriesQueryVariables> {
    document = StudyCategoriesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyCategoryQueryDocument = gql`
    query StudyCategoryQuery($id: UUID!) {
        studyCategory(id: $id) {
            ...StudyCategory
        }
    }
    ${StudyCategoryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyCategoryQueryGQL extends Apollo.Query<StudyCategoryQuery, StudyCategoryQueryVariables> {
    document = StudyCategoryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyProgrammeQueryDocument = gql`
    query StudyProgrammeQuery($id: UUID!) {
        studyProgramme(id: $id) {
            ...StudyProgramme
        }
    }
    ${StudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyProgrammeQueryGQL extends Apollo.Query<StudyProgrammeQuery, StudyProgrammeQueryVariables> {
    document = StudyProgrammeQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyProgrammesQueryDocument = gql`
    query StudyProgrammesQuery(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $studyProviderId: UUID
        $studyCategoryId: UUID
        $studyCountryCodes: [String!]
        $search: String
        $isEnquiriesEnabled: Boolean
        $forLandingList: Boolean
    ) {
        studyProgrammes(
            first: $first
            last: $last
            before: $before
            after: $after
            studyProviderId: $studyProviderId
            studyCategoryId: $studyCategoryId
            studyCountryCodes: $studyCountryCodes
            search: $search
            isEnquiriesEnabled: $isEnquiriesEnabled
            forLandingList: $forLandingList
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...StudyProgramme
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyProgrammesQueryGQL extends Apollo.Query<StudyProgrammesQuery, StudyProgrammesQueryVariables> {
    document = StudyProgrammesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyProviderForSuperadminQueryDocument = gql`
    query StudyProviderForSuperadminQuery($id: UUID!) {
        studyProvider(id: $id) {
            ...StudyProviderForSuperadmin
        }
    }
    ${StudyProviderForSuperadminFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyProviderForSuperadminQueryGQL extends Apollo.Query<
    StudyProviderForSuperadminQuery,
    StudyProviderForSuperadminQueryVariables
> {
    document = StudyProviderForSuperadminQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyProviderQueryDocument = gql`
    query StudyProviderQuery($id: UUID!) {
        studyProvider(id: $id) {
            ...StudyProvider
        }
    }
    ${StudyProviderFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyProviderQueryGQL extends Apollo.Query<StudyProviderQuery, StudyProviderQueryVariables> {
    document = StudyProviderQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyProvidersForSuperadminQueryDocument = gql`
    query StudyProvidersForSuperadminQuery($first: Int, $last: Int, $before: String, $after: String, $studyCountryCodes: [String!]) {
        studyProviders(first: $first, last: $last, before: $before, after: $after, studyCountryCodes: $studyCountryCodes) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...StudyProviderForSuperadmin
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudyProviderForSuperadminFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyProvidersForSuperadminQueryGQL extends Apollo.Query<
    StudyProvidersForSuperadminQuery,
    StudyProvidersForSuperadminQueryVariables
> {
    document = StudyProvidersForSuperadminQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyProvidersQueryDocument = gql`
    query StudyProvidersQuery($first: Int, $last: Int, $before: String, $after: String, $studyCountryCodes: [String!]) {
        studyProviders(first: $first, last: $last, before: $before, after: $after, studyCountryCodes: $studyCountryCodes) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...StudyProvider
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudyProviderFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudyProvidersQueryGQL extends Apollo.Query<StudyProvidersQuery, StudyProvidersQueryVariables> {
    document = StudyProvidersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnsaveStudyProgrammeMutationDocument = gql`
    mutation UnsaveStudyProgrammeMutation($input: UnsaveStudyProgrammeInput!) {
        unsaveStudyProgramme(input: $input) {
            studyProgramme {
                ...StudyProgramme
            }
        }
    }
    ${StudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnsaveStudyProgrammeMutationGQL extends Apollo.Mutation<UnsaveStudyProgrammeMutation, UnsaveStudyProgrammeMutationVariables> {
    document = UnsaveStudyProgrammeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateStudyCategoryMutationDocument = gql`
    mutation UpdateStudyCategoryMutation($input: UpdateStudyCategoryInput!) {
        updateStudyCategory(input: $input) {
            studyCategory {
                ...StudyCategory
            }
        }
    }
    ${StudyCategoryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateStudyCategoryMutationGQL extends Apollo.Mutation<UpdateStudyCategoryMutation, UpdateStudyCategoryMutationVariables> {
    document = UpdateStudyCategoryMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateStudyProgrammeMutationDocument = gql`
    mutation UpdateStudyProgrammeMutation($input: UpdateStudyProgrammeInput!) {
        updateStudyProgramme(input: $input) {
            studyProgramme {
                ...StudyProgramme
            }
        }
    }
    ${StudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateStudyProgrammeMutationGQL extends Apollo.Mutation<UpdateStudyProgrammeMutation, UpdateStudyProgrammeMutationVariables> {
    document = UpdateStudyProgrammeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateStudyProviderMutationDocument = gql`
    mutation UpdateStudyProviderMutation($input: UpdateStudyProviderInput!) {
        updateStudyProvider(input: $input) {
            studyProvider {
                ...StudyProviderForSuperadmin
            }
        }
    }
    ${StudyProviderForSuperadminFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateStudyProviderMutationGQL extends Apollo.Mutation<UpdateStudyProviderMutation, UpdateStudyProviderMutationVariables> {
    document = UpdateStudyProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ContactStudentJobProfileMutationDocument = gql`
    mutation ContactStudentJobProfileMutation($input: ContactStudentJobProfileInput!) {
        contactStudentJobProfile(input: $input) {
            contacted
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ContactStudentJobProfileMutationGQL extends Apollo.Mutation<
    ContactStudentJobProfileMutation,
    ContactStudentJobProfileMutationVariables
> {
    document = ContactStudentJobProfileMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DisableJobProfileMutationDocument = gql`
    mutation DisableJobProfileMutation($input: DisableJobProfileInput!) {
        disableJobProfile(input: $input) {
            jobProfile {
                ...JobProfile
            }
        }
    }
    ${JobProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DisableJobProfileMutationGQL extends Apollo.Mutation<DisableJobProfileMutation, DisableJobProfileMutationVariables> {
    document = DisableJobProfileMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const EnableJobProfileMutationDocument = gql`
    mutation EnableJobProfileMutation($input: EnableJobProfileInput!) {
        enableJobProfile(input: $input) {
            jobProfile {
                ...JobProfile
            }
        }
    }
    ${JobProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class EnableJobProfileMutationGQL extends Apollo.Mutation<EnableJobProfileMutation, EnableJobProfileMutationVariables> {
    document = EnableJobProfileMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GenerateCoverLetterMutationDocument = gql`
    mutation GenerateCoverLetterMutation($input: GenerateCoverLetterInput!) {
        generateCoverLetter(input: $input) {
            coverLetterText
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class GenerateCoverLetterMutationGQL extends Apollo.Mutation<GenerateCoverLetterMutation, GenerateCoverLetterMutationVariables> {
    document = GenerateCoverLetterMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GenerateCurriculumVitaeFileMutationDocument = gql`
    mutation GenerateCurriculumVitaeFileMutation($input: GenerateCurriculumVitaeFileInput!) {
        generateCurriculumVitaeFile(input: $input) {
            generatedFile {
                id
                fileId
                fileUrl
                generatedAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class GenerateCurriculumVitaeFileMutationGQL extends Apollo.Mutation<
    GenerateCurriculumVitaeFileMutation,
    GenerateCurriculumVitaeFileMutationVariables
> {
    document = GenerateCurriculumVitaeFileMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyCurriculumVitaeQueryDocument = gql`
    query MyCurriculumVitaeQuery {
        me {
            id
            curriculumVitae {
                ...CurriculumVitae
            }
        }
    }
    ${CurriculumVitaeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyCurriculumVitaeQueryGQL extends Apollo.Query<MyCurriculumVitaeQuery, MyCurriculumVitaeQueryVariables> {
    document = MyCurriculumVitaeQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyJobProfileActivitiesQueryDocument = gql`
    query MyJobProfileActivitiesQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            jobProfile {
                id
                activities(first: $first, last: $last, before: $before, after: $after) {
                    totalCount
                    pageInfo {
                        ...PageInfo
                    }
                    edges {
                        node {
                            ...JobProfileActivity
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${JobProfileActivityFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyJobProfileActivitiesQueryGQL extends Apollo.Query<MyJobProfileActivitiesQuery, MyJobProfileActivitiesQueryVariables> {
    document = MyJobProfileActivitiesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyJobProfileQueryDocument = gql`
    query MyJobProfileQuery {
        me {
            id
            jobProfile {
                ...JobProfile
            }
        }
    }
    ${JobProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyJobProfileQueryGQL extends Apollo.Query<MyJobProfileQuery, MyJobProfileQueryVariables> {
    document = MyJobProfileQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReportJobProfileActivityMutationDocument = gql`
    mutation ReportJobProfileActivityMutation($input: ReportJobProfileActivityInput!) {
        reportJobProfileActivity(input: $input) {
            reported
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ReportJobProfileActivityMutationGQL extends Apollo.Mutation<
    ReportJobProfileActivityMutation,
    ReportJobProfileActivityMutationVariables
> {
    document = ReportJobProfileActivityMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SearchStudentJobProfilesQueryDocument = gql`
    query SearchStudentJobProfilesQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $countryCode: String
        $geoLocationId: UUID
        $jobCategories: [JobCategory!]
        $ageGroups: [UserAgeGroup!]
        $query: String
        $isFavourite: Boolean
        $hasContacted: Boolean
    ) {
        provider(id: $providerId) {
            id
            searchStudentJobProfiles(
                first: $first
                last: $last
                before: $before
                after: $after
                countryCode: $countryCode
                geoLocationId: $geoLocationId
                jobCategories: $jobCategories
                ageGroups: $ageGroups
                query: $query
                isFavourite: $isFavourite
                hasContacted: $hasContacted
            ) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...StudentJobProfileSummary
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudentJobProfileSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SearchStudentJobProfilesQueryGQL extends Apollo.Query<SearchStudentJobProfilesQuery, SearchStudentJobProfilesQueryVariables> {
    document = SearchStudentJobProfilesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StartCurriculumVitaeMutationDocument = gql`
    mutation StartCurriculumVitaeMutation {
        startCurriculumVitae {
            curriculumVitae {
                ...CurriculumVitae
            }
        }
    }
    ${CurriculumVitaeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StartCurriculumVitaeMutationGQL extends Apollo.Mutation<StartCurriculumVitaeMutation, StartCurriculumVitaeMutationVariables> {
    document = StartCurriculumVitaeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudentJobProfileQueryDocument = gql`
    query StudentJobProfileQuery($providerId: UUID!, $studentJobProfileId: UUID!) {
        provider(id: $providerId) {
            id
            studentJobProfile(id: $studentJobProfileId) {
                ...StudentJobProfile
            }
        }
    }
    ${StudentJobProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudentJobProfileQueryGQL extends Apollo.Query<StudentJobProfileQuery, StudentJobProfileQueryVariables> {
    document = StudentJobProfileQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ToggleIsFavouriteStudentJobProfileMutationDocument = gql`
    mutation ToggleIsFavouriteStudentJobProfileMutation($input: ToggleIsFavouriteStudentJobProfileInput!) {
        toggleIsFavouriteStudentJobProfile(input: $input) {
            studentJobProfile {
                ...StudentJobProfile
            }
        }
    }
    ${StudentJobProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ToggleIsFavouriteStudentJobProfileMutationGQL extends Apollo.Mutation<
    ToggleIsFavouriteStudentJobProfileMutation,
    ToggleIsFavouriteStudentJobProfileMutationVariables
> {
    document = ToggleIsFavouriteStudentJobProfileMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateCurriculumVitaeMutationDocument = gql`
    mutation UpdateCurriculumVitaeMutation($input: UpdateCurriculumVitaeInput!) {
        updateCurriculumVitae(input: $input) {
            curriculumVitae {
                ...CurriculumVitae
            }
        }
    }
    ${CurriculumVitaeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateCurriculumVitaeMutationGQL extends Apollo.Mutation<
    UpdateCurriculumVitaeMutation,
    UpdateCurriculumVitaeMutationVariables
> {
    document = UpdateCurriculumVitaeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateJobProfileMutationDocument = gql`
    mutation UpdateJobProfileMutation($input: UpdateJobProfileInput!) {
        updateJobProfile(input: $input) {
            jobProfile {
                ...JobProfile
            }
        }
    }
    ${JobProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateJobProfileMutationGQL extends Apollo.Mutation<UpdateJobProfileMutation, UpdateJobProfileMutationVariables> {
    document = UpdateJobProfileMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GeoLocationCountryRegionsQueryDocument = gql`
    query GeoLocationCountryRegionsQuery($first: Int, $last: Int, $before: String, $after: String, $countryCode: CountryCode!) {
        geoLocationCountryRegions(first: $first, last: $last, before: $before, after: $after, countryCode: $countryCode) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...GeoLocation
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${GeoLocationFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class GeoLocationCountryRegionsQueryGQL extends Apollo.Query<
    GeoLocationCountryRegionsQuery,
    GeoLocationCountryRegionsQueryVariables
> {
    document = GeoLocationCountryRegionsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GeoLocationQueryDocument = gql`
    query GeoLocationQuery($id: UUID!) {
        geoLocation(id: $id) {
            ...GeoLocation
        }
    }
    ${GeoLocationFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class GeoLocationQueryGQL extends Apollo.Query<GeoLocationQuery, GeoLocationQueryVariables> {
    document = GeoLocationQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GeoLocationSearchQueryDocument = gql`
    query GeoLocationSearchQuery(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $countryCode: CountryCode!
        $type: GeoLocationSearchType!
        $query: String!
    ) {
        geoLocationSearch(
            first: $first
            last: $last
            before: $before
            after: $after
            countryCode: $countryCode
            type: $type
            query: $query
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...GeoLocation
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${GeoLocationFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class GeoLocationSearchQueryGQL extends Apollo.Query<GeoLocationSearchQuery, GeoLocationSearchQueryVariables> {
    document = GeoLocationSearchQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateGoalMutationDocument = gql`
    mutation CreateGoalMutation($input: CreateGoalInput!) {
        createGoal(input: $input) {
            goal {
                ...Goal
            }
        }
    }
    ${GoalFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateGoalMutationGQL extends Apollo.Mutation<CreateGoalMutation, CreateGoalMutationVariables> {
    document = CreateGoalMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateGoalNoteMutationDocument = gql`
    mutation CreateGoalNoteMutation($input: CreateGoalNoteInput!) {
        createGoalNote(input: $input) {
            goalNote {
                ...GoalNote
            }
        }
    }
    ${GoalNoteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateGoalNoteMutationGQL extends Apollo.Mutation<CreateGoalNoteMutation, CreateGoalNoteMutationVariables> {
    document = CreateGoalNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateGoalTaskMutationDocument = gql`
    mutation CreateGoalTaskMutation($input: CreateGoalTaskInput!) {
        createGoalTask(input: $input) {
            goalTask {
                ...GoalTask
            }
        }
    }
    ${GoalTaskFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateGoalTaskMutationGQL extends Apollo.Mutation<CreateGoalTaskMutation, CreateGoalTaskMutationVariables> {
    document = CreateGoalTaskMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteGoalMutationDocument = gql`
    mutation DeleteGoalMutation($input: DeleteGoalInput!) {
        deleteGoal(input: $input) {
            goalId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteGoalMutationGQL extends Apollo.Mutation<DeleteGoalMutation, DeleteGoalMutationVariables> {
    document = DeleteGoalMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteGoalNoteMutationDocument = gql`
    mutation DeleteGoalNoteMutation($input: DeleteGoalNoteInput!) {
        deleteGoalNote(input: $input) {
            goalNoteId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteGoalNoteMutationGQL extends Apollo.Mutation<DeleteGoalNoteMutation, DeleteGoalNoteMutationVariables> {
    document = DeleteGoalNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteGoalTaskMutationDocument = gql`
    mutation DeleteGoalTaskMutation($input: DeleteGoalTaskInput!) {
        deleteGoalTask(input: $input) {
            goalTaskId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteGoalTaskMutationGQL extends Apollo.Mutation<DeleteGoalTaskMutation, DeleteGoalTaskMutationVariables> {
    document = DeleteGoalTaskMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GoalQueryDocument = gql`
    query GoalQuery($goalId: UUID!) {
        goal(id: $goalId) {
            ...Goal
        }
    }
    ${GoalFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class GoalQueryGQL extends Apollo.Query<GoalQuery, GoalQueryVariables> {
    document = GoalQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyGoalsQueryDocument = gql`
    query MyGoalsQuery($first: Int, $last: Int, $before: String, $after: String, $types: [GoalType!], $query: String) {
        me {
            id
            goals(first: $first, last: $last, before: $before, after: $after, types: $types, query: $query) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...Goal
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${GoalFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyGoalsQueryGQL extends Apollo.Query<MyGoalsQuery, MyGoalsQueryVariables> {
    document = MyGoalsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateGoalMutationDocument = gql`
    mutation UpdateGoalMutation($input: UpdateGoalInput!) {
        updateGoal(input: $input) {
            goal {
                ...Goal
            }
        }
    }
    ${GoalFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateGoalMutationGQL extends Apollo.Mutation<UpdateGoalMutation, UpdateGoalMutationVariables> {
    document = UpdateGoalMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateGoalNoteMutationDocument = gql`
    mutation UpdateGoalNoteMutation($input: UpdateGoalNoteInput!) {
        updateGoalNote(input: $input) {
            goalNote {
                ...GoalNote
            }
        }
    }
    ${GoalNoteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateGoalNoteMutationGQL extends Apollo.Mutation<UpdateGoalNoteMutation, UpdateGoalNoteMutationVariables> {
    document = UpdateGoalNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateGoalTaskMutationDocument = gql`
    mutation UpdateGoalTaskMutation($input: UpdateGoalTaskInput!) {
        updateGoalTask(input: $input) {
            goalTask {
                ...GoalTask
            }
        }
    }
    ${GoalTaskFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateGoalTaskMutationGQL extends Apollo.Mutation<UpdateGoalTaskMutation, UpdateGoalTaskMutationVariables> {
    document = UpdateGoalTaskMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AttachProviderIntegrationLissToParentIntegrationMutationDocument = gql`
    mutation AttachProviderIntegrationLissToParentIntegrationMutation($input: AttachProviderIntegrationLissToParentIntegrationInput!) {
        attachProviderIntegrationLissToParentIntegration(input: $input) {
            providerIntegration {
                ...ProviderIntegrationLiss
            }
        }
    }
    ${ProviderIntegrationLissFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class AttachProviderIntegrationLissToParentIntegrationMutationGQL extends Apollo.Mutation<
    AttachProviderIntegrationLissToParentIntegrationMutation,
    AttachProviderIntegrationLissToParentIntegrationMutationVariables
> {
    document = AttachProviderIntegrationLissToParentIntegrationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderIntegrationMutationDocument = gql`
    mutation CreateProviderIntegrationMutation($input: CreateProviderIntegrationInput!) {
        createProviderIntegration(input: $input) {
            providerIntegration {
                ...ProviderIntegration
            }
        }
    }
    ${ProviderIntegrationFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderIntegrationMutationGQL extends Apollo.Mutation<
    CreateProviderIntegrationMutation,
    CreateProviderIntegrationMutationVariables
> {
    document = CreateProviderIntegrationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderIntegrationQueryDocument = gql`
    query ProviderIntegrationQuery($providerIntegrationId: UUID!) {
        providerIntegration(id: $providerIntegrationId) {
            ...ProviderIntegration
            ... on ProviderIntegrationEdgeLearning {
                ...ProviderIntegrationEdgeLearning
            }
            ... on ProviderIntegrationKamar {
                ...ProviderIntegrationKamar
            }
            ... on ProviderIntegrationLiss {
                ...ProviderIntegrationLiss
            }
            ... on ProviderIntegrationManualSmsData {
                ...ProviderIntegrationManualSmsData
            }
            ... on ProviderIntegrationWonde {
                ...ProviderIntegrationWonde
            }
        }
    }
    ${ProviderIntegrationFragmentDoc}
    ${ProviderIntegrationEdgeLearningFragmentDoc}
    ${ProviderIntegrationKamarFragmentDoc}
    ${ProviderIntegrationLissFragmentDoc}
    ${ProviderIntegrationManualSmsDataFragmentDoc}
    ${ProviderIntegrationWondeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderIntegrationQueryGQL extends Apollo.Query<ProviderIntegrationQuery, ProviderIntegrationQueryVariables> {
    document = ProviderIntegrationQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderIntegrationsQueryDocument = gql`
    query ProviderIntegrationsQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        provider(id: $providerId) {
            id
            integrations(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProviderIntegration
                    }
                }
            }
            availableIntegrationTypes
        }
    }
    ${PageInfoFragmentDoc}
    ${ProviderIntegrationFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderIntegrationsQueryGQL extends Apollo.Query<ProviderIntegrationsQuery, ProviderIntegrationsQueryVariables> {
    document = ProviderIntegrationsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QueueDeleteProviderIntegrationEdgeLearningMutationDocument = gql`
    mutation QueueDeleteProviderIntegrationEdgeLearningMutation($input: QueueDeleteProviderIntegrationEdgeLearningInput!) {
        queueDeleteProviderIntegrationEdgeLearning(input: $input) {
            success
            providerIntegration {
                ...ProviderIntegrationEdgeLearning
            }
        }
    }
    ${ProviderIntegrationEdgeLearningFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class QueueDeleteProviderIntegrationEdgeLearningMutationGQL extends Apollo.Mutation<
    QueueDeleteProviderIntegrationEdgeLearningMutation,
    QueueDeleteProviderIntegrationEdgeLearningMutationVariables
> {
    document = QueueDeleteProviderIntegrationEdgeLearningMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QueueDeleteProviderIntegrationKamarMutationDocument = gql`
    mutation QueueDeleteProviderIntegrationKamarMutation($input: QueueDeleteProviderIntegrationKamarInput!) {
        queueDeleteProviderIntegrationKamar(input: $input) {
            success
            providerIntegration {
                ...ProviderIntegrationKamar
            }
        }
    }
    ${ProviderIntegrationKamarFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class QueueDeleteProviderIntegrationKamarMutationGQL extends Apollo.Mutation<
    QueueDeleteProviderIntegrationKamarMutation,
    QueueDeleteProviderIntegrationKamarMutationVariables
> {
    document = QueueDeleteProviderIntegrationKamarMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QueueDeleteProviderIntegrationLissMutationDocument = gql`
    mutation QueueDeleteProviderIntegrationLissMutation($input: QueueDeleteProviderIntegrationLissInput!) {
        queueDeleteProviderIntegrationLiss(input: $input) {
            success
            providerIntegration {
                ...ProviderIntegrationLiss
            }
        }
    }
    ${ProviderIntegrationLissFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class QueueDeleteProviderIntegrationLissMutationGQL extends Apollo.Mutation<
    QueueDeleteProviderIntegrationLissMutation,
    QueueDeleteProviderIntegrationLissMutationVariables
> {
    document = QueueDeleteProviderIntegrationLissMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QueueDeleteProviderIntegrationManualSmsDataMutationDocument = gql`
    mutation QueueDeleteProviderIntegrationManualSmsDataMutation($input: QueueDeleteProviderIntegrationManualSmsDataInput!) {
        queueDeleteProviderIntegrationManualSmsData(input: $input) {
            success
            providerIntegration {
                ...ProviderIntegrationManualSmsData
            }
        }
    }
    ${ProviderIntegrationManualSmsDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class QueueDeleteProviderIntegrationManualSmsDataMutationGQL extends Apollo.Mutation<
    QueueDeleteProviderIntegrationManualSmsDataMutation,
    QueueDeleteProviderIntegrationManualSmsDataMutationVariables
> {
    document = QueueDeleteProviderIntegrationManualSmsDataMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QueueDeleteProviderIntegrationWondeMutationDocument = gql`
    mutation QueueDeleteProviderIntegrationWondeMutation($input: QueueDeleteProviderIntegrationWondeInput!) {
        queueDeleteProviderIntegrationWonde(input: $input) {
            success
            providerIntegration {
                ...ProviderIntegrationWonde
            }
        }
    }
    ${ProviderIntegrationWondeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class QueueDeleteProviderIntegrationWondeMutationGQL extends Apollo.Mutation<
    QueueDeleteProviderIntegrationWondeMutation,
    QueueDeleteProviderIntegrationWondeMutationVariables
> {
    document = QueueDeleteProviderIntegrationWondeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RegenerateProviderIntegrationKamarTokenMutationDocument = gql`
    mutation RegenerateProviderIntegrationKamarTokenMutation($input: RegenerateProviderIntegrationKamarTokenInput!) {
        regenerateProviderIntegrationKamarToken(input: $input) {
            providerIntegration {
                ...ProviderIntegrationKamar
            }
        }
    }
    ${ProviderIntegrationKamarFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RegenerateProviderIntegrationKamarTokenMutationGQL extends Apollo.Mutation<
    RegenerateProviderIntegrationKamarTokenMutation,
    RegenerateProviderIntegrationKamarTokenMutationVariables
> {
    document = RegenerateProviderIntegrationKamarTokenMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RegenerateProviderIntegrationLissTokenMutationDocument = gql`
    mutation RegenerateProviderIntegrationLissTokenMutation($input: RegenerateProviderIntegrationLissTokenInput!) {
        regenerateProviderIntegrationLissToken(input: $input) {
            providerIntegration {
                ...ProviderIntegrationLiss
            }
        }
    }
    ${ProviderIntegrationLissFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RegenerateProviderIntegrationLissTokenMutationGQL extends Apollo.Mutation<
    RegenerateProviderIntegrationLissTokenMutation,
    RegenerateProviderIntegrationLissTokenMutationVariables
> {
    document = RegenerateProviderIntegrationLissTokenMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ResyncProviderIntegrationWondeDataMutationDocument = gql`
    mutation ResyncProviderIntegrationWondeDataMutation($input: ResyncProviderIntegrationWondeDataInput!) {
        resyncProviderIntegrationWondeData(input: $input) {
            providerIntegration {
                ...ProviderIntegrationWonde
            }
        }
    }
    ${ProviderIntegrationWondeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ResyncProviderIntegrationWondeDataMutationGQL extends Apollo.Mutation<
    ResyncProviderIntegrationWondeDataMutation,
    ResyncProviderIntegrationWondeDataMutationVariables
> {
    document = ResyncProviderIntegrationWondeDataMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderIntegrationKamarTimetableDisabledUntilMutationDocument = gql`
    mutation UpdateProviderIntegrationKamarTimetableDisabledUntilMutation(
        $input: UpdateProviderIntegrationKamarTimetableDisabledUntilInput!
    ) {
        updateProviderIntegrationKamarTimetableDisabledUntil(input: $input) {
            providerIntegration {
                ...ProviderIntegrationKamar
            }
        }
    }
    ${ProviderIntegrationKamarFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderIntegrationKamarTimetableDisabledUntilMutationGQL extends Apollo.Mutation<
    UpdateProviderIntegrationKamarTimetableDisabledUntilMutation,
    UpdateProviderIntegrationKamarTimetableDisabledUntilMutationVariables
> {
    document = UpdateProviderIntegrationKamarTimetableDisabledUntilMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderIntegrationManualSmsDataEmployeesMutationDocument = gql`
    mutation UpdateProviderIntegrationManualSmsDataEmployeesMutation($input: UpdateProviderIntegrationManualSmsDataEmployeesInput!) {
        updateProviderIntegrationManualSmsDataEmployees(input: $input) {
            providerIntegration {
                ...ProviderIntegrationManualSmsData
            }
        }
    }
    ${ProviderIntegrationManualSmsDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderIntegrationManualSmsDataEmployeesMutationGQL extends Apollo.Mutation<
    UpdateProviderIntegrationManualSmsDataEmployeesMutation,
    UpdateProviderIntegrationManualSmsDataEmployeesMutationVariables
> {
    document = UpdateProviderIntegrationManualSmsDataEmployeesMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderIntegrationManualSmsDataGroupEmployeesMutationDocument = gql`
    mutation UpdateProviderIntegrationManualSmsDataGroupEmployeesMutation(
        $input: UpdateProviderIntegrationManualSmsDataGroupEmployeesInput!
    ) {
        updateProviderIntegrationManualSmsDataGroupEmployees(input: $input) {
            providerIntegration {
                ...ProviderIntegrationManualSmsData
            }
        }
    }
    ${ProviderIntegrationManualSmsDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderIntegrationManualSmsDataGroupEmployeesMutationGQL extends Apollo.Mutation<
    UpdateProviderIntegrationManualSmsDataGroupEmployeesMutation,
    UpdateProviderIntegrationManualSmsDataGroupEmployeesMutationVariables
> {
    document = UpdateProviderIntegrationManualSmsDataGroupEmployeesMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderIntegrationManualSmsDataGroupStudentsMutationDocument = gql`
    mutation UpdateProviderIntegrationManualSmsDataGroupStudentsMutation(
        $input: UpdateProviderIntegrationManualSmsDataGroupStudentsInput!
    ) {
        updateProviderIntegrationManualSmsDataGroupStudents(input: $input) {
            providerIntegration {
                ...ProviderIntegrationManualSmsData
            }
        }
    }
    ${ProviderIntegrationManualSmsDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderIntegrationManualSmsDataGroupStudentsMutationGQL extends Apollo.Mutation<
    UpdateProviderIntegrationManualSmsDataGroupStudentsMutation,
    UpdateProviderIntegrationManualSmsDataGroupStudentsMutationVariables
> {
    document = UpdateProviderIntegrationManualSmsDataGroupStudentsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderIntegrationManualSmsDataGroupsMutationDocument = gql`
    mutation UpdateProviderIntegrationManualSmsDataGroupsMutation($input: UpdateProviderIntegrationManualSmsDataGroupsInput!) {
        updateProviderIntegrationManualSmsDataGroups(input: $input) {
            providerIntegration {
                ...ProviderIntegrationManualSmsData
            }
        }
    }
    ${ProviderIntegrationManualSmsDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderIntegrationManualSmsDataGroupsMutationGQL extends Apollo.Mutation<
    UpdateProviderIntegrationManualSmsDataGroupsMutation,
    UpdateProviderIntegrationManualSmsDataGroupsMutationVariables
> {
    document = UpdateProviderIntegrationManualSmsDataGroupsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderIntegrationManualSmsDataStudentsMutationDocument = gql`
    mutation UpdateProviderIntegrationManualSmsDataStudentsMutation($input: UpdateProviderIntegrationManualSmsDataStudentsInput!) {
        updateProviderIntegrationManualSmsDataStudents(input: $input) {
            providerIntegration {
                ...ProviderIntegrationManualSmsData
            }
        }
    }
    ${ProviderIntegrationManualSmsDataFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderIntegrationManualSmsDataStudentsMutationGQL extends Apollo.Mutation<
    UpdateProviderIntegrationManualSmsDataStudentsMutation,
    UpdateProviderIntegrationManualSmsDataStudentsMutationVariables
> {
    document = UpdateProviderIntegrationManualSmsDataStudentsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyLearnerSmsProfilesQueryDocument = gql`
    query MyLearnerSmsProfilesQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            learnerSmsProfiles(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...LearnerSmsProfileDetailed
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${LearnerSmsProfileDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyLearnerSmsProfilesQueryGQL extends Apollo.Query<MyLearnerSmsProfilesQuery, MyLearnerSmsProfilesQueryVariables> {
    document = MyLearnerSmsProfilesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderLearnerSmsGroupsQueryDocument = gql`
    query ProviderLearnerSmsGroupsQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        provider(id: $providerId) {
            id
            learnerSmsGroups(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...LearnerSmsGroup
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${LearnerSmsGroupFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderLearnerSmsGroupsQueryGQL extends Apollo.Query<ProviderLearnerSmsGroupsQuery, ProviderLearnerSmsGroupsQueryVariables> {
    document = ProviderLearnerSmsGroupsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateLessonLocalizationMutationDocument = gql`
    mutation CreateLessonLocalizationMutation($input: CreateLessonLocalizationInput!) {
        createLessonLocalization(input: $input) {
            lesson {
                ...LessonWithLocalizations
            }
        }
    }
    ${LessonWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateLessonLocalizationMutationGQL extends Apollo.Mutation<
    CreateLessonLocalizationMutation,
    CreateLessonLocalizationMutationVariables
> {
    document = CreateLessonLocalizationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateLessonMutationDocument = gql`
    mutation CreateLessonMutation($input: CreateLessonInput!) {
        createLesson(input: $input) {
            lesson {
                ...LessonWithLocalizations
            }
        }
    }
    ${LessonWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateLessonMutationGQL extends Apollo.Mutation<CreateLessonMutation, CreateLessonMutationVariables> {
    document = CreateLessonMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteLessonLocalizationMutationDocument = gql`
    mutation DeleteLessonLocalizationMutation($input: DeleteLessonLocalizationInput!) {
        deleteLessonLocalization(input: $input) {
            lesson {
                ...LessonWithLocalizations
            }
        }
    }
    ${LessonWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteLessonLocalizationMutationGQL extends Apollo.Mutation<
    DeleteLessonLocalizationMutation,
    DeleteLessonLocalizationMutationVariables
> {
    document = DeleteLessonLocalizationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteLessonMutationDocument = gql`
    mutation DeleteLessonMutation($input: DeleteLessonInput!) {
        deleteLesson(input: $input) {
            lessonId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteLessonMutationGQL extends Apollo.Mutation<DeleteLessonMutation, DeleteLessonMutationVariables> {
    document = DeleteLessonMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LessonCategoriesQueryDocument = gql`
    query LessonCategoriesQuery($first: Int, $last: Int, $before: String, $after: String, $locale: Locale) {
        lessonCategories(first: $first, last: $last, before: $before, after: $after, locale: $locale) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...LessonCategory
                }
                cursor
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${LessonCategoryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class LessonCategoriesQueryGQL extends Apollo.Query<LessonCategoriesQuery, LessonCategoriesQueryVariables> {
    document = LessonCategoriesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LessonCategoriesWithLocalizationsQueryDocument = gql`
    query LessonCategoriesWithLocalizationsQuery($first: Int, $last: Int, $before: String, $after: String) {
        lessonCategories(first: $first, last: $last, before: $before, after: $after) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...LessonCategoryWithLocalizations
                }
                cursor
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${LessonCategoryWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class LessonCategoriesWithLocalizationsQueryGQL extends Apollo.Query<
    LessonCategoriesWithLocalizationsQuery,
    LessonCategoriesWithLocalizationsQueryVariables
> {
    document = LessonCategoriesWithLocalizationsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LessonQueryDocument = gql`
    query LessonQuery($lessonId: UUID!, $locale: Locale) {
        lesson(id: $lessonId, locale: $locale) {
            ...Lesson
        }
    }
    ${LessonFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class LessonQueryGQL extends Apollo.Query<LessonQuery, LessonQueryVariables> {
    document = LessonQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LessonTagsListQueryDocument = gql`
    query LessonTagsListQuery($locale: Locale) {
        lessonTagsList(locale: $locale)
    }
`;

@Injectable({
    providedIn: 'root'
})
export class LessonTagsListQueryGQL extends Apollo.Query<LessonTagsListQuery, LessonTagsListQueryVariables> {
    document = LessonTagsListQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LessonWithLocalizationsQueryDocument = gql`
    query LessonWithLocalizationsQuery($lessonId: UUID!) {
        lesson(id: $lessonId) {
            ...LessonWithLocalizations
        }
    }
    ${LessonWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class LessonWithLocalizationsQueryGQL extends Apollo.Query<LessonWithLocalizationsQuery, LessonWithLocalizationsQueryVariables> {
    document = LessonWithLocalizationsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LessonsSearchQueryDocument = gql`
    query LessonsSearchQuery(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $locale: Locale
        $query: String
        $countryCodes: [String!]
        $tags: [String!]
        $lessonCategoryId: String
        $isFeatured: Boolean
        $sort: LessonSortType
    ) {
        lessons(
            first: $first
            last: $last
            before: $before
            after: $after
            locale: $locale
            query: $query
            countryCodes: $countryCodes
            tags: $tags
            lessonCategoryId: $lessonCategoryId
            isFeatured: $isFeatured
            sort: $sort
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...LessonSearchResult
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${LessonSearchResultFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class LessonsSearchQueryGQL extends Apollo.Query<LessonsSearchQuery, LessonsSearchQueryVariables> {
    document = LessonsSearchQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LessonsWithLocalizationsSearchQueryDocument = gql`
    query LessonsWithLocalizationsSearchQuery(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $locale: Locale
        $query: String
        $countryCodes: [String!]
        $tags: [String!]
        $lessonCategoryId: String
        $isFeatured: Boolean
        $sort: LessonSortType
    ) {
        lessons(
            first: $first
            last: $last
            before: $before
            after: $after
            locale: $locale
            query: $query
            countryCodes: $countryCodes
            tags: $tags
            lessonCategoryId: $lessonCategoryId
            isFeatured: $isFeatured
            sort: $sort
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...LessonWithLocalizations
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${LessonWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class LessonsWithLocalizationsSearchQueryGQL extends Apollo.Query<
    LessonsWithLocalizationsSearchQuery,
    LessonsWithLocalizationsSearchQueryVariables
> {
    document = LessonsWithLocalizationsSearchQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ToggleFavoriteLessonMutationDocument = gql`
    mutation ToggleFavoriteLessonMutation($input: ToggleFavoriteLessonInput!) {
        toggleFavoriteLesson(input: $input) {
            lesson {
                id
                locale
                hasFavorited
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ToggleFavoriteLessonMutationGQL extends Apollo.Mutation<ToggleFavoriteLessonMutation, ToggleFavoriteLessonMutationVariables> {
    document = ToggleFavoriteLessonMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ToggleFeaturedLessonMutationDocument = gql`
    mutation ToggleFeaturedLessonMutation($input: ToggleFeaturedLessonInput!) {
        toggleFeaturedLesson(input: $input) {
            lesson {
                id
                locale
                isFeatured
                updatedAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ToggleFeaturedLessonMutationGQL extends Apollo.Mutation<ToggleFeaturedLessonMutation, ToggleFeaturedLessonMutationVariables> {
    document = ToggleFeaturedLessonMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ToggleProviderLessonAccessDocument = gql`
    mutation ToggleProviderLessonAccess($input: ToggleProviderLessonAccessInput!) {
        toggleProviderLessonAccess(input: $input) {
            provider {
                id
                isLessonsDisabled
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ToggleProviderLessonAccessGQL extends Apollo.Mutation<
    ToggleProviderLessonAccessMutation,
    ToggleProviderLessonAccessMutationVariables
> {
    document = ToggleProviderLessonAccessDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ToggleReadLessonMutationDocument = gql`
    mutation ToggleReadLessonMutation($input: ToggleReadLessonInput!) {
        toggleReadLesson(input: $input) {
            lesson {
                id
                locale
                hasRead
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ToggleReadLessonMutationGQL extends Apollo.Mutation<ToggleReadLessonMutation, ToggleReadLessonMutationVariables> {
    document = ToggleReadLessonMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateLessonLocalizationMutationDocument = gql`
    mutation UpdateLessonLocalizationMutation($input: UpdateLessonLocalizationInput!) {
        updateLessonLocalization(input: $input) {
            lesson {
                ...LessonWithLocalizations
            }
        }
    }
    ${LessonWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateLessonLocalizationMutationGQL extends Apollo.Mutation<
    UpdateLessonLocalizationMutation,
    UpdateLessonLocalizationMutationVariables
> {
    document = UpdateLessonLocalizationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateLessonMutationDocument = gql`
    mutation UpdateLessonMutation($input: UpdateLessonInput!) {
        updateLesson(input: $input) {
            lesson {
                ...LessonWithLocalizations
            }
        }
    }
    ${LessonWithLocalizationsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateLessonMutationGQL extends Apollo.Mutation<UpdateLessonMutation, UpdateLessonMutationVariables> {
    document = UpdateLessonMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AddMentorGroupMemberMutationDocument = gql`
    mutation AddMentorGroupMemberMutation($input: AddMentorGroupMemberInput!) {
        addMentorGroupMember(input: $input) {
            mentorGroupMember {
                ...MentorGroupMember
            }
        }
    }
    ${MentorGroupMemberFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class AddMentorGroupMemberMutationGQL extends Apollo.Mutation<AddMentorGroupMemberMutation, AddMentorGroupMemberMutationVariables> {
    document = AddMentorGroupMemberMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AddMentorGroupStudentMutationDocument = gql`
    mutation AddMentorGroupStudentMutation($input: AddMentorGroupStudentInput!) {
        addMentorGroupStudent(input: $input) {
            mentorGroupStudent {
                ...MentorGroupStudentSimple
            }
        }
    }
    ${MentorGroupStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class AddMentorGroupStudentMutationGQL extends Apollo.Mutation<
    AddMentorGroupStudentMutation,
    AddMentorGroupStudentMutationVariables
> {
    document = AddMentorGroupStudentMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMentorGroupMutationDocument = gql`
    mutation CreateMentorGroupMutation($input: CreateMentorGroupInput!) {
        createMentorGroup(input: $input) {
            mentorGroup {
                id
                title
                description
                createdAt
                updatedAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class CreateMentorGroupMutationGQL extends Apollo.Mutation<CreateMentorGroupMutation, CreateMentorGroupMutationVariables> {
    document = CreateMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMentorSharedResourceMutationDocument = gql`
    mutation CreateMentorSharedResourceMutation($input: CreateMentorSharedResourceInput!) {
        createMentorSharedResource(input: $input) {
            mentorSharedResource {
                ...MentorSharedResource
            }
        }
    }
    ${MentorSharedResourceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateMentorSharedResourceMutationGQL extends Apollo.Mutation<
    CreateMentorSharedResourceMutation,
    CreateMentorSharedResourceMutationVariables
> {
    document = CreateMentorSharedResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateStudentHomeworkDiaryMentorTaskMutationDocument = gql`
    mutation CreateStudentHomeworkDiaryMentorTaskMutation($input: CreateStudentHomeworkDiaryMentorTaskInput!) {
        createStudentHomeworkDiaryMentorTask(input: $input) {
            studentHomeworkDiaryMentorTask {
                ...StudentHomeworkDiaryMentorTask
            }
        }
    }
    ${StudentHomeworkDiaryMentorTaskFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateStudentHomeworkDiaryMentorTaskMutationGQL extends Apollo.Mutation<
    CreateStudentHomeworkDiaryMentorTaskMutation,
    CreateStudentHomeworkDiaryMentorTaskMutationVariables
> {
    document = CreateStudentHomeworkDiaryMentorTaskMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMentorGroupMutationDocument = gql`
    mutation DeleteMentorGroupMutation($input: DeleteMentorGroupInput!) {
        deleteMentorGroup(input: $input) {
            mentorGroupId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteMentorGroupMutationGQL extends Apollo.Mutation<DeleteMentorGroupMutation, DeleteMentorGroupMutationVariables> {
    document = DeleteMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMentorSharedResourceMutationDocument = gql`
    mutation DeleteMentorSharedResourceMutation($input: DeleteMentorSharedResourceInput!) {
        deleteMentorSharedResource(input: $input) {
            mentorSharedResourceId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteMentorSharedResourceMutationGQL extends Apollo.Mutation<
    DeleteMentorSharedResourceMutation,
    DeleteMentorSharedResourceMutationVariables
> {
    document = DeleteMentorSharedResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudentHomeworkDiaryMentorTaskMutationDocument = gql`
    mutation DeleteStudentHomeworkDiaryMentorTaskMutation($input: DeleteStudentHomeworkDiaryMentorTaskInput!) {
        deleteStudentHomeworkDiaryMentorTask(input: $input) {
            studentHomeworkDiaryMentorTaskId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteStudentHomeworkDiaryMentorTaskMutationGQL extends Apollo.Mutation<
    DeleteStudentHomeworkDiaryMentorTaskMutation,
    DeleteStudentHomeworkDiaryMentorTaskMutationVariables
> {
    document = DeleteStudentHomeworkDiaryMentorTaskMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DisconnectMentorGroupMutationDocument = gql`
    mutation DisconnectMentorGroupMutation($input: DisconnectMentorGroupInput!) {
        disconnectMentorGroup(input: $input) {
            mentorGroupId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DisconnectMentorGroupMutationGQL extends Apollo.Mutation<
    DisconnectMentorGroupMutation,
    DisconnectMentorGroupMutationVariables
> {
    document = DisconnectMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DisconnectMentorProviderMutationDocument = gql`
    mutation DisconnectMentorProviderMutation($input: DisconnectMentorProviderInput!) {
        disconnectMentorProvider(input: $input) {
            providerId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DisconnectMentorProviderMutationGQL extends Apollo.Mutation<
    DisconnectMentorProviderMutation,
    DisconnectMentorProviderMutationVariables
> {
    document = DisconnectMentorProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupInformationQueryDocument = gql`
    query MentorGroupInformationQuery($mentorGroupId: UUID!) {
        mentorGroup(id: $mentorGroupId) {
            id
            title
            information
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupInformationQueryGQL extends Apollo.Query<MentorGroupInformationQuery, MentorGroupInformationQueryVariables> {
    document = MentorGroupInformationQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupMembersQueryDocument = gql`
    query MentorGroupMembersQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            ...MentorGroupDetailed
            members(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorGroupMember
                    }
                }
            }
        }
    }
    ${MentorGroupDetailedFragmentDoc}
    ${PageInfoFragmentDoc}
    ${MentorGroupMemberFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupMembersQueryGQL extends Apollo.Query<MentorGroupMembersQuery, MentorGroupMembersQueryVariables> {
    document = MentorGroupMembersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupMentorStudentsQueryDocument = gql`
    query MentorGroupMentorStudentsQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            ...MentorGroupDetailed
            students(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        id
                        mentorStudent {
                            ...MentorStudentSimple
                        }
                    }
                }
            }
        }
    }
    ${MentorGroupDetailedFragmentDoc}
    ${PageInfoFragmentDoc}
    ${MentorStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupMentorStudentsQueryGQL extends Apollo.Query<
    MentorGroupMentorStudentsQuery,
    MentorGroupMentorStudentsQueryVariables
> {
    document = MentorGroupMentorStudentsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupMissingStudentsQueryDocument = gql`
    query MentorGroupMissingStudentsQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            id
            missingStudents(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        id
                        preferredFullName
                        preferredGivenName
                        preferredFamilyName
                        pictureUrl
                        idNumber
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupMissingStudentsQueryGQL extends Apollo.Query<
    MentorGroupMissingStudentsQuery,
    MentorGroupMissingStudentsQueryVariables
> {
    document = MentorGroupMissingStudentsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupQueryDocument = gql`
    query MentorGroupQuery($mentorGroupId: UUID!) {
        mentorGroup(id: $mentorGroupId) {
            ...MentorGroupDetailed
            students {
                totalCount
            }
        }
    }
    ${MentorGroupDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupQueryGQL extends Apollo.Query<MentorGroupQuery, MentorGroupQueryVariables> {
    document = MentorGroupQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupStudentsQueryDocument = gql`
    query MentorGroupStudentsQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            ...MentorGroupDetailed
            students(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorGroupStudentSimple
                        canRemove
                    }
                }
            }
        }
    }
    ${MentorGroupDetailedFragmentDoc}
    ${PageInfoFragmentDoc}
    ${MentorGroupStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupStudentsQueryGQL extends Apollo.Query<MentorGroupStudentsQuery, MentorGroupStudentsQueryVariables> {
    document = MentorGroupStudentsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupStudentsSimpleQueryDocument = gql`
    query MentorGroupStudentsSimpleQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            id
            students(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorGroupStudentSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorGroupStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupStudentsSimpleQueryGQL extends Apollo.Query<
    MentorGroupStudentsSimpleQuery,
    MentorGroupStudentsSimpleQueryVariables
> {
    document = MentorGroupStudentsSimpleQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupTitleQueryDocument = gql`
    query MentorGroupTitleQuery($mentorGroupId: UUID!) {
        mentorGroup(id: $mentorGroupId) {
            id
            title
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupTitleQueryGQL extends Apollo.Query<MentorGroupTitleQuery, MentorGroupTitleQueryVariables> {
    document = MentorGroupTitleQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderDailyNoticesQueryDocument = gql`
    query ProviderDailyNoticesQuery($providerId: UUID!, $start: DateTime!, $end: DateTime!) {
        provider(id: $providerId) {
            id
            dailyNotices: dailyNoticesList(start: $start, end: $end) {
                ...DailyNotice
            }
        }
    }
    ${DailyNoticeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderDailyNoticesQueryGQL extends Apollo.Query<ProviderDailyNoticesQuery, ProviderDailyNoticesQueryVariables> {
    document = ProviderDailyNoticesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorGroupSummariesQueryDocument = gql`
    query ProviderMentorGroupSummariesQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $query: String
        $ownedGroupsOnly: Boolean
    ) {
        me {
            id
        }
        provider(id: $providerId) {
            id
            mentorGroups(first: $first, last: $last, before: $before, after: $after, query: $query, ownedGroupsOnly: $ownedGroupsOnly) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorGroupDetailed
                        members(first: 1) {
                            totalCount
                            pageInfo {
                                ...PageInfo
                            }
                            edges {
                                node {
                                    ...MentorGroupMember
                                }
                                cursor
                            }
                        }
                        discussionUserState {
                            ...MentorGroupDiscussionUserState
                        }
                        students {
                            totalCount
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorGroupDetailedFragmentDoc}
    ${MentorGroupMemberFragmentDoc}
    ${MentorGroupDiscussionUserStateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorGroupSummariesQueryGQL extends Apollo.Query<
    ProviderMentorGroupSummariesQuery,
    ProviderMentorGroupSummariesQueryVariables
> {
    document = ProviderMentorGroupSummariesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorGroupsSimpleQueryDocument = gql`
    query ProviderMentorGroupsSimpleQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        provider(id: $providerId) {
            id
            mentorGroups(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorGroupSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorGroupSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorGroupsSimpleQueryGQL extends Apollo.Query<
    ProviderMentorGroupsSimpleQuery,
    ProviderMentorGroupsSimpleQueryVariables
> {
    document = ProviderMentorGroupsSimpleQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorGroupsUnreadMessageCountQueryDocument = gql`
    query ProviderMentorGroupsUnreadMessageCountQuery($providerId: UUID!) {
        provider(id: $providerId) {
            id
            mentorGroups(first: 0) {
                unreadMessageCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorGroupsUnreadMessageCountQueryGQL extends Apollo.Query<
    ProviderMentorGroupsUnreadMessageCountQuery,
    ProviderMentorGroupsUnreadMessageCountQueryVariables
> {
    document = ProviderMentorGroupsUnreadMessageCountQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorSharedResourcesQueryDocument = gql`
    query ProviderMentorSharedResourcesQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        provider(id: $providerId) {
            id
            mentorSharedResources(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorSharedResource
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorSharedResourceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorSharedResourcesQueryGQL extends Apollo.Query<
    ProviderMentorSharedResourcesQuery,
    ProviderMentorSharedResourcesQueryVariables
> {
    document = ProviderMentorSharedResourcesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QueueImportMentorGroupsFromLearnerSmsDataMutationDocument = gql`
    mutation QueueImportMentorGroupsFromLearnerSmsDataMutation($input: QueueImportMentorGroupsFromLearnerSmsDataInput!) {
        queueImportMentorGroupsFromLearnerSmsData(input: $input) {
            success
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class QueueImportMentorGroupsFromLearnerSmsDataMutationGQL extends Apollo.Mutation<
    QueueImportMentorGroupsFromLearnerSmsDataMutation,
    QueueImportMentorGroupsFromLearnerSmsDataMutationVariables
> {
    document = QueueImportMentorGroupsFromLearnerSmsDataMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RegenerateMentorGroupInviteCodeMutationDocument = gql`
    mutation RegenerateMentorGroupInviteCodeMutation($input: RegenerateMentorGroupInviteCodeInput!) {
        regenerateMentorGroupInviteCode(input: $input) {
            mentorGroup {
                ...MentorGroupSimple
            }
        }
    }
    ${MentorGroupSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RegenerateMentorGroupInviteCodeMutationGQL extends Apollo.Mutation<
    RegenerateMentorGroupInviteCodeMutation,
    RegenerateMentorGroupInviteCodeMutationVariables
> {
    document = RegenerateMentorGroupInviteCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RemoveMentorGroupMemberMutationDocument = gql`
    mutation RemoveMentorGroupMemberMutation($input: RemoveMentorGroupMemberInput!) {
        removeMentorGroupMember(input: $input) {
            mentorGroupMemberId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class RemoveMentorGroupMemberMutationGQL extends Apollo.Mutation<
    RemoveMentorGroupMemberMutation,
    RemoveMentorGroupMemberMutationVariables
> {
    document = RemoveMentorGroupMemberMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RemoveMentorGroupStudentMutationDocument = gql`
    mutation RemoveMentorGroupStudentMutation($input: RemoveMentorGroupStudentInput!) {
        removeMentorGroupStudent(input: $input) {
            mentorGroupStudentId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class RemoveMentorGroupStudentMutationGQL extends Apollo.Mutation<
    RemoveMentorGroupStudentMutation,
    RemoveMentorGroupStudentMutationVariables
> {
    document = RemoveMentorGroupStudentMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SendMentorGroupInvitesMutationDocument = gql`
    mutation SendMentorGroupInvitesMutation($input: SendMentorGroupInvitesInput!) {
        sendMentorGroupInvites(input: $input) {
            invites {
                ...MentorStudentInvite
            }
        }
    }
    ${MentorStudentInviteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SendMentorGroupInvitesMutationGQL extends Apollo.Mutation<
    SendMentorGroupInvitesMutation,
    SendMentorGroupInvitesMutationVariables
> {
    document = SendMentorGroupInvitesMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudentHomeworkDiaryDailyMentorTasksQueryDocument = gql`
    query StudentHomeworkDiaryDailyMentorTasksQuery(
        $mentorGroupId: UUID!
        $date: Date!
        $first: Int
        $last: Int
        $before: String
        $after: String
    ) {
        mentorGroup(id: $mentorGroupId) {
            id
            studentHomeworkDiaryDailyMentorTasks(date: $date, first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...StudentHomeworkDiaryMentorTask
                    }
                    cursor
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudentHomeworkDiaryMentorTaskFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudentHomeworkDiaryDailyMentorTasksQueryGQL extends Apollo.Query<
    StudentHomeworkDiaryDailyMentorTasksQuery,
    StudentHomeworkDiaryDailyMentorTasksQueryVariables
> {
    document = StudentHomeworkDiaryDailyMentorTasksQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudentHomeworkDiaryMentorTaskMonthSummaryQueryDocument = gql`
    query StudentHomeworkDiaryMentorTaskMonthSummaryQuery($mentorGroupId: UUID!, $date: Date!) {
        mentorGroup(id: $mentorGroupId) {
            id
            studentHomeworkDiaryMentorTaskMonthSummary(date: $date) {
                days {
                    date
                    colors
                }
                colors
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class StudentHomeworkDiaryMentorTaskMonthSummaryQueryGQL extends Apollo.Query<
    StudentHomeworkDiaryMentorTaskMonthSummaryQuery,
    StudentHomeworkDiaryMentorTaskMonthSummaryQueryVariables
> {
    document = StudentHomeworkDiaryMentorTaskMonthSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudentHomeworkDiaryMentorTaskStudentsAssignedQueryDocument = gql`
    query StudentHomeworkDiaryMentorTaskStudentsAssignedQuery(
        $studentHomeworkDiaryMentorTaskId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
    ) {
        studentHomeworkDiaryMentorTask(id: $studentHomeworkDiaryMentorTaskId) {
            id
            studentsAssigned(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                edges {
                    cursor
                    node {
                        ...MentorStudentSimple
                    }
                }
                pageInfo {
                    ...PageInfo
                }
            }
        }
    }
    ${MentorStudentSimpleFragmentDoc}
    ${PageInfoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudentHomeworkDiaryMentorTaskStudentsAssignedQueryGQL extends Apollo.Query<
    StudentHomeworkDiaryMentorTaskStudentsAssignedQuery,
    StudentHomeworkDiaryMentorTaskStudentsAssignedQueryVariables
> {
    document = StudentHomeworkDiaryMentorTaskStudentsAssignedQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudentHomeworkDiaryMentorTaskStudentsCompletedQueryDocument = gql`
    query StudentHomeworkDiaryMentorTaskStudentsCompletedQuery(
        $studentHomeworkDiaryMentorTaskId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
    ) {
        studentHomeworkDiaryMentorTask(id: $studentHomeworkDiaryMentorTaskId) {
            id
            studentsCompleted(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                edges {
                    cursor
                    node {
                        ...MentorStudentSimple
                    }
                }
                pageInfo {
                    ...PageInfo
                }
            }
        }
    }
    ${MentorStudentSimpleFragmentDoc}
    ${PageInfoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class StudentHomeworkDiaryMentorTaskStudentsCompletedQueryGQL extends Apollo.Query<
    StudentHomeworkDiaryMentorTaskStudentsCompletedQuery,
    StudentHomeworkDiaryMentorTaskStudentsCompletedQueryVariables
> {
    document = StudentHomeworkDiaryMentorTaskStudentsCompletedQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ToggleMentorGroupJoinabilityMutationDocument = gql`
    mutation ToggleMentorGroupJoinabilityMutation($input: ToggleMentorGroupJoinabilityInput!) {
        toggleMentorGroupJoinability(input: $input) {
            mentorGroup {
                ...MentorGroupSimple
                isJoinable
            }
        }
    }
    ${MentorGroupSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ToggleMentorGroupJoinabilityMutationGQL extends Apollo.Mutation<
    ToggleMentorGroupJoinabilityMutation,
    ToggleMentorGroupJoinabilityMutationVariables
> {
    document = ToggleMentorGroupJoinabilityMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TogglePinnedMentorGroupMutationDocument = gql`
    mutation TogglePinnedMentorGroupMutation($input: TogglePinnedMentorGroupInput!) {
        togglePinnedMentorGroup(input: $input) {
            mentorGroup {
                ...MentorGroupDetailed
            }
        }
    }
    ${MentorGroupDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class TogglePinnedMentorGroupMutationGQL extends Apollo.Mutation<
    TogglePinnedMentorGroupMutation,
    TogglePinnedMentorGroupMutationVariables
> {
    document = TogglePinnedMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorGroupBannerImageMutationDocument = gql`
    mutation UpdateMentorGroupBannerImageMutation($input: UpdateMentorGroupBannerImageInput!) {
        updateMentorGroupBannerImage(input: $input) {
            mentorGroup {
                ...MentorGroupSimple
            }
        }
    }
    ${MentorGroupSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorGroupBannerImageMutationGQL extends Apollo.Mutation<
    UpdateMentorGroupBannerImageMutation,
    UpdateMentorGroupBannerImageMutationVariables
> {
    document = UpdateMentorGroupBannerImageMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorGroupInformationMutationDocument = gql`
    mutation UpdateMentorGroupInformationMutation($input: UpdateMentorGroupInformationInput!) {
        updateMentorGroupInformation(input: $input) {
            mentorGroup {
                id
                information
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorGroupInformationMutationGQL extends Apollo.Mutation<
    UpdateMentorGroupInformationMutation,
    UpdateMentorGroupInformationMutationVariables
> {
    document = UpdateMentorGroupInformationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorGroupMutationDocument = gql`
    mutation UpdateMentorGroupMutation($input: UpdateMentorGroupInput!) {
        updateMentorGroup(input: $input) {
            mentorGroup {
                id
                title
                description
                createdAt
                updatedAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorGroupMutationGQL extends Apollo.Mutation<UpdateMentorGroupMutation, UpdateMentorGroupMutationVariables> {
    document = UpdateMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorSharedResourceMutationDocument = gql`
    mutation UpdateMentorSharedResourceMutation($input: UpdateMentorSharedResourceInput!) {
        updateMentorSharedResource(input: $input) {
            mentorSharedResource {
                ...MentorSharedResource
            }
        }
    }
    ${MentorSharedResourceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorSharedResourceMutationGQL extends Apollo.Mutation<
    UpdateMentorSharedResourceMutation,
    UpdateMentorSharedResourceMutationVariables
> {
    document = UpdateMentorSharedResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateStudentHomeworkDiaryMentorTaskMutationDocument = gql`
    mutation UpdateStudentHomeworkDiaryMentorTaskMutation($input: UpdateStudentHomeworkDiaryMentorTaskInput!) {
        updateStudentHomeworkDiaryMentorTask(input: $input) {
            studentHomeworkDiaryMentorTask {
                ...StudentHomeworkDiaryMentorTask
            }
        }
    }
    ${StudentHomeworkDiaryMentorTaskFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateStudentHomeworkDiaryMentorTaskMutationGQL extends Apollo.Mutation<
    UpdateStudentHomeworkDiaryMentorTaskMutation,
    UpdateStudentHomeworkDiaryMentorTaskMutationVariables
> {
    document = UpdateStudentHomeworkDiaryMentorTaskMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AcknowledgeMentorGroupDiscussionMessageDocument = gql`
    mutation AcknowledgeMentorGroupDiscussionMessage($input: AcknowledgeMentorGroupDiscussionMessageInput!) {
        acknowledgeMentorGroupDiscussionMessage(input: $input) {
            mentorGroupDiscussionMessage {
                ...MentorGroupDiscussionMessage
            }
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class AcknowledgeMentorGroupDiscussionMessageGQL extends Apollo.Mutation<
    AcknowledgeMentorGroupDiscussionMessageMutation,
    AcknowledgeMentorGroupDiscussionMessageMutationVariables
> {
    document = AcknowledgeMentorGroupDiscussionMessageDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ChooseMentorGroupDiscussionPollResponseMutationDocument = gql`
    mutation ChooseMentorGroupDiscussionPollResponseMutation($input: ChooseMentorGroupDiscussionPollResponseInput!) {
        chooseMentorGroupDiscussionPollResponse(input: $input) {
            mentorGroupDiscussionMessagePollResponse {
                ...MentorGroupDiscussionMessagePollResponse
            }
            mentorGroupDiscussionMessage {
                ...MentorGroupDiscussionMessage
            }
        }
    }
    ${MentorGroupDiscussionMessagePollResponseFragmentDoc}
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ChooseMentorGroupDiscussionPollResponseMutationGQL extends Apollo.Mutation<
    ChooseMentorGroupDiscussionPollResponseMutation,
    ChooseMentorGroupDiscussionPollResponseMutationVariables
> {
    document = ChooseMentorGroupDiscussionPollResponseMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMentorGroupDiscussionMessageDocument = gql`
    mutation DeleteMentorGroupDiscussionMessage($input: DeleteMentorGroupDiscussionMessageInput!) {
        deleteMentorGroupDiscussionMessage(input: $input) {
            mentorGroupDiscussionMessageId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteMentorGroupDiscussionMessageGQL extends Apollo.Mutation<
    DeleteMentorGroupDiscussionMessageMutation,
    DeleteMentorGroupDiscussionMessageMutationVariables
> {
    document = DeleteMentorGroupDiscussionMessageDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LockMentorGroupDiscussionDocument = gql`
    mutation LockMentorGroupDiscussion($input: LockMentorGroupDiscussionInput!) {
        lockMentorGroupDiscussion(input: $input) {
            mentorGroup {
                ...MentorGroupForDiscussion
            }
        }
    }
    ${MentorGroupForDiscussionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class LockMentorGroupDiscussionGQL extends Apollo.Mutation<
    LockMentorGroupDiscussionMutation,
    LockMentorGroupDiscussionMutationVariables
> {
    document = LockMentorGroupDiscussionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionMessageAcknowledgementStatusesQueryDocument = gql`
    query MentorGroupDiscussionMessageAcknowledgementStatusesQuery(
        $messageId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
    ) {
        mentorGroupDiscussionMessage(id: $messageId) {
            id
            acknowledgementStatuses(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                totalAcknowledgedCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorGroupDiscussionMessageAcknowledgementStatus
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorGroupDiscussionMessageAcknowledgementStatusFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionMessageAcknowledgementStatusesQueryGQL extends Apollo.Query<
    MentorGroupDiscussionMessageAcknowledgementStatusesQuery,
    MentorGroupDiscussionMessageAcknowledgementStatusesQueryVariables
> {
    document = MentorGroupDiscussionMessageAcknowledgementStatusesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionMessageChangedSubscriptionDocument = gql`
    subscription MentorGroupDiscussionMessageChangedSubscription($mentorGroupId: UUID!) {
        mentorGroupDiscussionMessageChanged(mentorGroupId: $mentorGroupId) {
            ...MentorGroupDiscussionMessage
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionMessageChangedSubscriptionGQL extends Apollo.Subscription<
    MentorGroupDiscussionMessageChangedSubscription,
    MentorGroupDiscussionMessageChangedSubscriptionVariables
> {
    document = MentorGroupDiscussionMessageChangedSubscriptionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionMessageDeletedSubscriptionDocument = gql`
    subscription MentorGroupDiscussionMessageDeletedSubscription($mentorGroupId: UUID!) {
        mentorGroupDiscussionMessageDeleted(mentorGroupId: $mentorGroupId)
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionMessageDeletedSubscriptionGQL extends Apollo.Subscription<
    MentorGroupDiscussionMessageDeletedSubscription,
    MentorGroupDiscussionMessageDeletedSubscriptionVariables
> {
    document = MentorGroupDiscussionMessageDeletedSubscriptionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionMessageQueryDocument = gql`
    query MentorGroupDiscussionMessageQuery($messageId: UUID!) {
        mentorGroupDiscussionMessage(id: $messageId) {
            ...MentorGroupDiscussionMessage
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionMessageQueryGQL extends Apollo.Query<
    MentorGroupDiscussionMessageQuery,
    MentorGroupDiscussionMessageQueryVariables
> {
    document = MentorGroupDiscussionMessageQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionMessageSentSubscriptionDocument = gql`
    subscription MentorGroupDiscussionMessageSentSubscription($mentorGroupId: UUID!) {
        mentorGroupDiscussionMessageSent(mentorGroupId: $mentorGroupId) {
            ...MentorGroupDiscussionMessage
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionMessageSentSubscriptionGQL extends Apollo.Subscription<
    MentorGroupDiscussionMessageSentSubscription,
    MentorGroupDiscussionMessageSentSubscriptionVariables
> {
    document = MentorGroupDiscussionMessageSentSubscriptionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionMessagesQueryDocument = gql`
    query MentorGroupDiscussionMessagesQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            id
            discussionMessages(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorGroupDiscussionMessage
                    }
                }
            }
            discussionUserState {
                ...MentorGroupDiscussionUserState
            }
            canSendDiscussionMessage
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorGroupDiscussionMessageFragmentDoc}
    ${MentorGroupDiscussionUserStateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionMessagesQueryGQL extends Apollo.Query<
    MentorGroupDiscussionMessagesQuery,
    MentorGroupDiscussionMessagesQueryVariables
> {
    document = MentorGroupDiscussionMessagesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionMutedUsersQueryDocument = gql`
    query MentorGroupDiscussionMutedUsersQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            id
            mentorGroupDiscussionMutedUsers(first: $first, last: $last, before: $before, after: $after) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...User
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${UserFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionMutedUsersQueryGQL extends Apollo.Query<
    MentorGroupDiscussionMutedUsersQuery,
    MentorGroupDiscussionMutedUsersQueryVariables
> {
    document = MentorGroupDiscussionMutedUsersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupDiscussionQueryDocument = gql`
    query MentorGroupDiscussionQuery($mentorGroupId: UUID!) {
        me {
            id
        }
        mentorGroup(id: $mentorGroupId) {
            ...MentorGroupForDiscussion
        }
    }
    ${MentorGroupForDiscussionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupDiscussionQueryGQL extends Apollo.Query<MentorGroupDiscussionQuery, MentorGroupDiscussionQueryVariables> {
    document = MentorGroupDiscussionQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MuteMentorGroupDiscussionUserDocument = gql`
    mutation MuteMentorGroupDiscussionUser($input: MuteMentorGroupDiscussionUserInput!) {
        muteMentorGroupDiscussionUser(input: $input) {
            mentorGroup {
                id
                mentorGroupDiscussionMutedUsers {
                    totalCount
                    pageInfo {
                        ...PageInfo
                    }
                    edges {
                        cursor
                        node {
                            ...User
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${UserFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MuteMentorGroupDiscussionUserGQL extends Apollo.Mutation<
    MuteMentorGroupDiscussionUserMutation,
    MuteMentorGroupDiscussionUserMutationVariables
> {
    document = MuteMentorGroupDiscussionUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PinMentorGroupDiscussionMessageDocument = gql`
    mutation PinMentorGroupDiscussionMessage($input: PinMentorGroupDiscussionMessageInput!) {
        pinMentorGroupDiscussionMessage(input: $input) {
            mentorGroupDiscussionMessage {
                ...MentorGroupDiscussionMessage
            }
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class PinMentorGroupDiscussionMessageGQL extends Apollo.Mutation<
    PinMentorGroupDiscussionMessageMutation,
    PinMentorGroupDiscussionMessageMutationVariables
> {
    document = PinMentorGroupDiscussionMessageDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReactMentorGroupDiscussionMessageMutationDocument = gql`
    mutation ReactMentorGroupDiscussionMessageMutation($input: ReactMentorGroupDiscussionMessageInput!) {
        reactMentorGroupDiscussionMessage(input: $input) {
            mentorGroupDiscussionMessage {
                ...MentorGroupDiscussionMessage
            }
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ReactMentorGroupDiscussionMessageMutationGQL extends Apollo.Mutation<
    ReactMentorGroupDiscussionMessageMutation,
    ReactMentorGroupDiscussionMessageMutationVariables
> {
    document = ReactMentorGroupDiscussionMessageMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SendMentorGroupDiscussionMessageDocument = gql`
    mutation SendMentorGroupDiscussionMessage($input: SendMentorGroupDiscussionMessageInput!) {
        sendMentorGroupDiscussionMessage(input: $input) {
            mentorGroupDiscussionMessage {
                ...MentorGroupDiscussionMessage
            }
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SendMentorGroupDiscussionMessageGQL extends Apollo.Mutation<
    SendMentorGroupDiscussionMessageMutation,
    SendMentorGroupDiscussionMessageMutationVariables
> {
    document = SendMentorGroupDiscussionMessageDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StartMentorGroupDiscussionMessageImageUploadDocument = gql`
    mutation startMentorGroupDiscussionMessageImageUpload($input: StartMentorGroupDiscussionMessageImageUploadInput!) {
        startMentorGroupDiscussionMessageImageUpload(input: $input) {
            fileId
            uploadUrl
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class StartMentorGroupDiscussionMessageImageUploadGQL extends Apollo.Mutation<
    StartMentorGroupDiscussionMessageImageUploadMutation,
    StartMentorGroupDiscussionMessageImageUploadMutationVariables
> {
    document = StartMentorGroupDiscussionMessageImageUploadDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnlockMentorGroupDiscussionDocument = gql`
    mutation UnlockMentorGroupDiscussion($input: UnlockMentorGroupDiscussionInput!) {
        unlockMentorGroupDiscussion(input: $input) {
            mentorGroup {
                ...MentorGroupForDiscussion
            }
        }
    }
    ${MentorGroupForDiscussionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnlockMentorGroupDiscussionGQL extends Apollo.Mutation<
    UnlockMentorGroupDiscussionMutation,
    UnlockMentorGroupDiscussionMutationVariables
> {
    document = UnlockMentorGroupDiscussionDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnmuteMentorGroupDiscussionUserDocument = gql`
    mutation UnmuteMentorGroupDiscussionUser($input: UnmuteMentorGroupDiscussionUserInput!) {
        unmuteMentorGroupDiscussionUser(input: $input) {
            mentorGroup {
                id
                mentorGroupDiscussionMutedUsers {
                    totalCount
                    pageInfo {
                        ...PageInfo
                    }
                    edges {
                        cursor
                        node {
                            ...User
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${UserFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnmuteMentorGroupDiscussionUserGQL extends Apollo.Mutation<
    UnmuteMentorGroupDiscussionUserMutation,
    UnmuteMentorGroupDiscussionUserMutationVariables
> {
    document = UnmuteMentorGroupDiscussionUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnpinMentorGroupDiscussionMessageDocument = gql`
    mutation UnpinMentorGroupDiscussionMessage($input: UnpinMentorGroupDiscussionMessageInput!) {
        unpinMentorGroupDiscussionMessage(input: $input) {
            mentorGroupDiscussionMessage {
                ...MentorGroupDiscussionMessage
            }
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnpinMentorGroupDiscussionMessageGQL extends Apollo.Mutation<
    UnpinMentorGroupDiscussionMessageMutation,
    UnpinMentorGroupDiscussionMessageMutationVariables
> {
    document = UnpinMentorGroupDiscussionMessageDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnreactMentorGroupDiscussionMessageMutationDocument = gql`
    mutation UnreactMentorGroupDiscussionMessageMutation($input: UnreactMentorGroupDiscussionMessageInput!) {
        unreactMentorGroupDiscussionMessage(input: $input) {
            mentorGroupDiscussionMessage {
                ...MentorGroupDiscussionMessage
            }
        }
    }
    ${MentorGroupDiscussionMessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnreactMentorGroupDiscussionMessageMutationGQL extends Apollo.Mutation<
    UnreactMentorGroupDiscussionMessageMutation,
    UnreactMentorGroupDiscussionMessageMutationVariables
> {
    document = UnreactMentorGroupDiscussionMessageMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorGroupDiscussionLastReadMutationDocument = gql`
    mutation UpdateMentorGroupDiscussionLastReadMutation($input: UpdateMentorGroupDiscussionLastReadInput!) {
        updateMentorGroupDiscussionLastRead(input: $input) {
            mentorGroupDiscussionUserState {
                ...MentorGroupDiscussionUserState
            }
        }
    }
    ${MentorGroupDiscussionUserStateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorGroupDiscussionLastReadMutationGQL extends Apollo.Mutation<
    UpdateMentorGroupDiscussionLastReadMutation,
    UpdateMentorGroupDiscussionLastReadMutationVariables
> {
    document = UpdateMentorGroupDiscussionLastReadMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorGroupDiscussionLastTypingMutationDocument = gql`
    mutation UpdateMentorGroupDiscussionLastTypingMutation($input: UpdateMentorGroupDiscussionLastTypingInput!) {
        updateMentorGroupDiscussionLastTyping(input: $input) {
            mentorGroupDiscussionUserState {
                ...MentorGroupDiscussionUserState
            }
        }
    }
    ${MentorGroupDiscussionUserStateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorGroupDiscussionLastTypingMutationGQL extends Apollo.Mutation<
    UpdateMentorGroupDiscussionLastTypingMutation,
    UpdateMentorGroupDiscussionLastTypingMutationVariables
> {
    document = UpdateMentorGroupDiscussionLastTypingMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AcceptMentorStudentInviteCodeMutationDocument = gql`
    mutation AcceptMentorStudentInviteCodeMutation($input: AcceptMentorStudentInviteCodeInput!) {
        acceptMentorStudentInviteCode(input: $input) {
            mentorStudent {
                ...MentorStudentSimple
                provider {
                    ...ProviderSimple
                }
            }
        }
    }
    ${MentorStudentSimpleFragmentDoc}
    ${ProviderSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class AcceptMentorStudentInviteCodeMutationGQL extends Apollo.Mutation<
    AcceptMentorStudentInviteCodeMutation,
    AcceptMentorStudentInviteCodeMutationVariables
> {
    document = AcceptMentorStudentInviteCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMentorStudentNoteMutationDocument = gql`
    mutation CreateMentorStudentNoteMutation($input: CreateMentorStudentNoteInput!) {
        createMentorStudentNote(input: $input) {
            mentorStudentNote {
                ...MentorStudentNote
            }
        }
    }
    ${MentorStudentNoteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateMentorStudentNoteMutationGQL extends Apollo.Mutation<
    CreateMentorStudentNoteMutation,
    CreateMentorStudentNoteMutationVariables
> {
    document = CreateMentorStudentNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMentorStudentTodoMutationDocument = gql`
    mutation CreateMentorStudentTodoMutation($input: CreateMentorStudentTodoInput!) {
        createMentorStudentTodo(input: $input) {
            mentorStudentTodo {
                ...MentorStudentTodo
            }
        }
    }
    ${MentorStudentTodoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateMentorStudentTodoMutationGQL extends Apollo.Mutation<
    CreateMentorStudentTodoMutation,
    CreateMentorStudentTodoMutationVariables
> {
    document = CreateMentorStudentTodoMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMentorStudentNoteMutationDocument = gql`
    mutation DeleteMentorStudentNoteMutation($input: DeleteMentorStudentNoteInput!) {
        deleteMentorStudentNote(input: $input) {
            mentorStudentNoteId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteMentorStudentNoteMutationGQL extends Apollo.Mutation<
    DeleteMentorStudentNoteMutation,
    DeleteMentorStudentNoteMutationVariables
> {
    document = DeleteMentorStudentNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMentorStudentTodoMutationDocument = gql`
    mutation DeleteMentorStudentTodoMutation($input: DeleteMentorStudentTodoInput!) {
        deleteMentorStudentTodo(input: $input) {
            mentorStudentTodoId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteMentorStudentTodoMutationGQL extends Apollo.Mutation<
    DeleteMentorStudentTodoMutation,
    DeleteMentorStudentTodoMutationVariables
> {
    document = DeleteMentorStudentTodoMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const JoinAvailableMentorGroupMutationDocument = gql`
    mutation JoinAvailableMentorGroupMutation($input: JoinAvailableMentorGroupInput!) {
        joinAvailableMentorGroup(input: $input) {
            mentorGroup {
                ...MentorGroupForJoining
            }
        }
    }
    ${MentorGroupForJoiningFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class JoinAvailableMentorGroupMutationGQL extends Apollo.Mutation<
    JoinAvailableMentorGroupMutation,
    JoinAvailableMentorGroupMutationVariables
> {
    document = JoinAvailableMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const JoinAvailableMentorProviderMutationDocument = gql`
    mutation JoinAvailableMentorProviderMutation($input: JoinAvailableMentorProviderInput!) {
        joinAvailableMentorProvider(input: $input) {
            mentorStudent {
                ...MentorStudentSimple
                provider {
                    ...ProviderSimple
                }
            }
        }
    }
    ${MentorStudentSimpleFragmentDoc}
    ${ProviderSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class JoinAvailableMentorProviderMutationGQL extends Apollo.Mutation<
    JoinAvailableMentorProviderMutation,
    JoinAvailableMentorProviderMutationVariables
> {
    document = JoinAvailableMentorProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const JoinJoinableMentorGroupMutationDocument = gql`
    mutation JoinJoinableMentorGroupMutation($input: JoinJoinableMentorGroupInput!) {
        joinJoinableMentorGroup(input: $input) {
            joinableMentorGroup {
                ...JoinableMentorGroupSummary
            }
            mentorGroup {
                ...MentorGroupForJoining
            }
        }
    }
    ${JoinableMentorGroupSummaryFragmentDoc}
    ${MentorGroupForJoiningFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class JoinJoinableMentorGroupMutationGQL extends Apollo.Mutation<
    JoinJoinableMentorGroupMutation,
    JoinJoinableMentorGroupMutationVariables
> {
    document = JoinJoinableMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const JoinableMentorGroupsQueryDocument = gql`
    query JoinableMentorGroupsQuery(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $countryCode: CountryCode
        $privateOnly: Boolean
    ) {
        joinableMentorGroups(
            first: $first
            last: $last
            before: $before
            after: $after
            countryCode: $countryCode
            privateOnly: $privateOnly
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...JoinableMentorGroupSummary
                }
                cursor
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${JoinableMentorGroupSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class JoinableMentorGroupsQueryGQL extends Apollo.Query<JoinableMentorGroupsQuery, JoinableMentorGroupsQueryVariables> {
    document = JoinableMentorGroupsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentAssessmentResultsQueryDocument = gql`
    query MentorStudentAssessmentResultsQuery($mentorStudentId: UUID!) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            assessmentResults: assessmentResultsList {
                ...AssessmentResult
            }
        }
    }
    ${UserFragmentDoc}
    ${AssessmentResultFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentAssessmentResultsQueryGQL extends Apollo.Query<
    MentorStudentAssessmentResultsQuery,
    MentorStudentAssessmentResultsQueryVariables
> {
    document = MentorStudentAssessmentResultsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentAttendanceSummaryQueryDocument = gql`
    query MentorStudentAttendanceSummaryQuery($mentorStudentId: UUID!, $start: Date!, $end: Date!) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            attendanceSummary(start: $start, end: $end) {
                ...LearnerSmsAttendanceSummary
            }
        }
    }
    ${UserFragmentDoc}
    ${LearnerSmsAttendanceSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentAttendanceSummaryQueryGQL extends Apollo.Query<
    MentorStudentAttendanceSummaryQuery,
    MentorStudentAttendanceSummaryQueryVariables
> {
    document = MentorStudentAttendanceSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentAttendanceYearSummaryQueryDocument = gql`
    query MentorStudentAttendanceYearSummaryQuery($mentorStudentId: UUID!, $year: Int!) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            januaryAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 1) {
                ...LearnerSmsAttendanceSummary
            }
            februaryAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 2) {
                ...LearnerSmsAttendanceSummary
            }
            marchAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 3) {
                ...LearnerSmsAttendanceSummary
            }
            aprilAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 4) {
                ...LearnerSmsAttendanceSummary
            }
            mayAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 5) {
                ...LearnerSmsAttendanceSummary
            }
            juneAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 6) {
                ...LearnerSmsAttendanceSummary
            }
            julyAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 7) {
                ...LearnerSmsAttendanceSummary
            }
            augustAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 8) {
                ...LearnerSmsAttendanceSummary
            }
            septemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 9) {
                ...LearnerSmsAttendanceSummary
            }
            octoberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 10) {
                ...LearnerSmsAttendanceSummary
            }
            novemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 11) {
                ...LearnerSmsAttendanceSummary
            }
            decemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 12) {
                ...LearnerSmsAttendanceSummary
            }
        }
    }
    ${UserFragmentDoc}
    ${LearnerSmsAttendanceSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentAttendanceYearSummaryQueryGQL extends Apollo.Query<
    MentorStudentAttendanceYearSummaryQuery,
    MentorStudentAttendanceYearSummaryQueryVariables
> {
    document = MentorStudentAttendanceYearSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentAvailableMentorGroupsQueryDocument = gql`
    query MentorStudentAvailableMentorGroupsQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorStudent(id: $mentorStudentId) {
            id
            availableMentorGroups(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...MentorGroupSimple
                        hasJoinedAsStudent
                    }
                    cursor
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorGroupSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentAvailableMentorGroupsQueryGQL extends Apollo.Query<
    MentorStudentAvailableMentorGroupsQuery,
    MentorStudentAvailableMentorGroupsQueryVariables
> {
    document = MentorStudentAvailableMentorGroupsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentCurriculumVitaeQueryDocument = gql`
    query MentorStudentCurriculumVitaeQuery($mentorStudentId: UUID!) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            curriculumVitae {
                ...CurriculumVitae
            }
        }
    }
    ${UserFragmentDoc}
    ${CurriculumVitaeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentCurriculumVitaeQueryGQL extends Apollo.Query<
    MentorStudentCurriculumVitaeQuery,
    MentorStudentCurriculumVitaeQueryVariables
> {
    document = MentorStudentCurriculumVitaeQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentGoalsQueryDocument = gql`
    query MentorStudentGoalsQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            goals(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...Goal
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${GoalFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentGoalsQueryGQL extends Apollo.Query<MentorStudentGoalsQuery, MentorStudentGoalsQueryVariables> {
    document = MentorStudentGoalsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentHomeworkDiaryDailyItemsQueryDocument = gql`
    query MentorStudentHomeworkDiaryDailyItemsQuery(
        $mentorStudentId: UUID!
        $date: Date!
        $first: Int
        $last: Int
        $before: String
        $after: String
    ) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            studentHomeworkDiaryDailyItems(date: $date, first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...StudentHomeworkDiaryItem
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${StudentHomeworkDiaryItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentHomeworkDiaryDailyItemsQueryGQL extends Apollo.Query<
    MentorStudentHomeworkDiaryDailyItemsQuery,
    MentorStudentHomeworkDiaryDailyItemsQueryVariables
> {
    document = MentorStudentHomeworkDiaryDailyItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentHomeworkDiaryMonthSummaryQueryDocument = gql`
    query MentorStudentHomeworkDiaryMonthSummaryQuery($mentorStudentId: UUID!, $date: Date!) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            studentHomeworkDiaryMonthSummary(date: $date) {
                days {
                    date
                    completed
                    total
                    colors
                }
                completed
                total
                colors
            }
        }
    }
    ${UserFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentHomeworkDiaryMonthSummaryQueryGQL extends Apollo.Query<
    MentorStudentHomeworkDiaryMonthSummaryQuery,
    MentorStudentHomeworkDiaryMonthSummaryQueryVariables
> {
    document = MentorStudentHomeworkDiaryMonthSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentJobProfileActivitiesQueryDocument = gql`
    query MentorStudentJobProfileActivitiesQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            jobProfile {
                id
                activities(first: $first, last: $last, before: $before, after: $after) {
                    totalCount
                    pageInfo {
                        ...PageInfo
                    }
                    edges {
                        node {
                            ...JobProfileActivity
                        }
                    }
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${JobProfileActivityFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentJobProfileActivitiesQueryGQL extends Apollo.Query<
    MentorStudentJobProfileActivitiesQuery,
    MentorStudentJobProfileActivitiesQueryVariables
> {
    document = MentorStudentJobProfileActivitiesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentJobProfileQueryDocument = gql`
    query MentorStudentJobProfileQuery($mentorStudentId: UUID!) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            jobProfile {
                ...JobProfile
            }
        }
    }
    ${UserFragmentDoc}
    ${JobProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentJobProfileQueryGQL extends Apollo.Query<MentorStudentJobProfileQuery, MentorStudentJobProfileQueryVariables> {
    document = MentorStudentJobProfileQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentNotViewedSharedNotesAndTodosCountQueryDocument = gql`
    query MentorStudentNotViewedSharedNotesAndTodosCountQuery($mentorStudentId: UUID!, $mentorGroupId: UUID) {
        mentorStudent(id: $mentorStudentId) {
            id
            todos(mentorGroupId: $mentorGroupId) {
                notViewedCount
            }
            notes(mentorGroupId: $mentorGroupId) {
                notViewedCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentNotViewedSharedNotesAndTodosCountQueryGQL extends Apollo.Query<
    MentorStudentNotViewedSharedNotesAndTodosCountQuery,
    MentorStudentNotViewedSharedNotesAndTodosCountQueryVariables
> {
    document = MentorStudentNotViewedSharedNotesAndTodosCountQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentNotesQueryDocument = gql`
    query MentorStudentNotesQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String, $mentorGroupId: UUID) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            notes(first: $first, last: $last, before: $before, after: $after, mentorGroupId: $mentorGroupId) {
                notViewedCount
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...MentorStudentNote
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${MentorStudentNoteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentNotesQueryGQL extends Apollo.Query<MentorStudentNotesQuery, MentorStudentNotesQueryVariables> {
    document = MentorStudentNotesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentPathwayPlannersQueryDocument = gql`
    query MentorStudentPathwayPlannersQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            pathwayPlanners(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...PathwayPlanner
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentPathwayPlannersQueryGQL extends Apollo.Query<
    MentorStudentPathwayPlannersQuery,
    MentorStudentPathwayPlannersQueryVariables
> {
    document = MentorStudentPathwayPlannersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentProjectsQueryDocument = gql`
    query MentorStudentProjectsQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            projects(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...ProjectSimple
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ProjectSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentProjectsQueryGQL extends Apollo.Query<MentorStudentProjectsQuery, MentorStudentProjectsQueryVariables> {
    document = MentorStudentProjectsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentReflectionsQueryDocument = gql`
    query MentorStudentReflectionsQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            reflections(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...Reflection
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ReflectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentReflectionsQueryGQL extends Apollo.Query<MentorStudentReflectionsQuery, MentorStudentReflectionsQueryVariables> {
    document = MentorStudentReflectionsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentResourceHubItemsQueryDocument = gql`
    query MentorStudentResourceHubItemsQuery(
        $mentorStudentId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $mimeTypes: [String!]
    ) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            resourceHubItems(first: $first, last: $last, before: $before, after: $after, mimeTypes: $mimeTypes) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...ResourceHubItem
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ResourceHubItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentResourceHubItemsQueryGQL extends Apollo.Query<
    MentorStudentResourceHubItemsQuery,
    MentorStudentResourceHubItemsQueryVariables
> {
    document = MentorStudentResourceHubItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentSavedStudyProgrammesQueryDocument = gql`
    query MentorStudentSavedStudyProgrammesQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            savedStudyProgrammes(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...SavedStudyProgramme
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${SavedStudyProgrammeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentSavedStudyProgrammesQueryGQL extends Apollo.Query<
    MentorStudentSavedStudyProgrammesQuery,
    MentorStudentSavedStudyProgrammesQueryVariables
> {
    document = MentorStudentSavedStudyProgrammesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentSimpleQueryDocument = gql`
    query MentorStudentSimpleQuery($mentorStudentId: UUID!) {
        mentorStudent(id: $mentorStudentId) {
            ...MentorStudentSimple
        }
    }
    ${MentorStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentSimpleQueryGQL extends Apollo.Query<MentorStudentSimpleQuery, MentorStudentSimpleQueryVariables> {
    document = MentorStudentSimpleQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentSummaryQueryDocument = gql`
    query MentorStudentSummaryQuery($mentorStudentId: UUID!, $mentorGroupId: UUID, $rewards: Boolean = false) {
        mentorStudent(id: $mentorStudentId) {
            ...MentorStudentSummary
            rewards @include(if: $rewards) {
                ...MentorRewardStudentSimple
            }
        }
    }
    ${MentorStudentSummaryFragmentDoc}
    ${MentorRewardStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentSummaryQueryGQL extends Apollo.Query<MentorStudentSummaryQuery, MentorStudentSummaryQueryVariables> {
    document = MentorStudentSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentTimetableEventsQueryDocument = gql`
    query MentorStudentTimetableEventsQuery($mentorStudentId: UUID!, $start: DateTime!, $end: DateTime!) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            timetableEventsList(start: $start, end: $end) {
                ...TimetableEvent
            }
        }
    }
    ${UserFragmentDoc}
    ${TimetableEventFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentTimetableEventsQueryGQL extends Apollo.Query<
    MentorStudentTimetableEventsQuery,
    MentorStudentTimetableEventsQueryVariables
> {
    document = MentorStudentTimetableEventsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorStudentTodosQueryDocument = gql`
    query MentorStudentTodosQuery($mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String, $mentorGroupId: UUID) {
        mentorStudent(id: $mentorStudentId) {
            id
            user {
                ...User
            }
            todos(first: $first, last: $last, before: $before, after: $after, mentorGroupId: $mentorGroupId) {
                notViewedCount
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...MentorStudentTodo
                    }
                    cursor
                }
            }
        }
    }
    ${UserFragmentDoc}
    ${PageInfoFragmentDoc}
    ${MentorStudentTodoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorStudentTodosQueryGQL extends Apollo.Query<MentorStudentTodosQuery, MentorStudentTodosQueryVariables> {
    document = MentorStudentTodosQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorStudentsQueryDocument = gql`
    query ProviderMentorStudentsQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $query: String
        $jobProfileEnabled: Boolean
        $withNoGroups: Boolean
        $withNoLearnerSmsProfile: Boolean
    ) {
        provider(id: $providerId) {
            id
            title
            logoImageUrl
            mentorStudents(
                first: $first
                last: $last
                before: $before
                after: $after
                query: $query
                jobProfileEnabled: $jobProfileEnabled
                withNoGroups: $withNoGroups
                withNoLearnerSmsProfile: $withNoLearnerSmsProfile
            ) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorStudentSimple
                        ...MentorStudentGroups
                        canRemove
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorStudentSimpleFragmentDoc}
    ${MentorStudentGroupsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorStudentsQueryGQL extends Apollo.Query<ProviderMentorStudentsQuery, ProviderMentorStudentsQueryVariables> {
    document = ProviderMentorStudentsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RemoveMentorStudentMutationDocument = gql`
    mutation RemoveMentorStudentMutation($input: RemoveMentorStudentInput!) {
        removeMentorStudent(input: $input) {
            mentorStudentId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class RemoveMentorStudentMutationGQL extends Apollo.Mutation<RemoveMentorStudentMutation, RemoveMentorStudentMutationVariables> {
    document = RemoveMentorStudentMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ShareMentorStudentNoteMutationDocument = gql`
    mutation ShareMentorStudentNoteMutation($input: ShareMentorStudentNoteInput!) {
        shareMentorStudentNote(input: $input) {
            mentorStudentNote {
                ...MentorStudentNote
            }
        }
    }
    ${MentorStudentNoteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ShareMentorStudentNoteMutationGQL extends Apollo.Mutation<
    ShareMentorStudentNoteMutation,
    ShareMentorStudentNoteMutationVariables
> {
    document = ShareMentorStudentNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ShareMentorStudentTodoMutationDocument = gql`
    mutation ShareMentorStudentTodoMutation($input: ShareMentorStudentTodoInput!) {
        shareMentorStudentTodo(input: $input) {
            mentorStudentTodo {
                ...MentorStudentTodo
            }
        }
    }
    ${MentorStudentTodoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ShareMentorStudentTodoMutationGQL extends Apollo.Mutation<
    ShareMentorStudentTodoMutation,
    ShareMentorStudentTodoMutationVariables
> {
    document = ShareMentorStudentTodoMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnshareMentorStudentNoteMutationDocument = gql`
    mutation UnshareMentorStudentNoteMutation($input: UnshareMentorStudentNoteInput!) {
        unshareMentorStudentNote(input: $input) {
            mentorStudentNote {
                ...MentorStudentNote
            }
        }
    }
    ${MentorStudentNoteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnshareMentorStudentNoteMutationGQL extends Apollo.Mutation<
    UnshareMentorStudentNoteMutation,
    UnshareMentorStudentNoteMutationVariables
> {
    document = UnshareMentorStudentNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnshareMentorStudentTodoMutationDocument = gql`
    mutation UnshareMentorStudentTodoMutation($input: UnshareMentorStudentTodoInput!) {
        unshareMentorStudentTodo(input: $input) {
            mentorStudentTodo {
                ...MentorStudentTodo
            }
        }
    }
    ${MentorStudentTodoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnshareMentorStudentTodoMutationGQL extends Apollo.Mutation<
    UnshareMentorStudentTodoMutation,
    UnshareMentorStudentTodoMutationVariables
> {
    document = UnshareMentorStudentTodoMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorStudentNoteMutationDocument = gql`
    mutation UpdateMentorStudentNoteMutation($input: UpdateMentorStudentNoteInput!) {
        updateMentorStudentNote(input: $input) {
            mentorStudentNote {
                ...MentorStudentNote
            }
        }
    }
    ${MentorStudentNoteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorStudentNoteMutationGQL extends Apollo.Mutation<
    UpdateMentorStudentNoteMutation,
    UpdateMentorStudentNoteMutationVariables
> {
    document = UpdateMentorStudentNoteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorStudentNoteViewedMutationDocument = gql`
    mutation UpdateMentorStudentNoteViewedMutation($input: UpdateMentorStudentNoteViewedInput!) {
        updateMentorStudentNoteViewed(input: $input) {
            viewedCount
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorStudentNoteViewedMutationGQL extends Apollo.Mutation<
    UpdateMentorStudentNoteViewedMutation,
    UpdateMentorStudentNoteViewedMutationVariables
> {
    document = UpdateMentorStudentNoteViewedMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorStudentSharedActivityMutationDocument = gql`
    mutation UpdateMentorStudentSharedActivityMutation($input: UpdateMentorStudentSharedActivityInput!) {
        updateMentorStudentSharedActivity(input: $input) {
            mentorStudent {
                ...MentorStudentSimple
            }
        }
    }
    ${MentorStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorStudentSharedActivityMutationGQL extends Apollo.Mutation<
    UpdateMentorStudentSharedActivityMutation,
    UpdateMentorStudentSharedActivityMutationVariables
> {
    document = UpdateMentorStudentSharedActivityMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorStudentTodoMutationDocument = gql`
    mutation UpdateMentorStudentTodoMutation($input: UpdateMentorStudentTodoInput!) {
        updateMentorStudentTodo(input: $input) {
            mentorStudentTodo {
                ...MentorStudentTodo
            }
        }
    }
    ${MentorStudentTodoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorStudentTodoMutationGQL extends Apollo.Mutation<
    UpdateMentorStudentTodoMutation,
    UpdateMentorStudentTodoMutationVariables
> {
    document = UpdateMentorStudentTodoMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorStudentTodoViewedMutationDocument = gql`
    mutation UpdateMentorStudentTodoViewedMutation($input: UpdateMentorStudentTodoViewedInput!) {
        updateMentorStudentTodoViewed(input: $input) {
            viewedCount
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorStudentTodoViewedMutationGQL extends Apollo.Mutation<
    UpdateMentorStudentTodoViewedMutation,
    UpdateMentorStudentTodoViewedMutationVariables
> {
    document = UpdateMentorStudentTodoViewedMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ValidateMentorStudentInviteCodeMutationDocument = gql`
    mutation ValidateMentorStudentInviteCodeMutation($input: ValidateMentorStudentInviteCodeInput!) {
        validateMentorStudentInviteCode(input: $input) {
            provider {
                ...ProviderSimple
            }
            mentorGroupTitles
        }
    }
    ${ProviderSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ValidateMentorStudentInviteCodeMutationGQL extends Apollo.Mutation<
    ValidateMentorStudentInviteCodeMutation,
    ValidateMentorStudentInviteCodeMutationVariables
> {
    document = ValidateMentorStudentInviteCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateStudentHomeworkDiaryItemMutationDocument = gql`
    mutation CreateStudentHomeworkDiaryItemMutation($input: CreateStudentHomeworkDiaryItemInput!) {
        createStudentHomeworkDiaryItem(input: $input) {
            studentHomeworkDiaryItem {
                ...StudentHomeworkDiaryItem
            }
        }
    }
    ${StudentHomeworkDiaryItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateStudentHomeworkDiaryItemMutationGQL extends Apollo.Mutation<
    CreateStudentHomeworkDiaryItemMutation,
    CreateStudentHomeworkDiaryItemMutationVariables
> {
    document = CreateStudentHomeworkDiaryItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudentHomeworkDiaryItemMutationDocument = gql`
    mutation deleteStudentHomeworkDiaryItemMutation($input: DeleteStudentHomeworkDiaryItemInput!) {
        deleteStudentHomeworkDiaryItem(input: $input) {
            studentHomeworkDiaryItemId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteStudentHomeworkDiaryItemMutationGQL extends Apollo.Mutation<
    DeleteStudentHomeworkDiaryItemMutation,
    DeleteStudentHomeworkDiaryItemMutationVariables
> {
    document = DeleteStudentHomeworkDiaryItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyAssessmentResultsQueryDocument = gql`
    query MyAssessmentResultsQuery {
        me {
            id
            currentMySchoolProvider {
                id
                assessmentResultsList {
                    ...AssessmentResult
                }
            }
        }
    }
    ${AssessmentResultFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyAssessmentResultsQueryGQL extends Apollo.Query<MyAssessmentResultsQuery, MyAssessmentResultsQueryVariables> {
    document = MyAssessmentResultsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyAttendanceSummaryQueryDocument = gql`
    query MyAttendanceSummaryQuery($start: Date!, $end: Date!) {
        me {
            id
            currentMySchoolProvider {
                id
                attendanceSummary(start: $start, end: $end) {
                    ...LearnerSmsAttendanceSummary
                }
            }
        }
    }
    ${LearnerSmsAttendanceSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyAttendanceSummaryQueryGQL extends Apollo.Query<MyAttendanceSummaryQuery, MyAttendanceSummaryQueryVariables> {
    document = MyAttendanceSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyAttendanceYearSummaryQueryDocument = gql`
    query MyAttendanceYearSummaryQuery($year: Int!) {
        me {
            id
            currentMySchoolProvider {
                id
                januaryAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 1) {
                    ...LearnerSmsAttendanceSummary
                }
                februaryAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 2) {
                    ...LearnerSmsAttendanceSummary
                }
                marchAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 3) {
                    ...LearnerSmsAttendanceSummary
                }
                aprilAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 4) {
                    ...LearnerSmsAttendanceSummary
                }
                mayAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 5) {
                    ...LearnerSmsAttendanceSummary
                }
                juneAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 6) {
                    ...LearnerSmsAttendanceSummary
                }
                julyAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 7) {
                    ...LearnerSmsAttendanceSummary
                }
                augustAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 8) {
                    ...LearnerSmsAttendanceSummary
                }
                septemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 9) {
                    ...LearnerSmsAttendanceSummary
                }
                octoberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 10) {
                    ...LearnerSmsAttendanceSummary
                }
                novemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 11) {
                    ...LearnerSmsAttendanceSummary
                }
                decemberAttendanceSummary: attendanceYearMonthSummary(year: $year, month: 12) {
                    ...LearnerSmsAttendanceSummary
                }
            }
        }
    }
    ${LearnerSmsAttendanceSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyAttendanceYearSummaryQueryGQL extends Apollo.Query<MyAttendanceYearSummaryQuery, MyAttendanceYearSummaryQueryVariables> {
    document = MyAttendanceYearSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyCurrentMySchoolProviderQueryDocument = gql`
    query MyCurrentMySchoolProviderQuery {
        me {
            id
            currentMySchoolProvider {
                ...MentorStudentForMySchool
            }
        }
    }
    ${MentorStudentForMySchoolFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyCurrentMySchoolProviderQueryGQL extends Apollo.Query<
    MyCurrentMySchoolProviderQuery,
    MyCurrentMySchoolProviderQueryVariables
> {
    document = MyCurrentMySchoolProviderQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyDailyNoticesQueryDocument = gql`
    query MyDailyNoticesQuery($start: DateTime!, $end: DateTime!) {
        me {
            id
            currentMySchoolProvider {
                id
                dailyNoticesList(start: $start, end: $end) {
                    ...DailyNotice
                }
            }
        }
    }
    ${DailyNoticeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyDailyNoticesQueryGQL extends Apollo.Query<MyDailyNoticesQuery, MyDailyNoticesQueryVariables> {
    document = MyDailyNoticesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyStudentHomeworkDiaryDailyItemsQueryDocument = gql`
    query MyStudentHomeworkDiaryDailyItemsQuery($date: Date!, $first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            currentMySchoolProvider {
                id
                studentHomeworkDiaryDailyItems(date: $date, first: $first, last: $last, before: $before, after: $after) {
                    totalCount
                    pageInfo {
                        ...PageInfo
                    }
                    edges {
                        node {
                            ...StudentHomeworkDiaryItem
                        }
                        cursor
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${StudentHomeworkDiaryItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyStudentHomeworkDiaryDailyItemsQueryGQL extends Apollo.Query<
    MyStudentHomeworkDiaryDailyItemsQuery,
    MyStudentHomeworkDiaryDailyItemsQueryVariables
> {
    document = MyStudentHomeworkDiaryDailyItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyStudentHomeworkDiaryMonthSummaryQueryDocument = gql`
    query MyStudentHomeworkDiaryMonthSummaryQuery($date: Date!) {
        me {
            id
            currentMySchoolProvider {
                id
                studentHomeworkDiaryMonthSummary(date: $date) {
                    days {
                        date
                        completed
                        total
                        colors
                    }
                    completed
                    total
                    colors
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MyStudentHomeworkDiaryMonthSummaryQueryGQL extends Apollo.Query<
    MyStudentHomeworkDiaryMonthSummaryQuery,
    MyStudentHomeworkDiaryMonthSummaryQueryVariables
> {
    document = MyStudentHomeworkDiaryMonthSummaryQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MySchoolInformationItemsQueryDocument = gql`
    query MySchoolInformationItemsQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            currentMySchoolProvider {
                id
                provider {
                    id
                    title
                    logoImageUrl
                }
                mySchoolInformationItems(first: $first, last: $last, before: $before, after: $after) {
                    pageInfo {
                        ...PageInfo
                    }
                    edges {
                        cursor
                        node {
                            ...ProviderMySchoolInformationItem
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProviderMySchoolInformationItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MySchoolInformationItemsQueryGQL extends Apollo.Query<MySchoolInformationItemsQuery, MySchoolInformationItemsQueryVariables> {
    document = MySchoolInformationItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyTimetableEventsQueryDocument = gql`
    query MyTimetableEventsQuery($start: DateTime!, $end: DateTime!) {
        me {
            id
            currentMySchoolProvider {
                id
                isTimetableDisabled
                timetableEventsList(start: $start, end: $end) {
                    ...TimetableEvent
                }
            }
        }
    }
    ${TimetableEventFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyTimetableEventsQueryGQL extends Apollo.Query<MyTimetableEventsQuery, MyTimetableEventsQueryVariables> {
    document = MyTimetableEventsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ToggleStudentHomeworkDiaryItemCompletionMutationDocument = gql`
    mutation ToggleStudentHomeworkDiaryItemCompletionMutation($input: ToggleStudentHomeworkDiaryItemCompletionInput!) {
        toggleStudentHomeworkDiaryItemCompletion(input: $input) {
            studentHomeworkDiaryItem {
                ...StudentHomeworkDiaryItem
            }
        }
    }
    ${StudentHomeworkDiaryItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ToggleStudentHomeworkDiaryItemCompletionMutationGQL extends Apollo.Mutation<
    ToggleStudentHomeworkDiaryItemCompletionMutation,
    ToggleStudentHomeworkDiaryItemCompletionMutationVariables
> {
    document = ToggleStudentHomeworkDiaryItemCompletionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateStudentHomeworkDiaryItemMutationDocument = gql`
    mutation UpdateStudentHomeworkDiaryItemMutation($input: UpdateStudentHomeworkDiaryItemInput!) {
        updateStudentHomeworkDiaryItem(input: $input) {
            studentHomeworkDiaryItem {
                ...StudentHomeworkDiaryItem
            }
        }
    }
    ${StudentHomeworkDiaryItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateStudentHomeworkDiaryItemMutationGQL extends Apollo.Mutation<
    UpdateStudentHomeworkDiaryItemMutation,
    UpdateStudentHomeworkDiaryItemMutationVariables
> {
    document = UpdateStudentHomeworkDiaryItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ChooseProviderPostPollResponseMutationDocument = gql`
    mutation ChooseProviderPostPollResponseMutation($input: ChooseProviderPostPollResponseInput!) {
        chooseProviderPostPollResponse(input: $input) {
            providerPost {
                ...ProviderPost
            }
        }
    }
    ${ProviderPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ChooseProviderPostPollResponseMutationGQL extends Apollo.Mutation<
    ChooseProviderPostPollResponseMutation,
    ChooseProviderPostPollResponseMutationVariables
> {
    document = ChooseProviderPostPollResponseMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderPostMutationDocument = gql`
    mutation CreateProviderPostMutation($input: CreateProviderPostInput!) {
        createProviderPost(input: $input) {
            providerPost {
                ...ProviderPost
            }
        }
    }
    ${ProviderPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderPostMutationGQL extends Apollo.Mutation<CreateProviderPostMutation, CreateProviderPostMutationVariables> {
    document = CreateProviderPostMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderPostMutationDocument = gql`
    mutation DeleteProviderPostMutation($input: DeleteProviderPostInput!) {
        deleteProviderPost(input: $input) {
            providerPostId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderPostMutationGQL extends Apollo.Mutation<DeleteProviderPostMutation, DeleteProviderPostMutationVariables> {
    document = DeleteProviderPostMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const NewsfeedItemQueryDocument = gql`
    query NewsfeedItemQuery($itemType: NewsfeedItemType!, $itemId: UUID!) {
        newsfeedItem(itemType: $itemType, itemId: $itemId) {
            ...NewsfeedItem
        }
    }
    ${NewsfeedItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class NewsfeedItemQueryGQL extends Apollo.Query<NewsfeedItemQuery, NewsfeedItemQueryVariables> {
    document = NewsfeedItemQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const NewsfeedQueryDocument = gql`
    query NewsfeedQuery($first: Int, $last: Int, $before: String, $after: String) {
        newsfeed(first: $first, last: $last, before: $before, after: $after) {
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    itemType
                    itemId
                    itemCreated
                    itemUpdated
                    ... on ProviderPost {
                        ...ProviderPost
                    }
                    ... on OpportunityListingPost {
                        ...OpportunityListingPost
                    }
                    ... on GoalsDueReminder {
                        ...GoalsDueReminder
                    }
                    ... on StudentHomeworkItemsDueReminder {
                        ...StudentHomeworkItemsDueReminder
                    }
                    ... on SponsorPost {
                        ...SponsorPost
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProviderPostFragmentDoc}
    ${OpportunityListingPostFragmentDoc}
    ${GoalsDueReminderFragmentDoc}
    ${StudentHomeworkItemsDueReminderFragmentDoc}
    ${SponsorPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class NewsfeedQueryGQL extends Apollo.Query<NewsfeedQuery, NewsfeedQueryVariables> {
    document = NewsfeedQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderNewsfeedQueryDocument = gql`
    query ProviderNewsfeedQuery($first: Int, $last: Int, $before: String, $after: String, $providerId: UUID!) {
        provider(id: $providerId) {
            ...ProviderWithPermissions
            newsfeedProviderPosts(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProviderPost
                    }
                }
            }
        }
    }
    ${ProviderWithPermissionsFragmentDoc}
    ${PageInfoFragmentDoc}
    ${ProviderPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderNewsfeedQueryGQL extends Apollo.Query<ProviderNewsfeedQuery, ProviderNewsfeedQueryVariables> {
    document = ProviderNewsfeedQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReactProviderPostMutationDocument = gql`
    mutation ReactProviderPostMutation($input: ReactProviderPostInput!) {
        reactProviderPost(input: $input) {
            providerPost {
                ...ProviderPost
            }
        }
    }
    ${ProviderPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ReactProviderPostMutationGQL extends Apollo.Mutation<ReactProviderPostMutation, ReactProviderPostMutationVariables> {
    document = ReactProviderPostMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RepostProviderPostMutationDocument = gql`
    mutation RepostProviderPostMutation($input: RepostProviderPostInput!) {
        repostProviderPost(input: $input) {
            providerPost {
                ...ProviderPost
            }
        }
    }
    ${ProviderPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RepostProviderPostMutationGQL extends Apollo.Mutation<RepostProviderPostMutation, RepostProviderPostMutationVariables> {
    document = RepostProviderPostMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnreactProviderPostMutationDocument = gql`
    mutation UnreactProviderPostMutation($input: UnreactProviderPostInput!) {
        unreactProviderPost(input: $input) {
            providerPost {
                ...ProviderPost
            }
        }
    }
    ${ProviderPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnreactProviderPostMutationGQL extends Apollo.Mutation<UnreactProviderPostMutation, UnreactProviderPostMutationVariables> {
    document = UnreactProviderPostMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderPostMutationDocument = gql`
    mutation UpdateProviderPostMutation($input: UpdateProviderPostInput!) {
        updateProviderPost(input: $input) {
            providerPost {
                ...ProviderPost
            }
        }
    }
    ${ProviderPostFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderPostMutationGQL extends Apollo.Mutation<UpdateProviderPostMutation, UpdateProviderPostMutationVariables> {
    document = UpdateProviderPostMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateOpportunityListingMutationDocument = gql`
    mutation CreateOpportunityListingMutation($input: CreateOpportunityListingInput!) {
        createOpportunityListing(input: $input) {
            opportunityListing {
                ...OpportunityListing
            }
        }
    }
    ${OpportunityListingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateOpportunityListingMutationGQL extends Apollo.Mutation<
    CreateOpportunityListingMutation,
    CreateOpportunityListingMutationVariables
> {
    document = CreateOpportunityListingMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ExpireOpportunityListingMutationDocument = gql`
    mutation ExpireOpportunityListingMutation($input: ExpireOpportunityListingInput!) {
        expireOpportunityListing(input: $input) {
            opportunityListing {
                ...OpportunityListing
            }
        }
    }
    ${OpportunityListingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ExpireOpportunityListingMutationGQL extends Apollo.Mutation<
    ExpireOpportunityListingMutation,
    ExpireOpportunityListingMutationVariables
> {
    document = ExpireOpportunityListingMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const OpportunityListingForProviderQueryDocument = gql`
    query OpportunityListingForProviderQuery($providerId: UUID!, $opportunityListingId: UUID!) {
        provider(id: $providerId) {
            id
            opportunityListing(id: $opportunityListingId) {
                ...OpportunityListing
            }
        }
    }
    ${OpportunityListingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class OpportunityListingForProviderQueryGQL extends Apollo.Query<
    OpportunityListingForProviderQuery,
    OpportunityListingForProviderQueryVariables
> {
    document = OpportunityListingForProviderQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const OpportunityListingQueryDocument = gql`
    query OpportunityListingQuery($opportunityListingId: UUID!) {
        opportunityListing(id: $opportunityListingId) {
            ...OpportunityListing
        }
    }
    ${OpportunityListingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class OpportunityListingQueryGQL extends Apollo.Query<OpportunityListingQuery, OpportunityListingQueryVariables> {
    document = OpportunityListingQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const OpportunityListingsForProviderQueryDocument = gql`
    query OpportunityListingsForProviderQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $country: String
        $geoLocationId: UUID
        $types: [OpportunityListingType!]
        $query: String
        $sort: OpportunitySortType
        $includeExpired: Boolean
    ) {
        provider(id: $providerId) {
            id
            opportunityListings(
                first: $first
                last: $last
                before: $before
                after: $after
                country: $country
                geoLocationId: $geoLocationId
                types: $types
                query: $query
                sort: $sort
                includeExpired: $includeExpired
            ) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...OpportunityListing
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${OpportunityListingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class OpportunityListingsForProviderQueryGQL extends Apollo.Query<
    OpportunityListingsForProviderQuery,
    OpportunityListingsForProviderQueryVariables
> {
    document = OpportunityListingsForProviderQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const OpportunityListingsQueryDocument = gql`
    query OpportunityListingsQuery(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $country: String
        $geoLocationId: UUID
        $types: [OpportunityListingType!]
        $query: String
        $sort: OpportunitySortType
    ) {
        opportunityListings(
            first: $first
            last: $last
            before: $before
            after: $after
            country: $country
            geoLocationId: $geoLocationId
            types: $types
            query: $query
            sort: $sort
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...OpportunityListing
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${OpportunityListingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class OpportunityListingsQueryGQL extends Apollo.Query<OpportunityListingsQuery, OpportunityListingsQueryVariables> {
    document = OpportunityListingsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateOpportunityListingMutationDocument = gql`
    mutation UpdateOpportunityListingMutation($input: UpdateOpportunityListingInput!) {
        updateOpportunityListing(input: $input) {
            opportunityListing {
                ...OpportunityListing
            }
        }
    }
    ${OpportunityListingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateOpportunityListingMutationGQL extends Apollo.Mutation<
    UpdateOpportunityListingMutation,
    UpdateOpportunityListingMutationVariables
> {
    document = UpdateOpportunityListingMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerMutationDocument = gql`
    mutation CreatePathwayPlannerMutation($input: CreatePathwayPlannerInput!) {
        createPathwayPlanner(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerMutationGQL extends Apollo.Mutation<CreatePathwayPlannerMutation, CreatePathwayPlannerMutationVariables> {
    document = CreatePathwayPlannerMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerPersonalRequirementMutationDocument = gql`
    mutation CreatePathwayPlannerPersonalRequirementMutation($input: CreatePathwayPlannerPersonalRequirementTodoInput!) {
        createPathwayPlannerPersonalRequirement(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerPersonalRequirementMutationGQL extends Apollo.Mutation<
    CreatePathwayPlannerPersonalRequirementMutation,
    CreatePathwayPlannerPersonalRequirementMutationVariables
> {
    document = CreatePathwayPlannerPersonalRequirementMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerQualificationMutationDocument = gql`
    mutation CreatePathwayPlannerQualificationMutation($input: CreatePathwayPlannerQualificationTodoInput!) {
        createPathwayPlannerQualification(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerQualificationMutationGQL extends Apollo.Mutation<
    CreatePathwayPlannerQualificationMutation,
    CreatePathwayPlannerQualificationMutationVariables
> {
    document = CreatePathwayPlannerQualificationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerSkillAndKnowledgeMutationDocument = gql`
    mutation CreatePathwayPlannerSkillAndKnowledgeMutation($input: CreatePathwayPlannerSkillAndKnowledgeTodoInput!) {
        createPathwayPlannerSkillAndKnowledge(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerSkillAndKnowledgeMutationGQL extends Apollo.Mutation<
    CreatePathwayPlannerSkillAndKnowledgeMutation,
    CreatePathwayPlannerSkillAndKnowledgeMutationVariables
> {
    document = CreatePathwayPlannerSkillAndKnowledgeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerStageMutationDocument = gql`
    mutation CreatePathwayPlannerStageMutation($input: CreatePathwayPlannerStageInput!) {
        createPathwayPlannerStage(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerStageMutationGQL extends Apollo.Mutation<
    CreatePathwayPlannerStageMutation,
    CreatePathwayPlannerStageMutationVariables
> {
    document = CreatePathwayPlannerStageMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerStageSubjectMutationDocument = gql`
    mutation CreatePathwayPlannerStageSubjectMutation($input: CreatePathwayPlannerStageSubjectTodoInput!) {
        createPathwayPlannerStageSubject(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerStageSubjectMutationGQL extends Apollo.Mutation<
    CreatePathwayPlannerStageSubjectMutation,
    CreatePathwayPlannerStageSubjectMutationVariables
> {
    document = CreatePathwayPlannerStageSubjectMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerStageUsefulExperienceMutationDocument = gql`
    mutation CreatePathwayPlannerStageUsefulExperienceMutation($input: CreatePathwayPlannerStageUsefulExperienceTodoInput!) {
        createPathwayPlannerStageUsefulExperience(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerStageUsefulExperienceMutationGQL extends Apollo.Mutation<
    CreatePathwayPlannerStageUsefulExperienceMutation,
    CreatePathwayPlannerStageUsefulExperienceMutationVariables
> {
    document = CreatePathwayPlannerStageUsefulExperienceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePathwayPlannerUsefulExperienceMutationDocument = gql`
    mutation CreatePathwayPlannerUsefulExperienceMutation($input: CreatePathwayPlannerUsefulExperienceTodoInput!) {
        createPathwayPlannerUsefulExperience(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreatePathwayPlannerUsefulExperienceMutationGQL extends Apollo.Mutation<
    CreatePathwayPlannerUsefulExperienceMutation,
    CreatePathwayPlannerUsefulExperienceMutationVariables
> {
    document = CreatePathwayPlannerUsefulExperienceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerMutationDocument = gql`
    mutation DeletePathwayPlannerMutation($input: DeletePathwayPlannerInput!) {
        deletePathwayPlanner(input: $input) {
            pathwayPlannerId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerMutationGQL extends Apollo.Mutation<DeletePathwayPlannerMutation, DeletePathwayPlannerMutationVariables> {
    document = DeletePathwayPlannerMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerPersonalRequirementMutationDocument = gql`
    mutation DeletePathwayPlannerPersonalRequirementMutation($input: DeletePathwayPlannerPersonalRequirementTodoInput!) {
        deletePathwayPlannerPersonalRequirement(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerPersonalRequirementMutationGQL extends Apollo.Mutation<
    DeletePathwayPlannerPersonalRequirementMutation,
    DeletePathwayPlannerPersonalRequirementMutationVariables
> {
    document = DeletePathwayPlannerPersonalRequirementMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerQualificationMutationDocument = gql`
    mutation DeletePathwayPlannerQualificationMutation($input: DeletePathwayPlannerQualificationTodoInput!) {
        deletePathwayPlannerQualification(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerQualificationMutationGQL extends Apollo.Mutation<
    DeletePathwayPlannerQualificationMutation,
    DeletePathwayPlannerQualificationMutationVariables
> {
    document = DeletePathwayPlannerQualificationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerSkillAndKnowledgeMutationDocument = gql`
    mutation DeletePathwayPlannerSkillAndKnowledgeMutation($input: DeletePathwayPlannerSkillAndKnowledgeTodoInput!) {
        deletePathwayPlannerSkillAndKnowledge(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerSkillAndKnowledgeMutationGQL extends Apollo.Mutation<
    DeletePathwayPlannerSkillAndKnowledgeMutation,
    DeletePathwayPlannerSkillAndKnowledgeMutationVariables
> {
    document = DeletePathwayPlannerSkillAndKnowledgeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerStageMutationDocument = gql`
    mutation DeletePathwayPlannerStageMutation($input: DeletePathwayPlannerStageInput!) {
        deletePathwayPlannerStage(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerStageMutationGQL extends Apollo.Mutation<
    DeletePathwayPlannerStageMutation,
    DeletePathwayPlannerStageMutationVariables
> {
    document = DeletePathwayPlannerStageMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerStageSubjectMutationDocument = gql`
    mutation DeletePathwayPlannerStageSubjectMutation($input: DeletePathwayPlannerStageSubjectTodoInput!) {
        deletePathwayPlannerStageSubject(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerStageSubjectMutationGQL extends Apollo.Mutation<
    DeletePathwayPlannerStageSubjectMutation,
    DeletePathwayPlannerStageSubjectMutationVariables
> {
    document = DeletePathwayPlannerStageSubjectMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerStageUsefulExperienceMutationDocument = gql`
    mutation DeletePathwayPlannerStageUsefulExperienceMutation($input: DeletePathwayPlannerStageUsefulExperienceTodoInput!) {
        deletePathwayPlannerStageUsefulExperience(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerStageUsefulExperienceMutationGQL extends Apollo.Mutation<
    DeletePathwayPlannerStageUsefulExperienceMutation,
    DeletePathwayPlannerStageUsefulExperienceMutationVariables
> {
    document = DeletePathwayPlannerStageUsefulExperienceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePathwayPlannerUsefulExperienceMutationDocument = gql`
    mutation DeletePathwayPlannerUsefulExperienceMutation($input: DeletePathwayPlannerUsefulExperienceTodoInput!) {
        deletePathwayPlannerUsefulExperience(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeletePathwayPlannerUsefulExperienceMutationGQL extends Apollo.Mutation<
    DeletePathwayPlannerUsefulExperienceMutation,
    DeletePathwayPlannerUsefulExperienceMutationVariables
> {
    document = DeletePathwayPlannerUsefulExperienceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GeneratePathwayPlannerInformationSuggestionsMutationDocument = gql`
    mutation GeneratePathwayPlannerInformationSuggestionsMutation($input: GeneratePathwayPlannerInformationSuggestionsInput!) {
        generatePathwayPlannerInformationSuggestions(input: $input) {
            pathwaySuggestions
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class GeneratePathwayPlannerInformationSuggestionsMutationGQL extends Apollo.Mutation<
    GeneratePathwayPlannerInformationSuggestionsMutation,
    GeneratePathwayPlannerInformationSuggestionsMutationVariables
> {
    document = GeneratePathwayPlannerInformationSuggestionsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyPathwayPlannersQueryDocument = gql`
    query MyPathwayPlannersQuery($first: Int, $last: Int, $before: String, $after: String, $query: String) {
        me {
            id
            countryCode
            pathwayPlanners(first: $first, last: $last, before: $before, after: $after, query: $query) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...PathwayPlanner
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyPathwayPlannersQueryGQL extends Apollo.Query<MyPathwayPlannersQuery, MyPathwayPlannersQueryVariables> {
    document = MyPathwayPlannersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PathwayPlannerQueryDocument = gql`
    query PathwayPlannerQuery($pathwayPlannerId: UUID!) {
        pathwayPlanner(id: $pathwayPlannerId) {
            ...PathwayPlanner
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class PathwayPlannerQueryGQL extends Apollo.Query<PathwayPlannerQuery, PathwayPlannerQueryVariables> {
    document = PathwayPlannerQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerMutationDocument = gql`
    mutation UpdatePathwayPlannerMutation($input: UpdatePathwayPlannerInput!) {
        updatePathwayPlanner(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerMutationGQL extends Apollo.Mutation<UpdatePathwayPlannerMutation, UpdatePathwayPlannerMutationVariables> {
    document = UpdatePathwayPlannerMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerPersonalRequirementMutationDocument = gql`
    mutation UpdatePathwayPlannerPersonalRequirementMutation($input: UpdatePathwayPlannerPersonalRequirementTodoInput!) {
        updatePathwayPlannerPersonalRequirement(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerPersonalRequirementMutationGQL extends Apollo.Mutation<
    UpdatePathwayPlannerPersonalRequirementMutation,
    UpdatePathwayPlannerPersonalRequirementMutationVariables
> {
    document = UpdatePathwayPlannerPersonalRequirementMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerQualificationMutationDocument = gql`
    mutation UpdatePathwayPlannerQualificationMutation($input: UpdatePathwayPlannerQualificationTodoInput!) {
        updatePathwayPlannerQualification(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerQualificationMutationGQL extends Apollo.Mutation<
    UpdatePathwayPlannerQualificationMutation,
    UpdatePathwayPlannerQualificationMutationVariables
> {
    document = UpdatePathwayPlannerQualificationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerSkillAndKnowledgeMutationDocument = gql`
    mutation UpdatePathwayPlannerSkillAndKnowledgeMutation($input: UpdatePathwayPlannerSkillAndKnowledgeTodoInput!) {
        updatePathwayPlannerSkillAndKnowledge(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerSkillAndKnowledgeMutationGQL extends Apollo.Mutation<
    UpdatePathwayPlannerSkillAndKnowledgeMutation,
    UpdatePathwayPlannerSkillAndKnowledgeMutationVariables
> {
    document = UpdatePathwayPlannerSkillAndKnowledgeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerStageMutationDocument = gql`
    mutation UpdatePathwayPlannerStageMutation($input: UpdatePathwayPlannerStageInput!) {
        updatePathwayPlannerStage(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerStageMutationGQL extends Apollo.Mutation<
    UpdatePathwayPlannerStageMutation,
    UpdatePathwayPlannerStageMutationVariables
> {
    document = UpdatePathwayPlannerStageMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerStageSubjectMutationDocument = gql`
    mutation UpdatePathwayPlannerStageSubjectMutation($input: UpdatePathwayPlannerStageSubjectTodoInput!) {
        updatePathwayPlannerStageSubject(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerStageSubjectMutationGQL extends Apollo.Mutation<
    UpdatePathwayPlannerStageSubjectMutation,
    UpdatePathwayPlannerStageSubjectMutationVariables
> {
    document = UpdatePathwayPlannerStageSubjectMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerStageUsefulExperienceMutationDocument = gql`
    mutation UpdatePathwayPlannerStageUsefulExperienceMutation($input: UpdatePathwayPlannerStageUsefulExperienceTodoInput!) {
        updatePathwayPlannerStageUsefulExperience(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerStageUsefulExperienceMutationGQL extends Apollo.Mutation<
    UpdatePathwayPlannerStageUsefulExperienceMutation,
    UpdatePathwayPlannerStageUsefulExperienceMutationVariables
> {
    document = UpdatePathwayPlannerStageUsefulExperienceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePathwayPlannerUsefulExperienceMutationDocument = gql`
    mutation UpdatePathwayPlannerUsefulExperienceMutation($input: UpdatePathwayPlannerUsefulExperienceTodoInput!) {
        updatePathwayPlannerUsefulExperience(input: $input) {
            pathwayPlanner {
                ...PathwayPlanner
            }
        }
    }
    ${PathwayPlannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdatePathwayPlannerUsefulExperienceMutationGQL extends Apollo.Mutation<
    UpdatePathwayPlannerUsefulExperienceMutation,
    UpdatePathwayPlannerUsefulExperienceMutationVariables
> {
    document = UpdatePathwayPlannerUsefulExperienceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CompleteProjectSectionMutationDocument = gql`
    mutation CompleteProjectSectionMutation($input: CompleteProjectSectionInput!) {
        completeProjectSection(input: $input) {
            projectSection {
                ...ProjectSection
            }
        }
    }
    ${ProjectSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CompleteProjectSectionMutationGQL extends Apollo.Mutation<
    CompleteProjectSectionMutation,
    CompleteProjectSectionMutationVariables
> {
    document = CompleteProjectSectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CompleteProjectSectionReviewMutationDocument = gql`
    mutation CompleteProjectSectionReviewMutation($input: CompleteProjectSectionReviewInput!) {
        completeProjectSectionReview(input: $input) {
            projectSection {
                ...ProjectSection
            }
        }
    }
    ${ProjectSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CompleteProjectSectionReviewMutationGQL extends Apollo.Mutation<
    CompleteProjectSectionReviewMutation,
    CompleteProjectSectionReviewMutationVariables
> {
    document = CompleteProjectSectionReviewMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProjectMutationDocument = gql`
    mutation CreateProjectMutation($input: CreateProjectInput!) {
        createProject(input: $input) {
            project {
                ...ProjectDetailed
            }
        }
    }
    ${ProjectDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProjectMutationGQL extends Apollo.Mutation<CreateProjectMutation, CreateProjectMutationVariables> {
    document = CreateProjectMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProjectSectionCommentMutationDocument = gql`
    mutation CreateProjectSectionCommentMutation($input: CreateProjectSectionCommentInput!) {
        createProjectSectionComment(input: $input) {
            projectSectionComment {
                ...ProjectSectionComment
            }
        }
    }
    ${ProjectSectionCommentFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProjectSectionCommentMutationGQL extends Apollo.Mutation<
    CreateProjectSectionCommentMutation,
    CreateProjectSectionCommentMutationVariables
> {
    document = CreateProjectSectionCommentMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProjectSectionEvidenceMutationDocument = gql`
    mutation CreateProjectSectionEvidenceMutation($input: CreateProjectSectionEvidenceInput!) {
        createProjectSectionEvidence(input: $input) {
            projectSectionEvidence {
                ...ProjectSectionEvidence
            }
        }
    }
    ${ProjectSectionEvidenceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProjectSectionEvidenceMutationGQL extends Apollo.Mutation<
    CreateProjectSectionEvidenceMutation,
    CreateProjectSectionEvidenceMutationVariables
> {
    document = CreateProjectSectionEvidenceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProjectsForMentorGroupStudentsMutationDocument = gql`
    mutation CreateProjectsForMentorGroupStudentsMutation($input: CreateProjectsForMentorGroupStudentsInput!) {
        createProjectsForMentorGroupStudents(input: $input) {
            projects {
                ...ProjectDetailed
            }
        }
    }
    ${ProjectDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProjectsForMentorGroupStudentsMutationGQL extends Apollo.Mutation<
    CreateProjectsForMentorGroupStudentsMutation,
    CreateProjectsForMentorGroupStudentsMutationVariables
> {
    document = CreateProjectsForMentorGroupStudentsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProjectMutationDocument = gql`
    mutation DeleteProjectMutation($input: DeleteProjectInput!) {
        deleteProject(input: $input) {
            projectId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProjectMutationGQL extends Apollo.Mutation<DeleteProjectMutation, DeleteProjectMutationVariables> {
    document = DeleteProjectMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProjectSectionCommentMutationDocument = gql`
    mutation DeleteProjectSectionCommentMutation($input: DeleteProjectSectionCommentInput!) {
        deleteProjectSectionComment(input: $input) {
            projectSectionCommentId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProjectSectionCommentMutationGQL extends Apollo.Mutation<
    DeleteProjectSectionCommentMutation,
    DeleteProjectSectionCommentMutationVariables
> {
    document = DeleteProjectSectionCommentMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProjectSectionEvidenceMutationDocument = gql`
    mutation DeleteProjectSectionEvidenceMutation($input: DeleteProjectSectionEvidenceInput!) {
        deleteProjectSectionEvidence(input: $input) {
            projectSectionEvidenceId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProjectSectionEvidenceMutationGQL extends Apollo.Mutation<
    DeleteProjectSectionEvidenceMutation,
    DeleteProjectSectionEvidenceMutationVariables
> {
    document = DeleteProjectSectionEvidenceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyProjectsQueryDocument = gql`
    query MyProjectsQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            projects(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProjectSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProjectSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyProjectsQueryGQL extends Apollo.Query<MyProjectsQuery, MyProjectsQueryVariables> {
    document = MyProjectsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectMentorGroupsQueryDocument = gql`
    query ProjectMentorGroupsQuery($providerId: UUID!, $mentorStudentId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        provider(id: $providerId) {
            id
            mentorGroups(mentorStudentId: $mentorStudentId, first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProjectMentorGroup
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProjectMentorGroupFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProjectMentorGroupsQueryGQL extends Apollo.Query<ProjectMentorGroupsQuery, ProjectMentorGroupsQueryVariables> {
    document = ProjectMentorGroupsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectQueryDocument = gql`
    query ProjectQuery($projectId: UUID!) {
        project(id: $projectId) {
            ...ProjectDetailed
        }
    }
    ${ProjectDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProjectQueryGQL extends Apollo.Query<ProjectQuery, ProjectQueryVariables> {
    document = ProjectQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectSectionQueryDocument = gql`
    query ProjectSectionQuery($projectSectionId: UUID!) {
        projectSection(id: $projectSectionId) {
            ...ProjectSection
        }
    }
    ${ProjectSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProjectSectionQueryGQL extends Apollo.Query<ProjectSectionQuery, ProjectSectionQueryVariables> {
    document = ProjectSectionQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectsSimilarInMentorGroupQueryDocument = gql`
    query ProjectsSimilarInMentorGroupQuery(
        $projectId: UUID!
        $mentorGroupId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
    ) {
        projectsSimilarInMentorGroup(
            projectId: $projectId
            mentorGroupId: $mentorGroupId
            first: $first
            last: $last
            before: $before
            after: $after
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...SimilarProject
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${SimilarProjectFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProjectsSimilarInMentorGroupQueryGQL extends Apollo.Query<
    ProjectsSimilarInMentorGroupQuery,
    ProjectsSimilarInMentorGroupQueryVariables
> {
    document = ProjectsSimilarInMentorGroupQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorStudentsProjectsQueryDocument = gql`
    query ProviderMentorStudentsProjectsQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $filter: ProjectFilterType
    ) {
        provider(id: $providerId) {
            id
            mentorStudentsProjects(first: $first, last: $last, before: $before, after: $after, filter: $filter) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProjectSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProjectSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorStudentsProjectsQueryGQL extends Apollo.Query<
    ProviderMentorStudentsProjectsQuery,
    ProviderMentorStudentsProjectsQueryVariables
> {
    document = ProviderMentorStudentsProjectsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RequestProjectSectionReviewMutationDocument = gql`
    mutation RequestProjectSectionReviewMutation($input: RequestProjectSectionReviewInput!) {
        requestProjectSectionReview(input: $input) {
            projectSection {
                ...ProjectSection
            }
        }
    }
    ${ProjectSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RequestProjectSectionReviewMutationGQL extends Apollo.Mutation<
    RequestProjectSectionReviewMutation,
    RequestProjectSectionReviewMutationVariables
> {
    document = RequestProjectSectionReviewMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UncompleteProjectSectionMutationDocument = gql`
    mutation UncompleteProjectSectionMutation($input: UncompleteProjectSectionInput!) {
        uncompleteProjectSection(input: $input) {
            projectSection {
                ...ProjectSection
            }
        }
    }
    ${ProjectSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UncompleteProjectSectionMutationGQL extends Apollo.Mutation<
    UncompleteProjectSectionMutation,
    UncompleteProjectSectionMutationVariables
> {
    document = UncompleteProjectSectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProjectMentorGroupMutationDocument = gql`
    mutation UpdateProjectMentorGroupMutation($input: UpdateProjectMentorGroupInput!) {
        updateProjectMentorGroup(input: $input) {
            project {
                id
                mentorGroup {
                    ...ProjectMentorGroup
                }
            }
        }
    }
    ${ProjectMentorGroupFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProjectMentorGroupMutationGQL extends Apollo.Mutation<
    UpdateProjectMentorGroupMutation,
    UpdateProjectMentorGroupMutationVariables
> {
    document = UpdateProjectMentorGroupMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProjectSectionCommentMutationDocument = gql`
    mutation UpdateProjectSectionCommentMutation($input: UpdateProjectSectionCommentInput!) {
        updateProjectSectionComment(input: $input) {
            projectSectionComment {
                ...ProjectSectionComment
            }
        }
    }
    ${ProjectSectionCommentFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProjectSectionCommentMutationGQL extends Apollo.Mutation<
    UpdateProjectSectionCommentMutation,
    UpdateProjectSectionCommentMutationVariables
> {
    document = UpdateProjectSectionCommentMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProjectSectionEvidenceMutationDocument = gql`
    mutation UpdateProjectSectionEvidenceMutation($input: UpdateProjectSectionEvidenceInput!) {
        updateProjectSectionEvidence(input: $input) {
            projectSectionEvidence {
                ...ProjectSectionEvidence
            }
        }
    }
    ${ProjectSectionEvidenceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProjectSectionEvidenceMutationGQL extends Apollo.Mutation<
    UpdateProjectSectionEvidenceMutation,
    UpdateProjectSectionEvidenceMutationVariables
> {
    document = UpdateProjectSectionEvidenceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProjectSectionResponseMutationDocument = gql`
    mutation UpdateProjectSectionResponseMutation($input: UpdateProjectSectionResponseInput!) {
        updateProjectSectionResponse(input: $input) {
            projectSection {
                ...ProjectSection
            }
        }
    }
    ${ProjectSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProjectSectionResponseMutationGQL extends Apollo.Mutation<
    UpdateProjectSectionResponseMutation,
    UpdateProjectSectionResponseMutationVariables
> {
    document = UpdateProjectSectionResponseMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderMemberInviteMutationDocument = gql`
    mutation CreateProviderMemberInviteMutation($input: CreateProviderMemberInviteInput!) {
        createProviderMemberInvite(input: $input) {
            invite {
                ...ProviderMemberInvite
            }
        }
    }
    ${ProviderMemberInviteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderMemberInviteMutationGQL extends Apollo.Mutation<
    CreateProviderMemberInviteMutation,
    CreateProviderMemberInviteMutationVariables
> {
    document = CreateProviderMemberInviteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderMemberInvitesMutationDocument = gql`
    mutation CreateProviderMemberInvitesMutation($input: CreateProviderMemberInvitesInput!) {
        createProviderMemberInvites(input: $input) {
            invites {
                ...ProviderMemberInvite
            }
        }
    }
    ${ProviderMemberInviteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderMemberInvitesMutationGQL extends Apollo.Mutation<
    CreateProviderMemberInvitesMutation,
    CreateProviderMemberInvitesMutationVariables
> {
    document = CreateProviderMemberInvitesMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderMutationDocument = gql`
    mutation CreateProviderMutation($input: CreateProviderInput!) {
        createProvider(input: $input) {
            provider {
                ...ProviderSimple
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderMutationGQL extends Apollo.Mutation<CreateProviderMutation, CreateProviderMutationVariables> {
    document = CreateProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderMySchoolInformationDocument = gql`
    mutation createProviderMySchoolInformation($input: CreateProviderMySchoolInformationInput!) {
        createProviderMySchoolInformation(input: $input) {
            providerMySchoolInformationItem {
                id
                title
                information
                sequence
                updatedAt
                createdAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderMySchoolInformationGQL extends Apollo.Mutation<
    CreateProviderMySchoolInformationMutation,
    CreateProviderMySchoolInformationMutationVariables
> {
    document = CreateProviderMySchoolInformationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderJoinCodeMutationDocument = gql`
    mutation DeleteProviderJoinCodeMutation($input: DeleteProviderJoinCodeInput!) {
        deleteProviderJoinCode(input: $input) {
            providerJoinCodeId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderJoinCodeMutationGQL extends Apollo.Mutation<
    DeleteProviderJoinCodeMutation,
    DeleteProviderJoinCodeMutationVariables
> {
    document = DeleteProviderJoinCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderMemberInviteMutationDocument = gql`
    mutation DeleteProviderMemberInviteMutation($input: DeleteProviderMemberInviteInput!) {
        deleteProviderMemberInvite(input: $input) {
            providerMemberInviteId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderMemberInviteMutationGQL extends Apollo.Mutation<
    DeleteProviderMemberInviteMutation,
    DeleteProviderMemberInviteMutationVariables
> {
    document = DeleteProviderMemberInviteMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderMemberMutationDocument = gql`
    mutation DeleteProviderMemberMutation($input: DeleteProviderMemberInput!) {
        deleteProviderMember(input: $input) {
            providerMemberId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderMemberMutationGQL extends Apollo.Mutation<DeleteProviderMemberMutation, DeleteProviderMemberMutationVariables> {
    document = DeleteProviderMemberMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderMutationDocument = gql`
    mutation DeleteProviderMutation($input: DeleteProviderInput!) {
        deleteProvider(input: $input) {
            providerId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderMutationGQL extends Apollo.Mutation<DeleteProviderMutation, DeleteProviderMutationVariables> {
    document = DeleteProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderMySchoolInformationMutationDocument = gql`
    mutation DeleteProviderMySchoolInformationMutation($input: DeleteProviderMySchoolInformationInput!) {
        deleteProviderMySchoolInformation(input: $input) {
            providerMySchoolInformationId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderMySchoolInformationMutationGQL extends Apollo.Mutation<
    DeleteProviderMySchoolInformationMutation,
    DeleteProviderMySchoolInformationMutationVariables
> {
    document = DeleteProviderMySchoolInformationMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DisableProviderJoinCodeMutationDocument = gql`
    mutation DisableProviderJoinCodeMutation($input: DisableProviderJoinCodeInput!) {
        disableProviderJoinCode(input: $input) {
            providerJoinCode {
                ...ProviderJoinCode
            }
        }
    }
    ${ProviderJoinCodeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DisableProviderJoinCodeMutationGQL extends Apollo.Mutation<
    DisableProviderJoinCodeMutation,
    DisableProviderJoinCodeMutationVariables
> {
    document = DisableProviderJoinCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DisableProviderModuleMutationDocument = gql`
    mutation DisableProviderModuleMutation($input: DisableProviderModuleInput!) {
        disableProviderModule(input: $input) {
            provider {
                ...ProviderSimple
                ...ProviderActiveModules
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderActiveModulesFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DisableProviderModuleMutationGQL extends Apollo.Mutation<
    DisableProviderModuleMutation,
    DisableProviderModuleMutationVariables
> {
    document = DisableProviderModuleMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const EnableProviderJoinCodeMutationDocument = gql`
    mutation EnableProviderJoinCodeMutation($input: EnableProviderJoinCodeInput!) {
        enableProviderJoinCode(input: $input) {
            providerJoinCode {
                ...ProviderJoinCode
            }
        }
    }
    ${ProviderJoinCodeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class EnableProviderJoinCodeMutationGQL extends Apollo.Mutation<
    EnableProviderJoinCodeMutation,
    EnableProviderJoinCodeMutationVariables
> {
    document = EnableProviderJoinCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const EnableProviderModuleMutationDocument = gql`
    mutation EnableProviderModuleMutation($input: EnableProviderModuleInput!) {
        enableProviderModule(input: $input) {
            provider {
                ...ProviderSimple
                ...ProviderActiveModules
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderActiveModulesFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class EnableProviderModuleMutationGQL extends Apollo.Mutation<EnableProviderModuleMutation, EnableProviderModuleMutationVariables> {
    document = EnableProviderModuleMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const FollowProviderMutationDocument = gql`
    mutation FollowProviderMutation($input: FollowProviderInput!) {
        followProvider(input: $input) {
            providerId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class FollowProviderMutationGQL extends Apollo.Mutation<FollowProviderMutation, FollowProviderMutationVariables> {
    document = FollowProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GenerateProviderJoinCodeMutationDocument = gql`
    mutation GenerateProviderJoinCodeMutation($input: GenerateProviderJoinCodeInput!) {
        generateProviderJoinCode(input: $input) {
            providerJoinCode {
                ...ProviderJoinCode
            }
        }
    }
    ${ProviderJoinCodeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class GenerateProviderJoinCodeMutationGQL extends Apollo.Mutation<
    GenerateProviderJoinCodeMutation,
    GenerateProviderJoinCodeMutationVariables
> {
    document = GenerateProviderJoinCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LeaveProviderMutationDocument = gql`
    mutation LeaveProviderMutation($input: LeaveProviderInput!) {
        leaveProvider(input: $input) {
            providerId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class LeaveProviderMutationGQL extends Apollo.Mutation<LeaveProviderMutation, LeaveProviderMutationVariables> {
    document = LeaveProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderJoinCodeQueryDocument = gql`
    query ProviderJoinCodeQuery($providerId: UUID!) {
        provider(id: $providerId) {
            ...ProviderSimple
            joinCode {
                ...ProviderJoinCode
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderJoinCodeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderJoinCodeQueryGQL extends Apollo.Query<ProviderJoinCodeQuery, ProviderJoinCodeQueryVariables> {
    document = ProviderJoinCodeQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMemberInvitesQueryDocument = gql`
    query ProviderMemberInvitesQuery($providerId: UUID!) {
        provider(id: $providerId) {
            ...ProviderSimple
            memberInvites: memberInvitesList {
                ...ProviderMemberInvite
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderMemberInviteFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMemberInvitesQueryGQL extends Apollo.Query<ProviderMemberInvitesQuery, ProviderMemberInvitesQueryVariables> {
    document = ProviderMemberInvitesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMembersQueryDocument = gql`
    query ProviderMembersQuery($providerId: UUID!) {
        provider(id: $providerId) {
            ...ProviderSimple
            members: membersList {
                ...ProviderMember
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderMemberFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMembersQueryGQL extends Apollo.Query<ProviderMembersQuery, ProviderMembersQueryVariables> {
    document = ProviderMembersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderModulesQueryDocument = gql`
    query ProviderModulesQuery {
        providerModules {
            ...ProviderModule
        }
    }
    ${ProviderModuleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderModulesQueryGQL extends Apollo.Query<ProviderModulesQuery, ProviderModulesQueryVariables> {
    document = ProviderModulesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMySchoolInformationItemQueryDocument = gql`
    query ProviderMySchoolInformationItemQuery($itemId: UUID!) {
        providerMySchoolInformationItem(id: $itemId) {
            ...ProviderMySchoolInformationItem
        }
    }
    ${ProviderMySchoolInformationItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMySchoolInformationItemQueryGQL extends Apollo.Query<
    ProviderMySchoolInformationItemQuery,
    ProviderMySchoolInformationItemQueryVariables
> {
    document = ProviderMySchoolInformationItemQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMySchoolInformationQueryDocument = gql`
    query ProviderMySchoolInformationQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        provider(id: $providerId) {
            id
            mySchoolInformationItems(first: $first, last: $last, before: $before, after: $after) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProviderMySchoolInformationItem
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProviderMySchoolInformationItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMySchoolInformationQueryGQL extends Apollo.Query<
    ProviderMySchoolInformationQuery,
    ProviderMySchoolInformationQueryVariables
> {
    document = ProviderMySchoolInformationQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderQueryDocument = gql`
    query ProviderQuery(
        $providerId: UUID!
        $withInternalContactDetails: Boolean = false
        $withTechnicalContactDetails: Boolean = false
        $withActiveModules: Boolean = false
        $withPermissions: Boolean = false
    ) {
        provider(id: $providerId) {
            ...ProviderSimple
            ...ProviderInternalContactDetails @include(if: $withInternalContactDetails)
            ...ProviderTechnicalContactDetails @include(if: $withTechnicalContactDetails)
            ...ProviderActiveModules @include(if: $withActiveModules)
            ...ProviderPermissions @include(if: $withPermissions)
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderInternalContactDetailsFragmentDoc}
    ${ProviderTechnicalContactDetailsFragmentDoc}
    ${ProviderActiveModulesFragmentDoc}
    ${ProviderPermissionsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderQueryGQL extends Apollo.Query<ProviderQuery, ProviderQueryVariables> {
    document = ProviderQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderRolesQueryDocument = gql`
    query ProviderRolesQuery {
        providerRoles {
            ...ProviderRole
        }
    }
    ${ProviderRoleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderRolesQueryGQL extends Apollo.Query<ProviderRolesQuery, ProviderRolesQueryVariables> {
    document = ProviderRolesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProvidersSearchQueryDocument = gql`
    query ProvidersSearchQuery(
        $types: [ProviderType!]
        $query: String
        $first: Int
        $last: Int
        $before: String
        $after: String
        $sort: ProviderSortType
    ) {
        providers(first: $first, last: $last, before: $before, after: $after, types: $types, query: $query, sort: $sort) {
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...ProviderSearchResult
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProviderSearchResultFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProvidersSearchQueryGQL extends Apollo.Query<ProvidersSearchQuery, ProvidersSearchQueryVariables> {
    document = ProvidersSearchQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RedeemProviderJoinCodeMutationDocument = gql`
    mutation RedeemProviderJoinCodeMutation($input: RedeemProviderJoinCodeInput!) {
        redeemProviderJoinCode(input: $input) {
            providerMember {
                ...ProviderMember
                ...ProviderMemberProvider
            }
        }
    }
    ${ProviderMemberFragmentDoc}
    ${ProviderMemberProviderFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RedeemProviderJoinCodeMutationGQL extends Apollo.Mutation<
    RedeemProviderJoinCodeMutation,
    RedeemProviderJoinCodeMutationVariables
> {
    document = RedeemProviderJoinCodeMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RequestProviderModuleMutationDocument = gql`
    mutation RequestProviderModuleMutation($input: RequestProviderModuleInput!) {
        requestProviderModule(input: $input) {
            module {
                ...ProviderModule
            }
        }
    }
    ${ProviderModuleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class RequestProviderModuleMutationGQL extends Apollo.Mutation<
    RequestProviderModuleMutation,
    RequestProviderModuleMutationVariables
> {
    document = RequestProviderModuleMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StartProviderImageUploadMutationDocument = gql`
    mutation StartProviderImageUploadMutation($input: StartProviderImageUploadInput!) {
        startProviderImageUpload(input: $input) {
            fileId
            uploadUrl
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class StartProviderImageUploadMutationGQL extends Apollo.Mutation<
    StartProviderImageUploadMutation,
    StartProviderImageUploadMutationVariables
> {
    document = StartProviderImageUploadMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnfollowProviderMutationDocument = gql`
    mutation UnfollowProviderMutation($input: UnfollowProviderInput!) {
        unfollowProvider(input: $input) {
            providerId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UnfollowProviderMutationGQL extends Apollo.Mutation<UnfollowProviderMutation, UnfollowProviderMutationVariables> {
    document = UnfollowProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderDetailsMutationDocument = gql`
    mutation UpdateProviderDetailsMutation($input: UpdateProviderDetailsInput!) {
        updateProviderDetails(input: $input) {
            provider {
                ...ProviderSimple
                ...ProviderInternalContactDetails
                ...ProviderTechnicalContactDetails
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${ProviderInternalContactDetailsFragmentDoc}
    ${ProviderTechnicalContactDetailsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderDetailsMutationGQL extends Apollo.Mutation<
    UpdateProviderDetailsMutation,
    UpdateProviderDetailsMutationVariables
> {
    document = UpdateProviderDetailsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderMemberRolesMutationDocument = gql`
    mutation UpdateProviderMemberRolesMutation($input: UpdateProviderMemberRolesInput!) {
        updateProviderMemberRoles(input: $input) {
            member {
                ...ProviderMember
            }
        }
    }
    ${ProviderMemberFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderMemberRolesMutationGQL extends Apollo.Mutation<
    UpdateProviderMemberRolesMutation,
    UpdateProviderMemberRolesMutationVariables
> {
    document = UpdateProviderMemberRolesMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderMySchoolInformationDocument = gql`
    mutation UpdateProviderMySchoolInformation($input: UpdateProviderMySchoolInformationInput!) {
        updateProviderMySchoolInformation(input: $input) {
            providerMySchoolInformationItem {
                id
                title
                information
                sequence
                updatedAt
                createdAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderMySchoolInformationGQL extends Apollo.Mutation<
    UpdateProviderMySchoolInformationMutation,
    UpdateProviderMySchoolInformationMutationVariables
> {
    document = UpdateProviderMySchoolInformationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderMySchoolInformationSequenceDocument = gql`
    mutation UpdateProviderMySchoolInformationSequence($input: UpdateProviderMySchoolInformationSequenceInput!) {
        updateProviderMySchoolInformationSequence(input: $input) {
            providerMySchoolInformationItems {
                ...ProviderMySchoolInformationItem
            }
        }
    }
    ${ProviderMySchoolInformationItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderMySchoolInformationSequenceGQL extends Apollo.Mutation<
    UpdateProviderMySchoolInformationSequenceMutation,
    UpdateProviderMySchoolInformationSequenceMutationVariables
> {
    document = UpdateProviderMySchoolInformationSequenceDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CopyProviderProjectTemplateMutationDocument = gql`
    mutation CopyProviderProjectTemplateMutation($input: CopyProviderProjectTemplateInput!) {
        copyProviderProjectTemplate(input: $input) {
            providerProjectTemplate {
                ...ProviderProjectTemplate
            }
        }
    }
    ${ProviderProjectTemplateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CopyProviderProjectTemplateMutationGQL extends Apollo.Mutation<
    CopyProviderProjectTemplateMutation,
    CopyProviderProjectTemplateMutationVariables
> {
    document = CopyProviderProjectTemplateMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderProjectTemplateMutationDocument = gql`
    mutation CreateProviderProjectTemplateMutation($input: CreateProviderProjectTemplateInput!) {
        createProviderProjectTemplate(input: $input) {
            providerProjectTemplate {
                ...ProviderProjectTemplate
            }
        }
    }
    ${ProviderProjectTemplateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderProjectTemplateMutationGQL extends Apollo.Mutation<
    CreateProviderProjectTemplateMutation,
    CreateProviderProjectTemplateMutationVariables
> {
    document = CreateProviderProjectTemplateMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderProjectTemplateOverviewResourceMutationDocument = gql`
    mutation CreateProviderProjectTemplateOverviewResourceMutation($input: CreateProviderProjectTemplateOverviewResourceInput!) {
        createProviderProjectTemplateOverviewResource(input: $input) {
            providerProjectTemplateOverviewResource {
                ...ProviderProjectTemplateOverviewResource
            }
        }
    }
    ${ProviderProjectTemplateOverviewResourceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderProjectTemplateOverviewResourceMutationGQL extends Apollo.Mutation<
    CreateProviderProjectTemplateOverviewResourceMutation,
    CreateProviderProjectTemplateOverviewResourceMutationVariables
> {
    document = CreateProviderProjectTemplateOverviewResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderProjectTemplateSectionMutationDocument = gql`
    mutation CreateProviderProjectTemplateSectionMutation($input: CreateProviderProjectTemplateSectionInput!) {
        createProviderProjectTemplateSection(input: $input) {
            providerProjectTemplateSection {
                ...ProviderProjectTemplateSection
            }
        }
    }
    ${ProviderProjectTemplateSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderProjectTemplateSectionMutationGQL extends Apollo.Mutation<
    CreateProviderProjectTemplateSectionMutation,
    CreateProviderProjectTemplateSectionMutationVariables
> {
    document = CreateProviderProjectTemplateSectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProviderProjectTemplateSectionResourceMutationDocument = gql`
    mutation CreateProviderProjectTemplateSectionResourceMutation($input: CreateProviderProjectTemplateSectionResourceInput!) {
        createProviderProjectTemplateSectionResource(input: $input) {
            providerProjectTemplateSectionResource {
                ...ProviderProjectTemplateSectionResource
            }
        }
    }
    ${ProviderProjectTemplateSectionResourceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateProviderProjectTemplateSectionResourceMutationGQL extends Apollo.Mutation<
    CreateProviderProjectTemplateSectionResourceMutation,
    CreateProviderProjectTemplateSectionResourceMutationVariables
> {
    document = CreateProviderProjectTemplateSectionResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderProjectTemplateMutationDocument = gql`
    mutation DeleteProviderProjectTemplateMutation($input: DeleteProviderProjectTemplateInput!) {
        deleteProviderProjectTemplate(input: $input) {
            providerProjectTemplateId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderProjectTemplateMutationGQL extends Apollo.Mutation<
    DeleteProviderProjectTemplateMutation,
    DeleteProviderProjectTemplateMutationVariables
> {
    document = DeleteProviderProjectTemplateMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderProjectTemplateOverviewResourceMutationDocument = gql`
    mutation DeleteProviderProjectTemplateOverviewResourceMutation($input: DeleteProviderProjectTemplateOverviewResourceInput!) {
        deleteProviderProjectTemplateOverviewResource(input: $input) {
            providerProjectTemplateOverviewResourceId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderProjectTemplateOverviewResourceMutationGQL extends Apollo.Mutation<
    DeleteProviderProjectTemplateOverviewResourceMutation,
    DeleteProviderProjectTemplateOverviewResourceMutationVariables
> {
    document = DeleteProviderProjectTemplateOverviewResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderProjectTemplateSectionMutationDocument = gql`
    mutation DeleteProviderProjectTemplateSectionMutation($input: DeleteProviderProjectTemplateSectionInput!) {
        deleteProviderProjectTemplateSection(input: $input) {
            providerProjectTemplateSectionId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderProjectTemplateSectionMutationGQL extends Apollo.Mutation<
    DeleteProviderProjectTemplateSectionMutation,
    DeleteProviderProjectTemplateSectionMutationVariables
> {
    document = DeleteProviderProjectTemplateSectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProviderProjectTemplateSectionResourceMutationDocument = gql`
    mutation DeleteProviderProjectTemplateSectionResourceMutation($input: DeleteProviderProjectTemplateSectionResourceInput!) {
        deleteProviderProjectTemplateSectionResource(input: $input) {
            providerProjectTemplateSectionResourceId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteProviderProjectTemplateSectionResourceMutationGQL extends Apollo.Mutation<
    DeleteProviderProjectTemplateSectionResourceMutation,
    DeleteProviderProjectTemplateSectionResourceMutationVariables
> {
    document = DeleteProviderProjectTemplateSectionResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderProjectTemplateQueryDocument = gql`
    query ProviderProjectTemplateQuery($id: UUID!) {
        providerProjectTemplate(id: $id) {
            ...ProviderProjectTemplate
        }
    }
    ${ProviderProjectTemplateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderProjectTemplateQueryGQL extends Apollo.Query<ProviderProjectTemplateQuery, ProviderProjectTemplateQueryVariables> {
    document = ProviderProjectTemplateQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderProjectTemplateSectionQueryDocument = gql`
    query ProviderProjectTemplateSectionQuery($id: UUID!) {
        providerProjectTemplateSection(id: $id) {
            ...ProviderProjectTemplateSection
        }
    }
    ${ProviderProjectTemplateSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderProjectTemplateSectionQueryGQL extends Apollo.Query<
    ProviderProjectTemplateSectionQuery,
    ProviderProjectTemplateSectionQueryVariables
> {
    document = ProviderProjectTemplateSectionQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderProjectTemplatesQueryDocument = gql`
    query ProviderProjectTemplatesQuery(
        $providerId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $includePublic: Boolean
    ) {
        provider(id: $providerId) {
            id
            providerProjectTemplates(first: $first, last: $last, before: $before, after: $after, includePublic: $includePublic) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ProviderProjectTemplateSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ProviderProjectTemplateSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderProjectTemplatesQueryGQL extends Apollo.Query<ProviderProjectTemplatesQuery, ProviderProjectTemplatesQueryVariables> {
    document = ProviderProjectTemplatesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderProjectTemplateBannerImageMutationDocument = gql`
    mutation UpdateProviderProjectTemplateBannerImageMutation($input: UpdateProviderProjectTemplateBannerImageInput!) {
        updateProviderProjectTemplateBannerImage(input: $input) {
            providerProjectTemplate {
                ...ProviderProjectTemplateSimple
            }
        }
    }
    ${ProviderProjectTemplateSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderProjectTemplateBannerImageMutationGQL extends Apollo.Mutation<
    UpdateProviderProjectTemplateBannerImageMutation,
    UpdateProviderProjectTemplateBannerImageMutationVariables
> {
    document = UpdateProviderProjectTemplateBannerImageMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderProjectTemplateMutationDocument = gql`
    mutation UpdateProviderProjectTemplateMutation($input: UpdateProviderProjectTemplateInput!) {
        updateProviderProjectTemplate(input: $input) {
            providerProjectTemplate {
                ...ProviderProjectTemplate
            }
        }
    }
    ${ProviderProjectTemplateFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderProjectTemplateMutationGQL extends Apollo.Mutation<
    UpdateProviderProjectTemplateMutation,
    UpdateProviderProjectTemplateMutationVariables
> {
    document = UpdateProviderProjectTemplateMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderProjectTemplateOverviewResourceMutationDocument = gql`
    mutation UpdateProviderProjectTemplateOverviewResourceMutation($input: UpdateProviderProjectTemplateOverviewResourceInput!) {
        updateProviderProjectTemplateOverviewResource(input: $input) {
            providerProjectTemplateOverviewResource {
                ...ProviderProjectTemplateOverviewResource
            }
        }
    }
    ${ProviderProjectTemplateOverviewResourceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderProjectTemplateOverviewResourceMutationGQL extends Apollo.Mutation<
    UpdateProviderProjectTemplateOverviewResourceMutation,
    UpdateProviderProjectTemplateOverviewResourceMutationVariables
> {
    document = UpdateProviderProjectTemplateOverviewResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderProjectTemplateSectionMutationDocument = gql`
    mutation UpdateProviderProjectTemplateSectionMutation($input: UpdateProviderProjectTemplateSectionInput!) {
        updateProviderProjectTemplateSection(input: $input) {
            providerProjectTemplateSection {
                ...ProviderProjectTemplateSection
            }
        }
    }
    ${ProviderProjectTemplateSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderProjectTemplateSectionMutationGQL extends Apollo.Mutation<
    UpdateProviderProjectTemplateSectionMutation,
    UpdateProviderProjectTemplateSectionMutationVariables
> {
    document = UpdateProviderProjectTemplateSectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderProjectTemplateSectionOrderMutationDocument = gql`
    mutation UpdateProviderProjectTemplateSectionOrderMutation($input: UpdateProviderProjectTemplateSectionOrderInput!) {
        updateProviderProjectTemplateSectionOrder(input: $input) {
            providerProjectTemplateSections {
                ...ProviderProjectTemplateSection
            }
        }
    }
    ${ProviderProjectTemplateSectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderProjectTemplateSectionOrderMutationGQL extends Apollo.Mutation<
    UpdateProviderProjectTemplateSectionOrderMutation,
    UpdateProviderProjectTemplateSectionOrderMutationVariables
> {
    document = UpdateProviderProjectTemplateSectionOrderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProviderProjectTemplateSectionResourceMutationDocument = gql`
    mutation UpdateProviderProjectTemplateSectionResourceMutation($input: UpdateProviderProjectTemplateSectionResourceInput!) {
        updateProviderProjectTemplateSectionResource(input: $input) {
            providerProjectTemplateSectionResource {
                ...ProviderProjectTemplateSectionResource
            }
        }
    }
    ${ProviderProjectTemplateSectionResourceFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateProviderProjectTemplateSectionResourceMutationGQL extends Apollo.Mutation<
    UpdateProviderProjectTemplateSectionResourceMutation,
    UpdateProviderProjectTemplateSectionResourceMutationVariables
> {
    document = UpdateProviderProjectTemplateSectionResourceMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateReflectionMutationDocument = gql`
    mutation CreateReflectionMutation($input: CreateReflectionInput!) {
        createReflection(input: $input) {
            reflection {
                ...Reflection
            }
        }
    }
    ${ReflectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateReflectionMutationGQL extends Apollo.Mutation<CreateReflectionMutation, CreateReflectionMutationVariables> {
    document = CreateReflectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteReflectionMutationDocument = gql`
    mutation DeleteReflectionMutation($input: DeleteReflectionInput!) {
        deleteReflection(input: $input) {
            reflectionId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteReflectionMutationGQL extends Apollo.Mutation<DeleteReflectionMutation, DeleteReflectionMutationVariables> {
    document = DeleteReflectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyReflectionsQueryDocument = gql`
    query MyReflectionsQuery($first: Int, $last: Int, $before: String, $after: String, $types: [ReflectionType!], $query: String) {
        me {
            id
            reflections(first: $first, last: $last, before: $before, after: $after, types: $types, query: $query) {
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...Reflection
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ReflectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyReflectionsQueryGQL extends Apollo.Query<MyReflectionsQuery, MyReflectionsQueryVariables> {
    document = MyReflectionsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReflectionQueryDocument = gql`
    query ReflectionQuery($reflectionId: UUID!) {
        reflection(id: $reflectionId) {
            ...Reflection
        }
    }
    ${ReflectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ReflectionQueryGQL extends Apollo.Query<ReflectionQuery, ReflectionQueryVariables> {
    document = ReflectionQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateReflectionMutationDocument = gql`
    mutation UpdateReflectionMutation($input: UpdateReflectionInput!) {
        updateReflection(input: $input) {
            reflection {
                ...Reflection
            }
        }
    }
    ${ReflectionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateReflectionMutationGQL extends Apollo.Mutation<UpdateReflectionMutation, UpdateReflectionMutationVariables> {
    document = UpdateReflectionMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateResourceHubItemMutationDocument = gql`
    mutation CreateResourceHubItemMutation($input: CreateResourceHubItemInput!) {
        createResourceHubItem(input: $input) {
            resourceHubItem {
                ...ResourceHubItem
            }
            fileId
            uploadUrl
        }
    }
    ${ResourceHubItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateResourceHubItemMutationGQL extends Apollo.Mutation<
    CreateResourceHubItemMutation,
    CreateResourceHubItemMutationVariables
> {
    document = CreateResourceHubItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteResourceHubItemMutationDocument = gql`
    mutation DeleteResourceHubItemMutation($input: DeleteResourceHubItemInput!) {
        deleteResourceHubItem(input: $input) {
            resourceHubItemId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteResourceHubItemMutationGQL extends Apollo.Mutation<
    DeleteResourceHubItemMutation,
    DeleteResourceHubItemMutationVariables
> {
    document = DeleteResourceHubItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyResourceHubItemsQueryDocument = gql`
    query MyResourceHubItemsQuery($first: Int, $last: Int, $before: String, $after: String, $mimeTypes: [String!]) {
        me {
            id
            resourceHubItems(first: $first, last: $last, before: $before, after: $after, mimeTypes: $mimeTypes) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...ResourceHubItem
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ResourceHubItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyResourceHubItemsQueryGQL extends Apollo.Query<MyResourceHubItemsQuery, MyResourceHubItemsQueryVariables> {
    document = MyResourceHubItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ResourceHubItemQueryDocument = gql`
    query ResourceHubItemQuery($resourceHubItemId: UUID!) {
        resourceHubItem(id: $resourceHubItemId) {
            ...ResourceHubItem
        }
    }
    ${ResourceHubItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ResourceHubItemQueryGQL extends Apollo.Query<ResourceHubItemQuery, ResourceHubItemQueryVariables> {
    document = ResourceHubItemQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateResourceHubItemMutationDocument = gql`
    mutation UpdateResourceHubItemMutation($input: UpdateResourceHubItemInput!) {
        updateResourceHubItem(input: $input) {
            resourceHubItem {
                ...ResourceHubItem
            }
        }
    }
    ${ResourceHubItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateResourceHubItemMutationGQL extends Apollo.Mutation<
    UpdateResourceHubItemMutation,
    UpdateResourceHubItemMutationVariables
> {
    document = UpdateResourceHubItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ConsumeMentorRewardMutationDocument = gql`
    mutation ConsumeMentorRewardMutation($input: ConsumeMentorRewardInput!) {
        consumeMentorReward(input: $input) {
            mentorRewardTransaction {
                ...MentorRewardTransaction
            }
        }
    }
    ${MentorRewardTransactionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ConsumeMentorRewardMutationGQL extends Apollo.Mutation<ConsumeMentorRewardMutation, ConsumeMentorRewardMutationVariables> {
    document = ConsumeMentorRewardMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMentorRewardItemMutationDocument = gql`
    mutation CreateMentorRewardItemMutation($input: CreateMentorRewardItemInput!) {
        createMentorRewardItem(input: $input) {
            mentorRewardItem {
                ...MentorRewardItem
            }
        }
    }
    ${MentorRewardItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateMentorRewardItemMutationGQL extends Apollo.Mutation<
    CreateMentorRewardItemMutation,
    CreateMentorRewardItemMutationVariables
> {
    document = CreateMentorRewardItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateMentorRewardValueMutationDocument = gql`
    mutation CreateMentorRewardValueMutation($input: CreateMentorRewardValueInput!) {
        createMentorRewardValue(input: $input) {
            mentorRewardValue {
                ...MentorRewardValue
            }
        }
    }
    ${MentorRewardValueFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateMentorRewardValueMutationGQL extends Apollo.Mutation<
    CreateMentorRewardValueMutation,
    CreateMentorRewardValueMutationVariables
> {
    document = CreateMentorRewardValueMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMentorRewardItemMutationDocument = gql`
    mutation DeleteMentorRewardItemMutation($input: DeleteMentorRewardItemInput!) {
        deleteMentorRewardItem(input: $input) {
            mentorRewardItem {
                ...MentorRewardItem
            }
        }
    }
    ${MentorRewardItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteMentorRewardItemMutationGQL extends Apollo.Mutation<
    DeleteMentorRewardItemMutation,
    DeleteMentorRewardItemMutationVariables
> {
    document = DeleteMentorRewardItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMentorRewardValueMutationDocument = gql`
    mutation DeleteMentorRewardValueMutation($input: DeleteMentorRewardValueInput!) {
        deleteMentorRewardValue(input: $input) {
            mentorRewardValueId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteMentorRewardValueMutationGQL extends Apollo.Mutation<
    DeleteMentorRewardValueMutation,
    DeleteMentorRewardValueMutationVariables
> {
    document = DeleteMentorRewardValueMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QueueDeleteProviderRewardsMutationDocument = gql`
    mutation QueueDeleteProviderRewardsMutation($input: QueueDeleteProviderRewardInput!) {
        queueDeleteProviderRewards(input: $input) {
            providerId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class QueueDeleteProviderRewardsMutationGQL extends Apollo.Mutation<
    QueueDeleteProviderRewardsMutation,
    QueueDeleteProviderRewardsMutationVariables
> {
    document = QueueDeleteProviderRewardsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GiveMentorRewardsMutationDocument = gql`
    mutation GiveMentorRewardsMutation($input: GiveMentorRewardsInput!) {
        giveMentorRewards(input: $input) {
            mentorRewardTransactions {
                ...MentorRewardTransaction
            }
        }
    }
    ${MentorRewardTransactionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class GiveMentorRewardsMutationGQL extends Apollo.Mutation<GiveMentorRewardsMutation, GiveMentorRewardsMutationVariables> {
    document = GiveMentorRewardsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ImportMentorRewardEventPointsMutationDocument = gql`
    mutation ImportMentorRewardEventPointsMutation($input: ImportMentorRewardEventPointsInput!) {
        importMentorRewardEventPoints(input: $input) {
            mentorRewardEventTransactions {
                ...MentorRewardTransaction
            }
        }
    }
    ${MentorRewardTransactionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ImportMentorRewardEventPointsMutationGQL extends Apollo.Mutation<
    ImportMentorRewardEventPointsMutation,
    ImportMentorRewardEventPointsMutationVariables
> {
    document = ImportMentorRewardEventPointsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ImportMentorRewardPointsMutationDocument = gql`
    mutation ImportMentorRewardPointsMutation($input: ImportMentorRewardPointsInput!) {
        importMentorRewardPoints(input: $input) {
            mentorRewardTransactions {
                ...MentorRewardTransaction
            }
        }
    }
    ${MentorRewardTransactionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ImportMentorRewardPointsMutationGQL extends Apollo.Mutation<
    ImportMentorRewardPointsMutation,
    ImportMentorRewardPointsMutationVariables
> {
    document = ImportMentorRewardPointsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupRewardStudentsQueryDocument = gql`
    query MentorGroupRewardStudentsQuery(
        $mentorGroupId: UUID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $start: DateTime!
        $end: DateTime!
    ) {
        mentorGroup(id: $mentorGroupId) {
            id
            title
            rewardStudents(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorRewardStudentDetailed
                        pointsSummaryRange(start: $start, end: $end) {
                            ...MentorRewardStudentPointsSummaryRange
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorRewardStudentDetailedFragmentDoc}
    ${MentorRewardStudentPointsSummaryRangeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupRewardStudentsQueryGQL extends Apollo.Query<
    MentorGroupRewardStudentsQuery,
    MentorGroupRewardStudentsQueryVariables
> {
    document = MentorGroupRewardStudentsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorGroupRewardStudentsSimpleQueryDocument = gql`
    query MentorGroupRewardStudentsSimpleQuery($mentorGroupId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorGroup(id: $mentorGroupId) {
            id
            title
            rewardStudents(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorRewardStudentSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorRewardStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorGroupRewardStudentsSimpleQueryGQL extends Apollo.Query<
    MentorGroupRewardStudentsSimpleQuery,
    MentorGroupRewardStudentsSimpleQueryVariables
> {
    document = MentorGroupRewardStudentsSimpleQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorRewardEventsQueryDocument = gql`
    query MentorRewardEventsQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String, $query: String) {
        provider(id: $providerId) {
            id
            mentorRewardEvents(first: $first, last: $last, before: $before, after: $after, query: $query) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorRewardEvent
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorRewardEventFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorRewardEventsQueryGQL extends Apollo.Query<MentorRewardEventsQuery, MentorRewardEventsQueryVariables> {
    document = MentorRewardEventsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorRewardItemsQueryDocument = gql`
    query MentorRewardItemsQuery($providerId: UUID!) {
        provider(id: $providerId) {
            id
            mentorRewardItems {
                ...MentorRewardItem
            }
        }
    }
    ${MentorRewardItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorRewardItemsQueryGQL extends Apollo.Query<MentorRewardItemsQuery, MentorRewardItemsQueryVariables> {
    document = MentorRewardItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorRewardStudentQueryDocument = gql`
    query MentorRewardStudentQuery($id: UUID!) {
        mentorRewardStudent(id: $id) {
            ...MentorRewardStudentDetailed
        }
    }
    ${MentorRewardStudentDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorRewardStudentQueryGQL extends Apollo.Query<MentorRewardStudentQuery, MentorRewardStudentQueryVariables> {
    document = MentorRewardStudentQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorRewardStudentTransactionsQueryDocument = gql`
    query MentorRewardStudentTransactionsQuery($id: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        mentorRewardStudent(id: $id) {
            id
            transactions(first: $first, last: $last, before: $before, after: $after) {
                notViewedCount
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorRewardTransaction
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorRewardTransactionFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorRewardStudentTransactionsQueryGQL extends Apollo.Query<
    MentorRewardStudentTransactionsQuery,
    MentorRewardStudentTransactionsQueryVariables
> {
    document = MentorRewardStudentTransactionsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MentorRewardValuesQueryDocument = gql`
    query MentorRewardValuesQuery($providerId: UUID!) {
        provider(id: $providerId) {
            id
            mentorRewardValues {
                ...MentorRewardValue
            }
        }
    }
    ${MentorRewardValueFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MentorRewardValuesQueryGQL extends Apollo.Query<MentorRewardValuesQuery, MentorRewardValuesQueryVariables> {
    document = MentorRewardValuesQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorRewardAnalyticsQueryDocument = gql`
    query ProviderMentorRewardAnalyticsQuery($providerId: UUID!, $year: Int!, $mentorGroupIds: [UUID!], $mentorRewardEventIds: [UUID!]) {
        provider(id: $providerId) {
            id
            mentorRewardAnalytics {
                pointsYearSummaryByType(year: $year, mentorGroupIds: $mentorGroupIds, mentorRewardEventIds: $mentorRewardEventIds) {
                    ...PointsYearSummaryByType
                }
            }
        }
    }
    ${PointsYearSummaryByTypeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorRewardAnalyticsQueryGQL extends Apollo.Query<
    ProviderMentorRewardAnalyticsQuery,
    ProviderMentorRewardAnalyticsQueryVariables
> {
    document = ProviderMentorRewardAnalyticsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorRewardPointStatsQueryDocument = gql`
    query ProviderMentorRewardPointStatsQuery(
        $providerId: UUID!
        $start: DateTime!
        $end: DateTime!
        $mentorGroupIds: [UUID!]
        $mentorRewardEventIds: [UUID!]
    ) {
        provider(id: $providerId) {
            id
            mentorRewardAnalytics {
                pointsSummaryByType(
                    start: $start
                    end: $end
                    mentorGroupIds: $mentorGroupIds
                    mentorRewardEventIds: $mentorRewardEventIds
                ) {
                    ...PointsSummaryByType
                }
            }
        }
    }
    ${PointsSummaryByTypeFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorRewardPointStatsQueryGQL extends Apollo.Query<
    ProviderMentorRewardPointStatsQuery,
    ProviderMentorRewardPointStatsQueryVariables
> {
    document = ProviderMentorRewardPointStatsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorRewardStudentsQueryDocument = gql`
    query ProviderMentorRewardStudentsQuery($providerId: UUID!, $first: Int, $last: Int, $before: String, $after: String, $query: String) {
        provider(id: $providerId) {
            id
            title
            logoImageUrl
            mentorRewardStudents(first: $first, last: $last, before: $before, after: $after, query: $query) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...MentorRewardStudentSimple
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${MentorRewardStudentSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorRewardStudentsQueryGQL extends Apollo.Query<
    ProviderMentorRewardStudentsQuery,
    ProviderMentorRewardStudentsQueryVariables
> {
    document = ProviderMentorRewardStudentsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorRewardTopLearnersQueryDocument = gql`
    query ProviderMentorRewardTopLearnersQuery(
        $providerId: UUID!
        $start: DateTime!
        $end: DateTime!
        $mentorGroupIds: [UUID!]
        $mentorRewardEventIds: [UUID!]
    ) {
        provider(id: $providerId) {
            id
            mentorRewardAnalytics {
                topLearners(start: $start, end: $end, mentorGroupIds: $mentorGroupIds, mentorRewardEventIds: $mentorRewardEventIds) {
                    ...MentorRewardTopLearner
                }
            }
            mentorRewardValues {
                ...MentorRewardValue
            }
        }
    }
    ${MentorRewardTopLearnerFragmentDoc}
    ${MentorRewardValueFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorRewardTopLearnersQueryGQL extends Apollo.Query<
    ProviderMentorRewardTopLearnersQuery,
    ProviderMentorRewardTopLearnersQueryVariables
> {
    document = ProviderMentorRewardTopLearnersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorRewardTopMentorGroupsQueryDocument = gql`
    query ProviderMentorRewardTopMentorGroupsQuery($providerId: UUID!, $start: DateTime!, $end: DateTime!) {
        provider(id: $providerId) {
            id
            mentorRewardAnalytics {
                topMentorGroups(start: $start, end: $end) {
                    ...MentorRewardTopMentorGroup
                }
            }
            mentorRewardValues {
                ...MentorRewardValue
            }
        }
    }
    ${MentorRewardTopMentorGroupFragmentDoc}
    ${MentorRewardValueFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorRewardTopMentorGroupsQueryGQL extends Apollo.Query<
    ProviderMentorRewardTopMentorGroupsQuery,
    ProviderMentorRewardTopMentorGroupsQueryVariables
> {
    document = ProviderMentorRewardTopMentorGroupsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProviderMentorRewardTopMentorsQueryDocument = gql`
    query ProviderMentorRewardTopMentorsQuery($providerId: UUID!, $start: DateTime!, $end: DateTime!, $mentorGroupIds: [UUID!]) {
        provider(id: $providerId) {
            id
            mentorRewardAnalytics {
                topMentors(start: $start, end: $end, mentorGroupIds: $mentorGroupIds) {
                    ...MentorRewardTopMentor
                }
            }
        }
    }
    ${MentorRewardTopMentorFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ProviderMentorRewardTopMentorsQueryGQL extends Apollo.Query<
    ProviderMentorRewardTopMentorsQuery,
    ProviderMentorRewardTopMentorsQueryVariables
> {
    document = ProviderMentorRewardTopMentorsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorRewardItemMutationDocument = gql`
    mutation UpdateMentorRewardItemMutation($input: UpdateMentorRewardItemInput!) {
        updateMentorRewardItem(input: $input) {
            mentorRewardItem {
                ...MentorRewardItem
            }
        }
    }
    ${MentorRewardItemFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorRewardItemMutationGQL extends Apollo.Mutation<
    UpdateMentorRewardItemMutation,
    UpdateMentorRewardItemMutationVariables
> {
    document = UpdateMentorRewardItemMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorRewardValueMutationDocument = gql`
    mutation UpdateMentorRewardValueMutation($input: UpdateMentorRewardValueInput!) {
        updateMentorRewardValue(input: $input) {
            mentorRewardValue {
                ...MentorRewardValue
            }
        }
    }
    ${MentorRewardValueFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorRewardValueMutationGQL extends Apollo.Mutation<
    UpdateMentorRewardValueMutation,
    UpdateMentorRewardValueMutationVariables
> {
    document = UpdateMentorRewardValueMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMentorRewardViewedMutationDocument = gql`
    mutation UpdateMentorRewardViewedMutation($input: UpdateMentorRewardViewedInput!) {
        updateMentorRewardViewed(input: $input) {
            viewedCount
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateMentorRewardViewedMutationGQL extends Apollo.Mutation<
    UpdateMentorRewardViewedMutation,
    UpdateMentorRewardViewedMutationVariables
> {
    document = UpdateMentorRewardViewedMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UrlMetadataQueryDocument = gql`
    query UrlMetadataQuery($url: URL!) {
        urlMetadata(url: $url)
    }
`;

@Injectable({
    providedIn: 'root'
})
export class UrlMetadataQueryGQL extends Apollo.Query<UrlMetadataQuery, UrlMetadataQueryVariables> {
    document = UrlMetadataQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ActiveSponsorCampaignBannerQueryDocument = gql`
    query ActiveSponsorCampaignBannerQuery($type: SponsorAssetType!) {
        sponsorCampaignBanner(type: $type) {
            ...SponsorCampaignBanner
        }
    }
    ${SponsorCampaignBannerFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ActiveSponsorCampaignBannerQueryGQL extends Apollo.Query<
    ActiveSponsorCampaignBannerQuery,
    ActiveSponsorCampaignBannerQueryVariables
> {
    document = ActiveSponsorCampaignBannerQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateSponsorAssetMutationDocument = gql`
    mutation CreateSponsorAssetMutation($input: CreateSponsorAssetInput!) {
        createSponsorAsset(input: $input) {
            sponsorAsset {
                ...SponsorAssetDetailed
            }
            fileId
            uploadUrl
        }
    }
    ${SponsorAssetDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateSponsorAssetMutationGQL extends Apollo.Mutation<CreateSponsorAssetMutation, CreateSponsorAssetMutationVariables> {
    document = CreateSponsorAssetMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateSponsorCampaignAssetMutationDocument = gql`
    mutation CreateSponsorCampaignAssetMutation($input: CreateSponsorCampaignAssetInput!) {
        createSponsorCampaignAsset(input: $input) {
            sponsorCampaignAsset {
                ...SponsorCampaignAsset
            }
        }
    }
    ${SponsorCampaignAssetFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateSponsorCampaignAssetMutationGQL extends Apollo.Mutation<
    CreateSponsorCampaignAssetMutation,
    CreateSponsorCampaignAssetMutationVariables
> {
    document = CreateSponsorCampaignAssetMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateSponsorCampaignMutationDocument = gql`
    mutation CreateSponsorCampaignMutation($input: CreateSponsorCampaignInput!) {
        createSponsorCampaign(input: $input) {
            sponsorCampaign {
                ...SponsorCampaignSimple
            }
        }
    }
    ${SponsorCampaignSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateSponsorCampaignMutationGQL extends Apollo.Mutation<
    CreateSponsorCampaignMutation,
    CreateSponsorCampaignMutationVariables
> {
    document = CreateSponsorCampaignMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateSponsorProviderMutationDocument = gql`
    mutation CreateSponsorProviderMutation($input: CreateSponsorProviderInput!) {
        createSponsorProvider(input: $input) {
            sponsorProvider {
                ...SponsorProviderSimple
            }
        }
    }
    ${SponsorProviderSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateSponsorProviderMutationGQL extends Apollo.Mutation<
    CreateSponsorProviderMutation,
    CreateSponsorProviderMutationVariables
> {
    document = CreateSponsorProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteSponsorAssetMutationDocument = gql`
    mutation DeleteSponsorAssetMutation($input: DeleteSponsorAssetInput!) {
        deleteSponsorAsset(input: $input) {
            sponsorAssetId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteSponsorAssetMutationGQL extends Apollo.Mutation<DeleteSponsorAssetMutation, DeleteSponsorAssetMutationVariables> {
    document = DeleteSponsorAssetMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteSponsorCampaignAssetMutationDocument = gql`
    mutation DeleteSponsorCampaignAssetMutation($input: DeleteSponsorCampaignAssetInput!) {
        deleteSponsorCampaignAsset(input: $input) {
            sponsorCampaignAssetId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteSponsorCampaignAssetMutationGQL extends Apollo.Mutation<
    DeleteSponsorCampaignAssetMutation,
    DeleteSponsorCampaignAssetMutationVariables
> {
    document = DeleteSponsorCampaignAssetMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteSponsorCampaignMutationDocument = gql`
    mutation DeleteSponsorCampaignMutation($input: DeleteSponsorCampaignInput!) {
        deleteSponsorCampaign(input: $input) {
            sponsorCampaignId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteSponsorCampaignMutationGQL extends Apollo.Mutation<
    DeleteSponsorCampaignMutation,
    DeleteSponsorCampaignMutationVariables
> {
    document = DeleteSponsorCampaignMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteSponsorProviderMutationDocument = gql`
    mutation DeleteSponsorProviderMutation($input: DeleteSponsorProviderInput!) {
        deleteSponsorProvider(input: $input) {
            sponsorProviderId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteSponsorProviderMutationGQL extends Apollo.Mutation<
    DeleteSponsorProviderMutation,
    DeleteSponsorProviderMutationVariables
> {
    document = DeleteSponsorProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PublishSponsorCampaignMutationDocument = gql`
    mutation PublishSponsorCampaignMutation($input: PublishSponsorCampaignInput!) {
        publishSponsorCampaign(input: $input) {
            sponsorCampaign {
                ...SponsorCampaignDetailed
            }
        }
    }
    ${SponsorCampaignDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class PublishSponsorCampaignMutationGQL extends Apollo.Mutation<
    PublishSponsorCampaignMutation,
    PublishSponsorCampaignMutationVariables
> {
    document = PublishSponsorCampaignMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SponsorAssetsQueryDocument = gql`
    query SponsorAssetsQuery($first: Int, $last: Int, $before: String, $after: String, $sponsorProviderId: UUID) {
        sponsorAssets(first: $first, last: $last, before: $before, after: $after, sponsorProviderId: $sponsorProviderId) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                node {
                    ...SponsorAssetDetailed
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${SponsorAssetDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SponsorAssetsQueryGQL extends Apollo.Query<SponsorAssetsQuery, SponsorAssetsQueryVariables> {
    document = SponsorAssetsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SponsorCampaignAnalyticQueryDocument = gql`
    query SponsorCampaignAnalyticQuery($sponsorCampaignId: UUID!) {
        sponsorCampaign(id: $sponsorCampaignId) {
            totalClicksCount
            totalViewsCount
            sponsorCampaignAssets {
                totalCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class SponsorCampaignAnalyticQueryGQL extends Apollo.Query<SponsorCampaignAnalyticQuery, SponsorCampaignAnalyticQueryVariables> {
    document = SponsorCampaignAnalyticQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SponsorCampaignQueryDocument = gql`
    query SponsorCampaignQuery($sponsorCampaignId: UUID!, $first: Int, $last: Int, $before: String, $after: String) {
        sponsorCampaign(id: $sponsorCampaignId) {
            ...SponsorCampaignWithoutAsset
            sponsorCampaignAssets(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...SponsorCampaignAsset
                    }
                }
            }
        }
    }
    ${SponsorCampaignWithoutAssetFragmentDoc}
    ${PageInfoFragmentDoc}
    ${SponsorCampaignAssetFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SponsorCampaignQueryGQL extends Apollo.Query<SponsorCampaignQuery, SponsorCampaignQueryVariables> {
    document = SponsorCampaignQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SponsorCampaignsQueryDocument = gql`
    query SponsorCampaignsQuery(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $sponsorProviderId: UUID
        $sponsorCampaignStatus: SponsorCampaignStatus
    ) {
        sponsorCampaigns(
            first: $first
            last: $last
            before: $before
            after: $after
            sponsorProviderId: $sponsorProviderId
            sponsorCampaignStatus: $sponsorCampaignStatus
        ) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...SponsorCampaignSummary
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${SponsorCampaignSummaryFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SponsorCampaignsQueryGQL extends Apollo.Query<SponsorCampaignsQuery, SponsorCampaignsQueryVariables> {
    document = SponsorCampaignsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SponsorProvidersQueryDocument = gql`
    query SponsorProvidersQuery($first: Int, $last: Int, $before: String, $after: String) {
        sponsorProviders(first: $first, last: $last, before: $before, after: $after) {
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...SponsorProviderDetailed
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${SponsorProviderDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SponsorProvidersQueryGQL extends Apollo.Query<SponsorProvidersQuery, SponsorProvidersQueryVariables> {
    document = SponsorProvidersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnpublishSponsorCampaignMutationDocument = gql`
    mutation UnpublishSponsorCampaignMutation($input: UnpublishSponsorCampaignInput!) {
        unpublishSponsorCampaign(input: $input) {
            sponsorCampaign {
                ...SponsorCampaignDetailed
            }
        }
    }
    ${SponsorCampaignDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnpublishSponsorCampaignMutationGQL extends Apollo.Mutation<
    UnpublishSponsorCampaignMutation,
    UnpublishSponsorCampaignMutationVariables
> {
    document = UnpublishSponsorCampaignMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateSponsorAssetMutationDocument = gql`
    mutation UpdateSponsorAssetMutation($input: UpdateSponsorAssetInput!) {
        updateSponsorAsset(input: $input) {
            sponsorAsset {
                ...SponsorAssetDetailed
            }
        }
    }
    ${SponsorAssetDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateSponsorAssetMutationGQL extends Apollo.Mutation<UpdateSponsorAssetMutation, UpdateSponsorAssetMutationVariables> {
    document = UpdateSponsorAssetMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateSponsorCampaignAssetMutationDocument = gql`
    mutation UpdateSponsorCampaignAssetMutation($input: UpdateSponsorCampaignAssetInput!) {
        updateSponsorCampaignAsset(input: $input) {
            sponsorCampaignAsset {
                ...SponsorCampaignAsset
            }
        }
    }
    ${SponsorCampaignAssetFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateSponsorCampaignAssetMutationGQL extends Apollo.Mutation<
    UpdateSponsorCampaignAssetMutation,
    UpdateSponsorCampaignAssetMutationVariables
> {
    document = UpdateSponsorCampaignAssetMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateSponsorCampaignMutationDocument = gql`
    mutation UpdateSponsorCampaignMutation($input: UpdateSponsorCampaignInput!) {
        updateSponsorCampaign(input: $input) {
            sponsorCampaign {
                ...SponsorCampaignDetailed
            }
        }
    }
    ${SponsorCampaignDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateSponsorCampaignMutationGQL extends Apollo.Mutation<
    UpdateSponsorCampaignMutation,
    UpdateSponsorCampaignMutationVariables
> {
    document = UpdateSponsorCampaignMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateSponsorProviderMutationDocument = gql`
    mutation UpdateSponsorProviderMutation($input: UpdateSponsorProviderInput!) {
        updateSponsorProvider(input: $input) {
            sponsorProvider {
                ...SponsorProviderSimple
            }
        }
    }
    ${SponsorProviderSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateSponsorProviderMutationGQL extends Apollo.Mutation<
    UpdateSponsorProviderMutation,
    UpdateSponsorProviderMutationVariables
> {
    document = UpdateSponsorProviderMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateThreeGoodThingsMutationDocument = gql`
    mutation CreateThreeGoodThingsMutation($input: CreateThreeGoodThingsInput!) {
        createThreeGoodThings(input: $input) {
            threeGoodThings {
                ...ThreeGoodThings
            }
        }
    }
    ${ThreeGoodThingsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CreateThreeGoodThingsMutationGQL extends Apollo.Mutation<
    CreateThreeGoodThingsMutation,
    CreateThreeGoodThingsMutationVariables
> {
    document = CreateThreeGoodThingsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteThreeGoodThingsMutationDocument = gql`
    mutation DeleteThreeGoodThingsMutation($input: DeleteThreeGoodThingsInput!) {
        deleteThreeGoodThings(input: $input) {
            threeGoodThingsId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class DeleteThreeGoodThingsMutationGQL extends Apollo.Mutation<
    DeleteThreeGoodThingsMutation,
    DeleteThreeGoodThingsMutationVariables
> {
    document = DeleteThreeGoodThingsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ThreeGoodThingsListQueryDocument = gql`
    query ThreeGoodThingsListQuery($first: Int, $last: Int, $before: String, $after: String) {
        threeGoodThingsList(first: $first, last: $last, before: $before, after: $after) {
            totalCount
            pageInfo {
                ...PageInfo
            }
            edges {
                cursor
                node {
                    ...ThreeGoodThings
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${ThreeGoodThingsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class ThreeGoodThingsListQueryGQL extends Apollo.Query<ThreeGoodThingsListQuery, ThreeGoodThingsListQueryVariables> {
    document = ThreeGoodThingsListQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateThreeGoodThingsMutationDocument = gql`
    mutation UpdateThreeGoodThingsMutation($input: UpdateThreeGoodThingsInput!) {
        updateThreeGoodThings(input: $input) {
            threeGoodThings {
                ...ThreeGoodThings
            }
        }
    }
    ${ThreeGoodThingsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateThreeGoodThingsMutationGQL extends Apollo.Mutation<
    UpdateThreeGoodThingsMutation,
    UpdateThreeGoodThingsMutationVariables
> {
    document = UpdateThreeGoodThingsMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CheckUserVerificationRequestResultMutationDocument = gql`
    mutation CheckUserVerificationRequestResultMutation($input: CheckUserVerificationRequestResultInput!) {
        checkUserVerificationRequestResult(input: $input) {
            success
            isComplete
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class CheckUserVerificationRequestResultMutationGQL extends Apollo.Mutation<
    CheckUserVerificationRequestResultMutation,
    CheckUserVerificationRequestResultMutationVariables
> {
    document = CheckUserVerificationRequestResultMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MeDetailedQueryDocument = gql`
    query MeDetailedQuery {
        me {
            ...UserDataDetailed
        }
    }
    ${UserDataDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MeDetailedQueryGQL extends Apollo.Query<MeDetailedQuery, MeDetailedQueryVariables> {
    document = MeDetailedQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MeSimpleQueryDocument = gql`
    query MeSimpleQuery {
        me {
            ...UserDataSimple
        }
    }
    ${UserDataSimpleFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MeSimpleQueryGQL extends Apollo.Query<MeSimpleQuery, MeSimpleQueryVariables> {
    document = MeSimpleQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MeVerifiedIdentityQueryDocument = gql`
    query MeVerifiedIdentityQuery {
        me {
            ...UserDataWithVerifiedIdentity
        }
    }
    ${UserDataWithVerifiedIdentityFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MeVerifiedIdentityQueryGQL extends Apollo.Query<MeVerifiedIdentityQuery, MeVerifiedIdentityQueryVariables> {
    document = MeVerifiedIdentityQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyCareersRiasecResultQueryDocument = gql`
    query MyCareersRiasecResultQuery {
        me {
            id
            careersRiasecResult {
                ...CareersRiasecResult
            }
        }
    }
    ${CareersRiasecResultFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyCareersRiasecResultQueryGQL extends Apollo.Query<MyCareersRiasecResultQuery, MyCareersRiasecResultQueryVariables> {
    document = MyCareersRiasecResultQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyConnectedMentorProvidersQueryDocument = gql`
    query MyConnectedMentorProvidersQuery {
        me {
            id
            connectedMentorProviders {
                id
                provider {
                    ...ProviderSimple
                }
                mentorGroups {
                    ...MentorGroupWithDiscussionUserState
                }
                rewards {
                    ...MentorRewardStudentSimple
                    transactions {
                        notViewedCount
                    }
                }
                notes {
                    notViewedCount
                }
                todos {
                    notViewedCount
                }
                badges(currentProviderOnly: true) {
                    totalCount
                    edges {
                        node {
                            ...Badge
                        }
                    }
                }
            }
            availableMentorProviders(first: 100) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...ProviderSimple
                    }
                    cursor
                }
            }
        }
    }
    ${ProviderSimpleFragmentDoc}
    ${MentorGroupWithDiscussionUserStateFragmentDoc}
    ${MentorRewardStudentSimpleFragmentDoc}
    ${BadgeFragmentDoc}
    ${PageInfoFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyConnectedMentorProvidersQueryGQL extends Apollo.Query<
    MyConnectedMentorProvidersQuery,
    MyConnectedMentorProvidersQueryVariables
> {
    document = MyConnectedMentorProvidersQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyEmailNotificationsSettingQueryDocument = gql`
    query MyEmailNotificationsSettingQuery {
        me {
            id
            emailNotificationsSetting {
                ...UserEmailNotificationsSetting
            }
        }
    }
    ${UserEmailNotificationsSettingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyEmailNotificationsSettingQueryGQL extends Apollo.Query<
    MyEmailNotificationsSettingQuery,
    MyEmailNotificationsSettingQueryVariables
> {
    document = MyEmailNotificationsSettingQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyEmailStatusQueryDocument = gql`
    query MyEmailStatusQuery {
        me {
            ...UserEmailStatus
        }
    }
    ${UserEmailStatusFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyEmailStatusQueryGQL extends Apollo.Query<MyEmailStatusQuery, MyEmailStatusQueryVariables> {
    document = MyEmailStatusQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyFavouriteLessonsQueryDocument = gql`
    query MyFavouriteLessonsQuery($first: Int, $last: Int, $before: String, $after: String) {
        me {
            id
            favoriteLessons(first: $first, last: $last, before: $before, after: $after) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    cursor
                    node {
                        ...Lesson
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${LessonFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyFavouriteLessonsQueryGQL extends Apollo.Query<MyFavouriteLessonsQuery, MyFavouriteLessonsQueryVariables> {
    document = MyFavouriteLessonsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyMenuNavigationItemsQueryDocument = gql`
    query MyMenuNavigationItemsQuery {
        me {
            ...UserDataWithMenuNavigationDetails
        }
    }
    ${UserDataWithMenuNavigationDetailsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyMenuNavigationItemsQueryGQL extends Apollo.Query<MyMenuNavigationItemsQuery, MyMenuNavigationItemsQueryVariables> {
    document = MyMenuNavigationItemsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyNotificationsCountQueryDocument = gql`
    query MyNotificationsCountQuery {
        me {
            id
            connectedMentorProviders {
                mentorGroups {
                    id
                    discussionUserState {
                        unreadMessageCount
                    }
                }
                notes {
                    notViewedCount
                }
                todos {
                    notViewedCount
                }
                rewards {
                    id
                    transactions {
                        notViewedCount
                    }
                }
            }
            providersWithMembership {
                id
                mentorGroups(first: 0) {
                    unreadMessageCount
                }
            }
            connectionRequests {
                totalCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MyNotificationsCountQueryGQL extends Apollo.Query<MyNotificationsCountQuery, MyNotificationsCountQueryVariables> {
    document = MyNotificationsCountQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyProvidersWithMembershipMentorGroupsUnreadMessageCountQueryDocument = gql`
    query MyProvidersWithMembershipMentorGroupsUnreadMessageCountQuery {
        me {
            id
            providersWithMembership {
                id
                mentorGroups(first: 0) {
                    unreadMessageCount
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MyProvidersWithMembershipMentorGroupsUnreadMessageCountQueryGQL extends Apollo.Query<
    MyProvidersWithMembershipMentorGroupsUnreadMessageCountQuery,
    MyProvidersWithMembershipMentorGroupsUnreadMessageCountQueryVariables
> {
    document = MyProvidersWithMembershipMentorGroupsUnreadMessageCountQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyProvidersWithMembershipQueryDocument = gql`
    query MyProvidersWithMembershipQuery {
        me {
            id
            providersWithMembership {
                ...ProviderWithPermissions
            }
        }
    }
    ${ProviderWithPermissionsFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyProvidersWithMembershipQueryGQL extends Apollo.Query<
    MyProvidersWithMembershipQuery,
    MyProvidersWithMembershipQueryVariables
> {
    document = MyProvidersWithMembershipQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReactivateUserEmailDocument = gql`
    mutation ReactivateUserEmail {
        reactivateUserEmail {
            success
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ReactivateUserEmailGQL extends Apollo.Mutation<ReactivateUserEmailMutation, ReactivateUserEmailMutationVariables> {
    document = ReactivateUserEmailDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StartUserImageUploadMutationDocument = gql`
    mutation StartUserImageUploadMutation($input: StartUserImageUploadInput!) {
        startUserImageUpload(input: $input) {
            fileId
            uploadUrl
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class StartUserImageUploadMutationGQL extends Apollo.Mutation<StartUserImageUploadMutation, StartUserImageUploadMutationVariables> {
    document = StartUserImageUploadMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StartUserVerificationRequestMutationDocument = gql`
    mutation StartUserVerificationRequestMutation($input: StartUserVerificationRequestInput!) {
        startUserVerificationRequest(input: $input) {
            iFrameSourceUrl
            userVerificationRequestId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class StartUserVerificationRequestMutationGQL extends Apollo.Mutation<
    StartUserVerificationRequestMutation,
    StartUserVerificationRequestMutationVariables
> {
    document = StartUserVerificationRequestMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SubmitCareersRiasecResultMutationDocument = gql`
    mutation SubmitCareersRiasecResultMutation($input: SubmitCareersRiasecResultInput!) {
        submitCareersRiasecResult(input: $input) {
            careersRiasecResult {
                ...CareersRiasecResult
            }
        }
    }
    ${CareersRiasecResultFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SubmitCareersRiasecResultMutationGQL extends Apollo.Mutation<
    SubmitCareersRiasecResultMutation,
    SubmitCareersRiasecResultMutationVariables
> {
    document = SubmitCareersRiasecResultMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnlinkAccountMutationDocument = gql`
    mutation UnlinkAccountMutation($input: UnlinkAccountInput!) {
        unlinkAccount(input: $input) {
            user {
                ...UserDataDetailed
            }
        }
    }
    ${UserDataDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UnlinkAccountMutationGQL extends Apollo.Mutation<UnlinkAccountMutation, UnlinkAccountMutationVariables> {
    document = UnlinkAccountMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateUserEmailAddressMutationDocument = gql`
    mutation UpdateUserEmailAddressMutation($input: UpdateUserEmailAddressInput!) {
        updateUserEmailAddress(input: $input) {
            user {
                ...UserDataDetailed
            }
        }
    }
    ${UserDataDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateUserEmailAddressMutationGQL extends Apollo.Mutation<
    UpdateUserEmailAddressMutation,
    UpdateUserEmailAddressMutationVariables
> {
    document = UpdateUserEmailAddressMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateUserEmailNotificationsSettingMutationDocument = gql`
    mutation UpdateUserEmailNotificationsSettingMutation($input: UpdateUserEmailNotificationsSettingInput!) {
        updateUserEmailNotificationsSetting(input: $input) {
            userEmailNotificationsSetting {
                ...UserEmailNotificationsSetting
            }
        }
    }
    ${UserEmailNotificationsSettingFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateUserEmailNotificationsSettingMutationGQL extends Apollo.Mutation<
    UpdateUserEmailNotificationsSettingMutation,
    UpdateUserEmailNotificationsSettingMutationVariables
> {
    document = UpdateUserEmailNotificationsSettingMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateUserMutationDocument = gql`
    mutation UpdateUserMutation($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                ...UserDataDetailed
            }
        }
    }
    ${UserDataDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateUserMutationGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateUserPictureMutationDocument = gql`
    mutation UpdateUserPictureMutation($input: UpdateUserPictureInput!) {
        updateUserPicture(input: $input) {
            user {
                ...UserDataDetailed
            }
        }
    }
    ${UserDataDetailedFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class UpdateUserPictureMutationGQL extends Apollo.Mutation<UpdateUserPictureMutation, UpdateUserPictureMutationVariables> {
    document = UpdateUserPictureMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CheckVerifiedIdCardRequestMutationDocument = gql`
    mutation CheckVerifiedIdCardRequestMutation($input: CheckVerifiedIdCardRequestInput!) {
        checkVerifiedIdCardRequest(input: $input) {
            success
            isComplete
            verifiedIdVerifiableCredential {
                ...VerifiedIdVerifiableCredential
            }
        }
    }
    ${VerifiedIdVerifiableCredentialFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class CheckVerifiedIdCardRequestMutationGQL extends Apollo.Mutation<
    CheckVerifiedIdCardRequestMutation,
    CheckVerifiedIdCardRequestMutationVariables
> {
    document = CheckVerifiedIdCardRequestMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CheckWalletCardVerificationSimpleQueryDocument = gql`
    query CheckWalletCardVerificationSimpleQuery($transactionId: UUID!) {
        checkWalletCardVerificationSimple(transactionId: $transactionId) {
            transactionId
            status
            credentials {
                credentialId
                claims
            }
            errorDetails {
                error
                errorDescription
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class CheckWalletCardVerificationSimpleQueryGQL extends Apollo.Query<
    CheckWalletCardVerificationSimpleQuery,
    CheckWalletCardVerificationSimpleQueryVariables
> {
    document = CheckWalletCardVerificationSimpleQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyPreferredWalletCardQueryDocument = gql`
    query MyPreferredWalletCardQuery {
        me {
            id
            preferredWalletCard {
                ...WalletCard
                ... on LearnerIdVerifiableCredential {
                    ...LearnerIdVerifiableCredential
                }
            }
        }
    }
    ${WalletCardFragmentDoc}
    ${LearnerIdVerifiableCredentialFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyPreferredWalletCardQueryGQL extends Apollo.Query<MyPreferredWalletCardQuery, MyPreferredWalletCardQueryVariables> {
    document = MyPreferredWalletCardQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MyWalletCardsQueryDocument = gql`
    query MyWalletCardsQuery($first: Int, $last: Int, $before: String, $after: String, $types: [WalletCardType!]) {
        me {
            id
            walletCards(first: $first, last: $last, before: $before, after: $after, types: $types) {
                totalCount
                pageInfo {
                    ...PageInfo
                }
                edges {
                    node {
                        ...WalletCard
                        ... on LearnerIdVerifiableCredential {
                            ...LearnerIdVerifiableCredential
                        }
                        ... on VerifiedIdVerifiableCredential {
                            ...VerifiedIdVerifiableCredential
                        }
                        ... on LoyaltyVerifiableCredential {
                            ...LoyaltyVerifiableCredential
                        }
                    }
                }
            }
        }
    }
    ${PageInfoFragmentDoc}
    ${WalletCardFragmentDoc}
    ${LearnerIdVerifiableCredentialFragmentDoc}
    ${VerifiedIdVerifiableCredentialFragmentDoc}
    ${LoyaltyVerifiableCredentialFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class MyWalletCardsQueryGQL extends Apollo.Query<MyWalletCardsQuery, MyWalletCardsQueryVariables> {
    document = MyWalletCardsQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RequestVerifiedIdCardMutationDocument = gql`
    mutation RequestVerifiedIdCardMutation($input: RequestVerifiedIdCardInput!) {
        requestVerifiedIdCard(input: $input) {
            iFrameSourceUrl
            userVerificationRequestId
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class RequestVerifiedIdCardMutationGQL extends Apollo.Mutation<
    RequestVerifiedIdCardMutation,
    RequestVerifiedIdCardMutationVariables
> {
    document = RequestVerifiedIdCardMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ResolveWalletCardPresentationSimpleMutationDocument = gql`
    mutation ResolveWalletCardPresentationSimpleMutation($input: ResolveWalletCardPresentationSimpleInput!) {
        resolveWalletCardPresentationSimple(input: $input) {
            success
            redirectUri
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ResolveWalletCardPresentationSimpleMutationGQL extends Apollo.Mutation<
    ResolveWalletCardPresentationSimpleMutation,
    ResolveWalletCardPresentationSimpleMutationVariables
> {
    document = ResolveWalletCardPresentationSimpleMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SimpleMatchWalletCardsForPresentationQueryDocument = gql`
    query SimpleMatchWalletCardsForPresentationQuery($dcqlQuery: JSONObject!, $clientId: String!, $clientMetadata: JSONObject!) {
        simpleMatchWalletCardsForPresentation(dcqlQuery: $dcqlQuery, clientId: $clientId, clientMetadata: $clientMetadata) {
            matches {
                credentialId
                requestedClaims
                walletCard {
                    ...WalletCard
                    ... on LearnerIdVerifiableCredential {
                        ...LearnerIdVerifiableCredential
                    }
                    ... on VerifiedIdVerifiableCredential {
                        ...VerifiedIdVerifiableCredential
                    }
                    ... on LoyaltyVerifiableCredential {
                        ...LoyaltyVerifiableCredential
                    }
                }
            }
            groups {
                groupIndex
                purpose
                required
                combinations
            }
        }
    }
    ${WalletCardFragmentDoc}
    ${LearnerIdVerifiableCredentialFragmentDoc}
    ${VerifiedIdVerifiableCredentialFragmentDoc}
    ${LoyaltyVerifiableCredentialFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class SimpleMatchWalletCardsForPresentationQueryGQL extends Apollo.Query<
    SimpleMatchWalletCardsForPresentationQuery,
    SimpleMatchWalletCardsForPresentationQueryVariables
> {
    document = SimpleMatchWalletCardsForPresentationQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StartWalletCardVerificationSimpleMutationDocument = gql`
    mutation StartWalletCardVerificationSimpleMutation($input: StartWalletCardVerificationSimpleInput!) {
        startWalletCardVerificationSimple(input: $input) {
            transactionId
            oid4vpUrl
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class StartWalletCardVerificationSimpleMutationGQL extends Apollo.Mutation<
    StartWalletCardVerificationSimpleMutation,
    StartWalletCardVerificationSimpleMutationVariables
> {
    document = StartWalletCardVerificationSimpleMutationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const WalletCardQueryDocument = gql`
    query WalletCardQuery($walletCardId: UUID!) {
        walletCard(id: $walletCardId) {
            ...WalletCard
            ... on LearnerIdVerifiableCredential {
                ...LearnerIdVerifiableCredential
            }
            ... on VerifiedIdVerifiableCredential {
                ...VerifiedIdVerifiableCredential
            }
            ... on LoyaltyVerifiableCredential {
                ...LoyaltyVerifiableCredential
            }
        }
    }
    ${WalletCardFragmentDoc}
    ${LearnerIdVerifiableCredentialFragmentDoc}
    ${VerifiedIdVerifiableCredentialFragmentDoc}
    ${LoyaltyVerifiableCredentialFragmentDoc}
`;

@Injectable({
    providedIn: 'root'
})
export class WalletCardQueryGQL extends Apollo.Query<WalletCardQuery, WalletCardQueryVariables> {
    document = WalletCardQueryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
